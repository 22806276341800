// @flow

import * as R from 'ramda';
import React, { useEffect } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { router } from '@accordo-feed/micro-frontends';

import * as selectors from 'src/redux/selectors';
// import Office365Menu from './Office365Menu.container';
import ShellMenu from './ShellMenu.container';
import { PATHS } from 'src/constants';
import { checkOpenSubMenu } from './menu.util';
import { setSubMenuOpenKeys, setMenuCollapsedState } from './menu.duck';

const customerProfilePathRegex = /companies\/(acc_.+?)/i;

/*************
 *   TYPES   *
 *************/

type Props = {
  appView: string,
  location: Object,
  router: Object,
  subMenuOpenKeys: Array<string>,
  collapsed: boolean,
  setSubMenuOpenKeys: Function,
  setMenuCollapsedState: Function
};

/*****************
 *   COMPONENT   *
 *****************/

const Menu = ({
  appView,
  subMenuOpenKeys,
  collapsed,
  setSubMenuOpenKeys,
  setMenuCollapsedState,
  location,
  router
}: Props) => {
  const { pathname } = location;

  const onClick = path => () => router.push(path);

  const onTitleClick = ({ key }) => {
    const isPathOpen = R.includes(key, subMenuOpenKeys);
    const openKeys = isPathOpen ? [] : [key];
    setSubMenuOpenKeys(openKeys);
  };

  useEffect(() => {
    if (collapsed) {
      setSubMenuOpenKeys([]);
      return;
    }

    if (customerProfilePathRegex.exec(pathname)) {
      setSubMenuOpenKeys([PATHS.CUSTOMERS.ROOT]);
    } else if (R.values(R.omit(['ROOT'], PATHS.DASHBOARD)).includes(pathname)) {
      setSubMenuOpenKeys([PATHS.DASHBOARD.ROOT]);
    } else if (pathname === PATHS.SECURITY_SCORE.ROOT) {
      setSubMenuOpenKeys([PATHS.DASHBOARD.ROOT]);
    } else {
      setSubMenuOpenKeys(checkOpenSubMenu(pathname));
    }
  }, [pathname, collapsed]);

  const menuProps = {
    location,
    onClick,
    onTitleClick,
    collapsed
  };

  // return appView === APP_VIEWS.SHELL ? <ShellMenu {...menuProps} /> : <Office365Menu {...menuProps} />;
  return <ShellMenu {...menuProps} />;
};

/*************
 *   REDUX   *
 *************/

const mapStateToProps = R.applySpec({
  appView: selectors.appViewSelector,
  subMenuOpenKeys: selectors.subMenuOpenKeysSelector,
  collapsed: selectors.menuCollapsedStateSelector
});

const mapDispatchToProps = {
  setSubMenuOpenKeys,
  setMenuCollapsedState
};

export default compose(connect(mapStateToProps, mapDispatchToProps), router.withRouterHOC)(Menu);
