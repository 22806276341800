import styled, { css } from 'styled-components';
import { constants } from '@accordo-feed/aco-styled-components';

const { colors, fontSize } = constants;

export const PromotionItem = styled.p`
  margin-top: 10px;
  font-size: ${fontSize.xSmall};
  font-weight: 600;
`;

export const PromotionContainer = styled.div`
  background-color: ${colors.tintGreen};
  margin: 0 auto 10px;
  text-align: center;
  padding: 5px;
  ${props =>
    props.isPlanOverview &&
    css`
      width: 50%;
    `};
`;
