// @flow

import * as R from 'ramda';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { router } from '@accordo-feed/micro-frontends';

import * as selectors from 'src/pages/secureApp/secureApp.selector';
import LeftSide from './LeftSide.component';

/*************
 *   REDUX   *
 *************/

const mapStateToProps = R.applySpec({
  currentStep: selectors.currentStepSelector
});

export default compose(
  connect(mapStateToProps),
  router.withRouterHOC,
  withHandlers({
    goBack: ({ router }) => e => {
      router.goBack();
    }
  })
)(LeftSide);
