import { createSelector } from 'reselect';

import { getModuleSelector } from 'src/redux/utils';

const moduleSelector = getModuleSelector('filters');

export const filtersMasterListSelector = createSelector(
  moduleSelector,
  module => module.masterList
);

export const filtersDashboardRecommendationsSelector = createSelector(
  moduleSelector,
  module => module.dashboardRecommendations
);

export const filtersDashboardUnassignedLicensesSelector = createSelector(
  moduleSelector,
  module => module.dashboardUnassignedLicenses
);

export const filtersDashboardGrowthTrendSelector = createSelector(
  moduleSelector,
  module => module.dashboardGrowthTrend
);

export const filtersSecureScoreSelector = createSelector(
  moduleSelector,
  module => module.secureScore
);
