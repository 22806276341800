import styled from 'styled-components';
import { constants } from '@accordo-feed/aco-styled-components';

import { appTextColor } from 'src/App.styled';

const { colors } = constants;

export const Circle = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 180px;
  border: 1px solid ${colors.darkGrey6};
`;

export const CircleWrap = styled.div`
  display: inline-flex;
  padding: 5px 8px;
  border-radius: 7px;
  border: 1px solid ${colors.darkGrey6};
  align-items: center;
  gap: 10px;
`;

export const ColorHex = styled.div`
  color: ${appTextColor};
`;
