// @flow

import * as R from 'ramda';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { redux } from '@accordo-feed/micro-frontends';

import { loaderHOC } from 'src/hoc';
import { connectPartnerCenter } from 'src/redux/modules/partnerCenter/partnerCenter.duck';
import * as selectors from 'src/redux/modules/partnerCenter/partnerCenter.selector';
import PartnerCenterWelcome from './PartnerCenterWelcome.component';

/*************
 *   REDUX   *
 *************/

const mapStateToProps = R.applySpec({
  isLinking: selectors.isLikingSelector
});

const mapDispatchToProps = redux.bindActionCreatorsCurried({
  connectPartnerCenter
});

export default compose(connect(mapStateToProps, mapDispatchToProps), loaderHOC())(PartnerCenterWelcome);
