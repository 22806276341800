// @flow

import React, { useEffect } from 'react';
import { useTranslate } from '@accordo-feed/language.entry';
import { eventTracking } from '@accordo-feed/micro-frontends';

import * as Styled from './partnerCenterWelcome.styled';
import AppFooter from 'src/components/appFooter';
import lang from './partnerCenterWelcome.lang';
import { PATHS, STORAGE_KEYS, SEGMENT_EVENTS } from 'src/constants';

/*************
 *   TYPES   *
 *************/

type Props = {
  actions: Object,
  isLinking: boolean,
  router: Object
};

/*****************
 *   COMPONENT   *
 *****************/

export default ({ actions, isLinking, router }: Props) => {
  const translate = useTranslate();

  useEffect(() => {
    localStorage.setItem(STORAGE_KEYS.HAS_VIEWED_WELCOME_PAGE, '1');
  }, []);

  const onManualLink = () => {
    eventTracking.trackEvent({ trackName: SEGMENT_EVENTS.WELCOME_PAGE_MANUAL });
    router.push(PATHS.CUSTOMERS.ROOT);
  };

  const onSkipLinkClick = () => {
    eventTracking.trackEvent({ trackName: SEGMENT_EVENTS.WELCOME_PAGE_SKIP });
    localStorage.setItem(STORAGE_KEYS.SKIP_PARTNER_CENTER, 'true');
    router.push(PATHS.DASHBOARD.ROOT);
  };

  const onSetupButtonClick = () => {
    eventTracking.trackEvent({ trackName: SEGMENT_EVENTS.WELCOME_PAGE_SETUP_SECURE_APP });
    router.push(PATHS.SECURE_APP);
  };

  const onImportClientListClick = () => {
    eventTracking.trackEvent({ trackName: SEGMENT_EVENTS.WELCOME_PAGE_IMPORT_CLIENT_LIST });
    actions.connectPartnerCenter();
  };

  const { option1: option1Lang, option2: option2Lang } = lang;

  const options = [
    {
      title: translate(option1Lang.title),
      name: translate(option1Lang.name),
      steps: [option1Lang.steps[0], translate(option1Lang.steps[1]), translate(option1Lang.steps[2])],
      button: (
        <Styled.ActionButton onClick={onSetupButtonClick}>{translate(lang.option1.buttonText)}</Styled.ActionButton>
      )
    },
    {
      title: translate(option2Lang.title),
      name: translate(option2Lang.name),
      steps: [translate(option2Lang.steps[0]), translate(option2Lang.steps[1]), translate(option2Lang.steps[2])],
      button: (
        <Styled.ActionButton onClick={onImportClientListClick} ghost>
          {translate(lang.option2.buttonText)}
        </Styled.ActionButton>
      )
    }
  ];

  return (
    <Styled.Container>
      <Styled.Logo />

      <Styled.LeftSide>
        <Styled.Title>{translate(lang.pageTitle)}</Styled.Title>
        <Styled.Title2>{translate(lang.pageTitle2)}</Styled.Title2>
        <Styled.ContentText>{translate(lang.pageDescription)}</Styled.ContentText>
      </Styled.LeftSide>

      <Styled.RightSide>
        <Styled.RightSideContent>
          <Styled.Title>{translate(lang.rightTitle1)}</Styled.Title>
          <Styled.ContentText2>{translate(lang.rightSection1Text)}</Styled.ContentText2>

          <Styled.OptionsContainer>
            {options.map((option, idx) => (
              <Styled.OptionContainer key={idx}>
                <Styled.OptionTitle>{option.title}</Styled.OptionTitle>
                <Styled.OptionName>{option.name}</Styled.OptionName>
                <Styled.Separator />
                <Styled.Steps>
                  {option.steps.map((step, idx) => (
                    <Styled.Step key={idx}>{step}</Styled.Step>
                  ))}
                </Styled.Steps>
                {option.button}
              </Styled.OptionContainer>
            ))}
          </Styled.OptionsContainer>

          <Styled.TitleLink onClick={onManualLink}>{translate(lang.rightTitle2)}</Styled.TitleLink>
          <Styled.ContentText2>{translate(lang.rightSection2Text)}</Styled.ContentText2>
          <Styled.SkipLink onClick={onSkipLinkClick}>{translate(lang.skipLink)}</Styled.SkipLink>
        </Styled.RightSideContent>

        <Styled.FooterWrapperOuter>
          <Styled.FooterWrapper>
            <AppFooter />
          </Styled.FooterWrapper>
        </Styled.FooterWrapperOuter>
      </Styled.RightSide>
    </Styled.Container>
  );
};
