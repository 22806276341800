// @flow

import React from 'react';
import { LanguageEntry } from '@accordo-feed/language.entry';
import { compose, withProps } from 'recompose';
import { loaderHOC } from '@accordo-feed/aco-styled-components';

export default (options: ?Object) =>
  compose(
    withProps(props => ({
      loadingMsg: (
        <LanguageEntry id="loading.spinner.language.newId" defaultMessage="ENTORI™ is loading your customer data" />
      )
    })),
    loaderHOC(options)
  );
