import * as R from 'ramda';
import bodybuilder from 'bodybuilder';
import { createSelector } from 'reselect';

import { orgIdSelector } from 'src/redux/selectors/userData';
import { widgetsDataSelector } from 'src/pages/dashboard/dashboard.selector';
import { filtersSecureScoreSelector } from 'src/redux/selectors';

const securityChartSelector = createSelector(
  widgetsDataSelector,
  R.prop('securityScores')
);

export const securityChartDataSelector = createSelector(
  securityChartSelector,
  R.prop('data')
);

export const securityChartIsLoadingSelector = createSelector(
  securityChartSelector,
  R.prop('isLoading')
);

export const barClickedIndexSelector = createSelector(
  filtersSecureScoreSelector,
  R.prop('barClickedIndex')
);

export const securityChartQuerySelector = createSelector(
  orgIdSelector,
  partnerId => {
    const esQuery = bodybuilder()
      .size(0)
      .query('term', 'partnerId', partnerId)
      .agg('avg', 'percentage', 'avgScore')
      .agg(
        'range',
        {
          field: 'percentage',
          keyed: true,
          ranges: {
            key: 'value',
            to: 40.0
          }
        },
        'lowScore'
      )
      .agg(
        'range',
        {
          field: 'percentage',
          keyed: true,
          ranges: [
            { key: '0-10%', to: 10.0 },
            { key: '10%-20%', from: 10.0, to: 20.0 },
            { key: '20%-30%', from: 20.0, to: 30.0 },
            { key: '30%-40%', from: 30.0, to: 40.0 },
            { key: '40%-50%', from: 40.0, to: 50.0 },
            { key: '50%-60%', from: 50.0, to: 60.0 },
            { key: '60%-70%', from: 60.0, to: 70.0 },
            { key: '70%-80%', from: 70.0, to: 80.0 },
            { key: '80%-90%', from: 80.0, to: 90.0 },
            { key: '90%-100%', from: 90.0, to: 100.0 }
          ]
        },
        'scoreRanges',
        a =>
          a.agg(
            'top_hits',
            {
              sort: [
                {
                  percentage: 'asc'
                }
              ],
              _source: {
                includes: ['clientName', 'currentScore', 'maxScore']
              },
              size: 4
            },
            'clients'
          )
      )
      .build();

    return {
      queryName: 'securityWidget',
      partnerId,
      esQuery
    };
  }
);
