// @flow
import { connect } from 'react-redux';

import * as selectors from 'src/redux/selectors';
import AzureUsage from './AzureUsage.component';

/*************
 *   REDUX   *
 *************/

const mapStateToProps = state => ({
  dataSource: selectors.azureDashboardDataSelector(state)
});

export default connect(mapStateToProps)(AzureUsage);
