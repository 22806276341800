// @flow

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';

import * as selectors from 'src/redux/selectors';
import withDashboardData from 'src/hoc/withDashboardDataHOC';
import {
  setUnassignedLicensesActiveUserFilter,
  setUnassignedLicensesTotalSpendFilter
} from 'src/redux/modules/filters/filters.duck';
import { formatCurrencyHOC } from 'src/hoc';
import UnassignedLicensesComp from './UnassignedLicenses.component';

/*****************
 *   COMPONENT   *
 *****************/

const UnassignedLicenses = props => <UnassignedLicensesComp {...props} />;

/*************
 *   REDUX   *
 *************/

const mapStateToProps = state => ({
  filterData: selectors.unassignedLicensesCtaFilterDataSelector(state),
  dataSource: selectors.unassignedLicensesCtaDataSourceSelector(state),
  originalDataSource: selectors.dashboardMasterListSelector(state),
  isLoading: selectors.dashboardIsLoadingSelector(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { setUnassignedLicensesActiveUserFilter, setUnassignedLicensesTotalSpendFilter },
    dispatch
  )
});

export default compose(
  withDashboardData(),

  // $FlowIgnore
  connect(mapStateToProps, mapDispatchToProps),

  formatCurrencyHOC
)(UnassignedLicenses);
