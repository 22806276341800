// @flow

import { connect } from 'react-redux';

import * as selectors from 'src/redux/selectors';
import { PaymentDetails } from './PaymentDetails.component';
import { updateBillingAddress, submitPromoCode, subscribeMspToPlan } from 'src/redux/modules/payment/payment.duck';

/*************
 *   REDUX   *
 *************/

const mapStateToProps = state => ({
  billingAddress: selectors.organizationBillingAddressSelector(state),
  orgId: selectors.orgIdSelector(state),
  payment: selectors.organizationPaymentSelector(state),
  subscribeToTier: selectors.office365PlanSelectedTierSelector(state),
  isAnnual: selectors.office365PlanSelectedIsAnnualSelector(state),
  userId: selectors.userIdSelector(state)
});

const mapDispatchToProps = {
  submitPromoCode,
  subscribeMspToPlan,
  updateBillingAddress
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetails);
