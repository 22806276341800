import * as yup from 'yup';

import lang from './profile.lang';
import { emptyNumber } from 'src/utils';
import { phoneRegex } from 'src/components/phoneCodePicker/phoneCodePicker.utils';

const { errors: errLang } = lang;

const basicEmailListSchema = translate =>
  yup.array().of(
    yup.object().shape({
      name: yup.string().email(translate(errLang.emailInvalid)),
      invalid: yup.boolean()
    })
  );

export const profileSchema = translate =>
  yup.object().shape({
    userDetails: yup.object().shape({
      firstName: yup
        .string()
        .max(35, translate(errLang.firstNameTooLong))
        .required(translate(errLang.firstNameRequired)),
      lastName: yup
        .string()
        .max(35, translate(errLang.lastNameTooLong))
        .required(translate(errLang.lastNameRequired)),
      phone: yup.object().shape({
        number: yup.string().matches(phoneRegex, translate(errLang.phoneNumberInvalid))
      })
    }),
    orgDetails: yup.object().shape({
      name: yup
        .string()
        .max(90, translate(errLang.organizationNameTooLong))
        .required(translate(errLang.organizationNameRequired)),
      seatCount: emptyNumber()
        .positive(translate(errLang.seatCountInvalid))
        .typeError(translate(errLang.seatCountInvalid)),
      clients: emptyNumber()
        .positive(translate(errLang.clientsInvalid))
        .typeError(translate(errLang.clientsInvalid))
    })
  });

export const psaBillingAlertSchema = translate =>
  yup.object().shape({
    psaBillingAlert: yup.object().shape({
      isAlertOn: yup.boolean(),
      emailList: yup.array().when('isAlertOn', {
        is: true,
        then: basicEmailListSchema(translate).min(1, translate(errLang.emailRequired)),
        otherwise: basicEmailListSchema(translate)
      })
    })
  });
