import { langHelper } from '@accordo-feed/micro-frontends-utils';

const genId = langHelper.genLangId('shell.aco.masterList.allocation');
const genOpts = langHelper.genLangOptions(genId);

export default {
  table: {
    main: {
      customer: genOpts('th.customer', 'Customer'),
      company: genOpts('th.company', 'Company'),
      base: genOpts('th.base', 'Allocation'),
      spend: genOpts('th.spend', 'Spend'),
      adoption: genOpts('th.adoption', 'Adoption'),
      action: genOpts('th.action', 'Actions'),
      tooltip1: genOpts('th.tooltip1', 'Adoption & Usage'),
      type: genOpts('th.type', 'Type')
    },
    base: {
      th1: genOpts('base.th1', 'Users'),
      th2: genOpts('base.th2', 'Licenses'),
      th3: genOpts('base.th3', 'Unassigned'),
      th4: genOpts('base.th4', 'Plans'),
      th5: genOpts('base.th5', 'Total Spend monthly'),
      th6: genOpts('base.th6', 'Total Spend annually'),
      tooltip1: genOpts('base.tooltip1', 'Total Spend monthly'),
      tooltip2: genOpts('base.tooltip2', 'Total Spend annually')
    },
    allocation: {
      th1: genOpts('allocation.th1', 'Licensed Users'),
      th2: genOpts('allocation.th2', 'Licenses'),
      th3: genOpts('allocation.th3', 'Unassigned'),
      th4: genOpts('allocation.th4', 'Mis-Assigned'),
      th5: genOpts('allocation.th5', 'Potential Savings')
    },
    spend: {
      th1: genOpts('spend.th1', 'Monthly Spend'),
      th2: genOpts('spend.th2', 'Annual Spend')
    },
    adoption: {
      th1: genOpts('adoption.th1', 'Inactive <90 days'),
      th2: genOpts('adoption.th2', 'Less Active 30-90 days'),
      th3: genOpts('adoption.th3', 'Storage'),
      th4: genOpts('adoption.th4', 'Potential Savings')
    },
    storage: {
      th1: genOpts('storage.th1', 'Exchange'),
      th2: genOpts('storage.th2', 'Sharepoint'),
      th3: genOpts('storage.th3', 'Onedrive')
    },
    action: {
      th1: genOpts('action.th1', 'Actions'),
      visit: genOpts('action.td.visit', 'actions')
    }
  },
  filter: {
    toggleButtonText: genOpts('filter.buttonText', 'Select Filters'),
    clearButtonText: genOpts('filter.clearButtonText', 'Clear Filters'),
    activeUserTitle: genOpts('filter.activeUserTitle', 'Active users'),
    totalSpendTitle: genOpts('filter.totalSpendTitle', 'Total monthly spend'),
    totalSpendTooltip: genOpts('filter.totalSpendTooltip', 'Explaination for the total spend'),
    monthlyTitle: genOpts('filter.monthlyTitle', 'Monthly'),
    anuallyTitle: genOpts('filter.anuallyTitle', 'Annually'),
    recommendationsTitle: genOpts('filter.recommendationsTitle', 'Recommendations'),
    hasEngagement: genOpts('filter.hasEngagement', 'Has Engagement'),
    hasMonthlyRevenue: genOpts('filter.hasMonthlyRevenue', 'Has Monthly Revenue'),
    hasConsultingRevenue: genOpts('filter.hasConsultingRevenue', 'Has Consulting Revenue')
  }
};
