// @flow

import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';

import * as selectors from 'src/pages/secureApp/secureApp.selector';
import Step1 from './step1';
import Step2 from './step2';

/*************
 *   REDUX   *
 *************/

const mapStateToProps = R.applySpec({
  currentStep: selectors.currentStepSelector
});

/*****************
 *   COMPONENT   *
 *****************/

const RightSide = ({ currentStep }) => {
  const Step = currentStep === 1 ? Step1 : Step2;
  return <Step />;
};

export default connect(mapStateToProps)(RightSide);
