// @flow

import { compose } from 'recompose';
import { connect } from 'react-redux';

import { formatCurrencyHOC } from 'src/hoc';
import { updateBillingAddress, updateBillingEmail } from 'src/redux/modules/payment/payment.duck';
import * as selectors from 'src/redux/selectors';
import Billing from './Billing.component';

/*************
 *   REDUX   *
 *************/

const mapStateToProps = state => ({
  orgId: selectors.orgIdSelector(state),
  billingEmail: selectors.billingEmailSelector(state),
  updateBillingEmailLoadingStatus: selectors.updateBillingEmailLoadingStatusSelector(state),
  loadingStatus: selectors.subscriptionsLoadingStatusSelector(state),
  subscriptions: selectors.subscriptionsSelector(state)
});

const mapDispatchToProps = {
  updateBillingAddress,
  updateBillingEmail
};

export default compose(connect(mapStateToProps, mapDispatchToProps), formatCurrencyHOC)(Billing);
