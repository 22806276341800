// @flow

import React from 'react';

import * as Styled from './recommendations.styled';
import Heading from './Heading.component';
import { RECOM_SECTIONS } from 'src/constants';
import { lang } from './recommendations.lang';
import {
  DescriptionCell,
  ImpactCell,
  ResouceCell,
  UpdatedCell,
  PotentialBenefitCell,
  convertDate
} from './recommendations.config';
import { type RecommendationsType } from './recommendations.duck';

/*************
 *   TYPES   *
 *************/

type Props = {
  translate: Function,
  recommendations: RecommendationsType,
  items: ?(Object[])
};

type RecommendationsDataType = {
  translate: Function,
  items: ?(Object[])
};

type HeaderType = {
  descriptionHeading: string,
  impactHeading: string,
  resourceHeading: string,
  updateHeading: string,
  benefitCol: string
};

// Table header for security, performance, availability
export const TableHeader = ({
  descriptionHeading,
  impactHeading,
  resourceHeading,
  updateHeading,
  benefitCol
}: HeaderType) => (
  <li className="hd">
    <DescriptionCell>{descriptionHeading}</DescriptionCell>
    <ImpactCell>{impactHeading}</ImpactCell>
    <ResouceCell>{resourceHeading}</ResouceCell>
    <UpdatedCell>{updateHeading}</UpdatedCell>
    <PotentialBenefitCell>{benefitCol}</PotentialBenefitCell>
  </li>
);

/*****************
 *   COMPONENT   *
 *****************/

export const RecommendationsData = ({ translate, items }: RecommendationsDataType) => (
  <Styled.Table>
    <TableHeader
      descriptionHeading={translate(lang.descriptionCol)}
      impactHeading={translate(lang.impactCol)}
      resourceHeading={translate(lang.resourceCol)}
      updateHeading={translate(lang.updatedCol)}
      benefitCol={translate(lang.potentialBenefitCol)}
    />

    {items &&
      items.map((item, idx) => {
        const { impact, impactedResource, lastUpdated, description, benefits } = item;

        return (
          <li key={idx}>
            <DescriptionCell>{description}</DescriptionCell>
            <ImpactCell>
              <Styled.Priority level={impact}>{translate(lang.impact[impact])}</Styled.Priority>
            </ImpactCell>
            <ResouceCell>{impactedResource}</ResouceCell>
            <UpdatedCell>{convertDate(lastUpdated)}</UpdatedCell>
            <PotentialBenefitCell>{benefits}</PotentialBenefitCell>
          </li>
        );
      })}
  </Styled.Table>
);

const Security = ({ translate, recommendations }: Props) => (
  <Styled.Section>
    <Heading type={RECOM_SECTIONS.SECURITY} translate={translate} />
    <RecommendationsData translate={translate} items={recommendations.security} />
  </Styled.Section>
);

export default Security;
