// @flow

import { createAction } from 'redux-actions';
import { redux } from '@accordo-feed/micro-frontends';

import type { Action } from 'src/redux/types';
import { wrapWithModule } from 'src/redux/utils';

const { setState } = redux;

/*************
 *   TYPES   *
 *************/

type StateType = {
  data: {
    clients: Array<Object>,
    count: number
  },
  isLoading: boolean
};

/*********************
 *   INITIAL STATE   *
 *********************/

const initialState: StateType = {
  data: {},
  isLoading: false
};

/***************
 *   ACTIONS   *
 ***************/

const wrapWithNamespace = wrapWithModule('pages/dashboard/securityScores');
const GET_SECURITY_PAGE_DATA = wrapWithNamespace('GET_SECURITY_PAGE_DATA');
const GET_SECURITY_PAGE_DATA_SUCCESS = wrapWithNamespace('GET_SECURITY_PAGE_DATA_SUCCESS');
const SET_LOADING_STATE = wrapWithNamespace('SET_LOADING_STATE');
const GET_FILTERED_SECURITY_PAGE_DATA = wrapWithNamespace('GET_FILTERED_SECURITY_PAGE_DATA');
const GET_FILTERED_SECURITY_PAGE_DATA_SUCCESS = wrapWithNamespace('GET_FILTERED_SECURITY_PAGE_DATA_SUCCESS');

export const getSecurityPageData = createAction(GET_SECURITY_PAGE_DATA);
export const getSecurityPageSuccess = createAction(GET_SECURITY_PAGE_DATA_SUCCESS);
export const setLoadingState = createAction(SET_LOADING_STATE);
export const getFilteredSecurityPageData = createAction(GET_FILTERED_SECURITY_PAGE_DATA);
export const getFilteredSecurityPageDataSuccess = createAction(GET_FILTERED_SECURITY_PAGE_DATA_SUCCESS);

/***************
 *   REDUCER   *
 ***************/

export default (state: StateType = initialState, action: Action) => {
  const { type, payload } = action;

  const reducer = {
    [GET_SECURITY_PAGE_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload,
      isLoading: false
    }),
    [GET_FILTERED_SECURITY_PAGE_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: {
        ...state.data,
        clients: payload.clients
      },
      isLoading: false
    }),
    [SET_LOADING_STATE]: setState('isLoading')
  }[type];

  return reducer ? reducer(state, payload) : state;
};
