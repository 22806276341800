// @flow

import React from 'react';
import SimpleTable from 'src/components/simpleTable';
import { widgetDataHOC } from 'src/hoc';
import { generateTableColumns } from './SecurityScoreIndex.config';

/*************
 *   TYPES   *
 *************/

type Props = {
  dataSource: Object
};

/*****************
 *   COMPONENT   *
 *****************/

const SecurityScoreIndex = ({ dataSource }: Props) => {
  const tableOptions = {
    dataSource,
    pageSize: 16,
    columns: generateTableColumns({
      enableSort: true
    }),
    x: 985
  };

  return <SimpleTable {...tableOptions} />;
};

export default widgetDataHOC(['dataSource'])(SecurityScoreIndex);
