import React from 'react';
import { LanguageEntry } from '@accordo-feed/language.entry';
import { langHelper } from '@accordo-feed/micro-frontends-utils';

const genId = itemName => `shell.aco.${itemName}.sider.title`;
const genLang = langHelper.genLangOptions(genId);

export const lang = {
  dashboard: <LanguageEntry {...genLang('dashboard', 'Dashboard')} />,
  hub365: <LanguageEntry {...genLang('hub365', '365 Hub')} />,
  masterList: <LanguageEntry {...genLang('masterList', 'Allocation and Adoption Dashboard')} />,
  comingSoon: <LanguageEntry {...genLang('comingSoon', 'coming soon')} />,
  customer: <LanguageEntry {...genLang('customer', 'Companies')} />,
  companies: <LanguageEntry {...genLang('companies', 'Companies')} />,
  profile: <LanguageEntry {...genLang('profile', 'Profile')} />,
  notificationsSettings: <LanguageEntry {...genLang('notificationsSettings', 'Notifications Settings')} />,
  licensePricing: <LanguageEntry {...genLang('licensePricing', 'License pricing')} />,
  accounts: <LanguageEntry {...genLang('accounts', 'Accounts')} />,
  settings: <LanguageEntry {...genLang('settings', 'Settings')} />,
  logout: <LanguageEntry {...genLang('logout', 'Log Out')} />,
  recommendations: <LanguageEntry {...genLang('recommendations', 'Recommendations')} />,
  users: <LanguageEntry {...genLang('users', 'Users')} />,
  billing: <LanguageEntry {...genLang('billing', 'Billing')} />,
  subscriptions: <LanguageEntry {...genLang('subscriptions', 'Subscriptions')} />,
  psa: <LanguageEntry {...genLang('psa', 'PSA Integration')} />,
  teamManagement: <LanguageEntry {...genLang('teamManagement', 'Team Management')} />,
  addConnection: <LanguageEntry {...genLang('addConnection', 'Add Connection')} />,
  clientGrowth: <LanguageEntry {...genLang('clientGrowth', 'Client Growth')} />,
  secureScoreIndex: <LanguageEntry {...genLang('secureScoreIndex', 'Secure Score Index')} />,
  unassignedLicenses: <LanguageEntry {...genLang('unassignedLicenses', 'Unassigned Licenses')} />,
  psaBillingErrors: <LanguageEntry {...genLang('psaBillingErrors', 'PSA Billing Errors')} />,
  productCatalog: <LanguageEntry {...genLang('productCatalog', 'Product Catalog')} />,
  azureDashboard: <LanguageEntry {...genLang('azureDashboard', 'Azure Dashboard')} />
};
