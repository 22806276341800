import Cookies from 'js-cookie';

const getCookiesStartWith = ({ prefix, cookie }) => {
  return cookie.filter(item => item.trim().startsWith(prefix));
};

export const deleteCookieWithPrefix = prefix => {
  const cookieNames = getCookiesStartWith({ prefix, cookie: Object.keys(Cookies.get()) });
  cookieNames.forEach(cookie => Cookies.remove(cookie));
};
