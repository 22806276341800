// @flow

import * as R from 'ramda';
import React from 'react';
import { Formik } from 'formik';
import { RightOutlined } from '@ant-design/icons';
import { useTranslate } from '@accordo-feed/language.entry';
import { eventTracking } from '@accordo-feed/micro-frontends';

import { SEGMENT_EVENTS } from 'src/constants';
import formSchema from './step1.schema';
import { FORM_KEYS } from './step1.constants';
import lang from './step1.lang';
import * as Styled from './step1.styled';

const shellRe = /ApplicationId:[\r|\n]*(.+)[\r|\n]*ApplicationSecret:[\r|\n]*(.+)[\r|\n]*TenantId:[\r|\n]*(.+)/m;
const formKeys = R.values(FORM_KEYS);

/*************
 *   TYPES   *
 *************/

type Actions = {
  setSecureApp: Function
};

type Props = {
  actions: Actions,
  initialValues: Object,
  isSaving: boolean
};

/*****************
 *   COMPONENT   *
 *****************/

export default ({ actions, initialValues, isSaving }: Props) => {
  const translate = useTranslate();

  const renderForm = ({ errors, handleSubmit, setFieldValue, touched, values }) => {
    const isSubmitEnabled = R.isEmpty(touched) || R.isEmpty(errors);

    const handleValueChange = key => e => {
      const { value } = e.target;
      const m = shellRe.exec(value);

      if (m) {
        const [, ...values] = m;

        formKeys.forEach((key, idx) => {
          setFieldValue(key, values[idx].trim());
        });
      } else {
        setFieldValue(key, value);
      }
    };

    const onSubmit = e => {
      eventTracking.trackEvent({ trackName: SEGMENT_EVENTS.SETUP_PS_NEXT });
      handleSubmit(e);
    };

    return (
      <Styled.Body>
        <Styled.Title>{translate(lang.title)}</Styled.Title>
        <Styled.Desc>{translate(lang.desc)}</Styled.Desc>

        {formKeys.map((key, idx) => (
          <div key={key}>
            <Styled.Label htmlFor={key}>{translate(lang[key])}</Styled.Label>
            <Styled.Input
              id={key}
              type={key === FORM_KEYS.APP_SECRET ? 'password' : 'text'}
              value={values[key]}
              onChange={handleValueChange(key)}
            />
            <Styled.ErrMsg name={key} />
          </div>
        ))}

        <Styled.SubmitWrapper>
          <Styled.Submit loading={isSaving} disabled={!isSubmitEnabled} onClick={onSubmit}>
            {translate(lang.submit)}
            <RightOutlined />
          </Styled.Submit>
        </Styled.SubmitWrapper>
      </Styled.Body>
    );
  };

  return (
    <Styled.Container>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values, { setErrors }) => {
          // Inform Formik if BE responses errors:
          // https://stackoverflow.com/a/54328567
          actions.setSecureApp({ values, setErrors });
        }}
        children={renderForm}
        validationSchema={formSchema(translate)}
      />
      <Styled.Footer>{translate(lang.footer)}</Styled.Footer>
    </Styled.Container>
  );
};
