import styled from 'styled-components';
import { LoadingOutlined, PictureOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ButtonElement, constants, mixins } from '@accordo-feed/aco-styled-components';

import { appTextColor, subHeaderTextColor } from 'src/App.styled';

const { colors, fontSize } = constants;

export const ProfileList = styled.div`
  color: ${appTextColor};
  @media (max-width: 1024px) {
    .ant-row {
      .ant-col-12 {
        width: 100%;
      }
    }
  }
`;

export const FormWrap = styled.div`
  padding: 0 15px 15px;
`;

export const SaveButton = styled(Button).attrs({
  type: 'primary'
})`
  width: 160px;
  font-size: ${fontSize.xSmall};
`;

export const FlagIcon = styled.div`
  line-height: 14px;
  margin-right: 10px;
`;

export const InviteButton = styled(Button).attrs({
  disabled: true,
  type: 'primary',
  ghost: true,
  size: 'large'
})`
  width: 320px;
  color: ${colors.lightGrey} !important;
`;

export const ComingSoonWrapper = styled.div`
  ${mixins.flexVerticalCenter()};
  margin-top: 10px;
  display: none;

  div {
    color: ${colors.shadeGreen};
    margin-left: 10px;
    font-size: ${fontSize.xSmall};
  }
`;

export const LogoFieldWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
`;

export const LogoFieldButtonWrap = styled.div``;

export const LogoFieldDesc = styled.p`
  color: ${subHeaderTextColor};
  font-size: ${fontSize.xSmall};
  line-height: 1.5;
`;

export const PreviewBlock = styled.div`
  width: 100px;
  border-radius: 6px;
  border: 1px solid ${props => (props.isDragActive ? colors.lightGrey : colors.darkGrey6)};
  border-style: ${props => (props.isDragActive ? 'dashed' : 'solid')};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;

  .logo-image {
    max-width: 100%;
  }
`;

export const DeleteButton = styled(ButtonElement)`
  width: 30px;
  position: absolute;
  top: 0;
  right: 0;
`;

export const LoadingIcon = styled(LoadingOutlined)`
  font-size: 40px;
  color: ${colors.darkGrey6};
`;

export const PictureIcon = styled(PictureOutlined)`
  font-size: 40px;
  color: ${colors.darkGrey6};
`;
