import * as R from 'ramda';
import { createSelector } from 'reselect';
import { search } from '@accordo-feed/micro-frontends-utils';

import { RECOMMENDATION_TYPE, initialState as initialFilters } from 'src/redux/modules/filters/filters.duck';
import { dashboardMasterListSelector } from 'src/pages/dashboard/dashboard.selector';
import { filterDataSelector } from './filterPopup/filterPopup.selector';
import { filtersMasterListSelector } from 'src/redux/selectors/filters';
import { searchTermSelector } from 'src/redux/selectors/searchTerm';

export const masterListDataSourceSelector = createSelector(
  dashboardMasterListSelector,
  searchTermSelector,
  filterDataSelector,

  (list, term, filters) => {
    const { recommendations } = filters;

    const filteredList = list.filter(
      ({ activeUsers, totalMonthlySpend, engagement, monthlyRevenue, consultingRevenue }) => {
        const totalSpend = totalMonthlySpend;

        return (
          activeUsers >= filters.selectedMinActiveUsers &&
          activeUsers <= filters.selectedMaxActiveUsers &&
          totalSpend >= filters.selectedMinTotalSpend &&
          totalSpend <= filters.selectedMaxTotalSpend &&
          (recommendations.includes(RECOMMENDATION_TYPE.ENGAGEMENT) ? !!engagement : true) &&
          (recommendations.includes(RECOMMENDATION_TYPE.MONTHLY_REVENUE) ? !!monthlyRevenue : true) &&
          (recommendations.includes(RECOMMENDATION_TYPE.CONSULTING_REVENUE) ? !!consultingRevenue : true)
        );
      }
    );

    return search({ list: filteredList, settings: ['name'] }, term);
  }
);

export const hasFiltersSelector = createSelector(
  filtersMasterListSelector,
  filterDataSelector,

  (filters, data) => {
    const defaults = initialFilters.masterList;
    const check = key => ![data[key], defaults[key]].includes(filters[key]);

    return (
      check('minActiveUsers') ||
      check('maxActiveUsers') ||
      check('minTotalSpend') ||
      check('maxTotalSpend') ||
      !R.equals(filters.recommendations, defaults.recommendations)
    );
  }
);
