// @flow
import { createAction } from 'redux-actions';
import { redux } from '@accordo-feed/micro-frontends';

import type { Action } from 'src/redux/types';
import { wrapWithModule } from 'src/redux/utils';

const { setState } = redux;

/*************
 *   TYPES   *
 *************/

export type AzureCustomerType = {
  id: string,
  companyId: string,
  companyName: string,
  partnerId: string,
  billingStartDate: string,
  billingEndDate: string,
  billingDaysRemaining: number,
  totalCost: number,
  currencyTotalCost: number,
  currencyCode: string,
  spendingBudget: number,
  percentOfBudgetSpent: number,
  totalResources: number,
  totalResourceGroups: number,
  lastSynced: string
};

type AzureDashboardState = {
  data: Array<AzureCustomerType>,
  partners: Array<AzureCustomerType>,
  isLoaded: boolean,
  isLoading: boolean,
  hasError: boolean
};

/*********************
 *   INITIAL STATE   *
 *********************/

const initialState: AzureDashboardState = {
  data: [],
  partners: [],
  isLoaded: false,
  isLoading: false,
  hasError: false
};

/***************
 *   ACTIONS   *
 ***************/

const wrapWithNamespace = wrapWithModule('pages/azureDashboard');
const GET_AZURE_DASHBOARD_DATA = wrapWithNamespace('GET_AZURE_DASHBOARD_DATA');
const GET_AZURE_DASHBOARD_DATA_SUCCESS = wrapWithNamespace('GET_AZURE_DASHBOARD_DATA_SUCCESS');
const GET_AZURE_DASHBOARD_DATA_FAILED = wrapWithNamespace('GET_AZURE_DASHBOARD_DATA_FAILED');
const SET_LOADING_STATE = wrapWithNamespace('SET_LOADING_STATE');
const GET_AZURE_PARTNERS_DASHBOARD_DATA = wrapWithNamespace('GET_AZURE_PARTNERS_DASHBOARD_DATA');
const GET_AZURE_PARTNERS_DASHBOARD_DATA_SUCCESS = wrapWithNamespace('GET_AZURE_PARTNERS_DASHBOARD_DATA_SUCCESS');
const GET_AZURE_PARTNERS_DASHBOARD_DATA_FAILED = wrapWithNamespace('GET_AZURE_PARTNERS_DASHBOARD_DATA_FAILED');

export const getAzureDashboardData = createAction(GET_AZURE_DASHBOARD_DATA);
export const getAzureDashboardDataSuccess = createAction(GET_AZURE_DASHBOARD_DATA_SUCCESS);
export const getAzureDashboardDataFailed = createAction(GET_AZURE_DASHBOARD_DATA_FAILED);
export const setLoadingState = createAction(SET_LOADING_STATE);
export const getAzurePartnersDashboardData = createAction(GET_AZURE_PARTNERS_DASHBOARD_DATA);
export const getAzurePartnersDashboardDataSuccess = createAction(GET_AZURE_PARTNERS_DASHBOARD_DATA_SUCCESS);
export const getAzurePartnersDashboardDataFailed = createAction(GET_AZURE_PARTNERS_DASHBOARD_DATA_FAILED);

/***************
 *   REDUCER   *
 ***************/

export default (state: AzureDashboardState = initialState, action: Action) => {
  const { type, payload } = action;

  const reducer = {
    [SET_LOADING_STATE]: setState('isLoading'),
    [GET_AZURE_DASHBOARD_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload,
      isLoaded: true,
      isLoading: false
    }),
    [GET_AZURE_DASHBOARD_DATA_FAILED]: state => ({
      ...state,
      isLoading: false,
      hasError: true
    }),
    [GET_AZURE_PARTNERS_DASHBOARD_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      partners: payload
    })
  }[type];

  return reducer ? reducer(state, payload) : state;
};
