// @flow

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { useTranslate } from '@accordo-feed/language.entry';
import { redux } from '@accordo-feed/micro-frontends';

import Widget from 'src/components/widget';
import ViewMore from 'src/components/viewMore';
import {
  secureScoreCtaDataSourceSelector,
  securityPageQuerySelector
} from 'src/pages/dashboard/securityScore/securityScorePage.selector';
import { getSecurityPageData } from 'src/pages/dashboard/securityScore/securityScorePage.duck';
import { PATHS } from 'src/constants';
import SecurityScoreIndex from './SecurityScoreIndex.component';
import lang from './SecurityScoreIndex.lang';

const { bindActionCreatorsCurried } = redux;

/*****************
 *   COMPONENT   *
 *****************/

const SecurityScoreIndexContainer = props => {
  const { actions, data, query } = props;
  const translate = useTranslate();

  useEffect(() => {
    actions.getSecurityPageData(query);
  }, []);

  const customers = data.clients;
  const dataSource = R.path([0, 'item'], customers)
    ? customers.map(customer => ({ ...customer.item, refIndex: customer.refIndex }))
    : customers;

  return (
    <Widget
      key="securityscoreindex"
      title={translate(lang.widgetTitle)}
      subTitle={translate(lang.widgetSubTitle)}
      footerContent={<ViewMore path={PATHS.SECURITY_SCORE.ROOT} />}
    >
      <SecurityScoreIndex dataSource={dataSource} />
    </Widget>
  );
};

/*************
 *   REDUX   *
 *************/

const mapStateToProps = state => ({
  data: secureScoreCtaDataSourceSelector(state),
  query: securityPageQuerySelector(state)
});

const mapDispatchToProps = bindActionCreatorsCurried({
  getSecurityPageData
});

export default connect(mapStateToProps, mapDispatchToProps)(SecurityScoreIndexContainer);
