// @flow
import { takeLatest, put, all, fork, select } from 'redux-saga/effects';

import * as selectors from 'src/redux/selectors';
import * as actions from './azureDashboard.duck';
import * as apiCalls from './azureDashboard.api';

/************
 *   SAGA   *
 ************/

function* getAzureDashboardDataSaga(): any {
  yield put(actions.setLoadingState(true));
  try {
    const orgId = yield select(selectors.orgIdSelector);
    const data = yield apiCalls.getAzureDashboardData(orgId);
    yield put(actions.getAzureDashboardDataSuccess(data));
  } catch (err) {
    yield put(actions.getAzureDashboardDataFailed());
  }
}

function* getAzurePartnersDashboardDataSaga(): any {
  const orgId = yield select(selectors.orgIdSelector);

  try {
    const data = yield apiCalls.getAzurePartnerDashboardData(orgId);
    yield put(actions.getAzurePartnersDashboardDataSuccess(data));
  } catch (err) {
    yield put(actions.getAzurePartnersDashboardDataFailed());
  }
}

export default function*(): any {
  yield all([
    fork(takeLatest, actions.getAzureDashboardData, getAzureDashboardDataSaga),
    fork(takeLatest, actions.getAzurePartnersDashboardData, getAzurePartnersDashboardDataSaga)
  ]);
}
