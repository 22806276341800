// @flow

import { createAction } from 'redux-actions';
import { redux } from '@accordo-feed/micro-frontends';

import type { Action } from 'src/redux/types';
import { wrapWithModule } from 'src/redux/utils';

const { setState } = redux;

/*************
 *   TYPES   *
 *************/

type StateType = string;

/*********************
 *   INITIAL STATE   *
 *********************/

const initialState: StateType = '';

/***************
 *   ACTIONS   *
 ***************/

const wrapWithNamespace = wrapWithModule('modules/searchTerm');
const SET_SEARCH_TERM = wrapWithNamespace('SET_SEARCH_TERM');
const RESET_SEARCH_TERM = wrapWithNamespace('RESET_SEARCH_TERM');

export const setSearchTerm = createAction(SET_SEARCH_TERM);
export const resetSearchTerm = createAction(RESET_SEARCH_TERM);

/***************
 *   REDUCER   *
 ***************/

export default (state: StateType = initialState, action: Action) => {
  const { type, payload } = action;

  const reducer = {
    [SET_SEARCH_TERM]: setState(),
    [RESET_SEARCH_TERM]: () => initialState
  }[type];

  return reducer ? reducer(state, payload) : state;
};
