// @flow

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as selectors from 'src/pages/dashboard/securityScore/securityScorePage.selector';
import Filter from './Filter.component';
import {
  setSecureScoreCurrentScoreFilter,
  setSecureScorePercentageFilter,
  setSecureScoreBarClickedIndex
} from 'src/redux/modules/filters/filters.duck';

/*************
 *   REDUX   *
 *************/

const mapStateToProps = state => selectors.filterSelector(state);

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setSecureScoreCurrentScoreFilter,
      setSecureScorePercentageFilter,
      setSecureScoreBarClickedIndex
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
