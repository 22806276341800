import { langHelper } from '@accordo-feed/micro-frontends-utils';

const genHeaderId = langHelper.genLangId('shell.aco.error.modal');
const genHeaderLang = langHelper.genLangOptions(genHeaderId);

export const lang = {
  header: genHeaderLang('header', 'Something went wrong'),
  showMore: genHeaderLang('showMore', 'Show more'),
  showLess: genHeaderLang('showLess', 'Show less'),
  logout: {
    para1: genHeaderLang('logout.message', 'You have been signed out. Please sign in and try again.'),
    button: genHeaderLang('logout.button', 'Sign in')
  },
  default: {
    para1: genHeaderLang('default.message1', 'Please try again by refreshing the page'),
    para2: genHeaderLang(
      'default.message2',
      'In case the problem keeps occurring contact your consultant for technical support'
    ),
    button: genHeaderLang('default.button', 'Refresh now')
  }
};
