// @flow

import React from 'react';
// import { Menu } from 'antd';
import * as R from 'ramda';
import { useTranslate } from '@accordo-feed/language.entry';

import SimpleTable from 'src/components/simpleTable';
import CtaLayout from 'src/pages/dashboard/ctaLayout';
import Filters from 'src/pages/dashboard/ctaLayout/Filters.container';
import { widgetDataHOC } from 'src/hoc';
import * as GrowthTrendStyled from 'src/pages/dashboard/widgets/growthTrend/growthTrend.styled';
// import GrowthTrendChart from 'src/pages/dashboard/widgets/growthTrend/GrowthTrendChart.component';
// import Dropdown from 'src/pages/dashboard/widgets/growthTrend/Dropdown.component';
// import LineIndicator from 'src/pages/dashboard/widgets/growthTrend/LineIndicator.component';
// import { CATEGORIES, DIRECTION } from 'src/components/widget/widget.constants';
import { TableColumns as columns } from './growthTrend.config';
import lang from './growthTrend.lang';

/*************
 *   TYPES   *
 *************/

type Props = {
  actions: {
    setGrowthTrendActiveUserFilter: Function,
    setGrowthTrendTotalSpendFilter: Function
    //setSelectedPath: Function
  },
  dataSource: Array<Object>,
  originalDataSource: Array<Object>,
  filterData: Object,
  filterMenu: Object,
  chartData: Object,
  isLoading: boolean
  //selectedPath: Function
};

/*****************
 *   COMPONENT   *
 *****************/

// const MenuItem = (item: Object) => (
//   <Menu.Item {...R.pick(['key', 'onClick'], item)}>
//     <div>{item.content}</div>
//   </Menu.Item>
// );

const GrowthTrendComp = ({
  actions,
  dataSource,
  originalDataSource,
  filterData,
  isLoading
}: // filterMenu,
// chartData,
// selectedPath
Props) => {
  const translate = useTranslate();
  const { setGrowthTrendActiveUserFilter, setGrowthTrendTotalSpendFilter } = actions;

  // const setConfig = (setItem, items) =>
  //   R.map(item => ({
  //     key: item,
  //     content: translate(lang[item]),
  //     onClick: () => setItem(item)
  //   }))(items);

  // const getMenuList = (setItem, items) => R.map(MenuItem)(setConfig(setItem, items));

  // const setSelection = (prop, value, setTitle) => {
  //   setTitle(translate(lang[value]));
  //   setSelectedPath({ prop, value });
  // };

  // const categoryLangMap = {
  //   [CATEGORIES.ACTIVE_USERS]: translate(lang.activeUsers),
  //   [CATEGORIES.TOTAL_LICENSES]: translate(lang.totalLicenses),
  //   [CATEGORIES.MONTHLY_SPEND]: translate(lang.monthlySpend)
  // };

  // const [selectedTitle, setSelectedTitle] = useState(categoryLangMap[selectedPath.category]);

  // const setCategory = value => setSelection('category', value, setSelectedTitle);

  // const categoryItems = getMenuList(setCategory, R.values(CATEGORIES));

  // const directionLangMap = {
  //   [DIRECTION.POSITIVE]: translate(lang.positive),
  //   [DIRECTION.NEGATIVE]: translate(lang.negative)
  // };

  // const [selectedDirectionTitle, setSelectedDirectionTitle] = useState(directionLangMap[selectedPath.direction]);

  // const setDirection = value => setSelection('direction', value, setSelectedDirectionTitle);

  // const directionItems = getMenuList(setDirection, R.values(DIRECTION));

  const tableDataSource = R.path([0, 'item'], dataSource)
    ? dataSource.map(e => ({ ...e.item, refIndex: e.refIndex }))
    : dataSource;

  const tableProps = {
    dataSource: tableDataSource,
    columns,
    loading: isLoading,
    pageSize: Infinity,
    tableHeight: `calc(100vh - 250px)`,
    x: 1450
  };

  const tableContent = (
    <GrowthTrendStyled.TableWrapper>
      <SimpleTable {...tableProps} />
    </GrowthTrendStyled.TableWrapper>
  );

  const activeUserSliderProps = {
    range: 'range',
    min: filterData.minActiveUsers,
    max: filterData.maxActiveUsers,
    value: [filterData.selectedMinActiveUsers, filterData.selectedMaxActiveUsers],
    onChange: setGrowthTrendActiveUserFilter
  };

  const totalSpendSliderProps = {
    range: 'range',
    min: filterData.minTotalSpend,
    max: filterData.maxTotalSpend,
    value: [filterData.selectedMinTotalSpend, filterData.selectedMaxTotalSpend],
    onChange: setGrowthTrendTotalSpendFilter
  };

  const filterProps = {
    filterData,
    originalDataSource,
    activeUserSliderProps,
    totalSpendSliderProps
  };

  const content = {
    className: 'at_growthTrendPage',
    title: translate(lang.pageTitle),
    // widget: (
    //   <GrowthTrendStyled.WidgetWrapper>
    //     <GrowthTrendStyled.ShortExtendedDropdownWrapper>
    //       <GrowthTrendStyled.DropdownWidgetWrapper>
    //         <Dropdown filterMenu={<Menu>{categoryItems}</Menu>} selectedTitle={selectedTitle} />
    //         <Dropdown filterMenu={<Menu>{directionItems}</Menu>} selectedTitle={selectedDirectionTitle} />
    //       </GrowthTrendStyled.DropdownWidgetWrapper>
    //       {!R.isEmpty(chartData) && <LineIndicator />}
    //     </GrowthTrendStyled.ShortExtendedDropdownWrapper>
    //     <GrowthTrendStyled.ChartWrapper>
    //       <GrowthTrendChart dataSource={chartData} isCta />
    //     </GrowthTrendStyled.ChartWrapper>
    //   </GrowthTrendStyled.WidgetWrapper>
    // ),
    description: '',
    filters: <Filters {...filterProps} />,
    tableContent
  };

  return <CtaLayout {...content} />;
};

export default widgetDataHOC(['dataSource'])(GrowthTrendComp);
