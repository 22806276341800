// @flow

import React from 'react';
import * as R from 'ramda';

import * as Styled from './growthIndicator.styled';

/*************
 *   TYPES   *
 *************/

type Props = {
  className: string,
  value: number,
  showValue?: boolean
};

/*****************
 *    HELPERS     *
 *****************/

const isValidValue = value => R.is(Number, value) && !R.equals(value, 0);

const renderValue = (value, showValue) => showValue && `${value}%`;

/*****************
 *   COMPONENT   *
 *****************/

const GrowthIndicator = ({ className, value, showValue = false }: Props) => {
  if (!isValidValue(value)) {
    return false;
  }

  return (
    <span className={className}>
      <Styled.GrowthIndicator>
        {value > 0 ? <Styled.GrowthUpIcon /> : <Styled.GrowthDownIcon />}
        {renderValue(value, showValue)}
      </Styled.GrowthIndicator>
    </span>
  );
};

export default GrowthIndicator;
