import * as R from 'ramda';
import { createSelector } from 'reselect';

import { getModuleSelector } from 'src/redux/utils';
import { transformForDataSelector, transformAlertDataSelector } from './profile.utils';

const organizationSelector = getModuleSelector('organization');
const profileSelector = getModuleSelector('profile');
const userDataSelector = getModuleSelector('userData');

export const dataSelector = createSelector(organizationSelector, userDataSelector, transformForDataSelector);

export const isProfileUpdatingSelector = createSelector(profileSelector, R.prop('isProfileUpdating'));

export const isProfileUpdatedSelector = createSelector(profileSelector, R.prop('isProfileUpdated'));

export const isAlertUpdatedSelector = createSelector(profileSelector, R.prop('isAlertUpdated'));

export const isAlertUpdatingSelector = createSelector(profileSelector, R.prop('isAlertUpdating'));

export const isMfaUpdatingSelector = createSelector(profileSelector, R.prop('isMfaUpdating'));

export const countryCodeSelector = createSelector(organizationSelector, R.path(['data', 'countryCode']));

export const psaBillingAlertSelector = createSelector(organizationSelector, transformAlertDataSelector);
