// @flow

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as R from 'ramda';
import { Menu } from 'antd';
import { useTranslate } from '@accordo-feed/language.entry';

import * as selectors from 'src/redux/selectors';
import ViewMore from 'src/components/viewMore';
import Widget from 'src/components/widget';
import { CATEGORIES } from 'src/components/widget/widget.constants';
import { setSelectedPath, resetGrowthWidgetPath } from 'src/pages/dashboard/dashboard.duck';
import { PATHS } from 'src/constants';
import GrowthContent from './GrowthTrend.component';
import lang from './growthTrend.lang';

/*************
 *   TYPES   *
 *************/

type Props = {
  selectedPath: Function,
  actions: {
    setSelectedPath: Function,
    resetGrowthWidgetPath: Function
  },
  dataSource: Array<Object>
};

/*****************
 *   COMPONENT   *
 *****************/

const MenuItem = (item: Object) => (
  <Menu.Item {...R.pick(['key', 'onClick'], item)}>
    <div>{item.content}</div>
  </Menu.Item>
);

const GrowthTrend = (props: Props) => {
  const translate = useTranslate();

  const { selectedPath, dataSource } = props;
  const { setSelectedPath, resetGrowthWidgetPath } = props.actions;
  const categoryLangMap = {
    [CATEGORIES.ACTIVE_USERS]: translate(lang.activeUsers),
    [CATEGORIES.TOTAL_LICENSES]: translate(lang.totalLicenses),
    [CATEGORIES.MONTHLY_SPEND]: translate(lang.monthlySpend)
  };

  const [selectedTitle, setSelectedTitle] = useState(categoryLangMap[CATEGORIES.ACTIVE_USERS]);

  useEffect(() => {
    resetGrowthWidgetPath();
  }, []);

  const setCategory = value => {
    setSelectedTitle(translate(lang[value]));
    setSelectedPath({ prop: 'category', value });
  };

  const setDirection = value => setSelectedPath({ prop: 'direction', value });

  const config = R.values(CATEGORIES).map(item => ({
    key: item,
    content: translate(lang[item]),
    onClick: () => setCategory(item)
  }));

  const menuItems = R.map(MenuItem)(config);

  return (
    <Widget
      key="growthTrend"
      title={translate(lang.widgetTitle)}
      subTitle={translate(lang.widgetSubTitle)}
      footerContent={<ViewMore path={PATHS.DASHBOARD.GROWTH_TREND} />}
    >
      <GrowthContent
        filterMenu={<Menu>{menuItems}</Menu>}
        selectedTitle={selectedTitle}
        selectedPath={selectedPath}
        setDirection={setDirection}
        dataSource={dataSource}
      />
    </Widget>
  );
};

/*************
 *   REDUX   *
 *************/

const mapStateToProps = state => ({
  dataSource: selectors.dashboardGrowthTrendSelectedDataSelector(state) || [],
  selectedPath: selectors.dashboardGrowthTrendSelectedPathSelector(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ setSelectedPath, resetGrowthWidgetPath }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(GrowthTrend);
