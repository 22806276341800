// @flow

import * as R from 'ramda';
import { takeLatest, put, all, fork } from 'redux-saga/effects';

import { API_ERROR_ACTION_TYPES } from 'src/constants';
import { getErrorType } from './errorModal.helper';
import * as actions from './errorModal.duck';

function* showErrorModalSaga({ payload: { error } }): any {
  const { response = {}, message, displayApiError } = error;
  const { status } = response;

  const errorCode = R.pathOr(status, ['data', 'statusCode'], response);
  const errorMessage = R.pathOr(message, ['data', 'message'], response);

  const type = getErrorType(status);

  const data = {
    title: R.propOr('', 'title', displayApiError),
    message: R.propOr(errorMessage, 'message', displayApiError),
    type,
    errorCode
  };

  try {
    yield put(actions.setErrorModal(data));
  } catch (err) {}
}

function* showErrorToasterSaga({ payload: { title, message, errorCode, notificationType } }): any {
  const data = {
    title,
    notificationType,
    message,
    errorCode,
    type: API_ERROR_ACTION_TYPES.SHOW_TOASTER
    // timeSet: new Date().toLocaleTimeString() // Required if need to be multiple notifications
  };

  try {
    yield put(actions.setErrorModal(data));
  } catch (err) {}
}

export default function*(): any {
  yield all([
    fork(takeLatest, actions.showErrorModal, showErrorModalSaga),
    fork(takeLatest, actions.showErrorToaster, showErrorToasterSaga)
  ]);
}
