// @flow

import React from 'react';
import { useTranslate } from '@accordo-feed/language.entry';

// import Opportunities from 'src/pages/dashboard/widgets/opportunities';
import CtaLayout from 'src/pages/dashboard/ctaLayout';
import SimpleTable from 'src/components/simpleTable';
import Search from 'src/components/search';
import { onSearchChange } from 'src/components/search/seach.util';
import type { Action } from 'src/redux/types';
import Filter from './filter';
import { TableColumns as columns } from './opportunities.config';
import * as Styled from './opportunities.styled';
import lang from './opportunities.lang';

/*************
 *   TYPES   *
 *************/

type Props = {
  dataSource: Array<Object>,
  originalDataSource: Array<Object>,
  searchTerm: string,
  actions: Action,
  isLoading: boolean
};

/*****************
 *   COMPONENT   *
 *****************/

const OpportunitiesComp = ({ actions, dataSource, originalDataSource, searchTerm, isLoading }: Props) => {
  if (dataSource.some(item => item.hasOwnProperty('item'))) {
    dataSource = dataSource.map(data => {
      return data.item;
    });
  }

  const translate = useTranslate();

  const tableProps = {
    dataSource,
    columns,
    loading: isLoading,
    pageSize: Infinity,
    tableHeight: `calc(100vh - 250px)`,
    x: 1300
  };

  const tableContent = (
    <Styled.TableWrapper>
      <SimpleTable {...tableProps} />
    </Styled.TableWrapper>
  );
  const filters = (
    <>
      {originalDataSource.length > 1 && <Filter />}
      <Search searchTerm={searchTerm} onChange={onSearchChange(actions.setSearchTerm)} />
    </>
  );
  const content = {
    className: 'at_opportunitiesPage',
    title: translate(lang.pageTitle),
    // hiding widget only for now COV-208
    // widget: (
    //   <Styled.WidgetWrapper>
    //     <Opportunities viewMoreAction={false} />
    //   </Styled.WidgetWrapper>
    // ),
    description: lang.introduction,
    filters,
    tableContent
  };

  return <CtaLayout {...content} />;
};

export default OpportunitiesComp;
