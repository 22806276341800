import { langHelper } from '@accordo-feed/micro-frontends-utils';

const genPermissionDeniedId = langHelper.genLangId('shell.aco.linking.error');
const genPermissionDeniedLang = langHelper.genLangOptions(genPermissionDeniedId);

export const lang = {
  permissionDenied: {
    title: genPermissionDeniedLang('permissionDenied.title', 'Unauthorized'),
    message: genPermissionDeniedLang(
      'permissionDenied.message',
      'Access Denied. Please use an admin account to Link O365'
    )
  },
  genericError: {
    title: genPermissionDeniedLang('genericError.title', 'Something went wrong'),
    message: genPermissionDeniedLang('genericError.message', 'Please try again later')
  }
};

export default lang;
