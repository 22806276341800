// @flow

import { takeLatest, put, all, fork, select, call } from 'redux-saga/effects';

import * as actions from './profile.duck';
import * as apiCalls from './profile.api';
import * as organizationSaga from 'src/redux/modules/organization/organization.duck';
import * as selectors from 'src/redux/selectors';
import * as profileSelector from './profile.selector';
import { transformForUpdate, validBase64Image } from './profile.utils';
import { auth } from 'src/utils';

function* putProfileSaga({ payload }): any {
  const orgId = yield select(selectors.orgIdSelector);
  const psaBillingAlert = yield select(selectors.organizationPsaBillingAlertSelector);

  const { orgDetails } = payload;
  let imgUrl = orgDetails.logoImage;
  if (validBase64Image(orgDetails.logoImage)) {
    const data = { orgId, image_data: orgDetails.logoImage };
    const response = yield apiCalls.postCustomerLogo({ orgId, data });
    imgUrl = response.imgUrl;
  }

  try {
    const data = { ...payload, orgDetails: { ...orgDetails, logoImage: imgUrl } };
    const updatedOrg = yield call(apiCalls.putProfile, { orgId, data });
    yield put(organizationSaga.getOrganizationSuccess({ ...updatedOrg, psaBillingAlert }));
    yield put(actions.updateProfileSuccess());
  } catch (err) {
    yield put(actions.updateProfileFailed());
  }
}

function* updatePsaBillingAlertSaga({ payload }): any {
  const orgId = yield select(selectors.orgIdSelector);
  const orgData = yield select(profileSelector.dataSelector);

  const data = transformForUpdate({ ...orgData, ...payload });

  try {
    const updatedOrg = yield call(apiCalls.putProfile, { orgId, data });
    yield put(organizationSaga.getOrganizationSuccess(updatedOrg));
    yield put(actions.updatePsaBillingAlertSuccess());
  } catch (err) {
    yield put(actions.updatePsaBillingAlertFailed());
  }
}

function* updateMfaSaga({ payload }): any {
  const orgId = yield select(selectors.orgIdSelector);
  yield put(actions.setUpdateMfaLoadingState(true));
  try {
    yield call(apiCalls.updateMfa, { orgId, data: payload });
    auth.logout();
  } catch (err) {
    yield put(actions.setUpdateMfaLoadingState(false));
  }
}

function* postCustomerLogoSaga({ payload: { image_data, callback } }): any {
  const orgId = yield select(selectors.orgIdSelector);
  try {
    const data = { orgId, image_data };
    const response = yield apiCalls.postCustomerLogo({ orgId, data });
    if (callback) callback(response.imgUrl);
  } catch (err) {
    if (callback) callback();
  }
}

export default function*(): any {
  yield all([fork(takeLatest, actions.updateProfile, putProfileSaga)]);
  yield all([fork(takeLatest, actions.updateMfa, updateMfaSaga)]);
  yield all([fork(takeLatest, actions.updatePsaBillingAlert, updatePsaBillingAlertSaga)]);
  yield all([fork(takeLatest, actions.postCustomerLogo, postCustomerLogoSaga)]);
}
