export const STATUS = {
  COMPLETED: 'Completed',
  NOT_COMPLETED: 'Not Completed'
};

export const IMPACT = {
  LOW: 'low',
  MODERATE: 'moderate',
  HIGH: 'high'
};

export const COL_WIDTHS = {
  improvementAction: 150,
  status: 70,
  score: 50,
  maxScore: 50,
  controlCategory: 50,
  userImpact: 50
};

export const TABLE_COLUMNS = {
  IMPROVEMENT_ACTION: 'improvementAction',
  STATUS: 'status',
  SCORE: 'score',
  MAX_SCORE: 'maxScore',
  CONTROL_CATEGORY: 'controlCategory',
  USER_IMPACT: 'userImpact'
};

export const FILTER_COLUMNS = [TABLE_COLUMNS.STATUS, TABLE_COLUMNS.CONTROL_CATEGORY, TABLE_COLUMNS.USER_IMPACT];

export const FILTER_TEXT_MAP = {
  [TABLE_COLUMNS.STATUS]: 'Status',
  [TABLE_COLUMNS.CONTROL_CATEGORY]: 'Category',
  [TABLE_COLUMNS.USER_IMPACT]: 'User Impact'
};
