// @flow

import { createAction } from 'redux-actions';
import { redux } from '@accordo-feed/micro-frontends';

import type { Action } from 'src/redux/types';
import { wrapWithModule } from 'src/redux/utils';

const { setState } = redux;

/*************
 *   TYPES   *
 *************/

type StateType = {
  isProfileUpdated: boolean,
  isProfileUpdating: boolean,
  isAlertUpdated: boolean,
  isAlertUpdating: boolean,
  isMfaUpdating: boolean
};

/*********************
 *   INITIAL STATE   *
 *********************/

const initialState: StateType = {
  isProfileUpdated: true,
  isProfileUpdating: false,
  isAlertUpdated: true,
  isAlertUpdating: false,
  isMfaUpdating: false
};

/***************
 *   ACTIONS   *
 ***************/

const wrapWithNamespace = wrapWithModule('pages/profile');
const SET_PROFILE_UPDATED = wrapWithNamespace('SET_PROFILE_UPDATED');
const SET_ALERT_UPDATED = wrapWithNamespace('SET_ALERT_UPDATED');
const UPDATE_MFA = wrapWithNamespace('UPDATE_MFA');
const SET_UPDATE_MFA_LOADING_STATE = wrapWithNamespace('SET_UPDATE_MFA_LOADING_STATE');
const UPDATE_PROFILE = wrapWithNamespace('UPDATE_PROFILE');
const UPDATE_PROFILE_FAILED = wrapWithNamespace('UPDATE_PROFILE_FAILED');
const UPDATE_PROFILE_SUCCESS = wrapWithNamespace('UPDATE_PROFILE_SUCCESS');
const UPDATE_PSA_BILLING_ALERT = wrapWithNamespace('UPDATE_PSA_BILLING_ALERT');
const UPDATE_PSA_BILLING_ALERT_SUCCESS = wrapWithNamespace('UPDATE_PSA_BILLING_ALERT_SUCCESS');
const UPDATE_PSA_BILLING_ALERT_FAILED = wrapWithNamespace('UPDATE_PSA_BILLING_ALERT_FAILED');
const POST_CUSTOMER_LOGO = wrapWithNamespace('POST_CUSTOMER_LOGO');

export const setProfileUpdated = createAction(SET_PROFILE_UPDATED);
export const setAlertUpdated = createAction(SET_ALERT_UPDATED);
export const updateMfa = createAction(UPDATE_MFA);
export const setUpdateMfaLoadingState = createAction(SET_UPDATE_MFA_LOADING_STATE);
export const updateProfile = createAction(UPDATE_PROFILE);
export const updateProfileFailed = createAction(UPDATE_PROFILE_FAILED);
export const updateProfileSuccess = createAction(UPDATE_PROFILE_SUCCESS);
export const updatePsaBillingAlert = createAction(UPDATE_PSA_BILLING_ALERT);
export const updatePsaBillingAlertSuccess = createAction(UPDATE_PSA_BILLING_ALERT_SUCCESS);
export const updatePsaBillingAlertFailed = createAction(UPDATE_PSA_BILLING_ALERT_FAILED);
export const postCustomerLogo = createAction(POST_CUSTOMER_LOGO);

/***************
 *   REDUCER   *
 ***************/

export default (state: StateType = initialState, action: Action) => {
  const { type, payload } = action;

  const reducer = {
    [SET_PROFILE_UPDATED]: setState('isProfileUpdated'),
    [SET_ALERT_UPDATED]: setState('isAlertUpdated'),
    [UPDATE_PROFILE]: state => ({
      ...state,
      isProfileUpdated: true,
      isProfileUpdating: true
    }),
    [UPDATE_PROFILE_SUCCESS]: state => ({
      ...state,
      isProfileUpdating: false
    }),
    [UPDATE_PROFILE_FAILED]: state => ({
      ...state,
      isProfileUpdated: false,
      isProfileUpdating: false
    }),
    [UPDATE_PSA_BILLING_ALERT]: state => ({
      ...state,
      isAlertUpdated: true,
      isAlertUpdating: true
    }),
    [UPDATE_PSA_BILLING_ALERT_SUCCESS]: state => ({
      ...state,
      isAlertUpdating: false
    }),
    [UPDATE_PSA_BILLING_ALERT_FAILED]: state => ({
      ...state,
      isAlertUpdated: false,
      isAlertUpdating: false
    }),
    [SET_UPDATE_MFA_LOADING_STATE]: setState('isMfaUpdating')
  }[type];

  return reducer ? reducer(state, payload) : state;
};
