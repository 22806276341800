import React from 'react';
import { LanguageEntry } from '@accordo-feed/language.entry';

import StyledLink from 'src/components/link';
import { sortByAlph, sortByNumber } from 'src/utils';
import { PATHS } from 'src/constants';
import lang from './SecurityScoreIndex.lang';

const tableLang = lang.table;

/***************
 *   CONFIGS   *
 ***************/

const defaultWidth = {
  small: 70,
  medium: 90,
  large: 120,
  xlarge: 200
};

export const generateTableColumns = props => {
  const { enableSort } = props;
  return [
    {
      title: <LanguageEntry {...tableLang.main.company} />,
      dataIndex: 'clientName',
      key: 'clientName',
      width: defaultWidth.xlarge,
      fixed: 'left',
      sorter: enableSort && sortByAlph('clientName'),
      render: (clientName, { id }) => (
        <StyledLink
          to={{
            pathname: `${PATHS.CUSTOMERS.ROOT}/${id}/`,
            state: { prevPath: PATHS.DASHBOARD.ROOT }
          }}
        >
          {clientName}
        </StyledLink>
      )
    },
    {
      title: <LanguageEntry {...tableLang.main.securescore} />,
      children: [
        {
          title: <LanguageEntry {...tableLang.base.th2} />,
          dataIndex: 'currentScore',
          key: 'currentScore',
          width: defaultWidth.medium,
          sorter: enableSort && sortByNumber('currentScore')
        },
        {
          title: <LanguageEntry {...tableLang.base.th3} />,
          dataIndex: 'maxScore',
          key: 'maxScore',
          width: defaultWidth.medium,
          sorter: enableSort && sortByNumber('maxScore')
        },
        {
          title: <LanguageEntry {...tableLang.base.th4} />,
          dataIndex: 'percentage',
          key: 'percentage',
          width: defaultWidth.medium,
          sorter: enableSort && sortByNumber('percentage')
        }
        // {
        //   title: <LanguageEntry {...tableLang.base.th5} />,
        //   dataIndex: 'change',
        //   key: 'change',
        //   width: defaultWidth.medium,
        //   render: () => 'NA'
        // }
      ]
    }
    // {
    //   title: <LanguageEntry {...tableLang.main.mfa} />,
    //   children: [
    //     {
    //       title: <LanguageEntry {...tableLang.base.th6} />,
    //       dataIndex: 'adminMfa',
    //       key: 'adminMfa',
    //       width: defaultWidth.medium,
    //       render: () => 'NA'
    //     },
    //     {
    //       title: <LanguageEntry {...tableLang.base.th7} />,
    //       dataIndex: 'generalMfa',
    //       key: 'generalMfa',
    //       width: defaultWidth.medium,
    //       render: () => 'NA'
    //     }
    //   ]
    // }
  ];
};
