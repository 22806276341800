// @flow

import { createAction } from 'redux-actions';

import type { Action } from 'src/redux/types';
import { wrapWithModule } from 'src/redux/utils';
import { isModalError } from './errorModal.helper';

/*************
 *   TYPES   *
 *************/

export type ErrorModalType = ?{
  title: string,
  message: string,
  type: string,
  errorCode: string,
  show: boolean
};

/*********************
 *   INITIAL STATE   *
 *********************/

const initialState: ErrorModalType = {
  title: '',
  message: '',
  type: '',
  errorCode: '',
  show: false
};

/***************
 *   ACTIONS   *
 ***************/

const wrapWithNamespace = wrapWithModule('modules/errorModal');
const SHOW_ERROR_MODAL = wrapWithNamespace('SHOW_ERROR_MODAL');
const SHOW_ERROR_TOASTER = wrapWithNamespace('SHOW_ERROR_TOASTER');
const SET_ERROR_MODAL = wrapWithNamespace('SET_ERROR_MODAL');
const HIDE_ERROR_MODAL = wrapWithNamespace('HIDE_ERROR_MODAL');

export const showErrorModal = createAction(SHOW_ERROR_MODAL);
export const showErrorToaster = createAction(SHOW_ERROR_TOASTER);
export const setErrorModal = createAction(SET_ERROR_MODAL);
export const hideErrorModal = createAction(HIDE_ERROR_MODAL);

/***************
 *   REDUCER   *
 ***************/

const onSetErrorModal = (state, payload) => {
  // Should skip show new error whenever ErrorModal is presented
  if (isModalError(state.type)) {
    return state;
  }

  return { ...payload, show: true };
};

export default (state: ErrorModalType = initialState, action: Action) => {
  const { type, payload } = action;

  const reducer = {
    [SET_ERROR_MODAL]: onSetErrorModal,
    [HIDE_ERROR_MODAL]: () => ({ ...initialState })
  }[type];

  return reducer ? reducer(state, payload) : state;
};
