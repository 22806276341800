import styled from 'styled-components';
import { constants } from '@accordo-feed/aco-styled-components';
import { appTextColor, widgetBorderColor, appLabelColor, subHeaderTextColor } from 'src/App.styled';

const { fontSize } = constants;
const LabelColors = ['#61ABDC', '#5CD470'];

export const Wrapper = styled.div`
  padding: 10px 20px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  font-size: ${fontSize.small};
  line-height: 1.5;
  color: ${appTextColor};
`;

export const AvailableLicenses = styled.span`
  font-size: 10px;
  line-height: 15px;
  color: ${appTextColor};
`;

export const LicenseRow = styled.div`
  margin: 10px 0 16px;

  .ant-progress-inner,
  .ant-progress-bg {
    border-radius: 11px !important;
  }
`;

export const ProgressWrapper = styled.div``;

const applyElipses = props => {
  if (props.elipseLongName) {
    return `
      white-space: nowrap;
      max-width: ${props.elipseMaxWidth};
      overflow: hidden;
      text-overflow: ellipsis;
    `;
  }
};

export const CompanyName = styled.span`
  ${applyElipses}
  margin-left: 9px;
`;

export const UnassignedLicense = styled.div`
  margin-top: 20px;
`;

export const NumberBox = styled.div`
  display: flex;
  flex-direction: column;
  border-color: ${widgetBorderColor};
`;

export const NumberLabel = styled.span`
  color: ${props =>
    props.theme === 'light' ? appLabelColor : props.colorIdx ? LabelColors[props.colorIdx] : LabelColors[0]};
  font-size: 25px;
  line-height: 2.5rem;
  font-weight: bold;
`;

export const Text = styled.span`
  color: ${subHeaderTextColor} !important;
  font-size: ${fontSize.xSmall};
  line-height: 100%;
`;
