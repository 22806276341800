import React from 'react';
import { Col, Row } from 'antd';
import { constants } from '@accordo-feed/aco-styled-components';

import Widget from 'src/components/widget';
import StyledLink from 'src/components/link';
import { nestedWidgetBackground } from 'src/App.styled';
import * as Styled from './opportunitiesHighlights.styled';

const { fontSize } = constants;

export default ({ title, recommendations, theme }) => (
  <Styled.Summary>
    <Styled.CustomRow type="flex" justify="space-around" gutter={[24, 16]}>
      <Col xs={{ span: 24 }}>
        <Widget title={title} isSubWidget={true} customBackground={nestedWidgetBackground}>
          <Styled.HighlightWrapper>
            <Row type="flex">
              <Col xs={6}>
                <Styled.NumberBox>
                  <Styled.NumberLabel theme={theme}>{recommendations?.savings ?? 0}</Styled.NumberLabel>
                  <StyledLink fontSize={fontSize.xSmall}>Savings</StyledLink>
                </Styled.NumberBox>
              </Col>
              <Col xs={6}>
                <Styled.NumberBox>
                  <Styled.NumberLabel theme={theme}>{recommendations?.storage ?? 0}</Styled.NumberLabel>
                  <StyledLink fontSize={fontSize.xSmall}>Storage</StyledLink>
                </Styled.NumberBox>
              </Col>
              <Col xs={6}>
                <Styled.NumberBox>
                  <Styled.NumberLabel theme={theme}>{recommendations?.optimization ?? 0}</Styled.NumberLabel>
                  <StyledLink fontSize={fontSize.xSmall}>Optimization</StyledLink>
                </Styled.NumberBox>
              </Col>
              <Col xs={6}>
                <Styled.NumberBox noBorder={true}>
                  <Styled.NumberLabel theme={theme} colorIdx="3">
                    {recommendations?.security ?? 0}
                  </Styled.NumberLabel>
                  <StyledLink fontSize={fontSize.xSmall}>Security</StyledLink>
                </Styled.NumberBox>
              </Col>
            </Row>
          </Styled.HighlightWrapper>
        </Widget>
      </Col>
    </Styled.CustomRow>
  </Styled.Summary>
);
