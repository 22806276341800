// @flow

import * as R from 'ramda';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';

import { selectedFiltersSelector } from './filterPanel/filterPanel.selector';
import { filterTagSelector } from './securityScorePage.selector';
import { setSecureScoreBarClickedIndex } from 'src/redux/modules/filters/filters.duck';
import { setSelectedFilter, resetFilters as resetFilterPanel } from './filterPanel/filterPanel.duck';
import { getFilteredSecurityPageData } from './securityScorePage.duck';
import { ADVANCED_FILTER_KEYS, ADVANCED_FILTER_TEXT_MAP } from './securityScore.constants';
import { findKeyByMappedValue } from 'src/utils';
import FilterTags from './filterTags';

/*************
 *   REDUX   *
 *************/

const mapStateToProps = R.applySpec({
  filterTags: filterTagSelector,
  selectedFilters: selectedFiltersSelector
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setSecureScoreBarClickedIndex,
      setSelectedFilter,
      getFilteredSecurityPageData,
      resetFilterPanel
    },
    dispatch
  )
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),

  withHandlers({
    onRemoveFilterTag: ({ actions, selectedFilters }) => removedFilter => {
      const pathAndValue = R.split(': ', removedFilter);
      const path = findKeyByMappedValue(ADVANCED_FILTER_TEXT_MAP, R.prop('0', pathAndValue));
      const valueNeedRemove = R.prop('1', pathAndValue);

      const value = [ADVANCED_FILTER_KEYS.CURRENT_SCORE, ADVANCED_FILTER_KEYS.PERCENTAGE].includes(path)
        ? []
        : R.reject(R.equals(valueNeedRemove), R.prop(path, selectedFilters));

      if (R.equals(ADVANCED_FILTER_KEYS.PERCENTAGE, path)) {
        actions.setSecureScoreBarClickedIndex(-1);
      }

      actions.setSelectedFilter({ [path]: value });
      actions.getFilteredSecurityPageData();
    },

    onClearAll: ({ actions }) => () => {
      actions.setSecureScoreBarClickedIndex(-1);
      actions.resetFilterPanel();
      actions.getFilteredSecurityPageData();
    }
  })
)(FilterTags);
