// @flow

import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import { useTranslate } from '@accordo-feed/language.entry';

import ErrorWrapper from 'src/components/wrapperComponents/Error.wrapper';
import Search from 'src/components/search';
import SimpleTable from 'src/components/simpleTable';
import { SectionWrapper, ActionWrapper } from 'src/pages/page.styled';
import { onSearchChange } from 'src/components/search/seach.util';
import FilterPopup from './filterPopup';
import { generateTableColumns } from './masterList.config';
import lang from './masterList.lang';
import * as Styled from './masterList.styled';

const filterLang = lang.filter;

/*************
 *   TYPES   *
 *************/

type Actions = {
  getDashboardData: Function,
  setSearchTerm: Function,
  clearMasterListFilters: Function
};

type Props = {
  actions: Actions,
  tableData: Array<Object>,
  searchTerm: string,
  hasFilters: boolean,
  hasError: boolean,
  isLoading: boolean
};

/*****************
 *   COMPONENT   *
 *****************/

export default ({ actions, tableData, searchTerm, hasFilters, hasError, isLoading }: Props) => {
  const translate = useTranslate();
  const [isFilterPopupOpen, setFilterPopupOpen] = useState(false);

  const toggleFilterPopup = () => setFilterPopupOpen(!isFilterPopupOpen);
  const closeFilterPopup = () => setFilterPopupOpen(false);
  const onClearFiltersButton = () => actions.clearMasterListFilters();

  const dataSource = R.path([0, 'item'], tableData)
    ? tableData.map(e => ({ ...e.item, refIndex: e.refIndex }))
    : tableData;

  const tableProps = {
    dataSource,
    columns: generateTableColumns(),
    pageSize: 10,
    loading: isLoading,
    tableHeight: `calc(100vh - 380px)`,
    x: 1900
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ErrorWrapper show={hasError}>
      <SectionWrapper className="at_masterListPage">
        <ActionWrapper>
          {dataSource.length > 1 && (
            <Styled.FilterButton
              className="at_searchFilter"
              isOpen={isFilterPopupOpen}
              hasFilters={hasFilters}
              onClick={toggleFilterPopup}
            >
              {translate(filterLang.toggleButtonText)}
              <Styled.FilterIcon />
            </Styled.FilterButton>
          )}

          {hasFilters && (
            <Styled.ClearFiltersButton className="at_clearSearchFilter" onClick={onClearFiltersButton}>
              {translate(filterLang.clearButtonText)}
            </Styled.ClearFiltersButton>
          )}

          <Search
            className="at__searchClients"
            searchTerm={searchTerm}
            onChange={onSearchChange(actions.setSearchTerm)}
          />
        </ActionWrapper>

        <FilterPopup className="at_filterGroup" isOpen={isFilterPopupOpen} onCloseBtnClick={closeFilterPopup} />

        <Styled.TableWrapper>
          <SimpleTable {...tableProps} />
        </Styled.TableWrapper>
      </SectionWrapper>
    </ErrorWrapper>
  );
};
