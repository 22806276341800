// @flow

import * as R from 'ramda';
import { createAction } from 'redux-actions';
import { redux } from '@accordo-feed/micro-frontends';

import type { Action } from 'src/redux/types';
import { wrapWithModule } from 'src/redux/utils';

const { setState } = redux;

/*************
 *   TYPES   *
 *************/

type StateType = {
  data: {
    currentScore: {
      min: number,
      max: number
    },
    percentage: {
      min: number,
      max: number
    },
    improvementAction: Array<string>,
    status: Array<string>,
    controlCategory: Array<string>,
    userImpact: Array<string>
  },
  selectedFilters: {
    currentScore: Array<string>,
    percentage: Array<string>,
    improvementAction: Array<string>,
    status: Array<string>,
    controlCategory: Array<string>,
    userImpact: Array<string>
  },
  isLoading: boolean,
  isOpen: boolean
};

/*********************
 *   INITIAL STATE   *
 *********************/

const initialState: StateType = {
  data: {
    currentScore: {},
    percentage: {},
    improvementAction: [],
    status: [],
    controlCategory: [],
    userImpact: []
  },
  selectedFilters: {
    currentScore: [],
    percentage: [],
    improvementAction: [],
    status: [],
    controlCategory: [],
    userImpact: []
  },
  isLoading: false,
  isOpen: true
};

/***************
 *   ACTIONS   *
 ***************/

const wrapWithNamespace = wrapWithModule('pages/dashboard/securityScores/filterPanel');
const SET_LOADING_STATE = wrapWithNamespace('SET_LOADING_STATE');
const SET_OPEN_STATE = wrapWithNamespace('SET_OPEN_STATE');
const GET_FILTER_DATA = wrapWithNamespace('GET_FILTER_DATA');
const GET_FILTER_DATA_SUCCESS = wrapWithNamespace('GET_FILTER_DATA_SUCCESS');
const SET_SELECTED_FILTER = wrapWithNamespace('SET_SELECTED_FILTER');
const RESET_FILTERS = wrapWithNamespace('RESET_FILTERS');

export const setLoadingState = createAction(SET_LOADING_STATE);
export const setOpenState = createAction(SET_OPEN_STATE);
export const getFilterData = createAction(GET_FILTER_DATA);
export const getFilterDataSuccess = createAction(GET_FILTER_DATA_SUCCESS);
export const setSelectedFilter = createAction(SET_SELECTED_FILTER);
export const resetFilters = createAction(RESET_FILTERS);

/***************
 *   REDUCER   *
 ***************/

export default (state: StateType = initialState, action: Action) => {
  const { type, payload } = action;

  const reducer = {
    [GET_FILTER_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      data: payload,
      isLoading: false
    }),
    [SET_LOADING_STATE]: setState('isLoading'),
    [SET_OPEN_STATE]: setState('isOpen'),
    [SET_SELECTED_FILTER]: (state, payload) => {
      const { selectedFilters } = state;
      return {
        ...state,
        selectedFilters: R.mergeDeepRight(selectedFilters, payload)
      };
    },
    [RESET_FILTERS]: R.assoc('selectedFilters', initialState.selectedFilters)
  }[type];

  return reducer ? reducer(state, payload) : state;
};
