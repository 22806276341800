import { PATHS } from 'src/constants';

const PSA_PATHS = PATHS.PSA;

const accountPaths = {
  ...PATHS.ACCOUNTS,
  PSA_ROOT: PSA_PATHS.ROOT,
  PSA_PRODUCT_MAPPING: PSA_PATHS.PRODUCT_MAPPING,
  PSA_CUSTOMER_MAPPING: PSA_PATHS.CUSTOMER_MAPPING
};

export const EXPANDABLE_CONFIG = {
  [PATHS.ACCOUNTS.ROOT]: accountPaths,
  [PATHS.OFFICE_365_OPTIMIZER.ROOT]: PATHS.OFFICE_365_OPTIMIZER
};
