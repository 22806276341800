import { langHelper } from '@accordo-feed/micro-frontends-utils';

const genId = langHelper.genLangId('shell.aco.dashboard.widget.unassignedLicenses');
const genOpts = langHelper.genLangOptions(genId);

export default {
  widgetTitle: genOpts('title', 'Unassigned Licenses'),
  widgetSubTitle: genOpts('subTitle', 'Convert savings to services revenue'),
  availableLicenses: genOpts('Unassigned:', 'Unassigned:')
};
