import { langHelper } from '@accordo-feed/micro-frontends-utils';

const genPaymentInfoId = langHelper.genLangId('shell.aco.billing.paymentInfo');
const genInvoiceHistoryId = langHelper.genLangId('shell.aco.billing.invoiceHistory');

const genPaymentInfoLang = langHelper.genLangOptions(genPaymentInfoId);
const genInvoiceHistoryLang = langHelper.genLangOptions(genInvoiceHistoryId);

export const paymentInfo = {
  heading: genPaymentInfoLang('heading', 'Subscription'),
  paymentInfo: {
    heading: genPaymentInfoLang('payinfoheading', 'Payment Information'),
    emailLabel: genPaymentInfoLang('emailLabel', 'Billing Email'),
    emailExample: genPaymentInfoLang('emailExample', 'admin@example.com'),
    saveButton: genPaymentInfoLang('saveButton', 'Save Changes'),
    cardLabel: genPaymentInfoLang('cardLabel', 'Card Number'),
    updateButton: genPaymentInfoLang('updateButton', 'Update Card'),
    invalidEmail: genPaymentInfoLang('invalidEmail', 'Please enter valid email address'),
    updateBillingEmail: {
      error: genPaymentInfoLang('updateBillingEmailError', 'Please try again later'),
      success: genPaymentInfoLang('updateBillingEmailSuccess', 'Your billing email was updated successfully')
    },
    billingAddress: {
      title: genPaymentInfoLang('billingAddressTitle', 'Billing Address'),
      updateButton: genPaymentInfoLang('billingAddressUpdateButton', 'Update Billing Address'),
      updateSuccessMsg: genPaymentInfoLang(
        'billingAddressUpdateSuccessMsg',
        'Your billing address was updated successfully'
      ),
      updateFailedMsg: genPaymentInfoLang(
        'billingAddressUpdateFailedMsg',
        'Update failed, please update according to the error message!'
      )
    }
  },
  updateCard: {
    heading: genPaymentInfoLang('updateHeading', 'Update credit card'),
    saveButton: genPaymentInfoLang('saveButton', 'Save Changes'),
    cancelButton: genPaymentInfoLang('cancelButton', 'Cancel'),
    httpError: {
      creditCardValidError: genPaymentInfoLang(
        'creditCardValidError',
        'Card validation failed, please check the card information.'
      ),
      updateCardError: genPaymentInfoLang(
        'updateCardError',
        'An error occured when updating your credit card number. Please try again later.'
      )
    }
  }
};

export const invoiceHistory = {
  heading: genInvoiceHistoryLang('heading', 'Invoice History'),
  dateLabel: genInvoiceHistoryLang('colLabel1', 'Date'),
  periodLabel: genInvoiceHistoryLang('colLabel2', 'Billing Period'),
  amountLabel: genInvoiceHistoryLang('colLabel3', 'Amount'),
  downloadLink: genInvoiceHistoryLang('downloadLink', 'Download Invoice')
};
