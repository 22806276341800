import React from 'react';
import { LanguageEntry } from '@accordo-feed/language.entry';

import { renderSpend } from 'src/pages/masterList/masterList.config';
import { PATHS, SUBMODULES } from 'src/constants';
import { sortByAlph, sortByNumber } from 'src/utils';
import { Tooltip } from 'src/pages/page.styled';
import defaultLang from 'src/pages/masterList/masterList.lang';
import * as Styled from 'src/pages/masterList/masterList.styled';
import lang from './unassignedLicenses.lang';

const tableLang = defaultLang.table;

/***************
 *   CONFIGS   *
 ***************/

const defaultWidth = {
  small: 80,
  medium: 140,
  large: 200,
  xlarge: 240
};

export const TableColumns = [
  {
    title: <LanguageEntry {...tableLang.main.customer} />,
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
    sorter: sortByAlph('name'),
    width: defaultWidth.large,
    render: name => <Styled.CustomerName>{name}</Styled.CustomerName>
  },
  {
    title: <LanguageEntry {...tableLang.base.th1} />,
    dataIndex: 'activeUsers',
    key: 'activeUsers',
    width: defaultWidth.small,
    sorter: sortByNumber('activeUsers')
  },
  {
    title: <LanguageEntry {...tableLang.base.th2} />,
    dataIndex: 'totalLicenses',
    key: 'totalLicenses',
    width: defaultWidth.small,
    sorter: sortByNumber('totalLicenses')
  },
  {
    title: <LanguageEntry {...tableLang.base.th3} />,
    dataIndex: 'unassignedLicenses',
    key: 'unassignedLicenses',
    width: defaultWidth.small,
    sorter: sortByNumber('unassignedLicenses')
  },
  {
    title: <LanguageEntry {...lang.table.percentUnassigned} />,
    dataIndex: 'unassignedLicensesPercentage',
    key: 'unassignedLicensesPercentage',
    width: defaultWidth.medium,
    sorter: sortByNumber('unassignedLicensesPercentage'),
    render: value => `${value}%`
  },
  {
    title: <LanguageEntry {...lang.table.potentialSavings} />,
    dataIndex: 'unassignedRevenueImpactAnnually',
    key: 'unassignedRevenueImpactAnnually',
    className: 'aco-td-light-cyan',
    width: defaultWidth.medium,
    render: renderSpend,
    sorter: sortByNumber('unassignedRevenueImpactAnnually')
  },
  {
    title: (
      <span>
        <LanguageEntry {...tableLang.base.th5} />
        <Tooltip title={<LanguageEntry {...tableLang.base.tooltip1} />} />
      </span>
    ),
    dataIndex: 'totalMonthlySpend',
    key: 'totalMonthlySpend',
    width: defaultWidth.medium,
    render: renderSpend,
    sorter: sortByNumber('totalMonthlySpend')
  },
  {
    title: (
      <span>
        <LanguageEntry {...tableLang.base.th6} />
        <Tooltip title={<LanguageEntry {...tableLang.base.tooltip2} />} />
      </span>
    ),
    dataIndex: 'totalAnnualSpend',
    key: 'totalAnnualSpend',
    width: defaultWidth.medium,
    render: renderSpend,
    sorter: sortByNumber('totalAnnualSpend')
  },
  {
    title: <LanguageEntry {...lang.table.viewLicensesDetails} />,
    dataIndex: 'id',
    key: 'id',
    width: defaultWidth.large,
    render: id => (
      <Styled.VisitLink
        to={{
          pathname: `/${SUBMODULES.OFFICE_365_OPTIMIZER}/${id}/plans`,
          state: { prevPath: PATHS.DASHBOARD.ROOT }
        }}
      >
        <LanguageEntry {...lang.table.viewLicenses} />
      </Styled.VisitLink>
    )
  }
];
