// @flow

import * as R from 'ramda';
import { connect } from 'react-redux';
import { redux } from '@accordo-feed/micro-frontends';

import * as secureAppSelectors from 'src/pages/secureApp/secureApp.selector';
import * as selectors from './step1.selector';
import Step1 from './Step1.component';
import { setSecureApp } from 'src/pages/secureApp/secureApp.duck';

/*************
 *   REDUX   *
 *************/

const mapStateToProps = R.applySpec({
  initialValues: selectors.initialValuesSelector,
  isSaving: secureAppSelectors.isSavingSelector
});

const mapDispatchToProps = redux.bindActionCreatorsCurried({
  setSecureApp
});

export default connect(mapStateToProps, mapDispatchToProps)(Step1);
