import styled from 'styled-components';
import { constants } from '@accordo-feed/aco-styled-components';

import { hexToRgb } from 'src/utils';
import { appLabelColor, appTextColor } from 'src/App.styled';

const { colors, fontSize } = constants;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 15px 10px;
`;

export const BarWrapper = styled.div`
  width: 100%;
  min-height: 30px;
  position: relative;
`;

export const BarLabel = styled.span`
  position: absolute;
  left: ${props => props.position};
  font-size: ${fontSize.xSmall};
  color: ${appLabelColor};
`;

export const BarContainer = styled.div`
  width: 100%;
  height: 28px;
  position: relative;
  display: flex;
  align-items: center;
`;

export const ProgressWrapper = styled.div`
  width: 100%;
  height: 19px;
  border-radius: 9px;
  background-color: ${colors.darkGrey1};
  padding: 2px;
`;

export const StyledProgress = styled.div`
  width: ${props => (props.percent ? `${props.percent}%` : '0%')};
  height: 100%;
  border-radius: 23px;
  background: linear-gradient(270deg, ${props => props.color} 1.47%, rgba(${hexToRgb(colors.lightBlue)}, 0) 100%);
`;

export const BarLine = styled.div`
  position: absolute;
  height: 100%;
  width: 1px;
  left: ${props => props.position};
  top: 0;
  background-color: ${appLabelColor};
`;

export const PercentValue = styled.span`
  position: absolute;
  top: 5px;
  right: 5px;
  color: ${appTextColor};
  font-size: ${fontSize.xSmall};
`;

export const ProgressInfoWrapper = styled.div`
  width: 100%;
  height: 17px;
  position: relative;
  margin-bottom: 10px;
`;

export const ProgressTitle = styled.span`
  color: ${props => (props.percent > 37.3 ? colors.lightBlue : colors.lightShadeGrey)};
  font-size: ${fontSize.xSmall};
`;

export const ProgressValue = styled.span`
  position: absolute;
  top: -3px;
  left: ${props => props.position};
  > span {
    color: ${colors.shadeGrey};
    font-size: ${fontSize.tiny};
  }
`;
