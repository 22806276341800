// @flow

import React from 'react';
import * as Styled from './button.styled';

/**************
 *   TYPES   *
 *************/

type ButtonProps = {
  variant?: string,
  children: any
};

export const Button = (props: ButtonProps) => {
  return <Styled.Button {...props}>{props.children}</Styled.Button>;
};

export default Button;
