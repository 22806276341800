// @flow
import React, { useMemo } from 'react';
import * as R from 'ramda';
import { useTranslate } from '@accordo-feed/language.entry';

import { widgetDataHOC } from 'src/hoc';
import type { AzureCustomerType } from '../azureDashboard.duck';
import { BudgetInfo, BudgetProgress } from './azureBudget.config';
import * as Styled from './azureBudget.styled';
import lang from './azureBudget.lang';

/*************
 *   TYPES   *
 *************/

type Props = {
  data: Array<AzureCustomerType>
};

/*****************
 *   COMPONENT   *
 *****************/

const AzureBudget = ({ data }: Props) => {
  const translate = useTranslate();

  const maxValue = useMemo(() => {
    let maxV = 1;
    data.forEach(e => {
      maxV = R.max(maxV, e.percentOfBudgetSpent);
    });
    return maxV;
  }, [data]);

  const dataSource = useMemo(() => {
    return R.sort(R.descend(R.prop('percentOfBudgetSpent')), data);
  }, [data]);

  const position = maxValue > 1.35 ? Math.ceil(100 / maxValue) : 74;

  return (
    <Styled.Wrapper>
      <Styled.BarWrapper>
        <Styled.BarLabel position={`calc(${position}% - 20px)`}>{translate(lang.budget)}</Styled.BarLabel>
      </Styled.BarWrapper>
      {R.slice(0, 5, dataSource).map(item => {
        const { id, companyName, percentOfBudgetSpent, spendingBudget } = item;
        return (
          <Styled.BarWrapper key={id}>
            <BudgetProgress
              linePosition={position}
              max={R.max(1, maxValue)}
              percent={(percentOfBudgetSpent * 100).toFixed(0)}
            />
            <BudgetInfo percentPosition={position} title={companyName} value={spendingBudget} />
          </Styled.BarWrapper>
        );
      })}
    </Styled.Wrapper>
  );
};

export default widgetDataHOC(['data'])(AzureBudget);
