import * as R from 'ramda';
import { langHelper } from '@accordo-feed/micro-frontends-utils';

import step1Lang from 'src/pages/secureApp/rightSide/step1/step1.lang';

const genId = langHelper.genLangId('shell.aco.secureApp.rightSide.step2');
const genOpts = langHelper.genLangOptions(genId);

export default {
  ...R.pick(['footer'], step1Lang),
  title: genOpts('title', 'The Secure App Model setup was successful'),
  desc: genOpts(
    'desc',
    'Connect to Partner Center with your Global credentials in order to get a full list of clients'
  ),
  back: genOpts('backButton', 'Back'),
  connect: genOpts('connectButton', 'Connect to Partner Center')
};
