import React from 'react';
import { LanguageEntry } from '@accordo-feed/language.entry';
import { langHelper } from '@accordo-feed/micro-frontends-utils';
const genProfileId = langHelper.genLangId('shell.aco.profile.cancellation');

const genProfileLang = langHelper.genLangOptions(genProfileId);
const defaultDescription = `To cancel your subscription, go to the Control Panel 
(<a target="_blank" rel='noopener noreferrer' href="https://cp.us.na.cloud.im/ccp/v/pa/marketplace/landing-page">https://cp.us.na.cloud.im/ccp/v/pa/marketplace/landing-page</a>) 
within the Ingram Micro Marketplace. Once cancelled, you will still have access to your account until the end of the billing period.
`;

export default {
  heading: genProfileLang('heading', 'Cancellation'),
  description: <LanguageEntry {...genProfileLang('description', defaultDescription)} />
};
