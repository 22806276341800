// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose, withHandlers } from 'recompose';
import * as R from 'ramda';

import { setOpenFilterPanel, setSecurityControlProfileFilter } from './drawer.duck';
import { getFilterData } from '../filterPanel/filterPanel.duck';
import FilterPanelComp from '../filterPanel/FilterPanel.component';
import * as filterSelectors from '../filterPanel/filterPanel.selector';
import * as globalSelectors from 'src/redux/selectors';
import * as selectors from './drawer.selector';

/*****************
 *   COMPONENT   *
 *****************/

const FilterPanel = props => {
  useEffect(() => {
    props.actions.getFilterData();
  }, []);

  return <FilterPanelComp {...props} />;
};

/*************
 *   REDUX   *
 *************/

const mapStateToProps = R.applySpec({
  isOpen: selectors.isOpenPanelSelector,
  isLoading: filterSelectors.isLoadingSelector,
  hasFeatureSecurityFilterPanel: globalSelectors.hasFeatureSecurityFilterPanel,
  selectedFilters: selectors.allFilterSelector,
  data: filterSelectors.filterDataSelector
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getFilterData,
      setFilterPanelOpen: setOpenFilterPanel,
      setSecurityControlProfileFilter
    },
    dispatch
  )
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),

  withHandlers({
    onFilterChange: ({ actions }) => ({ path, value }) => actions.setSecurityControlProfileFilter({ path, value }),
    onAfterChange: ({ actions }) => () => {}
  })
)(FilterPanel);
