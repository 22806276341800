import { langHelper } from '@accordo-feed/micro-frontends-utils';

const genId = langHelper.genLangId('shell.aco.planOverview.rightSide');
const genOpts = langHelper.genLangOptions(genId);

export default {
  title: genOpts('title1', 'Partner Pricing for Entori™ for M365 & O365'),
  promotion: genOpts('promotion', 'Currently {discount}% off for the life of your subscription as shown below'),
  card: {
    title: genOpts('cardTitle', 'Tier {tierNum}'),
    period: genOpts('cardPeriod', 'per user/mo'),
    userRange: genOpts('cardUserRange', '{range} Active users')
  },
  baseFee: genOpts('baseFee1', 'Minimum fee of US$350 applies for up to 500 active users'),
  button: genOpts('button', 'Start your free 30 day trial'),
  trialDescription: genOpts('trailDescription', 'No commitment. Cancel at any time.'),
  tieredPrice: genOpts(
    'tieredPrice',
    'We use tiered pricing. As more customers are added, the price per user will incrementally decrease for each tier.'
  ),
  minFee: genOpts('minFee', 'Min fee'),
  example: genOpts('example1', 'Example:<br />2,600 active users = $1080/mo (500 at $350 + 2,000*0.35 + 100*0.3)'),
  contactUs: genOpts(
    'contactUs1',
    'If you are not sure how the pricing model works for you, or if you manage more than 25,000 users, please <a href="mailto:sales@entori.com">contact us</a>'
  ),
  explaination: genOpts(
    'explaination1',
    `
      <p>
        Active user counts increase after you add a Client with users who are assigned 1 or more Office 365 license(s).
      </p>
      <p>
        A minimum fee applies for user counts up to 500 (see above). For more information, please contact us: <a href="mailto:sales@entori.com">sales@entori.com</a>.
      </p>
    `
  )
};
