import * as R from 'ramda';
import bodybuilder from 'bodybuilder';

import { isEmptyOrNil } from 'src/utils';

export const genFilterQuery = ({ partnerId }) => ({
  queryName: 'securityAdvancedFilter',
  partnerId,
  esQuery: bodybuilder()
    .filter('term', 'partnerId', partnerId)
    .agg('extended_stats', 'currentScore', { missing: 0 }, 'currentScore')
    .agg('extended_stats', 'percentage', { missing: 0 }, 'percentage')
    .agg('nested', { path: 'controlScores' }, 'controlScores', a =>
      a
        .agg('terms', 'controlScores.status', { size: 10 }, 'status')
        .agg('terms', 'controlScores.controlCategory', { size: 10 }, 'controlCategory')
        .agg('terms', 'controlScores.userImpact', { size: 10 }, 'userImpact')
        .agg(
          'terms',
          'controlScores.improvementAction.keyword',
          { order: { _key: 'asc' }, size: 1000 },
          'improvementAction'
        )
    )
    .size(0)
    .build()
});

/*************************
 *   TRANSFORM HELPERS   *
 *************************/
const getPathValue = (path, defaultValue) => R.pathOr(defaultValue, ['aggregations', ...path]);

const getMinAndMax = field => ({ min: getPathValue([field, 'min'], 0), max: getPathValue([field, 'max'], 0) });

const getBucketKeys = field =>
  R.pipe(getPathValue(['controlScores', field, 'buckets'], []), R.ifElse(isEmptyOrNil, R.always([]), R.pluck('key')));
/**
 * Transform the security page filter ES response to the format filter panel understands
 * @param {Object} response
 * @returns {Object}
 */
export const transformFilterResponse = R.applySpec({
  currentScore: getMinAndMax('currentScore'),
  percentage: getMinAndMax('percentage'),
  improvementAction: getBucketKeys('improvementAction'),
  status: getBucketKeys('status'),
  controlCategory: getBucketKeys('controlCategory'),
  userImpact: getBucketKeys('userImpact')
});

export const optionsGenerator = (arr: string[]) =>
  arr.map(value => ({
    label: value,
    value
  }));
