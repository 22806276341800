// @flow

import * as R from 'ramda';
import React from 'react';
import { Select } from 'antd';

import phoneCodes from './phoneCodes';
import { FlagIcon, StyledSelect } from './phoneCodePicker.styled';

const { Option } = Select;

/*****************
 *   COMPONENT   *
 *****************/

export default (props: Object) => (
  <StyledSelect
    defaultValue="us"
    optionFilterProp="children"
    filterOption={(input, option) => option.props.children[1].indexOf(input) >= 0}
    notFoundContent={null}
    {...props}
  >
    {phoneCodes.map(area => {
      const country = R.keys(area)[0];
      const code = area[country];

      return (
        <Option key={country} value={country}>
          <FlagIcon className={`flag-icon flag-icon-${country}`} />
          {code}
        </Option>
      );
    })}
  </StyledSelect>
);
