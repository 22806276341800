// @flow

import * as R from 'ramda';
import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';

import FilterPanelComp from './FilterPanel.component';
import { setOpenState, getFilterData, setSelectedFilter } from './filterPanel.duck';
import { getFilteredSecurityPageData } from 'src/pages/dashboard/securityScore/securityScorePage.duck';
import * as globalSelectors from 'src/redux/selectors';
import * as selectors from './filterPanel.selector';
import { setSecureScoreBarClickedIndex } from 'src/redux/modules/filters/filters.duck';

/*****************
 *   COMPONENT   *
 *****************/

const FilterPanel = props => {
  useEffect(() => {
    props.actions.getFilterData();
  }, []);

  return <FilterPanelComp hasSecureScoreFilter hasScoreIndexFilter hasImprovementFilter {...props} />;
};

/*************
 *   REDUX   *
 *************/

const mapStateToProps = R.applySpec({
  isLoading: selectors.isLoadingSelector,
  data: selectors.filterDataSelector,
  isOpen: selectors.isOpenSelector,
  hasFeatureSecurityFilterPanel: globalSelectors.hasFeatureSecurityFilterPanel,
  selectedFilters: selectors.selectedFiltersSelector
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setFilterPanelOpen: setOpenState,
      getFilterData,
      getFilteredSecurityPageData,
      setSelectedFilter,
      setSecureScoreBarClickedIndex
    },
    dispatch
  )
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),

  withHandlers({
    onSecureScoreBarClicked: ({ actions }) => () => {
      actions.setSecureScoreBarClickedIndex(-1);
    },
    onFilterChange: ({ actions }) => ({ path, value }) => actions.setSelectedFilter({ [path]: value }),
    onAfterChange: ({ actions }) => () => actions.getFilteredSecurityPageData()
  })
)(FilterPanel);
