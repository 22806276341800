// @flow

import React from 'react';
import { Empty } from 'antd';
import { useTranslate } from '@accordo-feed/language.entry';

import * as Styled from './widgetWrapper.styled';
import lang from './widgetWrapper.lang';

/*************
 *   TYPES   *
 *************/

type Props = {
  className: string
};

/*****************
 *   COMPONENT   *
 *****************/

const ErrorData = ({ className }: Props) => {
  const translate = useTranslate();
  return (
    <Styled.ErrorWrapper className={className}>
      <Empty image={<Styled.ErrorIcon />} description={translate(lang.errorMsg)} />
    </Styled.ErrorWrapper>
  );
};

export default ErrorData;
