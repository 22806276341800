import { langHelper } from '@accordo-feed/micro-frontends-utils';

const genId = langHelper.genLangId('shell.aco.masterList.allocation');
const genOpts = langHelper.genLangOptions(genId);

export default {
  widgetTitle: genOpts('title', 'Subscriptions'),
  filter: {
    title: genOpts('title', 'Filter'),
    spend: genOpts('spend', 'Spend'),
    description: genOpts('description', 'Seats expiring in less than:'),
    days: genOpts('days', 'Days')
  },
  table: {
    main: {
      overview: genOpts('th.overview', 'Overview'),
      annual: genOpts('th.annual', 'Annual'),
      monthly: genOpts('th.monthly', 'Monthly')
    },
    overview: {
      company: genOpts('th.company', 'Company'),
      subs: genOpts('th.subs', 'Subs'),
      seats: genOpts('th.seats', 'Seats')
    },
    annual: {
      subs: genOpts('th.subs', 'Subs'),
      seats: genOpts('th.seats', 'Seats'),
      addons: genOpts('th.addons', 'Addons'),
      spend: genOpts('th.spend', 'Spend'),
      expiring: genOpts('th.expiring', 'Expiring'),
      renewal: genOpts('th.renewal', 'Renewal'),
      atRisk: genOpts('th.atRisk', 'At Risk')
    },
    monthly: {
      subs: genOpts('th.subs', 'Subs'),
      seats: genOpts('th.seats', 'Seats'),
      addons: genOpts('th.addons', 'Addons'),
      spend: genOpts('th.spend', 'Spend'),
      expiring: genOpts('th.expiring', 'Expiring'),
      renewal: genOpts('th.renewal', 'Renewal'),
      atRisk: genOpts('th.atRisk', 'At Risk')
    }
  }
};
