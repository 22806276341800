// @flow

import React from 'react';

/*************
 *   TYPES   *
 *************/

type Props = {
  className: string,
  children: string
};

/*****************
 *   COMPONENT   *
 *****************/

export default ({ className, children }: Props) => (
  <div className={className} dangerouslySetInnerHTML={{ __html: children }} />
);
