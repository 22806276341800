// @flow

import * as R from 'ramda';
import { createAction } from 'redux-actions';
import { redux } from '@accordo-feed/micro-frontends';

import type { Action } from 'src/redux/types';
import { WIDGET, DIRECTION, CATEGORIES } from 'src/components/widget/widget.constants';
import { wrapWithModule } from 'src/redux/utils';

const { setState } = redux;

/*************
 *   TYPES   *
 *************/

/** @see https://github.com/STRML/react-grid-layout#grid-item-props */
export type GridItem = {
  i: string,
  x: number,
  y: number,
  w: number,
  h: number
};

type DashboardState = {
  widgetsData: Object,
  isLoaded: boolean,
  isLoading: boolean,
  hasError: boolean
};

/*********************
 *   INITIAL STATE   *
 *********************/

const growthTrendInitialState = {
  [WIDGET.GROWTH_TREND]: {
    selectedPath: {
      direction: DIRECTION.POSITIVE,
      category: CATEGORIES.ACTIVE_USERS
    }
  }
};

const initialState: DashboardState = {
  widgetsData: {
    // Top widget
    [WIDGET.SUMMARY]: {},

    [WIDGET.OVERVIEW]: { companies: {}, subscriptions: {} },

    // Row 1
    [WIDGET.RECOMMENDATIONS]: {},
    [WIDGET.OPPORTUNITIES]: {},
    [WIDGET.SECURITY_SCORES]: {},
    [WIDGET.M365_LICENSES]: [],

    // Row 2
    [WIDGET.UNASSIGNED_LICENSES]: {},
    [WIDGET.ALERTS]: {},
    [WIDGET.BILLING_DISCREPANCY]: {},
    ...growthTrendInitialState,

    // Row 3
    [WIDGET.MASTER_LIST]: []
  },
  isLoaded: false,
  isLoading: false,
  hasError: false
};

/***************
 *   ACTIONS   *
 ***************/

const wrapWithNamespace = wrapWithModule('pages/dashboard');
const GET_DASHBOARD_DATA = wrapWithNamespace('GET_DASHBOARD_DATA');
const GET_DASHBOARD_DATA_SUCCESS = wrapWithNamespace('GET_DASHBOARD_DATA_SUCCESS');
const GET_DASHBOARD_DATA_FAILED = wrapWithNamespace('GET_DASHBOARD_DATA_FAILED');
const SET_LOADING_STATE = wrapWithNamespace('SET_LOADING_STATE');
const SET_SELECTED_PATH = wrapWithNamespace('SET_SELECTED_PATH');
const RESET_GROWTH_WIDGET_PATH = wrapWithNamespace('RESET_GROWTH_WIDGET_PATH');
const GET_SECURITY_WIDGET_DATA = wrapWithNamespace('GET_SECURITY_WIDGET_DATA');
const GET_SECURITY_WIDGET_DATA_SUCCESS = wrapWithNamespace('GET_SECURITY_WIDGET_DATA_SUCCESS');
const SET_SECURITY_WIDGET_LOADING_STATE = wrapWithNamespace('SET_SECURITY_WIDGET_LOADING_STATE');
const GET_BILLING_DISCREPANCY_WIDGET_DATA = wrapWithNamespace('GET_BILLING_DISCREPANCY_WIDGET_DATA');
const GET_BILLING_DISCREPANCY_WIDGET_DATA_SUCCESS = wrapWithNamespace('GET_BILLING_DISCREPANCY_WIDGET_DATA_SUCCESS');
const GET_PSA_CONNECTION_DATA = wrapWithNamespace('GET_PSA_CONNECTION_DATA');
const SET_BILLING_DISCREPANCY_WIDGET_LOADING_STATE = wrapWithNamespace('SET_BILLING_DISCREPANCY_WIDGET_LOADING_STATE');
const DOWNLOAD_BILLING_DISCREPANCY_REPORT = wrapWithNamespace('DOWNLOAD_BILLING_DISCREPANCY_REPORT');

export const getDashboardData = createAction(GET_DASHBOARD_DATA);
export const getDashboardDataSuccess = createAction(GET_DASHBOARD_DATA_SUCCESS);
export const getDashboardDataFailed = createAction(GET_DASHBOARD_DATA_FAILED);
export const setLoadingState = createAction(SET_LOADING_STATE);
export const setSelectedPath = createAction(SET_SELECTED_PATH);
export const resetGrowthWidgetPath = createAction(RESET_GROWTH_WIDGET_PATH);
export const getSecurityWidgetData = createAction(GET_SECURITY_WIDGET_DATA);
export const getSecurityWidgetDataSuccess = createAction(GET_SECURITY_WIDGET_DATA_SUCCESS);
export const setSecurityWidgetLoadingState = createAction(SET_SECURITY_WIDGET_LOADING_STATE);
export const getBillingDiscrepancyWidgetData = createAction(GET_BILLING_DISCREPANCY_WIDGET_DATA);
export const getBillingDiscrepancyWidgetDataSuccess = createAction(GET_BILLING_DISCREPANCY_WIDGET_DATA_SUCCESS);
export const getPsaConnectionData = createAction(GET_PSA_CONNECTION_DATA);
export const setBillingDiscrepancyWidgetLoadingState = createAction(SET_BILLING_DISCREPANCY_WIDGET_LOADING_STATE);
export const downloadBillingDiscrepancyReport = createAction(DOWNLOAD_BILLING_DISCREPANCY_REPORT);

/***************
 *   REDUCER   *
 ***************/

export default (state: DashboardState = initialState, action: Action) => {
  const { type, payload } = action;

  const reducer = {
    [SET_LOADING_STATE]: setState('isLoading'),
    [GET_DASHBOARD_DATA_SUCCESS]: (state, payload) => ({
      ...state,
      widgetsData: R.mergeDeepRight(state.widgetsData, R.mergeDeepRight(payload, growthTrendInitialState)),
      isLoaded: true,
      isLoading: false
    }),
    [GET_DASHBOARD_DATA_FAILED]: state => ({
      ...state,
      isLoading: false,
      hasError: true
    }),
    [RESET_GROWTH_WIDGET_PATH]: state => ({
      ...state,
      widgetsData: R.mergeDeepRight(state.widgetsData, growthTrendInitialState)
    }),
    [SET_SELECTED_PATH]: (state, { prop, value }) =>
      R.assocPath(['widgetsData', WIDGET.GROWTH_TREND, 'selectedPath', prop], value, state),
    [GET_SECURITY_WIDGET_DATA_SUCCESS]: (state, payload) =>
      R.assocPath(['widgetsData', 'securityScores'], { data: payload, isLoading: false }, state),
    [SET_SECURITY_WIDGET_LOADING_STATE]: (state, payload) =>
      R.assocPath(['widgetsData', 'securityScores', 'isLoading'], payload, state),
    [GET_BILLING_DISCREPANCY_WIDGET_DATA_SUCCESS]: (state, payload) =>
      R.assocPath(['widgetsData', 'billingDiscrepancy'], { ...payload, isLoading: false }, state),
    [SET_BILLING_DISCREPANCY_WIDGET_LOADING_STATE]: (state, payload) =>
      R.assocPath(['widgetsData', 'billingDiscrepancy', 'isLoading'], payload, state)
  }[type];

  return reducer ? reducer(state, payload) : state;
};
