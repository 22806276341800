import styled from 'styled-components';
import { mixins } from '@accordo-feed/aco-styled-components';
import { appTextColor } from 'src/App.styled';

export const Title = styled.div`
  color: ${appTextColor};
  ${mixins.verticalLineMiddle('20px')}
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  margin-right: 10px;
`;
