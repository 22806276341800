import * as R from 'ramda';
import { createSelector } from 'reselect';

import { pageSelector } from 'src/pages/pages.selector';

export const moduleSelector = createSelector(pageSelector, R.path(['securityScore', 'filterPanel']));

export const isOpenSelector = createSelector(moduleSelector, R.prop('isOpen'));

export const filterDataSelector = createSelector(moduleSelector, R.prop('data'));

export const isLoadingSelector = createSelector(moduleSelector, R.prop('isLoading'));

export const selectedFiltersSelector = createSelector(moduleSelector, R.prop('selectedFilters'));
