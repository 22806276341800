import * as R from 'ramda';
import moment from 'moment';
import { constants } from '@accordo-feed/aco-styled-components';
import { formatAndShortenNumberWithSymbol } from 'src/utils';

const { colors } = constants;

const removeDuplicates = (myArr, prop) => {
  return myArr.filter((obj, pos, arr) => {
    return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
};

export const tooltipConfig = dataSource => ({
  trigger: 'axis',
  position: { left: '10%', top: '-43%' },
  formatter: params => {
    let formattedParams = removeDuplicates(params, 'seriesName');
    formattedParams = dataSource
      .map(element => {
        return R.find(R.propEq('seriesName', element.clientName))(formattedParams);
      })
      .filter(n => n);

    const date = moment(R.path(['0', 'value', '0'], formattedParams)).format('D MMM YYYY');
    let res = `<div style="font-size: 12px; padding: 0 7px"> ${date} </div>`;
    R.forEach(item => {
      const currentValue = R.path(['value', '1'], item); // second element of array that contains value (first one is date)
      const colorbox = `<div style="background: ${item.color}; width: 7px; height: 7px; border-radius: 50%;display:inline-block; padding-right: 6px"></div>`;
      res += `<div style="font-size: 12px; padding: 0 7px">${colorbox} ${item.seriesName}: ${currentValue ||
        '-'}</div>`;
    }, formattedParams);
    return res;
  }
});

const between0and7 = num => num > 0 && num <= 7;
const between7and14 = num => num > 7 && num <= 14;
const between14and30 = num => num > 14 && num <= 30;
const between30and45 = num => num > 31 && num <= 45;
const between45and60 = num => num > 45 && num <= 60;

export const xAxisConfig = len => [
  {
    type: 'time',
    splitNumber: R.cond([
      [between0and7, R.always(len)],
      [between7and14, R.always(len / 3)],
      [between14and30, R.always(len / 5)],
      [between30and45, R.always(len / 8)],
      [between45and60, R.always(len / 12)],
      [R.T, R.always(len / 30)]
    ])(len),
    minInterval: 3600 * 1000 * 24,
    boundaryGap: false,
    axisLine: {
      show: true,
      lineStyle: { color: colors.darkGrey }
    },
    splitLine: { show: false },
    axisTick: {
      lineStyle: { color: colors.darkGrey }
    },
    axisLabel: {
      showMinLabel: between0and7(len),
      showMaxLabel: between0and7(len),
      formatter: value => {
        const date = moment(value);
        const getFormat = R.cond([
          [R.gte(60), R.always(date.format('D-MMM'))],
          [R.T, R.always(date.format('MMM'))]
        ]);
        return getFormat(len);
      }
    }
  }
];

export const yAxisConfig = [
  {
    type: 'value',
    boundaryGap: false,
    axisLine: { show: false },
    splitLine: { show: true },
    axisTick: { show: false },
    axisLabel: {
      color: colors.darkGrey,
      formatter: value => formatAndShortenNumberWithSymbol(value, 1)
    }
  }
];

export const colorConfig = [colors.green, colors.tingPurple, colors.lightBlue, colors.yellow];

export const generateSingleSeries = (chart, prediction, clientName, color) => {
  const series = [];
  series.push(
    {
      name: clientName,
      type: 'line',
      showSymbol: false,
      data: chart.map(item => [item.date, item.value]),
      lineStyle: { width: 1.5 },
      color: [color]
    },
    {
      name: clientName,
      type: 'line',
      showSymbol: false,
      data: prediction.map(item => [item.date, item.value]),
      lineStyle: { width: 1.5, type: 'dashed' },
      color: [color]
    }
  );
  return series;
};

export const gridConfig = {
  left: '0',
  right: '0',
  bottom: '30px',
  top: '30px',
  containLabel: true
};
