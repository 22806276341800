// @flow

import { createAction } from 'redux-actions';
import { redux } from '@accordo-feed/micro-frontends';

import type { Action } from 'src/redux/types';
import { DEFAULT_CURRENCY_OPTIONS } from 'src/constants';
import { wrapWithModule } from 'src/redux/utils';

const { mergeState } = redux;

/*************
 *   TYPES   *
 *************/

type CurrencyOptionsType = {
  symbol: string,
  decimal: string,
  separator: string,
  precision: number
};

/*********************
 *   INITIAL STATE   *
 *********************/

const initialState: CurrencyOptionsType = DEFAULT_CURRENCY_OPTIONS;

/***************
 *   ACTIONS   *
 ***************/

const wrapWithNamespace = wrapWithModule('modules/currency');
const GET_CURRENCY_OPTIONS_SUCCESS = wrapWithNamespace('GET_CURRENCY_OPTIONS_SUCCESS');

export const getCurrencyOptionsSuccess = createAction(GET_CURRENCY_OPTIONS_SUCCESS);

/***************
 *   REDUCER   *
 ***************/

export default (state: CurrencyOptionsType = initialState, action: Action) => {
  const { type, payload } = action;

  const reducer = {
    [GET_CURRENCY_OPTIONS_SUCCESS]: mergeState
  }[type];

  return reducer ? reducer(state, payload) : state;
};
