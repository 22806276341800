import React from 'react';
import { Row } from 'antd';
import { injectStripe, CardNumberElement, CardExpiryElement, CardCVCElement } from 'react-stripe-elements';
import { useTranslate } from '@accordo-feed/language.entry';

import { cardForm as lang } from 'src/pages/paymentDetails/paymentDetails.lang';
import * as Styled from 'src/pages/paymentDetails/cardForm.styled';

export const StripeCardFields = injectStripe(() => {
  const translate = useTranslate();

  return (
    <>
      <Styled.CardFormField id="card-num" text={translate(lang.cardNumber)}>
        <CardNumberElement
          style={{
            base: {
              color: '#B3B5C8',
              '::placeholder': {
                color: '#B3B5C8'
              }
            }
          }}
        />
      </Styled.CardFormField>

      <Row type="flex" justify="space-between">
        <Styled.CardFormField id="card-expiry" text={translate(lang.expiryDate)} width="47%">
          <CardExpiryElement
            style={{
              base: {
                color: '#B3B5C8',
                '::placeholder': {
                  color: '#B3B5C8'
                }
              }
            }}
          />
        </Styled.CardFormField>
        <Styled.CardFormField id="card-cvc" text={translate(lang.cvc)} width="47%">
          <CardCVCElement
            style={{
              base: {
                color: '#B3B5C8',
                '::placeholder': {
                  color: '#B3B5C8'
                }
              }
            }}
          />
        </Styled.CardFormField>
      </Row>
    </>
  );
});
