import * as R from 'ramda';
import { createSelector } from 'reselect';

import { getModuleSelector } from 'src/redux/utils';
import { USER_ROLES } from 'src/constants';

const organizationSelector = getModuleSelector('organization');
export const userDataSelector = getModuleSelector('userData');

export const orgIdSelector = createSelector(userDataSelector, R.pathOr('', ['organizations', '0', 'orgId']));

export const userIdSelector = createSelector(userDataSelector, R.prop('userId'));

const userMetadataSelector = createSelector(userDataSelector, R.propOr({}, 'userMetadata'));

const currentUserSelector = createSelector(organizationSelector, userDataSelector, ({ data }, { userId }) =>
  data.users ? R.find(R.propEq('id', userId))(data.users) || R.head(data.users) : {}
);

const roleSelector = createSelector(currentUserSelector, R.prop('role'));

export const userNameInitialSelector = createSelector(currentUserSelector, currentUser => {
  const { firstName = '', lastName = '' } = currentUser;
  return R.toUpper(`${R.head(firstName)}${R.head(lastName)}`);
});

export const userNameSelector = createSelector(currentUserSelector, currentUser => {
  const { firstName, lastName } = currentUser;
  return `${[firstName, lastName].filter(item => item).join(' ')}`;
});

export const userInfoSelector = createSelector(
  currentUserSelector,
  userIdSelector,
  userMetadataSelector,
  (currentUser, userId, userMetadata) => ({
    userId,
    userRole: currentUser.role,
    ...userMetadata
  })
);

export const isPartnerAdminSelector = createSelector(roleSelector, R.equals(USER_ROLES.PARTNER_ADMIN));

export const userEmailSelector = createSelector(userInfoSelector, R.prop('email'));
