// @flow
import { core } from '@accordo-feed/micro-frontends-utils';

import { API, resolveApiEndpoint, requestHandler, handleOnlyAuthError } from 'src/utils';

/*****************
 *   API CALLS   *
 *****************/

const azureDashboardDataApi = resolveApiEndpoint(API.GET_AZURE_DASHBOARD);

export const getAzureDashboardData = (orgId: string): Promise<any> =>
  requestHandler({
    url: core.replaceAll(azureDashboardDataApi, { orgId }),
    handleError: handleOnlyAuthError
  });

export const getAzureCustomersData = (orgIds: Array<string>): Promise<any> => {
  const services = orgIds.map(id => getAzureDashboardData(id));
  return Promise.all(services);
};

export const getAzurePartnerDashboardData = (partnerId: string): Promise<any> =>
  requestHandler({
    url: core.replaceAll(azureDashboardDataApi, { partnerId }),
    handleError: handleOnlyAuthError
  });

export const getPartnersDashboardData = (partnerIds: Array<string>): Promise<any> => {
  const services = partnerIds.map(id => getAzurePartnerDashboardData(id));
  return Promise.all(services);
};
