import styled, { css } from 'styled-components';
import { FilterList } from '@material-ui/icons';
import { Link } from 'react-router';
import { constants, mixins, Icon } from '@accordo-feed/aco-styled-components';

import GrowthIndicator from 'src/components/growthIndicator';
import { appTextColor } from 'src/App.styled';
import { WidgetCardWrapper } from 'src/components/widget/widget.styled';

const { colors } = constants;

export const TableWrapper = styled(WidgetCardWrapper)`
  border-radius: 0;
  box-shadow: none;
`;

export const CustomerName = styled.div`
  text-align: left;
  min-width: 120px;
  word-wrap: break-word;
`;

export const LinkName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${appTextColor};
  text-decoration: underline;
  max-width: 210px;

  &.aco-light-red {
    color: #d8363d;
  }

  &.aco-text-left {
    text-align: left;
  }
`;

export const VisitLink = styled(Link)`
  text-decoration: none;
  display: inline-block;

  &.aco-text-left {
    text-align: left;
  }
`;

export const LastUpdated = styled.div`
  text-align: center;
`;

const buttonHeight = '40px';

export const FilterIcon = styled(Icon).attrs({
  element: FilterList,
  color: colors.lightGrey
})`
  && {
    margin: 0 10px;
  }
`;

export const FilterButton = styled.span`
  ${mixins.verticalLineMiddle(buttonHeight)};
  ${mixins.flexVerticalCenter('inline')};
  color: ${appTextColor};
  padding: 0 0 0 15px;
  border: 1px solid ${colors.lightGrey};
  cursor: pointer;
  ${props =>
    props.isOpen &&
    css`
      color: ${colors.blue};
      border-color: ${colors.blue};
      ${FilterIcon} {
        fill: ${colors.blue};
      }
    `}

  ${props =>
    props.hasFilters &&
    css`
      ${FilterIcon} {
        fill: ${colors.blue};
      }
    `}
`;

export const ClearFiltersButton = styled.span`
  ${mixins.verticalLineMiddle(buttonHeight)};
  display: inline-block;
  color: ${colors.darkShadeGrey};
  margin-left: 20px;
  cursor: pointer;
`;

export const TableGrowthIndicator = styled(GrowthIndicator)`
  > span {
    margin-left: 12px;
  }

  .anticon {
    margin-right: 0;
  }
`;

export const GrowthContentWrapper = styled.span`
  white-space: nowrap;
`;
