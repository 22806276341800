// @flow

import { createAction } from 'redux-actions';
import { redux } from '@accordo-feed/micro-frontends';

import type { Action } from 'src/redux/types';
import { wrapWithModule } from 'src/redux/utils';
import { APP_THEME, APP_VIEWS } from 'src/constants';

const { setState } = redux;

/*************
 *   TYPES   *
 *************/

type AppStatusType = {
  view: string,
  theme: string
};

/*********************
 *   INITIAL STATE   *
 *********************/

const initialState: AppStatusType = {
  view: APP_VIEWS.SHELL,
  theme: APP_THEME.LIGHT
};

/***************
 *   ACTIONS   *
 ***************/

const wrapWithNamespace = wrapWithModule('modules/appStatus');
const SET_APP_VIEW = wrapWithNamespace('SET_APP_VIEW');
const SET_APP_THEME = wrapWithNamespace('SET_APP_THEME');

export const setAppView = createAction(SET_APP_VIEW);
export const setAppTheme = createAction(SET_APP_THEME);

/***************
 *   REDUCER   *
 ***************/

export default (state: AppStatusType = initialState, action: Action) => {
  const { type, payload } = action;

  const reducer = {
    [SET_APP_VIEW]: setState('view'),
    [SET_APP_THEME]: setState('theme')
  }[type];

  return reducer ? reducer(state, payload) : state;
};
