import styled, { css } from 'styled-components';
import { constants } from '@accordo-feed/aco-styled-components';
import { Button as AntDesignButton } from 'antd';
import { hexToRgb } from 'src/utils';

const { colors, fontSize } = constants;

const variantOptions = {
  primary: {
    backgroundColor: colors.darkCyan,
    color: colors.white,
    hover: colors.white,
    borderWidth: '1px',
    borderColor: colors.darkCyan,
    padding: '0 20px'
  },
  outline: {
    backgroundColor: 'none',
    color: colors.darkCyan,
    hover: colors.darkCyan,
    borderWidth: '1px',
    borderColor: colors.darkCyan,
    padding: '0 20px'
  },
  danger: {
    backgroundColor: 'none',
    color: colors.red,
    hover: colors.red,
    borderWidth: '0px',
    borderColor: 'transparent',
    padding: 0
  },
  dangerOutline: {
    backgroundColor: 'none',
    color: colors.red,
    hover: colors.red,
    borderWidth: '1px',
    borderColor: colors.red,
    padding: '0 20px'
  }
};

export const Button = styled(AntDesignButton)`
  text-align: center;
  font-size: ${fontSize.xSmall};
  border-radius: 4px;
  margin: ${props => (props.margin ? props.margin : '0')};
  ${({ variant }) =>
    variant &&
    variantOptions[variant] &&
    css`
      background: ${variantOptions[variant].backgroundColor !== 'none'
        ? `rgba(${hexToRgb(variantOptions[variant].backgroundColor)},  0.8)`
        : 'none'};
      color: ${variantOptions[variant].color};
      border-color: rgba(${hexToRgb(variantOptions[variant].borderColor)}, 0.8);
      border-width: ${variantOptions[variant].borderWidth};
      padding: ${variantOptions[variant].padding};
      &:hover,
      &:focus,
      &:active {
        background: ${variantOptions[variant].backgroundColor !== 'none'
          ? variantOptions[variant].backgroundColor
          : 'none'};
        color: ${variantOptions[variant].color};
        border-color: ${variantOptions[variant].borderColor};
        color: ${variantOptions[variant].hover};
      }
      &:disabled,
      &[disabled] {
        opacity: 0.5;
        cursor: not-allowed;
        background: ${variantOptions[variant].backgroundColor};
        color: ${variantOptions[variant].color};
        border-color: ${variantOptions[variant].borderColor};
        border-width: ${variantOptions[variant].borderWidth};
        padding: ${variantOptions[variant].padding};
        &:hover {
          background: ${variantOptions[variant].backgroundColor};
          color: ${variantOptions[variant].color};
          border-color: ${variantOptions[variant].borderColor};
          border-width: ${variantOptions[variant].borderWidth};
          padding: ${variantOptions[variant].padding};
        }
      }
    `}
`;
