export default {
  summary: {
    totalCustomersGrowthPercentage: 538,
    monthlyActiveUsersGrowthPercentage: 134,
    totalLicensesGrowthPercentage: 95
  },
  masterList: [
    {
      name: 'Green Valley Medical',
      activeUsersGrowthPercentage: 5,
      totalLicensesGrowthPercentage: 8,
      totalMonthlySpendGrowthPercentage: 3,
      unassignedLicensesPercentage: 4.3
    },
    {
      name: 'Cyberdyne Systems',
      activeUsersGrowthPercentage: 0,
      totalLicensesGrowthPercentage: 0,
      totalMonthlySpendGrowthPercentage: 0,
      unassignedLicensesPercentage: 0
    },
    {
      name: 'United Fried Chicken',
      activeUsersGrowthPercentage: -1.02,
      totalLicensesGrowthPercentage: 0,
      totalMonthlySpendGrowthPercentage: 0,
      unassignedLicensesPercentage: 1.9
    },
    {
      name: 'Wayne Enterprises',
      activeUsersGrowthPercentage: 0,
      totalLicensesGrowthPercentage: 0,
      totalMonthlySpendGrowthPercentage: 0,
      unassignedLicensesPercentage: 0
    },
    {
      name: 'Mr. Sparkle',
      activeUsersGrowthPercentage: 0,
      totalLicensesGrowthPercentage: 0,
      totalMonthlySpendGrowthPercentage: 0,
      unassignedLicensesPercentage: 0
    },
    {
      name: 'Gekko & Co',
      activeUsersGrowthPercentage: 0,
      totalLicensesGrowthPercentage: 0,
      totalMonthlySpendGrowthPercentage: 0,
      unassignedLicensesPercentage: 0
    },
    {
      name: 'Ankh-Sto Associates',
      activeUsersGrowthPercentage: 0,
      totalLicensesGrowthPercentage: 0,
      totalMonthlySpendGrowthPercentage: 0,
      unassignedLicensesPercentage: 0
    },
    {
      name: 'Globo-Chem',
      activeUsersGrowthPercentage: 0,
      totalLicensesGrowthPercentage: 0,
      totalMonthlySpendGrowthPercentage: 0,
      unassignedLicensesPercentage: 0
    },
    {
      name: 'MarketStar',
      activeUsersGrowthPercentage: 0,
      totalLicensesGrowthPercentage: 0,
      totalMonthlySpendGrowthPercentage: 0,
      unassignedLicensesPercentage: 0
    },
    {
      name: 'Stark Industries',
      activeUsersGrowthPercentage: 0,
      totalLicensesGrowthPercentage: 0,
      totalMonthlySpendGrowthPercentage: 0,
      unassignedLicensesPercentage: 0
    },
    {
      name: 'LuthorCorp',
      activeUsersGrowthPercentage: 0,
      totalLicensesGrowthPercentage: 0,
      totalMonthlySpendGrowthPercentage: 0,
      unassignedLicensesPercentage: 0
    },
    {
      name: 'Bluth Company',
      activeUsersGrowthPercentage: 0,
      totalLicensesGrowthPercentage: 0,
      totalMonthlySpendGrowthPercentage: 0,
      unassignedLicensesPercentage: 0
    },
    {
      name: 'LexCorp',
      activeUsersGrowthPercentage: 14.29,
      totalLicensesGrowthPercentage: -11.11,
      totalMonthlySpendGrowthPercentage: -12.02,
      unassignedLicensesPercentage: 0
    },
    {
      name: 'Massive Dynamic',
      activeUsersGrowthPercentage: 0,
      totalLicensesGrowthPercentage: 0,
      totalMonthlySpendGrowthPercentage: 0,
      unassignedLicensesPercentage: 0
    },
    {
      name: 'Praxis Corporation',
      activeUsersGrowthPercentage: 0,
      totalLicensesGrowthPercentage: 0,
      totalMonthlySpendGrowthPercentage: 0,
      unassignedLicensesPercentage: 0
    },
    {
      name: 'Transworld Consortium',
      activeUsersGrowthPercentage: 2.01,
      totalLicensesGrowthPercentage: 1.36,
      totalMonthlySpendGrowthPercentage: 0.67,
      unassignedLicensesPercentage: 0.9
    },
    {
      name: 'Umbrella Corporation',
      activeUsersGrowthPercentage: 0,
      totalLicensesGrowthPercentage: 0,
      totalMonthlySpendGrowthPercentage: 0,
      unassignedLicensesPercentage: 12.5
    },
    {
      name: 'Galaxy Corp',
      activeUsersGrowthPercentage: 0,
      totalLicensesGrowthPercentage: 0,
      totalMonthlySpendGrowthPercentage: 0,
      unassignedLicensesPercentage: 0
    },
    {
      name: 'Globex Corporation',
      activeUsersGrowthPercentage: 0,
      totalLicensesGrowthPercentage: 0,
      totalMonthlySpendGrowthPercentage: 0,
      unassignedLicensesPercentage: 50
    },
    {
      name: 'The New York Inquirer',
      activeUsersGrowthPercentage: 0,
      totalLicensesGrowthPercentage: 0,
      totalMonthlySpendGrowthPercentage: 0,
      unassignedLicensesPercentage: 0
    },
    {
      name: 'Bluecat Services',
      activeUsersGrowthPercentage: 20,
      totalLicensesGrowthPercentage: 20,
      totalMonthlySpendGrowthPercentage: 7.41,
      unassignedLicensesPercentage: 0
    },
    {
      name: 'Good Burger',
      activeUsersGrowthPercentage: 15.38,
      totalLicensesGrowthPercentage: 14.29,
      totalMonthlySpendGrowthPercentage: 18.48,
      unassignedLicensesPercentage: 6.3
    },
    {
      name: 'City Migrate Services',
      activeUsersGrowthPercentage: 3.85,
      totalLicensesGrowthPercentage: 0,
      totalMonthlySpendGrowthPercentage: 0,
      unassignedLicensesPercentage: 0
    },
    {
      name: 'Soylent Corp',
      activeUsersGrowthPercentage: 3.7,
      totalLicensesGrowthPercentage: 3.41,
      totalMonthlySpendGrowthPercentage: 3.37,
      unassignedLicensesPercentage: 0
    },
    {
      name: 'Sto Plains Holdings',
      activeUsersGrowthPercentage: 0,
      totalLicensesGrowthPercentage: 0,
      totalMonthlySpendGrowthPercentage: 0,
      unassignedLicensesPercentage: 0
    },
    {
      name: 'Cheers',
      activeUsersGrowthPercentage: 0,
      totalLicensesGrowthPercentage: 0,
      totalMonthlySpendGrowthPercentage: 0,
      unassignedLicensesPercentage: 0
    },
    {
      name: 'Acme Corporation',
      activeUsersGrowthPercentage: -19,
      totalLicensesGrowthPercentage: -18,
      totalMonthlySpendGrowthPercentage: -20,
      unassignedLicensesPercentage: 0.8
    },
    {
      name: 'The Legitimate Business Club',
      activeUsersGrowthPercentage: 3.45,
      totalLicensesGrowthPercentage: -6.25,
      totalMonthlySpendGrowthPercentage: -10.78,
      unassignedLicensesPercentage: 0
    },
    {
      name: 'Hooli',
      activeUsersGrowthPercentage: 0,
      totalLicensesGrowthPercentage: 0,
      totalMonthlySpendGrowthPercentage: 0,
      unassignedLicensesPercentage: 20
    },
    {
      name: 'Extensive Enterprise',
      activeUsersGrowthPercentage: -5.41,
      totalLicensesGrowthPercentage: -5.41,
      totalMonthlySpendGrowthPercentage: -7.66,
      unassignedLicensesPercentage: 0
    },
    {
      name: 'Sombra Corporation',
      activeUsersGrowthPercentage: -7.14,
      totalLicensesGrowthPercentage: -6.67,
      totalMonthlySpendGrowthPercentage: -4.39,
      unassignedLicensesPercentage: 0.8
    },
    {
      name: 'Virtucon',
      activeUsersGrowthPercentage: 7,
      totalLicensesGrowthPercentage: 8,
      totalMonthlySpendGrowthPercentage: 8,
      unassignedLicensesPercentage: 0
    },
    {
      name: 'Skyforce',
      activeUsersGrowthPercentage: -4,
      totalLicensesGrowthPercentage: -4,
      totalMonthlySpendGrowthPercentage: -1.48,
      unassignedLicensesPercentage: 0
    },
    {
      name: 'Olivia Pope & Associates',
      activeUsersGrowthPercentage: 12.12,
      totalLicensesGrowthPercentage: 12.12,
      totalMonthlySpendGrowthPercentage: 7.48,
      unassignedLicensesPercentage: 0
    },
    {
      name: 'Initech',
      activeUsersGrowthPercentage: 0,
      totalLicensesGrowthPercentage: 0,
      totalMonthlySpendGrowthPercentage: 0,
      unassignedLicensesPercentage: 0
    },
    {
      name: 'Vehement Capital Partners',
      activeUsersGrowthPercentage: 0,
      totalLicensesGrowthPercentage: 0,
      totalMonthlySpendGrowthPercentage: 0,
      unassignedLicensesPercentage: 12.5
    },
    {
      name: 'Bubba Gump',
      activeUsersGrowthPercentage: 0,
      totalLicensesGrowthPercentage: 0,
      totalMonthlySpendGrowthPercentage: 0,
      unassignedLicensesPercentage: 5.6
    },
    {
      name: 'Duff Beer',
      activeUsersGrowthPercentage: 0,
      totalLicensesGrowthPercentage: 0,
      totalMonthlySpendGrowthPercentage: 0,
      unassignedLicensesPercentage: 0
    },
    {
      name: 'Krusty Krab',
      activeUsersGrowthPercentage: 0,
      totalLicensesGrowthPercentage: 0,
      totalMonthlySpendGrowthPercentage: 0,
      unassignedLicensesPercentage: 16.7
    },
    {
      name: 'Globex Corporation',
      activeUsersGrowthPercentage: 16.67,
      totalLicensesGrowthPercentage: 16.67,
      totalMonthlySpendGrowthPercentage: 18.52,
      unassignedLicensesPercentage: 0
    },
    {
      name: 'Brent Eastwood LLC',
      activeUsersGrowthPercentage: 0,
      totalLicensesGrowthPercentage: 0,
      totalMonthlySpendGrowthPercentage: 0,
      unassignedLicensesPercentage: 0
    },
    {
      name: 'Aviato US',
      activeUsersGrowthPercentage: 2.67,
      totalLicensesGrowthPercentage: 2.6,
      totalMonthlySpendGrowthPercentage: 2.84,
      unassignedLicensesPercentage: 2.5
    },
    {
      name: 'Allied Biscuit',
      activeUsersGrowthPercentage: 4.35,
      totalLicensesGrowthPercentage: 0,
      totalMonthlySpendGrowthPercentage: 0,
      unassignedLicensesPercentage: 0
    },
    {
      name: 'Sterling Cooper',
      activeUsersGrowthPercentage: 0,
      totalLicensesGrowthPercentage: 0,
      totalMonthlySpendGrowthPercentage: 0,
      unassignedLicensesPercentage: 0
    },
    {
      name: 'Genco Pura Olive Oil Company',
      activeUsersGrowthPercentage: 0,
      totalLicensesGrowthPercentage: 0,
      totalMonthlySpendGrowthPercentage: 0,
      unassignedLicensesPercentage: 0
    },
    {
      name: 'Wonka Industries',
      activeUsersGrowthPercentage: 0,
      totalLicensesGrowthPercentage: 0,
      totalMonthlySpendGrowthPercentage: 0,
      unassignedLicensesPercentage: 0
    },
    {
      name: 'Osato Chemicals',
      activeUsersGrowthPercentage: -4.35,
      totalLicensesGrowthPercentage: -4.35,
      totalMonthlySpendGrowthPercentage: -4.5,
      unassignedLicensesPercentage: 0
    },
    {
      name: 'North Central Positronics',
      activeUsersGrowthPercentage: 0,
      totalLicensesGrowthPercentage: 0,
      totalMonthlySpendGrowthPercentage: 0,
      unassignedLicensesPercentage: 0
    },
    {
      name: 'Universal Export',
      activeUsersGrowthPercentage: 0,
      totalLicensesGrowthPercentage: 0.32,
      totalMonthlySpendGrowthPercentage: 0.08,
      unassignedLicensesPercentage: 80.4
    },
    {
      name: 'Datamatiq',
      activeUsersGrowthPercentage: 0,
      totalLicensesGrowthPercentage: 0,
      totalMonthlySpendGrowthPercentage: 0,
      unassignedLicensesPercentage: 0
    },
    {
      name: "Ollivander's Wand Shop",
      activeUsersGrowthPercentage: 0,
      totalLicensesGrowthPercentage: 0,
      totalMonthlySpendGrowthPercentage: 0,
      unassignedLicensesPercentage: 0
    }
  ],
  customerGrowth: {
    positive: {
      activeUsers: [
        {
          clientName: 'Virtucon',
          growthPercentage: 19.69,
          data: [
            {
              value: 132
            },
            {
              value: 132
            },
            {
              value: 134
            },
            {
              value: 133
            },
            {
              value: 133
            },
            {
              value: 132
            },
            {
              value: 136
            },
            {
              value: 138
            },
            {
              value: 139
            },
            {
              value: 135
            },
            {
              value: 135
            },
            {
              value: 136
            },
            {
              value: 137
            },
            {
              value: 138
            },
            {
              value: 138
            },
            {
              value: 139
            },
            {
              value: 139
            },
            {
              value: 140
            },
            {
              value: 140
            },
            {
              value: 141
            },
            {
              value: 141
            },
            {
              value: 141
            },
            {
              value: 141
            },
            {
              value: 142
            },
            {
              value: 142
            },
            {
              value: 143
            },
            {
              value: 143
            },
            {
              value: 143
            },
            {
              value: 144
            },
            {
              value: 144
            },
            {
              value: 144
            },
            {
              value: 145
            },
            {
              value: 145
            },
            {
              value: 145
            },
            {
              value: 145
            },
            {
              value: 145
            },
            {
              value: 145
            },
            {
              value: 145
            },
            {
              value: 146
            },
            {
              value: 146
            },
            {
              value: 147
            },
            {
              value: 147
            },
            {
              value: 149
            },
            {
              value: 147
            },
            {
              value: 147
            },
            {
              value: 146
            },
            {
              value: 145
            },
            {
              value: 145
            },
            {
              value: 146
            },
            {
              value: 146
            },
            {
              value: 147
            },
            {
              value: 147
            },
            {
              value: 148
            },
            {
              value: 149
            },
            {
              value: 150
            },
            {
              value: 145
            },
            {
              value: 145
            },
            {
              value: 146
            },
            {
              value: 147
            },
            {
              value: 148
            },
            {
              value: 149
            },
            {
              value: 150
            },
            {
              value: 150
            },
            {
              value: 150
            },
            {
              value: 151
            },
            {
              value: 151
            },
            {
              value: 151
            },
            {
              value: 151
            },
            {
              value: 151
            },
            {
              value: 152
            },
            {
              value: 152
            },
            {
              value: 153
            },
            {
              value: 153
            },
            {
              value: 154
            },
            {
              value: 154
            },
            {
              value: 153
            },
            {
              value: 153
            },
            {
              value: 152
            },
            {
              value: 152
            },
            {
              value: 153
            },
            {
              value: 153
            },
            {
              value: 154
            },
            {
              value: 155
            },
            {
              value: 155
            },
            {
              value: 156
            },
            {
              value: 156
            },
            {
              value: 157
            },
            {
              value: 159
            },
            {
              value: 160
            },
            {
              value: 159
            },
            {
              value: 159
            },
            {
              value: 158
            }
          ],
          prediction: []
        },
        {
          clientName: 'Transworld Consortium',
          growthPercentage: 1,
          data: [
            {
              value: 201
            },
            {
              value: 201
            },
            {
              value: 202
            },
            {
              value: 202
            },
            {
              value: 202
            },
            {
              value: 202
            },
            {
              value: 201
            },
            {
              value: 201
            },
            {
              value: 201
            },
            {
              value: 201
            },
            {
              value: 201
            },
            {
              value: 201
            },
            {
              value: 201
            },
            {
              value: 158
            },
            {
              value: 201
            },
            {
              value: 201
            },
            {
              value: 201
            },
            {
              value: 201
            },
            {
              value: 201
            },
            {
              value: 201
            },
            {
              value: 201
            },
            {
              value: 201
            },
            {
              value: 201
            },
            {
              value: 201
            },
            {
              value: 201
            },
            {
              value: 201
            },
            {
              value: 201
            },
            {
              value: 201
            },
            {
              value: 201
            },
            {
              value: 200
            },
            {
              value: 200
            },
            {
              value: 201
            },
            {
              value: 202
            },
            {
              value: 202
            },
            {
              value: 202
            },
            {
              value: 203
            },
            {
              value: 203
            },
            {
              value: 203
            },
            {
              value: 203
            },
            {
              value: 203
            },
            {
              value: 203
            },
            {
              value: 203
            },
            {
              value: 203
            },
            {
              value: 203
            },
            {
              value: 203
            },
            {
              value: 203
            },
            {
              value: 203
            },
            {
              value: 203
            },
            {
              value: 203
            },
            {
              value: 202
            },
            {
              value: 200
            },
            {
              value: 200
            },
            {
              value: 200
            },
            {
              value: 200
            },
            {
              value: 200
            },
            {
              value: 200
            },
            {
              value: 200
            },
            {
              value: 199
            },
            {
              value: 199
            },
            {
              value: 199
            },
            {
              value: 199
            },
            {
              value: 199
            },
            {
              value: 200
            },
            {
              value: 200
            },
            {
              value: 200
            },
            {
              value: 200
            },
            {
              value: 200
            },
            {
              value: 200
            },
            {
              value: 201
            },
            {
              value: 202
            },
            {
              value: 202
            },
            {
              value: 202
            },
            {
              value: 202
            },
            {
              value: 201
            },
            {
              value: 201
            },
            {
              value: 202
            },
            {
              value: 202
            },
            {
              value: 204
            },
            {
              value: 204
            },
            {
              value: 204
            },
            {
              value: 203
            },
            {
              value: 203
            },
            {
              value: 203
            },
            {
              value: 203
            },
            {
              value: 203
            },
            {
              value: 203
            },
            {
              value: 203
            },
            {
              value: 203
            },
            {
              value: 203
            },
            {
              value: 203
            },
            {
              value: 203
            },
            {
              value: 203
            }
          ],
          prediction: []
        },
        {
          clientName: 'Sto Plains Holdings',
          growthPercentage: 0,
          data: [
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            }
          ],
          prediction: []
        },
        {
          clientName: 'Green Valley Medical',
          growthPercentage: 0,
          data: [
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 42
            },
            {
              value: 42
            },
            {
              value: 42
            },
            {
              value: 42
            },
            {
              value: 40
            },
            {
              value: 40
            },
            {
              value: 40
            },
            {
              value: 40
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 47
            },
            {
              value: 47
            },
            {
              value: 47
            },
            {
              value: 50
            },
            {
              value: 50
            },
            {
              value: 50
            },
            {
              value: 50
            },
            {
              value: 50
            },
            {
              value: 50
            },
            {
              value: 50
            },
            {
              value: 48
            },
            {
              value: 48
            },
            {
              value: 48
            },
            {
              value: 48
            },
            {
              value: 48
            },
            {
              value: 48
            },
            {
              value: 48
            },
            {
              value: 48
            },
            {
              value: 48
            },
            {
              value: 48
            },
            {
              value: 48
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            },
            {
              value: 45
            }
          ],
          prediction: []
        }
      ],
      totalLicenses: [
        {
          clientName: 'Universal Export',
          growthPercentage: 395.24,
          data: [
            {
              value: 63
            },
            {
              value: 63
            },
            {
              value: 65
            },
            {
              value: 65
            },
            {
              value: 65
            },
            {
              value: 65
            },
            {
              value: 65
            },
            {
              value: 65
            },
            {
              value: 65
            },
            {
              value: 65
            },
            {
              value: 65
            },
            {
              value: 65
            },
            {
              value: 65
            },
            {
              value: 65
            },
            {
              value: 65
            },
            {
              value: 65
            },
            {
              value: 65
            },
            {
              value: 65
            },
            {
              value: 65
            },
            {
              value: 65
            },
            {
              value: 315
            },
            {
              value: 315
            },
            {
              value: 315
            },
            {
              value: 315
            },
            {
              value: 315
            },
            {
              value: 315
            },
            {
              value: 315
            },
            {
              value: 315
            },
            {
              value: 315
            },
            {
              value: 315
            },
            {
              value: 315
            },
            {
              value: 315
            },
            {
              value: 315
            },
            {
              value: 315
            },
            {
              value: 340
            },
            {
              value: 340
            },
            {
              value: 334
            },
            {
              value: 309
            },
            {
              value: 310
            },
            {
              value: 310
            },
            {
              value: 310
            },
            {
              value: 309
            },
            {
              value: 309
            },
            {
              value: 309
            },
            {
              value: 309
            },
            {
              value: 310
            },
            {
              value: 310
            },
            {
              value: 310
            },
            {
              value: 309
            },
            {
              value: 309
            },
            {
              value: 309
            },
            {
              value: 309
            },
            {
              value: 309
            },
            {
              value: 309
            },
            {
              value: 309
            },
            {
              value: 311
            },
            {
              value: 311
            },
            {
              value: 311
            },
            {
              value: 311
            },
            {
              value: 311
            },
            {
              value: 311
            },
            {
              value: 311
            },
            {
              value: 311
            },
            {
              value: 311
            },
            {
              value: 311
            },
            {
              value: 311
            },
            {
              value: 311
            },
            {
              value: 311
            },
            {
              value: 311
            },
            {
              value: 310
            },
            {
              value: 311
            },
            {
              value: 311
            },
            {
              value: 311
            },
            {
              value: 311
            },
            {
              value: 311
            },
            {
              value: 311
            },
            {
              value: 311
            },
            {
              value: 311
            },
            {
              value: 311
            },
            {
              value: 311
            },
            {
              value: 311
            },
            {
              value: 311
            },
            {
              value: 311
            },
            {
              value: 311
            },
            {
              value: 311
            },
            {
              value: 311
            },
            {
              value: 311
            },
            {
              value: 312
            },
            {
              value: 312
            },
            {
              value: 312
            },
            {
              value: 312
            },
            {
              value: 312
            }
          ],
          prediction: []
        },
        {
          clientName: 'Transworld Consortium',
          growthPercentage: 2.29,
          data: [
            {
              value: 218
            },
            {
              value: 218
            },
            {
              value: 219
            },
            {
              value: 219
            },
            {
              value: 219
            },
            {
              value: 219
            },
            {
              value: 221
            },
            {
              value: 221
            },
            {
              value: 221
            },
            {
              value: 221
            },
            {
              value: 221
            },
            {
              value: 221
            },
            {
              value: 221
            },
            {
              value: 221
            },
            {
              value: 221
            },
            {
              value: 221
            },
            {
              value: 221
            },
            {
              value: 221
            },
            {
              value: 221
            },
            {
              value: 221
            },
            {
              value: 221
            },
            {
              value: 221
            },
            {
              value: 221
            },
            {
              value: 221
            },
            {
              value: 221
            },
            {
              value: 221
            },
            {
              value: 221
            },
            {
              value: 220
            },
            {
              value: 220
            },
            {
              value: 220
            },
            {
              value: 220
            },
            {
              value: 220
            },
            {
              value: 221
            },
            {
              value: 221
            },
            {
              value: 221
            },
            {
              value: 222
            },
            {
              value: 222
            },
            {
              value: 222
            },
            {
              value: 222
            },
            {
              value: 222
            },
            {
              value: 222
            },
            {
              value: 222
            },
            {
              value: 222
            },
            {
              value: 222
            },
            {
              value: 222
            },
            {
              value: 222
            },
            {
              value: 222
            },
            {
              value: 222
            },
            {
              value: 221
            },
            {
              value: 220
            },
            {
              value: 220
            },
            {
              value: 220
            },
            {
              value: 220
            },
            {
              value: 220
            },
            {
              value: 220
            },
            {
              value: 220
            },
            {
              value: 220
            },
            {
              value: 220
            },
            {
              value: 220
            },
            {
              value: 220
            },
            {
              value: 220
            },
            {
              value: 220
            },
            {
              value: 220
            },
            {
              value: 220
            },
            {
              value: 220
            },
            {
              value: 220
            },
            {
              value: 220
            },
            {
              value: 220
            },
            {
              value: 220
            },
            {
              value: 221
            },
            {
              value: 221
            },
            {
              value: 221
            },
            {
              value: 221
            },
            {
              value: 221
            },
            {
              value: 221
            },
            {
              value: 222
            },
            {
              value: 222
            },
            {
              value: 224
            },
            {
              value: 224
            },
            {
              value: 224
            },
            {
              value: 224
            },
            {
              value: 224
            },
            {
              value: 224
            },
            {
              value: 224
            },
            {
              value: 224
            },
            {
              value: 223
            },
            {
              value: 223
            },
            {
              value: 223
            },
            {
              value: 223
            },
            {
              value: 223
            },
            {
              value: 223
            },
            {
              value: 223
            }
          ],
          prediction: []
        },
        {
          clientName: 'Virtucon',
          growthPercentage: 24.18,
          data: [
            { value: 182 },
            { value: 183 },
            { value: 182 },
            { value: 181 },
            { value: 182 },
            { value: 183 },
            { value: 184 },
            { value: 184 },
            { value: 184 },
            { value: 185 },
            { value: 186 },
            { value: 187 },
            { value: 188 },
            { value: 189 },
            { value: 190 },
            { value: 190 },
            { value: 191 },
            { value: 191 },
            { value: 191 },
            { value: 190 },
            { value: 191 },
            { value: 192 },
            { value: 191 },
            { value: 190 },
            { value: 191 },
            { value: 192 },
            { value: 193 },
            { value: 193 },
            { value: 194 },
            { value: 195 },
            { value: 195 },
            { value: 196 },
            { value: 197 },
            { value: 197 },
            { value: 198 },
            { value: 197 },
            { value: 198 },
            { value: 199 },
            { value: 200 },
            { value: 200 },
            { value: 201 },
            { value: 201 },
            { value: 201 },
            { value: 202 },
            { value: 202 },
            { value: 203 },
            { value: 204 },
            { value: 205 },
            { value: 206 },
            { value: 207 },
            { value: 208 },
            { value: 209 },
            { value: 210 },
            { value: 210 },
            { value: 209 },
            { value: 209 },
            { value: 210 },
            { value: 211 },
            { value: 211 },
            { value: 211 },
            { value: 212 },
            { value: 212 },
            { value: 213 },
            { value: 213 },
            { value: 213 },
            { value: 213 },
            { value: 214 },
            { value: 215 },
            { value: 214 },
            { value: 215 },
            { value: 216 },
            { value: 217 },
            { value: 218 },
            { value: 218 },
            { value: 218 },
            { value: 218 },
            { value: 218 },
            { value: 219 },
            { value: 220 },
            { value: 220 },
            { value: 221 },
            { value: 222 },
            { value: 223 },
            { value: 224 },
            { value: 224 },
            { value: 224 },
            { value: 224 },
            { value: 223 },
            { value: 224 },
            { value: 225 },
            { value: 226 },
            { value: 226 }
          ],
          prediction: []
        },
        {
          clientName: 'Sto Plains Holdings',
          growthPercentage: 0,
          data: [
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            },
            {
              value: 15
            }
          ],
          prediction: []
        }
      ],
      monthlySpend: [
        {
          clientName: 'Universal Export',
          growthPercentage: 536.49,
          data: [
            {
              value: 1138.31
            },
            {
              value: 1138.31
            },
            {
              value: 1156.6100000000001
            },
            {
              value: 1156.6100000000001
            },
            {
              value: 1156.6100000000001
            },
            {
              value: 1156.6100000000001
            },
            {
              value: 1156.6100000000001
            },
            {
              value: 1156.6100000000001
            },
            {
              value: 1156.6100000000001
            },
            {
              value: 1156.6100000000001
            },
            {
              value: 1156.6100000000001
            },
            {
              value: 1156.6100000000001
            },
            {
              value: 1156.6100000000001
            },
            {
              value: 1156
            },
            {
              value: 1156.6100000000001
            },
            {
              value: 1156.6100000000001
            },
            {
              value: 1156.6100000000001
            },
            {
              value: 1156.6100000000001
            },
            {
              value: 1156.6100000000001
            },
            {
              value: 1156.6100000000001
            },
            {
              value: 6799.110000000001
            },
            {
              value: 6799.110000000001
            },
            {
              value: 6799.110000000001
            },
            {
              value: 6799.110000000001
            },
            {
              value: 6799.110000000001
            },
            {
              value: 6799.110000000001
            },
            {
              value: 6799.110000000001
            },
            {
              value: 6799.110000000001
            },
            {
              value: 6799.110000000001
            },
            {
              value: 6799.110000000001
            },
            {
              value: 6799.110000000001
            },
            {
              value: 6812.07
            },
            {
              value: 6812.07
            },
            {
              value: 6812.07
            },
            {
              value: 7126.57
            },
            {
              value: 7126.57
            },
            {
              value: 6780.45
            },
            {
              value: 6465.95
            },
            {
              value: 6478.53
            },
            {
              value: 6478.53
            },
            {
              value: 6478.53
            },
            {
              value: 6448.03
            },
            {
              value: 6448.03
            },
            {
              value: 6448.03
            },
            {
              value: 6448.03
            },
            {
              value: 6460.61
            },
            {
              value: 6460.61
            },
            {
              value: 6460.61
            },
            {
              value: 6454.89
            },
            {
              value: 7189.61
            },
            {
              value: 7189.61
            },
            {
              value: 7189.61
            },
            {
              value: 7189.61
            },
            {
              value: 7189.61
            },
            {
              value: 7189.61
            },
            {
              value: 7239.17
            },
            {
              value: 7239.17
            },
            {
              value: 7239.17
            },
            {
              value: 7239.17
            },
            {
              value: 7239.17
            },
            {
              value: 7239.17
            },
            {
              value: 7239.17
            },
            {
              value: 7239.17
            },
            {
              value: 7239.17
            },
            {
              value: 7239.17
            },
            {
              value: 7239.17
            },
            {
              value: 7239.17
            },
            {
              value: 7239.17
            },
            {
              value: 7239.17
            },
            {
              value: 7208.67
            },
            {
              value: 7214.77
            },
            {
              value: 7214.77
            },
            {
              value: 7214.77
            },
            {
              value: 7214.77
            },
            {
              value: 7214.77
            },
            {
              value: 7214.77
            },
            {
              value: 7214.77
            },
            {
              value: 7214.77
            },
            {
              value: 7214.77
            },
            {
              value: 7214.77
            },
            {
              value: 7214.77
            },
            {
              value: 7214.77
            },
            {
              value: 7214.77
            },
            {
              value: 7214.77
            },
            {
              value: 7214.77
            },
            {
              value: 7214.77
            },
            {
              value: 7214.77
            },
            {
              value: 7245.27
            },
            {
              value: 7245.27
            },
            {
              value: 7245.27
            },
            {
              value: 7245.27
            },
            {
              value: 7245.27
            }
          ],
          prediction: []
        },
        {
          clientName: 'Virtucon',
          growthPercentage: 27.57,
          data: [
            { value: 4280 },
            { value: 4280 },
            { value: 4280 },
            { value: 4320 },
            { value: 4360 },
            { value: 4400 },
            { value: 4380 },
            { value: 4360 },
            { value: 4360 },
            { value: 4400 },
            { value: 4440 },
            { value: 4420 },
            { value: 4420 },
            { value: 4420 },
            { value: 4420 },
            { value: 4420 },
            { value: 4460 },
            { value: 4500 },
            { value: 4500 },
            { value: 4540 },
            { value: 4580 },
            { value: 4560 },
            { value: 4540 },
            { value: 4520 },
            { value: 4560 },
            { value: 4540 },
            { value: 4540 },
            { value: 4580 },
            { value: 4620 },
            { value: 4600 },
            { value: 4580 },
            { value: 4580 },
            { value: 4620 },
            { value: 4600 },
            { value: 4580 },
            { value: 4620 },
            { value: 4660 },
            { value: 4640 },
            { value: 4620 },
            { value: 4660 },
            { value: 4700 },
            { value: 4740 },
            { value: 4780 },
            { value: 4820 },
            { value: 4800 },
            { value: 4800 },
            { value: 4800 },
            { value: 4800 },
            { value: 4840 },
            { value: 4820 },
            { value: 4860 },
            { value: 4900 },
            { value: 4900 },
            { value: 4900 },
            { value: 4940 },
            { value: 4980 },
            { value: 4980 },
            { value: 5020 },
            { value: 5060 },
            { value: 5060 },
            { value: 5040 },
            { value: 5080 },
            { value: 5060 },
            { value: 5040 },
            { value: 5080 },
            { value: 5060 },
            { value: 5100 },
            { value: 5080 },
            { value: 5060 },
            { value: 5060 },
            { value: 5040 },
            { value: 5080 },
            { value: 5120 },
            { value: 5160 },
            { value: 5160 },
            { value: 5200 },
            { value: 5200 },
            { value: 5240 },
            { value: 5280 },
            { value: 5320 },
            { value: 5360 },
            { value: 5360 },
            { value: 5400 },
            { value: 5400 },
            { value: 5440 },
            { value: 5420 },
            { value: 5460 },
            { value: 5440 },
            { value: 5480 },
            { value: 5480 },
            { value: 5460 },
            { value: 5460 }
          ],
          prediction: []
        },
        {
          clientName: 'Transworld Consortium',
          growthPercentage: 1.97,
          data: [
            {
              value: 4402.23
            },
            {
              value: 4402.23
            },
            {
              value: 4421.29
            },
            {
              value: 4421.29
            },
            {
              value: 4421.29
            },
            {
              value: 4421.29
            },
            {
              value: 4459.41
            },
            {
              value: 4459.41
            },
            {
              value: 4459.41
            },
            {
              value: 4459.41
            },
            {
              value: 4459.41
            },
            {
              value: 4459.41
            },
            {
              value: 4459.41
            },
            {
              value: 4459.41
            },
            {
              value: 4459.41
            },
            {
              value: 4459.41
            },
            {
              value: 4459.41
            },
            {
              value: 4459.41
            },
            {
              value: 4459.41
            },
            {
              value: 4459.41
            },
            {
              value: 4459.41
            },
            {
              value: 4459.41
            },
            {
              value: 4459.41
            },
            {
              value: 4459.41
            },
            {
              value: 4459.41
            },
            {
              value: 4459.41
            },
            {
              value: 4459.41
            },
            {
              value: 4440.35
            },
            {
              value: 4440.35
            },
            {
              value: 4440.35
            },
            {
              value: 4440.35
            },
            {
              value: 4440.35
            },
            {
              value: 4470.85
            },
            {
              value: 4470.85
            },
            {
              value: 4470.85
            },
            {
              value: 4489.91
            },
            {
              value: 4489.91
            },
            {
              value: 4489.91
            },
            {
              value: 4489.91
            },
            {
              value: 4489.91
            },
            {
              value: 4489.91
            },
            {
              value: 4489.91
            },
            {
              value: 4489.91
            },
            {
              value: 4489.91
            },
            {
              value: 4489.91
            },
            {
              value: 4489.91
            },
            {
              value: 4489.91
            },
            {
              value: 4489.91
            },
            {
              value: 4470.85
            },
            {
              value: 4459.41
            },
            {
              value: 4459.41
            },
            {
              value: 4459.41
            },
            {
              value: 4459.41
            },
            {
              value: 4459.41
            },
            {
              value: 4459.41
            },
            {
              value: 4459.41
            },
            {
              value: 4459.41
            },
            {
              value: 4459.41
            },
            {
              value: 4459.41
            },
            {
              value: 4459.41
            },
            {
              value: 4459.41
            },
            {
              value: 4459.41
            },
            {
              value: 4459.41
            },
            {
              value: 4459.41
            },
            {
              value: 4459.41
            },
            {
              value: 4459.41
            },
            {
              value: 4459.41
            },
            {
              value: 4459.41
            },
            {
              value: 4459.41
            },
            {
              value: 4462.46
            },
            {
              value: 4462.46
            },
            {
              value: 4462.46
            },
            {
              value: 4462.46
            },
            {
              value: 4462.46
            },
            {
              value: 4462.46
            },
            {
              value: 4481.52
            },
            {
              value: 4481.52
            },
            {
              value: 4508.21
            },
            {
              value: 4508.21
            },
            {
              value: 4508.21
            },
            {
              value: 4508.21
            },
            {
              value: 4508.21
            },
            {
              value: 4508.21
            },
            {
              value: 4508.21
            },
            {
              value: 4508.21
            },
            {
              value: 4489.15
            },
            {
              value: 4489.15
            },
            {
              value: 4489.15
            },
            {
              value: 4489.15
            },
            {
              value: 4489.15
            },
            {
              value: 4489.15
            },
            {
              value: 4489.15
            }
          ],
          prediction: []
        },
        {
          clientName: 'Sto Plains Holdings',
          growthPercentage: 0,
          data: [
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            },
            {
              value: 253.9
            }
          ],
          prediction: []
        }
      ]
    },
    negative: {
      activeUsers: [
        {
          clientName: 'The Legitimate Business Club',
          growthPercentage: -6.25,
          data: [
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 31
            },
            {
              value: 31
            },
            {
              value: 31
            },
            {
              value: 31
            },
            {
              value: 31
            },
            {
              value: 31
            },
            {
              value: 31
            },
            {
              value: 31
            },
            {
              value: 29
            },
            {
              value: 29
            },
            {
              value: 29
            },
            {
              value: 29
            },
            {
              value: 29
            },
            {
              value: 29
            },
            {
              value: 29
            },
            {
              value: 29
            },
            {
              value: 29
            },
            {
              value: 29
            },
            {
              value: 29
            },
            {
              value: 29
            },
            {
              value: 29
            },
            {
              value: 29
            },
            {
              value: 29
            },
            {
              value: 29
            },
            {
              value: 29
            },
            {
              value: 29
            },
            {
              value: 29
            },
            {
              value: 29
            },
            {
              value: 29
            },
            {
              value: 29
            },
            {
              value: 29
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            }
          ],
          prediction: []
        },
        {
          clientName: 'Acme Corporation',
          growthPercentage: -19,
          data: [
            {
              value: 125
            },
            {
              value: 125
            },
            {
              value: 125
            },
            {
              value: 124
            },
            {
              value: 124
            },
            {
              value: 124
            },
            {
              value: 123
            },
            {
              value: 123
            },
            {
              value: 123
            },
            {
              value: 123
            },
            {
              value: 123
            },
            {
              value: 123
            },
            {
              value: 123
            },
            {
              value: 123
            },
            {
              value: 123
            },
            {
              value: 123
            },
            {
              value: 123
            },
            {
              value: 123
            },
            {
              value: 123
            },
            {
              value: 123
            },
            {
              value: 123
            },
            {
              value: 123
            },
            {
              value: 123
            },
            {
              value: 125
            },
            {
              value: 125
            },
            {
              value: 125
            },
            {
              value: 125
            },
            {
              value: 125
            },
            {
              value: 124
            },
            {
              value: 124
            },
            {
              value: 124
            },
            {
              value: 125
            },
            {
              value: 125
            },
            {
              value: 125
            },
            {
              value: 125
            },
            {
              value: 125
            },
            {
              value: 125
            },
            {
              value: 125
            },
            {
              value: 125
            },
            {
              value: 125
            },
            {
              value: 125
            },
            {
              value: 125
            },
            {
              value: 117
            },
            {
              value: 118
            },
            {
              value: 118
            },
            {
              value: 118
            },
            {
              value: 118
            },
            {
              value: 118
            },
            {
              value: 118
            },
            {
              value: 118
            },
            {
              value: 118
            },
            {
              value: 118
            },
            {
              value: 117
            },
            {
              value: 117
            },
            {
              value: 117
            },
            {
              value: 116
            },
            {
              value: 116
            },
            {
              value: 116
            },
            {
              value: 116
            },
            {
              value: 117
            },
            {
              value: 117
            },
            {
              value: 117
            },
            {
              value: 118
            },
            {
              value: 117
            },
            {
              value: 117
            },
            {
              value: 117
            },
            {
              value: 117
            },
            {
              value: 117
            },
            {
              value: 117
            },
            {
              value: 117
            },
            {
              value: 117
            },
            {
              value: 117
            },
            {
              value: 117
            },
            {
              value: 117
            },
            {
              value: 117
            },
            {
              value: 117
            },
            {
              value: 117
            },
            {
              value: 94
            },
            {
              value: 94
            },
            {
              value: 93
            },
            {
              value: 92
            },
            {
              value: 91
            },
            {
              value: 91
            },
            {
              value: 91
            },
            {
              value: 91
            },
            {
              value: 91
            },
            {
              value: 91
            },
            {
              value: 91
            },
            {
              value: 91
            },
            {
              value: 91
            },
            {
              value: 91
            },
            {
              value: 91
            }
          ],
          prediction: []
        },
        {
          clientName: 'Universal Export',
          growthPercentage: -5.08,
          data: [
            {
              value: 59
            },
            {
              value: 59
            },
            {
              value: 61
            },
            {
              value: 61
            },
            {
              value: 61
            },
            {
              value: 61
            },
            {
              value: 61
            },
            {
              value: 61
            },
            {
              value: 61
            },
            {
              value: 61
            },
            {
              value: 61
            },
            {
              value: 61
            },
            {
              value: 61
            },
            {
              value: 61
            },
            {
              value: 61
            },
            {
              value: 61
            },
            {
              value: 61
            },
            {
              value: 61
            },
            {
              value: 61
            },
            {
              value: 61
            },
            {
              value: 61
            },
            {
              value: 61
            },
            {
              value: 61
            },
            {
              value: 61
            },
            {
              value: 61
            },
            {
              value: 61
            },
            {
              value: 61
            },
            {
              value: 61
            },
            {
              value: 61
            },
            {
              value: 61
            },
            {
              value: 61
            },
            {
              value: 61
            },
            {
              value: 61
            },
            {
              value: 61
            },
            {
              value: 61
            },
            {
              value: 61
            },
            {
              value: 53
            },
            {
              value: 54
            },
            {
              value: 54
            },
            {
              value: 54
            },
            {
              value: 54
            },
            {
              value: 54
            },
            {
              value: 54
            },
            {
              value: 54
            },
            {
              value: 54
            },
            {
              value: 55
            },
            {
              value: 55
            },
            {
              value: 55
            },
            {
              value: 55
            },
            {
              value: 55
            },
            {
              value: 55
            },
            {
              value: 55
            },
            {
              value: 55
            },
            {
              value: 55
            },
            {
              value: 55
            },
            {
              value: 56
            },
            {
              value: 56
            },
            {
              value: 56
            },
            {
              value: 56
            },
            {
              value: 56
            },
            {
              value: 56
            },
            {
              value: 56
            },
            {
              value: 56
            },
            {
              value: 56
            },
            {
              value: 56
            },
            {
              value: 56
            },
            {
              value: 56
            },
            {
              value: 56
            },
            {
              value: 56
            },
            {
              value: 55
            },
            {
              value: 56
            },
            {
              value: 56
            },
            {
              value: 56
            },
            {
              value: 55
            },
            {
              value: 55
            },
            {
              value: 55
            },
            {
              value: 55
            },
            {
              value: 55
            },
            {
              value: 55
            },
            {
              value: 55
            },
            {
              value: 55
            },
            {
              value: 55
            },
            {
              value: 55
            },
            {
              value: 55
            },
            {
              value: 55
            },
            {
              value: 55
            },
            {
              value: 55
            },
            {
              value: 56
            },
            {
              value: 56
            },
            {
              value: 56
            },
            {
              value: 56
            },
            {
              value: 56
            }
          ],
          prediction: []
        },
        {
          clientName: 'Osato Chemicals',
          growthPercentage: -4.35,
          data: [
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            }
          ],
          prediction: []
        }
      ],
      totalLicenses: [
        {
          clientName: 'The Legitimate Business Club',
          growthPercentage: -6.25,
          data: [
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 33
            },
            {
              value: 33
            },
            {
              value: 33
            },
            {
              value: 33
            },
            {
              value: 33
            },
            {
              value: 33
            },
            {
              value: 33
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 33
            },
            {
              value: 33
            },
            {
              value: 33
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 32
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            },
            {
              value: 30
            }
          ],
          prediction: []
        },
        {
          clientName: 'Acme Corporation',
          growthPercentage: -18,
          data: [
            {
              value: 130
            },
            {
              value: 130
            },
            {
              value: 130
            },
            {
              value: 130
            },
            {
              value: 130
            },
            {
              value: 130
            },
            {
              value: 130
            },
            {
              value: 130
            },
            {
              value: 130
            },
            {
              value: 130
            },
            {
              value: 130
            },
            {
              value: 130
            },
            {
              value: 130
            },
            {
              value: 130
            },
            {
              value: 130
            },
            {
              value: 130
            },
            {
              value: 130
            },
            {
              value: 130
            },
            {
              value: 130
            },
            {
              value: 130
            },
            {
              value: 130
            },
            {
              value: 130
            },
            {
              value: 130
            },
            {
              value: 131
            },
            {
              value: 131
            },
            {
              value: 131
            },
            {
              value: 131
            },
            {
              value: 131
            },
            {
              value: 131
            },
            {
              value: 132
            },
            {
              value: 132
            },
            {
              value: 131
            },
            {
              value: 131
            },
            {
              value: 131
            },
            {
              value: 131
            },
            {
              value: 131
            },
            {
              value: 131
            },
            {
              value: 131
            },
            {
              value: 131
            },
            {
              value: 131
            },
            {
              value: 131
            },
            {
              value: 131
            },
            {
              value: 131
            },
            {
              value: 121
            },
            {
              value: 121
            },
            {
              value: 121
            },
            {
              value: 121
            },
            {
              value: 121
            },
            {
              value: 121
            },
            {
              value: 121
            },
            {
              value: 121
            },
            {
              value: 121
            },
            {
              value: 121
            },
            {
              value: 121
            },
            {
              value: 121
            },
            {
              value: 121
            },
            {
              value: 121
            },
            {
              value: 121
            },
            {
              value: 121
            },
            {
              value: 122
            },
            {
              value: 122
            },
            {
              value: 122
            },
            {
              value: 122
            },
            {
              value: 121
            },
            {
              value: 121
            },
            {
              value: 121
            },
            {
              value: 121
            },
            {
              value: 121
            },
            {
              value: 121
            },
            {
              value: 121
            },
            {
              value: 121
            },
            {
              value: 121
            },
            {
              value: 121
            },
            {
              value: 121
            },
            {
              value: 121
            },
            {
              value: 121
            },
            {
              value: 121
            },
            {
              value: 94
            },
            {
              value: 94
            },
            {
              value: 94
            },
            {
              value: 94
            },
            {
              value: 94
            },
            {
              value: 94
            },
            {
              value: 94
            },
            {
              value: 94
            },
            {
              value: 94
            },
            {
              value: 94
            },
            {
              value: 94
            },
            {
              value: 94
            },
            {
              value: 94
            },
            {
              value: 94
            },
            {
              value: 94
            }
          ],
          prediction: []
        },
        {
          clientName: 'Osato Chemicals',
          growthPercentage: -4.35,
          data: [
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 23
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            },
            {
              value: 22
            }
          ],
          prediction: []
        }
      ],
      monthlySpend: [
        {
          clientName: 'The Legitimate Business Club',
          growthPercentage: -14.29,
          data: [
            {
              value: 485.65999999999997
            },
            {
              value: 485.65999999999997
            },
            {
              value: 485.65999999999997
            },
            {
              value: 485.65999999999997
            },
            {
              value: 485.65999999999997
            },
            {
              value: 485.65999999999997
            },
            {
              value: 504.72
            },
            {
              value: 504.72
            },
            {
              value: 504.72
            },
            {
              value: 504.72
            },
            {
              value: 504.72
            },
            {
              value: 504.72
            },
            {
              value: 504.72
            },
            {
              value: 492.52
            },
            {
              value: 492.52
            },
            {
              value: 492.52
            },
            {
              value: 492.52
            },
            {
              value: 492.52
            },
            {
              value: 492.52
            },
            {
              value: 492.52
            },
            {
              value: 492.52
            },
            {
              value: 448.29999999999995
            },
            {
              value: 448.29999999999995
            },
            {
              value: 448.29999999999995
            },
            {
              value: 448.29999999999995
            },
            {
              value: 448.29999999999995
            },
            {
              value: 448.29999999999995
            },
            {
              value: 448.29999999999995
            },
            {
              value: 448.29999999999995
            },
            {
              value: 448.29999999999995
            },
            {
              value: 448.29999999999995
            },
            {
              value: 448.29999999999995
            },
            {
              value: 448.29999999999995
            },
            {
              value: 448.29999999999995
            },
            {
              value: 448.29999999999995
            },
            {
              value: 448.29999999999995
            },
            {
              value: 448.29999999999995
            },
            {
              value: 448.29999999999995
            },
            {
              value: 448.29999999999995
            },
            {
              value: 448.29999999999995
            },
            {
              value: 448.29999999999995
            },
            {
              value: 448.29999999999995
            },
            {
              value: 448.29999999999995
            },
            {
              value: 448.29999999999995
            },
            {
              value: 448.29999999999995
            },
            {
              value: 472.7
            },
            {
              value: 472.7
            },
            {
              value: 472.7
            },
            {
              value: 466.6
            },
            {
              value: 466.6
            },
            {
              value: 466.6
            },
            {
              value: 466.6
            },
            {
              value: 466.6
            },
            {
              value: 466.6
            },
            {
              value: 466.6
            },
            {
              value: 466.6
            },
            {
              value: 466.6
            },
            {
              value: 466.6
            },
            {
              value: 466.6
            },
            {
              value: 466.6
            },
            {
              value: 466.6
            },
            {
              value: 466.6
            },
            {
              value: 466.6
            },
            {
              value: 466.6
            },
            {
              value: 466.6
            },
            {
              value: 466.6
            },
            {
              value: 466.6
            },
            {
              value: 466.6
            },
            {
              value: 428.48
            },
            {
              value: 428.48
            },
            {
              value: 428.48
            },
            {
              value: 428.48
            },
            {
              value: 428.48
            },
            {
              value: 428.48
            },
            {
              value: 428.48
            },
            {
              value: 428.48
            },
            {
              value: 428.48
            },
            {
              value: 428.48
            },
            {
              value: 428.48
            },
            {
              value: 428.48
            },
            {
              value: 428.48
            },
            {
              value: 428.48
            },
            {
              value: 428.48
            },
            {
              value: 428.48
            },
            {
              value: 416.28
            },
            {
              value: 416.28
            },
            {
              value: 416.28
            },
            {
              value: 416.28
            },
            {
              value: 416.28
            },
            {
              value: 416.28
            },
            {
              value: 416.28
            },
            {
              value: 416.28
            }
          ],
          prediction: []
        },
        {
          clientName: 'Osato Chemicals',
          growthPercentage: -4.5,
          data: [
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 677.1
            },
            {
              value: 646.6
            },
            {
              value: 646.6
            },
            {
              value: 646.6
            },
            {
              value: 646.6
            },
            {
              value: 646.6
            },
            {
              value: 646.6
            },
            {
              value: 646.6
            },
            {
              value: 646.6
            },
            {
              value: 646.6
            },
            {
              value: 646.6
            },
            {
              value: 646.6
            },
            {
              value: 646.6
            },
            {
              value: 646.6
            },
            {
              value: 646.6
            },
            {
              value: 646.6
            },
            {
              value: 646.6
            },
            {
              value: 646.6
            },
            {
              value: 646.6
            },
            {
              value: 646.6
            },
            {
              value: 646.6
            },
            {
              value: 646.6
            },
            {
              value: 646.6
            },
            {
              value: 646.6
            },
            {
              value: 646.6
            }
          ],
          prediction: []
        },
        {
          clientName: 'Acme Corporation',
          growthPercentage: -20,
          data: [
            {
              value: 3492.25
            },
            {
              value: 3492.25
            },
            {
              value: 3492.25
            },
            {
              value: 3492.25
            },
            {
              value: 3492.25
            },
            {
              value: 3492.25
            },
            {
              value: 3492.25
            },
            {
              value: 3492.25
            },
            {
              value: 3492.25
            },
            {
              value: 3492.25
            },
            {
              value: 3492.25
            },
            {
              value: 3492.25
            },
            {
              value: 3492.25
            },
            {
              value: 3492.25
            },
            {
              value: 3492.25
            },
            {
              value: 3492.25
            },
            {
              value: 3492.25
            },
            {
              value: 3492.25
            },
            {
              value: 3492.25
            },
            {
              value: 3492.25
            },
            {
              value: 3492.25
            },
            {
              value: 3492.25
            },
            {
              value: 3492.25
            },
            {
              value: 3522.75
            },
            {
              value: 3522.75
            },
            {
              value: 3522.75
            },
            {
              value: 3522.75
            },
            {
              value: 3522.75
            },
            {
              value: 3522.75
            },
            {
              value: 3553.25
            },
            {
              value: 3553.25
            },
            {
              value: 3522.75
            },
            {
              value: 3522.75
            },
            {
              value: 3522.75
            },
            {
              value: 3522.75
            },
            {
              value: 3522.75
            },
            {
              value: 3522.75
            },
            {
              value: 3522.75
            },
            {
              value: 3522.75
            },
            {
              value: 3522.75
            },
            {
              value: 3522.75
            },
            {
              value: 3522.75
            },
            {
              value: 3522.75
            },
            {
              value: 3327.55
            },
            {
              value: 3327.55
            },
            {
              value: 3327.55
            },
            {
              value: 3327.55
            },
            {
              value: 3327.55
            },
            {
              value: 3327.55
            },
            {
              value: 3327.55
            },
            {
              value: 3327.55
            },
            {
              value: 3327.55
            },
            {
              value: 3327.55
            },
            {
              value: 3327.55
            },
            {
              value: 3327.55
            },
            {
              value: 3327.55
            },
            {
              value: 3327.55
            },
            {
              value: 3327.55
            },
            {
              value: 3327.55
            },
            {
              value: 3358.05
            },
            {
              value: 3358.05
            },
            {
              value: 3358.05
            },
            {
              value: 3358.05
            },
            {
              value: 3327.55
            },
            {
              value: 3327.55
            },
            {
              value: 3327.55
            },
            {
              value: 3327.55
            },
            {
              value: 3327.55
            },
            {
              value: 3327.55
            },
            {
              value: 3327.55
            },
            {
              value: 3327.55
            },
            {
              value: 3327.55
            },
            {
              value: 3327.55
            },
            {
              value: 3327.55
            },
            {
              value: 3327.55
            },
            {
              value: 3327.55
            },
            {
              value: 3327.55
            },
            {
              value: 2700
            },
            {
              value: 2700
            },
            {
              value: 2650
            },
            {
              value: 2640
            },
            {
              value: 2600
            },
            {
              value: 2600
            },
            {
              value: 2600
            },
            {
              value: 2600
            },
            {
              value: 2600
            },
            {
              value: 2600
            },
            {
              value: 2600
            },
            {
              value: 2600
            },
            {
              value: 2600
            },
            {
              value: 2600
            },
            {
              value: 2600
            }
          ],
          prediction: []
        }
      ]
    }
  }
};
