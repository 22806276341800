import { langHelper } from '@accordo-feed/micro-frontends-utils';

import { FORM_KEYS } from './step1.constants';

const genId = langHelper.genLangId('shell.aco.secureApp.rightSide.step1');
const genOpts = langHelper.genLangOptions(genId);

export default {
  title: genOpts('title', 'Secure App Model (Setup PowerShell)'),
  desc: genOpts(
    'desc',
    'Get started fast. Automate the import of your client’s data from Partner Center with the help of PowerShell script. Follow the instructions here: <a target="_blank" href="">Entori docs</a>.'
  ),
  footer: genOpts(
    'footer1',
    '&copy;2021 Entori™. All rights reserved. This site is hosted by <br />Entori LLC. <a target="_blank" href="https://accordo.com/privacy-policy/">Privacy & Cookies</a> | <a target="_blank" href="https://accordo.com/terms-and-conditions/">Terms of Use</a>'
  ),
  [FORM_KEYS.APP_ID]: genOpts('partnerAppId', 'App ID'),
  [FORM_KEYS.APP_SECRET]: genOpts('partnerAppSecret', 'App Secret'),
  [FORM_KEYS.TENANT_ID]: genOpts('tenantId', 'Tenant ID'),
  submit: genOpts('submit', 'Next'),
  err: {
    [`${FORM_KEYS.APP_ID}Required`]: genOpts('partnerAppIdRequired', '"App ID" is required'),
    [`${FORM_KEYS.APP_ID}Invalid`]: genOpts('partnerAppIdInvalid', 'Please enter an valid "App ID"'),
    [`${FORM_KEYS.APP_SECRET}Required`]: genOpts('partnerAppSecretRequired', '"App Secret" is required'),
    [`${FORM_KEYS.TENANT_ID}Required`]: genOpts('tenantIdRequired', '"Tenant ID" is required'),
    [`${FORM_KEYS.TENANT_ID}Invalid`]: genOpts('tenantIdInvalid', 'Please enter an valid "Tenant ID"')
  }
};
