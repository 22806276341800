import { langHelper } from '@accordo-feed/micro-frontends-utils';

const genSubscriptionUsageId = langHelper.genLangId('shell.aco.subscriptions.subscriptionUsage');
const genCurrentSubscriptionId = langHelper.genLangId('shell.aco.subscriptions.currentSubscription');

const genUsageOpts = langHelper.genLangOptions(genSubscriptionUsageId);
const genCurrentOpts = langHelper.genLangOptions(genCurrentSubscriptionId);

export const subscriptionUsage = {
  sectionHeader: genUsageOpts('sectionHeader', 'Subscription Usage'),
  countHeader: genUsageOpts('countHeader', 'Monthly Active Users'),

  mauBreakdown: {
    title: genUsageOpts('title', 'Monthly Active Users Breakdown'),
    tierHeading: genUsageOpts('colHeading1', 'Tiers'),
    userHeading: genUsageOpts('colHeading2', 'Users'),
    tierBaseText: genUsageOpts('tierBaseText', 'Base'),
    tierText: genUsageOpts('tierText', 'Tier {tierNum}'),
    tierPack: genUsageOpts('tierPack', 'Starter Pack'),
    baseInfo: genUsageOpts('baseInfo', 'Base - the first {max} users are included in the minimum fee'),
    pabBaseInfo: genUsageOpts('pabBaseInfo', 'Base - the first {max} users are free'),
    info: genUsageOpts('info1', 'Tier {tierNum} - users from {min} to {max} are charged at {pricePerUser}'),
    lastInfo: genUsageOpts('lastInfo2', 'Tier {tierNum} - users from {min} are charged at {pricePerUser}')
  },
  mauDefined: {
    heading: genUsageOpts('mauDefinedHeading', 'Pricing Information'),
    definition1: genUsageOpts(
      'mauDefinedText3',
      `Monthly fees are based on the maximum number of Active Users each month. Active Users are users with 1 or more Office 365 licenses assigned for each customer managed in Entori.`
    ),
    definition2: genUsageOpts(
      'mauDefinedText4',
      `A minimum fee based on 200 users is applied for user counts below 200. All prices are in USD and are exclusive of taxes.`
    ),
    definition2PAB: genUsageOpts(
      'mauDefinedText2PAB',
      `Your first 1,000 users are free. All prices are in USD and are exclusive of taxes.`
    ),
    definition3FirstPart: genUsageOpts('definition3FirstPart', `Please see the`),
    definition3Terms: genUsageOpts('definition3Terms', 'Terms of Use'),
    definition3SecondPart: genUsageOpts('definition3SecondPart1', `for further details or contact us at`)
  }
};

export const currentSubscription = {
  table: {
    title: genCurrentOpts('heading', 'Current Subscription'),
    productHeading: genCurrentOpts('colHeading1', 'Products'),
    usageHeading: genCurrentOpts('colHeading2', 'Usage Tier'),
    priceHeading: genCurrentOpts('colHeading3', 'Your Price'),
    products1: genCurrentOpts('tableEntry1', 'Office 365'),
    usage1: genCurrentOpts('tableEntry2', ' '),
    price1: genCurrentOpts('tableEntry3', '{price}'),
    freeTrialPrice: genCurrentOpts('freeTrialPrice', 'From {price} after your free trial'),
    footer: genCurrentOpts('tableFooter', 'Your next bill will be on {billingPeriodEnd}'),
    cancelButton: genCurrentOpts('cancelButton', 'Cancel Subscription'),
    startButton: genCurrentOpts('startButton', 'Start Subscription'),
    whenCancelMsg: genCurrentOpts('whenCancelmsg', 'You cancelled subscription on {canceledDate}')
  },
  heading: genCurrentOpts('cancelBoxHeading', 'Confirm Subscription Cancellation'),
  message: genCurrentOpts(
    'cancelBoxText',
    `Are you sure you would like to cancel your current subscription?\
     You will not be able to access to your Optimizer data once it is disabled at the end of your current billing period on {billingPeriodEnd}.`
  ),
  confirmButton: genCurrentOpts('cancelBoxConfirmButton', 'Proceed'),
  cancelButton: genCurrentOpts('cancelBoxDeclineButton', 'Not Now'),
  trialDefinedHeading: genCurrentOpts('trialDefinedHeading', 'Trial'),
  trialDefinedSubHeading: genCurrentOpts('trialDefinedSubHeading', 'You have {daysLeft} left on your free trial'),
  trialDefinedText: genCurrentOpts('trialDefinedText', ` `)
};
