// @flow

import React from 'react';
import { useTranslate } from '@accordo-feed/language.entry';

import { LoadingBlock } from 'src/components/loadingBlock';
import type { LoadingStatusType } from 'src/constants';
import { PaymentInfo } from './PaymentInfo.component';
import { paymentInfo } from './billing.lang';
import { StyledSection } from './paymentInfo.styled';
/*************
 *   TYPES   *
 *************/

type Props = {
  orgId: string,
  billingEmail: string,
  formatCurrency: Function,
  translate: Function,
  updateBillingEmail: Function,
  updateBillingAddress: Function,
  updateBillingEmailLoadingStatus: LoadingStatusType,
  loadingStatus: LoadingStatusType
};

/*****************
 *   COMPONENT   *
 *****************/

export default ({
  orgId,
  billingEmail,
  formatCurrency,
  updateBillingAddress,
  updateBillingEmail,
  updateBillingEmailLoadingStatus,
  loadingStatus
}: Props) => {
  const translate = useTranslate();

  return (
    <LoadingBlock loadingStatus={loadingStatus}>
      <StyledSection title={translate(paymentInfo.heading)} marginBottom="0px">
        <PaymentInfo
          billingEmail={billingEmail}
          orgId={orgId}
          updateBillingEmail={updateBillingEmail}
          updateBillingEmailLoadingStatus={updateBillingEmailLoadingStatus}
        />
      </StyledSection>
    </LoadingBlock>
  );
};
