// @flow

import React, { useMemo, useState } from 'react';
import * as R from 'ramda';

import SimpleTable from 'src/components/simpleTable';
import { widgetDataHOC } from 'src/hoc';
import FilterPanel from './FilterPanel.component';
import { generateTableColumns } from './subscriptions.config';
import * as Styled from './subscriptions.styled';

/*************
 *   TYPES   *
 *************/

type Props = {
  data: Array<Object>,
  search: string
};

/*****************
 *   COMPONENT   *
 *****************/

const SubscriptionsComponent = ({ data, search }: Props) => {
  const [filterOptions, setFilterOptions] = useState({ minSpend: 0, maxSpend: 0, expiring: [] });

  const dataSource = useMemo(() => {
    const { minSpend, maxSpend, expiring } = filterOptions;
    const expiringDay = expiring.length > 0 ? Math.max(...expiring) : -1;
    const filteredData = R.filter(e => {
      const { name, subscriptions } = e;
      const annualSpend = subscriptions?.annual?.spend ?? 0;
      const monthlySpend = subscriptions?.monthly?.spend ?? 0;
      const annualExpiring = subscriptions?.annual?.expiring ?? 0;
      if (annualSpend < minSpend || annualSpend > maxSpend) return false;
      if (monthlySpend < minSpend || monthlySpend > maxSpend) return false;
      if (expiringDay > -1 && annualExpiring > expiringDay) return false;
      if (search !== '' && !R.includes(R.toLower(search), R.toLower(name))) return false;
      return true;
    }, data);
    return filteredData;
  }, [filterOptions, search]);

  const tableOptions = {
    dataSource,
    pageSize: 17,
    columns: generateTableColumns({
      enableSort: true
    }),
    x: 1400
  };

  return (
    <Styled.Wrapper>
      <FilterPanel data={data} onChange={e => setFilterOptions(e)} />
      <Styled.TableWrapper>
        <SimpleTable {...tableOptions} />
      </Styled.TableWrapper>
    </Styled.Wrapper>
  );
};

export default widgetDataHOC(['data'])(SubscriptionsComponent);
