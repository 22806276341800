// @flow

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as R from 'ramda';

import ProfileMfaModal from './ProfileMfaModal.component';
import * as profileSelectors from '../profile.selector';
import * as selectors from 'src/redux/selectors';
import { updateMfa } from 'src/pages/profile/profile.duck';

/*************
 *   REDUX   *
 *************/

const mapStateToProps = R.applySpec({
  isMfaEnabled: selectors.isMfaEnabledSelector,
  isMfaUpdating: profileSelectors.isMfaUpdatingSelector
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      updateMfa
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMfaModal);
