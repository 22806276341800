// @flow

import * as R from 'ramda';
import { connect } from 'react-redux';
import { redux } from '@accordo-feed/micro-frontends';

import { setStep, connectPartnerCenter } from 'src/pages/secureApp/secureApp.duck';
import * as selectors from 'src/pages/secureApp/secureApp.selector';
import Step2 from './Step2.component';

/*************
 *   REDUX   *
 *************/

const mapStateToProps = R.applySpec({
  isConnecting: selectors.isConnectingSelector
});

const mapDispatchToProps = redux.bindActionCreatorsCurried({
  setStep,
  connectPartnerCenter
});

export default connect(mapStateToProps, mapDispatchToProps)(Step2);
