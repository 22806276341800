import React from 'react';
import { useTranslate } from '@accordo-feed/language.entry';

import Widget from 'src/components/widget';
import SimpleTable from 'src/components/simpleTable';
import CustomModal from 'src/components/modal';
import { formatUsd } from 'src/utils';
import { useModalProps } from 'src/hooks';
import {
  Trial,
  NextBillDate,
  CancelSubscription,
  getDMYStrFromUTCTimestamp,
  BillPeriodSection
} from './currentSubscription.styled';
import { getDataSource, getColumns } from './currentSubscription.utils';
import { currentSubscription as lang } from './subscriptions.lang';

/*****************
 *   COMPONENT   *
 *****************/

export const CurrentSubscription = ({
  orgId,
  willOffice365BeCancelled,
  cancelSubscriptionLoadingStatus,
  office365CancelTime,
  cancelSubscription,
  subscriptions
}) => {
  const translate = useTranslate();
  const { isModalOpen, openModal, closeModal } = useModalProps();
  const nextBillingTimestamp = getDMYStrFromUTCTimestamp(subscriptions.office365Basic.nextBillingTime * 1000);

  return (
    <>
      <Widget title={translate(lang.table.title)}>
        <>
          <SimpleTable
            bordered={false}
            pagination={false}
            showScroll={false}
            dataSource={getDataSource(subscriptions, translate, formatUsd)}
            columns={getColumns(translate)}
          />

          <BillPeriodSection>
            <NextBillDate>
              {translate(lang.table.footer, {
                billingPeriodEnd: nextBillingTimestamp
              })}
            </NextBillDate>

            <CancelSubscription
              office365CancelTime={office365CancelTime}
              translate={translate}
              onClick={openModal}
              willOffice365BeCancelled={willOffice365BeCancelled}
            />
          </BillPeriodSection>
        </>
      </Widget>
      <CustomModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        title={translate(lang.heading)}
        description={translate(lang.message, { billingPeriodEnd: nextBillingTimestamp })}
        leftButtonText={translate(lang.cancelButton)}
        rightButtonText={translate(lang.confirmButton)}
        leftButtonOnClick={closeModal}
        rightButtonOnClick={() => {
          cancelSubscription({
            planId: 'office365Basic',
            orgId,
            whenSuccess: () => {
              closeModal();
              window.location.reload();
            }
          });
        }}
        rightButtonLoadingStatus={cancelSubscriptionLoadingStatus}
      />
      {subscriptions.office365Basic.trialPeriodEndTime && (
        <Trial trialPeriodEndTime={subscriptions.office365Basic.trialPeriodEndTime} />
      )}
    </>
  );
};
