// @flow

import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { command } from '@accordo-feed/micro-frontends';

import * as actions from './secureApp.duck';
import * as apiCalls from './secureApp.api';
import * as customerActions from 'src/pages/customers/customers.duck';
import * as partnerCenterActions from 'src/redux/modules/partnerCenter/partnerCenter.duck';
import * as selectors from 'src/redux/selectors';
import { PATHS, COMMAND_NAVIGATE_TYPE } from 'src/constants';
import { customerUtils } from 'src/pages/customers/customers.utils';
import { lang } from './secureApp.lang';
import { transformErrors } from './secureApp.utils';

function* setSecureAppSaga({ payload }): any {
  const orgId = yield select(selectors.orgIdSelector);

  yield put(actions.setSavingState(true));

  try {
    const data = yield apiCalls.setSecureApp({
      orgId,
      data: payload.values
    });

    yield put(partnerCenterActions.getConnectionStateSuccess(data));
    yield put(actions.setStep(undefined));
  } catch (err) {
    payload.setErrors(transformErrors(err.errors));
  } finally {
    yield put(actions.setSavingState(false));
  }
}

function* connectPartnerCenterSaga({ payload: { translate } }): any {
  yield put(actions.setIsConnectingState(true));

  const { loginWindow } = lang;
  const newWindow = customerUtils.generateLoadingNewWindow(translate(loginWindow.title));
  const orgId = yield select(selectors.orgIdSelector);

  try {
    const login = yield apiCalls.getConnectionLink(orgId);
    customerUtils.redirectNewWindow(newWindow, login.uri);
  } finally {
    yield put(actions.setIsConnectingState(false));
  }
}

function* connectPartnerCenterSuccessfulSaga(): any {
  yield put(customerActions.showSecureAppModal());
  yield put(customerActions.setBulkEditView(true));
  yield put(partnerCenterActions.getConnectionState());
  yield call(command.navigateTo, {
    path: PATHS.CUSTOMERS.ROOT,
    method: COMMAND_NAVIGATE_TYPE.REPLACE
  });
}

export default function*(): any {
  yield all([
    fork(takeLatest, actions.setSecureApp, setSecureAppSaga),
    fork(takeLatest, actions.connectPartnerCenter, connectPartnerCenterSaga),
    fork(takeLatest, actions.connectPartnerCenterSuccessful, connectPartnerCenterSuccessfulSaga)
  ]);
}
