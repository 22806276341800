// @flow

import React from 'react';

import * as Styled from './recommendations.styled';
import Heading from './Heading.component';
import { formatCurrencyHOC } from 'src/hoc';
import { RECOM_SECTIONS } from 'src/constants';
import { lang } from './recommendations.lang';
import {
  DescriptionCell,
  ImpactCell,
  ResouceCell,
  UpdatedCell,
  MonthlySavingsCell,
  convertDate
} from './recommendations.config';

/*************
 *   TYPES   *
 *************/

type Props = {
  descriptionHeading: string,
  impactHeading: string,
  resourceHeading: string,
  updateHeading: string,
  monthlyHeading: string
};

// Table header for cost
export const TableHeaderCost = ({
  descriptionHeading,
  impactHeading,
  resourceHeading,
  updateHeading,
  monthlyHeading
}: Props) => (
  <li className="hd">
    <DescriptionCell>{descriptionHeading}</DescriptionCell>
    <ImpactCell>{impactHeading}</ImpactCell>
    <ResouceCell>{resourceHeading}</ResouceCell>
    <UpdatedCell>{updateHeading}</UpdatedCell>
    <MonthlySavingsCell>{monthlyHeading}</MonthlySavingsCell>
  </li>
);

/*****************
 *   COMPONENT   *
 *****************/

const Cost = ({ translate, recommendations, formatCurrencyWithoutUnit }) => {
  const { cost: items } = recommendations;
  return (
    <Styled.Section>
      <Heading type={RECOM_SECTIONS.COST} translate={translate} />

      <Styled.Table>
        <TableHeaderCost
          descriptionHeading={translate(lang.descriptionCol)}
          impactHeading={translate(lang.impactCol)}
          resourceHeading={translate(lang.resourceCol)}
          updateHeading={translate(lang.updatedCol)}
          monthlyHeading={translate(lang.monthlySavingsCol)}
        />

        {items.map((item, idx) => {
          const { impact, impactedResource, lastUpdated, description, monthlySavings } = item;
          return (
            <li key={idx}>
              <DescriptionCell>{description}</DescriptionCell>
              <ImpactCell>
                <Styled.Priority level={impact}>{translate(lang.impact[impact])}</Styled.Priority>
              </ImpactCell>
              <ResouceCell>{impactedResource}</ResouceCell>
              <UpdatedCell>{convertDate(lastUpdated)}</UpdatedCell>
              <MonthlySavingsCell>
                {formatCurrencyWithoutUnit(monthlySavings)} {recommendations.currency}
              </MonthlySavingsCell>
            </li>
          );
        })}
      </Styled.Table>
    </Styled.Section>
  );
};

export default formatCurrencyHOC(Cost);
