import { langHelper } from '@accordo-feed/micro-frontends-utils';

const genSecureAppId = langHelper.genLangId('shell.aco.secureApp');
const genSecureAppLang = langHelper.genLangOptions(genSecureAppId);

export const lang = {
  loginWindow: {
    title: genSecureAppLang('loginWindow.title', 'Sign in to Microsoft partner center')
  }
};
