import { langHelper } from '@accordo-feed/micro-frontends-utils';

const genId = langHelper.genLangId('shell.aco.masterList.allocation');
const genOpts = langHelper.genLangOptions(genId);

export default {
  widgetTitle: genOpts('title', 'Secure Score Index'),
  widgetSubTitle: genOpts('subTitle', 'Secure Score'),
  table: {
    main: {
      company: genOpts('th.company', 'Company'),
      securescore: genOpts('th.securescore', 'Secure Score'),
      mfa: genOpts('th.mfa', 'MFA')
    },
    base: {
      th1: genOpts('base.th1', ''),
      th2: genOpts('base.th2', 'Current'),
      th3: genOpts('base.th3', 'Max'),
      th4: genOpts('base.th4', 'Percent to Max'),
      th5: genOpts('base.th5', 'Change'),
      th6: genOpts('base.th6', 'Admin MFA'),
      th7: genOpts('base.th7', 'General MFA')
    }
  }
};
