// @flow

import FileSaver from 'file-saver';
import { core } from '@accordo-feed/micro-frontends-utils';

import {
  API,
  resolveApiEndpoint,
  requestHandler,
  handleOnlyAuthError,
  createDateFileName,
  getElasticData
} from 'src/utils';

/*****************
 *   API CALLS   *
 *****************/

const dashboardDataApi = resolveApiEndpoint(API.GET_DASHBOARD);
const consumptionServiceApi = resolveApiEndpoint(API.GET_CUSTOMER_RECOMMENDATIONS);
const consumptionAdoptionApi = resolveApiEndpoint(API.GET_CUSTOMER_ADOPTION);
const securityDataApi = resolveApiEndpoint(API.O365_SECURITY_SNAPSHOT);
const downloadStaticReportApi = resolveApiEndpoint(API.DOWNLOAD_STATIC_REPORT);
const psaDataApi = resolveApiEndpoint(API.PSA_RECONCILE);
const psaConnectionApi = resolveApiEndpoint(API.PSA);

export const getDashboardData = (orgId: string): Promise<any> =>
  requestHandler({
    url: core.replaceAll(dashboardDataApi, { orgId }),
    handleError: handleOnlyAuthError
  });

export const getConsumptionService = (orgId: string): Promise<any> =>
  requestHandler({
    url: core.replaceAll(consumptionServiceApi, { orgId }),
    handleError: handleOnlyAuthError
  });

export const getConsumptionServices = (orgIds: Array<string>): Promise<any> => {
  const services = orgIds.map(id => getConsumptionService(id));
  return Promise.all(services);
};

export const getConsumptionAdoption = (orgId: string): Promise<any> =>
  requestHandler({
    url: core.replaceAll(consumptionAdoptionApi, { orgId }),
    handleError: handleOnlyAuthError
  });

export const getConsumptionAdoptions = (orgIds: Array<string>): Promise<any> => {
  const adoptions = orgIds.map(id => getConsumptionAdoption(id));
  return Promise.all(adoptions);
};

export const downloadStaticReport = ({ orgId, type }: { orgId: string, type: string }): Promise<any> =>
  requestHandler({
    url: core.replaceAll(downloadStaticReportApi, { orgId }),
    data: {
      type
    },
    method: 'POST',
    handleError: handleOnlyAuthError,
    handleBody: ({ data }) => FileSaver.saveAs(new Blob([data]), createDateFileName(type, 'csv')),
    responseType: 'blob'
  });

export const getSecurityData = (data: Object) => getElasticData({ data, apiUrl: securityDataApi });

export const getBillingDiscrepancyData = (data: Object) => getElasticData({ data, apiUrl: psaDataApi });

export const getPsaConnections = (orgId: string): Promise<any> =>
  requestHandler({
    method: 'GET',
    url: core.replaceAll(psaConnectionApi, { orgId })
  });
