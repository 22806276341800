// @flow

import { createAction } from 'redux-actions';
import { redux } from '@accordo-feed/micro-frontends';

import type { Action } from 'src/redux/types';
import { wrapWithModule } from 'src/redux/utils';

const { setState } = redux;

/*************
 *   TYPES   *
 *************/

export type StateType = {
  isConnecting: boolean,
  isSaving: boolean,
  step?: number
};

/*********************
 *   INITIAL STATE   *
 *********************/

const initialState: StateType = {
  isConnecting: false,
  isSaving: false
};

/***************
 *   ACTIONS   *
 ***************/

const wrapWithNamespace = wrapWithModule('modules/secureApp');
const SET_SAVING_STATE = wrapWithNamespace('SET_SAVING_STATE');
const SET_IS_CONNECTING_STATE = wrapWithNamespace('SET_IS_CONNECTING_STATE');
const SET_SECURE_APP = wrapWithNamespace('SET_SECURE_APP');
const SET_STEP = wrapWithNamespace('SET_STEP');
const CONNECT_PARTNER_CENTER = wrapWithNamespace('CONNECT_PARTNER_CENTER');
const CONNECT_PARTNER_CENTER_SUCCESSFUL = wrapWithNamespace('CONNECT_PARTNER_CENTER_SUCCESSFUL');

export const setSavingState = createAction(SET_SAVING_STATE);
export const setIsConnectingState = createAction(SET_IS_CONNECTING_STATE);
export const setSecureApp = createAction(SET_SECURE_APP);
export const setStep = createAction(SET_STEP);
export const connectPartnerCenter = createAction(CONNECT_PARTNER_CENTER);
export const connectPartnerCenterSuccessful = createAction(CONNECT_PARTNER_CENTER_SUCCESSFUL);

/***************
 *   REDUCER   *
 ***************/

export default (state: StateType = initialState, action: Action) => {
  const { type, payload } = action;

  const reducer = {
    [SET_SAVING_STATE]: setState('isSaving'),
    [SET_IS_CONNECTING_STATE]: setState('isConnecting'),
    [SET_STEP]: setState('step')
  }[type];

  return reducer ? reducer(state, payload) : state;
};
