// @flow

import * as R from 'ramda';
import { apiCommon, core } from '@accordo-feed/micro-frontends-utils';

import { SHELL_NAME } from 'src/constants';

/*************
 *   TYPES   *
 *************/

declare var __DEPLOYMENT_ENV__: string;

/*******************
 *   API MAPPING   *
 *******************/

export const API = {
  IOT_AUTH: 'IOT_AUTH',
  PUT_BILLING_ADDRESS: 'PUT_BILLING_ADDRESS',
  OLD_PUT_PAYMENT_METHOD: 'OLD_PUT_PAYMENT_METHOD',
  PUT_PAYMENT_METHOD: 'PUT_PAYMENT_METHOD',
  PUT_BILLING_EMAIL: 'PUT_BILLING_EMAIL',
  PROMO_CODE: 'PROMO_CODE',
  GET_SUBSCRIPTION_USAGE: 'GET_SUBSCRIPTION_USAGE',
  CANCEL_SUBSCRIPTION: 'CANCEL_SUBSCRIPTION',
  GET_ORGANIZATION: 'GET_ORGANIZATION',
  POST_CUSTOMER: 'POST_CUSTOMER',
  DELETE_CUSTOMER: 'DELETE_CUSTOMER',
  PUT_CUSTOMER: 'PUT_CUSTOMER',
  POST_CUSTOMER_LOGO: 'POST_CUSTOMER_LOGO',
  GET_CUSTOMERS: 'GET_CUSTOMERS',
  GET_CUSTOMER_STATE: 'GET_CUSTOMER_STATE',
  GET_CUSTOMER_LOGIN: 'GET_CUSTOMER_LOGIN',
  GET_CUSTOMER_DASHBOARD: 'GET_CUSTOMER_DASHBOARD',
  GET_CUSTOMER_RECOMMENDATIONS: 'GET_CUSTOMER_RECOMMENDATIONS',
  GET_CUSTOMER_ADOPTION: 'GET_CUSTOMER_ADOPTION',
  GET_CUSTOMER_USERS: 'GET_CUSTOMER_USERS',
  GET_CUSTOMER_AZURE_RESOURCES: 'GET_CUSTOMER_AZURE_RESOURCES',
  GET_CUSTOMER_AZURE_RECOMMENDATIONS: 'GET_CUSTOMER_AZURE_RECOMMENDATIONS',
  REFRESH_OFFICE365: 'REFRESH_OFFICE365',
  SET_BULK_ENABLE: 'SET_BULK_ENABLE',
  ENABLE_SINGLE_APP_CONNECT: 'ENABLE_SINGLE_APP_CONNECT',
  DISABLE_SINGLE_APP_CONNECT: 'DISABLE_SINGLE_APP_CONNECT',
  GET_DASHBOARD: 'GET_DASHBOARD',
  GET_AZURE_DASHBOARD: 'GET_AZURE_DASHBOARD',
  PUT_PROFILE: 'PUT_PROFILE',
  UPDATE_MFA: 'UPDATE_MFA',
  O365_SECURITY_SNAPSHOT: 'O365_SECURITY_SNAPSHOT',
  DOWNLOAD_STATIC_REPORT: 'DOWNLOAD_STATIC_REPORT',
  DOWNLOAD_QUERY_REPORT: 'DOWNLOAD_QUERY_REPORT',
  PSA_RECONCILE: 'PSA_RECONCILE',
  GET_MODERN_WORKPLACE_ASSESSMENT: 'GET_MODERN_WORKPLACE_ASSESSMENT',
  PSA: 'PSA',
  PARTNER_CENTER_STATE: 'PARTNER_CENTER_STATE',
  PARTNER_CENTER_LOGIN: 'PARTNER_CENTER_LOGIN',
  PARTNER_CENTER_APP_SETTINGS: 'PARTNER_CENTER_APP_SETTINGS',
  GET_DISTI_BILLING_DATA: 'GET_DISTI_BILLING_DATA'
};

export const API_PATHS = {
  [API.IOT_AUTH]: '/notification-auth/organizations/{{ orgId }}/auth',

  // Payment Gateway API
  // https://bitbucket.org/accordogroup/payment-gateway-api/src/master/docs/swagger.yaml
  [API.PUT_PAYMENT_METHOD]: '/payments/msp/{{ orgId }}/payment-method',
  [API.PUT_BILLING_EMAIL]: '/payments/msp/{{ orgId }}/billing-email',

  // Organization API
  // https://bitbucket.org/accordogroup/organization-api/src/master/docs/swagger.yaml
  [API.OLD_PUT_PAYMENT_METHOD]: '/organizations/aco/msp/{{ orgId }}/payment-method',
  [API.PUT_BILLING_ADDRESS]: '/organizations/aco/msp/{{ orgId }}/billing-address',
  [API.PROMO_CODE]: '/organizations/aco/msp/{{ orgId }}/promotion-code',
  [API.GET_SUBSCRIPTION_USAGE]: '/organizations/aco/msp/{{ orgId }}/plan-usage',
  [API.CANCEL_SUBSCRIPTION]: '/organizations/aco/msp/{{ orgId }}/cancel-subscription',
  [API.GET_ORGANIZATION]: '/organizations/{{ orgId }}',
  [API.POST_CUSTOMER]: '/organizations/aco/msp/{{ orgId }}/client',
  [API.DELETE_CUSTOMER]: '/organizations/aco/client/{{ orgId }}',
  [API.PUT_CUSTOMER]: '/organizations/aco/client/{{ orgId }}/profile',
  [API.PUT_PROFILE]: '/organizations/aco/msp/{{ orgId }}/profile',
  [API.UPDATE_MFA]: '/organizations/aco/msp/{{ orgId }}/mfa',
  [API.GET_DISTI_BILLING_DATA]: '/organizations/aco/msp/{{ orgId }}/disti-billingmeter',
  [API.POST_CUSTOMER_LOGO]: '/organizations/aco/client/{{ orgId }}/logo',

  // Search Service API
  // https://bitbucket.org/accordogroup/search-service/src/master/docs/swagger.yml
  [API.GET_CUSTOMERS]: '/search-service/organizations/{{ orgId }}/clients',
  [API.GET_DASHBOARD]: '/search-service/organizations/{{ orgId }}/dashboard',
  [API.GET_AZURE_DASHBOARD]: '/search-service/partners/{{ orgId }}/azure-dashboard',
  [API.O365_SECURITY_SNAPSHOT]: '/search-service/proxy/office365-security-snapshot/organizations/{{ orgId }}',
  [API.PSA_RECONCILE]: '/search-service/proxy/psa-reconcile/organizations/{{ orgId }}',
  [API.GET_MODERN_WORKPLACE_ASSESSMENT]:
    '/search-service/proxy/reports-modern-workplace-assessment/organizations/{{ orgId }}',
  [API.GET_CUSTOMER_AZURE_RESOURCES]: '/search-service/partners/{{ partnerId }}/organizations/{{ orgId }}/azure-usage',
  [API.GET_CUSTOMER_AZURE_RECOMMENDATIONS]:
    '/search-service/partners/{{ partnerId }}/organizations/{{ orgId }}/azure-usage',

  // Reporting Service API
  // https://bitbucket.org/accordogroup/reporting-services/src/master/docs/swagger.yaml
  [API.DOWNLOAD_STATIC_REPORT]: '/reporting-services/msp/{{ orgId }}/download-report',
  [API.DOWNLOAD_QUERY_REPORT]:
    '/reporting-services/proxy/office365-security-snapshot/organizations/{{ orgId }}/download-report',

  // Consumption API
  // https://bitbucket.org/accordogroup/consumption-service/src/master/docs/swagger.yml
  [API.GET_CUSTOMER_STATE]: '/consumption/organizations/{{ orgId }}/office365/state',
  [API.GET_CUSTOMER_LOGIN]: '/consumption/organizations/{{ orgId }}/office365/login',
  [API.GET_CUSTOMER_DASHBOARD]: '/consumption/organizations/{{ orgId }}/office365/dashboard',
  [API.GET_CUSTOMER_USERS]: '/consumption/organizations/{{ orgId }}/office365/users',
  [API.GET_CUSTOMER_RECOMMENDATIONS]: '/consumption/organizations/{{ orgId }}/office365/recommendations',
  [API.GET_CUSTOMER_ADOPTION]: '/consumption/organizations/{{ orgId }}/office365/adoption',
  [API.REFRESH_OFFICE365]: '/consumption/organizations/{{ orgId }}/office365/refresh',
  [API.SET_BULK_ENABLE]: '/consumption/organizations/{{ orgId }}/app-connect/bulk-enable',
  [API.ENABLE_SINGLE_APP_CONNECT]: '/consumption/organizations/{{ orgId }}/app-connect/enable',
  [API.DISABLE_SINGLE_APP_CONNECT]: '/consumption/organizations/{{ orgId }}/app-connect/disable',

  // Integrations Service
  // https://bitbucket.org/accordogroup/integrations-service/src/master/docs/swagger.yml
  [API.PSA]: '/integrations/organizations/{{ orgId }}/psa',

  // Partner Center Service
  // https://bitbucket.org/accordogroup/partner-center-service/src/master/docs/swagger.yml
  [API.PARTNER_CENTER_STATE]: '/partner-center/organizations/{{ orgId }}/state',
  [API.PARTNER_CENTER_LOGIN]: '/partner-center/organizations/{{ orgId }}/login',
  [API.PARTNER_CENTER_APP_SETTINGS]: '/partner-center/organizations/{{ orgId }}/app-settings'
};

const API_MAPPING = R.values(API).reduce((acc, key) => {
  acc[key] = API_PATHS[key];
  return acc;
}, {});

/**********************
 *   HELPER METHODS   *
 **********************/

const apiConfig = new apiCommon.ApiConfig({
  apiMapping: API_MAPPING,
  env: __DEPLOYMENT_ENV__,
  storageName: SHELL_NAME
});

export const resolveApiEndpoint = (key: string): string => apiConfig.get(key);

export const requestHandler = apiCommon.requestHandler(SHELL_NAME);

// $FlowIgnore
export const isAuthError = R.anyPass([R.equals(403), R.equals(401)]);

/**
 * handleOnlyAuthError with requestHandler handleError
 * @param {error} error passed from micro-frontends apiCommon.requestHandler
 * @param {function} defaultErrorHandler passed from micro-frontends apiCommon.requestHandler
 */
// $FlowIgnore
export const handleOnlyAuthError = (error, defaultErrorHandler) =>
  R.ifElse(R.pipe(R.path(['response', 'status']), isAuthError), defaultErrorHandler, () => {
    return error;
  })(error);

/**
 * get elastic data with query
 * @param {params}
 * @param {params.data} object containers orgId, eqQuery and queryName
 * @param {params.data.orgId} string orgId for the request
 * @param {params.data.queryName} string query name
 * @param {params.data.esQuery} object elastic search query
 * @param {params.apiUrl} string api end point
 * @returns {promise}
 */
export const getElasticData = ({ data, apiUrl }: { data: Object, apiUrl: string }): Promise<any> =>
  requestHandler({
    url: core.replaceAll(apiUrl, { orgId: data.partnerId }),
    data,
    method: 'POST',
    handleError: handleOnlyAuthError
  });
