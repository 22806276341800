// @flow

import * as R from 'ramda';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';

import * as selectors from './drawer.selector';
import FilterTags from 'src/pages/dashboard/securityScore/filterTags';
import { resetSecurityControlProfileFilters, setSecurityControlProfileFilter } from './drawer.duck';
import { findKeyByMappedValue } from 'src/utils';
import { FILTER_TEXT_MAP } from './drawer.constants';

/*************
 *   REDUX   *
 *************/

const mapStateToProps = R.applySpec({
  filterTags: selectors.filterTagSelector,
  selectedFilters: selectors.allFilterSelector
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setSecurityControlProfileFilter,
      resetSecurityControlProfileFilters
    },
    dispatch
  )
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),

  withHandlers({
    onRemoveFilterTag: ({ actions, selectedFilters }) => removedFilter => {
      const pathAndValue = R.split(': ', removedFilter);
      const path = findKeyByMappedValue(FILTER_TEXT_MAP, R.prop('0', pathAndValue));
      const valueNeedRemove = R.prop('1', pathAndValue);
      const value = R.reject(R.equals(valueNeedRemove), R.prop(path, selectedFilters));
      actions.setSecurityControlProfileFilter({ path, value });
    },
    onClearAll: ({ actions }) => () => {
      actions.resetSecurityControlProfileFilters();
    }
  })
)(FilterTags);
