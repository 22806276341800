import styled from 'styled-components';
import { Modal } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { mixins, constants } from '@accordo-feed/aco-styled-components';
import { appTextColor, modalBackgroundColor } from 'src/App.styled';

const { colors, fontSize } = constants;

export const CompleteModal = styled(Modal).attrs({
  width: '350px'
})`
  .ant-modal-content {
    border-radius: 2px;
    background-color: ${modalBackgroundColor};
  }

  .ant-modal-close {
    color: ${appTextColor} !important;
  }

  .ant-modal-body {
    ${mixins.flexVerticalCenter()};
    ${mixins.flexCenter()};
    flex-direction: column;
    padding: 75px 0;

    span {
      color: ${appTextColor};
      font-size: ${fontSize.large};
      margin-top: 20px;
    }
  }
`;

export const CircleCheckIcon = styled(CheckCircleOutlined)`
  svg {
    color: ${colors.shadeGreen};
    font-size: 60px;
  }
`;
