import { Select } from 'antd';
import styled from 'styled-components';
import { constants } from '@accordo-feed/aco-styled-components';
import { hexToRgb } from 'src/utils';
import { appTextColor } from 'src/App.styled';

const { colors } = constants;

export const StyledSelect = styled(Select).attrs({
  showAction: ['focus', 'click']
})`
  width: 100%;
  border-radius: 7px;
  overflow: hidden;

  &&& .ant-select-selector {
    border-radius: 7px;
    color: ${appTextColor};
    border: 1px solid ${colors.darkGrey6};
    background-color: rgba(${hexToRgb(colors.lightGrey7)}, 0.24);
  }
  .ant-select-arrow {
    svg {
      fill: ${appTextColor};
    }
  }
`;

export const FlagIcon = styled.div`
  line-height: 14px;
  margin-right: 10px;
`;
