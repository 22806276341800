import { langHelper } from '@accordo-feed/micro-frontends-utils';

// Can enable the following two statements once custom API-related entries
// are required:
// const genApiId = langHelper.genLangId('shell.aco.error.message.apiCode');
// const genApiOpts = langHelper.genLangOptions(genApiId);

const genStatusId = langHelper.genLangId('shell.aco.error.message.httpStatus');
const genStatusOpts = langHelper.genLangOptions(genStatusId);

export const CONNECT_ERROR_CODE = 101005;

/**
 * getLangIdByErrorCode - accepting HTTP Status or API Error Code
 * HTTP Status should cover client & server error codes 4xx and 5xx
 * @param {string|number} code HTTP Status or API Error Code, default CONNECT_ERROR_CODE: 101005
 * @return {string} language entry id for display
 */
export const getLangIdByErrorCode = (code = CONNECT_ERROR_CODE) => {
  code = parseInt(code, 10);
  const isHTTPStatus = code >= 400 && code < 600;
  const prefix = isHTTPStatus ? 'httpStatus' : 'apiCode';
  return `${prefix}${code}`;
};

export default {
  httpStatus401: genStatusOpts('401', 'Unauthorized'),
  httpStatus403: genStatusOpts('403', 'Forbidden'),
  httpStatus500: genStatusOpts('500', 'Internal Server Error')
};
