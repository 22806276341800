/**
 * Add types (e.g. filter, query...) to one bodyBuilder
 * @param {bodybuilder.Bodybuilder} bodyBuilder
 * @param {Array<{type: string, function: string, field: string|object|bodybuilder.QuerySubFilterFn, input: any}>} queryArr
 */
export const addTypesToBodyBuilder = (bodyBuilder, queryArr) => {
  queryArr.forEach(element => {
    bodyBuilder[element.type](element.function, element.field, element.input);
  });
};
