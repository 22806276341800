import { langHelper } from '@accordo-feed/micro-frontends-utils';

const genId = langHelper.genLangId('shell.aco.planOverview.leftSide');
const genOpts = langHelper.genLangOptions(genId);

export default {
  title: genOpts('title1', 'Microsoft Partners use Entori for M365 and O365 to:'),
  benefits: [
    genOpts('benefit1', 'View Office clients individually and in aggregate'),
    genOpts('benefit2', 'Manage licenses and optimize client spend'),
    genOpts('benefit3', 'Quickly evaluate client Secure Scores and apply relevant improvement actions'),
    genOpts('benefit4', 'Drive client engagement through actionable insights')
  ]
};
