export default {
  dashboard: '365 Hub',
  clientGrowth: 'Client Growth',
  opportunities: 'Recommendations',
  unassignedLicenses: 'Unassigned Licenses',
  securityScore: 'Secure Score Index',
  companyManagement: 'Companies',
  customers: 'Companies',
  settings: 'Settings',
  profile: 'Profile',
  notifications: 'Notification Settings',
  billing: 'Billing',
  teamManagement: 'Team Management',
  psa: 'PSA Integrations',
  psaProduct: 'Product Mapping',
  psaCustomer: 'Customer Mapping',
  subscriptions: 'Subscriptions',
  analyticsCenter: 'Allocation and Adoption Dashboard',
  submodule: '365 Consumption',
  users: 'Users',
  plans: 'Plans',
  spend: 'Spend',
  productCatalog: 'Product Catalog',
  azureDashboard: 'Azure Optimization',
  azureRecommendations: 'Azure Recommendations',
  recommendations: 'Recommendations',
  licenseAssignments: 'License Assignments'
};
