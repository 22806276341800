import React from 'react';
import styled from 'styled-components';
import { mixins, constants } from '@accordo-feed/aco-styled-components';
import { hexToRgb } from 'src/utils';

const { colors, fontSize } = constants;

export const blueColor = '#26ade5';

export const Title = styled.div`
  ${mixins.verticalLineMiddle('58px')};
  width: 142px;
  padding-left: 20px;
  margin-bottom: 25px;
  color: ${colors.black};
  font-size: ${fontSize.large};
  border-bottom: 3px solid ${colors.darkTintBlue};
`;

export const Container = styled.div`
  ${mixins.size('203px', '247px')}
  color: ${colors.darkGrey};
  text-align: left;
  border: 1px solid rgba(${hexToRgb(colors.darkBlue1)}, 0.5);
  padding-left: 10px;
  border-radius: 22px;

  &:nth-child(2) {
    ${Title} {
      border-color: ${blueColor};
    }
  }

  &:nth-child(3) {
    ${Title} {
      border-color: ${colors.lightBlue};
    }
  }
`;

export const Unit = styled.div`
  font-size: ${fontSize.xSmall};
`;

const OriginalPrice = styled.span`
  color: ${colors.red};
  font-size: ${fontSize.large};
  margin-right: 5px;
  text-decoration: line-through;
`;

const DiscountedPrice = styled.span`
  color: ${colors.black};
  font-size: ${fontSize.xLarge};
`;

const PriceContainer = styled.div`
  margin: 3px 0;
  line-height: 1;
`;

export const Price = ({ value, discount }) => {
  const format = num => num.toFixed(2);

  return (
    <PriceContainer>
      <OriginalPrice>{format(value)}</OriginalPrice>
      <DiscountedPrice>{format(value * (discount / 100))}</DiscountedPrice>
    </PriceContainer>
  );
};

export const Period = styled(Unit)`
  margin-bottom: 35px;
`;
