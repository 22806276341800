import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';
import { mixins, constants } from '@accordo-feed/aco-styled-components';
import { hexToRgb } from 'src/utils';
import { appTextColor } from 'src/App.styled';

const { colors } = constants;

export const Container = styled.div`
  position: relative;
  padding: 40px;
  box-shadow: rgba(${hexToRgb(colors.lightShadeGrey)}, 0.35) 0 0 10px;
  margin-top: -15px;
  margin-bottom: 20px;
  color: ${colors.darkShadeGrey};
`;

export const Title = styled.div`
  color: ${appTextColor};
  ${mixins.verticalLineMiddle('20px')}
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const CloseButton = styled(CloseOutlined)`
  color: ${colors.lightGrey};
  padding: 5px;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;

  &:hover {
    color: ${colors.lightShadeGrey};
  }
`;
