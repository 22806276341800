// @flow

import * as R from 'ramda';
import React, { useEffect } from 'react';
import { ConfigProvider } from 'antd';
import { compose, withProps } from 'recompose';
import { DownloadLink } from '@accordo-feed/aco-styled-components';
import { useTranslate, translateHOC } from '@accordo-feed/language.entry';

import CtaLayout from 'src/pages/dashboard/ctaLayout';
import SimpleTable from 'src/components/simpleTable';
import loaderHOC from 'src/hoc/loaderHOC';
import { TableWrapper } from 'src/pages/masterList/masterList.styled';
import { sortByAlph, sortByNumber } from 'src/utils';
import FilterPanel from './FilterPanel.container';
import FilterTags from './FilterTags.container';
import { COL_WIDTHS, TABLE_COLUMNS } from './drawer.constants';
import filterPanellang from '../filterPanel/filterPanel.lang';
import lang from './drawer.lang';
import * as Styled from './drawer.styled';

/*****************
 *   COMPONENT   *
 *****************/

const Content = ({
  actions,
  clientId,
  clientName,
  currentScore,
  dataSource,
  isDownloadActive,
  isDownloadReportLoading,
  maxScore,
  onSearchChange,
  originControlProfiles,
  params,
  percentage,
  hasFeatureSecurityFilterPanel,
  isFilterPanelOpen,
  searchTerm
}) => {
  useEffect(() => {
    actions.getSecurityControlProfileData(params.clientId);
    actions.setOpenFilterPanel(true);
    actions.setMenuCollapsedState(true);

    return () => {
      actions.resetFilterPanel();
    };
  }, []);

  const translate = useTranslate();

  const columns = R.values(TABLE_COLUMNS).map(dataIndex => {
    return {
      dataIndex,
      title: translate(lang.table[dataIndex]),
      className: 'aco-vertical-middle',
      width: COL_WIDTHS[dataIndex],
      fixed: dataIndex === TABLE_COLUMNS.IMPROVEMENT_ACTION ? 'left' : 'false',
      sorter: R.cond([
        [R.equals(TABLE_COLUMNS.IMPROVEMENT_ACTION), R.always(sortByAlph(dataIndex))],
        [R.equals(TABLE_COLUMNS.STATUS), R.always(sortByAlph(dataIndex))],
        [R.equals(TABLE_COLUMNS.SCORE), R.always(sortByNumber(dataIndex))],
        [R.equals(TABLE_COLUMNS.MAX_SCORE), R.always(sortByNumber(dataIndex))],
        [R.equals(TABLE_COLUMNS.CONTROL_CATEGORY), R.always(sortByAlph(dataIndex))],
        [R.equals(TABLE_COLUMNS.USER_IMPACT), R.always(sortByAlph(dataIndex))]
      ])(dataIndex),
      render: (text, record) => {
        const langKey = R.path([dataIndex, text], lang);

        if (langKey) {
          text = translate(langKey);
        }

        if (dataIndex === TABLE_COLUMNS.STATUS) {
          return <Styled.Status type={record[TABLE_COLUMNS.STATUS]}>{text}</Styled.Status>;
        }

        return text;
      }
    };
  });

  const onDownloadReportClick = () => {
    actions.setDownloadQueryReport({ clientId, clientName });
  };

  const tableProps = {
    dataSource,
    columns,
    pageSize: Infinity,
    x: 950,
    tableHeight: `calc(100vh - 320px)`
  };

  const totalNum = R.propOr(0, 'length', originControlProfiles);
  const filteredNum = R.propOr(0, 'length', dataSource);

  const filters = (
    <>
      <Styled.Search placeholder={translate(lang.searchPlaceholder)} value={searchTerm} onChange={onSearchChange} />
      {hasFeatureSecurityFilterPanel && !isFilterPanelOpen && (
        <Styled.ShowFilterPanel onClick={() => actions.setOpenFilterPanel(true)}>
          {translate(filterPanellang.showButton)}
        </Styled.ShowFilterPanel>
      )}
    </>
  );

  const statics = (
    <Styled.IndicatorContainer>
      <Styled.Indicator>
        {translate(lang.filteredActions, {
          num: filteredNum
        })}
        <em>
          {translate(lang.totalNum, {
            num: totalNum
          })}
        </em>
      </Styled.Indicator>
      <DownloadLink active={!isDownloadActive} onClick={onDownloadReportClick} isLoading={isDownloadReportLoading}>
        {translate(lang.downloadReport)}
      </DownloadLink>
    </Styled.IndicatorContainer>
  );

  const tableContent = (
    <TableWrapper>
      <SimpleTable {...tableProps} />
    </TableWrapper>
  );

  const widget = (
    <Styled.ScoreTable>
      <thead>
        <tr>
          <th>{translate(lang.currentScore)}</th>
          <th>{translate(lang.maxScore)}</th>
          <th>{translate(lang.scoreIndex)}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{currentScore}</td>
          <td>{maxScore}</td>
          <td>{percentage}%</td>
        </tr>
      </tbody>
    </Styled.ScoreTable>
  );

  const layoutProps = {
    className: 'at_securityScoreDetailPage',
    description: '',
    filters,
    statics,
    tableContent,
    widget,
    useTwoColumnLayout: hasFeatureSecurityFilterPanel && isFilterPanelOpen,
    siderContent: <FilterPanel />,
    filterTags: <FilterTags />
  };

  return (
    <>
      <Styled.Title>
        {translate(lang.title, {
          name: clientName,
          num: totalNum
        })}
      </Styled.Title>
      <CtaLayout {...layoutProps} />
    </>
  );
};

const ExpandedContent = compose(
  translateHOC,

  withProps(({ translate }) => ({
    loadingMsg: translate(lang.loadingMsg)
  })),

  loaderHOC({
    isKeep: true
  })
)(Content);

const Drawer = ({ drawerProps, ...contentProps }: Object) => (
  // For avoiding nested popup styling issue:
  // https://ant.design/docs/react/faq#Select-Dropdown-DatePicker-TimePicker-Popover-Popconfirm-scroll-with-the-page
  <ConfigProvider getPopupContainer={R.prop('parentNode')}>
    <Styled.Drawer {...drawerProps}>
      <ExpandedContent {...contentProps} />
    </Styled.Drawer>
  </ConfigProvider>
);

const Details = (props: Object) => (
  <ConfigProvider getPopupContainer={R.prop('parentNode')}>
    <Styled.Container>
      <ExpandedContent {...props} />
    </Styled.Container>
  </ConfigProvider>
);

export default ({ hasDrawer = true, ...props }: Object) => {
  return hasDrawer ? <Drawer {...props} /> : <Details {...props} />;
};
