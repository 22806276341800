import styled from 'styled-components';
import { constants } from '@accordo-feed/aco-styled-components';
import { appTextColor, bodyBackground } from 'src/App.styled';

const { fontSize } = constants;

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
`;

export const FilterWrapper = styled.div`
  width: 300px;
  height: 100%;
  min-width: 300px;
  padding: 20px;
  background-color: ${bodyBackground};
`;

export const FilterTitle = styled.div`
  font-size: ${fontSize.medium};
  color: ${appTextColor};
  margin-bottom: 20px;
`;

export const FilterLabel = styled.div`
  font-size: ${fontSize.small};
  color: ${appTextColor};
  margin-bottom: 7px;
`;

export const SliderFilter = styled.div`
  display: inline-block;
  margin-bottom: 20px;
`;

export const TableWrapper = styled.div`
  width: calc(100% - 300px);
  height: 100%;
`;
