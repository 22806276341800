// @flow

import React from 'react';
import { LanguageEntry, useTranslate } from '@accordo-feed/language.entry';
import { Link } from 'react-router';

import { PATHS } from 'src/constants';
import * as Styled from './globalAlert.styled';
import lang from './globalAlert.lang';

/*************
 *   TYPES   *
 *************/

type Props = {
  showGlobalAlert: boolean
};

/*****************
 *   COMPONENT   *
 *****************/

export default ({ showGlobalAlert = false }: Props) => {
  const translate = useTranslate();

  return (
    showGlobalAlert && (
      <Styled.GlobalAlert>
        <Styled.WarningIcon />
        <LanguageEntry {...lang.alertMessagePart1} />
        <Link to={PATHS.CUSTOMERS.ROOT}>{translate(lang.alertCta)}</Link>
        <LanguageEntry {...lang.alertMessagePart2} />
      </Styled.GlobalAlert>
    )
  );
};
