import * as React from 'react';

import {
  WidgetContainer,
  WidgetHeaderWrapper,
  WidgetBody,
  WidgetFooter,
  TitleContainer,
  WidgetCardTitle,
  WidgetCardSubTitle,
  Status,
  ExpandButton,
  ExpandIcon,
  ShrinkIcon
} from './widget.styled';

/*************
 *   TYPES   *
 *************/

type WidgetProps = {
  children?: React.Node | string,
  widgetId: string,
  title?: React.Node | string,
  isSubWidget?: boolean,
  icon?: React.Node,
  expanded?: boolean,
  theme?: 'light' | 'dark',
  onExpand?: Function,
  footerContent?: React.Node,
  className?: string,
  subTitle?: React.Node | string,
  status?: boolean,
  direction?: 'vertical' | 'horizontal',
  customBackground?: boolean,
  headerRight?: React.Node
};

/*****************
 *   COMPONENT   *
 *****************/

export const WidgetComponent = React.forwardRef(
  (
    {
      title,
      subTitle,
      isSubWidget = false,
      status = false,
      children,
      theme = 'light',
      expanded = false,
      footerContent = null,
      onExpand = null,
      className = '',
      widgetId,
      direction = 'vertical',
      customBackground,
      headerRight = null,
      ...props
    }: WidgetProps,
    ref
  ) => {
    let bodyHeight = title ? 49 : 0;
    bodyHeight += subTitle && direction === 'vertical' ? 18 : 0;
    bodyHeight += footerContent ? 47 : 0;
    return (
      <WidgetContainer
        id={widgetId}
        ref={ref}
        customBackground={customBackground}
        className={`${className} at_${widgetId}Widget`}
        {...props}
      >
        {title && (
          <WidgetHeaderWrapper>
            <TitleContainer direction={direction}>
              {title && (
                <WidgetCardTitle>
                  <WidgetCardTitle isSubWidget={isSubWidget}>{title}</WidgetCardTitle>
                </WidgetCardTitle>
              )}
              <WidgetCardSubTitle direction={direction}>
                {subTitle} {status ? <Status /> : ''}
              </WidgetCardSubTitle>
            </TitleContainer>
            {onExpand && <ExpandButton onClick={onExpand}>{expanded ? <ShrinkIcon /> : <ExpandIcon />}</ExpandButton>}
            {headerRight && headerRight}
          </WidgetHeaderWrapper>
        )}
        <WidgetBody height={`${bodyHeight}px`}>{children}</WidgetBody>
        {footerContent && <WidgetFooter>{footerContent}</WidgetFooter>}
      </WidgetContainer>
    );
  }
);

export default WidgetComponent;
