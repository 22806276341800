import * as R from 'ramda';

export const isEmptyOrNil = R.anyPass([R.isEmpty, R.isNil]);

const mergeObjByKey = R.curry((key, existing, obj) => {
  const index = R.findIndex(R.propEq(key, obj[key]))(existing);

  if (index !== -1 && R.not(R.equals(existing[index], obj))) {
    const newArray = R.clone(existing);

    newArray[index] = R.mergeDeepRight(newArray[index], obj);

    return newArray;
  }

  return false;
});

export const mergeObjById = mergeObjByKey('id');

export const arrayPick = (keys: Array<string>) => R.map(R.pick(keys));

export const getArrayTotal = key =>
  R.pipe(
    R.pluck(key),
    R.map(item => (/^\d+$/.test(item) ? parseInt(item, 10) : 0)),
    R.reduce(R.add, 0)
  );

export const pipeWhileNotNil = R.pipeWith((func, res) => (R.isNil(res) ? res : func(res)));

export const findKeyByMappedValue = R.curry((mapObj, value) =>
  R.find(R.o(R.equals(value), R.flip(R.prop)(mapObj)), R.keys(mapObj))
);
