// @flow

import React from 'react';

import * as Styled from './planOverview.styled';
import LeftSide from './leftSide';
import RightSide from './rightSide';

export default () => (
  <Styled.Container>
    <LeftSide />
    <RightSide />
  </Styled.Container>
);
