// @flow

import React from 'react';
import { useTranslate } from '@accordo-feed/language.entry';

import * as Styled from './Legend.styled';
import lang from 'src/pages/dashboard/widgets/securityScores/securityScores.lang';
import { isEmptyOrNil } from 'src/utils';

/*************
 *   TYPES   *
 *************/

type Props = {
  lowScore: number,
  average: number
};

/*****************
 *   COMPONENT   *
 *****************/

export default ({ lowScore, average }: Props) => {
  const translate = useTranslate();

  return (
    <Styled.LegendContainer className="at_securityLegend">
      <Styled.LegendItem>
        <Styled.LegendValue className="average">
          {!isEmptyOrNil(average) && `${Math.round(average)}`}
        </Styled.LegendValue>
        <Styled.LegendTitle>{translate(lang.yourAverage)}</Styled.LegendTitle>
      </Styled.LegendItem>

      <Styled.LegendItem>
        <Styled.LegendValue>{lowScore}</Styled.LegendValue>
        <Styled.LegendTitle>{translate(lang.customersWithLowScores)}</Styled.LegendTitle>
      </Styled.LegendItem>

      <Styled.LegendItem>
        <Styled.LegendValue className="high">**</Styled.LegendValue>
        <Styled.LegendTitle>{translate(lang.customersWithHighScores)}</Styled.LegendTitle>
      </Styled.LegendItem>
    </Styled.LegendContainer>
  );
};
