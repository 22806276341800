import * as R from 'ramda';
import moment from 'moment';
import { createSelector } from 'reselect';
import { search } from '@accordo-feed/micro-frontends-utils';

import { RECOMMENDATION_TYPE } from 'src/redux/modules/filters/filters.duck';
import {
  filtersDashboardRecommendationsSelector,
  filtersDashboardUnassignedLicensesSelector,
  filtersDashboardGrowthTrendSelector
} from 'src/redux/selectors/filters';
import { calculateFilterData } from 'src/pages/masterList/filterPopup/filterPopup.utils';
import { getModuleSelector } from 'src/redux/utils';
import { restructureOpportunitiesData } from './dashboard.utils';
import { searchTermSelector } from 'src/redux/selectors/searchTerm';
import { sortByProp, SORTING, isEmptyOrNil } from 'src/utils';
import { WIDGET } from 'src/components/widget/widget.constants';

const moduleSelector = getModuleSelector('dashboard');

export const widgetsDataSelector = createSelector(moduleSelector, R.prop('widgetsData'));

export const dashboardPageIsLoadedSelector = createSelector(moduleSelector, R.prop('isLoaded'));

export const dashboardPageLayoutSelector = createSelector(moduleSelector, R.prop('layout'));

export const dashboardIsLoadingSelector = createSelector(moduleSelector, R.prop('isLoading'));

export const dashboardPageHasErrorSelector = createSelector(moduleSelector, R.prop('hasError'));

export const dashboardMasterListSelector = createSelector(widgetsDataSelector, R.prop(WIDGET.MASTER_LIST));

export const dashboardSummarySelector = createSelector(widgetsDataSelector, R.prop(WIDGET.SUMMARY));

export const dashboardOverviewSelector = createSelector(widgetsDataSelector, R.prop(WIDGET.OVERVIEW));

export const dashboardM365LicensesSelector = createSelector(widgetsDataSelector, R.prop(WIDGET.M365_LICENSES));

export const dashboardRecommendationsSelector = createSelector(widgetsDataSelector, R.prop(WIDGET.RECOMMENDATIONS));

export const dashboardAlertsSelector = createSelector(widgetsDataSelector, R.prop(WIDGET.ALERTS));

export const dashboardNeedPermissionSelector = createSelector(dashboardAlertsSelector, R.prop('needPermissions'));

export const dashboardUnassignedLicensesSelector = createSelector(
  widgetsDataSelector,
  R.prop(WIDGET.UNASSIGNED_LICENSES)
);

export const dashboardGrowthTrendWidgetSelector = createSelector(widgetsDataSelector, R.prop(WIDGET.GROWTH_TREND));

export const dashboardGrowthTrendSelectedPathSelector = createSelector(
  dashboardGrowthTrendWidgetSelector,
  R.prop('selectedPath')
);

export const dashboardGrowthTrendSelectedDataSelector = createSelector(
  dashboardGrowthTrendWidgetSelector,
  dashboardGrowthTrendSelectedPathSelector,
  (data, selectedPath) => {
    const selectedData = R.path(R.values(selectedPath), data);
    if (isEmptyOrNil(selectedData)) return [];

    const formatDate = ({ date, value }) => ({
      date: moment(date).format('YYYY-MM-DD'),
      value: R.isNil(value) ? null : Math.round(value)
    });
    const formattedSelectedData = R.pipe(
      R.reject(({ data }) => isEmptyOrNil(data)),
      R.map(({ data, prediction, ...rest }) => ({
        ...rest,
        data: R.map(oneData => formatDate(oneData), data),
        prediction: R.map(onePrediction => formatDate(onePrediction), prediction)
      }))
    )(selectedData);

    return R.sort((a, b) => Math.abs(b.growthPercentage) - Math.abs(a.growthPercentage), formattedSelectedData);
  }
);

export const dashboardMasterListWidgetSelector = createSelector(
  dashboardMasterListSelector,
  sortByProp({ direction: SORTING.DESC, prop: 'totalAnnualSpend' })
);

export const unassignedLicensesCtaSelector = createSelector(
  dashboardMasterListSelector,
  sortByProp({ direction: SORTING.DESC, prop: 'unassignedLicenses' })
);

export const unassignedLicensesCtaFilterDataSelector = createSelector(
  dashboardMasterListSelector,
  filtersDashboardUnassignedLicensesSelector,
  calculateFilterData
);

export const unassignedLicensesCtaDataSourceSelector = createSelector(
  unassignedLicensesCtaSelector,
  searchTermSelector,
  unassignedLicensesCtaFilterDataSelector,

  (list, term, filters) => {
    const filteredList = list.filter(
      ({ activeUsers, totalMonthlySpend: totalSpend }) =>
        activeUsers >= filters.selectedMinActiveUsers &&
        activeUsers <= filters.selectedMaxActiveUsers &&
        totalSpend >= filters.selectedMinTotalSpend &&
        totalSpend <= filters.selectedMaxTotalSpend
    );

    return search({ list: filteredList, settings: ['name'] }, term);
  }
);

export const dashboardOpportunitiesSelector = createSelector(
  widgetsDataSelector,
  R.pipe(R.prop(WIDGET.OPPORTUNITIES), restructureOpportunitiesData)
);

export const opportunitiesCtaSelector = createSelector(
  dashboardMasterListSelector,
  sortByProp({ direction: SORTING.DESC, prop: 'totalRecommendations' })
);

export const opportunitiesCtaDataSourceSelector = createSelector(
  opportunitiesCtaSelector,
  searchTermSelector,
  filtersDashboardRecommendationsSelector,

  (list, term, filters) => {
    const filteredList = list.filter(
      ({ engagement, monthlyRevenue, consultingRevenue }) =>
        (filters.includes(RECOMMENDATION_TYPE.ENGAGEMENT) ? !!engagement : true) &&
        (filters.includes(RECOMMENDATION_TYPE.MONTHLY_REVENUE) ? !!monthlyRevenue : true) &&
        (filters.includes(RECOMMENDATION_TYPE.CONSULTING_REVENUE) ? !!consultingRevenue : true)
    );

    return search({ list: filteredList, settings: ['name'] }, term);
  }
);

export const growthTrendCtaSelector = createSelector(
  dashboardMasterListSelector,
  sortByProp({ direction: SORTING.DESC, prop: 'totalGrowthPercentage' })
);

export const growthTrendCtaFilterDataSelector = createSelector(
  dashboardMasterListSelector,
  filtersDashboardGrowthTrendSelector,
  calculateFilterData
);

export const growthTrendCtaDataSourceSelector = createSelector(
  growthTrendCtaSelector,
  searchTermSelector,
  growthTrendCtaFilterDataSelector,

  (list, term, filters) => {
    const filteredList = list.filter(
      ({ activeUsers, totalMonthlySpend: totalSpend }) =>
        activeUsers >= filters.selectedMinActiveUsers &&
        activeUsers <= filters.selectedMaxActiveUsers &&
        totalSpend >= filters.selectedMinTotalSpend &&
        totalSpend <= filters.selectedMaxTotalSpend
    );

    return search({ list: filteredList, settings: ['name'] }, term);
  }
);
