// @flow

import React, { useRef, useEffect } from 'react';
//import AssessmentIcon from '@material-ui/icons/Assessment';
import { useTranslate } from '@accordo-feed/language.entry';

import Legend from './Legend';
import { createChartOption } from './securityScores.utils';
import * as Styled from './securityScores.styled';
import lang from './securityScores.lang';

/*************
 *   TYPES   *
 *************/

type Props = {
  data: Object,
  isDemo: boolean,
  handleClick?: Function,
  barClickedIndex?: number,
  demoDisabled?: boolean,
  setShowOverlay?: Function,
  viewMoreAction?: boolean
};

/*****************
 *   COMPONENT   *
 *****************/

const SecurityScoresContent = ({ data, isDemo, handleClick, barClickedIndex, demoDisabled, setShowOverlay }: Props) => {
  const translate = useTranslate();
  const echartsReact = useRef(null);

  useEffect(() => {
    // $FlowIgnore
    const echartsInstance = echartsReact.current.getEchartsInstance();
    echartsInstance.setOption(createChartOption(data, barClickedIndex));
  });

  const onEvents = {
    click: handleClick
  };

  return (
    <Styled.ContentWrapper>
      <Styled.InfoBoxWrap>
        {isDemo ? (
          <Styled.InfoBox>
            <Styled.InfoText>{translate(lang.whySecureScores)}</Styled.InfoText>
            <Styled.InfoText>{translate(lang.weCanGive)}</Styled.InfoText>
          </Styled.InfoBox>
        ) : (
          <Legend lowScore={data.lowScore} average={data.average} />
        )}
      </Styled.InfoBoxWrap>

      <Styled.ScoreChartWrap>
        <Styled.SectionTitleWrap>
          <Styled.SectionTitle>{translate(lang.lowScore)}</Styled.SectionTitle>
          <Styled.SectionTitle>{translate(lang.moderate)}</Styled.SectionTitle>
          <Styled.SectionTitle>{translate(lang.highScore)}</Styled.SectionTitle>
        </Styled.SectionTitleWrap>

        <Styled.Chart option={createChartOption(data, barClickedIndex)} onEvents={onEvents} ref={echartsReact} />

        <Styled.ButtonsContainer>
          {/*!demoDisabled && (
            <>
              <Styled.DemoButton
                onClick={() => {
                  // Disable overlay show on click since overlay UI is broken
                  // setShowOverlay(true)
                  return;
                }}
              >
                <Styled.GraphIcon element={AssessmentIcon} />
                <Styled.DemoButtonText>{translate(lang.demoData)}</Styled.DemoButtonText>
              </Styled.DemoButton>
            </>
              )*/}
        </Styled.ButtonsContainer>
      </Styled.ScoreChartWrap>
    </Styled.ContentWrapper>
  );
};

export default SecurityScoresContent;
