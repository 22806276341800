import * as R from 'ramda';
import { createSelector } from 'reselect';
import { search } from '@accordo-feed/micro-frontends-utils';

import { pageSelector } from 'src/pages/pages.selector';
import { FILTER_TEXT_MAP, TABLE_COLUMNS } from './drawer.constants';
import { applyFilters } from './drawer.utils';

export const moduleSelector = createSelector(pageSelector, R.path(['securityScore', 'drawer']));

export const dataSelector = createSelector(moduleSelector, R.prop('data'));

export const originControlProfilesSelector = createSelector(dataSelector, R.prop('controlProfiles'));

export const clientIdSelector = createSelector(moduleSelector, R.prop('clientId'));

export const isLoadingSelector = createSelector(moduleSelector, R.prop('isLoading'));

export const isOpenPanelSelector = createSelector(moduleSelector, R.prop('isOpenPanel'));

export const isOpenSelector = createSelector(moduleSelector, R.prop('isOpen'));

export const searchTermSelector = createSelector(moduleSelector, R.prop('searchTerm'));

export const isTooltipDisabledSelector = createSelector(moduleSelector, R.prop('isTooltipdisabled'));

const filtersSelector = createSelector(moduleSelector, R.prop('filters'));

const statusFiltersSelector = createSelector(filtersSelector, R.prop(TABLE_COLUMNS.STATUS));

const controlCategoryFiltersSelector = createSelector(filtersSelector, R.prop(TABLE_COLUMNS.CONTROL_CATEGORY));

const userImpactFiltersSelector = createSelector(filtersSelector, R.prop(TABLE_COLUMNS.USER_IMPACT));

export const allFilterSelector = createSelector(
  [statusFiltersSelector, controlCategoryFiltersSelector, userImpactFiltersSelector],
  (a, b, c) => ({
    [TABLE_COLUMNS.STATUS]: R.defaultTo([], a),
    [TABLE_COLUMNS.CONTROL_CATEGORY]: R.defaultTo([], b),
    [TABLE_COLUMNS.USER_IMPACT]: R.defaultTo([], c)
  })
);

export const dataSourceSelector = createSelector(
  [dataSelector, searchTermSelector, allFilterSelector],
  (data, term, filters) =>
    applyFilters(
      search({ list: R.propOr([], 'controlProfiles', data), settings: ['improvementAction'] }, term),
      filters
    )
);

export const isDrawerEmptySelector = createSelector(dataSelector, R.pipe(R.prop('controlProfiles'), R.isEmpty));

export const isDownloadReportLoadingSelector = createSelector(moduleSelector, R.prop('isCsvLoading'));

export const filterTagSelector = createSelector(
  allFilterSelector,
  R.pipe(
    R.mapObjIndexed((val, key) =>
      R.isNil(val) ? [] : R.map(item => `${FILTER_TEXT_MAP[key] ? `${FILTER_TEXT_MAP[key]}: ` : ''}${item}`, val)
    ),
    R.values,
    R.flatten
  )
);

export const refreshLocalDropdownSelector = createSelector(moduleSelector, R.prop('refreshLocalDropdownState'));
