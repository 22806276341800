// @flow

import * as R from 'ramda';
import { createAction } from 'redux-actions';
import { redux } from '@accordo-feed/micro-frontends';

import type { Action } from 'src/redux/types';
import { wrapWithModule } from 'src/redux/utils';

const { setState } = redux;

/*************
 *   TYPES   *
 *************/

type StateType = {
  masterList: {
    minActiveUsers: ?number,
    maxActiveUsers: ?number,
    minTotalSpend: ?number,
    maxTotalSpend: ?number,
    recommendations: Array<boolean>
  },
  dashboardUnassignedLicenses: {
    minActiveUsers: ?number,
    maxActiveUsers: ?number,
    minTotalSpend: ?number,
    maxTotalSpend: ?number
  },
  dashboardGrowthTrend: {
    minActiveUsers: ?number,
    maxActiveUsers: ?number,
    minTotalSpend: ?number,
    maxTotalSpend: ?number
  },
  secureScore: {
    minCurrentScore: ?number,
    maxCurrentScore: ?number,
    minScoreIndex: ?number,
    maxScoreIndex: ?number,
    barClickedIndex: number
  }
};

/*********************
 *   INITIAL STATE   *
 *********************/

export const RECOMMENDATION_TYPE = {
  ENGAGEMENT: 'ENGAGEMENT',
  MONTHLY_REVENUE: 'MONTHLY_REVENUE',
  CONSULTING_REVENUE: 'CONSULTING_REVENUE'
};

export const initialState: StateType = {
  masterList: {
    minActiveUsers: null,
    maxActiveUsers: null,
    minTotalSpend: null,
    maxTotalSpend: null,
    recommendations: []
  },
  dashboardRecommendations: [],
  dashboardUnassignedLicenses: {
    minActiveUsers: null,
    maxActiveUsers: null,
    minTotalSpend: null,
    maxTotalSpend: null
  },
  dashboardGrowthTrend: {
    minActiveUsers: null,
    maxActiveUsers: null,
    minTotalSpend: null,
    maxTotalSpend: null
  },
  secureScore: {
    minCurrentScore: null,
    maxCurrentScore: null,
    minScoreIndex: 0,
    maxScoreIndex: 100,
    barClickedIndex: -1
  }
};

/***************
 *   ACTIONS   *
 ***************/

const wrapWithNamespace = wrapWithModule('modules/filters');
const SET_MASTER_LIST_ACTIVE_USER_FILTER = wrapWithNamespace('SET_MASTER_LIST_ACTIVE_USER_FILTER');
const SET_MASTER_LIST_TOTAL_SPEND_FILTER = wrapWithNamespace('SET_MASTER_LIST_TOTAL_SPEND_FILTER');
const SET_MASTER_LIST_RECOMMENDATION_FILTER = wrapWithNamespace('SET_MASTER_LIST_RECOMMENDATION_FILTER');
const SET_DASHBOARD_RECOMMENDATIONS_FILTER = wrapWithNamespace('SET_DASHBOARD_RECOMMENDATIONS_FILTER');
const CLEAR_MASTER_LIST_FILTERS = wrapWithNamespace('CLEAR_MASTER_LIST_FILTERS');
const SET_UNASSIGNED_LICENSES_ACTIVE_USER_FILTER = wrapWithNamespace('SET_UNASSIGNED_LICENSES_ACTIVE_USER_FILTER');
const SET_UNASSIGNED_LICENSES_TOTAL_SPEND_FILTER = wrapWithNamespace('SET_UNASSIGNED_LICENSES_TOTAL_SPEND_FILTER');
const SET_GROWTH_TREND_ACTIVE_USER_FILTER = wrapWithNamespace('SET_GROWTH_TREND_ACTIVE_USER_FILTER');
const SET_GROWTH_TREAND_TOTAL_SPEND_FILTER = wrapWithNamespace('SET_GROWTH_TREAND_TOTAL_SPEND_FILTER');
const SET_SECURE_SCORE_CURRENT_SCORE_FILTER = wrapWithNamespace('SET_SECURE_SCORE_CURRENT_SCORE_FILTER');
const SET_SECURE_SCORE_PERCENTAGE_FILTER = wrapWithNamespace('SET_SECURE_SCORE_PERCENTAGE_FILTER');
const RESET_FILTER = wrapWithNamespace('RESET_FILTER');
const SET_SECURE_SCORE_BAR_CLICKED_INDEX = wrapWithNamespace('SET_SECURE_SCORE_BAR_CLICKED_INDEX');

export const setMasterListActiveUserFilter = createAction(SET_MASTER_LIST_ACTIVE_USER_FILTER);
export const setMasterListTotalSpendFilter = createAction(SET_MASTER_LIST_TOTAL_SPEND_FILTER);
export const setMasterListRecommendationFilter = createAction(SET_MASTER_LIST_RECOMMENDATION_FILTER);
export const setDashboardRecommendationsFilter = createAction(SET_DASHBOARD_RECOMMENDATIONS_FILTER);
export const clearMasterListFilters = createAction(CLEAR_MASTER_LIST_FILTERS);
export const setUnassignedLicensesActiveUserFilter = createAction(SET_UNASSIGNED_LICENSES_ACTIVE_USER_FILTER);
export const setUnassignedLicensesTotalSpendFilter = createAction(SET_UNASSIGNED_LICENSES_TOTAL_SPEND_FILTER);
export const setGrowthTrendActiveUserFilter = createAction(SET_GROWTH_TREND_ACTIVE_USER_FILTER);
export const setGrowthTrendTotalSpendFilter = createAction(SET_GROWTH_TREAND_TOTAL_SPEND_FILTER);

export const setSecureScoreCurrentScoreFilter = createAction(SET_SECURE_SCORE_CURRENT_SCORE_FILTER);
export const setSecureScorePercentageFilter = createAction(SET_SECURE_SCORE_PERCENTAGE_FILTER);
export const resetFilter = createAction(RESET_FILTER);
export const setSecureScoreBarClickedIndex = createAction(SET_SECURE_SCORE_BAR_CLICKED_INDEX);

/***************
 *   REDUCER   *
 ***************/

const setFilter = stateKey => (
  key: string | Array<string>,
  transform: Function = (key, payload, state) => ({
    ...state,
    [key]: payload
  })
) => (state, payload) =>
  setState(stateKey)(state, {
    ...state[stateKey],
    ...transform(key, payload, state[stateKey])
  });

const setMasterListFilter = setFilter('masterList');

const setMasterListActiveUserReducer = setMasterListFilter(['minActiveUsers', 'maxActiveUsers'], (key, payload) =>
  R.zipObj(key, payload)
);

const setMasterListTotalSpendReducer = setMasterListFilter(['minTotalSpend', 'maxTotalSpend'], (key, payload) =>
  R.zipObj(key, payload)
);

const setMasterListRecommendationReducer = setMasterListFilter('recommendations');

const setUnassignedLicensesFilter = setFilter('dashboardUnassignedLicenses');

const setUnassignedLicensesActiveUserReducer = setUnassignedLicensesFilter(
  ['minActiveUsers', 'maxActiveUsers'],
  (key, payload) => R.zipObj(key, payload)
);

const setUnassignedLicensesTotalSpendReducer = setUnassignedLicensesFilter(
  ['minTotalSpend', 'maxTotalSpend'],
  (key, payload) => R.zipObj(key, payload)
);

const setGrowthTrendFilter = setFilter('dashboardGrowthTrend');

const setGrowthTrendActiveUserReducer = setGrowthTrendFilter(['minActiveUsers', 'maxActiveUsers'], (key, payload) =>
  R.zipObj(key, payload)
);

const setGrowthTrendTotalSpendReducer = setGrowthTrendFilter(['minTotalSpend', 'maxTotalSpend'], (key, payload) =>
  R.zipObj(key, payload)
);

const setSecureScoreFilter = setFilter('secureScore');

const setSecureScoreCurrentScoreReducer = setSecureScoreFilter(['minCurrentScore', 'maxCurrentScore'], (key, payload) =>
  R.zipObj(key, payload)
);

const setSecureScoreScoreIndexReducer = setSecureScoreFilter(['minScoreIndex', 'maxScoreIndex'], (key, payload) =>
  R.zipObj(key, payload)
);

export default (state: StateType = initialState, action: Action) => {
  const { type, payload } = action;

  const reducer = {
    [SET_MASTER_LIST_ACTIVE_USER_FILTER]: setMasterListActiveUserReducer,
    [SET_MASTER_LIST_TOTAL_SPEND_FILTER]: setMasterListTotalSpendReducer,
    [SET_MASTER_LIST_RECOMMENDATION_FILTER]: setMasterListRecommendationReducer,
    [CLEAR_MASTER_LIST_FILTERS]: state => ({
      ...state,
      masterList: initialState.masterList
    }),
    [SET_DASHBOARD_RECOMMENDATIONS_FILTER]: setState('dashboardRecommendations'),
    [SET_UNASSIGNED_LICENSES_ACTIVE_USER_FILTER]: setUnassignedLicensesActiveUserReducer,
    [SET_UNASSIGNED_LICENSES_TOTAL_SPEND_FILTER]: setUnassignedLicensesTotalSpendReducer,
    [SET_GROWTH_TREND_ACTIVE_USER_FILTER]: setGrowthTrendActiveUserReducer,
    [SET_GROWTH_TREAND_TOTAL_SPEND_FILTER]: setGrowthTrendTotalSpendReducer,
    [SET_SECURE_SCORE_CURRENT_SCORE_FILTER]: setSecureScoreCurrentScoreReducer,
    [SET_SECURE_SCORE_PERCENTAGE_FILTER]: setSecureScoreScoreIndexReducer,
    [SET_SECURE_SCORE_BAR_CLICKED_INDEX]: (state, value) =>
      R.mergeDeepLeft(
        {
          secureScore: { barClickedIndex: value }
        },
        state
      ),
    [RESET_FILTER]: (state, subFilterName) => ({ ...state, [subFilterName]: initialState[subFilterName] })
  }[type];

  return reducer ? reducer(state, payload) : state;
};
