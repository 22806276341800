import React from 'react';
import { Responsive } from 'react-grid-layout';
import { SizeMe } from 'react-sizeme';
import { useTranslate } from '@accordo-feed/language.entry';

import Widget from 'src/components/widget';
import ViewMore from 'src/components/viewMore';
import { PATHS } from 'src/constants';
import { BREAKPOINTS, COLS, LAYOUTS } from './constants';
import AzureUsage from './azureUsage';
import AzureBudget from './azureBudget';
import lang from './azureDashboard.lang';

/*************
 *   TYPES   *
 *************/

type Props = {
  theme?: 'light' | 'dark'
};

/*****************
 *   COMPONENT   *
 *****************/

const AzureDashboard = ({ theme = 'light' }: Props) => {
  const translate = useTranslate();

  const WIDGETS = [
    <Widget
      key="azureDashboard"
      title={translate(lang.widget.usageTitle)}
      subTitle={translate(lang.widget.usageSubTitle)}
    >
      <AzureUsage />
    </Widget>,
    <Widget
      key="azureBudgetMonitor"
      title={translate(lang.widget.budgetTitle)}
      subTitle={translate(lang.widget.budgetSubTitle)}
      footerContent={<ViewMore path={PATHS.AZURE_DASHBOARD.ROOT} />}
    >
      <AzureBudget />
    </Widget>
  ];

  const renderWidgets = () => {
    return WIDGETS.map(e => e);
  };

  return (
    <SizeMe>
      {({ size }) => (
        <Responsive
          breakpoints={BREAKPOINTS}
          cols={COLS}
          containerWidth={size.width ? size.width : 0}
          width={size.width ? size.width : 0}
          className="layout"
          rowHeight={80}
          containerPadding={[24, 30]}
          margin={[24, 24]}
          isResizable={false}
          isDraggable={false}
          layouts={LAYOUTS}
        >
          {renderWidgets()}
        </Responsive>
      )}
    </SizeMe>
  );
};

export default AzureDashboard;
