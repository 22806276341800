import styled from 'styled-components';
import { mixins, constants } from '@accordo-feed/aco-styled-components';
import { clearFix } from 'polished';
import { Link } from 'react-router';

import { SectionWrapper, ActionWrapper } from 'src/pages/page.styled';
import { WidgetHeaderWrapper, WidgetBodyWrapper, WidgetActionWrapper } from 'src/components/widget/widget.styled';
import { appTextColor, bodyBackground } from 'src/App.styled';

const { colors, fontSize } = constants;

const buttonStyles = `
  && {
    color: ${colors.blackColor};

    &:hover {
      color: ${colors.blue};
      border-color: ${colors.blue};
    }
  }
`;

// Override styles from the widget view
export const CtaLayoutWrapper = styled.div`
  ${SectionWrapper} {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  ${WidgetBodyWrapper} {
    padding: 0;
  }

  ${WidgetHeaderWrapper},
  ${WidgetActionWrapper} {
    display: none;
  }

  ${ActionWrapper} {
    margin-bottom: 10px;
  }
`;

export const InPageNavWrapper = styled.div`
  padding: 12px 40px 12px 20px;
  background-color: ${colors.hoverGrey};
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  color: ${colors.blackColor};
`;

export const PageTitle = styled.h1`
  font-size: ${fontSize.large};
  line-height: 1.75;
  margin: 0;
`;

export const BackBtn = styled(Link)`
  && {
    color: ${colors.blackColor};
    line-height: 35px;
    ${buttonStyles}
  }
`;

export const MasterListBtn = styled(Link)`
  && {
    border: 1px solid ${colors.darkGrey};
    color: ${colors.blackColor};
    line-height: 23px;
    text-align: center;
    padding: 5px 10px;
    ${buttonStyles}
  }
`;

export const WidgetIntroWrapper = styled.div`
  font-size: ${fontSize.normal};
  line-height: 1.375;
  color: ${colors.aquaBlue3};
  padding-left: 30px;

  h2 {
    color: ${colors.aquaBlue3} !important;
    font-size: ${fontSize.medium};
    line-height: 1.333;
    margin: 0 0 20px 0;
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const WidgetWrapper = styled.div`
  max-width: 450px;
`;

export const ActionPanel = styled.div`
  ${mixins.flexVerticalCenter()};
  width: 100%;
  margin-bottom: 10px;
`;

export const FiltersContainer = styled.div`
  ${clearFix()};
  flex: 1;
`;

export const FilterWording = styled.span`
  color: ${appTextColor};
`;

export const StaticsWrapper = styled.div`
  color: ${appTextColor};
  display: flex;
  justify-content: space-between;
  margin: 30px 0 10px;
  height: 30px;
`;

export const GridWrapper = styled.div`
  background-color: ${bodyBackground};
  padding: 0 16px 24px;

  &.at_securityScoreDetailPage {
    padding: 0;
  }
`;

export const TwoColumnLayoutWrapper = styled.div`
  padding: 0 16px;

  &.at_securityScoreDetailPage {
    padding: 0;
  }
`;

export const MainContent = styled.div`
  ${props => (props.hideSider ? 'padding: 0;' : 'margin-left: 16px;')};
`;
