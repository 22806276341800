export const getApiName = (url: string) => {
  const regEx = /(accordo\.com\/api|api.accordo.io)\/(.+?)\//i;
  const match = regEx.exec(url);

  return match ? match[2] : null;
};

export const isValidImage = async (url: string) => {
  const res = await fetch(url);
  const buff = await res.blob();
  return buff.type.startsWith('image/');
};
