// @flow

import React, { useEffect } from 'react';
import { notification } from 'antd';

/*************
 *   TYPES   *
 *************/

type Props = {
  title: string,
  header: string,
  message: string,
  errorCode: string,
  notificationType?: string
};

/***************
 *   HELPERS   *
 ***************/

const openNotification = ({ title, header, message, errorCode, notificationType }: Props) => {
  const type = notificationType || (errorCode ? 'error' : 'warning');
  const duration = errorCode ? 0 : 10;
  notification[type]({
    message: title || header,
    description: message,
    duration
  });
};

/*****************
 *   COMPONENT   *
 *****************/

const ErrorToaster = (props: Props) => {
  useEffect(() => {
    openNotification(props);
  }, [props]);
  return <div />;
};

export default ErrorToaster;
