// @flow

import * as R from 'ramda';
import React from 'react';
import { useTranslate } from '@accordo-feed/language.entry';

import CtaLayout from 'src/pages/dashboard/ctaLayout';
import Widget from 'src/components/widget';
import Search from 'src/components/search';
import Statics from 'src/pages/dashboard/ctaLayout/Statics.component';
import SimpleTable from 'src/components/simpleTable';
import { PATHS } from 'src/constants';
import { onSearchChange } from 'src/components/search/seach.util';
import Drawer from './drawer';
import FilterPanel from './filterPanel';
import FilterTags from './FilterTags.container';
import OldFilter from './oldFilter';
import { CustomRow, TableColumns as columns } from './securityScore.config';
import * as Styled from './securityScore.styled';
import filterPanellang from './filterPanel/filterPanel.lang';
import lang from './securityScore.lang';

/*************
 *   TYPES   *
 *************/

type Props = {
  actions: Object,
  clickedClientId: string,
  data: Object,
  hasFeatureSecurityFilterPanel: boolean,
  isFilterPanelOpen: boolean,
  isLoading: boolean,
  onClearAllTags: Function,
  onRemoveFilterTag: Function,
  permissionCount: number,
  route: Object,
  router: Object,
  searchTerm: string,
  summary: Object
};

/*****************
 *   COMPONENT   *
 *****************/

export default ({
  actions,
  clickedClientId,
  data,
  hasFeatureSecurityFilterPanel,
  isFilterPanelOpen,
  isLoading,
  onClearAllTags,
  onRemoveFilterTag,
  permissionCount,
  route,
  router,
  searchTerm,
  summary: { totalCustomers }
}: Props) => {
  const translate = useTranslate();

  const customers = data.clients;

  const dataSource =
    customers[0] && customers[0].item
      ? customers.map(customer => ({ ...customer.item, refIndex: customer.refIndex }))
      : customers;

  const tableProps = {
    columns,
    dataSource,
    loading: isLoading,
    pageSize: Infinity,
    components: { body: { row: CustomRow } },
    x: 1400,
    tableHeight: `calc(100vh - 310px)`,

    onRow: record => ({
      onClick: e => {
        router.push(`${PATHS.SECURITY_SCORE.ROOT}/${record.id}`);
      }
    }),

    rowClassName: R.ifElse(R.propEq('id', clickedClientId), R.always(Styled.CLICKED_ROW_CLS), R.always(''))
  };

  const staticsProps = {
    active: dataSource.length,
    total: R.propOr(0, 'count', data),
    hasClient: totalCustomers > 0,
    permissionCount
  };

  const tableContent = (
    <Styled.TableWrapper>
      <Widget widgetId="secure-score-index">
        <SimpleTable {...tableProps} />
      </Widget>
    </Styled.TableWrapper>
  );

  const filters = hasFeatureSecurityFilterPanel ? (
    <>
      <Styled.SearchBox searchTerm={searchTerm} onChange={onSearchChange(actions.setSearchTerm)} />
      {!isFilterPanelOpen && (
        <>
          <Styled.ShowFilterPanel onClick={() => actions.setFilterPanelOpen(true)}>
            {translate(filterPanellang.showButton)}
          </Styled.ShowFilterPanel>
        </>
      )}
    </>
  ) : (
    <>
      <OldFilter />
      <Search searchTerm={searchTerm} onChange={onSearchChange(actions.setSearchTerm)} />
    </>
  );

  const statics = <Statics {...staticsProps} />;

  const layoutProps = {
    className: 'at_securityScorePage',
    description: '',
    filters,
    statics,
    tableContent,
    title: translate(lang.pageTitle),
    useTwoColumnLayout: hasFeatureSecurityFilterPanel && isFilterPanelOpen,
    siderContent: <FilterPanel />,
    filterTags: <FilterTags />
  };

  const drawerProps = {
    route
  };

  return (
    <Styled.Container>
      <CtaLayout {...layoutProps} />
      <Drawer {...drawerProps} />
    </Styled.Container>
  );
};
