// @flow

import React from 'react';
import { useTranslate } from '@accordo-feed/language.entry';

import { LINK } from './footerLink.constants';
import { lang } from './appFooter.lang';
import { StyledAnchor } from './appFooter.styled';

/*************
 *   TYPES   *
 *************/

type LinkType = typeof LINK.TERMS | typeof LINK.PRIVACY;

/*****************
 *   COMPONENT   *
 *****************/

const FooterLink = ({ type, ...rest }: LinkType) => {
  const translate = useTranslate();

  return (
    <StyledAnchor href={translate(lang[`${type}LinkUrl`])} target="_blank" rel="noopener noreferrer" {...rest}>
      {translate(lang[`${type}LinkText`])}
    </StyledAnchor>
  );
};

export default FooterLink;
