import styled, { css } from 'styled-components';
import { constants, TagInput as AcoTagInput } from '@accordo-feed/aco-styled-components';
import { Input as AntInput, Col, Divider, Switch } from 'antd';
import { hexToRgb } from 'src/utils';
import ErrorMessage from 'src/components/errorMessage';
import {
  appLabelColor,
  appTextColor,
  tableRowBorderColor,
  widgetBorderColor,
  subHeaderTextColor,
  scrollbarHandlerColor
} from 'src/App.styled';

const { colors, fontSize } = constants;

export const InputField = styled(AntInput)`
  color: ${appTextColor};
  flex: 1;
  height: 40px;
  font-size: ${fontSize.small};
  border: 1px solid ${tableRowBorderColor};
  background-color: ${widgetBorderColor};
`;

export const CustomDivider = styled(Divider)`
  background-color: ${tableRowBorderColor};
`;

export const Title = styled.h3`
  color: ${appLabelColor};
  font-size: ${fontSize.normal};
  font-weight: 500;
  margin-bottom: 3px;
`;

export const SubTitle = styled.h3`
  color: ${appLabelColor};
  font-size: ${fontSize.normal};
  font-weight: 400;
  margin-bottom: 20px;
`;

export const Description = styled.div`
  color: ${subHeaderTextColor};
  font-size: ${fontSize.small};
  line-height: 1.5;
  a {
    text-decoration: underline;
  }
`;

export const FieldLabel = styled.div`
  color: ${appLabelColor};
  margin-bottom: 5px;
`;

export const FieldIntro = styled.div`
  color: ${subHeaderTextColor};
  font-size: ${fontSize.small};
`;

export const FieldValue = styled.div`
  color: ${appTextColor};
  margin-top: 4px;
`;

export const CustomTagInput = styled(AcoTagInput)`
  width: 100%;
  border-radius: 7px;
  border: 1px solid ${colors.darkGrey6};
  background-color: rgba(${hexToRgb(colors.lightGrey7)}, 0.24);
  margin-top: 4px;
  .ant-tag {
    color: ${appTextColor} !important;
    border: 1px solid ${colors.darkGrey7};
    background-color: rgba(${hexToRgb(colors.lightGrey7)}, 0.24);
    .anticon {
      &.anticon-close {
        color: ${appTextColor} !important;
      }
    }
  }
  .ant-input {
    color: ${appTextColor} !important;
    border: none;
    background-color: transparent;
    padding: 0 10px;
    box-shadow: none;
    &::placeholder {
      color: ${appTextColor};
    }
  }
`;

export const FormRow = styled.div`
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom + 'px' : '24px')};

  .ant-select-selection:first-child {
    border-top-left-radius: 2px !important;
    border-bottom-left-radius: 2px !important;
  }

  .ant-select-selection-selected-value,
  .ant-select-search,
  .ant-input {
    font-size: ${fontSize.small};
    border-radius: 7px;
    border: 1px solid ${colors.darkGrey6};
    background-color: rgba(${hexToRgb(colors.lightGrey7)}, 0.24);
  }

  .ant-input-group {
    flex: 1;
    display: flex !important;

    .ant-select {
      width: 8em;
      .ant-select-selection {
        display: flex;
        align-items: center;
        border: 1px solid ${colors.darkGrey6};
        background-color: rgba(${hexToRgb(colors.lightGrey7)}, 0.24);
      }
      .ant-select-selection-selected-value {
        border: 0;
        background: none;
        padding: 0;
      }
      .ant-select-search--inline {
        background: none;
        border: 0;
      }
      .ant-select-selection__rendered {
        padding-right: 10px;
      }
      .ant-select-selection--single {
        height: 40px;
      }

      &&:focus {
        box-shadow: none;
        border-color: ${colors.lightBlue};
      }
    }

    .ant-input {
      border-radius: 2px;
    }
  }

  .ant-checkbox-group {
    flex: 1;
    margin-right: -15px;
    margin-bottom: -10px;
    overflow: hidden;

    .ant-checkbox-group-item {
      width: 45%;
    }
  }

  && {
    .ant-input:focus {
      border-color: ${colors.blue};
    }

    .ant-select-open .ant-select-selection {
      border-color: ${colors.blue};
      box-shadow: none;
      margin-right: 1px;
    }
  }
`;

export const ErrMsg = styled(ErrorMessage)`
  margin: 5px 0 0 135px;
  ${props =>
    props.noLeftGap &&
    css`
      margin-left: 0;
    `}
`;

export const ToggleSwitchContainer = styled.div`
  margin-bottom: 35px;
`;

export const ToggleSwitch = styled(Switch)`
  background-color: ${scrollbarHandlerColor};
  &.ant-switch-checked {
    background-color: #1890ff;
  }
`;

export const SubmitRow = styled.div`
  margin-top: 20px;

  a {
    font-size: ${fontSize.xSmall};
    text-decoration: underline;
    margin-right: 10px;
  }
`;

export const CheckboxGroupWrap = styled.div`
  margin-top: 10px;
`;

export const ColField = styled(Col)`
  padding-right: 0 !important;
  min-height: 85px;
  .ant-select-arrow {
    svg {
      fill: ${colors.darkGrey6};
    }
  }
  &&& {
    > input,
    .ant-select-selector {
      color: ${appTextColor};
      height: 40px;
      border-radius: 7px;
      border: 1px solid ${colors.darkGrey6};
      background-color: rgba(${hexToRgb(colors.lightGrey7)}, 0.24);
    }

    > input {
      margin-bottom: 4px;
      &:focus {
        border: 1px solid ${colors.darkCyan} !important;
      }
    }
  }
`;

export const WidgetBodyInner = styled.div`
  height: 100%;
`;
