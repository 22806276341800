import * as R from 'ramda';

import routesMachine, { getFinalPath } from 'src/statecharts/routes';
import { checkAuth } from './auth';

const getPath = state => {
  const pathname = R.path(['location', 'pathname'], state);
  if (R.is(String, pathname)) {
    return pathname.startsWith('/') ? pathname : `/${pathname}`;
  }
  return null;
};

/**
 * Called when a route is about to be entered. It provides the next router state and a function to redirect to another path.
 * 'this' will be the route instance that triggered the hook.
 * Please see https://github.com/ReactTraining/react-router/blob/v3/docs/API.md#onenternextstate-replace-callback
 * nextState.location.pathname
 * @param {object} prevState previous router state
 * @param {object} nextState next router state
 * @param {object} nextState.location the next router location
 * @param {string} nextState.location.pathname the next router path
 * @param {string} nextState.location.action can be POP|REPLACE
 * @param {function} replace use this function if you want to redirect to another path
 * @callback {function} callback this hook will run asynchronously, and the transition will block until callback is called
 */
const validateRoute = (prevState, nextState, replace, callback, store) => {
  const path = getPath(nextState);
  const prevPath = getPath(prevState);

  const handleCheck = () => {
    let finalPath;
    let nextState = routesMachine.initialState;
    const state = store.getState();

    do {
      nextState = routesMachine.transition(nextState, {
        type: 'NAVIGATE',
        path,
        prevPath,
        state
      });
      finalPath = getFinalPath(nextState);

      // Can uncomment the below log statements for debugging
      // console.log('nextState', nextState.value);
      // console.log('finalPath', finalPath);
    } while (nextState.value !== 'originalRoute' && !finalPath);

    if (finalPath && finalPath !== path) {
      replace(finalPath);
    }

    return callback();
  };

  return checkAuth(nextState, replace, handleCheck);
};

const onEnter = store => (nextState, replace, callback) => validateRoute({}, nextState, replace, callback, store);

const onChange = store => (prevState, nextState, replace, callback) =>
  validateRoute(prevState, nextState, replace, callback, store);

const handlers = {
  onChange,
  onEnter
};

export default (store, events = ['onEnter']) =>
  events.reduce(
    (acc, evt) => ({
      ...acc,
      [evt]: handlers[evt](store)
    }),
    {}
  );
