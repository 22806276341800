// @flow

import React from 'react';
import { useTranslate } from '@accordo-feed/language.entry';
import { Row, Col } from 'antd';

import { Title, Description } from 'src/CustomComponent.styled';
import lang from './cancellation.lang';

export default () => {
  const translate = useTranslate();
  return (
    <Row gutter={[24, 0]}>
      <Col xs={{ span: 24 }} lg={{ span: 8 }}>
        <Title>{translate(lang.heading)}</Title>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 12 }}>
        <Description>{lang.description}</Description>
      </Col>
    </Row>
  );
};
