import * as R from 'ramda';
import bodybuilder from 'bodybuilder';

import { isEmptyOrNil } from 'src/utils';
import { filtersInit } from './drawer.duck';

export const genControlProfileQuery = ({ clientId, partnerId }) => ({
  queryName: 'securityControlProfileTable',
  partnerId,
  esQuery: bodybuilder()
    .rawOption('_source', [
      'id',
      'partnerId',
      'clientName',
      'currentScore',
      'maxScore',
      'percentage',
      'controlScores.improvementAction',
      'controlScores.status',
      'controlScores.score',
      'controlScores.maxScore',
      'controlScores.controlCategory',
      'controlScores.userImpact'
    ])
    .filter('term', 'id', clientId)
    .filter('term', 'partnerId', partnerId)
    .build()
});

export const genDownloadReportQuery = ({ clientId, partnerId }) => ({
  queryName: 'Customer:SecurityScore',
  partnerId,
  esQuery: bodybuilder()
    .size(10000)
    .filter('term', 'id', clientId)
    .filter('term', 'partnerId', partnerId)
    .build()
});

export const applyFilters = R.curry((dataSource, filters) => {
  const data = R.path([0, 'item'], dataSource)
    ? dataSource.map(e => ({ ...e.item, refIndex: e.refIndex }))
    : dataSource;

  if (R.isNil(filters) || !R.equals(R.keys(filters), R.keys(filtersInit))) return data;

  const matchFilter = R.curry((key, item) => {
    const currentFilters = filters[key];
    return R.isEmpty(currentFilters) || R.includes(item[key], currentFilters);
  });

  return R.filter(R.allPass(R.reduce((acc, key) => R.concat([matchFilter(key)], acc), [], R.keys(filters))), data);
});

/*************************
 *   TRANSFORM HELPERS   *
 *************************/

/**
 * Transform the security page ES response to the format antd table understands
 * @param {Object} response
 * @returns {Object}
 */
export const transformControlProfileResponse = response => {
  const commonPath = ['hits', 'hits', 0, '_source'];

  const pickVal = (key, fallback = '', mappedAttr) => ({
    [key]: R.pathOr(fallback, [...commonPath, mappedAttr || key], response)
  });

  if (isEmptyOrNil(R.path(commonPath, response))) {
    return {};
  }

  return {
    ...pickVal('clientName'),
    ...pickVal('currentScore', 0),
    ...pickVal('maxScore', 0),
    ...pickVal('percentage', 0),
    ...pickVal('controlProfiles', [], 'controlScores')
  };
};
