import styled from 'styled-components';
import { Link } from 'react-router';
import Widget from 'src/components/widget';

import { appTextColor } from 'src/App.styled';

export const CustomWidget = styled(Widget)`
  margin-top: 25px;
`;
export const LinkName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${appTextColor};
  text-decoration: underline;
  max-width: 130px;

  &.aco-light-red {
    color: #d8363d;
  }
`;

export const VisitLink = styled(Link)`
  text-decoration: none;
  display: inline-block;

  &.aco-text-left {
    text-align: left;
  }
`;
