// @flow

import * as R from 'ramda';
import { compose, mapProps, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { redux, router } from '@accordo-feed/micro-frontends';

import { setMenuCollapsedState } from 'src/components/appSider/menu/menu.duck';
import { PATHS } from 'src/constants';
import * as globalSelectors from 'src/redux/selectors';
import * as selectors from './drawer.selector';
import {
  getSecurityControlProfileData,
  reset,
  resetSecurityControlProfileFilters,
  setOpenFilterPanel,
  setDownloadQueryReport,
  setSearchTerm
} from './drawer.duck';
import Drawer from './Drawer.component';

/*************
 *   REDUX   *
 *************/

const mapStateToProps = R.applySpec({
  clientId: selectors.clientIdSelector,
  data: selectors.dataSelector,
  dataSource: selectors.dataSourceSelector,
  filterTags: selectors.filterTagSelector,
  isDownloadActive: selectors.isDrawerEmptySelector,
  isDownloadReportLoading: selectors.isDownloadReportLoadingSelector,
  isLoading: selectors.isLoadingSelector,
  isTooltipDisabled: selectors.isTooltipDisabledSelector,
  originControlProfiles: selectors.originControlProfilesSelector,
  hasFeatureSecurityFilterPanel: globalSelectors.hasFeatureSecurityFilterPanel,
  isFilterPanelOpen: selectors.isOpenPanelSelector,
  searchTerm: selectors.searchTermSelector
});

const mapDispatchToProps = redux.bindActionCreatorsCurried({
  getSecurityControlProfileData,
  setDownloadQueryReport,
  setOpenFilterPanel,
  reset,
  resetFilterPanel: resetSecurityControlProfileFilters,
  setMenuCollapsedState,
  setSearchTerm
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),

  router.withRouterHOC,

  withHandlers({
    onDrawerClose: ({ actions, router }) => () => {
      actions.reset();
      router.push(PATHS.SECURITY_SCORE.ROOT);
    },

    onSearchChange: ({ actions }) => e => actions.setSearchTerm(e.target.value)
  }),

  mapProps(({ data, onDrawerClose, route, ...props }) => ({
    ...props,
    ...R.pick(['clientName', 'currentScore', 'maxScore', 'percentage'], data),

    drawerProps: {
      destroyOnClose: true,
      onClose: onDrawerClose,
      visible: [PATHS.SECURITY_SCORE.CLIENT, PATHS.SECURITY_SCORE.CLIENT_DETAILS].includes(route.path)
    }
  }))
)(Drawer);
