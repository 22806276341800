import { combineReducers } from 'redux';

import bulkEditTable from './customers/bulkEditTable/bulkEditTable.duck';
import secureApp from './secureApp/secureApp.duck';
import securityScore from './dashboard/securityScore/securityScore.duck';

export default combineReducers({
  bulkEditTable,
  secureApp,
  securityScore
});
