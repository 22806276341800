// @flow

import * as R from 'ramda';
import { command } from '@accordo-feed/micro-frontends';
import { takeLatest, put, all, call, fork } from 'redux-saga/effects';

import * as actions from './payment.duck';
import * as orgActions from 'src/redux/modules/organization/organization.duck';
import { getOrganization } from 'src/redux/modules/organization/organization.api';
import {
  submitPromoCode,
  putPaymentMethod,
  oldPutPaymentMethod,
  putBillingEmail,
  updateBillingAddress,
  getSubscriptionUsage,
  cancelSubscription
} from './payment.api';
import { PATHS } from 'src/constants';

const errorCase1 = ['errors', 'innerDetails'];
const errorCase2 = ['errors'];
const errorCase3 = ['data', 'errors', 'innerDetails'];
const errorCase4 = ['data', 'errors'];
const errorCase5 = ['data', 'message'];

const getErrorDetails = R.pipe(
  R.prop('response'),
  R.cond([
    [R.hasPath(errorCase1), R.path(errorCase1)],
    [R.hasPath(errorCase2), R.path(errorCase2)],
    [R.hasPath(errorCase3), R.path(errorCase3)],
    [R.hasPath(errorCase4), R.path(errorCase4)],
    [R.T, R.pipe(R.path(errorCase5), message => [{ message }])]
  ]),
  R.map(R.prop('message'))
);

function* updateOrgData(orgId) {
  // Update the organization to sync the payment info
  const data = yield getOrganization(orgId);
  yield put(orgActions.getOrganizationSuccess(data));
}

function* subscripbeMspToPlanSaga({ payload }): any {
  const { orgId, cardToken, planIds, lastFourDigitsCc, whenFailed } = payload;

  try {
    yield oldPutPaymentMethod({
      orgId,
      data: {
        cardToken,
        planIds,
        lastFourDigitsCc
      }
    });
    yield updateOrgData(orgId);
    yield call(command.navigateTo, {
      path: PATHS.PARTNER_CENTER_WELCOME,
      method: 'replace'
    });
  } catch (e) {
    whenFailed();
  }
}

function* putPaymentMethodSaga({ payload }): any {
  const { orgId, cardToken, planIds, whenSuccessful, whenFailed } = payload;
  try {
    yield putPaymentMethod({
      orgId,
      data: {
        cardToken,
        planIds
      }
    });

    yield updateOrgData(orgId);

    whenSuccessful();
  } catch (e) {
    whenFailed();
  }
}

function* putBillingEmailSaga({ payload }): any {
  const { orgId, email, whenSuccessful, whenFailed } = payload;

  yield put(actions.updateBillingEmailLoading());

  try {
    yield putBillingEmail({
      orgId,
      data: {
        email
      }
    });

    yield updateOrgData(orgId);

    whenSuccessful();
  } catch (e) {
    whenFailed();
  }

  yield put(actions.updateBillingEmailLoaded());
}

function* submitPromoCodeSaga({ payload }): any {
  const { orgId, promotionCode, whenFailed, whenSuccessful } = payload;

  const result = yield call(submitPromoCode, {
    orgId,
    data: {
      promotionCode
    },
    handleError: whenFailed
  });

  if (result) whenSuccessful(result);
}

function* getSubscriptionUsageSaga({ payload }): any {
  const { orgId } = payload;

  try {
    yield put(actions.getSubscriptionUsageLoading());

    const result = yield call(getSubscriptionUsage, {
      orgId
    });

    yield put(actions.getSubscriptionUsageLoaded(result));
  } catch (err) {}
}

function* cancelSubscriptionSaga({ payload }): any {
  const { orgId, planId, whenSuccess } = payload;

  yield put(actions.cancelSubscriptionLoading());

  try {
    yield call(cancelSubscription, {
      orgId,
      planIds: [planId]
    });

    yield updateOrgData(orgId);

    yield put(actions.cancelSubscriptionLoaded());

    whenSuccess();
  } catch (err) {}
}

function* updateBillingAddressSaga({ payload }): any {
  const { orgId, data, whenSuccess, whenFailed } = payload;

  yield put(actions.updateBillingAddressLoading());

  try {
    yield call(updateBillingAddress, {
      orgId,
      data
    });

    yield updateOrgData(orgId);

    yield put(actions.updateBillingAddressLoaded());

    whenSuccess();
  } catch (err) {
    const errorDetails = getErrorDetails(err);

    yield put(actions.updateBillingAddressFailed(errorDetails));

    whenFailed();
  }
}

export default function*(): any {
  yield all([
    fork(takeLatest, actions.subscribeMspToPlan, subscripbeMspToPlanSaga),
    fork(takeLatest, actions.updatePaymentMethod, putPaymentMethodSaga),
    fork(takeLatest, actions.updateBillingEmail, putBillingEmailSaga),
    fork(takeLatest, actions.submitPromoCode, submitPromoCodeSaga),
    fork(takeLatest, actions.getSubscriptionUsage, getSubscriptionUsageSaga),
    fork(takeLatest, actions.cancelSubscription, cancelSubscriptionSaga),
    fork(takeLatest, actions.updateBillingAddress, updateBillingAddressSaga)
  ]);
}
