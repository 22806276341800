import { langHelper } from '@accordo-feed/micro-frontends-utils';

const genCardFormId = langHelper.genLangId('shell.aco.paymentDetails.cardForm');
const genPlanDetailId = langHelper.genLangId('shell.aco.paymentDetails.planDetail');

const genCardFormOpts = langHelper.genLangOptions(genCardFormId);
const genPlanDetailOpts = langHelper.genLangOptions(genPlanDetailId);

export const cardForm = {
  formHeader: genCardFormOpts('formHeader', 'Start your free trial'),
  formSubHeader: genCardFormOpts('formSubHeader', 'Credit card details'),
  stripeLink: genCardFormOpts('stripeLink', 'Secured by Stripe'),
  cardNumber: genCardFormOpts('cardNumber', 'Card number'),
  expiryDate: genCardFormOpts('expiryDate', 'Expiry date'),
  cvc: genCardFormOpts('cvc', 'CVC'),
  startButton: genCardFormOpts('startButton', 'Start your free {trialDays} day trial'),
  formHighlight1: genCardFormOpts('formHighlight1', "You won't be billed today"),
  formHighlight2: genCardFormOpts('formHighlight2', 'Cancel any time before {trialEnd}'),
  legalFooter: {
    firstPart: genCardFormOpts(
      'legalFooterFirstPart1',
      `By clicking "Start your free {trialDays} day trial" you agree to Entori's `
    ),
    terms: genCardFormOpts('legalFooterTerms', 'Terms of Use'),
    and: genCardFormOpts('legalFooterAnd', 'and'),
    policy: genCardFormOpts('legalFooterTermsPolicy', 'Privacy Policy'),
    lastPart: genCardFormOpts(
      'legalFooterLastPart',
      'Your credit card will be charged monthly and your fees may vary based on how we bill.'
    )
  },
  httpError: {
    stripe: genCardFormOpts('stripeError', 'Card validation failed, please check the card information.'),
    paymentMethod: genCardFormOpts(
      'paymentMethodError',
      'An error occured when processing your subscription. You will not be charged. Please try again later.'
    )
  }
};

export const planDetail = {
  planHeader: genPlanDetailOpts('planHeader', 'Your free {trialDays} day trial'),
  planName: genPlanDetailOpts('planName', 'Starter Plan - Essential'),
  planPrice: genPlanDetailOpts('planPrice', '{amount} user/month'),
  totalToday: {
    label: genPlanDetailOpts('totalTodayLabel', 'Total due today:'),
    price: genPlanDetailOpts('totalTodayPrice', '{amount}')
  },
  totalPostTrial: {
    label: genPlanDetailOpts('totalPostTrialLabel', 'Total after trial:'),
    price: genPlanDetailOpts('totalPostTrialPrice', 'from {amount} user/month')
  },
  couponLink: genPlanDetailOpts('couponLink', 'Apply coupon code'),
  applyButton: genPlanDetailOpts('applyButton', 'Apply'),
  invalidCode: genPlanDetailOpts('invalidCode', 'The coupon code "{value}" is invalid'),
  couponApplied: genPlanDetailOpts('couponApplied', 'The "{coupon}" coupon has been applied'),
  couponDetails: genPlanDetailOpts('couponDetails', '{coupon} coupon applied'),
  discountDesc: genPlanDetailOpts('discountDesc', '{percentOff}% off'),
  discountDuration: genPlanDetailOpts('discountDuration', 'for {durationInMonths} months'),
  trialExtDesc: genPlanDetailOpts('trialExtDesc', 'Additional {trialPeriodDays} days trial extension'),
  quote: {
    body: genPlanDetailOpts(
      'quoteBody1',
      `The most comprehensive partner platform for Cloud Optimization and Business Growth`
    ),
    name: genPlanDetailOpts('quoteName1', ' '),
    company: genPlanDetailOpts('quoteCompany1', ' ')
  }
};
