// @flow

import * as React from 'react';
import { Layout } from 'antd';

import * as Styled from './twoColumnLayout.styled';

/*************
 *   TYPES   *
 *************/

type Props = {
  className: string,
  siderContent: React.Node,
  mainContent: React.Node,
  hideSider: boolean
};

/*****************
 *   COMPONENT   *
 *****************/

export default ({ siderContent, mainContent, hideSider, className }: Props) => (
  <Layout>
    <Styled.SliderWrapper collapsedWidth={0} trigger={null} collapsible collapsed={hideSider} className={className}>
      {siderContent}
    </Styled.SliderWrapper>
    <Styled.ContentWrapper>{mainContent}</Styled.ContentWrapper>
  </Layout>
);
