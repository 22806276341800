import React from 'react';
import { LanguageEntry } from '@accordo-feed/language.entry';

import { Tooltip } from 'src/pages/page.styled';
import { renderSpend } from 'src/pages/masterList/masterList.config';
import { EXTERNAL_LINKS, PATHS, SUBMODULES } from 'src/constants';
import { sortByAlph, sortByNumber } from 'src/utils';
import lang from './allocation.lang';
import * as Styled from './allocation.styled';

const tableLang = lang.table;

/***************
 *   CONFIGS   *
 ***************/

const defaultWidth = {
  small: 70,
  medium: 80,
  large: 200
};

export const generateTableColumns = props => {
  const { enableSort, prevPath } = props;

  const onClickUnassignedLicenses = tenantId => {
    window.open(EXTERNAL_LINKS.PARTNER_CENTER_SUBSCRIPTIONS.replace(/{{ tenantId }}/, tenantId), '_blank');
  };

  return [
    {
      title: <LanguageEntry {...tableLang.main.company} />,
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      className: 'at_columnCompany',
      sorter: enableSort && sortByAlph('name'),
      width: defaultWidth.large,
      render: (name, { id }) => (
        <Styled.VisitLink
          to={{
            pathname: `${PATHS.CUSTOMERS.ROOT}/${id}/`,
            state: { prevPath: prevPath || PATHS.ALLOCATION_ADOPTION_DASHBOARD }
          }}
          className="at__visitO365 aco-text-left"
        >
          <Styled.LinkName>{name}</Styled.LinkName>
        </Styled.VisitLink>
      )
    },
    {
      title: <LanguageEntry {...tableLang.main.licenses} />,
      children: [
        {
          title: (
            <>
              <LanguageEntry {...tableLang.base.th2} />
              <Tooltip title={<LanguageEntry {...tableLang.base.tooltip1} />} />
            </>
          ),
          dataIndex: 'activePaidUsers',
          key: 'activePaidUsers',
          width: defaultWidth.small,
          className: 'at_columnActiveUsers',
          sorter: enableSort && sortByNumber('activePaidUsers'),
          render: (users, { id }) => (
            <Styled.VisitLink
              to={{
                pathname: `/${SUBMODULES.OFFICE_365_OPTIMIZER}/${id}/users`,
                state: { prevPath: prevPath || PATHS.ALLOCATION_ADOPTION_DASHBOARD }
              }}
              className="at__visitO365"
            >
              <Styled.LinkName>{users}</Styled.LinkName>
            </Styled.VisitLink>
          )
        },
        {
          title: <LanguageEntry {...tableLang.base.th4} />,
          dataIndex: 'totalLicenses',
          key: 'totalLicenses',
          width: defaultWidth.small,
          className: 'at_columnTotalLicense',
          sorter: enableSort && sortByNumber('totalLicenses'),
          defaultSortOrder: 'descend'
        },
        {
          title: (
            <>
              <LanguageEntry {...tableLang.base.th5} />
              <Tooltip title={<LanguageEntry {...tableLang.base.tooltip3} />} />
            </>
          ),
          dataIndex: 'totalAnnualSpend',
          key: 'totalAnnualSpend',
          width: defaultWidth.small,
          sorter: enableSort && sortByNumber('totalAnnualSpend'),
          render: renderSpend
        }
      ]
    },
    {
      title: <LanguageEntry {...tableLang.main.flagged} />,
      children: [
        {
          title: <LanguageEntry {...tableLang.base.th6} />,
          dataIndex: 'misassigned',
          key: 'misassigned',
          width: defaultWidth.medium,
          className: 'at_columnMisassigned',
          sorter: enableSort && sortByNumber('misassigned'),
          render: (misassigned, { id }) => {
            const enabled = misassigned > 0;
            if (enabled) {
              return (
                <Styled.VisitLink
                  to={{
                    pathname: `${PATHS.OFFICE_365_OPTIMIZER.RECOMMENDATIONS.replace(/{{ orgId }}/, id)}`,
                    state: {
                      prevPath: prevPath || PATHS.ALLOCATION_ADOPTION_DASHBOARD,
                      shows: ['misassigned_offboarded']
                    }
                  }}
                  className="at__visitO365"
                >
                  <Styled.LinkName className="aco-light-red">{misassigned}</Styled.LinkName>
                </Styled.VisitLink>
              );
            }
            return <span className="aco-light-red">{misassigned}</span>;
          }
        },
        {
          title: <LanguageEntry {...tableLang.base.th7} />,
          dataIndex: 'unassignedLicenses',
          key: 'unassignedLicenses',
          width: defaultWidth.medium,
          className: 'at_columnUnassignedLicenses',
          sorter: enableSort && sortByNumber('unassignedLicenses'),
          render: (unassignedLicenses, { microsoft }) => {
            const enabled = unassignedLicenses > 0 && microsoft?.tenantId;
            return (
              <Styled.ExternalLink
                disabled={!enabled}
                onClick={() => (enabled ? onClickUnassignedLicenses(microsoft?.tenantId) : null)}
              >
                {unassignedLicenses}
              </Styled.ExternalLink>
            );
          }
        }
      ]
    },
    {
      title: (
        <>
          <LanguageEntry {...tableLang.main.savings} />
          <Tooltip title={<LanguageEntry {...tableLang.base.tooltip4} />} />
        </>
      ),
      children: [
        {
          title: <LanguageEntry {...tableLang.base.th9} />,
          dataIndex: 'allocationPotentialSavings',
          key: 'savings-unassigned',
          width: defaultWidth.medium,
          sorter: enableSort && sortByNumber('allocationPotentialSavings'),
          render: renderSpend
        },
        {
          title: <LanguageEntry {...tableLang.base.th10} />,
          dataIndex: 'adoptionPotentialSavings',
          key: 'savings-missassigned',
          width: defaultWidth.medium,
          sorter: enableSort && sortByNumber('adoptionPotentialSavings'),
          render: renderSpend
        }
      ]
    }
  ];
};
