import * as R from 'ramda';
import { EXPANDABLE_CONFIG } from './menu.constants';

const orgIdRegEx = /(acc_.+?)\//i;

export const normalizeRoutePath = path => {
  const match = orgIdRegEx.exec(path);
  return match ? path.replace(match[1], '{{ orgId }}') : path;
};

export const checkOpenSubMenu = pathname =>
  R.keys(EXPANDABLE_CONFIG).filter(key =>
    R.values(EXPANDABLE_CONFIG[key]).some(val => R.equals(val, normalizeRoutePath(pathname)))
  );
