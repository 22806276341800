// @flow

import React from 'react';
import { Col, Row } from 'antd';
import { useTranslate } from '@accordo-feed/language.entry';

import Widget from 'src/components/widget';
import type { LoadingStatusType } from 'src/constants';
import BillingAddress from './BillingAddress.container';
import CardInfo from './CardInfo.container';
import Subcriptions from './subscriptions/';
import { BillingEmail } from './BillingEmail.component';
import { paymentInfo } from './billing.lang';
import { Field, Content } from './paymentInfo.styled';

/*************
 *   TYPES   *
 *************/

type Props = {
  billingEmail: string,
  updateBillingEmail: Function,
  orgId: string,
  updateBillingEmailLoadingStatus: LoadingStatusType
};

/*****************
 *   COMPONENT   *
 *****************/

export const PaymentInfo = ({ billingEmail, orgId, updateBillingEmail, updateBillingEmailLoadingStatus }: Props) => {
  const translate = useTranslate();

  return (
    <>
      <Row gutter={[20, 20]} type="flex" style={{ marginBottom: 10 }}>
        <Col xs={{ span: 24 }} lg={{ span: 16 }}>
          <Widget title={translate(paymentInfo.paymentInfo.heading)}>
            <Content>
              <BillingEmail
                orgId={orgId}
                email={billingEmail}
                updateBillingEmail={updateBillingEmail}
                updateBillingEmailLoadingStatus={updateBillingEmailLoadingStatus}
              />
              <Field labelText={translate(paymentInfo.paymentInfo.cardLabel)}>
                <CardInfo />
              </Field>
              <BillingAddress />
            </Content>
          </Widget>
        </Col>
      </Row>
      <Subcriptions />
    </>
  );
};
