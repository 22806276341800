import { langHelper } from '@accordo-feed/micro-frontends-utils';

const genId = langHelper.genLangId('shell.aco.inviteError');
const genOpts = langHelper.genLangOptions(genId);

export default {
  header: genOpts('header', 'We are sorry, you are not allowed to see this account'),
  message1: genOpts('message1', 'The invitaion could be expired or deleted.'),
  message2: genOpts('message2', 'Please contact the account owner to update our settings.'),
  backToSignIn: genOpts('backToSignIn', 'Back to Sign In')
};
