// @flow

import { core } from '@accordo-feed/micro-frontends-utils';

import { API } from 'src/utils/api';
import { resolveApiEndpoint, requestHandler } from 'src/utils';

const distiBillingDataApi = resolveApiEndpoint(API.GET_DISTI_BILLING_DATA);

export const getDistiBillingData = (orgId: string): Promise<Object> =>
  requestHandler({
    method: 'GET',
    url: core.replaceAll(distiBillingDataApi, { orgId })
  });
