import * as R from 'ramda';
import throttle from 'lodash.throttle';
import { core } from '@accordo-feed/micro-frontends-utils';
import { device } from 'aws-iot-device-sdk';

import handlers from './handlers';
import { IOT_RECONNECT_INTERVAL_MS } from './constants';
import { API, resolveApiEndpoint, requestHandler } from 'src/utils/api';

let client;
let intervalTimer;

const defaultOptions = {
  protocol: 'wss',
  port: 443
};

const iotAuthApi = resolveApiEndpoint(API.IOT_AUTH);

class IoT {
  init = throttle(
    ({ store, orgId, translate }) => {
      this.dispatch = store.dispatch;
      this.getState = store.getState;
      this.orgId = orgId;
      this.translate = translate;

      clearInterval(intervalTimer);
      this.connectWithNewToken();
      intervalTimer = setInterval(this.connectWithNewToken, IOT_RECONNECT_INTERVAL_MS);
    },
    5000,
    {
      trailing: false
    }
  );

  connectWithNewToken = () => {
    requestHandler({
      url: core.replaceAll(iotAuthApi, { orgId: this.orgId }),
      handleError: console.error
    }).then(this.connect);
  };

  connect = options => {
    const messageHandler = (topic, message) => {
      const messageJSON = JSON.parse(message.toString());
      const type = (messageJSON.type || '').toUpperCase();
      const handler = handlers[type];

      if (handler) {
        handler({
          ...R.pick(['dispatch', 'getState', 'translate'], this),
          messageJSON
        });
      } else {
        console.log(`No handler can match ${type} event`, messageJSON);
      }
    };

    // If it's local development
    if (!options || !options.topic) {
      // To test on your local, please trigger the mock IoT event from Chrome console like this:
      // window.dispatchEvent(new CustomEvent('aco:IOT_EVENT', { detail: 'Your JSON strigified message' }))

      window.addEventListener('aco:IOT_EVENT', ({ detail }) => {
        messageHandler(null, detail);
      });
      return;
    }

    const opts = core.merge(defaultOptions, options);

    if (client) {
      client.end(true);
      console.log('Disconnected from previous IoT connection.');
    }

    client = device(opts);

    client.on('connect', () => {
      const { topic } = opts;
      client.subscribe(topic);
      console.log(`Successfully subscribed to topic (${topic})`);
    });

    client.on('message', messageHandler);

    client.on('error', error => {
      console.log(`Error happened. The details are ${error.message}`);
    });
  };
}

export default new IoT();
