import * as R from 'ramda';
import { createSelector } from 'reselect';

import { DEFAULT_COUNTRY_CODE } from 'src/constants';
import { getModuleSelector } from 'src/redux/utils';
import { isEmptyOrNil } from 'src/utils';
import { isValidSubscription } from './organization.utils';
import { userEmailSelector } from 'src/redux/selectors/userData';

const moduleSelector = getModuleSelector('organization');

export const organizationLoadingSelector = createSelector(moduleSelector, R.prop('isLoading'));

export const organizationLoadedSelector = createSelector(moduleSelector, R.prop('isLoaded'));

export const organizationHasErrorSelector = createSelector(moduleSelector, R.prop('hasError'));

export const organizationDataSelector = createSelector(moduleSelector, R.prop('data'));

export const organizationPsaBillingAlertSelector = createSelector(organizationDataSelector, R.prop('psaBillingAlert'));

export const organizationPaymentSelector = createSelector(moduleSelector, R.pathOr(null, ['data', 'payment']));

export const organizationIsValidPaymentSelector = createSelector(organizationPaymentSelector, isValidSubscription);

export const organizationBillingAddressSelector = createSelector(
  moduleSelector,
  R.pipe(
    R.pathOr({}, ['data', 'billingAddress']),
    R.mergeRight({ country: DEFAULT_COUNTRY_CODE }),
    R.evolve({ country: R.when(isEmptyOrNil, R.always(DEFAULT_COUNTRY_CODE)) })
  )
);

export const organizationCountryCodeSelector = createSelector(organizationDataSelector, org =>
  (org.countryCode || DEFAULT_COUNTRY_CODE).toLowerCase()
);

export const organizationInfoSelector = createSelector(
  organizationDataSelector,
  ({ id = '', name = '', countryCode = '' }) => ({
    orgId: id,
    orgName: name,
    orgCountryCode: countryCode
  })
);

export const organizationDistributorSelector = createSelector(organizationDataSelector, R.prop('distributor'));

const paymentBillingEmailSelector = createSelector(organizationPaymentSelector, R.propOr(null, 'billingEmail'));

export const billingEmailSelector = createSelector(
  [paymentBillingEmailSelector, userEmailSelector],
  (billingEmail, userEmail) => billingEmail || userEmail
);

export const creditCardPaymentSelector = createSelector(
  organizationPaymentSelector,
  R.pathOr(null, ['lastFourDigitsCc'])
);

export const isMfaEnabledSelector = createSelector(organizationDataSelector, data => !!data.mfa);
