// @flow

import React from 'react';
import * as R from 'ramda';

import * as Styled from './growthTrend.styled';
import { widgetDataHOC } from 'src/hoc';
import {
  tooltipConfig,
  xAxisConfig,
  yAxisConfig,
  colorConfig,
  generateSingleSeries,
  gridConfig
} from './growthTrend.config';
import LegendComponent from './Legend.component';

/*************
 *   TYPES   *
 *************/

type Props = {
  dataSource: Array<Object>,
  isCta: boolean
};

/***************
 *   HELPERS   *
 ***************/

const getOption = dataSource => {
  let seriesData = [];
  let legends = [];
  let hasPrediction = false;

  dataSource.forEach((element, index) => {
    const { data, prediction, clientName } = R.pick(['data', 'prediction', 'clientName'], element);
    const dashedData = R.concat(data, prediction);
    hasPrediction = hasPrediction || !R.isEmpty(prediction);
    seriesData = R.concat(seriesData, generateSingleSeries(data, dashedData, clientName, colorConfig[index]));
    legends.push({
      clientId: element.clientId,
      clientName: element.clientName,
      color: colorConfig[index],
      growthPercentage: element.growthPercentage
    });
  });

  const length =
    R.compose(
      R.length,
      R.pathOr([], [0, 'data'])
      // $FlowIgnore
    )(dataSource) + (hasPrediction ? 90 : 0);

  return {
    tooltip: tooltipConfig(dataSource),
    color: colorConfig,
    grid: gridConfig,
    xAxis: xAxisConfig(length),
    yAxis: yAxisConfig,
    series: seriesData,
    legends
  };
};

/*****************
 *   COMPONENT   *
 *****************/

const GrowthTrendChart = ({ dataSource, isCta }: Props) => {
  const option = getOption(dataSource);
  const { legends } = option;

  return (
    <>
      {isCta ? <Styled.ShortExtendedChart option={option} notMerge={true} /> : <Styled.Chart option={option} notMerge={true} />}
      <LegendComponent legends={legends} />
    </>
  );
};

export default widgetDataHOC(['dataSource'])(GrowthTrendChart);
