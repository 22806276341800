// @flow

import * as R from 'ramda';
import React, { useState } from 'react';
import moment from 'moment';
import { Form } from 'formik';
import { injectStripe } from 'react-stripe-elements';
import { message } from 'antd';
import { useTranslate } from '@accordo-feed/language.entry';
import { eventTracking } from '@accordo-feed/micro-frontends';

import * as Styled from './cardForm.styled';
import AddressFormWrapper from 'src/components/addressForm';
import { SEGMENT_EVENTS, STRIPE_PLANS } from 'src/constants/index';
import { StripeCardFields } from 'src/components/stripe';
import { Title } from './paymentDetails.styled';
import { cardForm as lang } from './paymentDetails.lang';
import { lang as footerLang } from 'src/components/appFooter/appFooter.lang';

/*************
 *   TYPES   *
 *************/

type Props = {
  isAnnual: boolean,
  orgId: string,
  stripe: Object,
  subscribeMspToPlan: Function,
  subscribeToTier: number,
  trialDays: number,
  updateBillingAddress: Function,
  userId: string
};

const getTrialEnd = trialDays =>
  moment()
    .add(trialDays, 'days')
    .format('D MMMM YYYY');

/*****************
 *   COMPONENT   *
 *****************/

const CardForm = ({
  subscribeMspToPlan,
  subscribeToTier,
  isAnnual,
  updateBillingAddress,
  orgId,
  stripe,
  trialDays
}: Props) => {
  const translate = useTranslate();
  const [isLoading, setIsLoading] = useState(false);

  const onPayButtonClick = async ({ city, country, line1, line2, postalCode, region }) => {
    const { token, error } = await stripe.createToken();

    eventTracking.trackEvent({ trackName: SEGMENT_EVENTS.CC_PAGE_START_TRIAL });

    if (error) {
      message.error(translate(lang.httpError.stripe));
      return;
    }

    setIsLoading(true);

    updateBillingAddress({
      orgId,
      data: { city, country, line1, line2, postalCode, region },
      whenSuccess: () =>
        subscribeMspToPlan({
          orgId,
          planIds: [STRIPE_PLANS.OFFICE365],
          cardToken: token.id,
          lastFourDigitsCc: R.path(['card', 'last4'], token),
          whenFailed: () => {
            setIsLoading(false);
            message.error(translate(lang.httpError.paymentMethod));
          }
        }),
      whenFailed: () => {
        setIsLoading(false);
      }
    });
  };

  return (
    <AddressFormWrapper
      onSubmit={onPayButtonClick}
      renderWithAddressForm={({ addressElement }) => (
        <Styled.CardFormBox>
          <Form>
            <Title size="m">{translate(lang.formHeader)}</Title>
            <StripeCardFields />
            {addressElement}
            <Styled.StartButton htmlType="submit" type="primary" loading={isLoading}>
              {translate(lang.startButton, { trialDays })}
            </Styled.StartButton>

            <Styled.CardFormHighlight text={translate(lang.formHighlight1)} />
            <Styled.CardFormHighlight
              text={translate(lang.formHighlight2, {
                trialEnd: getTrialEnd(trialDays)
              })}
            />

            <Styled.CardFormFooter>
              {translate(lang.legalFooter.firstPart, {
                trialDays
              })}{' '}
              <Styled.Link href={translate(footerLang.termsLinkUrl)}>{translate(lang.legalFooter.terms)}</Styled.Link>
              {` ${translate(lang.legalFooter.and)} `}
              <Styled.Link href={translate(footerLang.privacyLinkUrl)}>{`${translate(
                lang.legalFooter.policy
              )}`}</Styled.Link>
              {'. '}
              {translate(lang.legalFooter.lastPart)}
            </Styled.CardFormFooter>
          </Form>
        </Styled.CardFormBox>
      )}
    />
  );
};

export default injectStripe(CardForm);
