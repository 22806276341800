import styled from 'styled-components';
import { Tag } from 'antd';
import { constants } from '@accordo-feed/aco-styled-components';
import { appLabelColor } from 'src/App.styled';

const { colors } = constants;

export const FiltersSection = styled.div`
  margin-bottom: 16px;
  margin-right: -3px;

  label {
    color: ${appLabelColor};
    margin-right: 5px;
  }
`;

export const FilterTag = styled(Tag).attrs({
  closable: true
})`
  margin-right: 3px;
  color: ${colors.blue};
  background: ${colors.blueHighlight};
  border-color: ${colors.blueHighlight};
`;

export const ClearAllButton = styled.span`
  margin-left: 10px;
  color: ${colors.blue};
  cursor: pointer;
`;
