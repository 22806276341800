import theme from 'styled-theming';
import { commonStyles, darkTheme, lightTheme } from '@accordo-feed/aco-styled-components';

export { commonStyles as GlobalStyle };

export const bodyBackground = theme('theme', {
  light: lightTheme.body,
  dark: darkTheme.body
});

export const widgetBackground = theme('theme', {
  light: lightTheme.cardBackground,
  dark: darkTheme.cardBackground
});

export const nestedWidgetBackground = theme('theme', {
  light: lightTheme.nestedWidgetBackground,
  dark: darkTheme.nestedWidgetBackground
});

export const sidebarBackground = theme('theme', {
  light: lightTheme.sidebarBackground,
  dark: darkTheme.sidebarBackground
});

export const widgetBorderColor = theme('theme', {
  light: lightTheme.cardBorderColor,
  dark: darkTheme.cardBorderColor
});

export const appTextColor = theme('theme', {
  light: lightTheme.text,
  dark: darkTheme.text
});

export const appLabelColor = theme('theme', {
  light: '#374151',
  dark: '#F9FAFB'
});

export const pageHeaderTextColor = theme('theme', {
  light: lightTheme.pageHeadingText,
  dark: darkTheme.pageHeadingText
});

export const subHeaderTextColor = theme('theme', {
  light: lightTheme.subHeadingText,
  dark: darkTheme.subHeadingText
});

export const widgetHeaderTextColor = theme('theme', {
  light: lightTheme.cardHeadingText,
  dark: darkTheme.cardHeadingText
});

export const tableHeaderTextColor = theme('theme', {
  light: lightTheme.tableHeadingText,
  dark: darkTheme.tableHeadingText
});

export const tableHeaderBorder = theme('theme', {
  light: '#A4A4A4',
  dark: '#191b2d'
});

export const tableHeaderRowBackground = theme('theme', {
  light: lightTheme.tableHeadingRowBackground,
  dark: darkTheme.tableHeadingRowBackground
});

export const tableEvenRowBackground = theme('theme', {
  light: lightTheme.tableEvenRowBackground,
  dark: darkTheme.tableEvenRowBackground
});

export const tableOddRowBackground = theme('theme', {
  light: lightTheme.tableOddRowBackground,
  dark: darkTheme.tableOddRowBackground
});

export const tableRowBorderColor = theme('theme', {
  light: lightTheme.tableRowBorder,
  dark: darkTheme.tableRowBorder
});

export const tableRowHoverColor = theme('theme', {
  light: lightTheme.tableRowHover,
  dark: darkTheme.tableRowHover
});

export const scrollbarBackground = theme('theme', {
  light: lightTheme.scrollbarColor,
  dark: darkTheme.scrollbarColor
});

export const scrollbarHandlerColor = theme('theme', {
  light: lightTheme.scrollbarHandler,
  dark: darkTheme.scrollbarHandler
});

export const paginationItemTextColor = theme('theme', {
  light: lightTheme.paginationItemText,
  dark: darkTheme.paginationItemText
});

export const paginationItemBackgroundColor = theme('theme', {
  light: lightTheme.paginationItemBackground,
  dark: darkTheme.paginationItemBackground
});

export const paginationItemBackgroundActiveColor = theme('theme', {
  light: lightTheme.paginationItemBackgroundActive,
  dark: darkTheme.paginationItemBackgroundActive
});

export const paginationItemActiveColor = theme('theme', {
  light: lightTheme.paginationItemActive,
  dark: darkTheme.paginationItemActive
});

export const popOverBackground = theme('theme', {
  light: 'white',
  dark: darkTheme.body
});

export const modalBackgroundColor = theme('theme', {
  light: lightTheme.modalBackground,
  dark: darkTheme.modalBackground
});
