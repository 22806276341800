import * as R from 'ramda';
import bodybuilder from 'bodybuilder';

import { isEmptyOrNil, addTypesToBodyBuilder } from 'src/utils';
import { ES_QUERY_CONFIG } from './securityScore.constants';

/*****************************
 *   TRANSFORM ES RESPONSE   *
 *****************************/

export const defaultSecurityPageData = {
  count: 0,
  clients: []
};

/**
 * Transform the security page ES response to the format antd table understands
 * @param {Object} response
 * @returns {Object}
 */
export const transform = response => {
  const { hits } = response;

  if (isEmptyOrNil(R.propOr(null, 'hits', hits))) {
    return defaultSecurityPageData;
  }

  const count = R.pathOr(0, ['total', 'value'], hits);
  const clients = R.propOr([], 'hits', hits).reduce((acc, item) => {
    return acc.concat([
      {
        id: R.pathOr('', ['_source', 'id'], item),
        partnerId: R.pathOr('', ['_source', 'partnerId'], item),
        clientName: R.pathOr('', ['_source', 'clientName'], item),
        currentScore: R.pathOr(0, ['_source', 'currentScore'], item),
        maxScore: R.pathOr(0, ['_source', 'maxScore'], item),
        percentage: R.pathOr(0, ['_source', 'percentage'], item),
        improvementActionCount: R.pathOr(
          {
            notCompleted: 0,
            ignored: 0,
            total: 0,
            resolvedBy3rdParty: 0,
            completed: 0
          },
          ['_source', 'improvementActionCount'],
          item
        )
      }
    ]);
  }, []);

  const transformation = {
    count: R.always(count),
    clients: R.always(clients)
  };

  return R.evolve(transformation, defaultSecurityPageData);
};

const genSelectedFilterQueryArr = selectedFilters =>
  R.pipe(
    R.keys,
    R.filter(key => !R.isEmpty(selectedFilters[key])),
    R.applySpec({
      rangeQuery: R.pipe(
        R.filter(R.flip(R.includes)(['currentScore', 'percentage'])),
        R.map(key => ({
          type: 'filter',
          function: 'range',
          field: key,
          input: {
            gte: selectedFilters[key][0],
            lte: selectedFilters[key][1]
          }
        }))
      ),
      controlScoreQuery: R.pipe(
        R.filter(R.flip(R.includes)(['status', 'improvementAction', 'controlCategory', 'userImpact'])),
        R.map(key => ({
          type: 'filter',
          function: 'terms',
          field: `controlScores.${key}${R.equals('improvementAction', key) ? '.keyword' : ''}`,
          input: selectedFilters[key]
        }))
      )
    })
  )(selectedFilters);

/**
 * Generate es query for selected filters object
 * @param {string} partnerId
 * @param {{currentScore?: number[], percentage?: number[], improvementAction?: string[], status?: string[], controlCategory?: string[], userImpact?: string[]}} selectedFilters
 */
export const genFilterResultQuery = (partnerId, selectedFilters) => {
  const { rangeQuery, controlScoreQuery } = genSelectedFilterQueryArr(selectedFilters);

  return {
    queryName: 'securityFilteredTable',
    partnerId,
    esQuery: bodybuilder()
      .rawOption('_source', ES_QUERY_CONFIG.FIELDS)
      .size(ES_QUERY_CONFIG.SIZE)
      .query('bool', q => {
        const bodyBuilder = q.filter('term', 'partnerId', partnerId);
        addTypesToBodyBuilder(bodyBuilder, rangeQuery);

        return bodyBuilder.filter('nested', 'path', 'controlScores', q => {
          return q.query('bool', q => {
            addTypesToBodyBuilder(q, controlScoreQuery);
            return q;
          });
        });
      })
      .sort(ES_QUERY_CONFIG.SORT)
      .build()
  };
};
