import React from 'react';
import { Link } from 'react-router';
import { PATHS, SUBMODULES } from 'src/constants';
import ToggleTheme from '../toggleTheme';
import SettingTheme from '../settingMenu';
import * as Styled from './globalHeader.styled';
import lang from './globalHeader.lang';

const CustomerProfilePathRegex = /companies\/(acc_.+?)/i;
const CustomerAzureRecommendationsPathRegex = /companies\/(acc_.+?)\/azure-recommendations/i;
const CustomerLicensePathRegex = /companies\/(acc_.+?)\/licenseAssignments/i;
const SecurityScorePathRegex = /security-score\/(acc_.+?)/i;
const ConsumptionPathRegex = /consumption\/(acc_.+?)/i;
const UsersPathRegex = /(acc_.+?)\/users/i;
const PlansPathRegex = /(acc_.+?)\/plans/i;
const DepartmentSpendPathRegex = /(acc_.+?)\/users\/department-spend/i;
const RecommendationsPathRegex = /(acc_.+?)\/recommendations/i;

const customersRoot = {
  text: lang.customers,
  path: PATHS.CUSTOMERS.ROOT
};

const dashboardRoot = {
  text: lang.dashboard,
  path: PATHS.DASHBOARD.ROOT
};

const submoduleRoot = {
  text: lang.submodule,
  path: SUBMODULES.OFFICE_365_OPTIMIZER
};

const settingsRoot = {
  text: lang.settings
};

const productCatalogRoot = {
  text: lang.productCatalog,
  path: PATHS.PRODUCT_CATALOG
};

const azureDashboardRoot = {
  text: lang.azureDashboard,
  path: PATHS.AZURE_DASHBOARD.ROOT
};

const Breadcrumbs = ({ nodes }) =>
  nodes.map(({ text, path = '' }, index) => {
    return (
      <Styled.Breadcrumbs key={`breadcrumbs-${index}`}>
        <Styled.Link as={path ? Link : 'span'} to={path}>
          {text}
        </Styled.Link>
        <Styled.PathConnector />
      </Styled.Breadcrumbs>
    );
  });

export default ({ location, params, customers }) => {
  const { pathname } = location;
  const clientId = params.id || params.clientId;
  const company = React.useMemo(() => customers.find(c => c.id === clientId) || null, [clientId, customers]);

  const getText = () => {
    switch (location.pathname) {
      case PATHS.DASHBOARD.ROOT:
        return [dashboardRoot];

      case PATHS.AZURE_DASHBOARD.ROOT:
        return [azureDashboardRoot];

      case PATHS.DASHBOARD.UNASSIGNED_LICENSES:
        return [dashboardRoot, { text: lang.unassignedLicenses }];

      case PATHS.DASHBOARD.OPPORTUNITIES:
        return [dashboardRoot, { text: lang.opportunities }];

      case PATHS.DASHBOARD.GROWTH_TREND:
        return [dashboardRoot, { text: lang.clientGrowth }];

      case PATHS.SECURITY_SCORE.ROOT:
        return [dashboardRoot, { text: lang.securityScore }];

      case PATHS.CUSTOMERS.ROOT:
        return [{ ...customersRoot, text: lang.companyManagement }];

      case PATHS.PRODUCT_CATALOG:
        return [{ ...productCatalogRoot, text: lang.productCatalog }];

      case PATHS.ACCOUNTS.PROFILE:
        return [settingsRoot, { text: lang.profile }];

      case PATHS.ACCOUNTS.NOTIFICATIONS_SETTINGS:
        return [settingsRoot, { text: lang.notifications }];

      case PATHS.TEAM_INVITES.ROOT:
        return [settingsRoot, { text: lang.teamManagement }];

      case PATHS.PSA.ROOT:
        return [settingsRoot, { text: lang.psa }];

      case PATHS.PSA.PRODUCT_MAPPING:
        return [settingsRoot, { text: lang.psa, path: PATHS.PSA.ROOT }, { text: lang.psaProduct }];

      case PATHS.PSA.CUSTOMER_MAPPING:
        return [settingsRoot, { text: lang.psa, path: PATHS.PSA.ROOT }, { text: lang.psaCustomer }];

      case PATHS.ACCOUNTS.BILLING:
        return [settingsRoot, { text: lang.billing }];

      case PATHS.ACCOUNTS.SUBSCRIPTIONS:
        return [settingsRoot, { text: lang.subscriptions }];

      case PATHS.ALLOCATION_ADOPTION_DASHBOARD:
        return [dashboardRoot, { text: lang.analyticsCenter }];

      default: {
        const companyProfilePath = company ? PATHS.CUSTOMERS.PROFILE.replace(/:id/, company.id) : '';
        const companyRoute = company ? { text: company.name, path: companyProfilePath } : null;
        if (ConsumptionPathRegex.exec(pathname)) {
          if (DepartmentSpendPathRegex.exec(pathname)) {
            return [customersRoot, companyRoute, { text: lang.spend }];
          } else if (UsersPathRegex.exec(pathname)) {
            return [customersRoot, companyRoute, { text: lang.users }];
          } else if (PlansPathRegex.exec(pathname)) {
            return [customersRoot, companyRoute, { text: lang.plans }];
          } else if (RecommendationsPathRegex.exec(pathname)) {
            return [companyRoute || customersRoot, { text: lang.recommendations }];
          }
          return company ? [submoduleRoot, { text: company.name }] : [submoduleRoot];
        }
        if (CustomerLicensePathRegex.exec(pathname)) {
          return [customersRoot, companyRoute, { text: lang.licenseAssignments }];
        } else if (CustomerAzureRecommendationsPathRegex.exec(pathname)) {
          return [customersRoot, companyRoute, { text: lang.azureRecommendations }];
        } else if (CustomerProfilePathRegex.exec(pathname)) {
          return company ? [customersRoot, { text: company.name }] : [customersRoot];
        }
        if (SecurityScorePathRegex.exec(pathname)) {
          return [customersRoot, companyRoute, { text: lang.securityScore }];
        }
        return [];
      }
    }
  };

  const pathObjects = getText().filter(e => e);

  return pathObjects.length > 0 ? (
    <Styled.HeaderContainer>
      <Styled.BreadcrumbsContainer>
        <Breadcrumbs nodes={pathObjects} />
      </Styled.BreadcrumbsContainer>
      <Styled.BreadcrumbsRigthIconContainer>
        <ToggleTheme />
        <SettingTheme />
      </Styled.BreadcrumbsRigthIconContainer>
    </Styled.HeaderContainer>
  ) : null;
};
