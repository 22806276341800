// @flow

import React, { useEffect, useState } from 'react';
import { useTranslate } from '@accordo-feed/language.entry';
import CheckboxGroup from 'src/components/checkboxGroup';
import Slider from 'src/components/slider';
import { formatCurrencyHOC } from 'src/hoc';
import * as Styled from './subscriptions.styled';
import lang from './subscriptions.lang';

/*************
 *   TYPES   *
 *************/

type Props = {
  data: Array<Object>,
  formatCurrency: Function,
  onChange: Function
};

/*****************
 *   COMPONENT   *
 *****************/

const ExpiringOptions = [
  { label: '30 Days', value: 30 },
  { label: '60 Days', value: 60 },
  { label: '90 Days', value: 90 }
];

const FilterPanel = ({ data, formatCurrency, onChange }: Props) => {
  const translate = useTranslate();
  let maxSpend = 0;
  data.forEach(e => {
    const { subscriptions } = e;
    const annualSpend = subscriptions?.annual?.spend ?? 0;
    const monthlySpend = subscriptions?.monthly?.spend ?? 0;
    if (maxSpend < annualSpend) maxSpend = annualSpend;
    if (maxSpend < monthlySpend) maxSpend = monthlySpend;
  });
  maxSpend = Math.ceil(maxSpend);

  const [selectedMinSpend, setSelectedMinSpend] = useState(0);
  const [selectedMaxSpend, setSelectedMaxSpend] = useState(maxSpend);
  const [seatsExpiring, setSeatsExpiring] = useState([]);

  const spendSliderProps = {
    titleLang: lang.filter.spend,
    minLabel: formatCurrency(0),
    maxLabel: formatCurrency(maxSpend),
    sliderProps: {
      width: '250px',
      range: 'range',
      min: 0,
      max: maxSpend,
      value: [selectedMinSpend, selectedMaxSpend],
      onChange: value => {
        setSelectedMinSpend(value[0]);
        setSelectedMaxSpend(value[1]);
      }
    }
  };

  useEffect(() => {
    if (onChange) {
      onChange({ minSpend: selectedMinSpend, maxSpend: selectedMaxSpend, expiring: seatsExpiring });
    }
  }, [selectedMinSpend, selectedMaxSpend, seatsExpiring]);

  return (
    <Styled.FilterWrapper>
      <Styled.FilterTitle>{translate(lang.filter.title)}</Styled.FilterTitle>
      <Styled.SliderFilter>
        <Slider {...spendSliderProps} />
      </Styled.SliderFilter>
      <Styled.FilterLabel>{translate(lang.filter.description)}</Styled.FilterLabel>
      <CheckboxGroup direction="vertical" options={ExpiringOptions} onChange={value => setSeatsExpiring(value)} />
    </Styled.FilterWrapper>
  );
};

export default formatCurrencyHOC(FilterPanel);
