// @flow

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Responsive } from 'react-grid-layout';
import { SizeMe } from 'react-sizeme';
import { compose } from 'recompose';
import * as R from 'ramda';
import { useTranslate } from '@accordo-feed/language.entry';

import Widget from 'src/components/widget';
import ErrorWrapper from 'src/components/wrapperComponents/Error.wrapper';
import withDashboardData from 'src/hoc/withDashboardDataHOC';
import { formatCurrencyHOC } from 'src/hoc';
import * as selectors from 'src/redux/selectors';

import OpportunitiesHighlights from './widgets/opportunitiesHighlights';
import Allocation from './widgets/Allocation';
import Adoption from './widgets/Adoption';
import GrowthTrend from './widgets/growthTrend';
import SecurityScores from './widgets/securityScores';
import UnassignedLicenses from './widgets/unassignedLicenses';
import BillingDiscrepancy from './widgets/billingDiscrepancy';
import SecurityScoreIndex from './widgets/SecurityScoreIndex';
import Subscriptions from './widgets/subscriptions';
import M365Licenses from './widgets/M365Licenses';
import { Elipse } from './widgets/unassignedLicenses/UnassignedLicenses.component';
import lang from './widgets/opportunitiesHighlights/opportunitiesHighlights.lang';
import * as Styled from './dashboard.styled';

const LAYOUTS = {
  xl: [
    { i: 'licenseOverview', x: 0, y: 0, w: 6, h: 2.5 },
    { i: 'recommendations', x: 6, y: 1, w: 6, h: 2.5 },

    { i: 'allocation', x: 0, y: 2, w: 9, h: 10 },
    { i: 'm365LicensesChart', x: 9, y: 3, w: 3, h: 5 },
    { i: 'unassignedLicenses', x: 9, y: 4, w: 3, h: 5 },

    { i: 'adoption', x: 0, y: 5, w: 9, h: 10 },
    { i: 'clientGrowth', x: 9, y: 6, w: 3, h: 5 },
    { i: 'psaBillingDiscrepancies', x: 9, y: 7, w: 3, h: 5 },

    { i: 'securityScoreIndex', x: 0, y: 8, w: 9, h: 10 },
    { i: 'securityScores', x: 9, y: 9, w: 3, h: 5 },

    { i: 'subscriptions', x: 0, y: 10, w: 12, h: 10 }
  ],
  lg: [
    { i: 'licenseOverview', x: 0, y: 0, w: 6, h: 3.9 },
    { i: 'recommendations', x: 6, y: 1, w: 6, h: 3.9 },

    { i: 'allocation', x: 0, y: 2, w: 8, h: 10 },
    { i: 'm365LicensesChart', x: 9, y: 3, w: 4, h: 5 },
    { i: 'unassignedLicenses', x: 9, y: 4, w: 4, h: 5 },

    { i: 'adoption', x: 0, y: 5, w: 8, h: 10 },
    { i: 'clientGrowth', x: 9, y: 6, w: 4, h: 5 },
    { i: 'psaBillingDiscrepancies', x: 9, y: 7, w: 4, h: 5 },

    { i: 'securityScoreIndex', x: 0, y: 8, w: 8, h: 10 },
    { i: 'securityScores', x: 9, y: 9, w: 4, h: 5 },

    { i: 'subscriptions', x: 0, y: 10, w: 12, h: 10 }
  ],
  xs: [
    { i: 'licenseOverview', x: 0, y: 0, w: 12, h: 3.9 },
    { i: 'recommendations', x: 0, y: 1, w: 12, h: 3.9 },

    { i: 'allocation', x: 0, y: 2, w: 12, h: 10 },
    { i: 'm365LicensesChart', x: 0, y: 3, w: 12, h: 5 },
    { i: 'unassignedLicenses', x: 0, y: 4, w: 12, h: 5 },

    { i: 'adoption', x: 0, y: 5, w: 12, h: 10 },
    { i: 'clientGrowth', x: 0, y: 6, w: 12, h: 5 },
    { i: 'psaBillingDiscrepancies', x: 0, y: 7, w: 12, h: 5 },

    { i: 'subscriptions', x: 0, y: 8, w: 12, h: 10 },
    { i: 'securityScores', x: 0, y: 9, w: 12, h: 5 },

    { i: 'securityScoreIndex', x: 0, y: 10, w: 12, h: 10 }
  ]
};

/*************
 *   TYPES   *
 *************/

type Props = {
  actions: Function,
  hasError: boolean,
  isLoading: boolean,
  spinner: Object
};

/***************
 *   HELPERS   *
 ***************/

const elipse: Elipse = {
  elipseLongName: true,
  elipseLongWidth: '150px',
  elipseShortWidth: '100px'
};

/*****************
 *   COMPONENT   *
 *****************/

const DashboardContainer = ({ isLoading, spinner, hasError }: Props) => {
  const translate = useTranslate();

  const isDev = process.env.NODE_ENV === 'development';
  const BREAKPOINTS = { xl: 1526, lg: 992, xs: 0 };
  const COLS = { xl: 12, lg: 12, xs: 1 };

  const [expand, setExpand] = useState('');

  const LAYOUTS_EXPANDED = {
    xl: [{ i: expand, x: 0, y: 0, w: 12, h: 8 }],
    lg: [{ i: expand, x: 0, y: 0, w: 12, h: 7 }],
    xs: [{ i: expand, x: 0, y: 0, w: 12, h: 7 }]
  };

  const handleExpand = e => () => {
    if (expand === '') {
      setExpand(e);
    } else {
      setExpand('');
    }
  };

  const WIDGETS = [
    <Widget key="licenseOverview" title={translate(lang.licenseOverview)} subTitle={translate(lang.overviewSubtitle)}>
      <OpportunitiesHighlights showLicenseOverview />
    </Widget>,
    <Widget
      key="recommendations"
      subTitle={translate(lang.recommendationsSubtitle)}
      title={translate(lang.opportunities)}
    >
      <OpportunitiesHighlights showRecommendations />
    </Widget>,
    <Styled.WidgetWrap key="allocation">
      <Allocation />
    </Styled.WidgetWrap>,
    <Styled.WidgetWrap key="adoption">
      <Adoption />
    </Styled.WidgetWrap>,
    <Styled.WidgetWrap key="clientGrowth">
      <GrowthTrend />
    </Styled.WidgetWrap>,
    <Styled.WidgetWrap key="securityScores">
      <SecurityScores />
    </Styled.WidgetWrap>,
    <Widget
      key="m365LicensesChart"
      title="M365 Licenses"
      subTitle="Plan Breakdown"
      expanded={expand !== ''}
      onExpand={handleExpand('m365LicensesChart')}
    >
      <M365Licenses />
    </Widget>,
    <Styled.WidgetWrap key="unassignedLicenses">
      <UnassignedLicenses elipse={elipse} />
    </Styled.WidgetWrap>,
    <Styled.WidgetWrap key="psaBillingDiscrepancies">
      <BillingDiscrepancy />
    </Styled.WidgetWrap>,
    <Styled.WidgetWrap key="securityScoreIndex">
      <SecurityScoreIndex />
    </Styled.WidgetWrap>
  ];

  if (isDev) {
    WIDGETS.push(
      <Styled.WidgetWrap key="subscriptions">
        <Subscriptions />
      </Styled.WidgetWrap>
    );
  }

  const renderWidgets = () => {
    if (expand === '') {
      return WIDGETS.map(e => e);
    } else {
      const widget = R.find(R.propEq('key', expand), WIDGETS);
      if (widget) {
        return widget;
      } else {
        return <Widget key={expand} />;
      }
    }
  };

  return (
    <ErrorWrapper show={!isLoading && hasError}>
      <Styled.DashboardWrapper>
        <div className={isLoading ? 'aco-loading' : ''}>
          <SizeMe>
            {({ size }) => (
              <Responsive
                breakpoints={BREAKPOINTS}
                cols={COLS}
                containerWidth={size.width ? size.width : 0}
                width={size.width ? size.width : 0}
                className="layout"
                rowHeight={72}
                containerPadding={[24, 10]}
                margin={[24, 24]}
                isResizable={false}
                isDraggable={false}
                layouts={expand !== '' ? LAYOUTS_EXPANDED : LAYOUTS}
              >
                {renderWidgets()}
              </Responsive>
            )}
          </SizeMe>
        </div>
        {spinner}
      </Styled.DashboardWrapper>
    </ErrorWrapper>
  );
};

/*************
 *   REDUX   *
 *************/

const mapStateToProps = state => ({
  isLoading: selectors.dashboardIsLoadingSelector(state),
  hasError: selectors.dashboardPageHasErrorSelector(state),
  orgId: selectors.orgIdSelector(state)
});

export default compose(
  withDashboardData({ enableCache: false }),
  connect(mapStateToProps),
  formatCurrencyHOC
)(DashboardContainer);
