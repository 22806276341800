// @flow

import * as R from 'ramda';
import React from 'react';
import { Select } from 'antd';
import { useTranslate, LanguageEntry } from '@accordo-feed/language.entry';

import CheckboxGroup from 'src/components/checkboxGroup';
import Slider from 'src/components/slider';
import { loaderInlineHOC } from 'src/hoc';
import { optionsGenerator } from './filterPanel.utils';
import lang from './filterPanel.lang';
import * as Styled from './filterPanel.styled';

/*************
 *   TYPES   *
 *************/

type Props = {
  data: Object,
  spinner: Object,
  isLoading: Boolean,
  isOpen: Boolean,
  hasSecureScoreFilter: Boolean,
  hasScoreIndexFilter: Boolean,
  hasImprovementFilter: Boolean,
  hasFeatureSecurityFilterPanel: Boolean,
  selectedFilters: Object,
  onSecureScoreBarClicked: Function,
  onFilterChange: Function,
  onAfterChange: Function,
  actions: {
    setFilterPanelOpen: Function,
    getFilteredSecurityPageData: Function,
    setSelectedFilter: Function,
    setSecureScoreBarClickedIndex: Function
  }
};

/*****************
 *   COMPONENT   *
 *****************/

const { Option } = Select;

const FilterPanelComp = (props: Props) => {
  const translate = useTranslate();
  const {
    actions,
    data,
    spinner,
    isLoading,
    isOpen,
    selectedFilters,
    onFilterChange,
    onAfterChange,
    onSecureScoreBarClicked
  } = props;
  const { hasFeatureSecurityFilterPanel, hasImprovementFilter, hasScoreIndexFilter, hasSecureScoreFilter } = props;

  const maxRange = data.currentScore.max && Math.ceil(data.currentScore.max);
  const userImpacts = R.uniqBy(R.toLower, data.userImpact ?? []);

  const currentScoreSliderProps = {
    titleLang: lang.currentScore,
    minLabel: data.currentScore.min,
    maxLabel: maxRange,
    sliderProps: {
      range: true,
      min: data.currentScore.min,
      max: maxRange,
      value: [
        R.pathOr(data.currentScore.min, ['currentScore', '0'], selectedFilters),
        R.pathOr(maxRange, ['currentScore', '1'], selectedFilters)
      ],
      onChange: value => onFilterChange({ path: 'currentScore', value }),
      onAfterChange,
      disabled: false,
      width: '250px'
    }
  };

  const scoreIndexSliderProps = {
    titleLang: lang.scoreIndex,
    tooltipLang: lang.scoreIndexTooltip,
    minLabel: 0,
    maxLabel: '100%',
    sliderProps: {
      range: true,
      min: 0,
      max: 100,
      onChange: value => {
        onSecureScoreBarClicked();
        onFilterChange({ path: 'percentage', value });
      },
      onAfterChange,
      disabled: false,
      value: [R.pathOr(0, ['percentage', '0'], selectedFilters), R.pathOr(100, ['percentage', '1'], selectedFilters)],
      width: '250px'
    }
  };

  return (
    <Styled.PanelWrapper>
      {!isLoading && (
        <>
          <Styled.PanelHeader>
            <Styled.PanelTitle>{translate(lang.panelTitle)}</Styled.PanelTitle>
            <Styled.Hide onClick={() => actions.setFilterPanelOpen(false)}>Hide</Styled.Hide>
          </Styled.PanelHeader>

          <Styled.SliderWrapper>
            {hasSecureScoreFilter && (
              <Styled.SliderBar>
                <Slider {...currentScoreSliderProps} />
              </Styled.SliderBar>
            )}
            {hasScoreIndexFilter && (
              <Styled.SliderBar>
                <Slider {...scoreIndexSliderProps} />
              </Styled.SliderBar>
            )}
          </Styled.SliderWrapper>

          {hasImprovementFilter && (
            <Styled.FilterWrapper>
              <Styled.FilterTitle>{translate(lang.improvementActionTitle)}</Styled.FilterTitle>
              <div>
                <Styled.ImprovementActionSelect
                  placeholder={translate(lang.improvementActionPlaceholder)}
                  optionFilterProp="children"
                  value={R.prop('improvementAction', selectedFilters)}
                  onChange={value => {
                    onFilterChange({ path: 'improvementAction', value });
                    onAfterChange();
                  }}
                >
                  {data.improvementAction.map(action => (
                    <Option value={action} key={action}>
                      {action}
                    </Option>
                  ))}
                </Styled.ImprovementActionSelect>
              </div>
            </Styled.FilterWrapper>
          )}
          <Styled.FilterWrapper>
            <Styled.FilterTitle>{translate(lang.statusTitle)}</Styled.FilterTitle>
            <div>
              <CheckboxGroup
                direction="vertical"
                value={R.prop('status', selectedFilters)}
                options={optionsGenerator(data.status)}
                onChange={value => {
                  onFilterChange({ path: 'status', value });
                  onAfterChange();
                }}
              />
            </div>
          </Styled.FilterWrapper>
          <Styled.FilterWrapper>
            <Styled.FilterTitle>{translate(lang.categoryTitle)}</Styled.FilterTitle>
            <div>
              <CheckboxGroup
                direction="vertical"
                value={R.prop('controlCategory', selectedFilters)}
                options={optionsGenerator(data.controlCategory)}
                onChange={value => {
                  onFilterChange({ path: 'controlCategory', value });
                  onAfterChange();
                }}
              />
            </div>
          </Styled.FilterWrapper>
          <Styled.FilterWrapper>
            <Styled.FilterTitle>{translate(lang.userImpactTitle)}</Styled.FilterTitle>
            <div>
              <CheckboxGroup
                direction="vertical"
                value={R.prop('userImpact', selectedFilters)}
                options={optionsGenerator(userImpacts)}
                onChange={value => {
                  const lowerValue = value.map(e => R.toLower(e));
                  onFilterChange({ path: 'userImpact', value: [...value, ...lowerValue] });
                  onAfterChange();
                }}
              />
            </div>
          </Styled.FilterWrapper>
        </>
      )}
      {hasFeatureSecurityFilterPanel && isOpen && spinner}
    </Styled.PanelWrapper>
  );
};

export default loaderInlineHOC({
  message: <LanguageEntry id="loading.loaderInline.language.id" defaultMessage="Loading..." />
})(FilterPanelComp);
