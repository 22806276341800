// @flow
import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { Col, Row } from 'antd';
import { useTranslate } from '@accordo-feed/language.entry';
import { constants } from '@accordo-feed/aco-styled-components';

import Widget from 'src/components/widget';
import StyledLink from 'src/components/link';
import { widgetDataHOC } from 'src/hoc';
import { PATHS } from 'src/constants';
import { nestedWidgetBackground } from 'src/App.styled';
import Recommendations from './Recommendations.component';
import lang from './opportunitiesHighlights.lang';
import * as Styled from './opportunitiesHighlights.styled';

const { fontSize } = constants;

/*************
 *   TYPES   *
 *************/

type Props = {
  overview: Object,
  recommendations: Object,
  showRecommendations: boolean,
  router: Object,
  theme?: 'light' | 'dark'
};

/*****************
 *   COMPONENT   *
 *****************/

const OpportunitiesHighlights = ({ theme, overview, recommendations, showRecommendations, router }: Props) => {
  const translate = useTranslate();

  const { companies, subscriptions } = overview;

  if (showRecommendations) {
    return (
      <Recommendations
        title={translate(lang.recommendationsOptimization)}
        recommendations={recommendations}
        theme={theme}
      />
    );
  }

  return (
    <Styled.Summary>
      <Styled.CustomRow type="flex" justify="space-around" gutter={[24, 16]}>
        <Col xs={{ span: 24 }} xxl={{ span: 12 }}>
          <Widget isSubWidget title={translate(lang.companiesTitle)} customBackground={nestedWidgetBackground}>
            <Styled.HighlightWrapper>
              <Row type="flex">
                <Col xs={6}>
                  <Styled.NumberBox>
                    <Styled.NumberLabel theme={theme}>{companies?.customers ?? 0}</Styled.NumberLabel>
                    <Styled.Link
                      onClick={() =>
                        router.push({
                          pathname: PATHS.CUSTOMERS.ROOT,
                          state: { filters: { status: [], type: ['Customer'] } }
                        })
                      }
                      fontSize={fontSize.xSmall}
                    >
                      {translate(lang.customers)}
                    </Styled.Link>
                  </Styled.NumberBox>
                </Col>
                <Col xs={6}>
                  <Styled.NumberBox>
                    <Styled.NumberLabel theme={theme}>{companies?.prospects ?? 0}</Styled.NumberLabel>
                    <Styled.Link
                      onClick={() =>
                        router.push({
                          pathname: PATHS.CUSTOMERS.ROOT,
                          state: { filters: { status: [], type: ['Prospect'] } }
                        })
                      }
                      fontSize={fontSize.xSmall}
                    >
                      {translate(lang.prospects)}
                    </Styled.Link>
                  </Styled.NumberBox>
                </Col>
                <Col xs={6}>
                  <Styled.NumberBox>
                    <Styled.NumberLabel theme={theme}>{companies?.connected ?? 0}</Styled.NumberLabel>
                    <Styled.Link
                      onClick={() =>
                        router.push({
                          pathname: PATHS.CUSTOMERS.ROOT,
                          state: { filters: { status: ['Active'], type: [] } }
                        })
                      }
                      fontSize={fontSize.xSmall}
                    >
                      {translate(lang.connected)}
                    </Styled.Link>
                  </Styled.NumberBox>
                </Col>
                <Col xs={6}>
                  <Styled.NumberBox noBorder={true}>
                    <Styled.NumberLabel theme={theme} colorIdx="3">
                      {companies?.failed ?? 0}
                    </Styled.NumberLabel>
                    <Styled.Link
                      onClick={() =>
                        router.push({
                          pathname: PATHS.CUSTOMERS.ROOT,
                          state: { filters: { status: ['Inactive', 'Auth Error'], type: [] } }
                        })
                      }
                      fontSize={fontSize.xSmall}
                    >
                      {translate(lang.failed)}
                    </Styled.Link>
                  </Styled.NumberBox>
                </Col>
              </Row>
            </Styled.HighlightWrapper>
          </Widget>
        </Col>

        <Col xs={{ span: 24 }} xxl={{ span: 12 }}>
          <Widget
            isSubWidget
            direction="horizontal"
            title={translate(lang.subscriptionsTitle)}
            subTitle={translate(lang.subscriptionsSubTitle)}
            customBackground={nestedWidgetBackground}
          >
            <Styled.HighlightWrapper>
              <Row type="flex">
                <Col xs={8}>
                  <Styled.NumberBox>
                    <Styled.NumberLabel theme={theme}>{subscriptions?.legacySubs ?? 0}</Styled.NumberLabel>
                    <StyledLink fontSize={fontSize.xSmall}>{translate(lang.legacy)}</StyledLink>
                  </Styled.NumberBox>
                </Col>
                <Col xs={8}>
                  <Styled.NumberBox>
                    <Styled.NumberLabel theme={theme}>{subscriptions?.newCommerce ?? 0}</Styled.NumberLabel>
                    <StyledLink fontSize={fontSize.xSmall}>{translate(lang.newCommerce)}</StyledLink>
                  </Styled.NumberBox>
                </Col>
                <Col xs={8}>
                  <Styled.NumberBox noBorder={true}>
                    <Styled.NumberLabel theme={theme} colorIdx="1">
                      {subscriptions?.totalLicenses ?? 0}
                    </Styled.NumberLabel>
                    <StyledLink fontSize={fontSize.xSmall}>{translate(lang.totalLicenses)}</StyledLink>
                  </Styled.NumberBox>
                </Col>
              </Row>
            </Styled.HighlightWrapper>
          </Widget>
        </Col>
      </Styled.CustomRow>
    </Styled.Summary>
  );
};

export default compose(widgetDataHOC(['overview', 'companies']), withRouter)(OpportunitiesHighlights);
