// @flow

import React, { useState } from 'react';
import { useTranslate } from '@accordo-feed/language.entry';
import { notification, Row, Col } from 'antd';

import UIButton from 'src/components/UI/button';
import * as Styled from './accountSecurity.styled';
import { Title, Description, FormRow, FieldLabel, FieldIntro } from 'src/CustomComponent.styled';
import lang from './accountSecurity.lang';
import { auth } from 'src/utils';

const { mfaEnableButtonText, mfaDisableButtonText } = lang;

/*************
 *   TYPES   *
 *************/

type Props = {
  openModal: Function,
  isMfaEnabled: boolean,
  email: string
};

export default ({ openModal, isMfaEnabled, email }: Props) => {
  const translate = useTranslate();
  const [passwordEmailSending, setPasswordEmailSending] = useState(false);

  const changePasswordNotification = ({ err, resp }) => {
    if (err) {
      notification.error({ message: translate(lang.passwordEmailSentError) });
    } else {
      notification.success({ message: translate(lang.passwordEmailSentNotification) });
    }
    setPasswordEmailSending(false);
  };

  return (
    <Row gutter={[24, 16]}>
      <Col xs={{ span: 24 }} lg={{ span: 6 }}>
        <Title>{translate(lang.heading)}</Title>
        <Description>{lang.mfaDescription}</Description>
      </Col>

      <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 8 }}>
        <FormRow gutter={[24, 0]}>
          <Styled.MfaOperationWrapper>
            <FieldLabel>{translate(lang.mfaWording)}</FieldLabel>
            {isMfaEnabled ? (
              <Styled.MfaIconsContainer>
                <Styled.MfaEnabledIcon />
                <Styled.MfaStatusText>{translate(lang.mfaEnabledText)}</Styled.MfaStatusText>
              </Styled.MfaIconsContainer>
            ) : (
              <Styled.MfaIconsContainer>
                <Styled.MfaDisabledIcon />
                <Styled.MfaStatusText>{translate(lang.mfaDisabledText)}</Styled.MfaStatusText>
              </Styled.MfaIconsContainer>
            )}
          </Styled.MfaOperationWrapper>

          <UIButton variant="outline" onClick={openModal} margin="10px 0 0 0">
            {translate(isMfaEnabled ? mfaDisableButtonText : mfaEnableButtonText)}
          </UIButton>
        </FormRow>

        <FieldLabel>{translate(lang.changePasswordHeading)}</FieldLabel>
        <FieldIntro>{translate(lang.changePasswordDescription)}</FieldIntro>

        <UIButton
          variant="outline"
          onClick={() => {
            setPasswordEmailSending(true);
            auth.changePassword(email, changePasswordNotification);
          }}
          margin="10px 0 0 0"
          loading={passwordEmailSending}
        >
          {translate(lang.changePasswordButtonText)}
        </UIButton>
      </Col>
    </Row>
  );
};
