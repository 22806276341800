import { compose } from 'recompose';
import { connect } from 'react-redux';
import { currency } from '@accordo-feed/micro-frontends-utils';

import * as selectors from 'src/redux/selectors';

const mapStateToProps = state => {
  const currencyOptions = selectors.currencyOptionsSelector(state);
  const formatCurrency = number => currency.formatCurrency(number, currencyOptions);
  const formatCurrencyWithoutUnit = number => currency.formatCurrencyWithoutUnit(number, currencyOptions);

  return {
    formatCurrency,
    formatCurrencyWithoutUnit
  };
};

export default compose(connect(mapStateToProps));
