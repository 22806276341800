import styled from 'styled-components';
import { constants, mixins } from '@accordo-feed/aco-styled-components';

const { colors, fontSize } = constants;

export const TitleWrapper = styled.div`
  ${mixins.flexVerticalCenter()};
  justify-content: space-between;
  margin: 20px 0;
`;

export const Description = styled.div`
  font-size: ${fontSize.small};
  color: ${colors.darkShadeGrey};
  width: 90%;
  margin-top: -10px;
`;

export const ContentTitle = styled.div`
  font-size: ${fontSize.small};
`;
