// @flow

import React, { useEffect } from 'react';
import { LanguageEntry, LanguageEntryProvider } from '@accordo-feed/language.entry';
import { LoadingView } from '@accordo-feed/aco-styled-components';
import { SubModule } from '@accordo-feed/micro-frontends';

import { APP_VIEWS } from 'src/constants';
import { SUBMODULES } from 'src/constants';
import { getFilepath } from 'src/utils/submodule';
import { setAppView } from 'src/redux/modules/appStatus/appStatus.duck';
import { useReduxStore } from 'src/hooks';

const filepath = getFilepath(SUBMODULES.OFFICE_365_OPTIMIZER, 'bundle');

const Spinner = () => (
  <LanguageEntryProvider>
    <LoadingView
      loadingMsg={
        <LanguageEntry id="consumption.loadingspinnertext2" defaultMessage="ENTORI™ is loading your customer data" />
      }
    />
  </LanguageEntryProvider>
);

export default () => {
  const store = useReduxStore();

  useEffect(() => {
    store.dispatch(setAppView(APP_VIEWS.OFFICE_365_OPTIMIZER));

    return () => {
      store.dispatch(setAppView(APP_VIEWS.SHELL));
    };
  }, []);

  return <SubModule filePath={filepath} store={store} spinner={<Spinner />} />;
};
