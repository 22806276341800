import React from 'react';
import { LanguageEntry } from '@accordo-feed/language.entry';

import { PATHS, SUBMODULES } from 'src/constants';
import { sortByAlph, sortByNumber } from 'src/utils';
import lang from './adoption.lang.js';
import * as Styled from './adoption.styled';

const tableLang = lang.table;

/***************
 *   CONFIGS   *
 ***************/

const defaultWidth = {
  small: 70,
  medium: 110,
  large: 130,
  xlarge: 210
};

export const generateTableColumns = props => {
  const { enableSort, prevPath } = props;

  return [
    {
      title: <LanguageEntry {...tableLang.main.company} />,
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      className: 'at_columnCompany',
      sorter: enableSort && sortByAlph('name'),
      width: defaultWidth.xlarge,
      render: (name, { id }) => (
        <Styled.VisitLink
          to={{
            pathname: `${PATHS.CUSTOMERS.ROOT}/${id}/`,
            state: { prevPath: prevPath || PATHS.ALLOCATION_ADOPTION_DASHBOARD }
          }}
          className="at__visitO365 aco-text-left"
        >
          <Styled.LinkName>{name}</Styled.LinkName>
        </Styled.VisitLink>
      )
    },
    {
      title: <LanguageEntry {...tableLang.main.m365} />,
      children: [
        {
          title: <LanguageEntry {...tableLang.base.th1} />,
          dataIndex: 'inactiveUsers',
          key: 'inactiveUsers',
          width: defaultWidth.medium,
          className: 'at_columnTotalInactive',
          sorter: enableSort && sortByNumber('inactiveUsers'),
          defaultSortOrder: 'descend',
          render: (inactiveUsers, { id }) => (
            <Styled.VisitLink
              to={{
                pathname: `/${SUBMODULES.OFFICE_365_OPTIMIZER}/${id}/recommendations`,
                state: { prevPath: prevPath || PATHS.ALLOCATION_ADOPTION_DASHBOARD }
              }}
              className="at__visitO365"
            >
              <Styled.LinkName className="aco-light-red">{inactiveUsers}</Styled.LinkName>
            </Styled.VisitLink>
          )
        },
        {
          title: <LanguageEntry {...tableLang.base.th2} />,
          dataIndex: 'lessActiveUsers',
          key: 'lessActiveUsers',
          width: defaultWidth.medium,
          className: 'at_columnLessActive aco-td-indigo',
          sorter: enableSort && sortByNumber('lessActiveUsers'),
          render: (lessActiveUsers, { id }) => (
            <Styled.VisitLink
              to={{
                pathname: `/${SUBMODULES.OFFICE_365_OPTIMIZER}/${id}/recommendations`,
                state: { prevPath: prevPath || PATHS.ALLOCATION_ADOPTION_DASHBOARD }
              }}
              className="at__visitO365"
            >
              <Styled.LinkName>{lessActiveUsers}</Styled.LinkName>
            </Styled.VisitLink>
          )
        }
      ]
    },
    {
      title: <LanguageEntry {...tableLang.main.teams} />,
      children: [
        {
          title: <LanguageEntry {...tableLang.base.th1} />,
          dataIndex: ['details', 5, 'inactiveUsers'],
          key: 'teamsInactiveUsers',
          width: defaultWidth.medium,
          className: 'at_columnTeamsInactive',
          sorter: enableSort && sortByNumber('details', 5, 'inactiveUsers')
        },
        {
          title: <LanguageEntry {...tableLang.base.th2} />,
          dataIndex: ['details', 5, 'lessActiveUsers'],
          key: 'teamsLessActiveUsers',
          width: defaultWidth.medium,
          className: 'at_columnTeamsLessActive',
          sorter: enableSort && sortByNumber('details', 5, 'lessActiveUsers')
        }
      ]
    },
    {
      title: <LanguageEntry {...tableLang.main.sharepoint} />,
      children: [
        {
          title: <LanguageEntry {...tableLang.base.th1} />,
          dataIndex: ['details', 2, 'inactiveUsers'],
          key: 'sharepointInactiveUsers',
          width: defaultWidth.medium,
          className: 'at_columnSharepointInactive',
          sorter: enableSort && sortByNumber('details', 2, 'inactiveUsers')
        },
        {
          title: <LanguageEntry {...tableLang.base.th2} />,
          dataIndex: ['details', 2, 'lessActiveUsers'],
          key: 'sharepointLessActiveUsers',
          width: defaultWidth.medium,
          className: 'at_columnSharepointLessActive',
          sorter: enableSort && sortByNumber('details', 2, 'lessActiveUsers')
        }
      ]
    },
    {
      title: <LanguageEntry {...tableLang.main.exchange} />,
      children: [
        {
          title: <LanguageEntry {...tableLang.base.th1} />,
          dataIndex: ['details', 0, 'inactiveUsers'],
          key: 'exchangeInactiveUsers',
          width: defaultWidth.medium,
          className: 'at_columnExchangeInactive',
          sorter: enableSort && sortByNumber('details', 0, 'inactiveUsers')
        },
        {
          title: <LanguageEntry {...tableLang.base.th2} />,
          dataIndex: ['details', 0, 'lessActiveUsers'],
          key: 'exchangeLessActiveUsers',
          width: defaultWidth.medium,
          className: 'at_columnExchangeLessActive',
          sorter: enableSort && sortByNumber('details', 0, 'lessActiveUsers')
        }
      ]
    }
  ];
};
