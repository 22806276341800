// @flow

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose } from 'recompose';

import withDashboardData from 'src/hoc/withDashboardDataHOC';
import { getDashboardData } from 'src/pages/dashboard/dashboard.duck';
import { clearMasterListFilters } from 'src/redux/modules/filters/filters.duck';
import { setSearchTerm } from 'src/redux/modules/searchTerm/searchTerm.duck';
import * as selectors from 'src/redux/selectors';
import { hasFiltersSelector } from './masterList.selector';
import MasterListComp from './MasterList.component';

/*****************
 *   COMPONENT   *
 *****************/

const MasterList = props => <MasterListComp {...props} />;

/*************
 *   REDUX   *
 *************/

const mapStateToProps = state => ({
  tableData: selectors.masterListDataSourceSelector(state),
  isLoading: selectors.dashboardIsLoadingSelector(state),
  hasError: selectors.dashboardPageHasErrorSelector(state),
  searchTerm: selectors.searchTermSelector(state),
  hasFilters: hasFiltersSelector(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ getDashboardData, setSearchTerm, clearMasterListFilters }, dispatch)
});

export default compose(
  withDashboardData({ enableCache: false }),
  connect(mapStateToProps, mapDispatchToProps)
)(MasterList);
