import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { translateHOC } from '@accordo-feed/language.entry';

import { getSubscriptionUsage, cancelSubscription } from 'src/redux/modules/payment/payment.duck';
import * as selectors from 'src/redux/selectors';
import Subscriptions from './Subcriptions.component';

/*************
 *   REDUX   *
 *************/

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ getSubscriptionUsage, cancelSubscription }, dispatch)
});

const mapStateToProps = state => ({
  orgId: selectors.orgIdSelector(state),
  subscriptions: selectors.subscriptionsSelector(state),
  loadingStatus: selectors.subscriptionsLoadingStatusSelector(state),
  cancelSubscriptionLoadingStatus: selectors.cancelSubscriptionLoadingStatusSelector(state),
  willOffice365BeCancelled: selectors.willOffice365BeCancelledSelector(state),
  office365CancelTime: selectors.office365BeCancelTimeSelector(state)
});

export default compose(connect(mapStateToProps, mapDispatchToProps), translateHOC)(Subscriptions);
