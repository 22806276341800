import * as R from 'ramda';
import { captureException } from '@sentry/browser';
import { command } from '@accordo-feed/micro-frontends';

import * as errorModalActions from 'src/redux/modules/errorModal/errorModal.duck';
import segment from './segment';
import { MF_COMMAND_EVENTS, SHELL_NAME, PATHS } from 'src/constants';
import { getApiName } from './url';

const handleAPIException = ({ error, submoduleName }) => {
  const { config } = error;
  const { url } = config;
  const API = getApiName(url);
  const position = `apiError:${submoduleName}`;

  captureException(error, {
    extra: {
      apiRequest: {
        url,
        options: R.pick(['method', 'data'], config)
      }
    },
    tags: {
      API,
      position
    },
    fingerprint: [position, API]
  });
};

/**
 * initCommandListeners
 * @param {redux} store redux store which can further using dispatch & getState
 */
export const initCommandListeners = store => {
  command.addListener(MF_COMMAND_EVENTS.GET_SHELL_ID, () => {
    command.dispatch(MF_COMMAND_EVENTS.RETURN_SHELL_ID, SHELL_NAME);
  });

  command.addListener(
    MF_COMMAND_EVENTS.API_ERROR,

    ({ error, submoduleName }) => {
      handleAPIException({ error, submoduleName });

      const { showErrorModal } = errorModalActions;
      store.dispatch(showErrorModal({ error }));
    }
  );

  command.addListener(MF_COMMAND_EVENTS.INVOKE_REDUX_ACTION, store.dispatch);
};

/**
 * initRouterCommandListeners
 * @param {router} history router history
 */
export const initRouterCommandListeners = history => {
  command.addListener(MF_COMMAND_EVENTS.ERROR_404, () => {
    history.push(PATHS.CUSTOMERS.ROOT);
  });

  // Accept a string path or an object history parameter in the shape of { path, method }
  command.addListener(MF_COMMAND_EVENTS.NAVIGATE, path => {
    let method = 'push';

    if (R.is(Object, path)) {
      method = path.method;
      path = path.path;
    }

    history[method](path);
  });

  command.addListener(MF_COMMAND_EVENTS.EVENT_TRACK, segment.track);
};

/**
 * removeCommandListeners
 * @param {redux} store redux store which can further using dispatch & getState
 */
export const removeCommandListeners = () => {
  command.removeListeners(R.values(MF_COMMAND_EVENTS));
};
