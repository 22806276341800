// @flow

import * as R from 'ramda';
import { connect } from 'react-redux';

import * as selectors from 'src/redux/selectors';
import SettingTheme from './SettingTheme.component';

/*************
 *   REDUX   *
 *************/

const mapStateToProps = R.applySpec({
  hasTeamInvites: selectors.hasFeatureTeamInvites,
  hasNotifications: selectors.hasNotifications,
  isDistributor: selectors.hasFeatureDistributor,
  isPartnerMember: selectors.hasFeaturePartnerMember
});

export default connect(mapStateToProps)(SettingTheme);
