import styled from 'styled-components';
import { Alert } from 'antd';
import { constants } from '@accordo-feed/aco-styled-components';
import { widgetBackground } from 'src/App.styled';

const { colors } = constants;

export const StyledAlert = styled(Alert)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  height: 30px !important;
  margin: auto 0 !important;
  background: ${widgetBackground};

  span {
    color: ${colors.aquaBlue3};
  }
`;
