import styled from 'styled-components';
import { Slider as AntSlider } from 'antd';
import { mixins, constants } from '@accordo-feed/aco-styled-components';
import { appTextColor, widgetHeaderTextColor } from 'src/App.styled';

const { fontSize } = constants;

export const FilterWrapper = styled.div`
  float: left;
  margin-right: 40px;

  &:last-child {
    margin-right: 0;
  }
`;

export const Title = styled.div`
  ${mixins.verticalLineMiddle('20px')}
  display: flex;
  margin-bottom: 8px;
  color: ${appTextColor};
  justify-content: left;
`;

export const SliderWrapper = styled.div`
  ${mixins.flexVerticalCenter()};
  width: ${props => props.width || '300px'};
`;

export const Slider = styled(AntSlider)`
  flex: 1;
  margin: 0 12px;
`;

export const SliderLabel = styled.div`
  color: ${widgetHeaderTextColor};
  font-size: ${fontSize.xSmall};
`;
