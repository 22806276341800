// @flow

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslate } from '@accordo-feed/language.entry';

import Search from 'src/components/search';
import Widget from 'src/components/widget';
import * as selectors from 'src/redux/selectors';
import SubscriptionsComponent from './Subscriptions.component';
import lang from './subscriptions.lang';
import { SUBSCRIPTIONS_MOCK_DATA } from './subscriptions.constants';

/*****************
 *   COMPONENT   *
 *****************/

const Subscriptions = props => {
  const translate = useTranslate();
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <Widget
      key="subscriptions"
      title={translate(lang.widgetTitle)}
      headerRight={<Search searchTerm={searchTerm} onChange={e => setSearchTerm(e.target.value)} />}
    >
      <SubscriptionsComponent data={SUBSCRIPTIONS_MOCK_DATA} search={searchTerm} />
    </Widget>
  );
};

/*************
 *   REDUX   *
 *************/

const mapStateToProps = state => ({
  data: selectors.dashboardMasterListWidgetSelector(state)
});

export default connect(mapStateToProps)(Subscriptions);
