// @flow

import { createAction } from 'redux-actions';
import { redux } from '@accordo-feed/micro-frontends';

import type { Action } from 'src/redux/types';
import { PARTNER_CENTER_AUTH_STATES } from './partnerCenter.constants';
import { wrapWithModule } from 'src/redux/utils';

const { setState, genApiSchema } = redux;

/*************
 *   TYPES   *
 *************/

type AuthType =
  | PARTNER_CENTER_AUTH_STATES.NEW
  | PARTNER_CENTER_AUTH_STATES.ACCESS_GRANTED
  | PARTNER_CENTER_AUTH_STATES.INVALID_TOKEN
  | PARTNER_CENTER_AUTH_STATES.DISCONNECTED;

export type DataType = {
  partnerAppId?: string,
  partnerAppSecret?: string,
  tenantId?: string,
  partnerAppAuthState?: AuthType,
  authState?: AuthType,
  lastTriggered?: Date,
  totalPartnerCenterCustomersSelector?: number
};

export type StateType = {
  data: DataType,
  isConnectionFailed: boolean,
  isLinking: boolean,
  isLoaded: boolean,
  isLoading: boolean
};

/*********************
 *   INITIAL STATE   *
 *********************/

const initialState: StateType = genApiSchema({
  data: {
    authState: PARTNER_CENTER_AUTH_STATES.NEW
  },
  isConnectionFailed: false,
  isLinking: false
});

/***************
 *   ACTIONS   *
 ***************/

const wrapWithNamespace = wrapWithModule('pages/partnerCenter');
const GET_CONNECTION_STATE = wrapWithNamespace('GET_CONNECTION_STATE');
const GET_CONNECTION_STATE_SUCCESS = wrapWithNamespace('GET_CONNECTION_STATE_SUCCESS');
const SET_LINKING = wrapWithNamespace('SET_LINKING');
const SET_LOADING = wrapWithNamespace('SET_LOADING');
const SET_CONNECTION_FAILED = wrapWithNamespace('SET_CONNECTION_FAILED');
const HANDLE_CONNECTION_FAILED = wrapWithNamespace('HANDLE_CONNECTION_FAILED');
const CONNECT_PARTNER_CENTER = wrapWithNamespace('CONNECT_PARTNER_CENTER');
const CONNECT_PARTNER_CENTER_SUCCESSFUL = wrapWithNamespace('CONNECT_PARTNER_CENTER_SUCCESSFUL');

export const getConnectionState = createAction(GET_CONNECTION_STATE);
export const getConnectionStateSuccess = createAction(GET_CONNECTION_STATE_SUCCESS);
export const setLinking = createAction(SET_LINKING);
export const setLoading = createAction(SET_LOADING);
export const setConnectionFailed = createAction(SET_CONNECTION_FAILED);
export const handleConnectionFailed = createAction(HANDLE_CONNECTION_FAILED);
export const connectPartnerCenter = createAction(CONNECT_PARTNER_CENTER);
export const connectPartnerCenterSuccessful = createAction(CONNECT_PARTNER_CENTER_SUCCESSFUL);

/***************
 *   REDUCER   *
 ***************/

export default (state: StateType = initialState, action: Action) => {
  const { type, payload } = action;

  const reducer = {
    [GET_CONNECTION_STATE_SUCCESS]: (state, payload) => ({
      ...initialState,
      data: payload,
      isLoaded: true
    }),

    [SET_CONNECTION_FAILED]: state => ({
      ...state,
      isLinking: false,
      isConnectionFailed: true
    }),

    [SET_LINKING]: setState('isLinking'),

    [SET_LOADING]: setState('isLoading')
  }[type];

  return reducer ? reducer(state, payload) : state;
};
