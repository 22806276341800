// @flow

import * as R from 'ramda';
import { captureException } from '@sentry/browser';

import { getConfigValue } from 'src/config';
import { deleteCookieWithPrefix } from './cookie';

/*************
 *   TYPES   *
 *************/

declare var Intercom: Function;
declare var __DEPLOYMENT_ENV__: string;
declare var analytics: Object;

type IdentifyProps = {
  email: string,
  firstName: string,
  lastName: string,
  orgCountryCode: string,
  orgId: string,
  orgName: string,
  partnerId: string,
  userId: string,
  userRole: string
};

type TrackProps = {
  trackName: string,
  trackMethod: string,
  data: Object
};

class Segment {
  properties = {};

  constructor() {
    // To debug on local, please comment out the following line
    if (__DEPLOYMENT_ENV__ === 'local') return;

    analytics.load(getConfigValue('segmentWriteKey'));
  }

  initForUser = ({
    email,
    firstName,
    lastName,
    orgCountryCode,
    orgId,
    orgName,
    partnerId,
    userId,
    userRole
  }: IdentifyProps) => {
    // We don't want our test account to trigger intercom.
    // Currently, segment only support server-side event filtering
    // We should replace the bellow logic when they start to support client-side event filtering
    const isAccordoTestUser = email.endsWith('@accordo.com') || orgId.startsWith('ac_ffffffff');

    this.properties = {
      orgId,
      partnerId: partnerId || orgId,
      userId,
      userRole
    };

    analytics.identify(
      userId,
      {
        ...this.properties,
        email,
        firstName,
        lastName,
        orgName,
        orgCountryCode
      },
      {
        integrations: isAccordoTestUser
          ? {
              Intercom: false
            }
          : {
              All: true
            }
      }
    );
  };

  initForGuest = () => {
    analytics.reset();
    deleteCookieWithPrefix('intercom');
  };

  reset = () => {
    analytics.reset();

    if (window.Intercom) {
      Intercom('shutdown');
    }
  };

  page = (name: string, properties: Object) => {
    analytics.page(name, R.mergeRight(this.properties, properties));
  };

  track = ({ trackName, trackMethod, data }: TrackProps) => {
    try {
      analytics[trackMethod](trackName, R.mergeRight(this.properties, data));
    } catch (err) {
      captureException(err);
    }
  };
}

export default new Segment();
