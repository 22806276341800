// @flow

import * as R from 'ramda';
import { isEmptyOrNil } from 'src/utils';

export const countValues = (property: string, comparator: string) => (arr: any[]) =>
  R.isNil(arr) ? 0 : R.reduce((acc, cur) => (cur[property] === comparator ? ++acc : acc), 0)(arr);

export const defaultBillingDiscrepancyWidgetData = {
  totalSurplus: 0,
  totalDeficit: 0
};

export const transform = ({ aggregations }: Object): Object => {
  if (isEmptyOrNil(aggregations)) {
    return defaultBillingDiscrepancyWidgetData;
  }

  const totalSurplus = R.pathOr(0, ['totalSurplus', 'value'], aggregations);
  const totalDeficit = R.pathOr(0, ['totalDeficit', 'value'], aggregations);

  const transformation = {
    totalSurplus: R.always(totalSurplus),
    totalDeficit: R.always(totalDeficit)
  };

  return R.evolve(transformation, defaultBillingDiscrepancyWidgetData);
};
