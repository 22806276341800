// @flow

import React, { useEffect } from 'react';
import { LoadingView } from '@accordo-feed/aco-styled-components';
import { useTranslate } from '@accordo-feed/language.entry';

import lang from './authError.lang';
import { auth } from 'src/utils';

/*****************
 *   COMPONENT   *
 *****************/

const AuthError = () => {
  const translate = useTranslate();

  useEffect(() => {
    // Sign out the user if the user has already signed in.
    // This will ensure the user start a new session.
    if (auth.isAuthenticated()) {
      auth.logout();
    }
  }, []);

  return <LoadingView loadingMsg={translate(lang.loading)} />;
};

export default AuthError;
