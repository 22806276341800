// @flow

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose } from 'recompose';

import * as selectors from 'src/redux/selectors';
import { dashboardOpportunitiesSelector } from 'src/pages/dashboard/dashboard.selector';
import { getDashboardData } from 'src/pages/dashboard/dashboard.duck';
import { setSearchTerm } from 'src/redux/modules/searchTerm/searchTerm.duck';
import OpportunitiesComp from './Opportunities.component';
import withDashboardData from 'src/hoc/withDashboardDataHOC';

/*****************
 *   COMPONENT   *
 *****************/

const Opportunities = props => <OpportunitiesComp {...props} />;

/*************
 *   REDUX   *
 *************/

const mapStateToProps = state => ({
  dataSource: selectors.opportunitiesCtaDataSourceSelector(state),
  originalDataSource: selectors.dashboardMasterListSelector(state),
  isLoading: selectors.dashboardIsLoadingSelector(state),
  hasError: selectors.dashboardPageHasErrorSelector(state),
  searchTerm: selectors.searchTermSelector(state),
  recommendations: dashboardOpportunitiesSelector(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ getDashboardData, setSearchTerm }, dispatch)
});

export default compose(
  withDashboardData(),

  connect(mapStateToProps, mapDispatchToProps)
)(Opportunities);
