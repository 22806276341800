import styled from 'styled-components';
import { constants } from '@accordo-feed/aco-styled-components';
import { Row } from 'antd';

import StyledLink from 'src/components/link';
import { subHeaderTextColor, appLabelColor, widgetBorderColor } from 'src/App.styled';
import { WidgetCardTitle } from 'src/components/widget/widget.styled';

const { fontSize } = constants;

const LabelColors = ['#61ABDC', '#5CD470', '#F3D2B8', '#E06F74'];
export const Summary = styled.div`
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const HighlightWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  padding: 0 0 20px;
`;

export const RecommendationWrapper = styled(HighlightWrapper)``;

export const NumberBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: ${props => (props.noBorder ? `none` : `1px solid`)};
  border-color: ${widgetBorderColor};
`;

export const NumberLabel = styled.span`
  color: ${props =>
    props.theme === 'light' ? appLabelColor : props.colorIdx ? LabelColors[props.colorIdx] : LabelColors[0]};
  font-size: 30px;
  line-height: 2.5rem;
  font-weight: bold;
`;

export const Link = styled(StyledLink)`
  text-decoration: underline;
`;

export const Text = styled.span`
  color: ${subHeaderTextColor} !important;
  font-size: ${fontSize.xxSmall};
  line-height: 150%;
`;

export const RecommendationBox = styled(NumberBox)`
  &:nth-last-child(n + 2) {
    border-right: none;
  }
`;

export const CardTitle = styled(WidgetCardTitle)`
  position: absolute;
  top: 5px;
  left: 12px;
`;

export const CustomRow = styled(Row)`
  padding: 0 15px;
  .ant-row {
    width: 100%;
  }
`;

export const SecureScoreLinkWrapper = styled.div`
  padding-left: 15px;
`;
