import styled from 'styled-components';
import { Button } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { mixins, constants } from '@accordo-feed/aco-styled-components';

import * as Step1Styled from 'src/pages/secureApp/rightSide/step1/step1.styled';
import LangWrapper from 'src/components/langWrapper';

export { Body, Footer } from 'src/pages/secureApp/rightSide/step1/step1.styled';

const { colors, fontSize } = constants;

export const Container = styled(Step1Styled.Container)`
  text-align: center;
`;

export const CircleCheckIcon = styled(CheckCircleOutlined)`
  font-size: 60px;
  color: ${colors.shadeGreen};
  margin-bottom: 30px;
`;

export const Title = styled.h1`
  font-size: ${fontSize.large};
  margin-bottom: 30px;
  padding: 0 30px;
`;

export const Desc = styled(LangWrapper)`
  margin-bottom: 80px;
`;

export const ButtonsWrapper = styled.div`
  ${mixins.flexCenter()};
  margin-bottom: 30px;
`;

export const Connect = styled(Button).attrs({
  size: 'large',
  type: 'primary'
})``;

export const Back = styled(Button).attrs({
  size: 'large',
  type: 'link'
})`
  margin-right: 10px;
  color: ${colors.darkShadeGrey};

  i {
    margin-left: 10px;
  }
`;
