// @flow

import React, { useEffect } from 'react';
import { useTranslate } from '@accordo-feed/language.entry';

import * as Styled from './search.styled';
import lang from './search.lang';

/*************
 *   TYPES   *
 *************/

type Actions = {
  resetSearchTerm: Function
};

type Props = {
  actions: Actions,
  className: string,
  clearOnUnmount: boolean,
  searchBarProps: Object,
  searchTerm: string
};

/*****************
 *   COMPONENT   *
 *****************/

const Search = ({ actions, className, searchTerm, ...searchBarProps }: Props) => {
  const translate = useTranslate();

  useEffect(() => {
    const { resetSearchTerm } = actions;
    const { clearOnUnmount = true } = searchBarProps;

    return () => {
      if (clearOnUnmount && resetSearchTerm) {
        resetSearchTerm();
      }
    };
  }, []);

  return (
    <Styled.Container className={className}>
      <Styled.Input placeholder={translate(lang.placeholder)} value={searchTerm} {...searchBarProps} />
      <Styled.Icon />
    </Styled.Container>
  );
};

Search.defaultProps = {
  actions: {}
};

export default Search;
