// @flow

import consoleHandler from './console';
import { handlerClients, handlerOauth, handlerLoadAllCustomers, handlerCustomersOauth } from './partnerCenter';
import { HANDLER_TYPES } from 'src/utils/iot/constants';

export default {
  [HANDLER_TYPES.CONSOLE]: consoleHandler,
  [HANDLER_TYPES.PARTNER_CLIENT_ORG_INDEX_UPDATED]: handlerClients,
  [HANDLER_TYPES.PC_OAUTH]: handlerOauth,
  [HANDLER_TYPES.LOAD_ALL_CUSTOMERS]: handlerLoadAllCustomers,
  [HANDLER_TYPES.GRAPH_OAUTH]: handlerCustomersOauth
};
