import React, { useEffect, useState } from 'react';
import * as R from 'ramda';

import { LoadingData, NoData, ErrorData } from 'src/components/widgetWrapper';

const isNoData = (dataPath, checkZero, checkEmpty) => {
  const validations = [R.isNil];
  if (checkZero) {
    validations.push(R.equals(0));
  }
  if (checkEmpty) {
    validations.push(R.isEmpty);
  }
  return R.pipe(R.path(dataPath), R.anyPass(validations));
};

/** TODO: Require enhancement in Dashboard Endpoint DE-568  */
const isErrorData = dataPath => R.pipe(R.path(dataPath), R.is(Error));

const widgetDataHOC = (
  dataPath = ['data'],
  checkZero = false,
  checkEmpty = true,
  loadingIndicator = true,
  CustomNoDataRenderer = NoData
) => WrappedComponent => props => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 10000);
  }, []);

  if (isNoData(dataPath, checkZero, checkEmpty)(props)) {
    return loadingIndicator && loading ? <LoadingData /> : <CustomNoDataRenderer />;
  } else if (isErrorData(dataPath)(props)) {
    return <ErrorData />;
  }
  return <WrappedComponent {...props} />;
};

export default widgetDataHOC;
