// @flow

import * as R from 'ramda';
import React, { useEffect } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { redux, router } from '@accordo-feed/micro-frontends';

import * as globalSelectors from 'src/redux/selectors';
import SecurityScoreComp from './SecurityScore.component';
import withDashboardData from 'src/hoc/withDashboardDataHOC';
import { clientIdSelector } from './drawer/drawer.selector';
import { dashboardSummarySelector, dashboardNeedPermissionSelector } from 'src/pages/dashboard/dashboard.selector';
import { getDashboardData } from 'src/pages/dashboard/dashboard.duck';
import { getSecurityControlProfileData } from './drawer/drawer.duck';
import { getSecurityPageData } from './securityScorePage.duck';
import { isOpenSelector as isFilterPanelOpenSelector } from './filterPanel/filterPanel.selector';
import { resetFilter } from 'src/redux/modules/filters/filters.duck';
import {
  secureScoreCtaDataSourceSelector,
  securityPageIsLoadingSelector,
  securityPageQuerySelector
} from './securityScorePage.selector';
import { setMenuCollapsedState } from 'src/components/appSider/menu/menu.duck';
import { setOpenState, resetFilters as resetFilterPanel } from './filterPanel/filterPanel.duck';
import { setSearchTerm } from 'src/redux/modules/searchTerm/searchTerm.duck';

/*****************
 *   COMPONENT   *
 *****************/

const SecurityScore = props => {
  const { actions, query, hasFeatureSecurityFilterPanel, isFilterPanelOpen } = props;

  useEffect(() => {
    if (hasFeatureSecurityFilterPanel && isFilterPanelOpen) {
      actions.setMenuCollapsedState(true);
    }

    actions.getSecurityPageData(query);

    return () => {
      actions.resetFilter('secureScore');
      actions.resetFilterPanel();
    };
  }, []);

  return <SecurityScoreComp {...props} />;
};

/*************
 *   REDUX   *
 *************/

const mapStateToProps = R.applySpec({
  clickedClientId: clientIdSelector,
  data: secureScoreCtaDataSourceSelector,
  hasFeatureSecurityFilterPanel: globalSelectors.hasFeatureSecurityFilterPanel,
  isFilterPanelOpen: isFilterPanelOpenSelector,
  isLoading: securityPageIsLoadingSelector,
  permissionCount: dashboardNeedPermissionSelector,
  query: securityPageQuerySelector,
  searchTerm: globalSelectors.searchTermSelector,
  summary: dashboardSummarySelector
});

const mapDispatchToProps = redux.bindActionCreatorsCurried({
  getDashboardData,
  getSecurityControlProfileData,
  getSecurityPageData,
  resetFilter,
  setFilterPanelOpen: setOpenState,
  setMenuCollapsedState,
  setSearchTerm,
  resetFilterPanel
});

export default compose(
  withDashboardData(),
  connect(mapStateToProps, mapDispatchToProps),
  router.withRouterHOC
)(SecurityScore);
