/* stylelint-disable property-no-vendor-prefix */
import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { Input as AntInput, Alert as AntAlert } from 'antd';
import { constants } from '@accordo-feed/aco-styled-components';
import { hexToRgb } from 'src/utils';

const { colors, fontSize } = constants;

const propsBasedColor = R.cond([
  [R.propEq('isError', true), R.always(colors.red)],
  [R.propEq('isFocus', true), R.always(colors.lightBlue)],
  [R.T, R.always('transparent')]
]);

export const Container = styled.div`
  margin-top: 30px;
`;

export const FieldLabel = styled.label`
  color: ${colors.lightGrey2};
  font-weight: 300;
  font-size: ${fontSize.small};
  letter-spacing: 0.24px;
  margin-bottom: 4px;
`;

export const OptionalLabel = styled.span`
  color: ${colors.darkGrey};
  font-size: ${fontSize.small};
  margin-left: 4px;
`;

export const Input = styled(AntInput)`
  font-size: ${fontSize.normal};
  height: 40px;
  padding: 11px;
  border-radius: 7px !important;
  border: 1px solid;

  && {
    color: ${colors.white} !important;
    border-color: ${propsBasedColor};
    background-color: rgba(${hexToRgb(colors.lightGrey7)}, 0.24) !important;
    &:focus {
      border-color: ${colors.lightBlue};
    }
    &:-webkit-autofill {
      border-color: ${propsBasedColor};
      -webkit-text-fill-color: ${colors.white};
      -webkit-box-shadow: 0 0 0 1000px transparent inset;
      transition: background-color 5000s ease-in-out 0s;
    }
  }
`;

export const ErrorLabel = styled.label`
  color: ${colors.red};
`;

const Alert = styled(AntAlert)`
  margin-top: 32px;
`;

export const AddressErrorMessage = ({ errors, message }: Props) =>
  errors.length > 0 ? <Alert type="error" showIcon message={message} description={errors.join(' ')} /> : null;
