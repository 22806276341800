import styled from 'styled-components';
import { mixins } from '@accordo-feed/aco-styled-components';

import { ErrorData } from 'src/components/widgetWrapper';

export const ErrorWrapper = styled.div`
  position: relative;
`;

export const ErrorBlurEffect = styled.div.attrs({
  className: 'aco-loading'
})``;

export const ErrorIcon = styled(ErrorData)`
  position: absolute;
  top: 0;
  left: 0;
  ${mixins.size('100%')}

  .ant-empty-image {
    height: 100px;
  }
`;
