import React from 'react';
import { Tooltip as AntTooltip } from 'antd';
import { LanguageEntry } from '@accordo-feed/language.entry';

import { Tooltip } from 'src/pages/page.styled';
import { sortByAlph, sortByNumber } from 'src/utils';
import * as MasterListStyled from 'src/pages/masterList/masterList.styled';
import { CUSTOMER_NAME_CLS } from './securityScore.styled';
import lang from './securityScore.lang';

const tableLang = lang.table;

/***************
 *   CONFIGS   *
 ***************/

const defaultWidth = {
  tiny: 40,
  small: 60,
  medium: 80,
  large: 100
};

export const TableColumns = [
  {
    title: <LanguageEntry {...tableLang.clientName} />,
    dataIndex: 'clientName',
    sorter: sortByAlph('clientName'),
    fixed: 'left',
    width: defaultWidth.large,
    className: CUSTOMER_NAME_CLS,
    render: clientName => <MasterListStyled.CustomerName>{clientName}</MasterListStyled.CustomerName>
  },
  {
    title: <LanguageEntry {...tableLang.currentScore} />,
    dataIndex: 'currentScore',
    width: defaultWidth.medium,
    sorter: sortByNumber('currentScore')
  },
  {
    title: <LanguageEntry {...tableLang.maxScore} />,
    dataIndex: 'maxScore',
    width: defaultWidth.medium,
    sorter: sortByNumber('maxScore')
  },
  {
    title: (
      <>
        <LanguageEntry {...tableLang.scoreIndex} />
        <Tooltip title={<LanguageEntry {...tableLang.tooltip1} />} />
      </>
    ),
    dataIndex: 'percentage',
    width: defaultWidth.medium,
    sorter: sortByNumber('percentage'),
    render: value => `${value}%`
  },
  {
    title: <LanguageEntry {...tableLang.numberOfImprovement} />,
    children: [
      {
        title: <LanguageEntry {...tableLang.children.notCompleted} />,
        dataIndex: ['improvementActionCount', 'notCompleted'],
        width: defaultWidth.small,
        sorter: sortByNumber('improvementActionCount', 'notCompleted')
      },
      {
        title: <LanguageEntry {...tableLang.children.completed} />,
        dataIndex: ['improvementActionCount', 'completed'],
        width: defaultWidth.small,
        sorter: sortByNumber('improvementActionCount', 'completed')
      },
      {
        title: <LanguageEntry {...tableLang.children.thirdParty} />,
        dataIndex: ['improvementActionCount', 'resolvedBy3rdParty'],
        width: defaultWidth.large,
        sorter: sortByNumber('improvementActionCount', 'resolvedBy3rdParty')
      },
      {
        title: <LanguageEntry {...tableLang.children.ignore} />,
        dataIndex: ['improvementActionCount', 'ignored'],
        width: defaultWidth.tiny,
        sorter: sortByNumber('improvementActionCount', 'ignored')
      }
    ]
  }
];

export const CustomRow = props => (
  <AntTooltip title={<LanguageEntry {...tableLang.tooltip2} />}>
    <tr {...props} />
  </AntTooltip>
);
