// @flow

import * as R from 'ramda';
import { connect } from 'react-redux';

import * as selectors from 'src/redux/selectors';
import { shellOrgSelector } from './office365Menu.selector';
import ShellMenu from './ShellMenu.component';

/*************
 *   REDUX   *
 *************/

const mapStateToProps = R.applySpec({
  userInitial: selectors.userNameInitialSelector,
  userInfo: selectors.userInfoSelector,
  hasAzureOptimizer: selectors.hasFeatureAzureOptimizer,
  hasTeamInvites: selectors.hasFeatureTeamInvites,
  hasNotifications: selectors.hasNotifications,
  isDistributor: selectors.hasFeatureDistributor,
  isPartnerMember: selectors.hasFeaturePartnerMember,
  subMenuOpenKeys: selectors.subMenuOpenKeysSelector,
  customers: selectors.customersDataSelector,
  org: shellOrgSelector
});

export default connect(mapStateToProps)(ShellMenu);
