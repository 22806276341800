import styled from 'styled-components';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Popover from '@material-ui/core/Popover';
import { constants, Icon } from '@accordo-feed/aco-styled-components';
import { appTextColor, bodyBackground, widgetBorderColor } from 'src/App.styled';

const { colors, fontSize } = constants;

export const VerticalIconWrapper = styled.div`
  cursor: pointer;
`;

export const VerticalIcon = styled(Icon).attrs({
  color: appTextColor,
  element: MoreVertIcon,
  hoverColor: colors.white
})``;

export const StyledPopover = styled(Popover)`
  .MuiPopover-paper {
    background-color: ${bodyBackground};
    border: 1px solid ${widgetBorderColor};
    .MuiListItem-root {
      color: ${appTextColor};
      font-size: ${fontSize.xSmall};
      line-height: 18px;
      &:hover {
        opacity: 0.7;
      }
    }
  }
`;
