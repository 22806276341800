// @flow

import * as R from 'ramda';

/*************
 *   TYPES   *
 *************/

declare var __DEPLOYMENT_ENV__: string;

/*********************
 *   CONFIG VALUES   *
 *********************/

/**
 * Any value in the config can be retrieved with `getConfigValue` at the bottom
 */
const devStripeTestKey =
  'pk_test_51HmoaoAZ422LZ6ZxSlwqwQkDgQFwjvro9mJ1oPtfbrHS8TBKHtraC3w58jE22FTomcO3ik0Zm3Kwlq41wg9CvBFV0041ZuyLPP';
const uatStripeTestKey =
  'pk_test_51HmoaoAZ422LZ6ZxSlwqwQkDgQFwjvro9mJ1oPtfbrHS8TBKHtraC3w58jE22FTomcO3ik0Zm3Kwlq41wg9CvBFV0041ZuyLPP';
const prodStripeTestKey =
  'pk_test_51HmoaoAZ422LZ6ZxSlwqwQkDgQFwjvro9mJ1oPtfbrHS8TBKHtraC3w58jE22FTomcO3ik0Zm3Kwlq41wg9CvBFV0041ZuyLPP';
const prodStripeLiveKey =
  'pk_live_51HmoaoAZ422LZ6ZxA60wOvZ1mxyvoMwx577MsfeX2pEyeA7ohckkRyjwFci3KsvZLHlg9Q6V35SFDCzHKI2Sr4bW00jDJ6K92e';

const defaultConfig: Object = {
  auth0: {
    responseType: 'token id_token',
    scope: 'openid',
    redirectUri: `${window.location.origin}/callback`
  }
};

const mergeWithDefaultConfig = R.mergeDeepRight(defaultConfig);

const localConfig = mergeWithDefaultConfig({
  auth0: {
    domain: 'dev-aco.auth0.com',
    clientID: 'Af0stN16VXzUzBrPTtNB5MsU3vYUhWE2'
  },
  stripeTestKey: devStripeTestKey,
  segmentWriteKey: '37LxeAVze2XSNSqFOy1jhK4CRmJC954u'
});

const devConfig = mergeWithDefaultConfig({
  auth0: {
    domain: 'dev-aco.auth0.com',
    clientID: 'Af0stN16VXzUzBrPTtNB5MsU3vYUhWE2'
  },
  stripeTestKey: devStripeTestKey,
  segmentWriteKey: '37LxeAVze2XSNSqFOy1jhK4CRmJC954u'
});

const uatConfig = mergeWithDefaultConfig({
  auth0: {
    domain: 'prod-aco.auth0.com',
    clientID: '1h6oN06O2FIjQYiQoxiBJ8jppJOSnAB0'
  },
  stripeTestKey: uatStripeTestKey,
  segmentWriteKey: 'PQMxC378n4rMyXz6kAv1rlnnGBf9i0G5'
});

const prodConfig = mergeWithDefaultConfig({
  auth0: {
    domain: 'login.app.entori.com',
    clientID: 'PU6omSwOwTf1L98L1Ld9MlUyhKPRslWi'
  },
  stripeTestKey: prodStripeTestKey,
  stripeLiveKey: prodStripeLiveKey,
  segmentWriteKey: 'pjtk0vS7HZh3w1iMun8ws5JspndlViOG'
});

/***************
 *   HELPERS   *
 ***************/

const configMap = {
  local: localConfig,
  dev: devConfig,
  uat: uatConfig,
  prod: prodConfig
};

const determinePath = R.split('.');
const envBasedConfig = R.pathOr('local', [__DEPLOYMENT_ENV__], configMap);
const lookupValueIn = R.flip(R.path);
const trimIfString = R.when(R.is(String), R.trim);

/**
 * Supply this function with string like `key.nestedKey.anotherNesterKey` to retrieve the value from the config above
 * e.g.
 * getConfigValue('auth0.domain') === 'dev-aco.auth0.com'; // on local and dev environments
 */
export const getConfigValue: string => Object | string | number = R.pipe(
  determinePath,
  lookupValueIn(envBasedConfig),
  trimIfString
);
