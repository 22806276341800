import * as R from 'ramda';

import { API_ERROR_ACTION_TYPES } from 'src/constants';
import { isAuthError } from 'src/utils/api';

/**
 * isModalError
 * @param {string} type
 */
export const isModalError = R.anyPass([
  R.equals(API_ERROR_ACTION_TYPES.LOG_OUT),
  R.equals(API_ERROR_ACTION_TYPES.SHOW_ERROR_MESSAGE)
]);

/**
 * getErrorType by using status code
 * @param {integer|string} statusCode passed from API error
 */
export const getErrorType = (statusCode = 0) =>
  R.cond([
    [isAuthError, R.always(API_ERROR_ACTION_TYPES.LOG_OUT)],
    [R.lte(500), R.always(API_ERROR_ACTION_TYPES.SHOW_ERROR_MESSAGE)],
    [R.T, R.always(API_ERROR_ACTION_TYPES.SHOW_TOASTER)]
  ])(parseInt(statusCode, 10));
