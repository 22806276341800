// @flow

import React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { useTranslate } from '@accordo-feed/language.entry';

import * as selectors from 'src/redux/selectors';
import Widget from 'src/components/widget';
import ViewMore from 'src/components/viewMore';
import { PATHS } from 'src/constants';
import AllocationComponent from './Allocation.component';
import lang from './allocation.lang';

/*****************
 *   COMPONENT   *
 *****************/

const AllocationContainer = props => {
  const translate = useTranslate();
  const { data, clients } = props;
  const dataSource = data.map(item => {
    const client = R.find(R.propEq('id', item.id), clients);
    const microsoft = client?.microsoft || {};
    return { ...item, microsoft };
  });
  return (
    <Widget
      title={translate(lang.widgetTitle)}
      subTitle={translate(lang.widgetSubTitle)}
      footerContent={<ViewMore path={PATHS.ALLOCATION_ADOPTION_DASHBOARD} />}
    >
      <AllocationComponent dataSource={dataSource} {...props} />
    </Widget>
  );
};

/*************
 *   REDUX   *
 *************/

const mapStateToProps = state => ({
  data: selectors.dashboardMasterListWidgetSelector(state),
  clients: selectors.customersDataSelector(state)
});

export default connect(mapStateToProps)(AllocationContainer);
