import styled from 'styled-components';
import { Switch } from 'antd';
import { constants } from '@accordo-feed/aco-styled-components';

import { tableRowBorderColor, appTextColor } from 'src/App.styled';

const { fontSize } = constants;

export const ToggleThemeWrapper = styled.div`
  display: inline-block;
  text-align: center;
`;

export const ToggleSwitch = styled(Switch)`
  &&&.ant-switch {
    margin-top: 14px;
    background: ${tableRowBorderColor};
    margin-right: 10px;
    &:focus {
      box-shadow: none;
    }
    &-checked {
      &:focus {
        box-shadow: none;
      }
    }
  }
`;

export const ToggleLabel = styled.p`
  color: ${appTextColor};
  font-size: ${fontSize.xxSmall};
  margin-top: 2px;
  text-transform: capitalize;
`;
