import { langHelper } from '@accordo-feed/micro-frontends-utils';

const genId = langHelper.genLangId('shell.aco.globalAlert');
const genOpts = langHelper.genLangOptions(genId);

export default {
  alertMessagePart1: genOpts(
    'alertMessagePart1',
    "Some of your customers don't have the latest data. <strong>Enable refresh</strong> for each customer in "
  ),
  alertCta: genOpts('alertCta', 'Customer Management'),
  alertMessagePart2: genOpts('alertMessagePart2', '.')
};
