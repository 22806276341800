// @flow

import * as R from 'ramda';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { getDistiBillingData } from 'src/redux/modules/distiBilling/distiBilling.duck';
import * as selectors from 'src/redux/selectors';
import {
  addPsaBillingAlertEmail,
  removePsaBillingAlertEmail,
  setPsaBillingAlertStatus
} from 'src/redux/modules/organization/organization.duck';
import { postCustomerLogo, setProfileUpdated, updateProfile } from './profile.duck';
import * as profileSelectors from './profile.selector';
import ProfileComponent from './Profile.component';

/*************
 *   REDUX   *
 *************/

const mapStateToProps = R.applySpec({
  countryCode: profileSelectors.countryCodeSelector,
  email: selectors.userEmailSelector,
  isProfileUpdated: profileSelectors.isProfileUpdatedSelector,
  isProfileUpdating: profileSelectors.isProfileUpdatingSelector,
  profile: profileSelectors.dataSelector,
  hasDistributorIngramMicro: selectors.hasFeatureDistributorIngramMicro
});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      addPsaBillingAlertEmail,
      removePsaBillingAlertEmail,
      setPsaBillingAlertStatus,
      setProfileUpdated,
      updateProfile,
      getDistiBillingData,
      postCustomerLogo
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileComponent);
