import styled from 'styled-components';
import { constants, mixins, Layout } from '@accordo-feed/aco-styled-components';

import { FullLogo } from 'src/components/logo';
import { setProperty } from 'src/utils';
import { subHeaderTextColor } from 'src/App.styled';

const { TwoColumns } = Layout;
const { colors } = constants;

export const Container = styled(TwoColumns.Container)`
  height: 100vh;
  max-width: none;
  padding-top: 0;
  overflow: hidden;
`;

export const MainColumn = styled(TwoColumns.Main)`
  && {
    margin-right: 0;
    position: relative;
  }

  justify-content: center;
  padding: 110px 36px;
  height: 100%;
  overflow-y: auto;
  background: linear-gradient(150.5deg, ${colors.deepDarkBlue1} 2.42%, ${colors.deepDarkBlue} 105.93%);
`;

export const Aside = styled(TwoColumns.Aside)`
  && {
    width: 560px;
    background: none;
    background-color: ${colors.white};
    color: ${colors.darkGrey4};
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  ${setProperty('width')}
  ${setProperty('padding')}
  ${setProperty('margin')}
  ${setProperty('align-items')}
  ${setProperty('justify-content')}
`;

export const Row = styled.div`
  display: flex;
  ${setProperty('width')}
  ${setProperty('padding')}
  ${setProperty('margin')}
  ${setProperty('align-items')}
  ${setProperty('justify-content')}
`;

const titleSizeMap = {
  l: '34px',
  m: '24px',
  s: '14px'
};

export const Title = styled.div`
  letter-spacing: 0.24px;
  font-size: ${({ size }) => titleSizeMap[size] || titleSizeMap['m']};
  color: ${({ color }) => color || subHeaderTextColor};
`;

export const Logo = styled(FullLogo).attrs({
  size: 'large'
})`
  ${mixins.size('200px !important', 'auto')};
  position: absolute;
  left: 50px;
  top: 45px;
`;
