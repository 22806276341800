import styled from 'styled-components';
import { Table } from 'antd';
import { mixins, constants } from '@accordo-feed/aco-styled-components';

import { SectionWrapper } from 'src/pages/page.styled';

const { colors, fontSize } = constants;

export const { Column } = Table;

export const BaseTable = styled(Table)`
  && {
    .ant-table-content {
      font-size: ${fontSize.xSmall};
    }
    .ant-table-thead {
      th {
        border-bottom-width: 2px;
        background-color: white;
        font-weight: 100;
        &:last-of-type {
          text-align: right;
        }
      }
    }
    .ant-table-tbody > tr > td:last-of-type {
      text-align: right;
    }
  }
`;

export const UsageFirstColContainer = styled.section`
  ${mixins.flexCenter()};
  flex-direction: column;
  height: 100%;
  padding: 0 16px;
`;

export const UsageColTitle = styled.div`
  margin-bottom: 8px;
  font-size: ${fontSize.medium};
  font-weight: 100;
`;

export const UsageFirstColNum = styled.div`
  color: ${colors.shadeGreen};
  font-size: ${fontSize.xxLarge};
  padding: 16px;
`;

export const UsageThirdColContent = styled.p`
  font-size: ${fontSize.xSmall};
`;

export const Content = styled.div`
  padding: 0 15px;
`;

export const CurrentSubscriptionWrapper = styled(SectionWrapper)`
  padding: 0;
`;
