// @flow

import React from 'react';
import { connect } from 'react-redux';
import { useTranslate } from '@accordo-feed/language.entry';

import * as selectors from 'src/redux/selectors';
import Widget from 'src/components/widget';
import ViewMore from 'src/components/viewMore';
import { PATHS } from 'src/constants';
import AdoptionComponent from './Adoption.component';
import lang from './adoption.lang';

/*****************
 *   COMPONENT   *
 *****************/

const AdoptionContainer = props => {
  const translate = useTranslate();
  return (
    <Widget
      title={translate(lang.widgetTitle)}
      subTitle={translate(lang.widgetSubTitle)}
      footerContent={<ViewMore path={PATHS.ALLOCATION_ADOPTION_DASHBOARD} />}
    >
      <AdoptionComponent {...props} />
    </Widget>
  );
};

/*************
 *   REDUX   *
 *************/

const mapStateToProps = state => ({
  dataSource: selectors.dashboardMasterListWidgetSelector(state)
});

export default connect(mapStateToProps)(AdoptionContainer);
