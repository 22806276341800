// @flow

import React from 'react';
import { Col, Row } from 'antd';
import { useTranslate } from '@accordo-feed/language.entry';
import Widget from 'src/components/widget';
import { subscriptionUsage as lang } from './subscriptions.lang';
import { lang as footerLang } from 'src/components/appFooter/appFooter.lang';
import * as Styled from './subscriptions.styled';

/*************
 *   TYPES   *
 *************/

type Tier = {
  upTo: number | null,
  pricePerUser: number,
  usage: number
};

type Props = {
  totalUsage: number,
  tiers: Array<Tier>
};

/*****************
 *   COMPONENT   *
 *****************/

export const SubscriptionUsage = ({ totalUsage, tiers }: Props) => {
  const translate = useTranslate();
  const isPAB = tiers[0].upTo === 1000;

  return (
    <Row gutter={[20, 20]} type="flex" style={{ marginBottom: 10 }}>
      <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
        <Widget title={translate(lang.countHeader)}>
          <Styled.UsageFirstColContainer>
            <Styled.UsageFirstColNum>{totalUsage}</Styled.UsageFirstColNum>
          </Styled.UsageFirstColContainer>
        </Widget>
      </Col>

      <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
        <Widget title={translate(lang.mauDefined.heading)}>
          <Styled.Content>
            <Styled.UsageThirdColContent>{translate(lang.mauDefined.definition1)}</Styled.UsageThirdColContent>
            {isPAB ? (
              <Styled.UsageThirdColContent>{translate(lang.mauDefined.definition2PAB)}</Styled.UsageThirdColContent>
            ) : (
              <Styled.UsageThirdColContent>{translate(lang.mauDefined.definition2)}</Styled.UsageThirdColContent>
            )}
            <Styled.UsageThirdColContent>
              {translate(lang.mauDefined.definition3FirstPart)}{' '}
              <a href={translate(footerLang.termsLinkUrl)}>{translate(lang.mauDefined.definition3Terms)}</a>{' '}
              {translate(lang.mauDefined.definition3SecondPart)} <a href="mailto:sales@entori.com">sales@entori.com</a>
              {'.'}
            </Styled.UsageThirdColContent>
          </Styled.Content>
        </Widget>
      </Col>
    </Row>
  );
};
