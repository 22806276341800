import * as R from 'ramda';
import { toggleFeature } from '@accordo-feed/micro-frontends-utils';

import { getModuleSelector } from 'src/redux/utils';
import { organizationDistributorSelector } from './organization';
import { userDataSelector } from './userData';

export const APP_FEATURES = {
  AZURE_OPTIMIZER: 'AzureOptimizer',
  SECURITY_CTA_FILTER_PANEL: 'SecurityCTAFilterPanel',
  TEAM_INVITES: 'TeamInvites',
  DISTRIBUTOR: 'Distributor',
  PARTNER_MEMBER: 'PartnerMember',
  SECURE_APP: 'SecureApp',
  DISTRIBUTOR_INGRAM_MICRO: 'DistributorIngramMicro',
  NOTIFICATIONS: 'Notifications',
  DEMO: 'Demo'
};

/***************
 *   HELPERS   *
 ***************/

const featureRulesSelector = getModuleSelector('featureRules');

/**
 * defaultFeatureMapStateToProps
 * @param {Redux State} state
 * @return {Object} feature toggle state contains, sessionDetails & rules
 */
export const defaultFeatureMapStateToProps = state => {
  const rules = featureRulesSelector(state);
  const userData = userDataSelector(state);
  const { organizations: userOrg, authorization } = userData;
  const distributorId = R.prop('id', organizationDistributorSelector(state));
  const organizations = distributorId ? R.assocPath([0, 'distributor'], distributorId, userOrg) : userOrg;

  return {
    sessionDetails: {
      organizations,
      authorization
    },
    rules
  };
};

const cloudOptimizerHasFeature = R.curry((feature, state) =>
  toggleFeature.hasFeature(feature, state, defaultFeatureMapStateToProps)
);

/*****************
 *   SELECTORS   *
 *****************/

export const hasFeatureAzureOptimizer = cloudOptimizerHasFeature(APP_FEATURES.AZURE_OPTIMIZER);

export const hasFeatureSecurityFilterPanel = cloudOptimizerHasFeature(APP_FEATURES.SECURITY_CTA_FILTER_PANEL);

export const hasFeatureTeamInvites = cloudOptimizerHasFeature(APP_FEATURES.TEAM_INVITES);

export const hasFeatureDistributor = cloudOptimizerHasFeature(APP_FEATURES.DISTRIBUTOR);

export const hasFeaturePartnerMember = cloudOptimizerHasFeature(APP_FEATURES.PARTNER_MEMBER);

export const hasFeatureSecureApp = cloudOptimizerHasFeature(APP_FEATURES.SECURE_APP);

export const hasFeatureDistributorIngramMicro = cloudOptimizerHasFeature(APP_FEATURES.DISTRIBUTOR_INGRAM_MICRO);

export const hasNotifications = cloudOptimizerHasFeature(APP_FEATURES.NOTIFICATIONS);

export const hasFeatureDemo = cloudOptimizerHasFeature(APP_FEATURES.DEMO);
