export const WIDGET = {
  // top widget
  SUMMARY: 'summary',
  OVERVIEW: 'overview',

  // grid widgets
  RECOMMENDATIONS: 'recommendations',
  OPPORTUNITIES: 'opportunities',
  SECURITY_SCORES: 'securityScores',
  M365_LICENSES: 'm365Licenses',
  UNASSIGNED_LICENSES: 'unassignedLicenses',
  ALERTS: 'alerts',
  BILLING_DISCREPANCY: 'billingDiscrepancy',
  MASTER_LIST: 'masterList',
  ALLOCATION: 'allocation',
  ADOPTION: 'adoption',
  GROWTH_TREND: 'customerGrowth'
};

export const DIRECTION = { POSITIVE: 'positive', NEGATIVE: 'negative' };
export const CATEGORIES = {
  ACTIVE_USERS: 'activeUsers',
  TOTAL_LICENSES: 'totalLicenses',
  MONTHLY_SPEND: 'monthlySpend'
};
