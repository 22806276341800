// @flow

import React from 'react';
import { useTranslate } from '@accordo-feed/language.entry';

import * as Styled from './widgetWrapper.styled';
import lang from './widgetWrapper.lang';

/*****************
 *   COMPONENT   *
 *****************/

const NoData = () => {
  const translate = useTranslate();
  return (
    <Styled.Wrapper>
      <Styled.WarningIcon />
      <span>{translate(lang.noDataConnection)}</span>
    </Styled.Wrapper>
  );
};

export default NoData;
