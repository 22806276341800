import { langHelper } from '@accordo-feed/micro-frontends-utils';

const genProfileId = langHelper.genLangId('shell.aco.profile');

const genProfileLang = langHelper.genLangOptions(genProfileId);

export default {
  enableMfaMsg: {
    title: genProfileLang('setUpMfaMsg.title', 'Set up Multi-Factor Authentication'),
    content: genProfileLang(
      'setUpMfaMsg.content',
      'You will be redirected to the Sign In page to set up Multi-Factor Authentication. Please have your preferred moblie device ready.'
    )
  },
  disableMfaMsg: {
    title: genProfileLang('disableMfaMsg.title', 'Disable Multi-Factor Authentication'),
    content: genProfileLang(
      'disableMfaMsg.content',
      'In order to disable Multi-Factor Authentication you will be logged out of your current session.'
    )
  },
  mfaModelButtons: {
    confirm: genProfileLang('mfaModelButtons.confirm', 'Continue to Sign in'),
    cancel: genProfileLang('mfaModelButtons.cancel', 'Cancel')
  }
};
