import React from 'react';
import { langHelper } from '@accordo-feed/micro-frontends-utils';
import { LanguageEntry } from '@accordo-feed/language.entry';

const genId = langHelper.genLangId('shell.aco.securityScore.page');
const genOpts = langHelper.genLangOptions(genId);

export default {
  pageTitle: genOpts('title', 'Security Score'),
  introduction: (
    <LanguageEntry
      id="shell.aco.securityScore.page.introduction"
      defaultMessage="<h2>Narrative to help with Widget’s purpose and how
    to act on it.</h2><p>Lorem ipsum dolor sit amet, consectetur adipiscing
    elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet
    lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor.</p>"
    />
  ),
  table: {
    clientName: genOpts('table.th1', 'Customer'),
    currentScore: genOpts('table.th2', 'Current Secure Score'),
    maxScore: genOpts('table.th3', 'Max Secure Score'),
    scoreIndex: genOpts('table.th4', 'Secure Score Index'),
    tooltip1: genOpts('table.tooltip1', 'Secure Score Index'),
    tooltip2: genOpts('table.tooltip2', 'Secure Score Details'),
    numberOfImprovement: genOpts('table.th5', 'Number of Improvement Actions by Status'),
    children: {
      notCompleted: genOpts('table.th4.th1', 'Not Completed'),
      completed: genOpts('table.th4.th2', 'Completed'),
      thirdParty: genOpts('table.th4.th3', 'Resolved through third-party'),
      ignore: genOpts('table.th4.th4', 'Ignored')
    }
  }
};
