import { langHelper } from '@accordo-feed/micro-frontends-utils';

const genPartnerCenterIotId = langHelper.genLangId('shell.aco.partnerCenterIot');
const genPartnerCenterIotLang = langHelper.genLangOptions(genPartnerCenterIotId);

export default {
  loginWindow: {
    connectSuccessTitle: genPartnerCenterIotLang('loginWindow.connectSuccessTitle', 'SUCCESS!'),
    connectSuccessMessage: genPartnerCenterIotLang(
      'loginWindow.connectSuccessMessage',
      'Your Microsoft Partner Center has been connected successfully.'
    )
  }
};
