// @flow

import * as React from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Row, Col, Switch } from 'antd';
import { useTranslate } from '@accordo-feed/language.entry';

import { Section } from 'src/components/section';
import lang from './notificationsSettings.lang';
import * as Styled from './notificationsSettings.styled';

const { title, desc, content } = lang;
const { heading1, desc1, heading2, desc2 } = content;

/*****************
 *   COMPONENT   *
 *****************/

export default () => {
  const translate = useTranslate();
  return (
    <Section title={translate(title)}>
      <Row gutter={[48, 8]}>
        <Col span={24} xl={12}>
          <Styled.Description>{translate(desc)}</Styled.Description>
          <Styled.TitleWrapper>
            <Styled.ContentTitle>{translate(heading1)}</Styled.ContentTitle>
            <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} defaultChecked />
          </Styled.TitleWrapper>
          <Styled.Description>{translate(desc1)}</Styled.Description>
          <Styled.TitleWrapper>
            <Styled.ContentTitle>{translate(heading2)}</Styled.ContentTitle>
            <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} defaultChecked />
          </Styled.TitleWrapper>
          <Styled.Description>{translate(desc2)}</Styled.Description>
        </Col>
      </Row>
    </Section>
  );
};
