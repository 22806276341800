import React from 'react';
import { LanguageEntry } from '@accordo-feed/language.entry';

import StyledLink from 'src/components/link';
import { sortByAlph, sortByNumber } from 'src/utils';
import { PATHS } from 'src/constants';
import lang from './subscriptions.lang';

const tableLang = lang.table;

/***************
 *   CONFIGS   *
 ***************/

export const generateTableColumns = props => {
  const { enableSort } = props;

  return [
    {
      title: <LanguageEntry {...tableLang.main.overview} />,
      children: [
        {
          title: <LanguageEntry {...tableLang.overview.company} />,
          dataIndex: 'name',
          key: 'company',
          width: 240,
          fixed: 'left',
          sorter: enableSort && sortByAlph('name'),
          render: (name, { id }) => (
            <StyledLink
              to={{
                pathname: `${PATHS.CUSTOMERS.ROOT}/${id}/`,
                state: { prevPath: PATHS.DASHBOARD.ROOT }
              }}
            >
              {name}
            </StyledLink>
          )
        },
        {
          title: <LanguageEntry {...tableLang.overview.subs} />,
          dataIndex: ['subscriptions', 'overview', 'subs'],
          key: 'overviewSubs',
          sorter: enableSort && sortByNumber('subscriptions', 'overview', 'subs')
        },
        {
          title: <LanguageEntry {...tableLang.overview.seats} />,
          dataIndex: ['subscriptions', 'overview', 'seats'],
          key: 'overviewSeats',
          sorter: enableSort && sortByNumber('subscriptions', 'overview', 'seats')
        }
      ]
    },
    {
      title: <LanguageEntry {...tableLang.main.annual} />,
      children: [
        {
          title: <LanguageEntry {...tableLang.annual.subs} />,
          dataIndex: ['subscriptions', 'annual', 'subs'],
          key: 'annualSubs',
          sorter: enableSort && sortByNumber('subscriptions', 'annual', 'subs')
        },
        {
          title: <LanguageEntry {...tableLang.annual.seats} />,
          dataIndex: ['subscriptions', 'annual', 'seats'],
          key: 'annualSeats',
          sorter: enableSort && sortByNumber('subscriptions', 'annual', 'seats')
        },
        {
          title: <LanguageEntry {...tableLang.annual.addons} />,
          dataIndex: ['subscriptions', 'annual', 'addons'],
          key: 'annualAddons',
          sorter: enableSort && sortByNumber('subscriptions', 'annual', 'addons')
        },
        {
          title: <LanguageEntry {...tableLang.annual.spend} />,
          dataIndex: ['subscriptions', 'annual', 'spend'],
          key: 'annualSpend',
          sorter: enableSort && sortByNumber('subscriptions', 'annual', 'spend')
        },
        {
          title: <LanguageEntry {...tableLang.annual.expiring} />,
          dataIndex: ['subscriptions', 'annual', 'expiring'],
          key: 'annualExpiring',
          sorter: enableSort && sortByNumber('subscriptions', 'annual', 'expiring')
        },
        {
          title: <LanguageEntry {...tableLang.annual.renewal} />,
          dataIndex: ['subscriptions', 'annual', 'renewal'],
          key: 'annualRenewal',
          sorter: enableSort && sortByNumber('subscriptions', 'annual', 'renewal')
        },
        {
          title: <LanguageEntry {...tableLang.annual.atRisk} />,
          dataIndex: ['subscriptions', 'annual', 'atrisk'],
          key: 'annualAtRisk',
          sorter: enableSort && sortByNumber('subscriptions', 'annual', 'atrisk')
        }
      ]
    },
    {
      title: <LanguageEntry {...tableLang.main.monthly} />,
      children: [
        {
          title: <LanguageEntry {...tableLang.monthly.subs} />,
          dataIndex: ['subscriptions', 'monthly', 'subs'],
          key: 'monthlySubs',
          sorter: enableSort && sortByNumber('subscriptions', 'monthly', 'subs')
        },
        {
          title: <LanguageEntry {...tableLang.monthly.seats} />,
          dataIndex: ['subscriptions', 'monthly', 'seats'],
          key: 'monthlySeats',
          sorter: enableSort && sortByNumber('subscriptions', 'monthly', 'seats')
        },
        {
          title: <LanguageEntry {...tableLang.monthly.addons} />,
          dataIndex: ['subscriptions', 'monthly', 'addons'],
          key: 'monthlyAddons',
          sorter: enableSort && sortByNumber('subscriptions', 'monthly', 'addons')
        },
        {
          title: <LanguageEntry {...tableLang.monthly.spend} />,
          dataIndex: ['subscriptions', 'monthly', 'spend'],
          key: 'monthlySpend',
          sorter: enableSort && sortByNumber('subscriptions', 'monthly', 'spend')
        },
        {
          title: <LanguageEntry {...tableLang.monthly.expiring} />,
          dataIndex: ['subscriptions', 'monthly', 'expiring'],
          key: 'monthlyExpiring',
          sorter: enableSort && sortByNumber('subscriptions', 'monthly', 'expiring')
        },
        {
          title: <LanguageEntry {...tableLang.monthly.renewal} />,
          dataIndex: ['subscriptions', 'monthly', 'renewal'],
          key: 'monthlyRenewal',
          sorter: enableSort && sortByNumber('subscriptions', 'monthly', 'renewal')
        },
        {
          title: <LanguageEntry {...tableLang.monthly.atRisk} />,
          dataIndex: ['subscriptions', 'monthly', 'atrisk'],
          key: 'monthlyAtRisk',
          sorter: enableSort && sortByNumber('subscriptions', 'monthly', 'atrisk')
        }
      ]
    }
  ];
};
