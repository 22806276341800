import React from 'react';
import { Formik } from 'formik';
import { useTranslate } from '@accordo-feed/language.entry';

import { AddressForm } from 'src/components/addressForm/AddressForm.component';
import { addressSchema } from './AddressFormWrapper.config';
import { createOnSubmit } from './AddressFormWrapper.helpers';

export const AddressFormWrapper = ({
  isInitialValid = true,
  errors,
  billingAddress,
  registeredCountryCode,
  onSubmit,
  renderWithAddressForm
}) => {
  const translate = useTranslate();
  const { line1, line2, city, region, postalCode, country } = billingAddress;

  const countryValue = (country || registeredCountryCode).toLowerCase();

  return (
    <Formik
      isInitialValid={isInitialValid}
      enableReinitialize
      validationSchema={addressSchema(translate)}
      initialValues={{
        line1,
        line2,
        city,
        region,
        country: countryValue,
        postalCode
      }}
      onSubmit={createOnSubmit(onSubmit, translate)}
      children={({ isValid, values, setFieldValue }) =>
        renderWithAddressForm({
          isValid,
          addressElement: (
            <AddressForm
              errors={errors}
              region={values.region}
              country={values.country}
              setFieldValue={setFieldValue}
            />
          )
        })
      }
    />
  );
};
