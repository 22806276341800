import { langHelper } from '@accordo-feed/micro-frontends-utils';

const genId = langHelper.genLangId('shell.aco.dashboard.widget.billingDiscrepancy');
const genOpts = langHelper.genLangOptions(genId);

export default {
  widgetTitle: genOpts('title', 'PSA Billing Discrepancies'),
  noConnection: genOpts('noConnection', 'Customers without connections'),
  uncoverBillingIssues: genOpts('uncoverBillingIssues', 'Uncover Billing Issues'),
  totalDiscrepancies: genOpts('totalDiscrepancies', 'Total billing discrepancies'),
  psaIntegrations: genOpts('psaIntegrations', 'PSA Integrations'),
  discrepanciesReport: genOpts('discrepanciesReport', 'Billing Discrepancies Report'),
  noDiscrepancies: genOpts('noDiscrepancies', 'No billing discrepancies detected.'),
  deficit: genOpts('deficit', 'Deficit'),
  surplus: genOpts('surplus', 'Surplus'),
  tooltipMessage: genOpts(
    'tooltipMessage',
    'An indicative report that provides details on where you losing or overcharging your customers. Its based on  your Mapped customers in PSA Integration'
  )
};
