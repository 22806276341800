// @flow

import React from 'react';

import * as Styled from './pageHeader.styled';

/*************
 *   TYPES   *
 *************/

type PageHeaderProps = {
  title: string
};

/*****************
 *   COMPONENT   *
 *****************/

const PageHeader = ({ title = '' }: PageHeaderProps) => (
  <Styled.Wrapper>
    <Styled.Container>
      <Styled.Title>{title}</Styled.Title>
    </Styled.Container>
  </Styled.Wrapper>
);

export default PageHeader;
