// @flow

import { core } from '@accordo-feed/micro-frontends-utils';

import type { PaymentMethodResponse, PromoCodeResponse } from './payment.duck.js';
import { API } from 'src/utils/api';
import { resolveApiEndpoint, requestHandler } from 'src/utils';

/***************
 *   HELPERS   *
 ***************/

const oldPaymentMethodUpdateApi = resolveApiEndpoint(API.OLD_PUT_PAYMENT_METHOD);
const paymentMethodUpdateApi = resolveApiEndpoint(API.PUT_PAYMENT_METHOD);
const billingEmailUpdateApi = resolveApiEndpoint(API.PUT_BILLING_EMAIL);
const billingAddressUpdateApi = resolveApiEndpoint(API.PUT_BILLING_ADDRESS);
const promoCodeApi = resolveApiEndpoint(API.PROMO_CODE);
const getPlanUsageApi = resolveApiEndpoint(API.GET_SUBSCRIPTION_USAGE);
const cancelSubscriptionApi = resolveApiEndpoint(API.CANCEL_SUBSCRIPTION);

/**
 * handleError - opt requestHandler to handle error
 * This will prevent error being handle by default error handler
 * @param {Error} error
 */
const handleError = error => {
  throw error;
};

/*****************
 *   API CALLS   *
 *****************/

export const oldPutPaymentMethod = ({
  orgId,
  data
}: {
  orgId: string,
  data: { cardToken: string, planIds: [string], lastFourDigitsCc: string }
}): Promise<PaymentMethodResponse> =>
  requestHandler({
    method: 'PUT',
    url: core.replaceAll(oldPaymentMethodUpdateApi, { orgId }),
    data,
    handleError
  });

export const putPaymentMethod = ({
  orgId,
  data
}: {
  orgId: string,
  data: { cardToken: string, planIds: [string] }
}): Promise<PaymentMethodResponse> =>
  requestHandler({
    method: 'PUT',
    url: core.replaceAll(paymentMethodUpdateApi, { orgId }),
    data,
    handleError
  });

export const putBillingEmail = ({ orgId, data }: { orgId: string, data: { email: string } }): Promise<any> =>
  requestHandler({
    method: 'PUT',
    url: core.replaceAll(billingEmailUpdateApi, { orgId }),
    data,
    handleError
  });

export const submitPromoCode = ({
  orgId,
  data,
  handleError
}: {
  orgId: string,
  data: { promotionCode: string },
  handleError: Function
}): Promise<PromoCodeResponse> =>
  requestHandler({
    method: 'PUT',
    url: core.replaceAll(promoCodeApi, { orgId }),
    data,
    handleError
  });

export const getSubscriptionUsage = ({ orgId }: { orgId: string }): Promise<any> =>
  requestHandler({
    method: 'GET',
    url: core.replaceAll(getPlanUsageApi, { orgId })
  });

export const cancelSubscription = ({ orgId, planIds }: { orgId: string, planIds: string[] }): Promise<any> =>
  requestHandler({
    method: 'POST',
    url: core.replaceAll(cancelSubscriptionApi, { orgId }),
    data: { planIds }
  });

export const updateBillingAddress = ({
  orgId,
  data
}: {
  orgId: string,
  data: {
    line1: string,
    line2?: string,
    city: string,
    region: string,
    country: string,
    postalCode: string
  }
}): Promise<PromoCodeResponse> =>
  requestHandler({
    method: 'PUT',
    url: core.replaceAll(billingAddressUpdateApi, { orgId }),
    data,
    handleError
  });
