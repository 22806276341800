import * as yup from 'yup';

// Helper to validate the numbers that can be empty string
// https://github.com/jquense/yup/issues/298#issuecomment-508346424
export const emptyNumber = typeErrorMessage =>
  yup
    .number()
    .transform((value, originalValue) => {
      if (yup.number().isType(value)) return value;
      if (!originalValue || !originalValue.trim()) {
        return null;
      }

      return originalValue;
    })
    .nullable(true)
    .typeError(typeErrorMessage);
