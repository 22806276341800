import ReactEcharts from 'echarts-for-react';
import styled from 'styled-components';
import { Dropdown as AntDropdown } from 'antd';
import { constants } from '@accordo-feed/aco-styled-components';
import { appTextColor, paginationItemTextColor } from 'src/App.styled';
import { WidgetCardWrapper } from 'src/components/widget/widget.styled';

const { colors, fontSize } = constants;

export const Dropdown = styled(AntDropdown)`
  color: ${colors.black};
  font-size: ${fontSize.xSmall};
`;

export const WidgetWrapper = styled(WidgetCardWrapper)`
  display: flex;
  flex-direction: column;
  padding: 20px 0 10px 10px;
`;

export const TableWrapper = styled(WidgetCardWrapper)`
  overflow: hidden;
  margin-top: 20px;
`;

export const DropdownWidgetWrapper = styled.div`
  width: 260px;
  display: flex;
  justify-content: space-between;
`;

export const ChartWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 200px;
  margin-bottom: -40px;
`;

export const TabContainer = styled.div`
  padding: 0 20px 10px;
  height: 100%;

  .ant-tabs {
    .ant-tabs-tab {
      padding: 5px 0;
      margin: 0 15px 0 0;
      color: ${paginationItemTextColor};
    }
  }
`;

export const Title = styled.div`
  margin-bottom: -1.5px;
  font-size: 14px;
`;

export const DropWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${appTextColor};
  .anticon {
    padding-left: 3px;
  }
`;

export const LegendsWrapper = styled.div`
  margin: 0 0 20px;
`;

export const LegendLine = styled.div`
  border-left: 3px solid ${props => props.color};
  display: flex;
  padding: 0;
  font-size: ${fontSize.xSmall};

  div {
    color: ${appTextColor};
    margin-left: 5px;
    max-width: 100px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  span {
    color: ${props => (props.isPositive ? colors.shadeGreen : colors.red)};
    margin-left: 10px;
  }
`;

export const Chart = styled(ReactEcharts).attrs({
  style: {
    height: 240,
    minWidth: 270,
    maxWidth: '100%'
  }
})``;

const shortenChartWidth = 500;

export const DropdownWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ShortExtendedChart = styled(ReactEcharts)`
  min-width: 300px;
  max-width: ${shortenChartWidth}px;
  width: 100%;
`;

export const ShortExtendedDropdownWrapper = styled(DropdownWrapper)`
  width: ${shortenChartWidth}px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ActualLine = styled.div`
  width: 30px;
  border-bottom: 1px solid ${appTextColor};
  margin-bottom: 5px;
`;

export const ForecastLine = styled(ActualLine)`
  border-bottom-style: dashed;
  margin-bottom: 5px;
`;

export const LineWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: ${fontSize.xSmall};
  color: ${appTextColor};

  div,
  span {
    margin-left: 10px;
  }
`;
