import styled from 'styled-components';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { constants } from '@accordo-feed/aco-styled-components';

const { colors, fontSize } = constants;

export const GrowthIndicator = styled.span`
  margin-left: 22px;

  && {
    font-size: ${fontSize.small};
  }

  .anticon {
    margin-right: 6px;
  }
`;

export const GrowthUpIcon = styled(CaretUpOutlined)`
  color: ${colors.shadeGreen};
`;

export const GrowthDownIcon = styled(CaretDownOutlined)`
  color: ${colors.red};
`;
