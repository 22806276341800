import styled from 'styled-components';
import { mixins, constants, TooltipIcon } from '@accordo-feed/aco-styled-components';
import { bodyBackground, sidebarBackground, appTextColor } from 'src/App.styled';

const { colors, fontSize } = constants;

/**
 * Width for page
 * @see https://b6ltkh.axshare.com/#g=1&p=modernisation_2_1
 */
export const WIDTH = 990;

export const Container = styled.div`
  width: ${WIDTH}px;
  margin: 0 auto;
`;

export const PageTitle = styled.div`
  margin-bottom: 20px;
  color: ${appTextColor};

  &,
  & h1 {
    font-size: ${fontSize.xLarge};
  }
`;

export const SectionWrapper = styled.div`
  background-color: ${bodyBackground};
  padding: 40px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  &.at_masterListPage {
    padding: 20px 24px 10px;
  }

  &.at_securityScorePage {
    background: ${sidebarBackground};
    margin-bottom: 1px;
  }
`;

export const ContentWrapper = styled(SectionWrapper)`
  padding: 0 24px;
`;

export const ActionWrapper = styled.div`
  margin-bottom: 40px;
  display: flex;
  align-items: baseline;
`;

export const Tooltip = styled(TooltipIcon).attrs(props => ({
  iconProps: {
    size: props.iconSize || '15px',
    color: colors.darkGrey
  },
  gapLeft: props.gapLeft || '7px'
}))``;

export const ActionPanel = styled.div`
  ${mixins.flexVerticalCenter()};
  width: 100%;
`;
