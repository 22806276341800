import React from 'react';
import { LanguageEntry } from '@accordo-feed/language.entry';
import { langHelper } from '@accordo-feed/micro-frontends-utils';

const genId = langHelper.genLangId('shell.aco.partnerCenterWelcome');
const genOpts = langHelper.genLangOptions(genId);

export default {
  pageTitle: genOpts('pageTitle', 'Welcome to Entori™'),
  pageTitle2: genOpts('pageTitle2', 'License Management for M365'),
  pageDescription: genOpts(
    'pageDescription',
    'Get started fast by connecting with Patner Center. We’ll do the hard work so you can quickly uncover new insights and savings for your customers!'
  ),
  rightTitle1: genOpts('right.title1', 'Options to sync with Partner Center'),
  rightTitle2: genOpts('right.title2', 'Adding clients manually'),
  rightSection1Text: genOpts(
    'right.section1.text',
    'Select the option that suits you best. This can always be changed later.'
  ),
  option1: {
    title: genOpts('option1.title', 'AUTOMATED DATA SYNC'),
    name: genOpts('option1.name', 'Secure Application Model'),
    steps: [
      <LanguageEntry
        {...genOpts(
          'option1.step1',
          'Run Powershell to setup SecureApp - <a href="https://docs.microsoft.com/en-us/partner-center/develop/enable-secure-app-model" target="_blank">Learn More</a>'
        )}
      />,
      genOpts('option1.step2', 'Select the clients to automatically sync from Partner Center'),
      genOpts('option1.step3', 'Quickly access data insights that help grow your practice')
    ],
    buttonText: genOpts('option1.buttonText', 'Set up Secure App')
  },
  option2: {
    title: genOpts('option2.title', 'PARTNER CENTER CLIENT LIST'),
    name: genOpts('option2.name', 'Client list import'),
    steps: [
      genOpts('option2.step1', 'Connect with Partner Center'),
      genOpts('option2.step2', 'We’ll import your list of clients'),
      genOpts('option2.step3', 'Select the clients you wish to activate manually')
    ],
    buttonText: genOpts('option2.buttonText', 'Import Client List')
  },
  rightSection2Text: genOpts(
    'right.section2.text',
    'If you do not want to run the PowerShell script or connect to Partner Center, you can get started by adding your clients manually and entering the credentials for each tenant.'
  ),
  skipLink: genOpts('skipLink', 'Skip Partner Center connection - I’ll add clients manually')
};
