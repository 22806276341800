import { Modal } from 'antd';
import styled from 'styled-components';
import { constants, mixins } from '@accordo-feed/aco-styled-components';

import { pageHeaderTextColor, modalBackgroundColor, subHeaderTextColor } from 'src/App.styled';

const { colors, fontSize } = constants;

export const ModalContainer = styled(Modal)`
  .ant-modal-content {
    background-color: ${modalBackgroundColor};
  }
  .ant-modal-close {
    svg {
      fill: ${colors.lightGrey};
    }
  }
`;

export const ModalTitle = styled.h1`
  color: ${pageHeaderTextColor};
  width: 100%;
  font-size: ${fontSize.large};
  margin: 0;
`;

export const ModalBody = styled.div`
  width: 100%;
  margin: 24px 0 0;
  color: ${subHeaderTextColor};
  font-size: ${fontSize.small};
  line-height: 1.5;
  a {
    text-decoration: underline;
  }
`;

export const ModalDescription = styled.div`
  color: ${subHeaderTextColor};
  font-size: ${fontSize.small};
  line-height: 1.5;
  a {
    text-decoration: underline;
  }
`;

export const ModalFooter = styled.div`
  ${mixins.flexVerticalCenter()};
  justify-content: end;
  padding: 24px 0 0;
`;
