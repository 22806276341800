// @flow

import * as R from 'ramda';
import React, { useState } from 'react';

import * as Styled from './paymentDetails.styled';
import CardForm from './CardForm.component';
import { DEFAULT_TRIAL_DAYS } from './paymentDetails.constants';
import { PlanDetail } from './PlanDetail.component';
import { StripeProvider } from 'src/components/stripe';

/*************
 *   TYPES   *
 *************/

type Props = {
  isAnnual: boolean,
  orgId: string,
  payment: Object,
  submitPromoCode: Function,
  subscribeMspToPlan: Function,
  subscribeToTier: number,
  updateBillingAddress: Function,
  userId: string
};

/*****************
 *   COMPONENT   *
 *****************/

export const PaymentDetails = ({
  orgId,
  payment,
  submitPromoCode,
  subscribeMspToPlan,
  updateBillingAddress,
  subscribeToTier,
  isAnnual,
  userId
}: Props) => {
  const [trialDays, setTrialDays] = useState(DEFAULT_TRIAL_DAYS);

  if (!orgId) return null;

  const cardFormProps = {
    isAnnual,
    orgId,
    subscribeMspToPlan,
    subscribeToTier,
    trialDays,
    updateBillingAddress
  };

  return (
    <Styled.Container>
      <StripeProvider>
        <>
          <Styled.MainColumn>
            <Styled.Logo />
            <Styled.Row justifyContent="center">
              <CardForm {...cardFormProps} />
            </Styled.Row>
          </Styled.MainColumn>

          <Styled.Aside>
            <PlanDetail
              orgId={orgId}
              existingPromoCode={R.path(['promotionCode'], payment)}
              userId={userId}
              trialDays={trialDays}
              setTrialDays={setTrialDays}
              submitPromoCode={submitPromoCode}
            />
          </Styled.Aside>
        </>
      </StripeProvider>
    </Styled.Container>
  );
};
