import React from 'react';
import { LanguageEntry } from '@accordo-feed/language.entry';

import VerticalMenu from 'src/components/verticalMenu';
import StyledLink from 'src/components/link';
import { renderSpend } from 'src/pages/masterList/masterList.config';
import { dateConverter, sortByAlph, sortByDate, sortByNumber } from 'src/utils';
import { PATHS, TIME_FORMATS } from 'src/constants';
import lang from './azureUsage.lang';

const tableLang = lang.table;

/***************
 *   CONFIGS   *
 ***************/

export const generateTableColumns = props => {
  const { enableSort, prevPath } = props;

  return [
    {
      key: 'companyName',
      children: [
        {
          key: 'companyName',
          title: <LanguageEntry {...tableLang.resources.company} />,
          className: 'at_columnCompany',
          dataIndex: 'companyName',
          sorter: enableSort && sortByAlph('companyName'),
          render: (name, { id }) => (
            <StyledLink
              to={{
                pathname: PATHS.CUSTOMERS.AZURE_RECOMMENDATIONS.replace(/:id/, id),
                state: { prevPath }
              }}
            >
              {name}
            </StyledLink>
          )
        }
      ]
    },
    {
      key: 'resources',
      title: <LanguageEntry {...tableLang.main.resources} />,
      children: [
        {
          key: 'countOfResourceGroups',
          align: 'center',
          title: <LanguageEntry {...tableLang.resources.groups} />,
          className: 'at_columnGroups',
          dataIndex: 'countOfResourceGroups',
          sorter: enableSort && sortByNumber('countOfResourceGroups')
        },
        {
          key: 'countOfResources',
          align: 'center',
          title: <LanguageEntry {...tableLang.resources.total} />,
          className: 'at_columnTotal',
          dataIndex: 'countOfResources',
          sorter: enableSort && sortByNumber('countOfResources')
        }
      ]
    },
    {
      key: 'billingStartDate',
      children: [
        {
          key: 'billingStartDate',
          align: 'center',
          title: <LanguageEntry {...tableLang.resources.period} />,
          className: 'at_columnBillingPeriod',
          dataIndex: 'billingStartDate',
          render: (billingStartDate, { billingEndDate }) => {
            const from = dateConverter(TIME_FORMATS.DAY_MONTH_YEAR)(billingStartDate);
            const to = dateConverter(TIME_FORMATS.DAY_MONTH_YEAR)(billingEndDate);
            return <span>{`${from} to ${to}`}</span>;
          }
        }
      ]
    },
    {
      key: 'billingDaysRemaining',
      children: [
        {
          key: 'billingDaysRemaining',
          align: 'center',
          title: <LanguageEntry {...tableLang.resources.remaining} />,
          className: 'at_columnBillingRemaining',
          dataIndex: 'billingDaysRemaining',
          sorter: enableSort && sortByNumber('billingDaysRemaining')
        }
      ]
    },
    {
      key: 'budget',
      title: <LanguageEntry {...tableLang.main.budget} />,
      children: [
        {
          key: 'totalCost',
          align: 'center',
          title: <LanguageEntry {...tableLang.budget.cost} />,
          className: 'at_columnEstimatedCost',
          dataIndex: 'totalCost',
          sorter: enableSort && sortByNumber('totalCost'),
          render: renderSpend
        },
        {
          key: 'spendingBudget',
          align: 'center',
          title: <LanguageEntry {...tableLang.budget.budgeted} />,
          className: 'at_columnBudgeted',
          dataIndex: 'spendingBudget',
          sorter: enableSort && sortByNumber('spendingBudget'),
          render: renderSpend
        },
        {
          key: 'percentOfBudgetSpent',
          align: 'center',
          title: <LanguageEntry {...tableLang.budget.percent} />,
          className: 'at_columnPercentBudget',
          dataIndex: 'percentOfBudgetSpent',
          sorter: enableSort && sortByNumber('percentOfBudgetSpent'),
          render: percent => <span>{`${(percent * 100).toFixed(0)}%`}</span>
        }
      ]
    },
    {
      key: 'lastSynced',
      children: [
        {
          key: 'lastSynced',
          title: <LanguageEntry {...tableLang.budget.lastSync} />,
          className: 'at_columnLastSynced',
          dataIndex: 'lastSynced',
          sorter: enableSort && sortByDate('lastSynced'),
          render: dateConverter(TIME_FORMATS.DAY_MONTH_YEAR)
        }
      ]
    },
    {
      key: 'verticalMenu',
      children: [
        {
          className: 'at_columnMenu',
          render: () => <VerticalMenu options={[]} />
        }
      ]
    }
  ];
};
