import React from 'react';
import { langHelper } from '@accordo-feed/micro-frontends-utils';
import { LanguageEntry } from '@accordo-feed/language.entry';

const genId = langHelper.genLangId('shell.aco.secureApp.leftSide');
const genOpts = langHelper.genLangOptions(genId);
const ACO_CDN_SECUREAPP_IMAGES = 'https://optimizer-cdn.accordo.com/images/secureapp';

export default {
  backLink: genOpts('backLink', 'Back to Welcome page'),

  steps: [
    {
      title: genOpts('step1.title1', 'Setup Secure App'),
      desc: genOpts(
        'step1.desc1',
        'Download and run the PowerShell script to generate your App ID, Secret ID, and Tenant ID.<br />Click for more details:<a href="https://docs.entori.com/en" target="_blank" rel="noopener noreferrer"> Secure App Setup Help</a>'
      ),
      panels: [
        {
          title: <LanguageEntry {...genOpts('step1.panel1.title', '1. Download the PowerShell script')} />,
          firstDesc: genOpts(
            'step1.panel1.firstDesc',
            "Download the script from <a href='https://github.com/EntoriLLC/partner-center' target='_blank'>Github</a> by clicking the <em>Clone or download</em> drop-down and selecting <em>Download ZIP</em>"
          ),
          image: `${ACO_CDN_SECUREAPP_IMAGES}/step1GitHubDownload.png`,
          secondDesc: genOpts(
            'step1.panel1.secondDesc',
            'We recommend extracting to a folder other than ‘Downloads’ as PowerShell may not allow you to run scripts from your Downloads folder'
          )
        },
        {
          title: (
            <LanguageEntry
              {...genOpts('step1.panel2.title', '2. Find PowerShell on Your computer and run it as admin')}
            />
          ),
          firstDesc: genOpts(
            'step1.panel2.firstDesc',
            'Locate PowerShell on your computer via the Start menu and select <em>Run as Administrator</em>'
          ),
          image: `${ACO_CDN_SECUREAPP_IMAGES}/step1RunPSFromWindows.png`
        },
        {
          title: (
            <LanguageEntry {...genOpts('step1.panel3.title', '3. Import the downloaded file into Window PowerShell')} />
          ),
          firstDesc: genOpts(
            'step1.panel3.firstDesc',
            "Use the command prompt in PowerShell to locate the downloaded installer file and run it by entering. './' followed by the filename (e.g. &ltfile path&gt.<em>\\ACO_App_Installer_1.0.X.ps1</em>)"
          ),
          image: `${ACO_CDN_SECUREAPP_IMAGES}/step1RunScriptFromPS.png`,
          secondDesc: genOpts(
            'step1.panel3.secondDesc',
            "The <a href='https://docs.microsoft.com/en-us/powershell/module/azuread/?view=azureadps-2.0' target='_blank'>Azure AD PowerShell</a> module is required to proceed. If not already installed, you will be prompted to auto-install it. <em>Type ‘Y’ and hit enter</em> to automatically install it"
          )
        },
        {
          title: (
            <LanguageEntry
              {...genOpts('step1.panel4.title', '4. Enter your Global admin credentials into Microsoft window')}
            />
          ),
          firstDesc: genOpts(
            'step1.panel4.firstDesc',
            'In the window that appears, enter you <em>Global Admin</em> credentials for <em>Azure Active Directory</em>'
          ),
          image: `${ACO_CDN_SECUREAPP_IMAGES}/step1SignInMs.jpg`
        },
        {
          title: (
            <LanguageEntry
              {...genOpts('step1.panel5.title', '5. Copy/Paste the App ID, App Secret and Tenant ID from script')}
            />
          ),
          firstDesc: genOpts(
            'step1.panel5.firstDesc',
            'Once the script has run successfully, it will output your <em>App ID, App Secret</em> and <em>Tenant ID</em> into the PowerShell window. Please <em>copy/paste</em> these into the fields in this window and <em>click Next</em>'
          ),
          image: `${ACO_CDN_SECUREAPP_IMAGES}/step1CopyPaste.gif`,
          secondDesc: genOpts(
            'step1.panel5.secondDesc',
            '<em>TIP:</em> You can copy all three credentials at once and paste them into any of the three fields in Entori. '
          )
        }
      ]
    },
    {
      title: genOpts('step2.title1', 'Connect to Partner Center'),
      desc: genOpts('step2.desc1', 'Start syncing with Microsoft Partner Center'),
      panels: [
        {
          firstDesc: genOpts(
            'step2.panel1.firstDesc',
            'Click <em>Connect to Partner Center</em> to open the Microsoft Partner Center login screen. Enter your Partner Center <em>Global Admin</em> user credentials and follow the prompts on screen to complete consent process.'
          ),
          image: `${ACO_CDN_SECUREAPP_IMAGES}/step2StartSyncing.png`,
          secondDesc: genOpts(
            'step2.panel1.secondDesc',
            'This will allow Entori to import your client list into the Client Management page'
          )
        }
      ]
    },
    {
      title: genOpts('step3.title1', 'Select and Import Clients'),
      desc: genOpts(
        'step3.desc1',
        "Select from your list of clients to start automatically syncing your clients' licensing, consumption, and security data"
      ),
      panels: []
    }
  ]
};
