import styled, { css } from 'styled-components';
import { CheckCircleOutlined } from '@ant-design/icons';
import { mixins, constants } from '@accordo-feed/aco-styled-components';
import BillingDiscrepancyLargeSvg from 'src/images/billingDiscrepancyLarge.svg';
import { iconMixins } from 'src/components/appSider/menu/menu.styled';

import UIButton from 'src/components/UI/button';
import { appTextColor } from 'src/App.styled';

const { colors, fontSize, borderRadius } = constants;

export const CheckIcon = styled(CheckCircleOutlined)`
  color: ${colors.shadeGreen} !important;
  font-size: 54px;
  margin-bottom: 20px;
`;

export const ContentWrapper = styled.div`
  ${mixins.flexCenter()};
  flex-direction: column;
  padding: 10px 20px;
  height: 100%;

  .ant-btn {
    margin-top: 10px;
  }
`;

export const FlexWrapper = styled.div`
  ${mixins.flexCenter()};
  flex-direction: ${props => (props.column ? 'column' : 'row')};
  align-items: ${props => (props.startAlign ? 'flex-start' : 'initial')};
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: ${props => (props.leftAlign ? 'left' : 'center')};
  color: ${appTextColor};
  font-size: ${props => (props.small ? fontSize.xSmall : fontSize.small)};
  line-height: 18px;
  margin-bottom: ${props => (props.marginBottom ? '10px' : 'auto')};
  margin-top: ${props => (props.marginTop ? '10px' : 'auto')};
  width: ${props => (props.configWidth ? '80%' : '100%')};

  > svg {
    > path {
      fill: ${appTextColor};
    }
  }
`;

export const EmphasisedText = styled.span`
  color: ${colors.darkBlue};
  font-size: 60px;
  line-height: 63px;
  padding-right: 20px;
  margin-right: 20px;
  border-right: 1px solid ${colors.lightShadeGrey};
`;

export const TextRow = styled.div`
  ${mixins.flexCenter};
  font-size: ${fontSize.xSmall};
  color: ${colors.darkShadeGrey};
`;

export const Text = styled.span`
  width: ${props => (props.width ? `${props.width}px` : 'initial')};
`;

const buttonAttrs = {
  type: 'primary',
  size: 'large',
  block: true
};

const buttonMixins = css`
  border-radius: ${borderRadius};
`;

export const DownloadButton = styled(UIButton).attrs({
  ...buttonAttrs,
  icon: 'download'
})`
  ${buttonMixins};
  margin-top: 30px;
  font-size: ${fontSize.xSmall} !important;
  i {
    font-size: ${fontSize.medium} !important;
  }
`;

export const BillingDiscrepancyLargeIcon = styled(BillingDiscrepancyLargeSvg).attrs({
  className: 'anticon'
})`
  ${iconMixins};
  ${mixins.size('66px')};
  fill: none;
`;
