// @flow

import * as React from 'react';

import { STORAGE_KEYS } from 'src/constants';
import { StyledAlert } from './banner.styled';

/*************
 *   TYPE   *
 *************/

type BannerProps = {
  localStorageName: string,
  children: React.Node,
  icon: React.Node,
  showIcon: boolean,
  closable: boolean
};

/*****************
 *   COMPONENT   *
 *****************/

const Banner = (props: BannerProps) => {
  const { localStorageName, children } = props;
  const isHidden = Boolean(localStorage.getItem(localStorageName));
  const hideBanner = () => {
    localStorage.setItem(localStorageName, 'true');
  };

  return !isHidden && <StyledAlert afterClose={hideBanner} message={children} {...props} />;
};

Banner.defaultProps = {
  localStorageName: STORAGE_KEYS.HIDE_COOKIE_BANNER,
  closable: true
};

export default Banner;
