// @flow

import * as R from 'ramda';
import { redux } from '@accordo-feed/micro-frontends';
import { connect } from 'react-redux';

import * as profileSelectors from 'src/pages/profile/profile.selector';
import PsaBillingAlertComponent from './PsaBillingAlert.component';
import {
  addPsaBillingAlertEmail,
  removePsaBillingAlertEmail,
  setPsaBillingAlertStatus
} from 'src/redux/modules/organization/organization.duck';
import { setAlertUpdated, updatePsaBillingAlert } from 'src/pages/profile/profile.duck';

const { bindActionCreatorsCurried } = redux;

/*************
 *   REDUX   *
 *************/

const mapStateToProps = R.applySpec({
  billingAlert: profileSelectors.psaBillingAlertSelector,
  isAlertUpdated: profileSelectors.isAlertUpdatedSelector,
  isAlertUpdating: profileSelectors.isAlertUpdatingSelector
});

const mapDispatchToProps = bindActionCreatorsCurried({
  addPsaBillingAlertEmail,
  removePsaBillingAlertEmail,
  setPsaBillingAlertStatus,
  setAlertUpdated,
  updatePsaBillingAlert
});

export default connect(mapStateToProps, mapDispatchToProps)(PsaBillingAlertComponent);
