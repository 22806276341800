// @flow

import React from 'react';

import * as Styled from './recommendations.styled';
import Heading from './Heading.component';
import { RECOM_SECTIONS } from 'src/constants';
import { RecommendationsData } from './Security.component';
import { type RecommendationsType } from './recommendations.duck';

/*************
 *   TYPES   *
 *************/

type Props = {
  translate: Function,
  recommendations: RecommendationsType
};

/*****************
 *   COMPONENT   *
 *****************/

const Availability = ({ translate, recommendations }: Props) => (
  <Styled.Section>
    <Heading type={RECOM_SECTIONS.AVAILABILITY} translate={translate} />
    <RecommendationsData translate={translate} items={recommendations.availability} />
  </Styled.Section>
);

export default Availability;
