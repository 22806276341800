import { langHelper } from '@accordo-feed/micro-frontends-utils';

const genId = langHelper.genLangId('generic.error.message');
const genOpts = langHelper.genLangOptions(genId);

export default {
  header: genOpts('header', 'We are sorry, the page you requested cannot be found'),
  paragraph1: genOpts('paragraph1', 'The URL may be misspelled or'),
  paragraph2: genOpts('paragraph2', 'the page you are looking for is no longer available'),
  buttonText: genOpts('buttonText', 'Return to your site')
};
