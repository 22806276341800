import { langHelper } from '@accordo-feed/micro-frontends-utils';

import { STATUS, IMPACT } from './drawer.constants';

const genId = langHelper.genLangId('shell.aco.securityScore.drawer');
const genOpts = langHelper.genLangOptions(genId);

export default {
  title: genOpts('title', '{name} ({num} Improvement actions)'),
  currentScore: genOpts('currentScore', 'Current Microsoft Secure Score'),
  maxScore: genOpts('maxScore', 'Max Microsoft Secure Score'),
  scoreIndex: genOpts('scoreIndex', 'Secure Score Index'),
  searchPlaceholder: genOpts('searchPlaceholder', 'Search by improvement action'),
  filteredActions: genOpts('filteredActions', '{num} filtered actions/ '),
  totalNum: genOpts('totalNum', 'Total {num}'),
  downloadReport: genOpts('downloadReport', 'Export All data (CSV)'),
  table: {
    improvementAction: genOpts('table.improvementAction', 'Improvement Actions'),
    status: genOpts('table.status', 'Status'),
    score: genOpts('table.score', 'Score Current'),
    maxScore: genOpts('table.maxScore', 'Score Max'),
    controlCategory: genOpts('table.controlCategory', 'Category'),
    userImpact: genOpts('table.userImpact', 'User Impact')
  },
  status: {
    [STATUS.COMPLETED]: genOpts('status.completed', 'Completed'),
    [STATUS.NOT_COMPLETED]: genOpts('status.notCompleted', 'Not Completed')
  },
  impact: {
    [IMPACT.LOW]: genOpts('impact.low', 'Low'),
    [IMPACT.MODERATE]: genOpts('impact.moderate', 'Moderate'),
    [IMPACT.HIGH]: genOpts('impact.high', 'High')
  },
  loadingMsg: genOpts('loadingMsg', 'Loading improvement actions for the selected customer'),
  tooltipMsg: genOpts('drawerTableStatusTooltip', 'Try filtering by clicking on the row header')
};
