// https://github.com/jackocnr/intl-tel-input/blob/master/src/js/data.js
export default [
  { au: '+61' },
  { at: '+43' },
  { be: '+32' },
  { bg: '+359' },
  { ca: '+1' },
  { hr: '+385' },
  { cy: '+357' },
  { cz: '+420' },
  { dk: '+45' },
  { ee: '+372' },
  { fi: '+358' },
  { fr: '+33' },
  { de: '+49' },
  { gr: '+30' },
  { hu: '+36' },
  { ie: '+353' },
  { it: '+39' },
  { kw: '+965' },
  { lv: '+371' },
  { lt: '+370' },
  { lu: '+352' },
  { my: '+60' },
  { mt: '+356' },
  { nl: '+31' },
  { nz: '+64' },
  { no: '+47' },
  { ph: '+63' },
  { pl: '+48' },
  { pt: '+351' },
  { ro: '+40' },
  { sg: '+65' },
  { sk: '+421' },
  { si: '+386' },
  { za: '+27' },
  { es: '+34' },
  { se: '+46' },
  { ch: '+41' },
  { ae: '+971' },
  { gb: '+44' },
  { us: '+1' }
];
