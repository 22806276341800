export default [
  'AU',
  'AT',
  'BE',
  'BG',
  'CA',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'KW',
  'LV',
  'LT',
  'LU',
  'MY',
  'MT',
  'NL',
  'NZ',
  'NO',
  'PH',
  'PL',
  'PT',
  'RO',
  'SG',
  'SK',
  'SI',
  'ZA',
  'ES',
  'SE',
  'CH',
  'AE',
  'GB',
  'US'
];
