// @flow

import * as R from 'ramda';
import { connect } from 'react-redux';

import * as selectors from 'src/redux/selectors';
import { AddressFormWrapper } from './AddressFormWrapper.component';

/*************
 *   REDUX   *
 *************/

const mapStateToProps = R.applySpec({
  billingAddress: selectors.organizationBillingAddressSelector,
  errors: selectors.updateBillingAddressErrorsSelector,
  registeredCountryCode: selectors.organizationCountryCodeSelector
});

export default connect(mapStateToProps)(AddressFormWrapper);
