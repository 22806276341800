import * as R from 'ramda';
import bodybuilder from 'bodybuilder';
import { createSelector } from 'reselect';

import { orgIdSelector } from 'src/redux/selectors/userData';
import { widgetsDataSelector } from 'src/pages/dashboard/dashboard.selector';

const billingDiscrepancySelector = createSelector(
  widgetsDataSelector,
  R.prop('billingDiscrepancy')
);

export const billingDiscrepancyDataSelector = createSelector(
  billingDiscrepancySelector,
  R.prop('data')
);

export const billingDiscrepancyIsLoadingSelector = createSelector(
  billingDiscrepancySelector,
  R.prop('isLoading')
);

export const billingDiscrepancyPsaIsConnectedSelector = createSelector(
  billingDiscrepancySelector,
  R.prop('isConnected')
);

export const billingDiscrepancyQuerySelector = createSelector(
  orgIdSelector,
  partnerId => {
    const esQuery = bodybuilder()
      .size(0)
      .query('term', 'partnerId', partnerId)
      .agg('sum', { field: 'deficit' }, 'totalDeficit')
      .agg('sum', { field: 'surplus' }, 'totalSurplus')
      .build();

    return {
      queryName: 'billingDiscrepancyWidget',
      partnerId,
      esQuery
    };
  }
);
