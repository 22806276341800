export const SUBSCRIPTIONS_MOCK_DATA = [
  {
    id: 'acc_24c3e368-33f4-4024-8843-7f7348b9ab4a',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Tech IO',
    email: 'matt@entori.com',
    status: 'active',
    countryCode: 'us',
    created: '2021-12-15T22:50:42.303Z',
    lastUpdated: '2021-12-27T18:57:35.072Z',
    microsoft: {},
    organizationType: 'Customer',
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {
        subs: 2,
        seats: 200,
        addons: 25,
        spend: 1453,
        expiring: 5,
        renewal: 0,
        atrisk: 1215
      }
    }
  },
  {
    id: 'acc_358b96a7-4f25-4deb-8e47-121490af1b28',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Foodmart',
    email: 'matt@entori.com',
    status: 'active',
    countryCode: 'us',
    created: '2021-12-07T18:05:53.906Z',
    lastUpdated: '2022-01-07T20:52:03.933Z',
    microsoft: {},
    organizationType: 'Customer',
    office365Status: 'Process failed',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2175
      },
      annual: {
        subs: 4,
        seats: 2220,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {
        subs: 0
      }
    }
  },
  {
    id: 'acc_542ab69f-9a76-4327-aaad-3607af86f799',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Urbanshire',
    email: 'matt@entori.com',
    status: 'active',
    countryCode: 'us',
    created: '2021-11-17T17:05:48.680Z',
    lastUpdated: '2021-12-17T16:53:14.765Z',
    microsoft: {},
    organizationType: 'Customer',
    office365Status: 'Process failed',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2222,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {
        subs: 2
      }
    }
  },
  {
    id: 'acc_4cd5764a-5f86-40fa-b2cd-4275a1e1bf58',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Big Data',
    email: 'matt@bigdata.com',
    status: 'active',
    countryCode: 'us',
    created: '2021-11-12T18:03:08.777Z',
    lastUpdated: '2022-01-25T18:56:49.643Z',
    microsoft: {},
    organizationType: 'Customer',
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {
        subs: 2
      }
    }
  },
  {
    id: 'acc_4e970df6-71f0-497f-8c0e-4af65001ace9',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'FunCo',
    email: 'matt@funco.com',
    status: 'active',
    countryCode: 'ee',
    created: '2021-10-13T18:05:37.121Z',
    lastUpdated: '2021-10-13T18:05:37.121Z',
    microsoft: {},
    organizationType: 'Prospect',
    office365Status: 'Inactive',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2100,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {
        subs: 2
      }
    }
  },
  {
    id: 'acc_872fbac7-be5a-4a0b-907a-8a2577f161b7',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Entori',
    email: 'Matt@entori.com',
    status: 'active',
    countryCode: 'us',
    created: '2021-09-24T08:41:32.316Z',
    lastUpdated: '2021-11-25T09:50:17.318Z',
    microsoft: {},
    organizationType: 'Customer',
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2000,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {
        subs: 2
      }
    }
  },
  {
    id: 'acc_051638f2-a575-4848-9682-7fab7dc607df',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Midsized Business Consulting',
    email: 'matt@entori.com',
    status: 'active',
    countryCode: 'us',
    created: '2021-09-14T22:43:37.416Z',
    lastUpdated: '2021-12-17T16:53:10.578Z',
    microsoft: {},
    organizationType: 'Customer',
    office365Status: 'Process failed',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {
        subs: 2,
        seats: 200,
        addons: 25,
        spend: 1453,
        expiring: 5,
        renewal: 0,
        atrisk: 1215
      }
    }
  },
  {
    id: 'acc_1887ad40-d0b1-4701-93e1-92ffa712c631',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Large and in Charge inc.',
    email: 'matt@entori.com',
    status: 'active',
    countryCode: 'us',
    created: '2021-09-14T22:43:34.759Z',
    lastUpdated: '2021-12-17T16:53:13.401Z',
    microsoft: {},
    organizationType: 'Customer',
    office365Status: 'Process failed',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {
        subs: 2,
        seats: 200,
        addons: 25,
        spend: 1453,
        expiring: 5,
        renewal: 0,
        atrisk: 1215
      }
    }
  },
  {
    id: 'acc_e182442c-8e0d-4afb-93dc-38534c016bab',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Globex Corporation',
    email: 'tycho.nestoris@globexcorporation.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:16.345Z',
    lastUpdated: '2021-05-14T19:39:16.345Z',
    microsoft: {},
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {}
    }
  },
  {
    id: 'acc_d639bab5-ded3-4944-bbaf-19d9fd952ba8',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Brent Eastwood LLC',
    email: 'brent.eastwood@brenteastwoodllc.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:16.176Z',
    lastUpdated: '2021-05-14T19:39:16.176Z',
    microsoft: {},
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {}
    }
  },
  {
    id: 'acc_2ebdde28-ffa6-4fb3-bd12-f6daac02ed81',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Cyberdyne Systems',
    email: 'troy.barnes@cyberdynesystems.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:16.119Z',
    lastUpdated: '2021-05-14T19:39:16.119Z',
    microsoft: {},
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {}
    }
  },
  {
    id: 'acc_212597e0-d3e5-4c33-8813-2a1269e8fc0c',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Transworld Consortium',
    email: 'sebastian.watson@transworldconsortium.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:16.095Z',
    lastUpdated: '2021-09-06T10:11:35.974Z',
    microsoft: {},
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {}
    }
  },
  {
    id: 'acc_c4eb65ff-ac9a-4511-82d8-869ff203f7b6',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Praxis Corporation',
    email: 'trevor.bell@praxiscorporation.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:16.087Z',
    lastUpdated: '2021-05-14T19:39:16.087Z',
    microsoft: {},
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {
        subs: 2,
        seats: 200,
        addons: 25,
        spend: 1453,
        expiring: 5,
        renewal: 0,
        atrisk: 1215
      }
    }
  },
  {
    id: 'acc_4d6792a7-2285-4457-a987-47dd5fb066be',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Bubba Gump',
    email: 'paula.gao@bubbagump.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:16.083Z',
    lastUpdated: '2021-12-03T23:50:32.534Z',
    microsoft: {},
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {
        subs: 2,
        seats: 200
      }
    }
  },
  {
    id: 'acc_8893992d-a2dd-4d24-af31-53c25dd520ee',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Soylent Corp',
    email: 'carmen.ferguson@soylentcorp.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:16.083Z',
    lastUpdated: '2021-05-14T19:39:16.083Z',
    microsoft: {},
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {
        subs: 2,
        seats: 200,
        addons: 25
      }
    }
  },
  {
    id: 'acc_bb8e15f6-f458-42f2-ad9c-c60653f0c95c',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Sombra Corporation',
    email: 'owen.cornish@sombracorporation.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:16.075Z',
    lastUpdated: '2021-05-14T19:39:16.075Z',
    microsoft: {},
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {
        subs: 2,
        seats: 200,
        renewal: 0,
        atrisk: 1215
      }
    }
  },
  {
    id: 'acc_6f5e03f9-03d0-4018-a7d7-2909df2ac7e8',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Green Valley Medical',
    email: 'robert.reed@greenvalleymedical.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:16.074Z',
    lastUpdated: '2021-05-14T19:39:16.074Z',
    microsoft: {},
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {}
    }
  },
  {
    id: 'acc_92b13d3c-d548-43b4-bb41-0b99b09d3330',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Vehement Capital Partners',
    email: 'james.delox@vehementcapitalpartners.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:16.070Z',
    lastUpdated: '2021-05-14T19:39:16.070Z',
    microsoft: {},
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {
        subs: 2,
        atrisk: 1215
      }
    }
  },
  {
    id: 'acc_f39849d2-2298-4543-8320-c8f56db0935e',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Initech',
    email: 'warren.nelson@initech.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:16.065Z',
    lastUpdated: '2021-05-14T19:39:16.065Z',
    microsoft: {},
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {
        subs: 2,
        atrisk: 1215
      }
    }
  },
  {
    id: 'acc_52b8818d-2d14-4831-989e-b8701572191a',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'United Fried Chicken',
    email: 'madeleine.anderson@unitedfriedchicken.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:16.061Z',
    lastUpdated: '2021-05-14T19:39:16.062Z',
    microsoft: {},
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {
        subs: 2,
        atrisk: 1215
      }
    }
  },
  {
    id: 'acc_034e795a-222c-4e8c-8bdc-1b491a176031',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Good Burger',
    email: 'kyron.lonn@goodburger.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:16.059Z',
    lastUpdated: '2021-05-14T19:39:16.059Z',
    microsoft: {},
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {}
    }
  },
  {
    id: 'acc_052d729e-a49d-4e69-8b0a-943a61308133',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Globo-Chem',
    email: 'chad.santiago@globochem.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:16.058Z',
    lastUpdated: '2021-05-14T19:39:16.058Z',
    microsoft: {},
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {}
    }
  },
  {
    id: 'acc_7955f2b0-640b-4ce4-a705-74793e4c8464',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'LexCorp',
    email: 'erlich.backman@lexcorp.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:16.057Z',
    lastUpdated: '2021-05-14T19:39:16.057Z',
    microsoft: {},
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {}
    }
  },
  {
    id: 'acc_a6da765e-6663-4258-8354-80740e5942a2',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'MarketStar',
    email: 'dmackenzie@marketstar.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:16.054Z',
    lastUpdated: '2022-01-07T20:39:51.564Z',
    microsoft: {},
    organizationType: 'Customer',
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {}
    }
  },
  {
    id: 'acc_779f9ecc-0789-49d9-99c3-24db15934a6f',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Osato Chemicals',
    email: 'neil.quinn@osatochemicals.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:16.054Z',
    lastUpdated: '2021-05-14T19:39:16.054Z',
    microsoft: {},
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {}
    }
  },
  {
    id: 'acc_f2f1cd91-b6d7-4c2d-a3c2-60c493caee9c',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Olivia Pope & Associates',
    email: 'mark.simmons@oliviapopeassociates.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:16.054Z',
    lastUpdated: '2021-05-14T19:39:16.054Z',
    microsoft: {},
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12055,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {
        subs: 2,
        seats: 200,
        addons: 25,
        spend: 1453,
        expiring: 5,
        renewal: 0,
        atrisk: 1215
      }
    }
  },
  {
    id: 'acc_648ee45c-7c9b-48f8-8bca-1a7a3fd98bf2',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Acme Corporation',
    email: 'saif.campbell@acmecorporation.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:16.047Z',
    lastUpdated: '2021-05-14T19:39:16.047Z',
    microsoft: {},
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 10355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {
        subs: 2,
        seats: 200,
        addons: 25,
        spend: 1453,
        expiring: 5,
        renewal: 0,
        atrisk: 1215
      }
    }
  },
  {
    id: 'acc_c399c56e-1fea-4d9a-9b9f-9bbbe6f8c0b7',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Stark Industries',
    email: 'ronan.dunn@starkindustries.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:16.015Z',
    lastUpdated: '2021-05-14T19:39:16.016Z',
    microsoft: {},
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 11355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {
        subs: 2,
        seats: 200,
        addons: 25,
        spend: 1453,
        expiring: 5,
        renewal: 0,
        atrisk: 1215
      }
    }
  },
  {
    id: 'acc_dcdf2dd8-aa96-4d8f-9779-3e4fc1775b96',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Wayne Enterprises',
    email: 'connor.hall@wayneenterprises.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:16.014Z',
    lastUpdated: '2021-05-14T19:39:16.014Z',
    microsoft: {},
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {
        subs: 2,
        seats: 200,
        addons: 25,
        spend: 1453,
        expiring: 5,
        renewal: 0,
        atrisk: 1215
      }
    }
  },
  {
    id: 'acc_d44fc855-db5e-4871-9bd5-7bd7a69716f8',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: "Ollivander's Wand Shop",
    email: 'caroline.watson@ollivanderswandshop.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:16.009Z',
    lastUpdated: '2021-05-14T19:39:16.009Z',
    microsoft: {},
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {
        subs: 2,
        seats: 200,
        addons: 25,
        spend: 1453,
        expiring: 5,
        renewal: 0,
        atrisk: 1215
      }
    }
  },
  {
    id: 'acc_967fa128-c60e-49aa-83fe-b4e60415efca',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Duff Beer',
    email: 'vanessa.banks@duffbeer.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:16.007Z',
    lastUpdated: '2021-05-14T19:39:16.007Z',
    microsoft: {},
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {
        subs: 2,
        seats: 200,
        addons: 25,
        spend: 1453,
        expiring: 5,
        renewal: 0,
        atrisk: 1215
      }
    }
  },
  {
    id: 'acc_56536397-09ee-40bb-81f1-4311e6aa8fe0',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Hooli',
    email: 'layla.whittle@hoolight.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:16.007Z',
    lastUpdated: '2021-05-14T19:39:16.007Z',
    microsoft: {},
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {
        subs: 2,
        seats: 200,
        addons: 25,
        spend: 1453,
        expiring: 5,
        renewal: 0,
        atrisk: 1215
      }
    }
  },
  {
    id: 'acc_bb46ee34-a744-495a-a7b7-85ba74d65faf',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Skyforce',
    email: 'yan.wei@skyforce.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:16.005Z',
    lastUpdated: '2021-05-14T19:39:16.006Z',
    microsoft: {},
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {
        subs: 2,
        seats: 200,
        addons: 25,
        spend: 1453,
        expiring: 5,
        renewal: 0,
        atrisk: 1215
      }
    }
  },
  {
    id: 'acc_205eb8b9-1757-4c58-88eb-a386305f8356',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Wonka Industries',
    email: 'john.garcia@wonkaindustries.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:16.002Z',
    lastUpdated: '2021-05-14T19:39:16.003Z',
    microsoft: {},
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {
        subs: 2,
        seats: 200,
        addons: 25,
        spend: 1453,
        expiring: 5,
        renewal: 0,
        atrisk: 1215
      }
    }
  },
  {
    id: 'acc_fd575fdf-8e94-4314-94a2-9a468e53ca0b',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'North Central Positronics',
    email: 'jacob.terry@northcentralpositronics.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:16.001Z',
    lastUpdated: '2021-05-14T19:39:16.001Z',
    microsoft: {},
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {
        subs: 2,
        seats: 200,
        addons: 25,
        spend: 1453,
        expiring: 5,
        renewal: 0,
        atrisk: 1215
      }
    }
  },
  {
    id: 'acc_6b4d8fa9-e02d-40ef-9da7-466e268f72e6',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Virtucon',
    email: 'sebastian.peake@virtucon.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:16.001Z',
    lastUpdated: '2021-05-14T19:39:16.001Z',
    microsoft: {},
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {
        subs: 2,
        seats: 200,
        addons: 25,
        spend: 1453,
        expiring: 5,
        renewal: 0,
        atrisk: 1215
      }
    }
  },
  {
    id: 'acc_641dcc74-943f-427f-bada-4a4e7d4efab0',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Gekko & Co',
    email: 'euan.morales@gekkoco.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:15.999Z',
    lastUpdated: '2021-05-14T19:39:16.000Z',
    microsoft: {},
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {
        subs: 2,
        seats: 200,
        addons: 25,
        spend: 1453,
        expiring: 5,
        renewal: 0,
        atrisk: 1215
      }
    }
  },
  {
    id: 'acc_12ed768e-4123-442e-9fd9-f6295d8696b7',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Sto Plains Holdings',
    email: 'ava.vaughan@stoplainsholdings.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:15.998Z',
    lastUpdated: '2021-05-14T19:39:15.998Z',
    microsoft: {},
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {
        subs: 2,
        seats: 200,
        addons: 25,
        spend: 1453,
        expiring: 5,
        renewal: 0,
        atrisk: 1215
      }
    }
  },
  {
    id: 'acc_5e22ad48-5519-471f-8b18-d880a0201688',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Galaxy Corp',
    email: 'john.osborne@galaxycorp.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:15.996Z',
    lastUpdated: '2021-05-14T19:39:15.996Z',
    microsoft: {},
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {
        subs: 2,
        seats: 200,
        addons: 25,
        spend: 1453,
        expiring: 5,
        renewal: 0,
        atrisk: 1215
      }
    }
  },
  {
    id: 'acc_179a4e2f-6938-4d52-9d1a-7276ba94a3ee',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Umbrella Corporation',
    email: 'fiona.hines@umbrellacorporation.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:15.994Z',
    lastUpdated: '2021-05-14T19:39:15.994Z',
    microsoft: {},
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {
        subs: 2,
        seats: 200,
        addons: 25,
        spend: 1453,
        expiring: 5,
        renewal: 0,
        atrisk: 1215
      }
    }
  },
  {
    id: 'acc_ae55b7f2-2544-4407-80e6-3feb11efe566',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Cheers',
    email: 'lachlan.fisher@cheers.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:15.991Z',
    lastUpdated: '2021-05-14T19:39:15.992Z',
    microsoft: {},
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {
        subs: 2,
        seats: 200,
        addons: 25,
        spend: 1453,
        expiring: 5,
        renewal: 0,
        atrisk: 1215
      }
    }
  },
  {
    id: 'acc_0aa9c427-fc9a-41e6-95fc-72ed8c29d98e',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Aviato US',
    email: 'douglas.reynholm@aviatous.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:15.991Z',
    lastUpdated: '2022-01-27T17:31:02.456Z',
    microsoft: {},
    organizationType: 'Customer',
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {
        subs: 2,
        seats: 200,
        addons: 25,
        spend: 1453,
        expiring: 5,
        renewal: 0,
        atrisk: 1215
      }
    }
  },
  {
    id: 'acc_0d399392-679f-490f-8a28-9eb68bdfea15',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Sterling Cooper',
    email: 'elmer.lane@sterlingcooper.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:15.990Z',
    lastUpdated: '2021-05-14T19:39:15.990Z',
    microsoft: {},
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {
        subs: 2,
        seats: 200,
        addons: 25,
        spend: 1453,
        expiring: 5,
        renewal: 0,
        atrisk: 1215
      }
    }
  },
  {
    id: 'acc_03b58dcb-1f20-413f-b26d-5b588344da25',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Datamatiq',
    email: 'anika.hawkings@datamatiq.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:15.988Z',
    lastUpdated: '2022-01-25T18:55:48.558Z',
    microsoft: {},
    organizationType: 'Customer',
    office365Status: 'Auth Failed',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {
        subs: 2,
        seats: 200,
        addons: 25,
        spend: 1453,
        expiring: 5,
        renewal: 0,
        atrisk: 1215
      }
    }
  },
  {
    id: 'acc_eed14c1e-7236-42ca-9b38-dd16b774444b',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Extensive Enterprise',
    email: 'hamala.adarsh@extensiveenterprise.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:15.987Z',
    lastUpdated: '2022-02-02T06:02:12.586Z',
    microsoft: {},
    organizationType: 'Customer',
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {
        subs: 2,
        seats: 200,
        addons: 25,
        spend: 1453,
        expiring: 5,
        renewal: 0,
        atrisk: 1215
      }
    }
  },
  {
    id: 'acc_e6b94381-db5c-494b-866e-0660db24a74d',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'The New York Inquirer',
    email: 'beth.ray@thenewyorkinquirer.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:15.985Z',
    lastUpdated: '2021-05-14T19:39:15.985Z',
    microsoft: {},
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {
        subs: 2,
        seats: 200,
        addons: 25,
        spend: 1453,
        expiring: 5,
        renewal: 0,
        atrisk: 1215
      }
    }
  },
  {
    id: 'acc_63d07d97-33e4-4745-8887-a3fde0e30518',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Bluecat Services',
    email: 'sian.tao@bluecatservices.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:15.984Z',
    lastUpdated: '2021-05-14T19:39:15.984Z',
    microsoft: {},
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {
        subs: 2,
        seats: 200,
        addons: 25,
        spend: 1453,
        expiring: 5,
        renewal: 0,
        atrisk: 1215
      }
    }
  },
  {
    id: 'acc_08b1bcd0-4bc5-40c5-b6b6-5cb896ddb2dc',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'Universal Export',
    email: 'irene.mackay@universalexport.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:15.981Z',
    lastUpdated: '2021-05-14T19:39:15.981Z',
    microsoft: {},
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {
        subs: 2,
        seats: 200,
        addons: 25,
        spend: 1453,
        expiring: 5,
        renewal: 0,
        atrisk: 1215
      }
    }
  },
  {
    id: 'acc_f2b17de8-ebbc-4cd4-8987-b85d0358d9c4',
    mspId: 'ac_40c5ca3f-029d-47b5-bc8a-8ce6b7cad5ed',
    partnerName: 'Entori',
    psa: [],
    name: 'The Legitimate Business Club',
    email: 'leonard.sanderson@thelegitimatebusinessclub.com',
    status: 'active',
    countryCode: 'nz',
    created: '2021-05-14T19:39:15.955Z',
    lastUpdated: '2021-05-14T19:39:15.955Z',
    microsoft: {},
    office365Status: 'Active',
    subscriptions: {
      overview: {
        subs: 22,
        seats: 2275
      },
      annual: {
        subs: 4,
        seats: 2200,
        addons: 223,
        spend: 12355,
        expiring: 5,
        renewal: 4,
        atrisk: 1323
      },
      monthly: {
        subs: 2,
        seats: 200,
        addons: 25,
        spend: 1453,
        expiring: 5,
        renewal: 0,
        atrisk: 1215
      }
    }
  }
];
