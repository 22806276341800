import { langHelper } from '@accordo-feed/micro-frontends-utils';

const genId = langHelper.genLangId('shell.aco.dashboard.widget.growthTrend');
const genOpts = langHelper.genLangOptions(genId);

export default {
  widgetTitle: genOpts('widgetTitle', 'Company Growth'),
  widgetSubTitle: genOpts('widgetSubTitle', 'M365 growth by Company'),
  activeUsers: genOpts('activeUsers', 'Active users'),
  totalLicenses: genOpts('totalLicenses', 'License count'),
  monthlySpend: genOpts('monthlySpend', 'Spend'),
  actualLine: genOpts('actualLine', 'Actual'),
  forecastLine: genOpts('forecastLine', 'Forecast'),
  positive: genOpts('positive', 'Positive'),
  negative: genOpts('negative', 'Negative')
};
