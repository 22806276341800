import React, { useState } from 'react';
import styled from 'styled-components';

const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 4px;
`;

export const useStripeField = id => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isFocus, setIsFocus] = useState(false);

  return {
    props: {
      id,

      isFocus,
      isError: !!errorMessage,

      onFocus: e => {
        setIsFocus(true);
      },

      onBlur: e => {
        setIsFocus(false);
      },

      onChange: ({ error }) => {
        if (error) {
          setErrorMessage(error.message);
          return;
        }

        if (errorMessage) {
          setErrorMessage('');
        }
      }
    },

    errorMessage: errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>
  };
};
