// @flow

import React from 'react';
import { useTranslate } from '@accordo-feed/language.entry';

import * as Styled from './leftSide.styled';
import lang from './leftSide.lang';

export default () => {
  const translate = useTranslate();

  return (
    <Styled.Container>
      <Styled.Logo />
      <Styled.Title>{translate(lang.title)}</Styled.Title>
      <Styled.Content>
        {lang.benefits.map((item, idx) => (
          <li key={idx}>
            <Styled.CheckIcon />
            <Styled.Info>{translate(item)}</Styled.Info>
          </li>
        ))}
      </Styled.Content>
    </Styled.Container>
  );
};
