import React from 'react';
import { LanguageEntry } from '@accordo-feed/language.entry';

import { renderGrowth, renderSpend } from 'src/pages/masterList/masterList.config';
import { Tooltip } from 'src/pages/page.styled';
import { PATHS, SUBMODULES } from 'src/constants';
import { sortByAlph, sortByNumber } from 'src/utils';
import defaultLang from 'src/pages/masterList/masterList.lang';
import * as Styled from 'src/pages/masterList/masterList.styled';
import lang from './growthTrend.lang';

const tableLang = defaultLang.table;

/***************
 *   CONFIGS   *
 ***************/

const defaultWidth = {
  small: 80,
  medium: 140,
  large: 200,
  xlarge: 240
};

export const TableColumns = [
  {
    title: <LanguageEntry {...tableLang.main.customer} />,
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
    className: 'aco-font-small',
    sorter: sortByAlph('name'),
    width: defaultWidth.large,
    render: name => <Styled.CustomerName>{name}</Styled.CustomerName>
  },
  {
    title: <LanguageEntry {...tableLang.base.th1} />,
    dataIndex: 'activeUsers',
    key: 'activeUsers',
    width: defaultWidth.small,
    className: 'aco-font-small',
    sorter: sortByNumber('activeUsers')
  },
  {
    title: <LanguageEntry {...lang.table.activeUsersGrowth} />,
    dataIndex: 'activeUsersGrowthPercentage',
    key: 'activeUsersGrowthPercentage',
    width: defaultWidth.large,
    className: 'at_columnActiveUsersGrowthPercentage aco-font-small aco-vertical-middle',
    render: renderGrowth,
    sorter: sortByNumber('activeUsersGrowthPercentage')
  },
  {
    title: <LanguageEntry {...tableLang.base.th2} />,
    dataIndex: 'totalLicenses',
    key: 'totalLicenses',
    width: defaultWidth.small,
    className: 'aco-font-small',
    sorter: sortByNumber('totalLicenses')
  },
  {
    title: <LanguageEntry {...lang.table.licensesGrowth} />,
    dataIndex: 'totalLicensesGrowthPercentage',
    key: 'totalLicensesGrowthPercentage',
    width: defaultWidth.medium,
    className: 'at_columnTotalLicensesGrowthPercentage aco-font-small aco-vertical-middle',
    render: renderGrowth,
    sorter: sortByNumber('totalLicensesGrowthPercentage')
  },
  {
    title: (
      <span>
        <LanguageEntry {...tableLang.base.th5} />
        <Tooltip title={<LanguageEntry {...tableLang.base.tooltip1} />} />
      </span>
    ),
    dataIndex: 'totalMonthlySpend',
    key: 'totalMonthlySpend',
    width: defaultWidth.large,
    className: 'aco-font-small',
    render: renderSpend,
    sorter: sortByNumber('totalMonthlySpend')
  },
  {
    title: <LanguageEntry {...lang.table.spendGrowth} />,
    dataIndex: 'totalMonthlySpendGrowthPercentage',
    key: 'totalMonthlySpendGrowthPercentage',
    width: defaultWidth.medium,
    className: 'at_columnTotalSpendGrowthPercentage aco-font-small aco-vertical-middle',
    render: renderGrowth,
    sorter: sortByNumber('totalMonthlySpendGrowthPercentage')
  },
  {
    title: <LanguageEntry {...lang.table.overallGrowth} />,
    dataIndex: 'totalGrowthPercentage',
    key: 'totalGrowthPercentage',
    width: defaultWidth.medium,
    className: 'at_columntTotalGrowthPercentage aco-font-small aco-vertical-middle',
    render: renderGrowth,
    sorter: sortByNumber('totalGrowthPercentage')
  },
  {
    title: <LanguageEntry {...lang.table.viewGrowthRecommendations} />,
    dataIndex: 'id',
    key: 'id',
    width: defaultWidth.xlarge,
    className: 'aco-font-small',
    render: id => (
      <Styled.VisitLink
        to={{
          pathname: `/${SUBMODULES.OFFICE_365_OPTIMIZER}/${id}/recommendations`,
          state: { prevPath: PATHS.DASHBOARD.ROOT }
        }}
      >
        <LanguageEntry {...lang.table.viewRecommendations} />
      </Styled.VisitLink>
    )
  }
];
