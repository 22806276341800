import React from 'react';
import { langHelper } from '@accordo-feed/micro-frontends-utils';
import { LanguageEntry } from '@accordo-feed/language.entry';

const genProfileId = langHelper.genLangId('shell.aco.profile.accountSecurity');

const genProfileLang = langHelper.genLangOptions(genProfileId);

const defaultMfaDescription = `
  Protect your account with Multi-Factor Authentication (MFA). Each time you sign in to your account, you'll need to enter your password and verify your access via a mobile device.
  <a target="_blank" href="">Learn more</a>
`;

export default {
  heading: genProfileLang('heading', 'Account Security'),
  mfaDescription: <LanguageEntry {...genProfileLang('mfaDescription', defaultMfaDescription)} />,
  mfaWording: genProfileLang('mfaWording', 'Multi-Factor Authentication'),
  mfaEnableButtonText: genProfileLang('mfaEnableButtonText', 'Set up MFA'),
  mfaDisableButtonText: genProfileLang('mfaDisableButtonText', 'Disable MFA'),
  passwordHeading: genProfileLang('passwordHeading', 'Password'),
  passwordDescritpion: genProfileLang(
    'passwordDescrition',
    'The link with instructions will be emailed to your account'
  ),
  mfaEnabledText: genProfileLang('mfaEnabledText', 'On'),
  mfaDisabledText: genProfileLang('mfaDisabledText', 'Off'),
  changePasswordHeading: genProfileLang('changePasswordHeading', 'Password'),
  changePasswordDescription: genProfileLang(
    'changePasswordDescription',
    'The link with instructions will be emailed to your account.'
  ),
  changePasswordButtonText: genProfileLang('changePasswordButtonText', 'Change Password'),
  passwordEmailSentError: genProfileLang(
    'passwordEmailSentError',
    'Change password request is failed, please contact with us.'
  ),
  passwordEmailSentNotification: genProfileLang(
    'passwordEmailSentNotification',
    `We've just sent you an email to reset your password`
  )
};
