// @flow

import React, { useEffect, useState } from 'react';
import createBrowserHistory from 'history/lib/createBrowserHistory';
import { Router, Route, IndexRedirect } from 'react-router';
import { bindActionCreators } from 'redux';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { router } from '@accordo-feed/micro-frontends';
import { useTranslate } from '@accordo-feed/language.entry';

import * as selectors from './redux/selectors';
import AuthError from './pages/authError';
import AzureDashboard from './pages/azureDashboard';
import Billing from './pages/billing';
import CoreLayout from 'src/layouts/CoreLayout';
import Customers from './pages/customers';
import CustomerProfile from './pages/customers/profile';
import AzureRecommendations from './pages/azureRecommendations';
import CustomerLicenseAssignment from './pages/customers/licenseAssignment';
import Dashboard from './pages/dashboard';
import Error404 from './pages/errors/404';
import GrowthTrend from './pages/dashboard/growthTrend';
import Home from './pages/home';
import InviteError from './pages/inviteError';
import LicensePricing from './pages/licensePricing';
import MasterList from './pages/masterList';
import NotificationsSettings from './pages/notificationsSettings';
import Opportunities from './pages/dashboard/opportunities';
import PartnerCenterWelcome from './pages/partnerCenterWelcome';
import PaymentDetails from './pages/paymentDetails';
import PlanOverview from './pages/planOverview';
import Profile from './pages/profile';
import ProductCatalog from './pages/productCatalog';
import SecureApp from './pages/secureApp';
import SecurityScore from './pages/dashboard/securityScore';
import SecurityScoreDetails from './pages/dashboard/securityScore/details';
import UnassignedLicenses from './pages/dashboard/unassignedLicenses';
import checkRouter from './utils/checkRouter';
import loaderHOC from './hoc/loaderHOC';
import { AUTH_ACTIONS, PATHS } from './constants';
import { AzureOptimizer, Office365Optimizer, Psa, TeamInvites } from './submodules';
import { LoginCallback, Auth } from './components/auth';
import { auth, initRouterCommandListeners, iot, removeCommandListeners, segment } from './utils';
import { getConnectionState as getPartnerCenterConnectionState } from './redux/modules/partnerCenter/partnerCenter.duck';
import { getCustomersData } from './pages/customers/customers.duck';
import { getOrganization } from './redux/modules/organization/organization.duck';
import { getUserData } from './redux/modules/userData/userData.duck';
import { showErrorToaster } from './redux/modules/errorModal/errorModal.duck';
import { useReduxStore } from './hooks';
import SettingTheme from './components/settingMenu';
/*************
 *   TYPES   *
 *************/

type AppProps = {
  actions: Object,
  hasAzureOptimizer: boolean,
  hasNotifications: boolean,
  hasSecureApp: boolean,
  hasTeamInvites: boolean,
  isDistributor: boolean,
  isLoading: boolean,
  isOrgLoaded: boolean,
  isPartnerMember: boolean,
  isReady: boolean,
  orgId: ?string,
  orgInfo: Object,
  userInfo: Object
};

/******************
 *   INITIALISE   *
 ******************/

const history = router.getHistory({
  createHistory: createBrowserHistory
});

history.listen(({ pathname }) => segment.page(pathname));

/*****************
 *   COMPONENT   *
 *****************/

const App = ({
  actions,
  hasAzureOptimizer,
  hasNotifications,
  hasSecureApp,
  hasTeamInvites,
  isDistributor,
  isOrgLoaded,
  isPartnerMember,
  isReady,
  orgId,
  orgInfo,
  userInfo
}: AppProps) => {
  const store = useReduxStore();
  const translate = useTranslate();
  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated());

  useEffect(() => {
    removeCommandListeners();
    initRouterCommandListeners(history);

    if (!isAuthenticated) {
      segment.initForGuest();
    }

    return () => {
      removeCommandListeners();
    };
  }, []);

  useEffect(() => {
    if (!isAuthenticated) return;

    if (orgId && orgId !== '') {
      iot.init({ store, orgId, translate });
      actions.getCustomersData();
      actions.getPartnerCenterConnectionState();
    } else {
      actions.getUserData();
    }
  }, [isAuthenticated, orgId]);

  useEffect(() => {
    if (!isAuthenticated) return;

    if (isOrgLoaded) {
      segment.initForUser({
        ...userInfo,
        ...orgInfo
      });
    } else {
      actions.getOrganization();
    }
  }, [isAuthenticated, isOrgLoaded]);

  if (!isReady) return null;

  const ACCOUNTS_PATH = PATHS.ACCOUNTS;
  const CUSTOMERS_PATH = PATHS.CUSTOMERS;
  const DASHBOARD_PATH = PATHS.DASHBOARD;

  const loginCallbackProps = {
    path: PATHS.AUTH_CALLBACK,
    component: LoginCallback,

    // Enable LoginCallback switch the status of isAuthenticated and do the setup for the logged-in user
    isAuthenticated,
    setIsAuthenticated
  };

  const routeHandlers = checkRouter(store);
  const isDev = process.env.NODE_ENV === 'development';

  return (
    <Router history={history}>
      <>
        {isAuthenticated && (
          <Route {...routeHandlers}>
            <Route path={PATHS.PLAN_OVERVIEW} component={PlanOverview} />
            <Route path={PATHS.PAYMENT_DETAILS} component={PaymentDetails} />
            <Route path={PATHS.PARTNER_CENTER_WELCOME} component={PartnerCenterWelcome} />
            {hasSecureApp && <Route path={PATHS.SECURE_APP} component={SecureApp} />}
          </Route>
        )}
      </>
      <Route path={PATHS.HOME} component={Home} />
      <Route path={PATHS.AUTH_ERROR} component={AuthError} />
      <Route component={CoreLayout}>
        <Route path={PATHS.INVITE_ERROR} component={InviteError} />
        <Route path={PATHS.LOGIN} component={() => <Auth action={AUTH_ACTIONS.LOGIN} />} />
        <Route path={PATHS.LOGOUT} component={() => <Auth action={AUTH_ACTIONS.LOGOUT} />} />
        <Route path={PATHS.SIGNUP} component={() => <Auth action={AUTH_ACTIONS.SIGNUP} />} />
        <Route {...loginCallbackProps} />
        {isAuthenticated && (
          <>
            <Route {...routeHandlers}>
              {hasAzureOptimizer && <Route path={`${PATHS.AZURE_OPTIMIZER.ROOT}*`} component={AzureOptimizer} />}
              <Route path={`${PATHS.OFFICE_365_OPTIMIZER.ROOT}*`} component={Office365Optimizer} />
              <Route path={`${PATHS.PSA.ROOT}*`} component={Psa} />
              {hasTeamInvites && !isPartnerMember && (
                <Route path={`${PATHS.TEAM_INVITES.ROOT}*`} component={TeamInvites} />
              )}
              {hasNotifications && (
                <Route path={ACCOUNTS_PATH.NOTIFICATIONS_SETTINGS} component={NotificationsSettings} />
              )}
              {!isPartnerMember && (
                <Route path={ACCOUNTS_PATH.ROOT}>
                  <IndexRedirect to={ACCOUNTS_PATH.PROFILE} />
                  <Route path={ACCOUNTS_PATH.PROFILE} component={Profile} />
                  {!isDistributor && <Route path={ACCOUNTS_PATH.BILLING} component={Billing} />}
                </Route>
              )}
              <Route path={CUSTOMERS_PATH.ROOT} component={Customers} />
              <Route path={CUSTOMERS_PATH.PROFILE} component={CustomerProfile} />
              <Route path={CUSTOMERS_PATH.AZURE_RECOMMENDATIONS} component={AzureRecommendations} />
              <Route path={CUSTOMERS_PATH.LICENSE_ASSIGNMENT} component={CustomerLicenseAssignment} />
              <Route path={DASHBOARD_PATH.ROOT} component={Dashboard} />
              <Route path={DASHBOARD_PATH.UNASSIGNED_LICENSES} component={UnassignedLicenses} />
              <Route path={DASHBOARD_PATH.OPPORTUNITIES} component={Opportunities} />
              <Route path={DASHBOARD_PATH.GROWTH_TREND} component={GrowthTrend} />
              <Route path={PATHS.ALLOCATION_ADOPTION_DASHBOARD} component={MasterList} />
              <Route path={PATHS.LICENSE_PRICING} component={LicensePricing} />
              <Route path={PATHS.SECURITY_SCORE.ROOT} component={SecurityScore} />
              <Route path={PATHS.SECURITY_SCORE.CLIENT} component={SecurityScore} />
              <Route path={PATHS.SECURITY_SCORE.CLIENT_DETAILS} component={SecurityScoreDetails} />
              {isDev && <Route path={PATHS.PRODUCT_CATALOG} component={ProductCatalog} />}
              {isDev && <Route path={PATHS.AZURE_DASHBOARD.ROOT} component={AzureDashboard} />}
            </Route>
            <Route path="*" component={Error404} />
          </>
        )}
      </Route>
      <Route component={SettingTheme} />
      <Route path="*" component={Home} />
    </Router>
  );
};

/*************
 *   REDUX   *
 *************/

const mapStateToProps = state => ({
  hasAzureOptimizer: selectors.hasFeatureAzureOptimizer(state),
  hasError: selectors.organizationHasErrorSelector(state),
  hasNotifications: selectors.hasNotifications(state),
  hasSecureApp: selectors.hasFeatureSecureApp(state),
  hasTeamInvites: selectors.hasFeatureTeamInvites(state),
  isDistributor: selectors.hasFeatureDistributor(state),
  isLoading: selectors.organizationLoadingSelector(state),
  isOrgLoaded: selectors.organizationLoadedSelector(state),
  isPartnerMember: selectors.hasFeaturePartnerMember(state),
  isReady: auth.isAuthenticated()
    ? !!selectors.orgIdSelector(state) && selectors.organizationLoadedSelector(state)
    : true,
  orgId: selectors.orgIdSelector(state),
  orgInfo: selectors.organizationInfoSelector(state),
  userInfo: selectors.userInfoSelector(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getOrganization,
      getUserData,
      showErrorToaster,
      getPartnerCenterConnectionState,
      getCustomersData
    },
    dispatch
  )
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),

  loaderHOC({
    isKeep: true
  })
)(App);
