// @flow

import { connect } from 'react-redux';

import { updatePaymentMethod } from 'src/redux/modules/payment/payment.duck';
import * as selectors from 'src/redux/selectors';
import { CardInfo } from './CardInfo.component';

/*************
 *   TYPES   *
 *************/

type Props = {
  orgId: string,
  cardNum: string
};

/*************
 *   REDUX   *
 *************/

const mapStateToProps = (state: Props) => ({
  orgId: selectors.orgIdSelector(state),
  lastFourDigitsCc: selectors.creditCardPaymentSelector(state)
});

const mapDispatchToProps = {
  updatePaymentMethod
};

export default connect(mapStateToProps, mapDispatchToProps)(CardInfo);
