// @flow

import { createAction } from 'redux-actions';
import * as R from 'ramda';

import type { Action } from 'src/redux/types';
import { wrapWithModule } from 'src/redux/utils';
import type { LoadingStatusType } from 'src/constants';
import { LOADING_STATUS } from 'src/constants';

/*************
 *   TYPES   *
 *************/

export type PaymentMethodResponse = {
  customerId: string,
  hasValidCard: boolean
};

export type PromoCodeResponse = {
  duration: string,
  durationInMonths: number,
  percentOff: number,
  trialPeriodDays: number,
  type: string
};

// Each of the below property represent a screen or UI section
type StateType = {
  planOverview: {
    office365Basic: {
      subscribeToTier: number,
      isAnnual: boolean
    }
  },
  subscriptions: {
    cancelSubscriptionLoadingStatus: LoadingStatusType,
    loadingStatus: LoadingStatusType,
    updateBillingEmailLoadingStatus: LoadingStatusType,
    data: Object
  },
  billingAddressStatus: {
    loadingStatus: LoadingStatusType,
    errors: Array<{
      field: string,
      message: string
    }>
  }
};

/*********************
 *   INITIAL STATE   *
 *********************/

const initialState: StateType = {
  planOverview: {
    office365Basic: {
      subscribeToTier: 0,
      isAnnual: false
    }
  },
  subscriptions: {
    cancelSubscriptionLoadingStatus: LOADING_STATUS.INITIAL,
    loadingStatus: LOADING_STATUS.INITIAL,
    updateBillingEmailLoadingStatus: LOADING_STATUS.INITIAL,
    data: {
      office365Basic: {
        usage: 0,
        tiers: [{}]
      }
    }
  },
  billingAddressStatus: {
    loadingStatus: LOADING_STATUS.INITIAL,
    errors: []
  }
};

/***************
 *   ACTIONS   *
 ***************/

const wrapWithNamespace = wrapWithModule('modules/payment');
const SELECT_OFFICE365_PLAN_TIER = wrapWithNamespace('SELECT_OFFICE365_PLAN_TIER');
const SELECT_OFFICE365_PLAN_ISANNUAL = wrapWithNamespace('SELECT_OFFICE365_PLAN_ISANNUAL');
const SUBMIT_PROMO_CODE = wrapWithNamespace('SUBMIT_PROMO_CODE');
const SUBSCRIBE_MSP_TO_PLAN = wrapWithNamespace('SUBSCRIBE_MSP_TO_PLAN');
const UPDATE_PAYMENT_METHOD = wrapWithNamespace('UPDATE_PAYMENT_METHOD');
const UPDATE_BILLING_EMAIL = wrapWithNamespace('UPDATE_BILLING_EMAIL');
const UPDATE_BILLING_EMAIL_LOADING = wrapWithNamespace('UPDATE_BILLING_EMAIL_LOADING');
const UPDATE_BILLING_EMAIL_LOADED = wrapWithNamespace('UPDATE_BILLING_EMAIL_LOADED');
const GET_SUBSCRIPTION_USAGE = wrapWithNamespace('GET_SUBSCRIPTION_USAGE');
const GET_SUBSCRIPTION_USAGE_LOADING = wrapWithNamespace('GET_SUBSCRIPTION_USAGE_LOADING');
const GET_SUBSCRIPTION_USAGE_LOADED = wrapWithNamespace('GET_SUBSCRIPTION_USAGE_LOADED');
const CANCEL_SUBSCRIPTION = wrapWithNamespace('CANCEL_SUBSCRIPTION');
const CANCEL_SUBSCRIPTION_LOADING = wrapWithNamespace('CANCEL_SUBSCRIPTION_LOADING');
const CANCEL_SUBSCRIPTION_LOADED = wrapWithNamespace('CANCEL_SUBSCRIPTION_LOADED');
const UPDATE_BILLING_ADDRESS = wrapWithNamespace('UPDATE_BILLING_ADDRESS');
const UPDATE_BILLING_ADDRESS_LOADING = wrapWithNamespace('UPDATE_BILLING_ADDRESS_LOADING');
const UPDATE_BILLING_ADDRESS_LOADED = wrapWithNamespace('UPDATE_BILLING_ADDRESS_LOADED');
const UPDATE_BILLING_ADDRESS_FAILED = wrapWithNamespace('UPDATE_BILLING_ADDRESS_FAILED');

export const selectOffice365Tier = createAction(SELECT_OFFICE365_PLAN_TIER);
export const selectOffice365IsAnnual = createAction(SELECT_OFFICE365_PLAN_ISANNUAL);
export const submitPromoCode = createAction(SUBMIT_PROMO_CODE);
export const subscribeMspToPlan = createAction(SUBSCRIBE_MSP_TO_PLAN);
export const updatePaymentMethod = createAction(UPDATE_PAYMENT_METHOD);

export const updateBillingEmail = createAction(UPDATE_BILLING_EMAIL);
export const updateBillingEmailLoading = createAction(UPDATE_BILLING_EMAIL_LOADING);
export const updateBillingEmailLoaded = createAction(UPDATE_BILLING_EMAIL_LOADED);

export const getSubscriptionUsage = createAction(GET_SUBSCRIPTION_USAGE);
export const getSubscriptionUsageLoading = createAction(GET_SUBSCRIPTION_USAGE_LOADING);
export const getSubscriptionUsageLoaded = createAction(GET_SUBSCRIPTION_USAGE_LOADED);

export const cancelSubscription = createAction(CANCEL_SUBSCRIPTION);
export const cancelSubscriptionLoading = createAction(CANCEL_SUBSCRIPTION_LOADING);
export const cancelSubscriptionLoaded = createAction(CANCEL_SUBSCRIPTION_LOADED);

export const updateBillingAddress = createAction(UPDATE_BILLING_ADDRESS);
export const updateBillingAddressLoading = createAction(UPDATE_BILLING_ADDRESS_LOADING);
export const updateBillingAddressLoaded = createAction(UPDATE_BILLING_ADDRESS_LOADED);
export const updateBillingAddressFailed = createAction(UPDATE_BILLING_ADDRESS_FAILED);

/***************
 *   REDUCER   *
 ***************/

export default (state: StateType = initialState, action: Action) => {
  const { type, payload } = action;

  const reducer = {
    [SELECT_OFFICE365_PLAN_TIER]: (state, subscribeToTier) =>
      R.mergeDeepRight(state, {
        planOverview: {
          office365Basic: {
            subscribeToTier
          }
        }
      }),
    [SELECT_OFFICE365_PLAN_ISANNUAL]: (state, isAnnual) =>
      R.mergeDeepRight(state, {
        planOverview: {
          office365Basic: {
            isAnnual
          }
        }
      }),

    [GET_SUBSCRIPTION_USAGE_LOADING]: state =>
      R.mergeDeepRight(state, {
        subscriptions: {
          loadingStatus: LOADING_STATUS.LOADING
        }
      }),

    [GET_SUBSCRIPTION_USAGE_LOADED]: (state, data) =>
      R.mergeDeepRight(state, {
        subscriptions: {
          loadingStatus: LOADING_STATUS.LOADED,
          data
        }
      }),

    [CANCEL_SUBSCRIPTION_LOADING]: state =>
      R.mergeDeepRight(state, {
        subscriptions: {
          cancelSubscriptionLoadingStatus: LOADING_STATUS.LOADING
        }
      }),

    [CANCEL_SUBSCRIPTION_LOADED]: state =>
      R.mergeDeepRight(state, {
        subscriptions: {
          cancelSubscriptionLoadingStatus: LOADING_STATUS.LOADED
        }
      }),

    [UPDATE_BILLING_EMAIL_LOADING]: state =>
      R.mergeDeepRight(state, {
        subscriptions: {
          updateBillingEmailLoadingStatus: LOADING_STATUS.LOADING
        }
      }),

    [UPDATE_BILLING_EMAIL_LOADED]: state =>
      R.mergeDeepRight(state, {
        subscriptions: {
          updateBillingEmailLoadingStatus: LOADING_STATUS.LOADED
        }
      }),

    [UPDATE_BILLING_ADDRESS_LOADING]: state =>
      R.mergeDeepRight(state, {
        billingAddressStatus: {
          loadingStatus: LOADING_STATUS.LOADING,
          errors: []
        }
      }),

    [UPDATE_BILLING_ADDRESS_LOADED]: state =>
      R.mergeDeepRight(state, {
        billingAddressStatus: {
          loadingStatus: LOADING_STATUS.LOADED,
          errors: []
        }
      }),

    [UPDATE_BILLING_ADDRESS_FAILED]: (state, errors) =>
      R.mergeDeepRight(state, {
        billingAddressStatus: {
          loadingStatus: LOADING_STATUS.FAILED,
          errors
        }
      })
  }[type];

  return reducer ? reducer(state, payload) : state;
};
