import { Layout } from 'antd';
import styled from 'styled-components';
import { constants, mixins } from '@accordo-feed/aco-styled-components';

import { setProperty } from 'src/utils';
import { appTextColor, bodyBackground } from 'src/App.styled';

const { Footer: AntFooter } = Layout;
const { fontSize } = constants;

export const footerHeight = '45px';

export const Footer = styled(AntFooter)`
  ${mixins.flexCenter()};
  display: flex;
  align-items: flex-end;
  height: ${footerHeight};
  padding: 0 30px;
  background-color: ${bodyBackground};
`;

export const Wrapper = styled.div`
  position: relative;
  text-align: center;
  color: ${appTextColor};
  font-size: ${fontSize.xSmall};
`;

export const StyledAnchor = styled.a`
  font-size: ${fontSize.xSmall};
  white-space: nowrap;
  ${setProperty('margin')}
  ${setProperty('display')}
`;
