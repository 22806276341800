import styled from 'styled-components';
import { constants, MultipleSelect } from '@accordo-feed/aco-styled-components';

import { hexToRgb } from 'src/utils';
import { appTextColor } from 'src/App.styled';

const { colors, fontSize } = constants;

export const SliderBar = styled.div`
  margin-bottom: 20px;
`;

export const PanelWrapper = styled.div`
  font-size: ${fontSize.xSmall};
`;

export const PanelHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PanelTitle = styled.span`
  color: ${appTextColor};
  font-size: ${fontSize.normal};
`;

export const SliderWrapper = styled.div`
  padding: 20px 0 0;
  display: flex;
  flex-direction: column;
`;

export const FilterWrapper = styled.div`
  padding-bottom: 20px;
`;

export const FilterTitle = styled.div`
  color: ${appTextColor};
  font-size: ${fontSize.small};
  margin-bottom: 10px;
`;

export const Hide = styled.span`
  font-size: ${fontSize.small};
  margin-left: 15px;
  color: ${colors.blue};
  cursor: pointer;
`;

export const ImprovementActionSelect = styled(MultipleSelect)`
  width: 100%;

  &&& .ant-select-selection {
    border-radius: 7px;
    color: ${appTextColor};
    border: 1px solid ${colors.darkGrey6};
    background-color: rgba(${hexToRgb(colors.lightGrey7)}, 0.24);
  }

  .ant-select-selection--multiple {
    .ant-select-selection__choice {
      max-width: 90%;
    }
  }

  .ant-select-selection__clear {
    width: 16px;
    height: 16px;
    font-size: 17px;
    background: ${colors.darkGrey6};
    border-radius: 180px;
    .anticon {
      width: 12px;
      height: 12px;
      font-size: 10px;
      color: ${colors.white};
    }
  }
`;
