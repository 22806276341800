import { langHelper } from '@accordo-feed/micro-frontends-utils';

const genId = langHelper.genLangId('shell.aco.cta.filter');
const genOpts = langHelper.genLangOptions(genId);

export default {
  filterTitle1: genOpts('title1', 'Active users'),
  filterTitle2: genOpts('title2', 'Total monthly spend'),
  tooltip1: genOpts('tooltip1', 'Total monthly spend')
};
