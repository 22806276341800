import React from 'react';
import { ButtonElement } from '@accordo-feed/aco-styled-components';

import { isStatus } from 'src/utils';
import { LOADING_STATUS } from 'src/constants';
import * as Styled from './modal.styled';

export const CustomModal = ({
  isModalOpen,
  closeModal,
  title,
  subTitle,
  description,
  children,
  rightButtonText,
  rightButtonOnClick,
  leftButtonText,
  leftButtonOnClick,
  rightButtonLoadingStatus = LOADING_STATUS.INITIAL,
  customButtons = false,
  ...props
}) => (
  <Styled.ModalContainer
    closable={true}
    visible={isModalOpen}
    centered
    onOk={closeModal}
    onCancel={closeModal}
    footer={null}
    {...props}
  >
    <Styled.ModalTitle>{title}</Styled.ModalTitle>
    {subTitle && subTitle}
    <Styled.ModalBody>
      {description && <Styled.ModalDescription>{description}</Styled.ModalDescription>}
      {children}
    </Styled.ModalBody>

    {!customButtons && (
      <Styled.ModalFooter>
        <ButtonElement
          variant="outline"
          onClick={leftButtonOnClick}
          disabled={isStatus.loading(rightButtonLoadingStatus)}
        >
          {leftButtonText}
        </ButtonElement>
        <ButtonElement
          htmlType="submit"
          margin="0 0 0 15px"
          variant="primary"
          onClick={rightButtonOnClick}
          loading={isStatus.loading(rightButtonLoadingStatus)}
        >
          {rightButtonText}
        </ButtonElement>
      </Styled.ModalFooter>
    )}
  </Styled.ModalContainer>
);
