// @flow

import * as React from 'react';
import { ErrorMessage, Field, connect } from 'formik';
import { useTranslate } from '@accordo-feed/language.entry';

import { InputField, ErrMsg, FieldLabel } from 'src/CustomComponent.styled';
import { lang } from './addressForm.lang';
import { getRelatedError } from './AddressFormWrapper.helpers';
import * as Styled from './addressField.styled';

/*************
 *   TYPES   *
 *************/

type Props = {
  id?: string,
  children?: React.Node,
  label: string,
  name?: string,
  remoteErrorMessage?: string,
  required?: boolean,
  maxLength?: number,
  formik: Object
};

/*****************
 *   COMPONENT   *
 *****************/

const AddressField = ({ id, children, label, maxLength, name = '', required = true, formik }: Props) => {
  const translate = useTranslate();

  const fieldError = getRelatedError(formik.errors, name);

  return (
    <>
      <FieldLabel htmlFor={id}>
        {label} {required || <Styled.OptionalLabel>{translate(lang.optional)}</Styled.OptionalLabel>}
      </FieldLabel>

      {children ? (
        <Field name={name}>{() => children}</Field>
      ) : (
        <Field name={name}>{({ field }) => <InputField maxLength={maxLength} {...field} />}</Field>
      )}
      {fieldError && <ErrorMessage name={name} render={fieldError => <ErrMsg noLeftGap>{fieldError}</ErrMsg>} />}
    </>
  );
};

export default connect(AddressField);
