import { langHelper } from '@accordo-feed/micro-frontends-utils';

const genId = langHelper.genLangId('shell.aco.azureDashboard');
const genOpts = langHelper.genLangOptions(genId);

export default {
  widget: {
    usageTitle: genOpts('usageTitle', 'Azure Usage Monitor'),
    usageSubTitle: genOpts('usageSubTitle', 'Budget Summary'),
    budgetTitle: genOpts('budgetTitle', 'Azure Budget Monitor'),
    budgetSubTitle: genOpts('budgetSubTitle', 'Top 5 | Ranked by % of Budget')
  }
};
