// @flow

import React from 'react';
import queryString from 'query-string';
import { LoadingView } from '@accordo-feed/aco-styled-components';
import { translateHOC } from '@accordo-feed/language.entry';

import lang from './auth.lang';
import { PATHS } from 'src/constants';
import { auth } from 'src/utils';
import { useEffectAsync } from 'src/hooks';
import { isInviteError } from './loginCallback.utils';

/*************
 *   TYPES   *
 *************/

type Props = {
  route: Object,
  router: Object,
  translate: Function
};

/*****************
 *   COMPONENT   *
 *****************/

const LoginCallback = ({ route: { isAuthenticated, setIsAuthenticated }, router, translate }: Props) => {
  const parsedHash = queryString.parse(router.location.hash);
  const { state } = parsedHash;
  // $FlowIgnore
  const parsedState = JSON.parse(state);

  useEffectAsync(async () => {
    if (isAuthenticated) {
      router.replace(parsedState.redirectTo || PATHS.PARTNER_CENTER_WELCOME);
    } else {
      try {
        // Handle expired/deleted member invite
        if (isInviteError(parsedHash)) {
          router.replace(PATHS.INVITE_ERROR);
        } else {
          await auth.handleAuthentication(state);
          setIsAuthenticated(true);
        }
      } catch (err) {
        auth.logout();
      }
    }
  }, [isAuthenticated]);

  return <LoadingView loadingMsg={translate(lang.loading)} />;
};

export default translateHOC(LoginCallback);
