import styled from 'styled-components';
import { constants } from '@accordo-feed/aco-styled-components';

const { fontSize } = constants;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const DropdownWrapper = styled.div`
  position: absolute;
  top: -55px;
  right: 45px;

  && .m365licenses_dropdown {
    .ant-select-selector {
      border: none;
      min-width: 140px;
      font-size: ${fontSize.small};
      background-color: transparent;
    }
  }
`;
