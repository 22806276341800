// @flow

import React from 'react';
import { useTranslate } from '@accordo-feed/language.entry';

import * as Styled from './promotionBanner.styled';
import lang from './promotionBanner.lang';

/*************
 *   TYPES   *
 *************/

type Props = {
  isPlanOverview: boolean
};

/*****************
 *   COMPONENT   *
 *****************/

const PromotionBanner = ({ isPlanOverview = false }: Props) => {
  const translate = useTranslate();

  return (
    <Styled.PromotionContainer isPlanOverview={isPlanOverview}>
      <Styled.PromotionItem>{translate(lang.promotion1)}</Styled.PromotionItem>
      <Styled.PromotionItem>{translate(lang.promotion2)}</Styled.PromotionItem>
    </Styled.PromotionContainer>
  );
};

export default PromotionBanner;
