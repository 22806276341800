import * as R from 'ramda';
import React from 'react';
import { LanguageEntry } from '@accordo-feed/language.entry';

import * as Styled from 'src/pages/masterList/masterList.styled';
import CurrencyWrapper from 'src/components/wrapperComponents/Currency.wrapper';
import { PATHS, SUBMODULES } from 'src/constants';
import { convertBytes, sortByAlph, sortByNumber } from 'src/utils';
import lang from './masterList.lang';
import { roundPercentage } from 'src/utils';

const tableLang = lang.table;

/***************
 *   HELPERS   *
 ***************/

const generatePercentage = num => R.equals(roundPercentage(num), 0) || `${roundPercentage(num)}%`;

export const renderGrowth = num => (
  <Styled.GrowthContentWrapper>
    {generatePercentage(num)}
    <Styled.TableGrowthIndicator className="at_growthIndicator growthIndicator" value={num} />
  </Styled.GrowthContentWrapper>
);

export const renderSpend = num => <CurrencyWrapper value={num} />;

/***************
 *   CONFIGS   *
 ***************/

const defaultWidth = {
  small: 100,
  medium: 130,
  large: 150,
  extra: 180
};

export const generateTableColumns = options => {
  const { columnWidth, enableSort, prevPath } = R.mergeRight(
    {
      columnWidth: defaultWidth,
      enableSort: true
    },
    options
  );

  return [
    {
      title: <LanguageEntry {...tableLang.main.type} />,
      dataIndex: 'organizationType',
      key: 'organizationType',
      fixed: 'left',
      className: 'at_columnTotalorganizationType',
      width: columnWidth.small,
      render: (organizationType, { id }) => (organizationType ? organizationType : ''),
      sorter: enableSort && sortByAlph('organizationType')
    },
    {
      title: <LanguageEntry {...tableLang.main.company} />,
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      className: 'at_columnCompany',
      width: columnWidth.extra,
      sorter: enableSort && sortByAlph('name'),
      render: (name, { id }) => (
        <Styled.VisitLink
          to={{
            pathname: `${PATHS.CUSTOMERS.ROOT}/${id}/`,
            state: { prevPath: prevPath || PATHS.ALLOCATION_ADOPTION_DASHBOARD }
          }}
          className="at__visitO365 aco-text-left"
        >
          <Styled.LinkName>{name}</Styled.LinkName>
        </Styled.VisitLink>
      )
    },
    {
      title: <LanguageEntry {...tableLang.main.base} />,
      className: 'at_columnO365Base',
      children: [
        {
          align: 'center',
          title: <LanguageEntry {...tableLang.allocation.th1} />,
          dataIndex: 'activeUsers',
          key: 'activeUsers',
          width: columnWidth.medium,
          className: 'at_columnActiveUsers',
          sorter: enableSort && sortByNumber('activeUsers'),
          render: (users, { id }) => (
            <Styled.VisitLink
              to={{
                pathname: `/${SUBMODULES.OFFICE_365_OPTIMIZER}/${id}/users`,
                state: { prevPath: prevPath || PATHS.ALLOCATION_ADOPTION_DASHBOARD }
              }}
              className="at__visitO365"
            >
              <Styled.LinkName>{users}</Styled.LinkName>
            </Styled.VisitLink>
          )
        },
        {
          align: 'center',
          title: <LanguageEntry {...tableLang.allocation.th2} />,
          dataIndex: 'totalLicenses',
          key: 'totalLicenses',
          width: columnWidth.small,
          className: 'at_columnTotalLicense',
          sorter: enableSort && sortByNumber('totalLicenses')
        },
        {
          align: 'center',
          title: <LanguageEntry {...tableLang.allocation.th3} />,
          dataIndex: 'unassignedLicenses',
          key: 'unassignedLicenses',
          width: columnWidth.small,
          className: 'at_columnUnassignedLicenses aco-td-light-red',
          sorter: enableSort && sortByNumber('unassignedLicenses')
        },
        {
          align: 'center',
          title: <LanguageEntry {...tableLang.allocation.th4} />,
          dataIndex: 'misassigned',
          key: 'misassigned',
          width: defaultWidth.medium,
          className: 'at_columnMisassigned',
          sorter: enableSort && sortByNumber('misassigned')
        },
        {
          align: 'center',
          title: <LanguageEntry {...tableLang.allocation.th5} />,
          dataIndex: 'allocationPotentialSavings',
          key: 'allocationPotentialSavings',
          width: defaultWidth.large,
          className: 'at_columnPotentialSavings aco-td-light-cyan',
          sorter: enableSort && sortByNumber('allocationPotentialSavings')
        }
      ]
    },
    {
      title: <LanguageEntry {...tableLang.main.adoption} />,
      className: 'at_columnAdoption',
      children: [
        {
          align: 'center',
          title: <LanguageEntry {...tableLang.adoption.th1} />,
          dataIndex: 'inactiveUsers',
          key: 'totalInactive',
          width: columnWidth.large,
          className: 'at_columnTotalInactive',
          sorter: enableSort && sortByNumber('inactiveUsers'),
          render: (inactiveUsers, { id }) => (
            <Styled.VisitLink
              to={{
                pathname: `/${SUBMODULES.OFFICE_365_OPTIMIZER}/${id}/recommendations`,
                state: { prevPath: prevPath || PATHS.ALLOCATION_ADOPTION_DASHBOARD }
              }}
              className="at__visitO365"
            >
              <Styled.LinkName className="aco-light-red">{inactiveUsers}</Styled.LinkName>
            </Styled.VisitLink>
          )
        },
        {
          align: 'center',
          title: <LanguageEntry {...tableLang.adoption.th2} />,
          dataIndex: 'lessActiveUsers',
          key: 'lessActive',
          width: columnWidth.extra,
          className: 'at_columnLessActive',
          sorter: enableSort && sortByNumber('lessActiveUsers'),
          render: (lessActiveUsers, { id }) => (
            <Styled.VisitLink
              to={{
                pathname: `/${SUBMODULES.OFFICE_365_OPTIMIZER}/${id}/recommendations`,
                state: { prevPath: prevPath || PATHS.ALLOCATION_ADOPTION_DASHBOARD }
              }}
              className="at__visitO365"
            >
              <Styled.LinkName>{lessActiveUsers}</Styled.LinkName>
            </Styled.VisitLink>
          )
        },
        {
          align: 'center',
          title: <LanguageEntry {...tableLang.adoption.th4} />,
          dataIndex: 'adoptionPotentialSavings',
          key: 'adoptionPotentialSavings',
          width: columnWidth.large,
          className: 'at_columnPotentialSavings aco-td-light-cyan',
          sorter: enableSort && sortByNumber('adoptionPotentialSavings')
        }
      ]
    },
    {
      title: <LanguageEntry {...tableLang.adoption.th3} />,
      className: 'at_columnStorage',
      children: [
        {
          align: 'center',
          title: <LanguageEntry {...tableLang.storage.th1} />,
          dataIndex: ['storage', 'exchange'],
          key: 'exchange',
          width: columnWidth.small,
          className: 'at_columnExchange',
          sorter: enableSort && sortByNumber('storage', 'exchange'),
          render: e => convertBytes(e)
        },
        {
          align: 'center',
          title: <LanguageEntry {...tableLang.storage.th2} />,
          dataIndex: ['storage', 'sharepoint'],
          key: 'sharepoint',
          width: columnWidth.small,
          className: 'at_columnSharepoint',
          sorter: enableSort && sortByNumber('storage', 'sharepoint'),
          render: e => convertBytes(e)
        },
        {
          align: 'center',
          title: <LanguageEntry {...tableLang.storage.th3} />,
          dataIndex: ['storage', 'onedrive'],
          key: 'onedrive',
          width: columnWidth.small,
          className: 'at_columnOnedrive',
          sorter: enableSort && sortByNumber('storage', 'onedrive'),
          render: e => convertBytes(e)
        }
      ]
    },
    {
      title: <LanguageEntry {...tableLang.main.spend} />,
      className: 'at_columnSpend',
      children: [
        {
          align: 'center',
          title: <LanguageEntry {...tableLang.spend.th1} />,
          dataIndex: 'totalMonthlySpend',
          key: 'totalMonthlySpend',
          width: columnWidth.medium,
          className: 'at_columnTotalMonthlySpend',
          render: renderSpend,
          sorter: enableSort && sortByNumber('totalMonthlySpend')
        },
        {
          align: 'center',
          title: <LanguageEntry {...tableLang.spend.th2} />,
          dataIndex: 'totalAnnualSpend',
          key: 'totalAnnualSpend',
          width: columnWidth.medium,
          className: 'at_columnTotalAnnualSpend',
          render: renderSpend,
          sorter: enableSort && sortByNumber('totalAnnualSpend')
        }
      ]
    },
    {
      title: '',
      className: 'at_columnAction',
      children: [
        {
          align: 'center',
          title: <LanguageEntry {...tableLang.action.th1} />,
          dataIndex: 'totalRecommendations',
          key: 'actions',
          width: columnWidth.small,
          className: 'at_columnAction',
          render: (actions, { id }) => (
            <Styled.VisitLink
              to={{
                pathname: `/${SUBMODULES.OFFICE_365_OPTIMIZER}/${id}/recommendations`,
                state: { prevPath: prevPath || PATHS.ALLOCATION_ADOPTION_DASHBOARD }
              }}
              className="at__visitO365"
            >
              <Styled.LinkName>{actions}</Styled.LinkName>
            </Styled.VisitLink>
          )
        }
      ]
    }
  ];
};
