// @flow

import * as React from 'react';

import { StyledCheckboxGroup } from './CheckboxGroup.styled';

/*************
 *   TYPE   *
 *************/

type CheckboxGroupProps = {
  direction?: 'vertical' | 'horizontal'
};

/*****************
 *   COMPONENT   *
 *****************/

const CheckboxGroup = ({ direction = 'horizontal', ...props }: CheckboxGroupProps) => (
  <StyledCheckboxGroup direction={direction} {...props} />
);

export default CheckboxGroup;
