import * as R from 'ramda';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { redux } from '@accordo-feed/micro-frontends';

import { REDUCER_NAMESPACES, SUBMODULES } from 'src/constants';
import { getSaga } from './saga';
import { default as appStatus } from './modules/appStatus/appStatus.duck';
import { default as currencyOptions } from './modules/currency/currency.duck';
import { default as azureDashboard } from 'src/pages/azureDashboard/azureDashboard.duck';
import { default as customerDrawer } from 'src/pages/customers/customerDrawer/customerDrawer.duck';
import { default as customers } from 'src/pages/customers/customers.duck';
import { default as dashboard } from 'src/pages/dashboard/dashboard.duck';
import { default as errorModal } from './modules/errorModal/errorModal.duck';
import { default as featureRules } from './modules/featureRules/featureRules.duck';
import { default as filters } from './modules/filters/filters.duck';
import { default as menu } from 'src/components/appSider/menu/menu.duck';
import { default as organization } from './modules/organization/organization.duck';
import { default as pages } from 'src/pages/pages.duck';
import { default as partnerCenter } from './modules/partnerCenter/partnerCenter.duck';
import { default as payment } from './modules/payment/payment.duck';
import { default as profile } from 'src/pages/profile/profile.duck';
import { default as searchTerm } from './modules/searchTerm/searchTerm.duck';
import { default as userData } from './modules/userData/userData.duck';
import { default as distiBilling } from './modules/distiBilling/distiBilling.duck';

import { loadRemoteBundle } from 'src/utils';

const appPersistConfig = {
  key: 'appStatus',
  storage,
  blacklist: ['view']
};

const menuPersistConfig = {
  key: 'menu',
  storage
};

const loadReducerBundle = moduleName => loadRemoteBundle(moduleName, 'reducerBundle');

const shellReducers = combineReducers({
  appStatus: persistReducer(appPersistConfig, appStatus),
  currencyOptions,
  customerDrawer,
  customers,
  azureDashboard,
  dashboard,
  errorModal,
  featureRules,
  filters,
  menu: persistReducer(menuPersistConfig, menu),
  organization,
  pages,
  partnerCenter,
  payment,
  profile,
  searchTerm,
  userData,
  distiBilling
});

const getReducers = async () => {
  const submoduleValues = R.values(SUBMODULES);
  const invertedSubmodules = R.invertObj(SUBMODULES);

  const bundles = await Promise.all(submoduleValues.map(loadReducerBundle));

  const structuredBundles = bundles.map((bundle, idx) => ({
    submodule: submoduleValues[idx],
    bundle
  }));

  const reducers = [
    {
      namespace: REDUCER_NAMESPACES.SHELL,
      reducers: shellReducers
    },

    ...structuredBundles.map(({ submodule, bundle }) => ({
      namespace: REDUCER_NAMESPACES[invertedSubmodules[submodule]],
      reducers: bundle
    }))
  ].reduce(
    (acc, { namespace, reducers }) =>
      reducers
        ? {
            ...acc,
            [namespace]: reducers
          }
        : acc,
    {}
  );

  return combineReducers(reducers);
};

export default async () => {
  const rootReducer = await getReducers();
  const rootSaga = await getSaga();
  const store = redux.configureStore({ rootReducer, rootSaga });
  return store;
};
