// @flow

import React from 'react';

import { formatCurrencyHOC } from 'src/hoc';

/*************
 *   TYPES   *
 *************/

type valueType = {
  value: number,
  formatCurrency: Function
};

/*****************
 *   COMPONENT   *
 *****************/

const CurrencyWrapper = ({ value, formatCurrency }: valueType) => <span>{formatCurrency(value)}</span>;

export default formatCurrencyHOC(CurrencyWrapper);
