import styled from 'styled-components';
import { Button } from 'antd';
import { mixins, constants } from '@accordo-feed/aco-styled-components';

import { EntoriLogo } from 'src/components/logo';

const { colors, fontSize } = constants;

export const Container = styled.div`
  display: flex;
  height: 100vh;
`;

const Side = styled.div`
  flex-direction: column;
  ${mixins.flexCenter()}
`;

export const Title = styled.p`
  color: ${colors.darkSkyBlue};
  font-size: ${fontSize.large};
  text-align: center;
`;

export const TitleLink = styled(Button).attrs({
  type: 'link'
})`
  width: 100%;
  color: ${colors.darkSkyBlue};
  font-size: ${fontSize.large};
  text-align: center;
`;

export const Title2 = styled(Title)`
  color: ${colors.white};
  font-size: ${fontSize.medium};
`;

export const ContentText = styled.p`
  color: ${colors.white};
  font-size: ${fontSize.normal};
  line-height: 22px;
  margin-top: 40px;
  text-align: center;
`;

export const ContentText2 = styled(ContentText)`
  color: ${colors.darkBlue};
  margin-top: 10px;
`;

export const LeftSide = styled(Side)`
  background: linear-gradient(170.11deg, ${colors.blackishBlue4} 2.48%, ${colors.deepDarkBlue2} 92.57%);
  width: 45%;

  ${Title} {
    width: 305px;
    margin-bottom: 5px;
  }

  ${ContentText} {
    width: 445px;
  }
`;

export const RightSide = styled(Side)`
  background-color: ${colors.white};
  justify-content: space-between;
  width: 55%;
  overflow-y: auto;

  ${Title} {
    margin: 0;
    margin-bottom: 10px;
  }
`;

export const RightSideContent = styled.div`
  width: 75%;
  padding-top: 120px;
`;

export const Logo = styled(EntoriLogo)`
  position: absolute;
  left: 60px;
  top: 60px;
`;

export const OptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 35px;
`;

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  border: 1px solid ${colors.blackishBlue5};
  border-radius: 18px;
  background-color: ${colors.white};
  padding: 16px 22px 20px;

  &:first-child {
    margin-right: 15px;
  }
`;

export const OptionTitle = styled.span`
  display: block;
  color: ${colors.darkShadeGrey};
  font-size: ${fontSize.xSmall};
  margin-bottom: 5px;
  text-align: center;
`;

export const OptionName = styled.span`
  display: block;
  color: ${colors.darkBlue};
  font-size: ${fontSize.normal};
  text-align: center;
  @media (max-width: 1024px) {
    font-size: ${fontSize.small};
  }
`;

export const Separator = styled.div`
  height: 1px;
  background-color: ${colors.blackishBlue5};
  width: 100%;
  margin: 12px 0;
`;

export const Steps = styled.ul`
  list-style-type: disc;
  padding-left: 15px;
`;

export const Step = styled.li`
  color: ${colors.darkShadeGrey};
  font-size: ${fontSize.xSmall};
  margin-bottom: 14px;
  min-height: 30px;
`;

export const ActionButton = styled(Button).attrs({
  type: 'primary'
})`
  ${mixins.size('82%', '30px')};
  margin-top: auto;
  background: ${colors.darkSkyBlue} !important;
  border-radius: 9px !important;
  color: ${colors.white} !important;
  width: 100%;
`;

export const SkipLink = styled(Button).attrs({
  type: 'link'
})`
  width: 100%;
  height: 40px;
  padding: 0;
  text-align: center;
`;

export const FooterWrapperOuter = styled.div`
  width: 100%;
  position: sticky;
  bottom: 0;
  background-color: ${colors.white};
  padding: 10px;
`;

export const FooterWrapper = styled.div`
  width: 450px;
  margin: 0 auto;
`;
