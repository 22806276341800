import styled from 'styled-components';
import { mixins, constants, ButtonTab, Icon as StyledIcon } from '@accordo-feed/aco-styled-components';
import {
  appTextColor,
  subHeaderTextColor,
  tableEvenRowBackground,
  tableHeaderTextColor,
  tableRowBorderColor,
  widgetBackground
} from 'src/App.styled';

const { colors, fontSize } = constants;

export const PageContainer = styled.div`
  width: 100%;
  padding: 0 24px;
`;

export const TabsContainer = styled.div`
  border-bottom: solid ${tableEvenRowBackground} 10px;
  background: ${widgetBackground};
`;

export const Tab = styled(ButtonTab)`
  ${mixins.size('20%', '65px')}
  font-size: ${fontSize.normal};
  text-align: left;
  color: ${appTextColor};
  border-color: ${props => (props.active ? subHeaderTextColor : tableRowBorderColor)};
  background: ${props => (props.active ? tableEvenRowBackground : 'transparent')};

  && > svg {
    fill: ${props => (props.active ? '' : colors.lightGrey)};
  }
`;

export const Icon = styled(StyledIcon)`
  vertical-align: middle;
  margin-bottom: 0;
  size: '20px';
`;

export const Section = styled.div`
  margin-bottom: 50px;
`;

export const Panel = styled.div`
  padding: 30px 20px 50px;
  color: ${appTextColor};
  background: ${widgetBackground};

  ${/* sc-selector */ Section}:last-child {
    margin-bottom: 0;
  }
`;

export const Table = styled.ul`
  ${mixins.fontSize('small')}

  li {
    ${mixins.flexVerticalCenter()}
    font-size: ${fontSize.small};
    padding: 5px 0;
    border-bottom: 1px solid ${tableRowBorderColor};

    &.hd {
      font-size: ${fontSize.xSmall};
      color: ${tableHeaderTextColor};
    }
  }

  ul {
    padding: 5px 24px 5px 50px;

    li {
      padding: 3px 0;
      border-bottom: none;
    }
  }
`;

export const genTableCell = (widthWeight, textAlign = 'left') => styled.div`
  flex: ${widthWeight};
  text-align: ${textAlign};
  margin-right: 10px;
`;

export const genTableCellSaving = (widthWeight, textAlign = 'left') => styled.div`
  flex: ${widthWeight};
  text-align: ${textAlign};
  margin-right: 30px;
`;

const PRIORITY_COLORS = {
  high: colors.alertRed,
  medium: colors.alertYellow,
  low: colors.alertBlue
};

export const Priority = styled.span`
  font-size: ${fontSize.small};
  display: inline-block;
  padding: 2px 4px;
  min-width: 80px;
  border-radius: 12px;
  text-align: center;
  color: ${colors.whiteColor};
  background: ${props => PRIORITY_COLORS[props.level]};
  text-transform: capitalize;
`;

export const Container = styled.div`
  ${mixins.flexVerticalCenter()}
  margin-bottom: 5px;
`;
