// @flow

import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { command } from '@accordo-feed/micro-frontends';

import * as actions from './partnerCenter.duck';
import * as apiCalls from './partnerCenter.api';
import * as errorModalActions from 'src/redux/modules/errorModal/errorModal.duck';
import * as selectors from 'src/redux/selectors';
import { PATHS, COMMAND_NAVIGATE_TYPE } from 'src/constants';
import { customerUtils } from 'src/pages/customers/customers.utils';

/*************
 *   SAGAS   *
 *************/

function* getConnectionState(): any {
  yield put(actions.setLoading(true));

  const orgId = yield select(selectors.orgIdSelector);

  try {
    const state = yield apiCalls.getConnectionState(orgId);
    yield put(actions.getConnectionStateSuccess(state));
  } catch (err) {
    yield put(actions.setLoading(false));
  }
}

function* connectPartnerCenter(): any {
  yield put(actions.setLinking(true));

  const newWindow = customerUtils.generateLoadingNewWindow('Sign in to Microsoft partner center');
  const orgId = yield select(selectors.orgIdSelector);
  const login = yield apiCalls.getConnectionLink(orgId);

  customerUtils.redirectNewWindow(newWindow, login.uri);
}

function* connectPartnerCenterSuccessfulSaga({ payload: { message, title } }): any {
  // TODO: refactor, create success toaster, or abstract it away from error
  yield put(errorModalActions.showErrorToaster({ message, title, notificationType: 'success' }));
  yield put(actions.getConnectionState());

  // Use `replace` to prevent going back to the previous route
  yield call(command.navigateTo, {
    path: PATHS.CUSTOMERS.ROOT,
    method: COMMAND_NAVIGATE_TYPE.REPLACE
  });
}

function* handleConnectionFailed({ payload: { errorCode, errorMessage } }): any {
  yield put(actions.setConnectionFailed(true));
  yield put(errorModalActions.showErrorToaster({ message: errorMessage, errorCode }));
}

export default function*(): any {
  yield all([
    fork(takeLatest, actions.getConnectionState, getConnectionState),
    fork(takeLatest, actions.connectPartnerCenter, connectPartnerCenter),
    fork(takeLatest, actions.connectPartnerCenterSuccessful, connectPartnerCenterSuccessfulSaga),
    fork(takeLatest, actions.handleConnectionFailed, handleConnectionFailed)
  ]);
}
