// @flow

import React from 'react';
import { AreaChartOutlined } from '@ant-design/icons';
import { Menu, Popover, Tooltip } from 'antd';
import { Search as SearchIcon } from '@material-ui/icons';

import * as Styled from './menu.styled';
import { PATHS } from 'src/constants';
import { lang } from './shellMenu.lang';

const { SubMenu } = Menu;
const { AZURE_DASHBOARD, CUSTOMERS, DASHBOARD, PSA, SECURITY_SCORE, LOGOUT, PRODUCT_CATALOG } = PATHS;

const mockAzureOptimizerPath = '/azure-optimizer/acc_8ad750dc-b6f2-4e30-99a6-ca04b2d397c5/';

/*************
 *   TYPES   *
 *************/

type MenuProps = {
  userInitial: string,
  userInfo: Object,
  hasAzureOptimizer: boolean,
  hasTeamInvites: boolean,
  hasNotifications: boolean,
  isDistributor: boolean,
  isPartnerMember: boolean,
  location: Object,
  onClick: Function,
  onTitleClick: Function,
  subMenuOpenKeys: Array<string>,
  customers: Array<Object>,
  collapsed: boolean,
  org: Object
};

/**************
 *   HELPER   *
 **************/

const getSelectedKeys = location => {
  const r = [];
  const { pathname } = location;

  if (pathname.startsWith(PATHS.DASHBOARD.ROOT)) {
    r.push(PATHS.DASHBOARD.ROOT);
  } else if (pathname.startsWith(PATHS.PSA.ROOT)) {
    r.push(PATHS.PSA.ROOT);
  } else {
    r.push(pathname);
  }

  return r;
};

/*****************
 *   COMPONENT   *
 *****************/

export default ({
  userInitial,
  userInfo,
  hasAzureOptimizer,
  hasTeamInvites,
  hasNotifications,
  isDistributor,
  isPartnerMember,
  location,
  onClick,
  onTitleClick,
  subMenuOpenKeys,
  customers,
  collapsed,
  org: { data: organization }
}: MenuProps) => {
  const handleClickMenuItem = e => {
    e.domEvent.stopPropagation();
  };

  const renderExpandIcon = ({ eventKey }) => {
    return (
      <Styled.ExpandMoreIcon
        collapsed={collapsed ? 1 : 0}
        onClick={ev => {
          ev.stopPropagation();
          onTitleClick({ key: eventKey });
        }}
      />
    );
  };

  const isDev = process.env.NODE_ENV === 'development';

  return (
    <Styled.SidebarWrapper>
      <Styled.WithoutToggleLogo onClick={onClick(DASHBOARD.ROOT)}>
        {collapsed ? <Styled.LogoIcon /> : <Styled.FullLogoIcon />}
      </Styled.WithoutToggleLogo>

      <Styled.WithoutToolTipPopOverContainer>
        <Popover
          overlayClassName="popupThemeColor"
          content={
            <Styled.LogoutButton onClick={onClick(LOGOUT)}>
              <Styled.LogoutIcon />
              {lang.logout}
            </Styled.LogoutButton>
          }
          title={
            <Styled.UserInfoContainer>
              <p>{`${userInfo.firstName} ${userInfo.lastName}`}</p>
              <p>{userInfo.email}</p>
            </Styled.UserInfoContainer>
          }
          align={{ points: ['tl', 'tr'] }}
        >
          <Styled.UserAvatar size={42}>{userInitial}</Styled.UserAvatar>
        </Popover>
      </Styled.WithoutToolTipPopOverContainer>

      <Styled.SidebarMenu
        onClick={handleClickMenuItem}
        expandIcon={renderExpandIcon}
        selectedKeys={getSelectedKeys(location)}
        openKeys={subMenuOpenKeys}
      >
        <Styled.GlobalStyle />

        <Styled.StaticMenuGroup>
          <SubMenu
            className="at_acoSubMenu"
            key={DASHBOARD.ROOT}
            onTitleClick={onClick(DASHBOARD.ROOT)}
            title={
              <Tooltip
                placement="right"
                overlayClassName={collapsed ? 'tooltipMenuToggleCollapsed' : 'tooltipMenuToggleNonCollapsed'}
                title={lang.hub365}
              >
                <Styled.DashboardIcon />
                <span>{lang.hub365}</span>
              </Tooltip>
            }
          >
            <Menu.Item key={DASHBOARD.OPPORTUNITIES} onClick={onClick(DASHBOARD.OPPORTUNITIES)}>
              <span>{lang.recommendations}</span>
            </Menu.Item>

            <Menu.Item key={DASHBOARD.GROWTH_TREND} onClick={onClick(DASHBOARD.GROWTH_TREND)}>
              <span>{lang.clientGrowth}</span>
            </Menu.Item>

            <Menu.Item key={SECURITY_SCORE.ROOT} onClick={onClick(SECURITY_SCORE.ROOT)}>
              <span>{lang.secureScoreIndex}</span>
            </Menu.Item>

            <Menu.Item key={DASHBOARD.UNASSIGNED_LICENSES} onClick={onClick(DASHBOARD.UNASSIGNED_LICENSES)}>
              <span>{lang.unassignedLicenses}</span>
            </Menu.Item>

            <Menu.Item key={PSA.ROOT} onClick={onClick(PSA.ROOT)}>
              <span>{lang.psaBillingErrors}</span>
            </Menu.Item>
          </SubMenu>

          {/* FIXME: The azure optimizer menu is added for testing purpose only,
          and it needs to be removed for production later on. */}
          {hasAzureOptimizer && (
            <Menu.Item
              className="at_acoMenuItem"
              key={mockAzureOptimizerPath}
              onClick={onClick(mockAzureOptimizerPath)}
            >
              <AreaChartOutlined />
              <span>Azure Optimizer</span>
            </Menu.Item>
          )}

          <SubMenu
            className="at_acoSubMenu"
            key={CUSTOMERS.ROOT}
            onTitleClick={onClick(CUSTOMERS.ROOT)}
            title={
              <Tooltip
                placement="right"
                overlayClassName={collapsed ? 'tooltipMenuToggleCollapsed' : 'tooltipMenuToggleNonCollapsed'}
                title={lang.companies}
              >
                <Styled.CompaniesIcon />
                {lang.companies}
                <Styled.SearchButton
                  collapsed={collapsed ? 1 : 0}
                  onClick={ev => {
                    ev.stopPropagation();
                    onClick(CUSTOMERS.ROOT)();
                  }}
                >
                  <SearchIcon />
                </Styled.SearchButton>
              </Tooltip>
            }
          >
            {customers.map(customer => {
              return (
                <Menu.Item key={customer.id} onClick={onClick(`/companies/${customer.id}`)}>
                  {customer.name}
                </Menu.Item>
              );
            })}
          </SubMenu>

          {isDev && (
            <Menu.Item className="at_acoMenuItem" key={PRODUCT_CATALOG} onClick={onClick(PRODUCT_CATALOG)}>
              <Styled.AddProductCatalogIcon />
              <span>{lang.productCatalog}</span>
            </Menu.Item>
          )}

          {isDev && (
            <Menu.Item className="at_acoMenuItem" key={AZURE_DASHBOARD.ROOT} onClick={onClick(AZURE_DASHBOARD.ROOT)}>
              <Styled.AzureIcon />
              <span>{lang.azureDashboard}</span>
            </Menu.Item>
          )}
        </Styled.StaticMenuGroup>
      </Styled.SidebarMenu>
    </Styled.SidebarWrapper>
  );
};
