import React from 'react';
import { constants } from '@accordo-feed/aco-styled-components';
import { renderSpend } from 'src/pages/masterList/masterList.config';
import {
  BarContainer,
  BarLine,
  ProgressInfoWrapper,
  PercentValue,
  ProgressWrapper,
  StyledProgress,
  ProgressTitle,
  ProgressValue
} from './azureBudget.styled';

const { colors } = constants;

/*************
 *   TYPES   *
 *************/

type BudgetProgressProps = {
  percent: number,
  max: number,
  linePosition: number
};

type BudgetInfoProps = {
  title: string,
  value: number,
  percentPosition: number
};

/*****************
 *   COMPONENT   *
 *****************/

export const BudgetProgress = (props: BudgetProgressProps) => {
  // 1.35: max percent is 135%
  // 74: when max percent is 135%, progress width is 74%
  // minimum value of max percent is 135%
  const { linePosition, max, percent } = props;
  const calcPercent = max > 1.35 ? percent / max : percent / 1.35;
  const progressColor = percent > 100 ? colors.alertRed : percent > 50 ? colors.lightBlue : colors.lightGreen;
  return (
    <BarContainer>
      <ProgressWrapper>
        <StyledProgress color={progressColor} percent={calcPercent} />
        <BarLine position={`${linePosition}%`} />
        <PercentValue>{`${percent}%`}</PercentValue>
      </ProgressWrapper>
    </BarContainer>
  );
};

export const BudgetInfo = (props: BudgetInfoProps) => {
  const { percentPosition, title, value } = props;
  const position = `calc(${percentPosition}% - 10px)`;
  return (
    <ProgressInfoWrapper>
      <ProgressTitle>{title}</ProgressTitle>
      <ProgressValue position={position}>{renderSpend(value)}</ProgressValue>
    </ProgressInfoWrapper>
  );
};
