import { langHelper } from '@accordo-feed/micro-frontends-utils';

const genId = langHelper.genLangId('shell.aco.masterList.allocation');
const genOpts = langHelper.genLangOptions(genId);

export default {
  widgetTitle: genOpts('title', 'Allocation'),
  widgetSubTitle: genOpts('subTitle', 'M365 allocation by Company'),
  table: {
    main: {
      company: genOpts('th.company', 'Company'),
      licenses: genOpts('th.licenses', 'Licenses'),
      flagged: genOpts('th.flagged', 'Flagged'),
      savings: genOpts('th.savings', 'Savings')
    },
    base: {
      th1: genOpts('base.th1', ''),
      th2: genOpts('base.th2', 'Users'),
      th3: genOpts('base.th3', 'Subscriptions'),
      th4: genOpts('base.th4', 'Licenses'),
      th5: genOpts('base.th5', 'Spend'),
      th6: genOpts('base.th6', 'Mis-Assigned'),
      th7: genOpts('base.th7', 'Unassigned'),
      th8: genOpts('base.th8', 'Expiring'),
      th9: genOpts('base.th9', 'Unassigned'),
      th10: genOpts('base.t10', 'Mis-Assigned'),
      tooltip1: genOpts('base.tooltip1', 'Count of active licensed users in azure active directory'),
      tooltip2: genOpts(
        'base.tooltip2',
        'Misassigned" are subscriptions that have been assigned to deactivated users.'
      ),
      tooltip3: genOpts('base.tooltip3', 'Annual spend'),
      tooltip4: genOpts('base.tooltip4', 'Annual savings')
    }
  }
};
