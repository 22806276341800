import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import uuidV4 from 'uuid/v4';
import { WidgetBodyInner } from 'src/CustomComponent.styled';
import { StyledTable } from './SimpleTable.styled';

/*****************
 *   COMPONENT   *
 *****************/

export default (props: Object) => {
  const widgetRef = useRef(null);
  const [height, setHeight] = useState(null);
  const { dataSource, pageSize = 20, x, tableHeight, showScroll = true, showSizeChanger } = props;

  useEffect(() => {
    if (widgetRef?.current?.clientHeight) {
      const scrollHeight = 5;
      const tableNode = ReactDOM.findDOMNode(widgetRef.current) ?? {};
      const tableHeader = tableNode.getElementsByClassName('ant-table-thead')[0];
      const tableBody = tableNode.getElementsByClassName('ant-table-tbody')[0];
      const tablePagination = tableNode.getElementsByClassName('ant-table-pagination')[0];
      const tableHeaderHeight = tableHeader?.clientHeight ?? 0;
      const tablePaginationHeight = tablePagination?.clientHeight ?? 0;
      const tableBodyHeight = widgetRef.current.clientHeight - scrollHeight - tableHeaderHeight - tablePaginationHeight;
      if (tableBody?.clientHeight && tableBodyHeight > tableBody?.clientHeight) {
        setHeight(null);
      } else {
        setHeight(tableBodyHeight);
      }
    }
  }, []);

  const options = {
    pagination: {
      hideOnSinglePage: true,
      position: ['none', 'bottomCenter'],
      showSizeChanger: showSizeChanger ?? false,
      total: dataSource.length,
      defaultPageSize: pageSize,
      defaultCurrent: 1
    },
    scroll: dataSource.length > 0 && showScroll ? { x: x, y: tableHeight ? tableHeight : height } : {},
    ...props
  };

  return (
    <WidgetBodyInner ref={widgetRef}>
      <StyledTable rowKey={e => e?.key || e?.id || uuidV4()} {...options} />
    </WidgetBodyInner>
  );
};
