import React, { useState } from 'react';
import styled from 'styled-components';
import { ChatBubbleOutline, CheckCircle } from '@material-ui/icons';
import { Input } from 'antd';
import { mixins, constants } from '@accordo-feed/aco-styled-components';

import { PROMOTION_CODE } from 'src/constants';
import { Row, Column } from './paymentDetails.styled';
import { setProperty } from 'src/utils';

const { colors } = constants;

export const PlainDetailBox = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: 106px 36px 36px 36px;
`;

export const Line = styled.div`
  border-top: 1px ${({ isDashed }) => (isDashed ? 'dashed' : 'solid')} ${colors.lightGrey};
  ${setProperty('margin')}
`;

const PlanIcon = styled(ChatBubbleOutline)`
 ${mixins.size('32px')}
 color: ${colors.darkGrey};
 margin-right: 24px;
 transform: scaleX(-1);
`;

const PlanDescPrice = styled.div`
  color: ${colors.darkGrey};
`;

export const PlanDesc = ({ title, price }) => (
  <Row
    padding="16px 24px"
    css={{
      alignItems: 'center'
    }}
  >
    <PlanIcon />
    <Column>
      <div>{title}</div>
      <PlanDescPrice>{price}</PlanDescPrice>
    </Column>
  </Row>
);

export const PriceItem = ({ left, right }) => (
  <Row justifyContent="space-between" width="100%">
    <div>{left}</div>
    <div>{right}</div>
  </Row>
);

const GreenTick = styled(CheckCircle)`
  ${mixins.size('20px !important')};
  color: ${colors.shadeGreen};
  margin-right: 8px;
`;

export const CouponDetails = ({ text }) => (
  <Row align-items="center" margin="8px 0 0 0">
    <GreenTick />
    {text}
  </Row>
);

const StyledApplyButton = styled.span`
  display: inline-block;
  color: ${colors.darkGrey};
  cursor: pointer;
  font-size: 14px;
  margin-top: 16px;
  text-align: center;
  text-decoration: underline;
`;

export const ApplyCouponButton = ({ text, onClick }) => <StyledApplyButton onClick={onClick}>{text}</StyledApplyButton>;

const StyledInput = styled(Input)`
  && {
    width: 200px;
    height: 35px;
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const getButtonStyle = coupon =>
  coupon.length > 0
    ? `color: ${colors.lightBlue}; border: 1px solid; ${colors.lightBlue}; cursor: pointer;`
    : `color: ${colors.lightGrey1}; border: 1px solid ${colors.lightGrey}; `;

const StyledButton = styled.button`
  border-radius: 3px;
  padding: 4px 12px;
  margin-left: 16px;
`;

export const CouponInput = ({ text, onButtonClick, isInPromotion }) => {
  const [coupon, setCoupon] = useState(isInPromotion ? PROMOTION_CODE : '');
  const buttonStyle = getButtonStyle(coupon);

  return (
    <Row margin="16px 0 0 0">
      <StyledInput
        value={coupon}
        onChange={e => {
          e.preventDefault();
          setCoupon(e.currentTarget.value.toUpperCase());
        }}
      />
      <StyledButton css={buttonStyle} onClick={() => onButtonClick(coupon)}>
        {text}
      </StyledButton>
    </Row>
  );
};

const MspDiv = styled.div`
  text-align: right;
`;
export const Quote = ({ body, name, company }) => (
  <Column padding="24px">
    <p>{body}</p>
    <MspDiv>
      <b>{name}</b>
    </MspDiv>
    <MspDiv>{company}</MspDiv>
  </Column>
);
