import auth0 from 'auth0-js';
import queryString from 'query-string';

import segment from './segment';
import { AUTH_ACTIONS, PATHS, STORAGE_KEYS } from 'src/constants';
import { getConfigValue } from 'src/config';
import { getLocale } from './lang';
import { isEmptyOrNil } from './ramda';

declare var __DEPLOYMENT_ENV__: string;

const { location } = window;

const getUrlParam = key => {
  const params = queryString.parse(location.search);
  return { [key]: params[key] };
};

export const REDIRECT_TO_PARAM = 'redirectTo';

// For reference
// https://auth0.com/docs/quickstart/spa/react/01-login
class Auth {
  auth0 = new auth0.WebAuth(getConfigValue('auth0'));

  params = {
    env: __DEPLOYMENT_ENV__ || 'local',
    locale: getLocale()
  };

  tokenRenewalTimeout = null;

  constructor() {
    this.renewStorage();

    if (this.expiresAt && this.idToken) {
      this.scheduleRenewal();
    }
  }

  // The `state` needs to be passed-in for the verification of it's the same value as it's generated before,
  // which prevents CSRF attacks. Read more:
  // https://auth0.com/docs/libraries/auth0js/v9#extract-the-authresult-and-get-user-info
  handleAuthentication = state =>
    new Promise((resolve, reject) => {
      this.auth0.parseHash(
        {
          state
        },
        (err, authResult) => {
          if (err || !authResult || !authResult.idToken) {
            return reject(err);
          }
          this.setSession(authResult);
          resolve();
        }
      );
    });

  setSession = authResult => {
    // Set the time that the Access Token will expire at
    this.expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
    this.accessToken = authResult.accessToken;
    this.idToken = authResult.idToken;

    // Set user token in localStorage
    localStorage.setItem(STORAGE_KEYS.USER_TOKEN, this.idToken);
    localStorage.setItem(STORAGE_KEYS.USER_TOKEN_EXPIRES_AT, this.expiresAt);
  };

  renewSession = () => {
    this.auth0.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
      } else if (err) {
        this.logout();
      }
    });
  };

  renewStorage() {
    this.expiresAt = localStorage.getItem(STORAGE_KEYS.USER_TOKEN_EXPIRES_AT);
    this.idToken = localStorage.getItem(STORAGE_KEYS.USER_TOKEN);
  }

  scheduleRenewal() {
    const timeout = this.expiresAt - new Date();
    if (timeout > 0) {
      this.tokenRenewalTimeout = setTimeout(() => {
        this.renewSession();
      }, timeout);
    }
  }

  logout = () => {
    // Remove tokens and expiry time
    this.accessToken = null;
    this.idToken = null;
    this.expiresAt = 0;

    clearTimeout(this.tokenRenewalTimeout);

    // Remove user token from localStorage
    localStorage.removeItem(STORAGE_KEYS.USER_TOKEN);
    localStorage.removeItem(STORAGE_KEYS.USER_TOKEN_EXPIRES_AT);

    this.auth0.logout({
      returnTo: `${location.origin}${PATHS.LOGIN}`
    });

    segment.reset();
  };

  login = (type = AUTH_ACTIONS.LOGIN) => {
    const redirectTo = getUrlParam(REDIRECT_TO_PARAM);

    const state = JSON.stringify({
      ...redirectTo
    });

    this.auth0.authorize({
      type,
      state,
      ...this.params,
      ...getUrlParam('disti'),
      ...getUrlParam('invite')
    });
  };

  signUp = () => {
    this.login(AUTH_ACTIONS.SIGNUP);
  };

  isAuthenticated = () => Date.now() < this.expiresAt && !isEmptyOrNil(this.idToken) && !isEmptyOrNil(this.expiresAt);

  changePassword = (email, callback) => {
    this.auth0.changePassword(
      {
        connection: 'Username-Password-Authentication',
        email
      },
      (err, resp) => callback({ err, resp })
    );
  };
}

const auth = new Auth();

/**
 * React Router onEnter hook for protected routes
 * @param _ or "nextState" if parameter used
 * @param __ or "replace" if parameter used
 * @param callback
 */
export const checkAuth = (_, __, callback) => {
  auth.renewStorage();
  return !auth.isAuthenticated() ? auth.logout() : callback();
};

export default auth;
