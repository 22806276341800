// @flow

import React from 'react';
import { useTranslate } from '@accordo-feed/language.entry';

import SimpleTable from 'src/components/simpleTable';
import * as Styled from 'src/pages/dashboard/ctaLayout/ctaLayout.styled';
import CtaLayout from 'src/pages/dashboard/ctaLayout';
import Filters from 'src/pages/dashboard/ctaLayout/Filters.container';
import UnassignedLicenses from 'src/pages/dashboard/widgets/unassignedLicenses';
import { WidgetCardWrapper } from 'src/components/widget/widget.styled';
import { Elipse } from 'src/pages/dashboard/widgets/unassignedLicenses/UnassignedLicenses.component';
import { TableColumns as columns } from './unassignedLicenses.config';
import lang from './unassignedLicenses.lang';

/*************
 *   TYPES   *
 *************/

type Props = {
  actions: {
    setUnassignedLicensesActiveUserFilter: Function,
    setUnassignedLicensesTotalSpendFilter: Function
  },
  dataSource: Array<Object>,
  originalDataSource: Array<Object>,
  filterData: Object
};

/*****************
 *   COMPONENT   *
 *****************/

const UnassignedLicensesComp = ({ actions, dataSource, originalDataSource, filterData }: Props) => {
  const translate = useTranslate();

  const isDataSource = dataSource[0] && dataSource[0].item;
  const tableSource = isDataSource ? dataSource.map(e => ({ ...e.item, refIndex: e.refIndex })) : dataSource;

  const tableProps = {
    dataSource: tableSource,
    columns,
    pageSize: Infinity,
    tableHeight: `calc(100vh - 250px)`,
    x: 1400
  };

  const tableContent = (
    <WidgetCardWrapper>
      <SimpleTable {...tableProps} />
    </WidgetCardWrapper>
  );

  const activeUserSliderProps = {
    range: 'range',
    min: filterData.minActiveUsers,
    max: filterData.maxActiveUsers,
    value: [filterData.selectedMinActiveUsers, filterData.selectedMaxActiveUsers],
    onChange: actions.setUnassignedLicensesActiveUserFilter
  };

  const totalSpendSliderProps = {
    range: 'range',
    min: filterData.minTotalSpend,
    max: filterData.maxTotalSpend,
    value: [filterData.selectedMinTotalSpend, filterData.selectedMaxTotalSpend],
    onChange: actions.setUnassignedLicensesTotalSpendFilter
  };

  const filterProps = {
    filterData,
    originalDataSource,
    activeUserSliderProps,
    totalSpendSliderProps
  };

  const elipse: Elipse = {
    elipseLongName: true,
    elipseLongWidth: '360px',
    elipseShortWidth: '298px'
  };

  const content = {
    className: 'at_unassignedLicensesPage',
    title: translate(lang.pageTitle),
    widget: (
      <Styled.WidgetWrapper>
        <UnassignedLicenses elipse={elipse} viewMoreAction={false} />
      </Styled.WidgetWrapper>
    ),
    description: '',
    filters: <Filters {...filterProps} />,
    tableContent
  };

  return <CtaLayout {...content} />;
};

export default UnassignedLicensesComp;
