import React, { forwardRef, useState } from 'react';
import * as R from 'ramda';

import { byInputValue } from './dropdown.utils';
import { StyledSelect } from './dropdown.styled';

const { Option } = StyledSelect;

/*****************
 *   COMPONENT   *
 *****************/

const Dropdown = forwardRef(
  (
    {
      data = [],
      defaultValue = '',
      placeholder = 'Select',
      size = 'large',
      type = '',
      path = [],
      showSearch = true,
      ...props
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState();

    const getOption = e => {
      return path && !R.isEmpty(path) ? R.path(path, e) : e;
    };

    return (
      <StyledSelect
        ref={ref}
        size={size}
        showSearch={showSearch}
        filterOption={false}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onSearch={showSearch && setInputValue}
        notFoundContent={null}
        {...props}
      >
        {data.filter(byInputValue(inputValue, getOption)).map((e, index) => {
          const option = getOption(e);
          return (
            <Option value={option} key={index}>
              {option}
            </Option>
          );
        })}
      </StyledSelect>
    );
  }
);

export default Dropdown;
