import styled from 'styled-components';
import { mixins, constants } from '@accordo-feed/aco-styled-components';

import CheckIconSvg from 'src/images/check_circle_blue.svg';
import { FullLogo } from 'src/components/logo';

const { colors, fontSize } = constants;

export const Container = styled.div`
  ${mixins.flexCenter()};
  flex: 2;
  height: 100vh;
  min-width: 390px;
  min-height: 500px;
  flex-direction: column;
  position: relative;
  background: linear-gradient(150.5deg, ${colors.deepDarkBlue1} 2.42%, ${colors.deepDarkBlue} 105.93%);
`;

export const Logo = styled(FullLogo).attrs({
  size: 'large'
})`
  position: absolute;
  top: 120px;
  width: 40%;
  min-width: 300px;
  max-width: 350px;
`;

export const Title = styled.p`
  font-size: ${fontSize.medium};
  letter-spacing: 0.24px;
  color: ${colors.white};
  margin-bottom: 45px;
  max-width: 360px;

  span {
    color: ${colors.cyan};
  }
`;

export const Content = styled.ul`
  color: ${colors.darkShadeGrey};
  margin-bottom: -25px;
  max-width: 360px;

  li {
    display: flex;
    min-height: 70px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Info = styled.div`
  width: calc(100% - 34px);
  color: ${colors.white};
  font-size: ${fontSize.small};
`;

export const CheckIcon = styled(CheckIconSvg)`
  margin-right: 10px;
`;
