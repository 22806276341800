import styled from 'styled-components';
import { Table } from 'antd';
import { constants } from '@accordo-feed/aco-styled-components';
import {
  appLabelColor,
  appTextColor,
  paginationItemActiveColor,
  paginationItemBackgroundActiveColor,
  paginationItemBackgroundColor,
  paginationItemTextColor,
  scrollbarBackground,
  scrollbarHandlerColor,
  tableEvenRowBackground,
  tableHeaderBorder,
  tableHeaderRowBackground,
  tableHeaderTextColor,
  tableOddRowBackground,
  tableRowHoverColor
} from 'src/App.styled';
import { hexToRgb } from 'src/utils';

const { colors, fontSize } = constants;

export const StyledTable = styled(Table)`
  height: 100%;
  overflow: hidden;

  .ant-pagination-options {
    .ant-select {
      .ant-select-selector {
        border-radius: 7px;
        color: ${appTextColor};
        border: 1px solid ${colors.darkGrey6};
        background-color: rgba(${hexToRgb(colors.lightGrey7)}, 0.24);
      }
      .ant-select-arrow {
        svg {
          fill: ${appTextColor};
        }
      }
    }
  }

  .ant-table {
    background: none;

    .ant-table-container {
      table {
        border-radius: 0;
        border-collapse: collapse;
      }

      .ant-table-thead {
        th {
          background: ${tableHeaderRowBackground};
          color: ${tableHeaderTextColor};
          padding: 10px 15px;
          font-size: ${fontSize.xSmall};
          vertical-align: middle;
          white-space: nowrap;
          border: 1px solid ${tableHeaderBorder};

          .ant-table-column-sorters {
            .ant-table-column-sorter {
              visibility: hidden;
            }
            &:hover {
              .ant-table-column-sorter {
                visibility: visible;
              }
            }
          }
        }
      }

      .ant-table-body {
        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
          background-color: ${scrollbarBackground};
        }

        &::-webkit-scrollbar-track {
          background-color: ${scrollbarBackground};
        }

        &::-webkit-scrollbar-thumb {
          background-color: ${scrollbarHandlerColor};
        }

        &::-webkit-scrollbar-corner {
          background: ${scrollbarHandlerColor};
        }
      }

      .ant-table-tbody {
        .ant-empty-description {
          color: ${appTextColor};
        }

        tr {
          td {
            color: ${appTextColor} !important;
            padding: 10px 15px;
            cursor: pointer;
            font-size: ${fontSize.small};
            vertical-align: middle;

            &.ant-table-cell-row-hover {
              background: ${tableRowHoverColor};
            }

            &.aco-td-indigo {
              color: ${colors.darkIndigo} !important;
            }

            &.aco-td-light-red {
              color: #d8363d !important;
            }

            &.aco-td-light-cyan {
              color: #23b63b !important;
            }
          }

          th,
          td {
            font-weight: normal;
            line-height: 150%;
            border: none !important;
            border-radius: 0 !important;
          }

          &.ant-table-row-selected {
            td {
              background: none;
            }
          }

          &:nth-child(even) {
            background: ${tableEvenRowBackground};
            td {
              &.ant-table-column-sort,
              &.ant-table-cell-fix-left {
                background: ${tableEvenRowBackground};
              }
              &.ant-table-cell-row-hover {
                background: ${tableRowHoverColor};
              }
            }
          }

          &:nth-child(odd) {
            background: ${tableOddRowBackground};
            box-sizing: border-box;
            td {
              &.ant-table-column-sort,
              &.ant-table-cell-fix-left {
                background: ${tableOddRowBackground};
              }

              &.ant-table-cell-row-hover {
                background: ${tableRowHoverColor};
              }
            }
          }

          &:hover {
            td {
              background: ${tableRowHoverColor};
              &:hover {
                .ant-table-column-sort,
                .ant-table-cell-fix-left {
                  background: ${tableRowHoverColor};
                }
              }
              .vertical-menu {
                svg {
                  fill: ${appLabelColor};
                }
              }
            }
          }

          &.ant-table-placeholder {
            &:hover {
              td {
                background: none !important;
              }
            }
          }
        }
      }

      .ant-table-content {
        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
          background-color: ${scrollbarBackground};
        }

        &::-webkit-scrollbar-track {
          background-color: ${scrollbarBackground};
        }

        &::-webkit-scrollbar-thumb {
          background-color: ${scrollbarHandlerColor};
        }

        &::-webkit-scrollbar-corner {
          background: ${scrollbarHandlerColor};
        }
      }
    }
  }

  .ant-pagination {
    padding: 16px 0;
    margin: 0;
  }

  .ant-pagination-item {
    border-color: ${paginationItemActiveColor};
    background: ${paginationItemBackgroundColor};
    a {
      color: ${paginationItemTextColor};
    }

    &:hover,
    &.ant-pagination-item-active {
      border-color: ${colors.darkCyan};
      background: ${paginationItemBackgroundActiveColor};
      a {
        color: ${colors.darkCyan};
      }
    }
  }

  .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: ${paginationItemActiveColor};
    &:hover {
      color: ${colors.darkCyan};
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    .ant-pagination-item-link {
      color: ${paginationItemTextColor};
      border-color: ${paginationItemActiveColor};
      background: ${paginationItemBackgroundColor};
      &:hover {
        color: ${colors.darkCyan};
        border-color: ${colors.darkCyan};
        background: ${paginationItemBackgroundActiveColor};
      }
    }

    &.ant-pagination-disabled {
      .ant-pagination-item-link {
        color: ${paginationItemActiveColor};
      }
      &:hover,
      &:focus {
        .ant-pagination-item-link {
          color: ${paginationItemActiveColor};
        }
      }
    }
  }
`;
