// @flow

import { createAction } from 'redux-actions';
import { redux } from '@accordo-feed/micro-frontends';

import type { Action } from 'src/redux/types';
import { wrapWithModule } from 'src/redux/utils';

const { genApiSchema } = redux;

/*************
 *   TYPES   *
 *************/

type StateType = {
  data: Object,
  isLoaded: boolean,
  isLoading: boolean
};

/*********************
 *   INITIAL STATE   *
 *********************/

const initialState: StateType = genApiSchema();

/***************
 *   ACTIONS   *
 ***************/

const wrapWithNamespace = wrapWithModule('modules/distiBilling');

const GET_DISTI_BILLING_DATA = wrapWithNamespace('GET_DISTI_BILLING_DATA');
const GET_DISTI_BILLING_DATA_SUCCESS = wrapWithNamespace('GET_DISTI_BILLING_DATA_SUCCESS');

export const getDistiBillingData = createAction(GET_DISTI_BILLING_DATA);
export const getDistiBillingDataSuccess = createAction(GET_DISTI_BILLING_DATA_SUCCESS);

/***************
 *   REDUCER   *
 ***************/

export default (state: StateType = initialState, action: Action) => {
  const { type, payload } = action;

  const reducer = {
    [GET_DISTI_BILLING_DATA_SUCCESS]: (state, data) => ({
      data,
      isLoaded: true,
      isLoading: false
    })
  }[type];

  return reducer ? reducer(state, payload) : state;
};
