import { createSelector } from 'reselect';

import { calculateFilterData } from './filterPopup.utils';
import { dashboardMasterListSelector } from 'src/pages/dashboard/dashboard.selector';
import { filtersMasterListSelector } from 'src/redux/selectors/filters';

export const filterDataSelector = createSelector(
  dashboardMasterListSelector,
  filtersMasterListSelector,
  calculateFilterData
);
