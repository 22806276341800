import React, { useState } from 'react';

import regionData from './regionData.json';
import { StyledSelect } from 'src/components/countryPicker/countryPicker.styled';
import { byInputValue, generateOption } from 'src/components/countryPicker/countryPicker.utils';

/*****************
 *   COMPONENT   *
 *****************/

const StatePicker = props => {
  const { country } = props;
  const [inputValue, setInputValue] = useState();
  const regionList = regionData[country]._list;

  return (
    <StyledSelect
      name="region"
      size="large"
      showSearch
      filterOption={false}
      notFoundContent={null}
      onSearch={setInputValue}
      {...props}
    >
      {regionList.filter(byInputValue(inputValue)).map(generateOption(regionData[country]._map, 'state'))}
    </StyledSelect>
  );
};

export default StatePicker;
