import { langHelper } from '@accordo-feed/micro-frontends-utils';

const genProfileId = langHelper.genLangId('shell.aco.profile.billingDiscrepanciesAlerts');

const genProfileLang = langHelper.genLangOptions(genProfileId);

export default {
  title: genProfileLang('title', 'Billing Discrepancies Alerts'),
  switch: genProfileLang('switch', 'Subscribe to email PSA Billing discrepancies alert'),
  emailTitle: genProfileLang('emailTitle', 'Alerts Email *'),
  emailIntro: genProfileLang('emailIntro', 'We will send Email alerts and PSA related notifications to you here.'),
  placeholder: genProfileLang('placeholder', 'Add email'),
  saveChangesButton: genProfileLang('saveChangesButton', 'Save Changes')
};
