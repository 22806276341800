// @flow

import React, { useState } from 'react';
import { compose, withProps } from 'recompose';
import * as R from 'ramda';
import { router } from '@accordo-feed/micro-frontends';
import { translateHOC } from '@accordo-feed/language.entry';

import { AZURE_TRACK_EVENTS, CLICK_TRACK_CLASS, RECOM_SECTIONS } from 'src/constants';
import * as Styled from './recommendations.styled';
import Availability from './Availability.component';
import Cost from './Cost.component';
import Performance from './Performance.component';
import Security from './Security.component';
import { ICONS_MAP } from './recommendations.config';
import { lang } from './recommendations.lang';
import { type RecommendationsType } from './recommendations.duck';

// FIXME: update with the data from Elasticsearch
const mockRecomendations = {
  cost: [
    {
      description: 'Buy virtual machine reserved instances to save money over pay-as-you-go costs',
      impact: 'high',
      impactedResource: '22 VMs',
      lastUpdated: new Date(),
      monthlySavings: '3221'
    },
    {
      description: 'Your virtual machine has low usage. Shut down or resize your VM',
      impact: 'high',
      impactedResource: '1 VM',
      lastUpdated: new Date(),
      monthlySavings: '5400'
    },
    {
      description: 'Use a cost effective solution to manage the performance goals of multiple SQL databases',
      impact: 'medium',
      impactedResource: '1 SQL server',
      lastUpdated: new Date(),
      monthlySavings: '600'
    },
    {
      description: 'Enable autoscale on your Azure Cosmos DB database or container',
      impact: 'medium',
      impactedResource: '1 Cosmos Db Accounts',
      lastUpdated: new Date(),
      monthlySavings: '100'
    }
  ],
  security: [
    {
      description: 'There should be more than one owner assigned to subscriptions',
      impact: 'high',
      impactedResource: '1 Subscription',
      lastUpdated: new Date(),
      benefits: 'Administrator access redundancy.'
    },
    {
      description: 'Auto provisioning of the Log Analytics agent should be enabled on subscriptions',
      impact: 'low',
      impactedResource: '1 Subscription',
      lastUpdated: new Date(),
      benefits: 'Actively monitor for security vulnerabilities and threats'
    },
    {
      description: 'Subscriptions should have a contact email address for security issues',
      impact: 'low',
      impactedResource: '1 Subscription',
      lastUpdated: new Date(),
      benefits:
        'Ensure relevant people in your organization are notified when there is a potential security breach in one of your subscriptions.'
    }
  ],
  performance: [
    {
      description: 'Configure your Azure Cosmos DB query page size (MaxItemCount) to -1',
      impact: 'high',
      impactedResource: '1 Cosmos Db Accounts',
      lastUpdated: new Date(),
      benefits: 'Using a page size of -1 will result in faster database scans'
    }
  ],
  availability: [
    {
      description: 'Enable Cross Region Restore for your recovery Services Vault',
      impact: 'medium',
      impactedResource: '2 Vaults',
      lastUpdated: new Date(),
      benefits:
        'As one of the restore options, Cross Region Restore (CRR) allows you to restore Azure VMs in a secondary region, which is an Azure paired region.'
    },
    {
      description: 'Upgrade your old Azure Cosmos DB SDK to the latest version',
      impact: 'medium',
      impactedResource: '1 Cosmos Db Accounts',
      lastUpdated: new Date(),
      benefits: 'Improve the availability of your production workloads'
    },
    {
      description: 'Add a second region to your production workloads on Azure Cosmos DB',
      impact: 'medium',
      impactedResource: '1 Cosmos Db Accounts',
      lastUpdated: new Date(),
      benefits: 'Improved reliability, performance, and new feature capabilities'
    },
    {
      description: 'Improve resiliency by migrating your Azure Cosmos DB accounts to continuous backup',
      impact: 'medium',
      impactedResource: '2 Cosmos Db Accounts',
      lastUpdated: new Date(),
      benefits: 'Improve the resiliency of your Azure Cosmos DB workloads'
    }
  ]
};

/*************
 *   TYPES   *
 *************/

type Props = {
  activeTab: string,
  translate: Function,
  location: Object,
  recommendations: RecommendationsType,
  isLoading: boolean,
  onChangeTab: Function
};

/***************
 *   HELPERS   *
 ***************/

const SECTIONS_MAP = {
  [RECOM_SECTIONS.ALL]: {
    [RECOM_SECTIONS.COST]: Cost,
    [RECOM_SECTIONS.SECURITY]: Security,
    [RECOM_SECTIONS.PERFORMANCE]: Performance,
    [RECOM_SECTIONS.AVAILABILITY]: Availability
  },
  [RECOM_SECTIONS.COST]: {
    [RECOM_SECTIONS.COST]: Cost
  },
  [RECOM_SECTIONS.SECURITY]: {
    [RECOM_SECTIONS.SECURITY]: Security
  },
  [RECOM_SECTIONS.PERFORMANCE]: {
    [RECOM_SECTIONS.PERFORMANCE]: Performance
  },
  [RECOM_SECTIONS.AVAILABILITY]: {
    [RECOM_SECTIONS.AVAILABILITY]: Availability
  }
};

const TAB_TRACK_MAP = {
  [RECOM_SECTIONS.ALL]: AZURE_TRACK_EVENTS.AZUREOPTIMIZER_RECOMMENDATIONS_TAB_ALL,
  [RECOM_SECTIONS.COST]: AZURE_TRACK_EVENTS.AZUREOPTIMIZER_RECOMMENDATIONS_TAB_COST,
  [RECOM_SECTIONS.SECURITY]: AZURE_TRACK_EVENTS.AZUREOPTIMIZER_RECOMMENDATIONS_TAB_SECURITY,
  [RECOM_SECTIONS.PERFORMANCE]: AZURE_TRACK_EVENTS.AZUREOPTIMIZER_RECOMMENDATIONS_TAB_PERFORMANCE,
  [RECOM_SECTIONS.AVAILABILITY]: AZURE_TRACK_EVENTS.AZUREOPTIMIZER_RECOMMENDATIONS_TAB_AVAILABILITY
};

const renderTabs = ({ activeTab, translate, recommendations, onChangeTab }: Props) => {
  const tabs = R.values(RECOM_SECTIONS).map((section: string) => {
    if (section !== RECOM_SECTIONS.ALL && !recommendations[section]) return null;

    const iconProps = ICONS_MAP[section];
    const labelLang = translate(lang[section]);
    const trackName = TAB_TRACK_MAP[section];

    const iconExtendedProps = {
      size: '26px',
      gapRight: '5px'
    };

    return (
      <Styled.Tab
        key={section}
        active={activeTab === section}
        className={`${CLICK_TRACK_CLASS} at__view-recomm-tab-${section}`}
        data-track={JSON.stringify({ trackName })}
        onClick={() => onChangeTab(section)}
      >
        {iconProps && <Styled.Icon {...iconProps} {...iconExtendedProps} />}
        {labelLang}
      </Styled.Tab>
    );
  });

  return <Styled.TabsContainer>{tabs}</Styled.TabsContainer>;
};

const renderSections = ({ activeTab, translate, recommendations }: Props) => {
  const sections = R.toPairs(SECTIONS_MAP[activeTab])
    .map(([key, Section], idx) => {
      const props = {
        translate,
        recommendations,
        key: idx
      };

      return recommendations[key] ? <Section {...props} /> : null;
    })
    .filter(item => item);
  return <Styled.Panel>{sections}</Styled.Panel>;
};

/*****************
 *   COMPONENT   *
 *****************/

const Recommendations = (props: Props) => {
  const [activeTab, setActiveTab] = useState(RECOM_SECTIONS.ALL);
  const handleSelectTab = e => {
    if (activeTab !== e) setActiveTab(e);
  };
  return (
    <Styled.PageContainer>
      {renderTabs({ activeTab, ...props, onChangeTab: handleSelectTab })}
      {renderSections({ activeTab, ...props })}
    </Styled.PageContainer>
  );
};

export default compose(
  withProps(props => ({
    recommendations: mockRecomendations
  })),

  translateHOC,
  router.withRouterHOC
)(Recommendations);
