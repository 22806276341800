import styled from 'styled-components';
import { Link } from 'react-router';

import { appTextColor } from 'src/App.styled';

export const LinkName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${appTextColor};
  text-decoration: underline;
  max-width: 360px;

  &.aco-light-red {
    color: #d8363d;
  }
`;

export const VisitLink = styled(Link)`
  text-decoration: none;
  display: inline-flex;

  &.aco-text-left {
    text-align: left;
  }
`;

export const ExternalLink = styled.div`
  text-decoration: ${props => (props.disabled ? 'none' : 'underline')};
`;
