// @flow
import React from 'react';

import SimpleTable from 'src/components/simpleTable';
import { widgetDataHOC } from 'src/hoc';
import { PATHS } from 'src/constants';
import { generateTableColumns } from './azureUsage.config';
import type { AzureCustomerType } from '../azureDashboard.duck';

/*************
 *   TYPES   *
 *************/

type Props = {
  dataSource: Array<AzureCustomerType>
};

/*****************
 *   COMPONENT   *
 *****************/

const AzureUsage = ({ dataSource }: Props) => {
  const tableOptions = {
    dataSource,
    pageSize: 10,
    columns: generateTableColumns({
      enableSort: true,
      prevPath: PATHS.AZURE_DASHBOARD.ROOT
    }),
    pagination: dataSource.length > 10,
    x: 1300
  };

  return <SimpleTable {...tableOptions} />;
};

export default widgetDataHOC(['dataSource'])(AzureUsage);
