// @flow

import * as R from 'ramda';
import { Machine } from 'xstate';

import * as partnerCenterSelectors from 'src/redux/modules/partnerCenter/partnerCenter.selector';
import * as userDataSelectors from 'src/redux/selectors/userData';
import * as selectors from 'src/redux/selectors';
import { PATHS, STORAGE_KEYS } from 'src/constants';
import { getFinalPath } from './routes.utils';

export { getFinalPath };

/*************
 *   PATHS   *
 *************/

const PAYMENT_PATHS = [PATHS.PLAN_OVERVIEW, PATHS.PAYMENT_DETAILS];

/**************
 *   GUARDS   *
 **************/

const isPaid = (context: ?Object, event: Object): boolean => {
  const { state } = event;
  const isValidPayment = selectors.organizationIsValidPaymentSelector(state);
  const isDistributor = selectors.hasFeatureDistributor(state);

  return isDistributor || isValidPayment;
};

const isNotPaid = R.complement(isPaid);

const isPaymentRoutes = (context, event) => {
  return R.includes(event.path, PAYMENT_PATHS);
};

const isConnectRoutes = (content, event) => {
  return event.path === PATHS.PARTNER_CENTER_WELCOME;
};

// Example, navigating from plan overview page to payment details page, or doing that reversly.
const isFromPaymentRoutes = (context, event) => {
  return R.includes(event.prevPath, PAYMENT_PATHS);
};

const isConnected = (context: ?Object, event: Object): boolean => {
  const { state } = event;
  const isSkip = !!localStorage.getItem(STORAGE_KEYS.SKIP_PARTNER_CENTER);
  // Please note that, the selector covers the detection for both app and partner admin connections
  const isAppOrPartnerAdminConnected = partnerCenterSelectors.hasTriedConnectedSelector(state);

  return isSkip || isAppOrPartnerAdminConnected;
};

const isShowWelcomeRoute = (context: ?Object, event: Object): boolean => {
  const { state } = event;

  const hasViewedWelcomePage = !!localStorage.getItem(STORAGE_KEYS.HAS_VIEWED_WELCOME_PAGE);
  const isPartnerAdmin = userDataSelectors.isPartnerAdminSelector(state);
  const isSecureAppConnectionType = partnerCenterSelectors.isSecureAppConnectionTypeSelector(state);

  return isPartnerAdmin && !isSecureAppConnectionType && !hasViewedWelcomePage;
};

/**********************
 *   STATE MACHINES   *
 **********************/

const routes = {
  id: 'routes',
  initial: 'start',
  states: {
    start: {
      on: {
        NAVIGATE: [
          {
            cond: 'isPaid',
            target: 'paidRoutes'
          },
          {
            cond: 'isNotPaid',
            target: 'planOverviewRoute'
          }
        ]
      }
    },
    paidRoutes: {
      on: {
        NAVIGATE: [
          {
            cond: 'isPaymentRoutes',
            target: 'paymentRoutes'
          },
          {
            cond: 'isConnectRoutes',
            target: 'connectRoutes'
          },
          {
            target: 'originalRoute'
          }
        ]
      }
    },
    paymentRoutes: {
      on: {
        NAVIGATE: [
          {
            cond: 'isPaid',
            target: 'connectRoutes'
          },
          {
            cond: 'isFromPaymentRoutes',
            target: 'originalRoute'
          },
          {
            target: 'planOverviewRoute'
          }
        ]
      }
    },
    connectRoutes: {
      on: {
        NAVIGATE: [
          {
            cond: 'isShowWelcomeRoute',
            target: 'partnerCenterWelcomeRoute'
          },
          {
            cond: 'isConnected',
            target: 'dashboardRoute'
          },
          {
            target: 'partnerCenterWelcomeRoute'
          }
        ]
      }
    },
    originalRoute: {},
    partnerCenterWelcomeRoute: {
      meta: { finalPath: PATHS.PARTNER_CENTER_WELCOME }
    },
    dashboardRoute: {
      meta: { finalPath: PATHS.DASHBOARD.ROOT }
    },
    planOverviewRoute: {
      meta: { finalPath: PATHS.PLAN_OVERVIEW }
    }
  }
};

export default Machine(routes, {
  guards: {
    isConnectRoutes,
    isConnected,
    isFromPaymentRoutes,
    isNotPaid,
    isPaid,
    isPaymentRoutes,
    isShowWelcomeRoute
  }
});
