import styled, { css } from 'styled-components';
import { Drawer as AntDrawer } from 'antd';
import { constants, mixins } from '@accordo-feed/aco-styled-components';

import SearchComponent from 'src/components/search/Search.component';
import CheckboxGroup from 'src/components/checkboxGroup';
import { hexToRgb } from 'src/utils';
import { appLabelColor, appTextColor, tableRowBorderColor, bodyBackground } from 'src/App.styled';
import { PanelHeader } from 'src/pages/dashboard/securityScore/filterPanel/filterPanel.styled';
import { STATUS } from './drawer.constants';

import { widgetBorderColor } from 'src/App.styled';

const { colors, fontSize, borderRadius } = constants;

export const Drawer = styled(AntDrawer).attrs({
  placement: 'right',
  width: 1000
})`
  top: 0;

  .ant-drawer- {
    &content-wrapper {
      height: 100%;
      border: 1px solid ${widgetBorderColor};
    }

    &close {
      z-index: 10000;
    }

    &header {
      background: transparent;
      border-bottom: 0;
      padding: 16px;
      .ant-drawer-close {
        .anticon-close {
          color: ${appTextColor};
        }
      }
    }

    &body {
      padding-top: 0;
    }
  }

  .ant-drawer-content {
    background: ${bodyBackground};
  }

  .ant-drawer-close i {
    color: ${appTextColor};
  }

  .ant-table-empty {
    .ant-table-body {
      overflow: visible !important;
    }
  }
`;

export const Title = styled.h2`
  color: ${appLabelColor};
  font-size: ${fontSize.normal};
`;

export const ScoreTable = styled.table`
  width: 365px;
  margin-bottom: 32px;

  th {
    font-size: ${fontSize.xSmall};
    color: ${appLabelColor};
  }

  td {
    color: ${appTextColor};
    font-size: ${fontSize.xLarge};
  }

  th,
  td {
    border-right: 1px solid ${tableRowBorderColor};
    padding-left: 15px;
    padding-right: 25px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
      border-right: none;
    }
  }
`;

export const Search = styled(SearchComponent)`
  margin-left: 0;
  margin-bottom: 16px;
`;

export const IndicatorContainer = styled.div`
  ${mixins.flexVerticalCenter()};
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const Indicator = styled.div`
  color: ${appTextColor};

  em {
    font-style: inherit;
  }
`;

export const Status = styled.span`
  display: inline-block;
  font-size: ${fontSize.xSmall};
  border-radius: ${borderRadius};
  border: 1px solid ${colors.shadeGreen};
  background: rgba(${hexToRgb(colors.tintGreen)}, 0.3);
  text-align: center;
  padding: 3px 6px;
  ${props =>
    props.type === STATUS.NOT_COMPLETED &&
    css`
      border-color: ${colors.shadeYellow};
      background: rgba(${hexToRgb(colors.shadeYellow)}, 0.3);
    `}
`;

export const Container = styled.div`
  padding: 8px 24px;
`;

export const FilterPanelHeader = styled(PanelHeader)`
  margin-bottom: 20px;
`;

export const ShowFilterPanel = styled.span`
  font-size: ${fontSize.small};
  margin-left: 20px;
  color: ${colors.blue};
  cursor: pointer;
`;

export const StyledCheckboxGroup = styled(CheckboxGroup)`
  && {
    .ant-checkbox-group-item {
      margin: 0;
    }
  }
`;
