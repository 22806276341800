import styled from 'styled-components';
import { Row } from 'antd';
import { constants } from '@accordo-feed/aco-styled-components';

const { colors } = constants;

export const Container = styled(Row).attrs({
  type: 'flex'
})`
  min-height: 100vh;
  background: ${colors.white};
`;
