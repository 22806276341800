// @flow

import React from 'react';
import { Row, Col } from 'antd';
import { useTranslate } from '@accordo-feed/language.entry';

import Slider from 'src/components/slider';
import CheckboxGroup from 'src/components/checkboxGroup';
import lang from 'src/pages/masterList/masterList.lang';
import { RECOMMENDATION_TYPE } from 'src/redux/modules/filters/filters.duck';
import * as Styled from './filterPopup.styled';

const filterLang = lang.filter;

/*************
 *   TYPES   *
 *************/

type ActionType = {
  setMasterListActiveUserFilter: Function,
  setMasterListTotalSpendFilter: Function,
  setMasterListRecommendationFilter: Function
};

type Props = {
  actions: ActionType,
  shouldShowActiveUsersFilter: boolean,
  shouldShowTotalSpendFilter: boolean,
  minActiveUsers: number,
  maxActiveUsers: number,
  minTotalSpend: number,
  maxTotalSpend: number,
  selectedMinActiveUsers: number,
  selectedMaxActiveUsers: number,
  selectedMinTotalSpend: number,
  selectedMaxTotalSpend: number,
  isOpen: boolean,
  recommendations: Array<string>,
  onCloseBtnClick: Function,
  formatCurrency: Function
};

/*****************
 *   COMPONENT   *
 *****************/

export default ({
  actions,
  shouldShowActiveUsersFilter,
  shouldShowTotalSpendFilter,
  minActiveUsers,
  maxActiveUsers,
  minTotalSpend,
  maxTotalSpend,
  selectedMinActiveUsers,
  selectedMaxActiveUsers,
  selectedMinTotalSpend,
  selectedMaxTotalSpend,
  isOpen,
  recommendations,
  onCloseBtnClick,
  formatCurrency
}: Props) => {
  if (!isOpen) return null;

  const translate = useTranslate();

  const recommendationFilterOptions = [
    { label: translate(filterLang.hasEngagement), value: RECOMMENDATION_TYPE.ENGAGEMENT },
    { label: translate(filterLang.hasMonthlyRevenue), value: RECOMMENDATION_TYPE.MONTHLY_REVENUE },
    { label: translate(filterLang.hasConsultingRevenue), value: RECOMMENDATION_TYPE.CONSULTING_REVENUE }
  ];

  const activeUserSliderProps = {
    range: 'range',
    min: minActiveUsers,
    max: maxActiveUsers,
    value: [selectedMinActiveUsers, selectedMaxActiveUsers],
    onChange: actions.setMasterListActiveUserFilter
  };
  const activeUserSilderComponentProps = {
    titleLang: filterLang.activeUserTitle,
    isFilterShowed: shouldShowActiveUsersFilter,
    minLabel: minActiveUsers,
    maxLabel: maxActiveUsers,
    sliderProps: activeUserSliderProps
  };

  const totalSpendSliderProps = {
    range: 'range',
    min: minTotalSpend,
    max: maxTotalSpend,
    value: [selectedMinTotalSpend, selectedMaxTotalSpend],
    onChange: actions.setMasterListTotalSpendFilter
  };
  const totalSpendSliderComponentProps = {
    titleLang: filterLang.totalSpendTitle,
    tooltipLang: filterLang.totalSpendTooltip,
    isFilterShowed: shouldShowTotalSpendFilter,
    minLabel: formatCurrency(minTotalSpend),
    maxLabel: formatCurrency(maxTotalSpend),
    sliderProps: totalSpendSliderProps
  };

  return (
    <Styled.Container>
      <Styled.CloseButton onClick={onCloseBtnClick} />

      <Row gutter={30}>
        <Col span={7}>
          <Slider {...activeUserSilderComponentProps} />
        </Col>
        <Col span={7}>
          <Slider {...totalSpendSliderComponentProps} />
        </Col>
        <Col span={10}>
          <Styled.Title>{translate(filterLang.recommendationsTitle)}</Styled.Title>
          <CheckboxGroup
            options={recommendationFilterOptions}
            value={recommendations}
            onChange={actions.setMasterListRecommendationFilter}
          />
        </Col>
      </Row>
    </Styled.Container>
  );
};
