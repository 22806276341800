import React from 'react';
import { langHelper } from '@accordo-feed/micro-frontends-utils';
import { LanguageEntry } from '@accordo-feed/language.entry';

const genId = langHelper.genLangId('shell.aco.opportunities.page');
const genOpts = langHelper.genLangOptions(genId);

export default {
  pageTitle: genOpts('title', 'Recommendations'),
  // TODO: Update language entry
  introduction: (
    <LanguageEntry
      id="shell.aco.opportunities.page.introduction"
      defaultMessage="<h2>See exactly where your opportunities are!</h2><ul><li>Retain customers and reduce churn by acting on the engagement opportunities.</li>
<li>Drive growth across your monthly recurring revenue base. </li>
<li>Boost your one-off consulting revenue.</li>
</ul>"
    />
  ),
  table: {
    viewRecommendations: genOpts('th.viewRecommendations', 'View Recommendations'),
    viewOpportunitiesDetails: genOpts('td.viewOpportunitiesDetails', 'View recommendations'),
    customer: genOpts('table.customer', 'Customer'),
    opportunities: {
      th1: genOpts('opportunities.th1', 'Total'),
      th2: genOpts('opportunities.th2', 'Engagement'),
      th3: genOpts('opportunities.th3', 'Monthly Revenue'),
      th4: genOpts('opportunities.th4', 'Consulting Revenue')
    }
  }
};
