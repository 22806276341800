// @flow

import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';

import GlobalAlertComp from './GlobalAlert.component';
import { shouldShowGlobalAlertSelector } from './globalAlert.selector';

/*****************
 *   COMPONENT   *
 *****************/

const GlobalAlert = props => <GlobalAlertComp {...props} />;

/*************
 *   REDUX   *
 *************/

const mapStateToProps = R.applySpec({
  showGlobalAlert: shouldShowGlobalAlertSelector
});

export default connect(mapStateToProps)(GlobalAlert);
