// @flow

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Search from './Search.component';
import { resetSearchTerm } from 'src/redux/modules/searchTerm/searchTerm.duck';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ resetSearchTerm }, dispatch)
});

export default connect(null, mapDispatchToProps)(Search);
