// @flow

import { bindActionCreators } from 'redux';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { router } from '@accordo-feed/micro-frontends';

import { getBillingDiscrepancyWidgetData, downloadBillingDiscrepancyReport } from 'src/pages/dashboard/dashboard.duck';
import { loaderInlineHOC } from 'src/hoc';
import * as billingDiscrepancySelectors from './billingDiscrepancy.selector';
import BillingDiscrepancy from './BillingDiscrepancy.component';

/*************
 *   REDUX   *
 *************/

const mapStateToProps = state => ({
  query: billingDiscrepancySelectors.billingDiscrepancyQuerySelector(state),
  data: billingDiscrepancySelectors.billingDiscrepancyDataSelector(state),
  isLoading: billingDiscrepancySelectors.billingDiscrepancyIsLoadingSelector(state),
  isConnected: billingDiscrepancySelectors.billingDiscrepancyPsaIsConnectedSelector(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getBillingDiscrepancyWidgetData,
      downloadBillingDiscrepancyReport
    },
    dispatch
  )
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  router.withRouterHOC,
  loaderInlineHOC()
)(BillingDiscrepancy);
