import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { Input as AntInput } from 'antd';
import { constants } from '@accordo-feed/aco-styled-components';

import UIButton from 'src/components/UI/button';
import { Row } from 'src/pages/paymentDetails/paymentDetails.styled';
import { appTextColor, tableEvenRowBackground, widgetBorderColor } from 'src/App.styled';
import { setProperty } from 'src/utils';
import { Section } from 'src/components/section';

const { fontSize, colors, borderRadius } = constants;

export const Input = styled(AntInput)`
  color: ${appTextColor};
  border-radius: ${borderRadius};
  min-height: 32px;
  width: 250px;
  border: 1px solid ${colors.lightGrey};
  padding: 4px 6px;
  background-color: ${widgetBorderColor};
`;

const Label = styled.label`
  color: ${appTextColor};
  min-width: 120px;
  font-size: ${fontSize.small};
`;

export const Field = ({ labelText, alignItems = 'center', children }) => (
  <Row margin="0 0 16px 0" alignItems={alignItems}>
    <Label>{labelText}</Label>
    {children}
  </Row>
);

export const Button = styled(({ minWidth, ...rest }) => <UIButton {...rest} />)`
  min-width: ${R.propOr('auto', 'minWidth')};
  ${setProperty('float')}
  ${setProperty('margin')}
  background-color: ${tableEvenRowBackground};
  &:focus {
    background-color: ${tableEvenRowBackground};
  }
  &:hover {
    background-color: ${tableEvenRowBackground};
    opacity: 0.7;
  }
`;

export const ErrorInfo = styled.div`
  margin-left: 120px;
  margin-bottom: 24px;
  color: ${colors.red};
`;

export const CardNumber = styled.div`
  color: ${appTextColor};
`;

export const StyledAddress = styled.div`
  color: ${appTextColor};
`;

export const StyledSection = styled(Section)`
  padding: 0 16px;
`;

export const Content = styled.div`
  padding: 0 15px;
`;
