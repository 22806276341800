import { langHelper } from '@accordo-feed/micro-frontends-utils';

const genId = langHelper.genLangId('shell.aco.azureDashboard.usage');
const genOpts = langHelper.genLangOptions(genId);

export default {
  table: {
    main: {
      resources: genOpts('th.resources', 'Resources'),
      budget: genOpts('th.budget', 'Budget')
    },
    resources: {
      company: genOpts('resources.company', 'Company'),
      groups: genOpts('resources.groups', 'Groups'),
      total: genOpts('resources.total', 'Total Resources'),
      period: genOpts('resources.period', 'Billing Period'),
      remaining: genOpts('budget.remaining', 'Days Remaining')
    },
    budget: {
      cost: genOpts('budget.cost', 'Estimated Cost'),
      budgeted: genOpts('budget.budgeted', 'Budgeted'),
      percent: genOpts('budget.percent', '% of Budget'),
      lastSync: genOpts('budget.lastSync', 'Last Sync')
    }
  }
};
