// @flow

import * as R from 'ramda';
import React from 'react';
import { useTranslate } from '@accordo-feed/language.entry';
import { KeyboardArrowLeft } from '@material-ui/icons';

import lang from 'src/pages/dashboard/widgets/securityScores/securityScores.lang';
import ViewMore from 'src/components/viewMore';
import { WidgetBodyWrapper } from 'src/components/widget/widget.styled';
import SecurityScoresContent from '../SecurityScores.component';
import * as Styled from './demoOverlay.styled';
import { demoData } from './demoOverlay.config';

/*************
 *   TYPES   *
 *************/

type Props = {
  hide: Function
};

/*****************
 *   COMPONENT   *
 *****************/

export default ({ hide }: Props) => {
  const translate = useTranslate();
  const data = R.clone(demoData);
  data.xAxis.name = translate(lang.chartXAxis);
  data.yAxis.name = translate(lang.chartYAxis);

  return (
    <Styled.Overlay>
      <Styled.OverlayTitle>{translate(lang.overlayTitle)}</Styled.OverlayTitle>
      <WidgetBodyWrapper>
        <SecurityScoresContent data={data} isDemo />
      </WidgetBodyWrapper>
      <ViewMore disabled={true} path="" />
      <Styled.BackButton onClick={hide}>
        <Styled.ArrowIcon element={KeyboardArrowLeft} />
        {translate(lang.backToYourData)}
      </Styled.BackButton>
    </Styled.Overlay>
  );
};
