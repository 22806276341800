// @flow

import { bindActionCreators } from 'redux';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import FilterPopup from './FilterPopup.component';
import { filterDataSelector } from './filterPopup.selector';
import { formatCurrencyHOC } from 'src/hoc';
import {
  setMasterListActiveUserFilter,
  setMasterListTotalSpendFilter,
  setMasterListRecommendationFilter
} from 'src/redux/modules/filters/filters.duck';

/*************
 *   REDUX   *
 *************/

const mapStateToProps = state => filterDataSelector(state);

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setMasterListActiveUserFilter,
      setMasterListTotalSpendFilter,
      setMasterListRecommendationFilter
    },
    dispatch
  )
});

export default compose(connect(mapStateToProps, mapDispatchToProps), formatCurrencyHOC)(FilterPopup);
