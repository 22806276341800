// @flow

import React from 'react';
import { LanguageEntry, LanguageEntryProvider } from '@accordo-feed/language.entry';
import { LoadingView } from '@accordo-feed/aco-styled-components';
import { SubModule } from '@accordo-feed/micro-frontends';

import { SUBMODULES } from 'src/constants';
import { getFilepath } from 'src/utils/submodule';
import { useReduxStore } from 'src/hooks';

const filepath = getFilepath(SUBMODULES.AZURE_OPTIMIZER, 'bundle');

const Spinner = () => (
  <LanguageEntryProvider>
    <LoadingView
      loadingMsg={<LanguageEntry id="azure-optimizer.loadingspinnertext" defaultMessage="Loading Azure Optimizer" />}
    />
  </LanguageEntryProvider>
);

export default () => {
  const store = useReduxStore();

  return <SubModule filePath={filepath} store={store} spinner={<Spinner />} />;
};
