import { langHelper } from '@accordo-feed/micro-frontends-utils';

import { RECOM_SECTIONS } from 'src/constants';

const genId = langHelper.genLangId('azureoptimizer.overviewpage.subpage.recommendations');
const genLang = langHelper.genLangOptions(genId);

export const lang = {
  pageTitle: genLang('heading', 'Recommendations'),
  [RECOM_SECTIONS.ALL]: genLang('tab1', 'All'),
  [RECOM_SECTIONS.COST]: genLang('tab2', 'Cost'),
  [RECOM_SECTIONS.SECURITY]: genLang('tab3', 'Security'),
  [RECOM_SECTIONS.PERFORMANCE]: genLang('tab4', 'Performance'),
  [RECOM_SECTIONS.AVAILABILITY]: genLang('tab5', 'High Availability'),
  descriptionCol: genLang('tab1.subheading1', 'Description'),
  impactCol: genLang('tab1.subheading2', 'Impact'),
  resourceCol: genLang('tab1.subheading3', 'Impacted Resources'),
  updatedCol: genLang('tab1.subheading4', 'Updated'),
  monthlySavingsCol: genLang('tab1.subheading5', 'Potential Monthly Savings'),
  potentialBenefitCol: genLang('tab1.subheading6', 'Potential Benifits'),
  impact: {
    high: genLang('impact.label1', 'High'),
    medium: genLang('impact.label2', 'Medium'),
    low: genLang('impact.label3', 'Low')
  },
  total: genLang('tab1.expandsection.label', 'Total')
};
