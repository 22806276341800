import * as R from 'ramda';
import * as yup from 'yup';

const emailSchema = yup.string().email();

const initValue = {
  isAlertOn: false,
  emailList: []
};

export const transformForDataSelector = ({ data: org }, { userId }) => {
  const currentUser = R.find(R.propEq('id', userId))(org.users) || R.head(org.users);

  return {
    orgDetails: {
      name: org.name,
      ...R.pick(['seatCount', 'clients', 'psaTools', 'brandAccentColor', 'logoImage'], org.orgDetails)
    },
    userDetails: {
      firstName: R.path(['firstName'], currentUser),
      lastName: R.path(['lastName'], currentUser),
      phone: R.pipe(R.pathOr({}, ['phone']), R.pick(['countryCode', 'prefix', 'number']))(currentUser)
    }
  };
};

export const transformForUpdate = data => {
  const psaBillingAlert = R.propOr(initValue, ['psaBillingAlert'])(data);

  return {
    ...data,
    psaBillingAlert: {
      ...psaBillingAlert,
      emailList: R.pipe(
        R.prop('emailList'),
        R.filter(item => !item.invalid),
        R.map(item => item.name)
      )(psaBillingAlert)
    }
  };
};

export const transformAlertDataSelector = ({ data: org }) => {
  const psaBillingAlert = R.pathOr(initValue, ['psaBillingAlert'])(org);
  return {
    psaBillingAlert: {
      ...psaBillingAlert,
      emailList: psaBillingAlert.emailList.map(email => {
        let isValid = true;

        try {
          emailSchema.validateSync(email);
        } catch (err) {
          isValid = false;
        }

        return {
          name: email,
          invalid: !isValid
        };
      })
    }
  };
};

export const fileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const validBase64Image = (str: string) => {
  const regex = /^data:image\/(?:gif|png|jpeg|bmp|webp|svg\+xml)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/;
  return str && regex.test(str);
};
