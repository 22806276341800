// @flow

import React from 'react';
import { useTranslate } from '@accordo-feed/language.entry';

import * as Styled from './appFooter.styled';
import FooterLink from './FooterLink.component';
import { LINK } from './footerLink.constants';
import { lang } from './appFooter.lang';

/*****************
 *   COMPONENT   *
 *****************/

export const PrivacyLink = (props: any) => <FooterLink type={LINK.PRIVACY} {...props} />;

const AppFooter = () => {
  const translate = useTranslate();
  return (
    <Styled.Footer>
      <Styled.Wrapper>
        {'© '}
        {new Date().getFullYear()}&nbsp;
        {translate(lang.copyright)}&nbsp;
        {translate(lang.siteHost)}&nbsp;
        <PrivacyLink />
        {' | '}
        <FooterLink type={LINK.TERMS} />
      </Styled.Wrapper>
    </Styled.Footer>
  );
};

export default AppFooter;
