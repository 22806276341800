// @flow
import { connect } from 'react-redux';

import * as selectors from 'src/redux/selectors';
import AzureBudget from './AzureBudget.component';

/*************
 *   REDUX   *
 *************/

const mapStateToProps = state => ({
  data: selectors.azureDashboardDataSelector(state)
});

export default connect(mapStateToProps)(AzureBudget);
