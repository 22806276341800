// @flow

import React, { useEffect } from 'react';
import { LoadingView } from '@accordo-feed/aco-styled-components';
import { translateHOC } from '@accordo-feed/language.entry';

import auth from 'src/utils/auth';
import { AUTH_ACTIONS } from 'src/constants';
import lang from './auth.lang';

/*************
 *   TYPES   *
 *************/

type Props = {
  translate: Function,
  action: typeof AUTH_ACTIONS.LOGIN | typeof AUTH_ACTIONS.LOGOUT | typeof AUTH_ACTIONS.SIGNUP
};

/*****************
 *   COMPONENT   *
 *****************/

const Auth = ({ translate, action }: Props) => {
  useEffect(() => {
    auth[action]();
  }, []);

  return <LoadingView loadingMsg={translate(lang.loading)} />;
};

export default translateHOC(Auth);
