// @flow

import React from 'react';
import SimpleTable from 'src/components/simpleTable';
import { generateTableColumns } from './adoption.config';
import { widgetDataHOC } from 'src/hoc';
import { PATHS } from 'src/constants';

/*************
 *   TYPES   *
 *************/

type Props = {
  dataSource: Array<Object>
};

/*****************
 *   COMPONENT   *
 *****************/

const Adoption = ({ dataSource }: Props) => {
  const tableOptions = {
    dataSource,
    columns: generateTableColumns({
      enableSort: true,
      prevPath: PATHS.DASHBOARD.ROOT
    }),
    pageSize: 17,
    x: 985
  };
  return <SimpleTable {...tableOptions} />;
};

export default widgetDataHOC(['dataSource'])(Adoption);
