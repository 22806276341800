// @flow

import React from 'react';
import { Tabs } from 'antd';
import { useTranslate } from '@accordo-feed/language.entry';

import { DIRECTION } from 'src/components/widget/widget.constants';
import { isEmptyOrNil } from 'src/utils';
import * as Styled from './growthTrend.styled';
import DropdownComponent from './Dropdown.component';
import GrowthTrendChart from './GrowthTrendChart.component';
import LineIndicator from './LineIndicator.component';
import lang from './growthTrend.lang';

/*************
 *   TYPES   *
 *************/

type Props = {
  filterMenu: Object,
  selectedTitle: Function,
  selectedPath: Function,
  setDirection: Function,
  dataSource: Array<Object>
};

/*****************
 *   COMPONENT   *
 *****************/

const { TabPane } = Tabs;

const RenderTabPaneComponent = (key, { filterMenu, selectedTitle, dataSource }) => {
  const translate = useTranslate();
  const directionLangMap = {
    [DIRECTION.POSITIVE]: translate(lang.positive),
    [DIRECTION.NEGATIVE]: translate(lang.negative)
  };
  const name = directionLangMap[key];

  return (
    <TabPane tab={name} key={key}>
      <Styled.DropdownWrapper>
        <DropdownComponent filterMenu={filterMenu} selectedTitle={selectedTitle} />
        {!isEmptyOrNil(dataSource) && <LineIndicator />}
      </Styled.DropdownWrapper>
    </TabPane>
  );
};

const GrowthContent = (props: Props) => {
  const { setDirection, dataSource } = props;

  return (
    <Styled.TabContainer>
      <Tabs defaultActiveKey={DIRECTION.POSITIVE} animated={false} onChange={setDirection}>
        {RenderTabPaneComponent(DIRECTION.POSITIVE, { ...props })}
        {RenderTabPaneComponent(DIRECTION.NEGATIVE, { ...props })}
      </Tabs>
      <Styled.ContentWrapper>
        <GrowthTrendChart dataSource={dataSource} />
      </Styled.ContentWrapper>
    </Styled.TabContainer>
  );
};

export default GrowthContent;
