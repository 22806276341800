import { langHelper } from '@accordo-feed/micro-frontends-utils';

const genAddressFormId = langHelper.genLangId('shell.aco.address.form');
const genAddressForm = langHelper.genLangOptions(genAddressFormId);

export const lang = {
  line1: genAddressForm('line1', 'Address line 1'),
  line2: genAddressForm('line2', 'Address line 2'),
  country: genAddressForm('country', 'Company location'),
  city: genAddressForm('city', 'City'),
  region: genAddressForm('region', 'State/Province'),
  postalCode: genAddressForm('postalCode', 'Zip/Postal code'),
  error: genAddressForm('error', '{fieldName} is required'),
  addressErrorTitle: genAddressForm('addressErrorTitle', 'Address Information Error'),
  optional: genAddressForm('optional', '(Optional)'),
  emptyStateErr: genAddressForm('emptyStateError', 'Please select a state'),
  wrongStateErr: genAddressForm('wrongStateErr', 'Please select a valid state for the country')
};
