import React from 'react';
import styled, { css } from 'styled-components';
import { Button as AntButton } from 'antd';
import { mixins, constants } from '@accordo-feed/aco-styled-components';

import LangWrapper from 'src/components/langWrapper';
import { blueColor } from './priceBox/priceBox.styled';

const { colors, fontSize } = constants;

const spaceBetweenMixin = css`
  display: flex;
  justify-content: space-between;
`;

const lightFontMixin = css`
  color: ${colors.darkShadeGrey};
  font-size: ${fontSize.xSmall};
`;

export const Container = styled.div`
  flex: 5;
  background: ${colors.white};
  padding: 140px 0 35px;
  min-height: 100vh;
  overflow: auto;
  position: relative;
`;

export const PromotionBarContainer = styled.div`
  position: absolute;
  top: 40px;
  right: 0;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.3));
`;

export const PromotionBar = styled.div`
  ${mixins.verticalLineMiddle('58px')}
  color: ${colors.white};
  font-weight: 600;
  font-size: ${fontSize.medium};
  padding: 0 35px 0 50px;
  background-color: ${colors.deepCyan};
  clip-path: polygon(3% 64%,0% 0%,100% 0%,100% 100%,1% 100%);
`;

export const Title = styled.h1`
  font-size: ${fontSize.xLarge};
  font-weight: normal;
  letter-spacing: 0.24px;
  text-align: center;
  margin-bottom: 20px;
`;

export const Content = styled.div`
  text-align: center;
  border-bottom: 1px solid ${colors.lightTintGrey};
`;

export const FixedWidthContainer = styled.div`
  ${mixins.centerBlock('660px')};
  text-align: center;
`;

export const Plans = styled(FixedWidthContainer)`
  ${spaceBetweenMixin};
`;

export const BaseFee = styled(LangWrapper)`
  margin-top: 30px;

  span {
    color: ${colors.red};

    i {
      text-decoration: line-through;
    }
  }
`;

export const Button = styled(AntButton).attrs({
  type: 'primary',
  size: 'large',
  shape: 'round'
})`
  ${mixins.size('349px', '51px !important')};
  font-size: ${fontSize.large} !important;
  letter-spacing: 0.24px;
  margin: 20px 0 10px;
  border-color: transparent !important;
  border-radius: 30px !important;
  background: linear-gradient(180deg, ${colors.deepCyan} 1.56%, ${colors.deepCyan1} 100%);
`;

export const TrailDescription = styled.div`
  ${lightFontMixin};
  margin-bottom: 55px;
`;

export const TieredPrice = styled.div`
  font-size: ${fontSize.normal};
  margin-top: 40px;
`;

export const ChartContainer = styled.div`
  display: flex;
  margin: 25px 0;
  font-size: ${fontSize.xSmall};
  color: ${colors.darkShadeGrey};
`;

const PlanChartSectionContainer = styled.div`
  margin-right: 1px;

  &:last-child {
    margin-right: 0;
  }
`;

const PlanChartName = styled.div`
  position: relative;

  span {
    position: absolute;
    right: -4px;
  }
`;

const PlanChartBar = styled.div`
  height: 10px;
`;

const PlanChartMinUsers = styled.div`
  position: relative;
  left: -10px;
`;

const PlanChartMaxUsers = styled.div``;

const PlanChartRange = styled.div`
  ${spaceBetweenMixin};
`;

const PlanChartSection = ({ className, name, minUsers = '', maxUsers = '' }) => (
  <PlanChartSectionContainer className={className}>
    <PlanChartName>
      {name}
      <span>+</span>
    </PlanChartName>
    <PlanChartBar />
    <PlanChartRange>
      <PlanChartMinUsers>{minUsers}</PlanChartMinUsers>
      <PlanChartMaxUsers>{maxUsers}</PlanChartMaxUsers>
    </PlanChartRange>
  </PlanChartSectionContainer>
);

export const BaseChartSection = styled(PlanChartSection)`
  flex: 56;

  ${PlanChartBar} {
    background: ${colors.lightTintGrey};
  }

  ${PlanChartMinUsers} {
    left: 0;
  }
`;

export const Tier1ChartSection = styled(PlanChartSection)`
  flex: 145;

  ${PlanChartBar} {
    background: ${colors.darkTintBlue};
  }
`;

export const Tier2ChartSection = styled(PlanChartSection)`
  flex: 208;

  ${PlanChartBar} {
    background: ${blueColor};
  }
`;

export const Tier3ChartSection = styled(PlanChartSection)`
  flex: 148;

  ${PlanChartName} {
    span {
      display: none;
    }
  }

  ${PlanChartBar} {
    background: ${colors.lightBlue};
  }
`;

export const Example = styled(LangWrapper)`
  ${lightFontMixin};
  margin-bottom: 40px;
`;

export const ContactUs = styled(LangWrapper)`
  margin-bottom: 50px;
`;

export const Explaination = styled(LangWrapper)`
  ${lightFontMixin};
  padding: 0 40px;

  p {
    margin-bottom: 0;
  }
`;
