import styled from 'styled-components';
import { Layout } from 'antd';
import { bodyBackground, modalBackgroundColor } from 'src/App.styled';

const { Sider, Content } = Layout;

export const SliderWrapper = styled(Sider).attrs({
  width: '300px'
})`
  background-color: ${modalBackgroundColor};
  padding: ${props => (props.collapsed ? 0 : '20px')};

  &.at_securityScoreDetailPage {
    flex: 0 0 200px !important;
    min-width: 200px !important;
    width: 200px !important;
  }
`;

export const ContentWrapper = styled(Content)`
  background-color: ${bodyBackground};
`;
