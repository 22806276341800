// @flow

import { takeEvery, takeLatest, put, all, fork, select } from 'redux-saga/effects';

import { getSecurityData } from 'src/pages/dashboard/dashboard.api';
import { orgIdSelector } from 'src/redux/selectors';
import { STORAGE_KEYS } from 'src/constants';
import * as actions from './drawer.duck';
import * as apiCalls from './drawer.api';
import * as utils from './drawer.utils';

/*************
 *   SAGAS   *
 *************/

function* getSecurityControlProfileDataSaga({ payload: clientId }): any {
  const partnerId = yield select(orgIdSelector);
  const query = utils.genControlProfileQuery({ clientId, partnerId });

  yield put(actions.setSecurityControlProfileLoadingState(true));
  yield put(actions.setSecurityControlProfileClientId(clientId));

  try {
    const data = yield getSecurityData(query);

    yield put(actions.getSecurityControlProfileDataSuccess(utils.transformControlProfileResponse(data)));
    yield put(actions.setSecurityControlProfileLoadingState(false));
  } catch (err) {
    yield put(actions.setSecurityControlProfileLoadingState(false));
  }
}

function* setDownloadQueryReportSaga({ payload: { clientId, clientName } }): any {
  yield put(actions.setDownloadQueryReportLoadingState(true));
  const partnerId = yield select(orgIdSelector);
  const query = utils.genDownloadReportQuery({ clientId, partnerId });

  try {
    yield apiCalls.downloadQueryReport({ orgId: partnerId, clientName, data: query });
  } finally {
    yield put(actions.setDownloadQueryReportLoadingState(false));
  }
}

function* disableDrawerTooltipSaga(): any {
  yield localStorage.setItem(STORAGE_KEYS.HIDE_SECURITY_SCORE_RECOMMENDATION_TOOLTIP, '1');
}

export default function*(): any {
  yield all([
    fork(takeLatest, actions.getSecurityControlProfileData, getSecurityControlProfileDataSaga),
    fork(takeEvery, actions.setDownloadQueryReport, setDownloadQueryReportSaga),
    fork(takeLatest, actions.disableDrawerTooltip, disableDrawerTooltipSaga)
  ]);
}
