import styled, { css } from 'styled-components';
import { Menu as AntMenu } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { constants } from '@accordo-feed/aco-styled-components';
import { appTextColor, bodyBackground, widgetBorderColor } from 'src/App.styled';

const { colors, fontSize } = constants;

export const iconMixins = css`
  fill: ${colors.lightGrey2};
  font-size: ${fontSize.normal};
  margin-right: 8px;
`;

export const SettingThemeWrapper = styled.div`
  display: inline-block;
  position: relative;
  top: -12px;
`;

export const Menu = styled(AntMenu)`
  background-color: ${bodyBackground};
  border: 1px solid ${widgetBorderColor};
  .ant-dropdown-menu-item {
    background-color: ${bodyBackground};
    color: ${appTextColor};
    font-size: ${fontSize.xSmall};
    &:last-child {
      border-top: 1px solid ${widgetBorderColor};
    }
    &:hover {
      opacity: 0.7;
    }
  }
`;

export const SettingIcon = styled(SettingOutlined)`
  font-size: ${fontSize.xLarge};
  color: ${colors.darkGrey};
  padding: 3px;
  margin-left: 10px;
  cursor: pointer;
  &:hover,
  &:focus,
  &:active {
    color: ${colors.blue};
  }
`;

export const ToggleLabel = styled.p`
  color: ${appTextColor};
  font-size: ${fontSize.xxSmall};
  margin-top: 2px;
  text-transform: capitalize;
`;
