// @flow
import * as React from 'react';

import * as Styled from './Error.wrapper.styled';

/*************
 *   TYPES   *
 *************/

type Props = {
  children: React.Node,
  show: boolean
};

/*****************
 *   COMPONENT   *
 *****************/

const ErrorWrapper = ({ children, show }: Props) =>
  show ? (
    <Styled.ErrorWrapper>
      <Styled.ErrorBlurEffect>{children}</Styled.ErrorBlurEffect>
      <Styled.ErrorIcon />
    </Styled.ErrorWrapper>
  ) : (
    children
  );

export default ErrorWrapper;
