import * as R from 'ramda';
import { isEmptyOrNil } from 'src/utils';

const CHART_WIDTH = 280;
const CHART_LEFT_MARGIN = 35;

const colorPalette = [
  '#EF4444',
  '#F97316',
  '#FCD34D',
  '#FDE047',
  '#67E8F9',
  '#38BDF8',
  '#60A5FA',
  '#BEF264',
  '#84CC16',
  '#86EFAC'
];

const getTooltipConfig = () => {
  const contentStyles = 'display: block; font-size: 12px;';
  const bulletSpan = () => {
    const styles = [
      'display: inline-block',
      'margin-right: 4px',
      'margin-bottom: 2px',
      'border-radius: 50%',
      'width: 5px',
      'height: 5px',
      'background-color: white'
    ];
    return `<span style="${styles.join(';')}"></span>`;
  };

  return {
    trigger: 'axis',
    position: { left: '20%', top: '-40%' },
    axisPointer: {
      type: 'shadow'
    },
    padding: 10,
    formatter: params => {
      let html = '';
      const { data, dataIndex, name } = params[0];
      const level = dataIndex < 4 ? 'Low score' : dataIndex < 7 ? 'Moderate' : 'High score';

      if (data.customerData) {
        html = `<span style="${contentStyles}">${level} ${name}</span>`;
        html += data.customerData
          .map(({ clientName, currentScore, maxScore }) => {
            return `<span style="${contentStyles}">${bulletSpan()} ${clientName}: ${Math.round(
              currentScore
            )}/${Math.round(maxScore)}</span>`;
          })
          .join('');
      }

      return html;
    }
  };
};

const getMarkLineConfig = percentage => {
  const xPos = CHART_WIDTH * percentage + CHART_LEFT_MARGIN;

  return [
    {
      x: xPos,
      y: 140,
      symbol: 'none',
      lineStyle: { color: '#9497B0', width: 1 }
    },
    {
      x: xPos,
      y: 0,
      symbol: 'none'
    }
  ];
};

export const createChartOption = (data, barClickedIndex) => {
  if (isEmptyOrNil(data)) {
    return {};
  }

  const { xAxis, yAxis } = data;
  let interval = 5;
  const barsData = yAxis.values.map((record, i) => {
    return {
      value: record.value,
      customerData: record.customerData,
      itemStyle: { color: colorPalette[i] }
    };
  });

  const maxValue = Math.max(...barsData.map(data => data.value));
  if (maxValue > 50) {
    interval = Math.ceil(maxValue / 5);
  }

  const dataShadow =
    barClickedIndex >= 0 ? barsData.map((data, i) => (barClickedIndex === i ? maxValue : 0)) : barsData.map(() => 0);
  const grid = {
    top: 20,
    right: -20,
    left: CHART_LEFT_MARGIN
  };

  return {
    tooltip: getTooltipConfig(),
    grid,
    xAxis: {
      max: 10,
      name: xAxis.name,
      nameLocation: 'center',
      nameGap: 25,
      nameTextStyle: {
        color: '#9497B0',
        fontSize: 10,
        fontFamily: "'Poppins', sans-serif"
      },
      type: 'category',
      data: xAxis.values,
      axisTick: { show: false },
      axisLine: { show: false },
      axisLabel: {
        padding: [0, 5, 0, 0],
        align: 'right',
        interval: 0,
        color: '#9497B0',
        fontSize: 10,
        fontFamily: "'Poppins', sans-serif",
        formatter: (value, index) => data.xAxis.values[index].label.replace(/%/g, '')
      }
    },
    yAxis: {
      name: yAxis.name,
      nameLocation: 'center',
      nameGap: 25,
      nameTextStyle: {
        color: '#9497B0',
        fontSize: 10,
        fontFamily: "'Poppins', sans-serif"
      },
      type: 'value',
      interval,
      axisLine: { show: false },
      axisLabel: {
        color: '#9497B0',
        fontSize: 10,
        fontFamily: "'Poppins', sans-serif"
      },
      axisTick: { show: false },
      splitLine: { show: false }
    },
    series: [
      {
        data: barsData,
        type: 'bar',
        barCategoryGap: 12,
        barMaxWidth: 16,
        markLine: {
          data: [getMarkLineConfig(0.4), getMarkLineConfig(0.7)]
        },
        itemStyle: { borderRadius: [180, 180, 180, 180] }
      },
      {
        // For shadow
        type: 'bar',
        itemStyle: { color: 'rgba(0,0,0,0.12)' },
        barGap: '-100%',
        data: dataShadow,
        animation: false
      }
    ]
  };
};

/*****************************
 *   TRANSFORM ES RESPONSE   *
 *****************************/

export const defaultRanges = [
  '0%-10%',
  '10%-20%',
  '20%-30%',
  '30%-40%',
  '40%-50%',
  '50%-60%',
  '60%-70%',
  '70%-80%',
  '80%-90%',
  '90%-100%'
];

export const defaultSecurityWidgetData = {
  count: 0,
  average: 0,
  lowScore: 0,
  xAxis: { values: [] },
  yAxis: { values: [] }
};

/**
 * Get the part of string before "-", e.g. "10%" of "10%-20%"
 * @param {string} value
 * @returns {string}
 */
export const extractLowerRange = value => value.match(/[^-]*/i)[0];

/**
 * Get all the clients for a single range (e.g. 10%-20%)
 * @param {Array} range
 * @returns {Array}
 */
const getClientsPerRange = range =>
  R.reduce(
    (acc, value) => {
      const {
        _source: { clientName, currentScore, maxScore }
      } = value;
      return R.concat(acc, [{ clientName, currentScore, maxScore }]);
    },
    [],
    R.pathOr([], ['clients', 'hits', 'hits'], range)
  );

/**
 * Generate the bar chart xAxis option values
 * @param {Array} rangeArray
 * @returns {Array}
 */
export const generateXAxisData = (rangeArray = defaultRanges) => {
  if (isEmptyOrNil(rangeArray)) {
    return [];
  }

  const lastObj = { value: '', label: '100%' };

  const reducer = (acc, value) =>
    R.concat(acc, [
      {
        label: extractLowerRange(value) === '0' ? '0%' : extractLowerRange(value),
        value
      }
    ]);

  const output = R.reduce(reducer, [], rangeArray);

  return [...output, lastObj];
};

/**
 * Generate the bar chart yAxis option values
 * @param {Array} valueArray
 * @returns {Array}
 */
export const generateYAxisData = valueArray => {
  if (isEmptyOrNil(valueArray)) {
    return [];
  }

  const reducer = (acc, value) =>
    R.concat(acc, [
      {
        value: R.propOr(0, 'doc_count', value),
        customerData: getClientsPerRange(value)
      }
    ]);

  const output = R.reduce(reducer, [], valueArray);

  return output;
};

/**
 * Transform the security widget ES response to the format echart understands
 * @param {Object} response
 * @returns {Object}
 */
export const transform = response => {
  const { aggregations, hits } = response;

  if (isEmptyOrNil(aggregations)) {
    return defaultSecurityWidgetData;
  }

  const count = R.pathOr(0, ['total', 'value'], hits);
  const scoreRanges = R.pathOr([], ['scoreRanges', 'buckets'], aggregations);
  const avgScore = R.pathOr(0, ['avgScore', 'value'], aggregations);
  const lowScore = R.pathOr(0, ['lowScore', 'buckets', 'value', 'doc_count'], aggregations);

  const transformation = {
    count: R.always(count),
    average: R.always(avgScore),
    lowScore: R.always(lowScore),
    xAxis: {
      values: R.always(generateXAxisData(Object.keys(scoreRanges)))
    },
    yAxis: {
      values: R.always(generateYAxisData(Object.values(scoreRanges)))
    }
  };

  return R.evolve(transformation, defaultSecurityWidgetData);
};

/**
 * get security opportunities count to show on the dashboard
 * @param {Object} data
 * @returns {Number}
 */
export const getSecurityOpportunities = data => {
  const { clients = [] } = data;
  const notCompleteActionCount = clients.reduce((a, b) => a + (b.improvementActionCount?.notCompleted || 0), 0);
  return notCompleteActionCount;
};
