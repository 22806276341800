// @flow

import * as R from 'ramda';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { redux } from '@accordo-feed/micro-frontends';

import * as selectors from 'src/redux/selectors';
import GlobalHeader from './GlobalHeader.component';

const { bindActionCreatorsCurried } = redux;

/*************
 *   REDUX   *
 *************/

const mapStateToProps = R.applySpec({
  customers: selectors.customersFilteredDataSelector
});

const mapDispatchToProps = bindActionCreatorsCurried({});

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(GlobalHeader);
