import React from 'react';
import { connect } from 'react-redux';

import { Elements, StripeProvider as OfficialProvider } from 'react-stripe-elements';

import * as selectors from 'src/redux/selectors';
import { getStripeAPIkey } from 'src/utils/stripe';

const StripeProviderBase = ({ orgId, children }) => (
  <OfficialProvider apiKey={getStripeAPIkey(orgId)}>
    <Elements>{children}</Elements>
  </OfficialProvider>
);

/*************
 *   REDUX   *
 *************/

const mapStateToProps = state => ({
  orgId: selectors.orgIdSelector(state)
});

export const StripeProvider = connect(mapStateToProps)(StripeProviderBase);
