// @flow

import { compose } from 'recompose';
import { connect } from 'react-redux';
import { translateHOC } from '@accordo-feed/language.entry';

import * as selectors from 'src/redux/selectors';
import LicensePricing from './LicensePricing.component';
import { formatCurrencyHOC } from 'src/hoc';

/*************
 *   REDUX   *
 *************/

const mapStateToProps = state => ({
  orgId: selectors.orgIdSelector(state)
});

export default compose(
  connect(mapStateToProps),
  formatCurrencyHOC,
  translateHOC
)(LicensePricing);
