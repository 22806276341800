import { langHelper } from '@accordo-feed/micro-frontends-utils';

const genId = langHelper.genLangId('shell.aco.dashboard.widget.general');
const genOpts = langHelper.genLangOptions(genId);

export const lang = {
  noDataConnection: genOpts('noDataConnection', 'Failed to Retrieve Data'),
  loadingData: genOpts('loadingData', 'Loading Data...'),
  errorMsg: genOpts('errorMsg', 'Something went wrong')
};

export default lang;
