// @flow

import { createAction } from 'redux-actions';
import { redux } from '@accordo-feed/micro-frontends';

import type { Action } from 'src/redux/types';
import { wrapWithModule } from 'src/redux/utils';

const { setState } = redux;

/*************
 *   TYPES   *
 *************/

type MenuType = {
  subMenuOpenKeys: Array<string>,
  collapsed: boolean
};

/*********************
 *   INITIAL STATE   *
 *********************/

const initialState: MenuType = {
  subMenuOpenKeys: [],
  collapsed: false
};

/***************
 *   ACTIONS   *
 ***************/

const wrapWithNamespace = wrapWithModule('modules/menu');
const SET_SUB_MENU_OPEN_KEYS = wrapWithNamespace('SET_SUB_MENU_OPEN_KEYS');
const SET_MENU_COLLAPSED_STATE = wrapWithNamespace('SET_MENU_COLLAPSED_STATE');

export const setSubMenuOpenKeys = createAction(SET_SUB_MENU_OPEN_KEYS);
export const setMenuCollapsedState = createAction(SET_MENU_COLLAPSED_STATE);

/***************
 *   REDUCER   *
 ***************/

export default (state: MenuType = initialState, action: Action) => {
  const { type, payload } = action;

  const reducer = {
    [SET_SUB_MENU_OPEN_KEYS]: setState('subMenuOpenKeys'),
    [SET_MENU_COLLAPSED_STATE]: setState('collapsed')
  }[type];

  return reducer ? reducer(state, payload) : state;
};
