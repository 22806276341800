import styled from 'styled-components';
import { constants, mixins, Icon } from '@accordo-feed/aco-styled-components';

const { colors, fontSize } = constants;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.white};
  z-index: 1020;
`;

export const OverlayTitle = styled.div`
  padding: 10px 15px;
  border-bottom: 1px solid ${colors.hoverGrey};
  background-color: ${colors.blue};
  line-height: 24px;
  font-size: ${fontSize.xSmall};
  color: ${colors.white};
`;

export const BackButton = styled.button`
  ${mixins.flexVerticalCenter};
  position: absolute;
  left: -5px;
  bottom: 6px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: ${fontSize.small};
  line-height: 24px;
  color: ${colors.blue};
`;

export const ArrowIcon = styled(Icon).attrs({
  color: colors.blue,
  size: '30px'
})``;
