import { langHelper } from '@accordo-feed/micro-frontends-utils';

const genFooterId = langHelper.genLangId('shell.aco.footer');
const genFooterLang = langHelper.genLangOptions(genFooterId);

export const lang = {
  copyright: genFooterLang('copyright', 'Entori™. All rights reserved.'),
  siteHost: genFooterLang('siteHostText', 'This site is hosted by Entori LLC.'),

  // these keys are linked to constant values in FooterLink.component
  // so if you change them here then change constants there
  privacyLinkUrl: genFooterLang('privacyLink.url', 'https://entori.com/privacy-policy'),
  privacyLinkText: genFooterLang('privacyLink.text', 'Privacy & Cookies'),
  termsLinkUrl: genFooterLang('termsLink.url', 'https://entori.com/terms-and-conditions'),
  termsLinkText: genFooterLang('termsLink.text', 'Terms of Use')
};
