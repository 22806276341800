import * as R from 'ramda';
import { utils } from '@accordo-feed/micro-frontends';

const isMinified = useMinified => {
  if (R.is(Boolean, useMinified)) {
    return useMinified;
  } else {
    return __MINIFY_SUBMODULE_BUNDLES__ || __DEPLOYMENT_ENV__ !== 'local';
  }
};

export const getFilepath = (moduleName, bundleName, useMinified) =>
  `/submodules/aco-${moduleName}/${bundleName}${isMinified(useMinified) ? '.min' : ''}.js`;

export const loadRemoteBundle = (moduleName, bundleName, useMinified) =>
  new Promise(resolve => {
    utils
      .loadRemoteBundle(getFilepath(moduleName, bundleName, useMinified))
      .then(resolve)
      .catch(err => {
        // TODO: PE-94 config sentry
        console.error('Load Remote Bundle error: ', err);

        // Will response undefined when an error occurs. Please check the use cases in
        // "src/redux/sagas.js" and "src/redux/rootReducer.js"
        resolve(undefined);
      });
  });
