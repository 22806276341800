import React from 'react';
import { Menu, Dropdown } from 'antd';
import { router } from '@accordo-feed/micro-frontends';

import { PATHS } from 'src/constants';
import { lang } from '../appSider/menu/shellMenu.lang';
import * as Styled from './SettingTheme.styled';
const { ACCOUNTS, PSA, TEAM_INVITES } = PATHS;

type Props = {
  hasTeamInvites: boolean,
  hasNotifications: boolean,
  isDistributor: boolean,
  isPartnerMember: boolean
};

const SettingTheme = ({ router, isPartnerMember, hasNotifications, isDistributor, hasTeamInvites }: Props) => {
  const onClickEvent = path => () => router.push(path);

  const menu = (
    <Styled.Menu>
      {!isPartnerMember && (
        <Menu.Item className="at_profileMenuItem" key={ACCOUNTS.PROFILE} onClick={onClickEvent(PATHS.ACCOUNTS.PROFILE)}>
          {lang.profile}
        </Menu.Item>
      )}
      {hasNotifications && (
        <Menu.Item
          className="at_notificationsSettingsMenuItem"
          key={ACCOUNTS.NOTIFICATIONS_SETTINGS}
          onClick={onClickEvent(ACCOUNTS.NOTIFICATIONS_SETTINGS)}
        >
          {lang.notificationsSettings}
        </Menu.Item>
      )}
      {hasTeamInvites && !isPartnerMember && (
        <Menu.Item
          className="at_teamManagementMenuItem"
          key={TEAM_INVITES.ROOT}
          onClick={onClickEvent(TEAM_INVITES.ROOT)}
        >
          {lang.teamManagement}
        </Menu.Item>
      )}
      <Menu.Item className="at_psaMenuItem" key={PSA.ROOT} onClick={onClickEvent(PATHS.PSA.ROOT)}>
        {lang.psa}
      </Menu.Item>
      {!isDistributor && !isPartnerMember && (
        <Menu.Item className="at_billingMenuItem" key={ACCOUNTS.BILLING} onClick={onClickEvent(ACCOUNTS.BILLING)}>
          {lang.billing}
        </Menu.Item>
      )}
    </Styled.Menu>
  );

  return (
    <Styled.SettingThemeWrapper>
      <Dropdown overlay={menu}>
        <div className="ant-dropdown-link" onClick={e => e.preventDefault()}>
          <Styled.SettingIcon />
        </div>
      </Dropdown>
    </Styled.SettingThemeWrapper>
  );
};

export default router.withRouterHOC(SettingTheme);
