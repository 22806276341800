// @flow

import React from 'react';
import { useTranslate } from '@accordo-feed/language.entry';

import * as Styled from './rightSide.styled';
import PriceBox from './priceBox';
import lang from './rightSide.lang';

const cardLang = lang.card;

/*************
 *   TYPES   *
 *************/

type Props = {
  discount: number,
  onTrailButtonClick: Function,
  plans: Array<Object>
};

/*****************
 *   COMPONENT   *
 *****************/

export default ({ discount, onTrailButtonClick, plans }: Props) => {
  const translate = useTranslate();

  return (
    <Styled.Container>
      <Styled.Title>{translate(lang.title)}</Styled.Title>

      <Styled.Content>
        <Styled.Plans>
          {plans.map(plan => (
            <PriceBox {...plan} discount={discount} key={plan.tierNum} />
          ))}
        </Styled.Plans>
        <Styled.BaseFee>{translate(lang.baseFee)}</Styled.BaseFee>
        <Styled.Button onClick={onTrailButtonClick}>{translate(lang.button)}</Styled.Button>
        <Styled.TrailDescription>{translate(lang.trialDescription)}</Styled.TrailDescription>
      </Styled.Content>

      <Styled.FixedWidthContainer>
        <Styled.TieredPrice>{translate(lang.tieredPrice)}</Styled.TieredPrice>

        <Styled.ChartContainer>
          <Styled.BaseChartSection name={translate(lang.minFee)} minUsers="0" />
          <Styled.Tier1ChartSection name={translate(cardLang.title, { tierNum: 1 })} minUsers="500" />
          <Styled.Tier2ChartSection name={translate(cardLang.title, { tierNum: 2 })} minUsers="2,500" />
          <Styled.Tier3ChartSection
            name={translate(cardLang.title, { tierNum: 3 })}
            minUsers="10,000"
            maxUsers="Active users"
          />
        </Styled.ChartContainer>

        <Styled.Example>{translate(lang.example)}</Styled.Example>
        <Styled.ContactUs>{translate(lang.contactUs)}</Styled.ContactUs>
      </Styled.FixedWidthContainer>

      <Styled.Explaination>{translate(lang.explaination)}</Styled.Explaination>
    </Styled.Container>
  );
};
