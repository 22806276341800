import styled from 'styled-components';
import { mixins, constants } from '@accordo-feed/aco-styled-components';

const { colors, fontSize } = constants;

export const Wrapper = styled.div`
  position: relative;
`;

export const Container = styled.div`
  ${mixins.flexVerticalCenter()};
  height: 60px;
  margin-bottom: 10px;
  padding: 0 20px;
  background: ${colors.hoverGrey};
`;

export const Title = styled.span`
  font-size: ${fontSize.large};
`;
