// @flow

import React from 'react';
import { ChevronRightRounded } from '@material-ui/icons';

import * as Styled from './appSider.styled';
import Menu from './menu';

/*************
 *   TYPES   *
 *************/

type AppProps = {
  collapsed: boolean,
  setCollapsed: Function
};

/*****************
 *   COMPONENT   *
 *****************/

const AppSider = ({ collapsed, setCollapsed }: AppProps) => {
  const toggleSider = () => setCollapsed(!collapsed);

  return (
    <Styled.Sider collapsible collapsedWidth={68} collapsed={collapsed}>
      <Menu collapsed={collapsed} />
      <Styled.ToggleContainer onClick={toggleSider}>
        <Styled.ToggleIcon className={!collapsed && 'expand'} element={ChevronRightRounded} />
      </Styled.ToggleContainer>
    </Styled.Sider>
  );
};

export default AppSider;
