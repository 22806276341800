import styled from 'styled-components';
import { constants } from '@accordo-feed/aco-styled-components';
import { appTextColor } from 'src/App.styled';

const { colors, fontSize } = constants;

export const LegendContainer = styled.div`
  z-index: 10;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
`;

export const LegendItem = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: ${fontSize.xSmall};
`;

export const LegendTitle = styled.div`
  font-weight: normal;
  font-size: ${fontSize.xSmall};
  line-height: 1.5;
  color: ${appTextColor};
`;

export const LegendValue = styled.span`
  font-weight: 600;
  font-size: 32px;
  line-height: 1.5;
  color: ${colors.aquaRed};
  &.average {
    color: ${colors.aquaBlue3};
  }
  &.high {
    color: #5cd470;
  }
`;
