import { core } from '@accordo-feed/micro-frontends-utils';

import { API } from 'src/utils/api';
import { resolveApiEndpoint, requestHandler } from 'src/utils';

/*****************
 *   API CALLS   *
 *****************/

const profileUpdateApi = resolveApiEndpoint(API.PUT_PROFILE);
const updateMfaApi = resolveApiEndpoint(API.UPDATE_MFA);
const postCustomerLogoApi = resolveApiEndpoint(API.POST_CUSTOMER_LOGO);

export const putProfile = ({ orgId, data }: { orgId: string, data: Object }): Promise<Object> =>
  requestHandler({
    method: 'PUT',
    url: core.replaceAll(profileUpdateApi, { orgId }),
    data
  });

export const postCustomerLogo = ({ orgId, data }: { orgId: string, data: Object }): Promise<Object> =>
  requestHandler({
    method: 'POST',
    url: core.replaceAll(postCustomerLogoApi, { orgId }),
    data
  });

export const updateMfa = ({ orgId, data }: { orgId: string, data: Object }): Promise<Object> =>
  requestHandler({
    method: 'PUT',
    url: core.replaceAll(updateMfaApi, { orgId }),
    data
  });
