import { getConfigValue } from 'src/config';

/*************
 *   TYPES   *
 *************/

type getStripeAPIkeyProps = {
  orgId: string
};

/***************
 *   HELPERS   *
 ***************/

export const getStripeAPIkey = (orgId: getStripeAPIkeyProps) => {
  let apiKey = getConfigValue('stripeTestKey');
  const liveKey = getConfigValue('stripeLiveKey');

  // https://accordo.atlassian.net/wiki/spaces/ACO/pages/731578392/Test+Data+conventions+for+ACO
  const isTestUser = orgId.startsWith('ac_ffffffff');

  if (liveKey && !isTestUser) {
    apiKey = liveKey;
  }

  return apiKey;
};
