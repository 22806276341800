import React from 'react';
import { langHelper } from '@accordo-feed/micro-frontends-utils';
import { LanguageEntry } from '@accordo-feed/language.entry';

const genId = langHelper.genLangId('shell.aco.growthTrend.page');
const genOpts = langHelper.genLangOptions(genId);

export default {
  pageTitle: genOpts('title', 'Customer Growth'),
  // TODO: Update language entry
  introduction: (
    <LanguageEntry
      id="shell.aco.growthTrend.page.introduction"
      defaultMessage="<h2>Narrative to help with Widget’s purpose and how
    to act on it.</h2><p>Lorem ipsum dolor sit amet, consectetur adipiscing
    elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet
    lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor.</p>"
    />
  ),
  table: {
    activeUsersGrowth: genOpts('th.activeUsersGrowth', 'Active Users Growth'),
    licensesGrowth: genOpts('th.licensesGrowth', 'Licenses Growth'),
    spendGrowth: genOpts('th.spendGrowth', 'Spend Growth'),
    overallGrowth: genOpts('th.overallGrowth', 'Overall Growth'),
    viewGrowthRecommendations: genOpts('th.viewGrowthRecommendations', 'View Growth Recommendations'),
    viewRecommendations: genOpts('td.viewRecommendations', 'View recommendations')
  },
  filter: {
    fiterTitle1: genOpts('filter.title1', 'Active users'),
    fiterTitle2: genOpts('filter.title2', 'Total monthly spend'),
    tooltip1: genOpts('filter.tooltip1', 'Total monthly spend')
  },
  widgetTitle: genOpts('title', 'Customer Growth:'),
  activeUsers: genOpts('activeUsers', 'Active users'),
  totalLicenses: genOpts('totalLicenses', 'License count'),
  monthlySpend: genOpts('monthlySpend', 'Spend'),
  actualLine: genOpts('actualLine', 'Actual'),
  forecastLine: genOpts('forecastLine', 'Forecast'),
  positive: genOpts('positive', 'Positive'),
  negative: genOpts('negative', 'Negative')
};
