import * as R from 'ramda';

/**
 * Check if any filters have been selected
 * @param {{...filter1?: any[],filter2?: any[]}} selectedFiltersObj
 * @returns {boolean}
 */
export const hasFiltersApplied = selectedFiltersObj =>
  R.pipe(
    R.keys,
    R.map(o => ({ [o]: R.isEmpty })),
    R.mergeAll,
    R.flip(R.where)(selectedFiltersObj),
    R.not
  )(selectedFiltersObj);
