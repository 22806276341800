// @flow
import React from 'react';
import { useTranslate } from '@accordo-feed/language.entry';

import lang from './growthTrend.lang';
import * as Styled from './growthTrend.styled';

/*****************
 *   COMPONENT   *
 *****************/

const LineIndicator = () => {
  const translate = useTranslate();
  return (
    <Styled.LineWrapper>
      <div>
        <Styled.ActualLine />
        <span>{translate(lang.actualLine)}</span>
      </div>
      <div>
        <Styled.ForecastLine />
        <span>{translate(lang.forecastLine)}</span>
      </div>
    </Styled.LineWrapper>
  );
};

export default LineIndicator;
