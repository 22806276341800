// @flow

import React, { useState } from 'react';
import { ButtonElement } from '@accordo-feed/aco-styled-components';
import * as Styled from './errorModal.styled';

/*************
 *   TYPES   *
 *************/

type Props = {
  onClick: Function,
  show: boolean,
  header: string,
  para1: string,
  para2: string,
  button: string,
  errorCode: string,
  message: string,
  showMore: string,
  showLess: string
};

/******************
 *   COMPONENTS   *
 ******************/

const ErrorExpander = ({ message, errorCode, showMore, showLess }: Props) => {
  const [show, setShow] = useState(false);
  const onClick = () => setShow(!show);

  return (
    <Styled.Paragraph>
      {show && (
        <div>
          <b>{errorCode}</b>
          <p>{message}</p>
        </div>
      )}
      <ButtonElement type="link" onClick={onClick}>
        {show ? showLess : showMore}
      </ButtonElement>
    </Styled.Paragraph>
  );
};

const ErrorModal = (props: Props) => {
  const { header = '', para1 = '', para2 = '', button, onClick, show } = props;
  return (
    <Styled.Modal visible={show}>
      <Styled.Wrapper>
        <Styled.ErrorPageIcon />
        <Styled.Header>{header}</Styled.Header>
        <Styled.Paragraph>{para1}</Styled.Paragraph>
        <Styled.Paragraph>{para2}</Styled.Paragraph>
        <ErrorExpander {...props} />
        <ButtonElement margin="20px 0 0 0" variant="primary" onClick={onClick}>
          {button}
        </ButtonElement>
      </Styled.Wrapper>
    </Styled.Modal>
  );
};

export default ErrorModal;
