import * as acoStyledComponents from '@accordo-feed/aco-styled-components';
import * as formik from 'formik';
import * as yup from 'yup';
import * as languageEntry from '@accordo-feed/language.entry';
import * as microFrontends from '@accordo-feed/micro-frontends';
import * as microFrontendsUtils from '@accordo-feed/micro-frontends-utils';
import * as reactGridLayout from 'react-grid-layout';
import ReactECharts from 'echarts-for-react';
import fileSaver from 'file-saver';
import html2canvas from 'html2canvas';
import queryString from 'query-string';

export const initSharedDependencies = () => {
  microFrontends.utils.initSharedDependencies({
    acoStyledComponents,
    formik,
    yup,
    languageEntry,
    microFrontends,
    microFrontendsUtils,
    reactGridLayout,
    ReactECharts,
    fileSaver,
    html2canvas,
    queryString
  });
};
