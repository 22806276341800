// @flow

import { takeLatest, put, all, fork } from 'redux-saga/effects';
import { jwtDecoder } from '@accordo-feed/micro-frontends-utils';

import * as actions from './userData.duck';
import { SHELL_NAME } from 'src/constants';

function* getUserDataStateSaga(): any {
  let data = jwtDecoder.decodeToken({ shellId: SHELL_NAME });
  yield put(actions.getUserDataSuccess(data));
}

export default function*(): any {
  yield all([fork(takeLatest, actions.getUserData, getUserDataStateSaga)]);
}
