// @flow

import React from 'react';
import { useTranslate } from '@accordo-feed/language.entry';

import { FormRow } from 'src/CustomComponent.styled';
import CountryPicker from '../countryPicker';
import StatePicker from '../statePicker';
import AddressField from './AddressField.component';
import { AddressErrorMessage } from './addressField.styled';
import { lang } from './addressForm.lang';

/*************
 *   TYPES   *
 *************/

type Props = {
  region: string,
  country: string,
  errors: Array<string>,
  setFieldValue: (fieldName: string, fieldValue: any) => void
};

/*****************
 *   COMPONENT   *
 *****************/

export const AddressForm = ({ region = '', country = '', setFieldValue, errors }: Props) => {
  const translate = useTranslate();

  const showStatePicker = country === 'ca' || country === 'us';

  const setField = (fieldName: string) => value => {
    setFieldValue(fieldName, value);
  };

  const setCountry = value => {
    setFieldValue('country', value);
    setFieldValue('region', '');
  };

  return (
    <div className="inspectletIgnore">
      <FormRow>
        <AddressField name="country" label={translate(lang.country)}>
          <CountryPicker registeredCountryCode={country} onSelect={setCountry} includeAllCountries={false} />
        </AddressField>
      </FormRow>

      <FormRow>
        <AddressField name="line1" maxLength={50} label={translate(lang.line1)} />
      </FormRow>

      <FormRow>
        <AddressField name="line2" maxLength={50} label={translate(lang.line2)} required={false} />
      </FormRow>

      <FormRow>
        <AddressField name="city" maxLength={50} label={translate(lang.city)} />
      </FormRow>

      {showStatePicker && (
        <FormRow>
          <AddressField name="region" label={translate(lang.region)}>
            <StatePicker value={region} defaultValue={region} onSelect={setField('region')} country={country} />
          </AddressField>
        </FormRow>
      )}

      <FormRow marginBottom="0">
        <AddressField name="postalCode" maxLength={11} label={translate(lang.postalCode)} />
      </FormRow>

      <AddressErrorMessage errors={errors} message={translate(lang.addressErrorTitle)} />
    </div>
  );
};
