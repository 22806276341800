import { combineReducers } from 'redux';

import drawerReducer from './drawer/drawer.duck';
import filterPanelReducer from './filterPanel/filterPanel.duck';
import pageReducer from './securityScorePage.duck';

export default combineReducers({
  drawer: drawerReducer,
  filterPanel: filterPanelReducer,
  page: pageReducer
});
