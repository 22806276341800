import * as R from 'ramda';
import React from 'react';
import styled from 'styled-components';
import { LanguageEntry } from '@accordo-feed/language.entry';
import { Spin } from 'antd';
import { mixins, constants } from '@accordo-feed/aco-styled-components';
import { hexToRgb } from 'src/utils';

const { colors, zIndex } = constants;

const Spinner = styled(Spin)`
  ${mixins.flexCenter()};
  ${mixins.size('100%')};
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  z-index: ${zIndex};
  background: rgba(${hexToRgb(colors.lightGrey)}, 0.1);

  .ant-spin-text {
    color: ${colors.blue};
  }

  .ant-spin-dot-spin {
    margin-bottom: 5px;
  }

  .ant-spin-dot-item {
    background-color: ${colors.blue};
  }
`;

const defaultMsg = <LanguageEntry id="loading.loaderInline.language.id" defaultMessage="Loading..." />;

const loaderInlineHOC = options => WrappedComponent => {
  const { message, isLoadingKey } = R.mergeRight(
    {
      message: defaultMsg,
      isLoadingKey: 'isLoading'
    },
    options
  );

  return class extends React.Component {
    render() {
      const isLoading = this.props[isLoadingKey];

      const spinner = isLoading && <Spinner size="small" tip={message} />;

      return <WrappedComponent {...this.props} spinner={spinner} />;
    }
  };
};

export default loaderInlineHOC;
