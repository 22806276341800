// @flow

import * as R from 'ramda';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { loaderHOC } from 'src/hoc';
import * as selectors from 'src/redux/modules/partnerCenter/partnerCenter.selector';
import SecureApp from './SecureApp.component';

/*************
 *   REDUX   *
 *************/

const mapStateToProps = R.applySpec({
  isLoading: selectors.isLoadingSelector
});

export default compose(connect(mapStateToProps), loaderHOC())(SecureApp);
