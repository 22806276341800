// @flow

import * as R from 'ramda';
import { createSelector } from 'reselect';
import { redux } from '@accordo-feed/micro-frontends';

import { SHELL_NAME, REDUCER_NAMESPACES } from 'src/constants';

export const wrapWithModule = R.curry(redux.getActionWrapper)(SHELL_NAME);

export const getModuleSelector = (key: string | Array<string>, namespace: string = REDUCER_NAMESPACES.SHELL) =>
  createSelector(
    state => R.path([namespace].concat(key), state),
    module => R.clone(module)
  );
