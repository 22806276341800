import * as R from 'ramda';
import { fork, all } from 'redux-saga/effects';

import azureDashboardSaga from 'src/pages/azureDashboard/azureDashboard.saga';
import customerDrawerSaga from 'src/pages/customers/customerDrawer/customerDrawer.saga';
import customersSaga from 'src/pages/customers/customers.saga';
import dashboardSaga from 'src/pages/dashboard/dashboard.saga';
import errorModalSaga from 'src/redux/modules/errorModal/errorModal.saga';
import organizationSaga from 'src/redux/modules/organization/organization.saga';
import partnerCenterPageSaga from 'src/redux/modules/partnerCenter/partnerCenter.saga';
import paymentSaga from 'src/redux/modules/payment/payment.saga';
import profileSaga from 'src/pages/profile/profile.saga';
import secureAppSaga from 'src/pages/secureApp/secureApp.saga';
import securityDrawerSaga from 'src/pages/dashboard/securityScore/drawer/drawer.saga';
import securityFilterPanelSafa from 'src/pages/dashboard/securityScore/filterPanel/filterPanel.saga';
import securityPageSaga from 'src/pages/dashboard/securityScore/securityScorePage.saga';
import userDataSaga from 'src/redux/modules/userData/userData.saga';
import bulkEditTableSaga from 'src/pages/customers/bulkEditTable/bulkEditTable.saga';
import distiBillingSaga from 'src/redux/modules/distiBilling/distiBilling.saga';
import { SUBMODULES } from 'src/constants';
import { loadRemoteBundle } from 'src/utils/submodule';

const loadSagaBundle = moduleName => loadRemoteBundle(moduleName, 'sagaBundle');
const isValidEffects = effects => R.is(Array, effects) && R.not(R.isEmpty(effects));

const effects = [
  fork(azureDashboardSaga),
  fork(customerDrawerSaga),
  fork(customersSaga),
  fork(dashboardSaga),
  fork(errorModalSaga),
  fork(organizationSaga),
  fork(partnerCenterPageSaga),
  fork(paymentSaga),
  fork(profileSaga),
  fork(secureAppSaga),
  fork(securityDrawerSaga),
  fork(securityFilterPanelSafa),
  fork(securityPageSaga),
  fork(userDataSaga),
  fork(bulkEditTableSaga),
  fork(distiBillingSaga)
];

export const getSaga = async () => {
  const [...submoduleEffects] = await Promise.all(R.values(SUBMODULES).map(loadSagaBundle));

  return function*() {
    yield all(R.flatten([effects, ...submoduleEffects].filter(isValidEffects)));
  };
};
