// @flow

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import FilterPopup from './Filter.component';
import { setDashboardRecommendationsFilter } from 'src/redux/modules/filters/filters.duck';

/*************
 *   REDUX   *
 *************/

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setDashboardRecommendationsFilter: setDashboardRecommendationsFilter
    },
    dispatch
  )
});

export default connect(null, mapDispatchToProps)(FilterPopup);
