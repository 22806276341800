import * as R from 'ramda';
import { createSelector } from 'reselect';

import * as partnerCenterSelectors from 'src/redux/modules/partnerCenter/partnerCenter.selector';
import { PARTNER_CENTER_AUTH_STATES } from 'src/redux/modules/partnerCenter/partnerCenter.constants';
import { getModuleSelector } from 'src/redux/utils';

const moduleSelector = getModuleSelector(['pages', 'secureApp']);

export const isConnectingSelector = createSelector(moduleSelector, R.prop('isConnecting'));

export const isSavingSelector = createSelector(moduleSelector, R.prop('isSaving'));

const stepSelector = createSelector(moduleSelector, R.prop('step'));

export const currentStepSelector = createSelector(
  stepSelector,
  partnerCenterSelectors.dataSelector,

  (step, { partnerAppId, partnerAppAuthState }) => {
    if (!partnerAppId || step === 1) return 1;

    if (partnerAppAuthState === PARTNER_CENTER_AUTH_STATES.NEW) return 2;

    return 3;
  }
);
