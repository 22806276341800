// @flow
import { message } from 'antd';
import * as R from 'ramda';

import { usStates, caStates } from '../statePicker';
import { lang } from './addressForm.lang';

/*************
 *   TYPES   *
 *************/

type geoInfo = {
  line1: string,
  line2: string,
  city: string,
  region: string,
  country: string,
  postalCode: string
};

/*************
 *  HELPERS  *
 *************/

const isCountryUs = R.pipe(
  R.toLower,
  R.equals('us')
);
const isCountryCa = R.pipe(
  R.toLower,
  R.equals('ca')
);

const isCountryUsOrCa = R.either(isCountryUs, isCountryCa);

const isNoRegionSelectedForUsAndCa = (country: string = '', region: string = '') =>
  isCountryUsOrCa(country) && region === '';

const isWrongRegionSelectedForUsAndCa = (country: string = '', region: string = '') => {
  if (isNoRegionSelectedForUsAndCa(country, region)) {
    return true;
  }

  if (isCountryUs(country)) {
    return !R.hasIn(region, usStates._map);
  }

  if (isCountryCa(country)) {
    return !R.hasIn(region, caStates._map);
  }

  return false;
};

const removeRegionIfNotUsOrCa = (props: geoInfo): geoInfo =>
  isCountryUsOrCa(props.country) ? props : R.dissoc('region', props);

const removeLine2IfNotUsed = props => {
  const ifLine2IsNullOrEmpty = R.hasIn('line2', props) && !props.line2;

  return ifLine2IsNullOrEmpty ? R.dissoc('line2', props) : props;
};

export const createOnSubmit = (onSubmit: Function, translate: Function) => (props: geoInfo) => {
  const { country, region } = props;

  if (isNoRegionSelectedForUsAndCa(country, region)) {
    message.error(translate(lang.emptyStateErr), 3);
    return;
  }

  if (isWrongRegionSelectedForUsAndCa(country, region)) {
    message.error(translate(lang.wrongStateErr), 3);
    return;
  }

  const result = R.pipe(
    removeLine2IfNotUsed,
    removeRegionIfNotUsOrCa
  )(props);

  onSubmit(result);
};

export const getRelatedError = (errors: Object = {}, name: string = ''): string => R.pathOr('', [name], errors);
