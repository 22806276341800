// @flow

import { core } from '@accordo-feed/micro-frontends-utils';

import { API } from 'src/utils/api';
import { resolveApiEndpoint, requestHandler } from 'src/utils';

/*************
 *   TYPES   *
 *************/

type SetProps = {
  orgId: string,
  data: Object
};

/*****************
 *   API URLS   *
 *****************/

const stateApi = resolveApiEndpoint(API.PARTNER_CENTER_STATE);
const loginApi = resolveApiEndpoint(API.PARTNER_CENTER_LOGIN);
const appSettingsApi = resolveApiEndpoint(API.PARTNER_CENTER_APP_SETTINGS);

/*****************
 *   API CALLS   *
 *****************/

export const getConnectionState = (orgId: string): Promise<Object> =>
  requestHandler({
    url: core.replaceAll(stateApi, { orgId })
  });

export const getConnectionLink = (orgId: string): Promise<Object> =>
  requestHandler({
    url: core.replaceAll(loginApi, { orgId })
  });

export const setSecureApp = ({ orgId, data }: SetProps): Promise<Object> =>
  new Promise((resolve, reject) => {
    requestHandler({
      url: core.replaceAll(appSettingsApi, { orgId }),
      method: 'PUT',
      data,

      handleError: (err, defaultErrorHandler) => {
        const { data, status } = err.response;

        if (status === 400) {
          reject(data);
        } else {
          defaultErrorHandler(err);
        }
      }
    }).then(resolve);
  });
