import * as R from 'ramda';
import { createSelector } from 'reselect';

import { PARTNER_CENTER_AUTH_STATES, PARTNER_CENTER_CONNECT_TYPE } from './partnerCenter.constants';
import { getModuleSelector } from 'src/redux/utils';

const moduleSelector = getModuleSelector('partnerCenter');

export const dataSelector = createSelector(moduleSelector, R.prop('data'));

export const isLikingSelector = createSelector(moduleSelector, R.prop('isLinking'));

export const isLoadingSelector = createSelector(moduleSelector, R.prop('isLoading'));

export const isLoadedSelector = createSelector(moduleSelector, R.prop('isLoaded'));

export const authStateSelector = createSelector(
  dataSelector,
  data => data[data.connectionType === PARTNER_CENTER_CONNECT_TYPE.APP_CONNECT ? 'partnerAppAuthState' : 'authState']
);

export const isConnectedSelector = createSelector(
  authStateSelector,
  R.equals(PARTNER_CENTER_AUTH_STATES.ACCESS_GRANTED)
);

export const hasTriedConnectedSelector = createSelector(
  authStateSelector,
  R.pipe(R.equals(PARTNER_CENTER_AUTH_STATES.NEW), R.not)
);

const connectionTypeSelector = createSelector(dataSelector, R.prop('connectionType'));

export const isSecureAppConnectionTypeSelector = createSelector(
  connectionTypeSelector,
  R.equals(PARTNER_CENTER_CONNECT_TYPE.APP_CONNECT)
);

export const lastTriggeredSelector = createSelector(dataSelector, R.prop('lastTriggered'));

export const totalPartnerCenterCustomersSelector = createSelector(dataSelector, R.prop('totalPartnerCenterCustomers'));

const isConnectionFailed = createSelector(moduleSelector, R.prop('isConnectionFailed'));

export const isFailedConnectedSelector = createSelector(
  isConnectionFailed,
  authStateSelector,
  (isConnectionFailed, authState) =>
    isConnectionFailed ||
    ![PARTNER_CENTER_AUTH_STATES.NEW, PARTNER_CENTER_AUTH_STATES.ACCESS_GRANTED].includes(authState)
);
