import React, { useState } from 'react';
import { ChromePicker } from 'react-color';
import Tippy from '@tippyjs/react';
import rgbHex from 'rgb-hex';

import * as Styled from './colorPicker.styled';

const ColorPicker = ({ initialColor, onChange }) => {
  const [selectedColor, setSelectedColor] = useState(initialColor ?? '#0d0b0b');

  const handleChangeColor = color => {
    const hexColor = color.rgb.a !== 1 ? `#${rgbHex(color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a)}` : color.hex;
    setSelectedColor(hexColor);
    onChange(hexColor);
  };

  return (
    <Tippy
      interactive={true}
      placement={'bottom-start'}
      trigger={'click'}
      content={<ChromePicker color={selectedColor} onChangeComplete={handleChangeColor} />}
    >
      <Styled.CircleWrap>
        <Styled.Circle style={{ backgroundColor: selectedColor }}></Styled.Circle>
        <Styled.ColorHex>{selectedColor}</Styled.ColorHex>
      </Styled.CircleWrap>
    </Tippy>
  );
};

export default ColorPicker;
