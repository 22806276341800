import styled, { css, createGlobalStyle } from 'styled-components';
import { Menu, Avatar } from 'antd';
import { ExpandMore, Person } from '@material-ui/icons';
import { ellipsis } from 'polished';
import { Icon, mixins, constants } from '@accordo-feed/aco-styled-components';

import {
  appTextColor,
  popOverBackground,
  widgetBorderColor,
  tableEvenRowBackground,
  widgetHeaderTextColor,
  tableRowBorderColor,
  scrollbarBackground,
  scrollbarHandlerColor
} from 'src/App.styled';

import AzureSvg from 'src/images/azure.svg';
import O365Svg from 'src/images/office365.svg';
import DashboardSvg from 'src/images/dashboard.svg';
import LogoSvg from 'src/images/logo.svg';
import FullLogoSvg from 'src/images/full-logo.svg';
import LogoutSvg from 'src/images/logout-icon.svg';
import AddConnectionSvg from 'src/images/add-connection-icon.svg';
import AddProductCatalogSvg from 'src/images/product-catalog.svg';
import CompaniesSvg from 'src/images/companies-menu-icon.svg';

const { Item: MenuItem, ItemGroup } = Menu;
const { colors, fontSize } = constants;

export const iconMixins = css`
  fill: ${colors.lightGrey2};
  font-size: ${fontSize.normal};
  margin-right: 8px;
`;

const genMaterialIcon = element => styled(Icon).attrs({
  element,
  className: 'anticon'
})`
  && {
    ${iconMixins};
  }
`;

export const PersonIcon = genMaterialIcon(Person);

export const O365Icon = styled(O365Svg).attrs({
  className: 'anticon'
})`
  ${iconMixins};
  ${mixins.size('16px')};
  fill: ${colors.red};
`;

export const DashboardIcon = styled(DashboardSvg).attrs({
  className: 'anticon'
})`
  ${iconMixins};
  ${mixins.size('16px')};
  fill: none;
`;

export const AddConnectionIcon = styled(AddConnectionSvg).attrs({
  className: 'anticon'
})`
  ${iconMixins};
  ${mixins.size('18px')};
  fill: none;
`;

export const AddProductCatalogIcon = styled(AddProductCatalogSvg).attrs({
  className: 'anticon'
})`
  ${iconMixins};
  ${mixins.size('18px')};
  fill: none;
`;

export const AzureIcon = styled(AzureSvg).attrs({
  className: 'anticon'
})`
  ${iconMixins};
  ${mixins.size('20px')};
  fill: none;
`;

export const LogoIcon = styled(LogoSvg).attrs({
  className: 'anticon'
})`
  ${iconMixins};
  fill: none;
  stroke: none !important;
`;

export const FullLogoIcon = styled(FullLogoSvg).attrs({
  className: 'anticon'
})`
  ${iconMixins};
  fill: none;
`;

export const CompaniesIcon = styled(CompaniesSvg).attrs({
  className: 'anticon'
})`
  ${iconMixins};
  fill: none;
`;

export const LogoutIcon = styled(LogoutSvg).attrs({
  className: 'anticon'
})`
  ${iconMixins};
  fill: none;
  margin-bottom: -10px;
  transform: rotate(180deg);
`;

export const menuBorderWidth = '5px';

export const SearchButton = styled.button`
  position: absolute;
  right: 36px;
  display: ${props => (props.collapsed ? 'none' : 'flex')};
  margin-left: 14px;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;

  svg {
    fill: ${colors.lightGrey2};
  }
`;

export const ExpandMoreIcon = styled(ExpandMore)`
  display: ${props => (props.collapsed ? 'none' : 'initial')} !important;
  height: 16px !important;
  position: absolute;
  right: 8px;
  fill: ${colors.lightGrey2} !important;
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
`;

const border = `1px solid ${colors.lightTintGrey}`;

export const CustomerNameItem = styled(MenuItem)`
  && {
    border-top: ${border};
    border-bottom: ${border};
    cursor: default;

    &:hover {
      background: transparent !important;
    }

    > span {
      ${ellipsis()};
      display: inline;
    }
  }
`;

export const WithoutToggleLogo = styled.div`
  ${mixins.flexCenter()};
  padding: 0 !important;
  cursor: pointer;
  .anticon {
    width: auto;
  }

  ${FullLogoIcon} {
    > path:first-child {
      fill: ${widgetHeaderTextColor};
      stroke: ${widgetHeaderTextColor};
    }
  }
`;

export const menuItemMixin = css`
  ${mixins.flexVerticalCenter()};
  color: ${colors.lightShadeIndigo};
  margin: 0;
  height: 40px;

  svg {
    stroke: ${widgetHeaderTextColor};
  }

  &-selected,
  &:hover {
    background-color: ${tableEvenRowBackground};
    color: ${colors.cyan};

    &.ant-menu-submenu-title {
      & > span {
        color: ${colors.cyan};
      }

      svg {
        stroke: ${colors.cyan};
      }

      .anticon {
        color: ${colors.cyan};
      }

      & > .ant-menu-submenu-arrow::before,
      & > .ant-menu-submenu-arrow::after {
        background-color: ${colors.cyan};
      }
    }
  }

  &-disabled {
    color: ${colors.blackishBlue} !important;
  }
`;

/* To hide submenu popup in sider collapsed state */
export const GlobalStyle = createGlobalStyle`
  .ant-menu-submenu-popup {
    > .ant-menu-vertical {
      display: none;
    }
  }

  .popupThemeColor{
    .ant-popover-inner{
      background-color: ${popOverBackground};
    }

    .ant-popover-arrow{
      border-bottom-color: ${widgetBorderColor} !important;
      border-left-color: ${widgetBorderColor} !important;
    }

    .ant-popover-title {
      border-bottom: 1px solid ${tableRowBorderColor};
    }
  }
  
  .tooltipMenuToggleCollapsed{
    left: 72px !important;
  }

  .tooltipMenuToggleNonCollapsed{
    display: none !important;
  }
`;

export const SidebarWrapper = styled.div`
  height: 80%;
`;

export const SidebarMenu = styled(Menu).attrs({
  mode: 'inline'
})`
  && {
    width: 100%;
    border-right: 0;
    background: transparent;
    height: 90%;
    position: relative;

    .ant-menu-item,
    .ant-menu-submenu-title {
      ${menuItemMixin};
      position: relative;
    }

    .ant-menu-submenu {
      &-active {
        .ant-menu-submenu-title > span {
          color: ${colors.blue};
        }
      }

      &.ant-menu-submenu-open {
        .ant-menu-submenu-title {
          ${ExpandMoreIcon} {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
`;

export const StaticMenuGroup = styled(ItemGroup)`
  @media (max-height: 720px) {
    max-height: 240px;
    overflow-y: auto;
  }

  @media (max-height: 640px) {
    max-height: 180px;
    overflow-y: auto;
  }

  &&&& {
    .ant-menu-item-group-list {
      .at_acoSubMenu {
        > .ant-menu-submenu-title {
          color: ${appTextColor};
          padding: 0 24px !important;

          svg {
            stroke: ${appTextColor};
          }
        }
        > ul.ant-menu-sub {
          background-color: transparent;
          max-height: 165px;
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
            background-color: ${scrollbarBackground};
          }

          &::-webkit-scrollbar-track {
            background-color: ${scrollbarBackground};
          }

          &::-webkit-scrollbar-thumb {
            background-color: ${scrollbarHandlerColor};
          }

          &::-webkit-scrollbar-corner {
            background: ${scrollbarHandlerColor};
          }
        }
      }
      .ant-menu-item {
        &.at_acoMenuItem {
          color: ${appTextColor};
          padding: 0 24px !important;
          > svg {
            width: 20px;
            height: 16px;
            stroke: ${appTextColor};
          }
        }
      }
    }
  }
`;

export const UserAvatar = styled(Avatar)`
  && {
    color: ${colors.darkCyan};
    font-size: ${fontSize.normal} !important;
    line-height: 150%;
    border: 1px solid ${colors.aquaBlue3};
    filter: drop-shadow(0 0 7px ${colors.aquaBlue1});
    background-color: transparent;
    cursor: pointer;
  }
`;

export const ComingSoon = styled.span`
  color: ${colors.shadeGreen};
  font-size: ${fontSize.xSmall};
`;

export const GoBackMenuItem = styled(MenuItem)`
  && {
    position: relative;
    top: -10px;
  }
`;

export const LogoutButton = styled.button`
  color: ${appTextColor};
  display: block;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
`;

export const UserInfoContainer = styled.div`
  padding: 0 4px;

  p {
    margin: 0;
    font-size: 12px;
    color: ${appTextColor};
    &:first-child {
      font-size: 14px;
    }
  }
`;

export const WithoutToolTipPopOverContainer = styled.div.attrs({
  className: 'anticon'
})`
  padding: 0 4px;
  height: 120px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
