import styled from 'styled-components';
import { Link as RRLink } from 'react-router';
import { ChevronRight } from '@material-ui/icons';
import { constants } from '@accordo-feed/aco-styled-components';
import { appTextColor } from 'src/App.styled';

const { colors, fontSize } = constants;

export const HeaderContainer = styled.div`
  padding: 4px 24px;
  height: 43px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BreadcrumbsContainer = styled.div`
  display: flex;
  float: left;
`;

export const BreadcrumbsRigthIconContainer = styled.span`
  float: right;
  margin-top: 10px;
`;

export const Link = styled(RRLink)`
  color: ${colors.skyBlue};
  font-size: ${fontSize.medium};
  line-height: 24px;
`;

export const PathConnector = styled(ChevronRight)`
  && {
    fill: ${appTextColor};
    stroke: ${appTextColor};
  }
`;

export const Breadcrumbs = styled(BreadcrumbsContainer)`
  &:last-child {
    ${Link} {
      color: ${appTextColor};
    }
    ${PathConnector} {
      display: none;
    }
  }
`;
