import React from 'react';
import { langHelper } from '@accordo-feed/micro-frontends-utils';
import { LanguageEntry } from '@accordo-feed/language.entry';

const genProfileId = langHelper.genLangId('shell.aco.profile.subscriptionUsage');
const genProfileLang = langHelper.genLangOptions(genProfileId);

export default {
  heading: genProfileLang('heading', 'Subscription usage'),
  activeUsers: genProfileLang('activeUsers', 'Monthly Active Users'),
  freeTrial: genProfileLang('freeTrial', 'free trial'),
  tooltip: genProfileLang('tooltip', 'Maximum number of active users per calendar month.'),
  description: (
    <LanguageEntry
      {...genProfileLang(
        'description',
        'Billing will be based on the highest number of active users added to your account within a calendar month. <br/> Active users during the trial period will be excluded from the calculation. For more information on billing please contact your Distributor.'
      )}
    />
  )
};
