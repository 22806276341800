import styled, { css } from 'styled-components';
import { Button, Col, Input as AntInput } from 'antd';
import { Form } from 'formik';
import { mixins, constants } from '@accordo-feed/aco-styled-components';

import ErrorMessage from 'src/components/errorMessage';
import LangWrapper from 'src/components/langWrapper';

const { colors, fontSize } = constants;

const verticalCenter = () => css`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const Container = styled(Col).attrs({
  span: 12
})`
  ${verticalCenter()};
  padding: 40px 0;
  height: 100vh;
`;

export const Body = styled(Form)`
  ${mixins.centerBlock('420px')};
  ${verticalCenter()};
  flex: 1;
`;

export const Title = styled.h1`
  font-size: ${fontSize.xLarge};
  margin-bottom: 10px;
`;

export const Desc = styled(LangWrapper)`
  margin-bottom: 30px;
`;

export const Footer = styled(LangWrapper)`
  text-align: center;
  color: ${colors.darkShadeGrey};
  font-size: ${fontSize.xSmall};
`;

export const Label = styled.label`
  display: block;
  color: ${colors.darkShadeGrey};
  margin: 10px 0 5px;
`;

export const Input = styled(AntInput).attrs({
  size: 'large'
})``;

export const SubmitWrapper = styled.div`
  text-align: right;
`;

export const Submit = styled(Button).attrs({
  type: 'primary',
  size: 'large'
})`
  margin: 30px 0 40px;

  i {
    margin-left: 10px;
  }
`;

export const ErrMsg = styled(ErrorMessage)`
  margin-top: 3px;
`;
