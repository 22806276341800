import * as R from 'ramda';

import { generateXAxisData } from 'src/pages/dashboard/widgets/securityScores/securityScores.utils';

const customerData = [
  { clientName: 'ABC Clinic', currentScore: '1.0', maxScore: '890.0' },
  { clientName: 'Acme Corporation', currentScore: '5', maxScore: '700.0' },
  { clientName: 'Niels Gmbh', currentScore: '6', maxScore: '650.0' },
  { clientName: 'Guardian Assets', currentScore: '8', maxScore: '750.0' }
];
const demoRangeValues = [3, 10, 12, 7, 15, 13, 12, 10, 2, 1];

const generateCustomerData = () =>
  R.reduce((acc, value) => R.concat(acc, [{ value, customerData }]), [], demoRangeValues);

export const demoData = {
  average: '0%',
  customersShowing: 0,
  lowScore: 0,
  xAxis: {
    values: generateXAxisData()
  },
  yAxis: {
    values: generateCustomerData()
  }
};
