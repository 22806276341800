import * as R from 'ramda';

import { LOADING_STATUS } from 'src/constants';

export const isStatus = {
  initial: R.equals(LOADING_STATUS.INITIAL),
  loading: R.equals(LOADING_STATUS.LOADING),
  loaded: R.equals(LOADING_STATUS.LOADED),
  failed: R.equals(LOADING_STATUS.FAILED)
};
