import * as R from 'ramda';

export const SORTING = {
  ASC: 'asc',
  DESC: 'desc'
};

export const sortByProp = opts => obj => {
  const withDirection = opts.direction && opts.direction === SORTING.DESC ? R.descend : R.ascend;
  return R.sortWith([withDirection(R.prop(opts.prop))])(obj);
};

export const sortByAlph = (...path) => (a, b) => {
  const getValueToSort = R.pipe(R.pathOr('', [...path]), R.toLower);
  // prettier-disable
  return R.cond([
    [R.lt, R.always(1)],
    [R.equals, R.always(0)],
    [R.T, R.always(-1)]
  ])(getValueToSort(a), getValueToSort(b));
  // prettier-enable
};

export const sortByNumber = (...path) => (a, b) => (R.path([...path], a) || 0) - (R.path([...path], b) || 0);

export const sortByDate = (...path) => (a, b) =>
  new Date(R.path([...path], a) || '1900-01-01T12:00:00.000Z') -
  new Date(R.path([...path], b) || '1900-01-01T12:00:00.000Z');

export const sortByPriorityMap = priorityMap => (...path) => (a, b) => {
  if (!priorityMap || typeof priorityMap !== 'object') return 0;

  const getValueToSort = R.pipe(R.pathOr('', [...path]), R.toLower);
  const priority1 = getValueToSort(a);
  const priority2 = getValueToSort(b);
  const val1 = priorityMap[priority1];
  const val2 = priorityMap[priority2];

  if (!val1) return -1;
  if (!val2) return 1;
  return val1 - val2;
};
