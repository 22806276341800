import styled from 'styled-components';
import { widgetHeaderTextColor } from 'src/App.styled';

export const DashboardWrapper = styled.div`
  position: relative;
  .test {
    height: 100%;
  }
`;

export const WidgetWrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  color: ${widgetHeaderTextColor};
  overflow: hidden;
`;
