// @flow

import * as R from 'ramda';
import { createAction } from 'redux-actions';
import { redux } from '@accordo-feed/micro-frontends';

import type { Action } from 'src/redux/types';
import { wrapWithModule } from 'src/redux/utils';

const { setState } = redux;

/*************
 *   TYPES   *
 *************/

export type StateType = {
  data: Object,
  isLoaded: boolean,
  isLoading: boolean,
  hasError: boolean
};

/*********************
 *   INITIAL STATE   *
 *********************/

const initialState: StateType = {
  data: {},
  isLoaded: false,
  isLoading: false,
  hasError: false
};

/***************
 *   ACTIONS   *
 ***************/

const wrapWithNamespace = wrapWithModule('modules/orgnization');
const GET_ORGANIZATION = wrapWithNamespace('GET_ORGANIZATION');
const GET_ORGANIZATION_SUCCESS = wrapWithNamespace('GET_ORGANIZATION_SUCCESS');
const GET_ORGANIZATION_FAILED = wrapWithNamespace('GET_ORGANIZATION_FAILED');
const SET_LOADING_STATE = wrapWithNamespace('SET_LOADING_STATE');
const SET_PSA_BILLING_ALERT_STATUS = wrapWithNamespace('SET_PSA_BILLING_ALERT_STATUS');
const ADD_PSA_BILLING_ALERT_EMAIL = wrapWithNamespace('ADD_PSA_BILLING_ALERT_EMAIL');
const REMOVE_PSA_BILLING_ALERT_EMAIL = wrapWithNamespace('REMOVE_PSA_BILLING_ALERT_EMAIL');

export const getOrganization = createAction(GET_ORGANIZATION);
export const getOrganizationSuccess = createAction(GET_ORGANIZATION_SUCCESS);
export const getOrganizationFailed = createAction(GET_ORGANIZATION_FAILED);
export const setLoadingState = createAction(SET_LOADING_STATE);
export const setPsaBillingAlertStatus = createAction(SET_PSA_BILLING_ALERT_STATUS);
export const addPsaBillingAlertEmail = createAction(ADD_PSA_BILLING_ALERT_EMAIL);
export const removePsaBillingAlertEmail = createAction(REMOVE_PSA_BILLING_ALERT_EMAIL);

/***************
 *   HELPERS   *
 ***************/

const getEmailList = R.pathOr([], ['data', 'psaBillingAlert', 'emailList']);

/****************
 *   REDUCERS   *
 ****************/

const updatePsaBillingAlertReducer = key => (state, payload) => {
  const { data } = state;

  return {
    ...state,
    data: {
      ...data,
      psaBillingAlert: {
        ...R.propOr(
          {
            isAlertOn: false,
            emailList: []
          },
          'psaBillingAlert'
        )(data),
        [key]: payload
      }
    }
  };
};

export default (state: StateType = initialState, action: Action) => {
  const { type, payload } = action;

  const reducer = {
    [GET_ORGANIZATION_SUCCESS]: redux.apiSuccessState(),

    [GET_ORGANIZATION_FAILED]: state => ({
      ...state,
      isLoading: false,
      hasError: true
    }),

    [SET_LOADING_STATE]: setState('isLoading'),

    [SET_PSA_BILLING_ALERT_STATUS]: updatePsaBillingAlertReducer('isAlertOn'),

    [ADD_PSA_BILLING_ALERT_EMAIL]: (state, payload) => {
      const emailList = R.uniq([...getEmailList(state), payload]);
      return updatePsaBillingAlertReducer('emailList')(state, emailList);
    },

    [REMOVE_PSA_BILLING_ALERT_EMAIL]: (state, payload) => {
      const emailList = R.difference(getEmailList(state), [payload]);
      return updatePsaBillingAlertReducer('emailList')(state, emailList);
    }
  }[type];

  return reducer ? reducer(state, payload) : state;
};
