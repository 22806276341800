import { langHelper } from '@accordo-feed/micro-frontends-utils';

const genId = langHelper.genLangId('shell.aco.securityScore.page');
const genOpts = langHelper.genLangOptions(genId);

export default {
  currentScore: genOpts('filter.title1', 'Fiter by: Microsoft Secure Score current'),
  scoreIndex: genOpts('filter.title2', 'Filter by: Secure Score Index'),
  scoreIndexTooltip: genOpts('filter.tooltip1', 'Filter by: Secure Score Index')
};
