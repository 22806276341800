// @flow

import * as R from 'ramda';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';

import { getDashboardData } from 'src/pages/dashboard/dashboard.duck';
import * as selectors from 'src/redux/selectors';

export default (options: ?Object) => {
  const { loadedPropName, enableCache } = R.mergeRight(
    {
      loadedPropName: 'isLoaded',
      enableCache: true
    },
    options
  );

  const mapStateToProps = state => ({
    [loadedPropName]: selectors.dashboardPageIsLoadedSelector(state)
  });

  const mapDispatchToProps = {
    getDashboardData
  };

  return compose(
    connect(
      mapStateToProps,
      mapDispatchToProps
    ),

    lifecycle({
      componentDidMount() {
        const { [loadedPropName]: isLoaded, getDashboardData } = this.props;

        if (!enableCache || !isLoaded) {
          getDashboardData();
        }
      }
    })
  );
};
