// @flow

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { useTranslate } from '@accordo-feed/language.entry';

import { formatCurrencyHOC } from 'src/hoc';
import * as selectors from 'src/redux/selectors';
import ViewMore from 'src/components/viewMore';
import Widget from 'src/components/widget';
import { PATHS } from 'src/constants';
import UnassignedLicences, { Elipse } from './UnassignedLicenses.component';
import lang from './unassignedLicenses.lang';

/*************
 *   TYPES   *
 *************/

type Props = {
  elipse: Elipse,
  formatCurrencyWithoutUnit: Function,
  unassignedLicenses: Array<{ name: string, total: number, value: number, percentage: number }>,
  viewMoreAction: boolean,
  theme?: 'light' | 'dark'
};

/*****************
 *   COMPONENT   *
 *****************/

const UnassignedLicenses = ({
  unassignedLicenses,
  elipse,
  formatCurrencyWithoutUnit,
  viewMoreAction = true,
  theme
}: Props) => {
  const translate = useTranslate();

  return (
    <Widget
      key="unassignedLicenses"
      title={translate(lang.widgetTitle)}
      subTitle={translate(lang.widgetSubTitle)}
      footerContent={viewMoreAction ? <ViewMore path={PATHS.DASHBOARD.UNASSIGNED_LICENSES} /> : null}
    >
      <UnassignedLicences
        unassignedLicenses={unassignedLicenses}
        elipse={elipse}
        formatCurrencyWithoutUnit={formatCurrencyWithoutUnit}
        theme={theme}
      />
    </Widget>
  );
};

const mapStateToProps = state => ({
  unassignedLicenses: selectors.dashboardUnassignedLicensesSelector(state),
  theme: selectors.appThemeSelector(state)
});

export default compose(connect(mapStateToProps), formatCurrencyHOC)(UnassignedLicenses);
