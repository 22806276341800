// @flow

import React from 'react';
import { connect } from 'react-redux';

import { securityPageQuerySelector } from 'src/pages/dashboard/securityScore/securityScorePage.selector';
import * as selectors from 'src/redux/selectors';
import OpportunitiesHighlightsComponent from './OpportunitiesHighlights.component';
import * as Styled from './opportunitiesHighlights.styled';

/*****************
 *   COMPONENT   *
 *****************/

const OpportunitiesHighlights = props => (
  <Styled.Container>
    <OpportunitiesHighlightsComponent {...props} />
  </Styled.Container>
);

/*************
 *   REDUX   *
 *************/

const mapStateToProps = state => ({
  query: securityPageQuerySelector(state),
  recommendations: selectors.dashboardRecommendationsSelector(state),
  overview: selectors.dashboardOverviewSelector(state),
  theme: selectors.appThemeSelector(state)
});

export default connect(mapStateToProps)(OpportunitiesHighlights);
