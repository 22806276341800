import * as R from 'ramda';
import { connect } from 'react-redux';
import { translateHOC } from '@accordo-feed/language.entry';
import { compose, branch, renderComponent, renderNothing, mapProps } from 'recompose';

import * as selectors from 'src/redux/selectors';
import { API_ERROR_ACTION_TYPES } from 'src/constants';
import auth from 'src/utils/auth';
import ErrorModal from './errorModal.component';
import ErrorToaster from './errorToaster.component';
import msgLang, { getLangIdByErrorCode } from './errorMsg.lang';
import { lang } from './errorModal.lang';

/*************
 *   TYPES   *
 *************/

declare var location: Object;

/***************
 *   HELPERS   *
 ***************/

const hasNoError = R.propEq('show', false);
const isToasterError = R.propEq('type', API_ERROR_ACTION_TYPES.SHOW_TOASTER);
const isLogoutError = R.propEq('type', API_ERROR_ACTION_TYPES.LOG_OUT);

const handleErrorProps = props => {
  const { translate, errorCode } = props;

  const langId = getLangIdByErrorCode(errorCode);
  const isLogoutErr = isLogoutError(props);
  const typeProps = isLogoutErr
    ? {
        para1: translate(lang.logout.para1),
        button: translate(lang.logout.button),
        onClick: () => auth.logout()
      }
    : {
        para1: translate(lang.default.para1),
        para2: translate(lang.default.para2),
        button: translate(lang.default.button),
        onClick: () => location.reload()
      };

  // Should translate if any language entry id provided
  const doTranslate = R.when((value = '') => R.has('id', value), translate);
  const title = doTranslate(props.title);
  const message = doTranslate(props.message);

  return {
    ...props,
    ...typeProps,
    title,
    header: translate(lang.header),
    message: msgLang[langId] || translate({ id: langId, defaultMessage: message }),
    showMore: translate(lang.showMore),
    showLess: translate(lang.showLess)
  };
};

/*************
 *   REDUX   *
 *************/

const mapStateToProps = state => ({
  ...selectors.errorModalSelector(state)
});

export default compose(
  connect(mapStateToProps),
  // Skip render
  branch(hasNoError, renderNothing),
  // Setup error message props
  translateHOC,
  mapProps(handleErrorProps),
  // based on type to decide error handler
  branch(isToasterError, renderComponent(ErrorToaster))
)(ErrorModal);
