import { currentSubscription as lang } from './subscriptions.lang';

/***************
 *   HELPERS   *
 ***************/

export const getLastUsedTierInfo = tiers => {
  // case 1 & 2: user hit the maxim tier
  const lastItem = tiers[tiers.length - 1];
  if (lastItem.usage !== 0) {
    return {
      index: tiers.length - 1,
      info: lastItem
    };
  }

  // case 3: user could be somewhere in the tiers
  // We find the first occuring usage === 0
  // substract 1 from its index will be the highest tier that user has used
  let index = 0;

  tiers.find((tier, i) => {
    const isNoUsage = tier.usage === 0;
    const is1stItem = i === 0;

    // handle case 4
    index = is1stItem ? i : i - 1;

    return isNoUsage;
  });

  return {
    index,
    info: tiers[index]
  };
};

export const getDataSource = (subscriptions, translate, formatCurrency) =>
  Object.entries(subscriptions).map(([planName, { tiers, amountDue, isInTrialPeriod }]) => {
    const { index } = getLastUsedTierInfo(tiers);
    const price = isInTrialPeriod
      ? translate(lang.table.freeTrialPrice, { price: formatCurrency(amountDue) })
      : formatCurrency(amountDue);

    return {
      key: index + 1,
      planName,
      amountDue: price
    };
  });

export const getColumns = translate => [
  {
    title: translate(lang.table.productHeading),
    dataIndex: 'planName',
    key: 'planName'
  },
  {
    title: translate(lang.table.priceHeading),
    dataIndex: 'amountDue',
    key: 'amountDue'
  }
];
