import styled from 'styled-components';
import { Spin } from 'antd';
import { mixins, constants } from '@accordo-feed/aco-styled-components';
import ErrorPageSVG from 'src/images/errorPageIcon.svg';
import WarningSvg from 'src/images/warning.svg';

const { colors } = constants;

export const Wrapper = styled.div`
  ${mixins.flexCenter()}
  width: 100%;
  color: ${colors.lightRed};
  span {
    margin-left: 7px;
    font-size: 12px;
    line-height: 18px;
    text-decoration-line: underline;
  }
`;

export const ErrorWrapper = styled(Wrapper)`
  ${mixins.flexCenter()}
  flex: 1;

  .ant-empty {
    margin: 32px 0;
    color: ${colors.lightShadeGrey};

    .ant-empty-image {
      height: 70px;
    }
  }
`;

export const ErrorIcon = styled(ErrorPageSVG)`
  height: 100%;
`;

export const WarningIcon = styled(WarningSvg)``;

export const SpinContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.skyBlue};

  .ant-spin-dot,
  .ant-spin-text {
    position: static !important;
  }

  .ant-spin-text {
    text-shadow: none !important;
  }
`;

export const SpinIcon = styled(Spin)`
  .ant-spin-dot {
    font-size: 20px;

    .ant-spin-dot-item {
      background-color: ${colors.skyBlue};
    }
  }
`;
