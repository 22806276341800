import { langHelper } from '@accordo-feed/micro-frontends-utils';

const genId = langHelper.genLangId('shell.aco.masterList.allocation');
const genOpts = langHelper.genLangOptions(genId);

export default {
  widgetTitle: genOpts('title', 'Utilization | Adoption'),
  widgetSubTitle: genOpts('subTitle', 'M365 Usage and Activity'),
  table: {
    main: {
      company: genOpts('th.company', 'Company'),
      m365: genOpts('th.m365', 'M365'),
      teams: genOpts('th.teams', 'Teams'),
      sharepoint: genOpts('th.sharepoint', 'Sharepoint'),
      exchange: genOpts('th.exchange', 'Exchange')
    },
    base: {
      th1: genOpts('base.th1', 'Inactive'),
      th2: genOpts('base.th2', 'Less Active')
    }
  }
};
