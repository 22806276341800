import styled from 'styled-components';
import { Modal as AntModal } from 'antd';
import { mixins, constants } from '@accordo-feed/aco-styled-components';

import ErrorPageSVG from 'src/images/errorPageIcon.svg';
import { pageHeaderTextColor, modalBackgroundColor, appTextColor } from 'src/App.styled';

const { fontSize } = constants;

export const Modal = styled(AntModal).attrs({
  closable: false,
  centered: true,
  footer: null,
  zIndex: 10000
})`
  .ant-modal-content {
    ${mixins.flexCenter()}
    background-color: ${modalBackgroundColor};
    min-width: 400px;
    min-height: 400px;
  }
`;

export const Wrapper = styled.div`
  ${mixins.flexCenter()}
  flex-direction: column;
  padding: 20px;
`;

export const Header = styled.h1`
  color: ${pageHeaderTextColor};
  width: 100%;
  font-size: ${fontSize.large};
  margin: 0;
  text-align: center;
`;

export const Paragraph = styled.div`
  margin: 10px;
  text-align: center;
  color: ${appTextColor};
`;

export const ErrorPageIcon = styled(ErrorPageSVG)`
  ${mixins.size('170px')};
  margin: 20px;
`;
