// @flow

import React from 'react';
import { Row, Col } from 'antd';
import * as Styled from './growthTrend.styled';

/*************
 *   TYPES   *
 *************/

type Props = {
  legends: Array<{ clientId: string, clientName: string, color: string, growthPercentage: number }>
};

/*****************
 *   COMPONENT   *
 *****************/

export default ({ legends }: Props) => (
  <Styled.LegendsWrapper>
    <Row gutter={[10, 10]} type="flex">
      {legends.map(legend => (
        <Col key={legend.clientId} xs={{ span: 12 }}>
          <Styled.LegendLine title={legend.clientName} color={legend.color} isPositive={legend.growthPercentage > 0}>
            <div>{legend.clientName}</div>
            <span>{legend.growthPercentage}%</span>
          </Styled.LegendLine>
        </Col>
      ))}
    </Row>
  </Styled.LegendsWrapper>
);
