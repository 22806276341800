// @flow

import { takeLatest, put, all, fork, select } from 'redux-saga/effects';

import * as actions from './distiBilling.duck';
import * as apiCalls from './distiBilling.api';
import * as selectors from 'src/redux/selectors';

function* getDistiBillingDataSaga(): any {
  const orgId = yield select(selectors.orgIdSelector);

  try {
    const data = yield apiCalls.getDistiBillingData(orgId);
    yield put(actions.getDistiBillingDataSuccess(data));
  } catch (err) {}
}

export default function*(): any {
  yield all([fork(takeLatest, actions.getDistiBillingData, getDistiBillingDataSaga)]);
}
