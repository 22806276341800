// @flow

import React from 'react';
import { Row } from 'antd';
import { useTranslate } from '@accordo-feed/language.entry';
import { ButtonElement } from '@accordo-feed/aco-styled-components';

import CustomModal from 'src/components/modal';
import lang from './profileMfaModal.lang';

const { enableMfaMsg, disableMfaMsg, mfaModelButtons } = lang;

/*************
 *   TYPES   *
 *************/

export type Actions = {
  updateMfa: Function
};

type Props = {
  actions: Actions,
  isMfaEnabled: boolean,
  isMfaUpdating: boolean,
  isModalOpen: boolean,
  closeModal: Function
};

/*****************
 *   COMPONENT   *
 *****************/

export default ({ actions, isMfaEnabled, isMfaUpdating, isModalOpen, closeModal }: Props) => {
  const mfaMsg = isMfaEnabled ? disableMfaMsg : enableMfaMsg;
  const translate = useTranslate();

  return (
    <CustomModal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      title={translate(mfaMsg.title)}
      description={translate(mfaMsg.content)}
      closable={!isMfaUpdating}
      customButtons={true}
    >
      <Row type="flex" justify="end" align="middle">
        <ButtonElement variant="outline" onClick={closeModal} disabled={isMfaUpdating} margin="32px 0 0 0">
          {translate(mfaModelButtons.cancel)}
        </ButtonElement>
        <ButtonElement
          htmlType="submit"
          variant="primary"
          margin="32px 0 0 15px"
          onClick={() => actions.updateMfa({ mfa: !isMfaEnabled })}
          loading={isMfaUpdating}
        >
          {translate(mfaModelButtons.confirm)}
        </ButtonElement>
      </Row>
    </CustomModal>
  );
};
