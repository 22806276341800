import styled from 'styled-components';
import { Col, Button, Collapse } from 'antd';
import { mixins, constants } from '@accordo-feed/aco-styled-components';

import { EntoriLogo } from 'src/components/logo';
import { COLLAPSE_PANEL_STAY_ACTIVE_KEY } from './leftSide.constants';

const { colors } = constants;
const { Panel } = Collapse;

export const Container = styled(Col).attrs({
  span: 12
})`
  position: relative;
  background: linear-gradient(170.11deg, ${colors.blackishBlue4} 2.48%, ${colors.deepDarkBlue2} 92.57%);
`;

export const Header = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 15px 0 0 30px;
`;

export const Logo = styled(EntoriLogo).attrs({
  size: 'large'
})`
  display: block;
  margin-bottom: 15px;
`;

export const BackLink = styled(Button).attrs({
  type: 'link'
})`
  height: 40px;
  padding: 0;
  margin-right: 5px;
`;

export const CollapsePanel = styled(Panel)`
  .ant-collapse-content {
    &-box {
      > div {
        color: ${colors.lightGrey3};
        em {
          color: ${colors.alertBlue};
        }
      }
    }
  }
`;

export const StepCollapse = styled(Collapse).attrs({
  expandIconPosition: 'right',
  bordered: false,
  accordion: true,
  defaultActiveKey: COLLAPSE_PANEL_STAY_ACTIVE_KEY
})`
  background: transparent;

  && {
    .ant-collapse {
      &-header {
        color: ${colors.lightGrey3};
        em {
          color: ${colors.alertBlue};
        }
      }
      &-item {
        border-bottom: 1px solid ${colors.darkGrey1};

        &-active {
          .ant-collapse-header {
            .ant-collapse-arrow {
              transform: rotate(180deg);
            }
          }
        }
      }
      &-no-arrow {
        .ant-collapse-header {
          display: none;
        }
        .ant-collapse-content-box {
          padding: 10px 0;
        }
      }
    }
  }
`;

export const Body = styled.div`
  ${mixins.flexVerticalCenter()};
  height: 100%;
  padding: 0 10px 0 80px;

  &&& {
    .ant-steps {
      padding: 20px 10px 10px;
      overflow: auto;
      max-height: ${({ height }) => height - 210}px;

      &-item {
        margin-right: 70px;

        &-title {
          color: ${colors.white};
        }

        &-description {
          color: ${colors.lightGrey2};
        }
      }

      &-item-finish,
      &-item-wait {
        .ant-collapse {
          display: none;
        }
      }
    }
  }

  img {
    margin: 20px 0;
    max-width: 100%;
  }
`;

export const PanelImage = styled.img.attrs({
  alt: 'panelImage'
})``;
