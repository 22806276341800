// @flow

import { takeLatest, put, all, fork, select } from 'redux-saga/effects';

import { orgIdSelector } from 'src/redux/selectors';
import * as apiCalls from 'src/pages/dashboard/dashboard.api';
import * as actions from './filterPanel.duck';
import * as utils from './filterPanel.utils';

/*************
 *   SAGAS   *
 *************/

function* getFilterDataSaga(): any {
  const partnerId = yield select(orgIdSelector);
  const query = utils.genFilterQuery({ partnerId });
  yield put(actions.setLoadingState(true));

  try {
    const data = yield apiCalls.getSecurityData(query);

    yield put(actions.getFilterDataSuccess(utils.transformFilterResponse(data)));
  } catch (err) {
    yield put(actions.setLoadingState(false));
  }
}

export default function*(): any {
  yield all([fork(takeLatest, actions.getFilterData, getFilterDataSaga)]);
}
