import styled from 'styled-components';
import { Radio } from 'antd';
import { appTextColor } from 'src/App.styled';

export const RadioGroup = styled(Radio.Group)``;

export const RadioOption = styled(Radio)`
  margin-right: 0;
  margin-bottom: 5px;

  span {
    color: ${appTextColor};
  }

  .ant-radio {
    .ant-radio-inner {
      border-color: ${appTextColor};
      background-color: transparent;
    }

    &.ant-radio-checked {
      .ant-radio-inner {
        border-color: ${appTextColor};
        background-color: transparent;
      }
    }
  }
`;
