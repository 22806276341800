import * as R from 'ramda';
import { createSelector } from 'reselect';
import { getModuleSelector } from 'src/redux/utils';

const moduleSelector = getModuleSelector('azureDashboard');

export const azureDashboardDataSelector = createSelector(moduleSelector, R.prop('data'));

export const azurePartnerDashboardDataSelector = createSelector(moduleSelector, R.prop('partners'));

export const azureDashboardPageIsLoadedSelector = createSelector(moduleSelector, R.prop('isLoaded'));

export const azureDashboardIsLoadingSelector = createSelector(moduleSelector, R.prop('isLoading'));

export const azureDashboardPageHasErrorSelector = createSelector(moduleSelector, R.prop('hasError'));
