import React from 'react';

import * as Styled from './section.styled';

export const Section = ({ className, title, width, children, marginBottom }) => {
  const props = { className, width, marginBottom };

  return (
    <Styled.SectionBox {...props}>
      <Styled.SectionTitle marginBottom={marginBottom}>{title}</Styled.SectionTitle>
      {children}
    </Styled.SectionBox>
  );
};
