import styled from 'styled-components';
import { Search as SearchIcon } from '@material-ui/icons';
import { mixins, constants, Icon as AcoIcon } from '@accordo-feed/aco-styled-components';
import { tableHeaderRowBackground, tableRowBorderColor, widgetHeaderTextColor } from 'src/App.styled';

const searchHeight = 40;
const { fontSize, borderRadius } = constants;

// Flexbox alignment explanation:
// https://stackoverflow.com/a/33856609
export const Container = styled.div`
  ${mixins.size('100%', `${searchHeight}px`)}
  background-color: ${tableHeaderRowBackground};
  position: relative;
  width: 365px;
  margin-left: auto;
`;

export const Icon = styled(AcoIcon).attrs({
  size: 24,
  color: widgetHeaderTextColor,
  element: SearchIcon
})`
  position: absolute;
  top: 8px;
  right: 8px;
`;

export const Input = styled.input`
  ${mixins.size('100%', `${searchHeight}px`)}
  font-size: ${fontSize.small};
  font-style: normal;
  box-shadow: none;
  color: ${widgetHeaderTextColor};
  border: 1px solid ${tableRowBorderColor};
  border-radius: ${borderRadius};
  padding: 8px 60px 8px 15px;
  background: transparent;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${widgetHeaderTextColor};
  }
`;
