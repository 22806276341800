import styled from 'styled-components';
import { Checkbox } from 'antd';
import { appTextColor } from 'src/App.styled';

export const StyledCheckboxGroup = styled(Checkbox.Group)`
  ${props => (props.direction === 'vertical' ? 'display: flex; flex-direction: column;' : '')};

  .ant-checkbox-group-item {
    color: ${appTextColor};
    margin-bottom: 5px;
  }
`;
