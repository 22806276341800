import { langHelper } from '@accordo-feed/micro-frontends-utils';

const genId = langHelper.genLangId('shell.aco.dashboard.widget.securityScores');
const genOpts = langHelper.genLangOptions(genId);

export default {
  widgetTitle: genOpts('title', 'Security Score'),
  widgetSubTitle: genOpts('subTitle', 'Vulnerabilities and Secure Score'),
  chartXAxis: genOpts('chartxaxis', 'Security Score'),
  chartYAxis: genOpts('chartyaxis', 'Number of Clients'),
  customersWithLowScores: genOpts('customerswithlowscores', 'Low Score'),
  customersWithHighScores: genOpts('customerswithhighscores', 'High Score'),
  yourAverage: genOpts('youraverage', 'Average Score'),
  lowScore: genOpts('lowscore', 'Low Score'),
  moderate: genOpts('moderate', 'Moderate'),
  highScore: genOpts('highscore', 'High Score'),
  customersNotShowing: genOpts('customersnotshowing', 'Clients not showing'),
  noCustomersShowing: genOpts('nocustomersshowing', 'No Clients showing'),
  updatePermissions: genOpts('updatepermissions', 'Update permissions here'),
  addCustomers: genOpts('addcustomers', 'Add Clients here'),
  demoData: genOpts('demoData', 'Demo Data'),
  overlayTitle: genOpts('overlaytitle', 'Security (demo data)'),
  backToYourData: genOpts('backtoyourdata', 'Back to your data'),
  whySecureScores: genOpts('whysecurescores', 'Why get Secure Scores one at a time?'),
  weCanGive: genOpts('wecangive', 'We can give you this in one easy view.'),
  insights: genOpts('insights', 'Insights')
};
