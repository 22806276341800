// @flow

import React from 'react';

import LeftSide from './leftSide';
import RightSide from './rightSide';
import * as Styled from './secureApp.styled';

export default () => (
  <Styled.Container>
    <LeftSide />
    <RightSide />
  </Styled.Container>
);
