import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { ButtonElement } from '@accordo-feed/aco-styled-components';
import { useTranslate } from '@accordo-feed/language.entry';

import Widget from 'src/components/widget';
import { currentSubscription as lang } from './subscriptions.lang';

const TrialBox = styled.div`
  padding: 15px;
`;

const TrialBoxWrap = styled(Widget)`
  margin-top: 20px;
`;

const getDaysLeft = timeStamp =>
  moment(timeStamp)
    .local()
    .from(new Date(), true);

export const Trial = ({ trialPeriodEndTime }) => {
  const translate = useTranslate();
  const timeStamp = trialPeriodEndTime * 1000;

  const isTrialEnd = moment(timeStamp).isBefore(moment.utc());
  if (isTrialEnd) return null;

  return (
    <TrialBoxWrap title={translate(lang.trialDefinedHeading)}>
      <TrialBox>{translate(lang.trialDefinedSubHeading, { daysLeft: getDaysLeft(timeStamp) })}</TrialBox>
    </TrialBoxWrap>
  );
};

export const NextBillDate = styled.div`
  margin: 0 0 15px;
`;

export const BillPeriodSection = styled.div`
  padding: 15px;
`;

export const getDMYStrFromUTCTimestamp = timeStamp =>
  moment(timeStamp)
    .local()
    .format('D MMMM YYYY');

export const CancelSubscription = ({ office365CancelTime = 0, translate, willOffice365BeCancelled, onClick }) => (
  <>
    {willOffice365BeCancelled ? (
      <>
        {translate(lang.table.whenCancelMsg, {
          canceledDate: getDMYStrFromUTCTimestamp(office365CancelTime)
        })}
      </>
    ) : (
      <ButtonElement variant="dangerOutline" onClick={onClick}>
        {translate(lang.table.cancelButton)}
      </ButtonElement>
    )}
  </>
);
