// @flow

import React, { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Col, Image, Input, Row } from 'antd';
import { Formik, Form } from 'formik';
import { getName } from 'country-list';
import { useTranslate } from '@accordo-feed/language.entry';
import { ButtonElement } from '@accordo-feed/aco-styled-components';

import Widget from 'src/components/widget';
import CheckboxGroup from 'src/components/checkboxGroup';
import ColorPicker from 'src/components/colorPicker';
import PhoneCodePicker from 'src/components/phoneCodePicker';
import { getCodeByCountry } from 'src/components/phoneCodePicker/phoneCodePicker.utils';
import { ContentWrapper } from 'src/pages/page.styled';
import { useModalProps } from 'src/hooks';
import {
  InputField,
  CustomDivider,
  Title,
  FormRow,
  ErrMsg,
  FieldLabel,
  FieldValue,
  CheckboxGroupWrap
} from 'src/CustomComponent.styled';

import AccountSecurity from './accountSecurity';
import ProfileMfaModal from './profileMfaModal';
import PsaBillingAlert from './psaBillingAlert';
import SubscriptionUsage from './subscriptionUsage';
import { profileSchema } from './profile.config';
import Cancellation from './cancellation';
import { fileToBase64 } from './profile.utils';
import lang from './profile.lang';
import * as Styled from './profile.styled';

const { Group: InputGroup } = Input;
const { psaOptions: psaOptionsLang } = lang;

/*************
 *   TYPES   *
 *************/

export type Actions = {
  addPsaBillingAlertEmail: Function,
  removePsaBillingAlertEmail: Function,
  setPsaBillingAlertStatus: Function,
  setProfileUpdated: Function,
  updateProfile: Function,
  getDistiBillingData: Function,
  postCustomerLogo: Function
};

type Props = {
  actions: Actions,
  countryCode: string,
  email: string,
  isProfileUpdated: boolean,
  hasDistributorIngramMicro: boolean,
  isProfileUpdating: boolean,
  profile: Object
};

/*****************
 *   COMPONENT   *
 *****************/

const Profile = ({
  actions,
  countryCode,
  email,
  isProfileUpdated,
  isProfileUpdating,
  profile,
  hasDistributorIngramMicro
}: Props) => {
  const translate = useTranslate();

  const { isModalOpen, openModal, closeModal } = useModalProps();
  const modalProps = { isModalOpen, closeModal };
  const psaOptions = [
    { label: translate(psaOptionsLang.connectwise), value: 'connectWise' },
    { label: translate(psaOptionsLang.autotask), value: 'autoTask' },
    { label: translate(psaOptionsLang.kaseya), value: 'kaseya' },
    { label: translate(psaOptionsLang.others), value: 'others' }
  ];

  useEffect(() => {
    if (hasDistributorIngramMicro) {
      actions.getDistiBillingData();
    }
  }, []);

  const renderForm = ({ handleSubmit, setFieldValue, values: { userDetails, orgDetails } }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const onDrop = useCallback(async acceptedFiles => {
      if (acceptedFiles[0]) {
        const logo = await fileToBase64(acceptedFiles[0]);
        handleValueChange('orgDetails.logoImage')(logo);
      }
    }, []);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { isDragActive, getRootProps, getInputProps, open } = useDropzone({
      accept: 'image/jpeg,image/png',
      disabled: isProfileUpdating,
      multiple: false,
      maxSize: 5 * 1024 * 1024, // 5MB
      noKeyboard: true,
      onDrop
    });

    const handleValueChange = (key, callback: ?Function) => value => {
      actions.setProfileUpdated(false);
      setFieldValue(key, value);

      if (callback) {
        callback(value);
      }
    };

    const handleInputChange = (key, callback: ?Function) => e => {
      const { value } = e.target;
      handleValueChange(key, callback)(value);
    };

    const handlePhoneCodeChange = countryCode => {
      actions.setProfileUpdated(false);
      setFieldValue('userDetails.phone.countryCode', countryCode);
      setFieldValue('userDetails.phone.prefix', getCodeByCountry(countryCode));
    };

    return (
      <Styled.ProfileList>
        <Form>
          <Row gutter={[24, 16]}>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Title>{translate(lang.personalDetailsHeading)}</Title>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 18 }}>
              <Row gutter={[24, 0]}>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                  <FormRow>
                    <FieldLabel htmlFor="firstName">{translate(lang.firstNameLabel)}</FieldLabel>
                    <InputField
                      id="firstName"
                      className="at__firstNameTextbox"
                      value={userDetails.firstName}
                      onChange={handleInputChange('userDetails.firstName')}
                      name="userDetails.firstName"
                    />
                    <ErrMsg name="userDetails.firstName" noLeftGap />
                  </FormRow>
                </Col>

                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                  <FormRow>
                    <FieldLabel htmlFor="lastName">{translate(lang.lastNameLabel)}</FieldLabel>
                    <InputField
                      id="lastName"
                      className="at__lastNameTextbox"
                      value={userDetails.lastName}
                      onChange={handleInputChange('userDetails.lastName')}
                      name="userDetails.lastName"
                    />
                    <ErrMsg name="userDetails.lastName" noLeftGap />
                  </FormRow>
                </Col>
              </Row>

              <FormRow>
                <FieldLabel>{translate(lang.emailLabel)}</FieldLabel>
                <FieldValue>{email}</FieldValue>
              </FormRow>

              <FormRow>
                <FieldLabel>{translate(lang.countryLabel)}</FieldLabel>
                <FieldValue>
                  <Styled.FlagIcon className={`flag-icon flag-icon-${countryCode}`} />
                  {getName(countryCode)}
                </FieldValue>
              </FormRow>

              <FormRow marginBottom="0">
                <Row gutter={[24, 0]}>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    <FieldLabel htmlFor="phoneNumber">{translate(lang.phoneNumberLabel)}</FieldLabel>
                    <InputGroup compact>
                      <PhoneCodePicker
                        className="at__phoneCodeDropdown"
                        size="large"
                        showSearch
                        value={userDetails.phone.countryCode}
                        onChange={handlePhoneCodeChange}
                      />
                      <InputField
                        size="large"
                        id="phoneNumber"
                        className="at__phoneNumberTextbox"
                        name="userDetails.phone.number"
                        value={userDetails.phone.number}
                        onChange={handleInputChange('userDetails.phone.number')}
                      />
                    </InputGroup>
                    <ErrMsg name="userDetails.phone.number" noLeftGap />
                  </Col>
                </Row>
              </FormRow>
              <CustomDivider />
            </Col>
          </Row>

          <Row gutter={[24, 16]}>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Title>{translate(lang.organizationDetailsHeading)}</Title>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 18 }}>
              <Row gutter={[24, 0]}>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                  <FormRow>
                    <FieldLabel htmlFor="organisation">{translate(lang.organizationLabel)}</FieldLabel>
                    <InputField
                      id="organisation"
                      className="at__orgTextbox"
                      value={orgDetails.name}
                      onChange={handleInputChange('orgDetails.name')}
                      name="orgDetails.name"
                    />
                    <ErrMsg name="orgDetails.name" noLeftGap />
                  </FormRow>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                  <FormRow>
                    <FieldLabel htmlFor="organisationSeatCount">{translate(lang.seatCountLabel)}</FieldLabel>
                    <InputField
                      id="organisationSeatCount"
                      className="at__orgSeatTextbox"
                      value={orgDetails.seatCount}
                      onChange={handleInputChange('orgDetails.seatCount')}
                      name="orgDetails.seatCount"
                    />
                    <ErrMsg name="orgDetails.seatCount" noLeftGap />
                  </FormRow>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                  <FormRow>
                    <FieldLabel htmlFor="clientsNumber">{translate(lang.clientsLabel)}</FieldLabel>
                    <InputField
                      id="clientsNumber"
                      className="at__orgClientsTextbox"
                      value={orgDetails.clients}
                      onChange={handleInputChange('orgDetails.clients')}
                      name="orgDetails.clients"
                    />
                    <ErrMsg name="orgDetails.clients" noLeftGap />
                  </FormRow>
                </Col>
              </Row>

              <Row gutter={[24, 0]}>
                <Col xs={{ span: 24 }}>
                  <FormRow>
                    <FieldLabel>{translate(lang.brandLogo)}</FieldLabel>
                    <Styled.LogoFieldWrap>
                      <Styled.PreviewBlock {...getRootProps({ isDragActive })}>
                        <input {...getInputProps()} />
                        {orgDetails.logoImage ? (
                          <Image
                            className="logo-image"
                            preview={false}
                            src={orgDetails.logoImage}
                            alt={`${orgDetails.name} logo`}
                          />
                        ) : (
                          <Styled.PictureIcon />
                        )}
                      </Styled.PreviewBlock>
                      <Styled.LogoFieldButtonWrap>
                        <Styled.LogoFieldDesc>{translate(lang.brandLogoDescription)}</Styled.LogoFieldDesc>
                        <ButtonElement disabled={isProfileUpdating} variant="outline" onClick={open}>
                          {translate(lang.brandLogoButton)}
                        </ButtonElement>
                      </Styled.LogoFieldButtonWrap>
                    </Styled.LogoFieldWrap>
                    <ErrMsg name="orgDetails.logoImage" noLeftGap />
                  </FormRow>

                  <FormRow>
                    <FieldLabel>{translate(lang.brandAccentColor)}</FieldLabel>
                    <ColorPicker
                      initialColor={orgDetails.brandAccentColor}
                      onChange={handleValueChange('orgDetails.brandAccentColor')}
                    />
                  </FormRow>

                  <FormRow marginBottom="0">
                    <FieldLabel>{translate(lang.psaToolsLabel)}</FieldLabel>
                    <CheckboxGroupWrap>
                      <CheckboxGroup
                        options={psaOptions}
                        value={orgDetails.psaTools}
                        onChange={handleValueChange('orgDetails.psaTools')}
                      />
                    </CheckboxGroupWrap>
                  </FormRow>
                </Col>
              </Row>

              <CustomDivider />
            </Col>
          </Row>

          <Row gutter={[24, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}></Col>
            <Col xs={{ span: 24 }} lg={{ span: 18 }}>
              <ButtonElement
                variant="primary"
                onClick={handleSubmit}
                disabled={isProfileUpdated}
                loading={isProfileUpdating}
                margin="0"
              >
                {translate(lang.saveChangesButton)}
              </ButtonElement>
            </Col>
          </Row>
        </Form>
      </Styled.ProfileList>
    );
  };

  return (
    <ContentWrapper>
      <Widget title={translate(lang.heading)}>
        <Styled.FormWrap>
          <Formik
            enableReinitialize={true}
            validationSchema={profileSchema(translate)}
            initialValues={profile}
            onSubmit={actions.updateProfile}
            children={renderForm}
          />

          <CustomDivider />

          {hasDistributorIngramMicro && (
            <>
              <SubscriptionUsage />
              <CustomDivider />
            </>
          )}

          <AccountSecurity openModal={openModal} />

          <CustomDivider />

          <PsaBillingAlert />

          <ProfileMfaModal {...modalProps} />

          {hasDistributorIngramMicro && (
            <>
              <CustomDivider />
              <Cancellation />
            </>
          )}

          {
            // Save it here in order future use.
            /* <Styled.ComingSoonWrapper>
            <Styled.InviteButton>{translate(lang.inviteTeamMemberButton)}</Styled.InviteButton>
            <div>{translate(lang.comingSoonSpan)}</div>
            </Styled.ComingSoonWrapper> */
          }
        </Styled.FormWrap>
      </Widget>
    </ContentWrapper>
  );
};

Profile.noMinWidth = true;

export default Profile;
