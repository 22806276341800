import styled from 'styled-components';
import { CheckCircleFilled, MinusCircleFilled } from '@ant-design/icons';
import { constants, mixins } from '@accordo-feed/aco-styled-components';
import { appLabelColor, appTextColor } from 'src/App.styled';

const { colors, fontSize } = constants;

export const MfaIntroText = styled.span`
  color: ${appLabelColor};
  font-size: ${fontSize.small};
  margin-left: 25px;
`;

export const MfaOperationWrapper = styled.div`
  ${mixins.flexVerticalCenter};
  justify-content: space-between;
`;

export const MfaIconsContainer = styled.div`
  display: flex;
  ${mixins.flexVerticalCenter};
`;

export const MfaEnabledIcon = styled(CheckCircleFilled)`
  color: ${colors.aquaGreen};
  font-size: ${fontSize.large};
`;

export const MfaDisabledIcon = styled(MinusCircleFilled)`
  color: ${colors.aquaRed};
  font-size: ${fontSize.large};
`;

export const MfaStatusText = styled.span`
  margin-left: 5px;
  color: ${appTextColor};
`;

export const ChangePwdHeading = styled.div`
  color: ${appLabelColor};
  font-size: ${fontSize.small};
  margin-top: 10px;
`;

export const ChangePwdDescription = styled.div`
  color: ${appTextColor};
  font-size: ${fontSize.xSmall};
  flex: 1;
`;

export const ChangePwdOperationWrapper = styled(MfaOperationWrapper)`
  margin-top: 0;
`;
