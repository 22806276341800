import styled from 'styled-components';
import { WidgetCardWrapper } from 'src/components/widget/widget.styled';

export const WidgetWrapper = styled.div`
  margin-bottom: -24px;
`;

export const TableWrapper = styled(WidgetCardWrapper)`
  overflow: hidden;
`;
