import React from 'react';
import { langHelper } from '@accordo-feed/micro-frontends-utils';
import { LanguageEntry } from '@accordo-feed/language.entry';

const genId = langHelper.genLangId('shell.aco.unassignedLicenses.page');
const genOpts = langHelper.genLangOptions(genId);

export default {
  pageTitle: genOpts('title', 'Unassigned Licenses'),
  // TODO: Update language entry
  introduction: (
    <LanguageEntry
      id="shell.aco.unassignedLicenses.page.introduction"
      defaultMessage="<h2>Narrative to help with Widget’s purpose and how
    to act on it.</h2><p>Lorem ipsum dolor sit amet, consectetur adipiscing
    elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet
    lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor.</p>"
    />
  ),
  table: {
    viewLicensesDetails: genOpts('th.viewLicensesDetails', "View Licenses' Details"),
    viewLicenses: genOpts('td.viewLicenses', 'View Licenses'),
    percentUnassigned: genOpts('td.percentUnassigned', 'Percent Unassigned'),
    potentialSavings: genOpts('td.potentialSavings', 'Potential Savings')
  },
  filter: {
    fiterTitle1: genOpts('filter.title1', 'Active users'),
    fiterTitle2: genOpts('filter.title2', 'Total monthly spend'),
    tooltip1: genOpts('filter.tooltip1', 'Total monthly spend')
  }
};
