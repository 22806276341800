// @flow

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';

import withDashboardData from 'src/hoc/withDashboardDataHOC';
import { setGrowthTrendActiveUserFilter, setGrowthTrendTotalSpendFilter } from 'src/redux/modules/filters/filters.duck';
import { setSelectedPath } from 'src/pages/dashboard/dashboard.duck';
import { formatCurrencyHOC } from 'src/hoc';
import * as selectors from 'src/redux/selectors';
import GrowthTrendComp from './GrowthTrend.component';

/*****************
 *   COMPONENT   *
 *****************/

const GrowthTrend = props => <GrowthTrendComp {...props} />;

/*************
 *   REDUX   *
 *************/

const mapStateToProps = state => ({
  filterData: selectors.growthTrendCtaFilterDataSelector(state),
  dataSource: selectors.growthTrendCtaDataSourceSelector(state),
  originalDataSource: selectors.dashboardMasterListSelector(state),
  isLoading: selectors.dashboardIsLoadingSelector(state),
  chartData: selectors.dashboardGrowthTrendSelectedDataSelector(state) || [],
  selectedPath: selectors.dashboardGrowthTrendSelectedPathSelector(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { setGrowthTrendActiveUserFilter, setGrowthTrendTotalSpendFilter, setSelectedPath },
    dispatch
  )
});

export default compose(
  withDashboardData(),
  connect(mapStateToProps, mapDispatchToProps),
  formatCurrencyHOC
)(GrowthTrend);
