// @flow

import { WIDGET } from 'src/components/widget/widget.constants';

/*************
 *   TYPES   *
 *************/

// Note key objects names are dynamic and more or less can be added/removed
// e.g prop may contain {engagement: {value: 2, percentage: 12}}
// or
// {engagement: {value: 2, percentage: 12}, spongeBob: {value: 10, percentage: 55}} etc
type OpportunitiesProps = {
  engagement: {
    value: number,
    percentage: number
  },
  monthlyRevenue: {
    value: number,
    percentage: number
  },
  consultingRevenue: {
    value: number,
    percentage: number
  }
};

export const restructureOpportunitiesData = (opportunities: OpportunitiesProps) => {
  let total = 0;
  let data = [];

  if (!opportunities) {
    return { total, data };
  }

  // $FlowIgnore
  data = Object.keys(opportunities).map(key => {
    total += opportunities[key].value;
    return {
      key,
      value: opportunities[key].value,
      percentage: opportunities[key].percentage
    };
  });
  return { data, total };
};

export const generateLayout = () => [
  // Row 1
  // { i: WIDGET.OPPORTUNITIES, w: 2, active: true },
  { i: WIDGET.GROWTH_TREND, w: 2, active: true },
  { i: WIDGET.SECURITY_SCORES, w: 2, active: true },

  // Row 2
  { i: WIDGET.ADOPTION, w: 2, active: true },
  { i: WIDGET.ALLOCATION, w: 2, active: true },

  // Row 3
  { i: WIDGET.UNASSIGNED_LICENSES, w: 2, active: true },
  { i: WIDGET.BILLING_DISCREPANCY, w: 2, active: true }
];
