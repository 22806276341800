// @flow

import React from 'react';
import { useTranslate } from '@accordo-feed/language.entry';
import { Row, Col } from 'antd';

import { Tooltip } from 'src/pages/page.styled';
import { Title, Description, FormRow, FieldLabel, FieldValue } from 'src/CustomComponent.styled';
import lang from './subscriptionUsage.lang';

/*************
 *   TYPES   *
 *************/

type Props = {
  data: Object
};

/*****************
 *   COMPONENT   *
 *****************/

export default ({ data }: Props) => {
  const translate = useTranslate();

  return (
    <Row gutter={[24, 16]}>
      <Col xs={{ span: 24 }} lg={{ span: 8 }}>
        <Title>{translate(lang.heading)}</Title>
        <Description> {lang.description} </Description>
      </Col>
      <Col xs={{ span: 24 }} lg={{ span: 8 }}>
        <FormRow>
          <FieldLabel>
            {translate(lang.activeUsers)}
            <Tooltip title={translate(lang.tooltip)} />
          </FieldLabel>
          <FieldValue>{data.isInTrial ? translate(lang.freeTrial) : data.paidUserCount}</FieldValue>
        </FormRow>
      </Col>
    </Row>
  );
};
