export const LAYOUTS = {
  lg: [
    { i: 'azureDashboard', x: 0, y: 0, w: 12, h: 4, minH: 2, maxH: 5 },
    { i: 'azureBudgetMonitor', x: 8, y: 5, w: 4, h: 4, minH: 2, maxH: 5 }
  ],
  xs: [
    { i: 'azureDashboard', x: 0, y: 0, w: 1, h: 4, minH: 2, maxH: 5 },
    { i: 'azureBudgetMonitor', x: 0, y: 5, w: 12, h: 4, minH: 2, maxH: 5 }
  ]
};

export const BREAKPOINTS = { lg: 978, xs: 0 };
export const COLS = { lg: 12, xs: 1 };
