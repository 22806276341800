import styled from 'styled-components';
import { clearFix } from 'polished';

import Search from 'src/components/search';
import { Hide } from './filterPanel/filterPanel.styled';
import { WidgetCardWrapper } from 'src/components/widget/widget.styled';

export const CLICKED_ROW_CLS = 'clicked-row';
export const CUSTOMER_NAME_CLS = 'customer-name';

export const WidgetWrapper = styled.div`
  max-width: 640px;
  position: relative;
  height: 260px;
`;

export const TableWrapper = styled(WidgetCardWrapper)`
  overflow: hidden;
`;

export const OldFiltersContainer = styled.div`
  ${clearFix()};
  flex: 1;
`;

export const SearchBox = styled(Search)`
  margin-left: 0;
`;

export const ShowFilterPanel = styled(Hide)`
  margin-left: 20px;
`;

export const Container = styled.div``;
