import * as R from 'ramda';

import patch from './demo.data';

const injectDateAndValues = data => {
  const todayDate = new Date(new Date().setUTCHours(0, 0, 0, 0));

  return R.pipe(
    R.reverse,
    R.map(v => {
      v.date = todayDate.toISOString();
      todayDate.setDate(todayDate.getDate() - 1);
      return v;
    }),
    R.reverse
  )(data);
};

const prepareCustomerGrowthDemoData = R.mapObjIndexed(R.mapObjIndexed(R.map(R.evolve({ data: injectDateAndValues }))));

const prepareMasterListDemoData = (patchedMasterList, { masterList }) => {
  return R.map(m => {
    R.forEach(p => {
      if (m.name === p.name) {
        m.activeUsersGrowthPercentage = p.activeUsersGrowthPercentage;
        m.totalLicensesGrowthPercentage = p.totalLicensesGrowthPercentage;
        m.totalMonthlySpendGrowthPercentage = p.totalMonthlySpendGrowthPercentage;
        m.unassignedLicensesPercentage = p.unassignedLicensesPercentage;
      }
    }, patchedMasterList);
    return m;
  }, masterList);
};

export const patchData = data => {
  const { summary, masterList, customerGrowth } = patch;

  const customerGrowthDateInjected = prepareCustomerGrowthDemoData(customerGrowth);
  data.masterList = prepareMasterListDemoData(masterList, data);

  return R.evolve(
    {
      summary: R.mergeDeepLeft(summary),
      customerGrowth: R.mergeDeepLeft(customerGrowthDateInjected)
    },
    data
  );
};
