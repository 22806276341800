import * as R from 'ramda';
import { createSelector } from 'reselect';

import { getModuleSelector } from 'src/redux/utils';
import { REDUCER_NAMESPACES } from 'src/constants';

const orgSelector = getModuleSelector('organization', REDUCER_NAMESPACES.OFFICE_365_OPTIMIZER);

export const shellOrgSelector = getModuleSelector('organization', REDUCER_NAMESPACES.SHELL);

const msAuthSelector = getModuleSelector('msAuth', REDUCER_NAMESPACES.OFFICE_365_OPTIMIZER);

export const customerNameSelector = createSelector(orgSelector, R.path(['data', 'name']));

export const isLargeCustomerSelector = createSelector(msAuthSelector, R.prop('isLargeCustomer'));
