// @flow

import * as React from 'react';
import { mapProps } from 'recompose';

import * as Styled from './recommendations.styled';
import { ICONS_MAP } from './recommendations.config';
import { lang } from './recommendations.lang';

/*************
 *   TYPES   *
 *************/

type Props = {
  icon: React.Node,
  title: string
};

/*****************
 *   COMPONENT   *
 *****************/

const Heading = ({ icon, title }: Props) => (
  <Styled.Container>
    {icon}
    {title}
  </Styled.Container>
);

export default mapProps(({ type, translate }) => {
  const title = translate(lang[type]);
  const iconProps = ICONS_MAP[type];

  return {
    title,
    icon: <Styled.Icon {...iconProps} size="32px" gapRight="10px" />
  };
})(Heading);
