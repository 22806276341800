// @flow

import * as polished from 'polished';
import { Security, CreditCard, Equalizer, Public } from '@material-ui/icons';
import { constants } from '@accordo-feed/aco-styled-components';

import * as Styled from './recommendations.styled';
import { RECOM_SECTIONS } from 'src/constants';
import { dateConverter } from 'src/utils';

const { genTableCell, genTableCellSaving } = Styled;
const { colors } = constants;

export const DescriptionCell = genTableCell(8);
export const ImpactCell = genTableCell(3, 'center');
export const ResouceCell = genTableCell(4);
export const UpdatedCell = genTableCell(3);
export const MonthlySavingsCell = genTableCellSaving(6, 'right');
export const PotentialBenefitCell = genTableCell(6);
export const convertDate = dateConverter('DD/MM/YY');

export const ICONS_MAP = {
  [RECOM_SECTIONS.COST]: {
    element: CreditCard,
    color: colors.teal
  },
  [RECOM_SECTIONS.SECURITY]: {
    element: Security,
    color: colors.cyan
  },
  [RECOM_SECTIONS.PERFORMANCE]: {
    element: Equalizer,
    color: polished.rgba(colors.purple, 0.6)
  },
  [RECOM_SECTIONS.AVAILABILITY]: {
    element: Public,
    color: colors.navy
  }
};
