import styled from 'styled-components';
import { Layout } from 'antd';
import { stripUnit } from 'polished';
import { constants, Icon } from '@accordo-feed/aco-styled-components';
import { bodyBackground, scrollbarHandlerColor, sidebarBackground } from 'src/App.styled';

const { colors, zIndex } = constants;

export const siderWidth = '256px';

export const Sider = styled(Layout.Sider).attrs({
  width: stripUnit(siderWidth)
})`
  && {
    height: 100vh;
    position: fixed;
    left: 0;
    z-index: ${zIndex + 10};
    background: ${sidebarBackground};
    padding-top: 30px;
    overflow: visible;

    .ant-menu-item {
      width: 100%;
    }

    .ant-layout-sider-trigger {
      display: none;
    }

    .ant-layout-sider-children {
      position: relative;
    }
  }
`;

export const ToggleContainer = styled.div`
  width: 26px;
  height: 26px;
  position: absolute;
  z-index: ${zIndex + 10};
  bottom: 20px;
  right: -13px;
  border-radius: 15px;
  border: 2px solid ${scrollbarHandlerColor};
  background: ${bodyBackground};
  cursor: pointer;
`;

export const ToggleIcon = styled(Icon).attrs({
  color: colors.darkCyan,
  size: '22px'
})`
  transition: transform 1s ease;
  &.expand {
    transform: rotate(-180deg);
  }
`;
