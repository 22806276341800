import * as React from 'react';
import { ErrorMessage } from 'formik';

import * as Styled from './errorMessage.styled';

/*************
 *   TYPES   *
 *************/

type Props = {
  className: String,
  children: React.Node
};

/*****************
 *   COMPONENT   *
 *****************/

export default ({ className, children, ...restProps }: Props) => (
  <Styled.Wrapper className={className}>{children || <ErrorMessage {...restProps} />}</Styled.Wrapper>
);
