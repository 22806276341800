import React from 'react';
import { LanguageEntry } from '@accordo-feed/language.entry';

import * as Styled from 'src/pages/masterList/masterList.styled';
import { PATHS, SUBMODULES } from 'src/constants';
import { sortByAlph, sortByNumber } from 'src/utils';
import lang from './opportunities.lang';

/***************
 *   CONFIGS   *
 ***************/

const defaultWidth = {
  small: 80,
  medium: 120,
  large: 150
};

export const TableColumns = [
  {
    title: <LanguageEntry {...lang.table.customer} />,
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
    sorter: sortByAlph('name'),
    width: defaultWidth.large,
    className: 'at_columnName aco-font-small aco-vertical-middle',
    render: name => <Styled.CustomerName>{name}</Styled.CustomerName>
  },
  {
    title: <LanguageEntry {...lang.table.opportunities.th2} />,
    dataIndex: 'engagement',
    key: 'engagement',
    width: defaultWidth.small,
    className: 'at_columnEngagement aco-font-small aco-vertical-middle aco-text-center',
    sorter: sortByNumber('engagement')
  },
  {
    title: <LanguageEntry {...lang.table.opportunities.th3} />,
    dataIndex: 'monthlyRevenue',
    key: 'monthlyRevenue',
    width: defaultWidth.small,
    className: 'at_columnMonthlyRevenue aco-font-small aco-vertical-middle aco-text-center',
    sorter: sortByNumber('monthlyRevenue')
  },
  {
    title: <LanguageEntry {...lang.table.opportunities.th4} />,
    dataIndex: 'consultingRevenue',
    key: 'consultingRevenue',
    width: defaultWidth.small,
    className: 'at_columnConsultingRevenue aco-font-small aco-vertical-middle aco-text-center',
    sorter: sortByNumber('consultingRevenue')
  },
  {
    title: <LanguageEntry {...lang.table.opportunities.th1} />,
    dataIndex: 'totalRecommendations',
    key: 'totalRecommendations',
    width: defaultWidth.small,
    className: 'at_columnTotalRecommendations aco-font-small aco-vertical-middle aco-text-center',
    sorter: sortByNumber('totalRecommendations')
  },
  {
    title: <LanguageEntry {...lang.table.viewRecommendations} />,
    dataIndex: 'id',
    key: 'id',
    width: defaultWidth.large,
    className: 'at_columnRecommendations aco-font-small aco-vertical-middle aco-text-center',
    render: id => (
      <Styled.VisitLink
        to={{
          pathname: `/${SUBMODULES.OFFICE_365_OPTIMIZER}/${id}/recommendations`,
          state: { prevPath: PATHS.DASHBOARD.ROOT }
        }}
      >
        <LanguageEntry {...lang.table.viewOpportunitiesDetails} />
      </Styled.VisitLink>
    )
  }
];
