import styled from 'styled-components';
import { Link } from 'react-router';
import { constants } from '@accordo-feed/aco-styled-components';

import { appTextColor } from 'src/App.styled';

const { fontSize } = constants;

const StyledLink = styled(Link)`
  cursor: pointer;
  color: ${appTextColor};
  font-size: ${props => props.fontSize || fontSize.small};
  line-height: ${props => props.lineHeight || '100%'};
  text-decoration: ${props => (props.to ? 'underline' : 'none')};

  &:hover {
    text-decoration: none;
  }

  &:active,
  &:focus {
    text-decoration: ${props => (props.to ? 'underline' : 'none')};
  }
`;

export default StyledLink;
