// @flow

import * as R from 'ramda';
import { connect } from 'react-redux';

import * as selectors from 'src/redux/selectors';
import AccountSecurityComponent from './AccountSecurity.component';

/*************
 *   REDUX   *
 *************/

const mapStateToProps = R.applySpec({
  isMfaEnabled: selectors.isMfaEnabledSelector,
  email: selectors.userEmailSelector
});

export default connect(mapStateToProps)(AccountSecurityComponent);
