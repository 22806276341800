import * as R from 'ramda';
import bodybuilder from 'bodybuilder';
import { createSelector } from 'reselect';
import { search } from '@accordo-feed/micro-frontends-utils';

import { orgIdSelector, searchTermSelector, filtersSecureScoreSelector } from 'src/redux/selectors';
import { pageSelector } from 'src/pages/pages.selector';
import { selectedFiltersSelector } from './filterPanel/filterPanel.selector';
import { ADVANCED_FILTER_KEYS, ADVANCED_FILTER_TEXT_MAP, ES_QUERY_CONFIG } from './securityScore.constants';

export const securityPageSelector = createSelector(pageSelector, R.path(['securityScore', 'page']));

export const securityPageDataSelector = createSelector(securityPageSelector, R.prop('data'));

export const securityPageIsLoadingSelector = createSelector(securityPageSelector, R.prop('isLoading'));

export const securityPageClientsSelector = createSelector(securityPageDataSelector, R.propOr([], 'clients'));

export const securityPageCountSelector = createSelector(securityPageDataSelector, R.propOr([], 'count'));

export const securityPageQuerySelector = createSelector(orgIdSelector, partnerId => {
  const esQuery = bodybuilder()
    .size(ES_QUERY_CONFIG.SIZE)
    .rawOption('_source', ES_QUERY_CONFIG.FIELDS)
    .query('term', 'partnerId', partnerId)
    .sort(ES_QUERY_CONFIG.SORT)
    .build();

  return {
    queryName: 'securityTable',
    partnerId,
    esQuery
  };
});

export const filterSelector = createSelector(
  securityPageClientsSelector,
  filtersSecureScoreSelector,
  (list, selectedFilters) => {
    const valueArray = R.uniq(R.map(R.prop('currentScore'), list));
    const minCurrentScore = Math.floor(Math.min(...valueArray));
    const maxCurrentScore = Math.ceil(Math.max(...valueArray));
    const minScore = valueArray.length > 0 ? minCurrentScore : 0;
    const maxScore = valueArray.length > 0 ? maxCurrentScore : 0;
    const defaultFilters = {
      shouldShowCurrentScoreFilter: false,
      shouldShowScoreIndexFilter: false,
      minCurrentScore: minScore,
      maxCurrentScore: maxScore,
      minScoreIndex: 0,
      maxScoreIndex: 100,
      selectedMinCurrentScore: selectedFilters.minCurrentScore || minScore,
      selectedMaxCurrentScore: selectedFilters.maxCurrentScore || maxScore,
      selectedMinScoreIndex: selectedFilters.minScoreIndex,
      selectedMaxScoreIndex: selectedFilters.maxScoreIndex
    };

    if (valueArray.length < 2) return defaultFilters;

    const transformations = {
      shouldShowCurrentScoreFilter: R.T,
      shouldShowScoreIndexFilter: R.T,
      minCurrentScore: R.always(minCurrentScore),
      maxCurrentScore: R.always(maxCurrentScore)
    };

    return R.evolve(transformations, defaultFilters);
  }
);

export const secureScoreCtaDataSourceSelector = createSelector(
  securityPageClientsSelector,
  securityPageCountSelector,
  searchTermSelector,
  filterSelector,

  (list, count, term, filters) => {
    const filteredList = list.filter(
      ({ currentScore, percentage }) =>
        currentScore >= filters.selectedMinCurrentScore &&
        currentScore <= filters.selectedMaxCurrentScore &&
        percentage >= filters.selectedMinScoreIndex &&
        percentage <= filters.selectedMaxScoreIndex
    );

    return { clients: search({ list: filteredList, settings: ['clientName'] }, term), count };
  }
);

export const filterTagSelector = createSelector(
  selectedFiltersSelector,
  R.pipe(
    R.mapObjIndexed((val, key) => {
      if ([ADVANCED_FILTER_KEYS.CURRENT_SCORE, ADVANCED_FILTER_KEYS.PERCENTAGE].includes(key)) {
        val = R.isEmpty(val) || R.isNil(val) ? [] : [`${val[0]} - ${val[1]}`];
      }
      return R.isNil(val)
        ? []
        : R.map(item => `${ADVANCED_FILTER_TEXT_MAP[key] ? `${ADVANCED_FILTER_TEXT_MAP[key]}: ` : ''}${item}`, val);
    }),
    R.values,
    R.flatten
  )
);
