// @flow

import React from 'react';
import { LanguageEntry } from '@accordo-feed/language.entry';

import { NoData } from 'src/components/widgetWrapper';
import * as Styled from './billingDiscrepancy.styled';
import lang from './billingDiscrepancy.lang';

/*****************
 *     TYPES     *
 *****************/

type Props = {
  onButtonClick: Function
};

/*****************
 *   COMPONENT   *
 *****************/

export const NotConfigured = ({ onButtonClick }: Props) => (
  <Styled.ContentWrapper>
    <Styled.InfoWrapper configWidth>
      <LanguageEntry {...lang.uncoverBillingIssues} />
      <Styled.BillingDiscrepancyLargeIcon />
      <NoData />
    </Styled.InfoWrapper>
  </Styled.ContentWrapper>
);
