import styled, { css } from 'styled-components';
import { constants } from '@accordo-feed/aco-styled-components';

import { appTextColor, bodyBackground } from 'src/App.styled';
import { setProperty } from 'src/utils';

const { fontSize } = constants;

export const SectionBox = styled.section`
  padding: 32px;
  background-color: ${bodyBackground};
  ${({ noMarginBottom }) =>
    !noMarginBottom &&
    css`
      margin-bottom: 10px;
    `};
  flex: 1;
  ${setProperty('width')}
  &.modal-section {
    background-color: transparent;
  }
`;

export const SectionTitle = styled.div`
  color: ${appTextColor};
  font-size: ${fontSize.xLarge};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '16px')};
`;
