// @flow

import * as React from 'react';
// import { Grid } from '@accordo-feed/aco-styled-components';
// import { Icon } from 'antd';
// import { useTranslate } from '@accordo-feed/language.entry';

import * as Styled from './ctaLayout.styled';
// import lang from './ctaLayout.lang';
// import { PATHS } from 'src/constants';
import { ActionWrapper } from 'src/pages/page.styled';
import TwoColumnLayout from 'src/layouts/sectionLayouts/twoColumnLayout';

/*************
 *   TYPES   *
 *************/

type LayoutTypes = {
  className: string,
  title: string | React.Node,
  widget: React.Node,
  description: string | React.Node,
  filters: React.Node,
  tableContent: React.Node,
  statics?: React.Node,
  useTwoColumnLayout: boolean,
  siderContent?: React.Node,
  filterTags?: React.Node
};

/*****************
 *   COMPONENT   *
 *****************/

const CtaLayout = ({
  className,
  title,
  widget,
  description,
  filters,
  tableContent,
  statics,
  useTwoColumnLayout,
  siderContent,
  filterTags
}: LayoutTypes) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const translate = useTranslate();

  return (
    <Styled.CtaLayoutWrapper className={className}>
      {/* <Styled.InPageNavWrapper>
        <Styled.BackBtn to={PATHS.DASHBOARD.ROOT}>
          <Icon type="left" /> {translate(lang.backBtn)}
        </Styled.BackBtn>

        <Styled.PageTitle>{title}</Styled.PageTitle>

        <Styled.MasterListBtn to={PATHS.ALLOCATION_ADOPTION_DASHBOARD}>{translate(lang.masterListBtn)}</Styled.MasterListBtn>
      </Styled.InPageNavWrapper> */}

      {/* <Styled.GridWrapper className={className}>
        <Grid
          rowProps={{ gutter: 0, type: 'flex', align: 'middle' }}
          colProps={{ col1: { span: 14 }, col2: { span: 8 } }}
          columns={
            widget
              ? {
                  col1: widget,
                  col2: <Styled.WidgetIntroWrapper>{description}</Styled.WidgetIntroWrapper>
                }
              : { col1: <Styled.WidgetIntroWrapper>{description}</Styled.WidgetIntroWrapper> }
          }
        />
      </Styled.GridWrapper> */}

      <Styled.TwoColumnLayoutWrapper className={className}>
        <TwoColumnLayout
          className={useTwoColumnLayout && className}
          hideSider={!useTwoColumnLayout}
          siderContent={siderContent}
          mainContent={
            <Styled.MainContent hideSider={!useTwoColumnLayout}>
              <ActionWrapper>{filters}</ActionWrapper>
              {filterTags}
              {statics}
              {tableContent}
            </Styled.MainContent>
          }
        />
      </Styled.TwoColumnLayoutWrapper>
    </Styled.CtaLayoutWrapper>
  );
};

export default CtaLayout;
