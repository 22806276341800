// @flow

import React from 'react';
import { Form } from 'formik';
import { message } from 'antd';
import { getName as getCountryName } from 'country-list';
import { ButtonElement } from '@accordo-feed/aco-styled-components';
import { useTranslate } from '@accordo-feed/language.entry';

import AddressFormWrapper from 'src/components/addressForm';
import type { LoadingStatusType } from 'src/constants';
import { PrivacyLink } from 'src/components/appFooter/AppFooter.component';
import { isStatus } from 'src/utils';
import { useModalProps } from 'src/hooks';
import CustomModal from 'src/components/modal';

import { Field, StyledAddress } from './paymentInfo.styled';
import { Row } from '../paymentDetails/paymentDetails.styled';
import { paymentInfo as lang } from './billing.lang';

/*************
 *   TYPES   *
 *************/

type Props = {
  orgId: string,
  billingAddress: {
    line1: string,
    line2?: string,
    city: string,
    region?: string,
    country: string,
    postalCode: string
  },
  updateBillingAddressLoadingStatus: LoadingStatusType,
  updateBillingAddress: Function,
  updateBillingAddressLoaded: Function
};

/*****************
 *   COMPONENT   *
 *****************/

const SavedAddress = ({ billingAddress, openModal, buttonLabel }) => {
  const { line1, line2, city, region, postalCode, country } = billingAddress;
  return (
    <StyledAddress>
      <div>{line1}</div>
      {line2 && <div>{line2}</div>}
      <div>{city}</div>
      <div>{`${region ? `${region.toUpperCase()} ` : ''}${postalCode}`}</div>
      <div>{getCountryName(country)}</div>
      <ButtonElement onClick={openModal} variant="outline" margin="20px 0 0 0">
        {buttonLabel}
      </ButtonElement>
    </StyledAddress>
  );
};

export const BillingAddress = ({
  orgId,
  billingAddress,
  updateBillingAddress,
  updateBillingAddressLoaded,
  updateBillingAddressLoadingStatus
}: Props) => {
  const translate = useTranslate();
  const { isModalOpen, openModal, closeModal } = useModalProps();

  const onSave = data => {
    updateBillingAddress({
      orgId,
      data,
      whenSuccess: () => {
        closeModal();
        message.success(translate(lang.paymentInfo.billingAddress.updateSuccessMsg));
      },
      whenFailed: () => {
        message.warn(translate(lang.paymentInfo.billingAddress.updateFailedMsg));
      }
    });
  };

  const onCancel = () => {
    updateBillingAddressLoaded();
    closeModal();
  };

  return (
    <Field labelText={translate(lang.paymentInfo.billingAddress.title)} alignItems="baseline">
      <SavedAddress
        billingAddress={billingAddress}
        openModal={openModal}
        buttonLabel={translate(lang.paymentInfo.billingAddress.updateButton)}
      />

      <CustomModal
        isModalOpen={isModalOpen}
        closeModal={onCancel}
        title="Update Billing Address"
        customButtons={true}
        destroyOnClose
      >
        <AddressFormWrapper
          isInitialValid={false}
          onSubmit={onSave}
          renderWithAddressForm={({ isValid, addressElement }) => (
            <Form>
              {addressElement}
              <PrivacyLink margin="10px 0 0 0" display="block" />
              <Row margin="32px 0 0" justifyContent="flex-end" alignItems="center">
                <ButtonElement variant="outline" onClick={onCancel}>
                  {translate(lang.updateCard.cancelButton)}
                </ButtonElement>
                <ButtonElement
                  htmlType="submit"
                  variant="primary"
                  disabled={!isValid}
                  loading={isStatus.loading(updateBillingAddressLoadingStatus)}
                  margin="0 0 0 15px"
                >
                  {translate(lang.updateCard.saveButton)}
                </ButtonElement>
              </Row>
            </Form>
          )}
        />
      </CustomModal>
    </Field>
  );
};
