// @flow

import { core } from '@accordo-feed/micro-frontends-utils';

import { API } from 'src/utils/api';
import { resolveApiEndpoint, requestHandler } from 'src/utils';

const orgnizationApi = resolveApiEndpoint(API.GET_ORGANIZATION);

export const getOrganization = (orgId: string): Promise<Object> =>
  requestHandler({
    url: core.replaceAll(orgnizationApi, { orgId })
  });
