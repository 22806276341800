import * as R from 'ramda';
import throttle from 'lodash.throttle';

import * as customersActions from 'src/pages/customers/customers.duck';
import * as partnerCenterActions from 'src/redux/modules/partnerCenter/partnerCenter.duck';
import * as partnerCenterSelector from 'src/redux/modules/partnerCenter/partnerCenter.selector';
import * as secureAppActions from 'src/pages/secureApp/secureApp.duck';
import * as syncCustomersSelector from 'src/pages/customers/syncCustomersMessage/syncCustomersMessage.selector';
import lang from './partnerCenter.lang';
import { PARTNER_CLIENT_ORG_INDEX_UPDATED_INDEXES } from 'src/utils/iot/constants';

/**************
 *   CONFIG   *
 **************/

/**
 * Options for throttling function
 *
 * @type {{trailing: boolean}}
 */
const options = {
  trailing: true // means that function will be invoked at the end of WAIT time
};

/**
 * How long to ignore function invocation after invoking it first time
 *
 * @type {number}
 */
const WAIT = 5000;

/**
 * How long to close processing import customers message. Current setting is 15 minutes.
 *
 * @type {number}
 */
const MAX_SYNC_CUSTOMERS_WAIT = 900000;

/****************
 *   HANDLERS   *
 ****************/

/**
 * PARTNER_CLIENT_ORG_INDEX_UPDATED handler
 * if messageJSON index field is 'partner-client-organization' then trigger processing message setting
 * no matter index is organization or consumption always trigger fetchCustomers
 */
export const handlerClients = ({ dispatch, getState, messageJSON }) => {
  if (R.pathEq(['status'], 'error', messageJSON)) return;

  const state = getState();
  const syncCustomersNum = syncCustomersSelector.syncCustomersNumSelector(state);
  const totalSyncCustomersNum = syncCustomersSelector.totalSyncCustomersNumSelector(state);

  const isOrgUpdate = R.pathEq(['index'], PARTNER_CLIENT_ORG_INDEX_UPDATED_INDEXES.PARTNER_CLIENT_ORG, messageJSON);
  const isTriggeredByLoadAllCustomers = totalSyncCustomersNum > 0;

  if (isOrgUpdate && isTriggeredByLoadAllCustomers) {
    if (syncCustomersNum === 0) {
      dispatch(customersActions.setSyncCustomersMessageState(true));
    }

    dispatch(customersActions.setSyncCustomersNum(R.inc(syncCustomersNum)));
  }
  fetchCustomers({ dispatch, getState, messageJSON });
};

/**
 * trigger fetchCustomers action which under controls by throttle
 */
const fetchCustomers = throttle(
  ({ dispatch, getState, messageJSON }) => {
    dispatch(customersActions.fetchCustomers());
  },
  WAIT,
  options
);

/**
 * PC_OAUTH and LOAD_ALL_CUSTOMERS BE logic always chain execute.
 * For handlerOauth is the first step of partner Oauth flow so we only catch error.
 */
export const handlerOauth = ({ dispatch, getState, messageJSON }) => {
  if (R.pathEq(['status'], 'error', messageJSON)) {
    dispatch(partnerCenterActions.handleConnectionFailed(messageJSON));
  }
};

/**
 * LOAD_ALL_CUSTOMERS is the second step of partner Oauth flow we catch success and treat different for secureApp or partnerCenter connect.
 * For error we treat same.
 */
export const handlerLoadAllCustomers = ({ dispatch, getState, messageJSON, translate }) => {
  const state = getState();
  const isSecureApp = partnerCenterSelector.isSecureAppConnectionTypeSelector(state);
  const loginWindowLang = lang.loginWindow;
  const totalSyncCustomersNum = R.prop('newCustomersToImport', messageJSON);

  if (R.pathEq(['status'], 'success', messageJSON)) {
    const syncCustomersTimeoutId = setTimeout(() => {
      dispatch(customersActions.resetSyncCustomersMessageSettings());
    }, MAX_SYNC_CUSTOMERS_WAIT);

    const action = isSecureApp
      ? secureAppActions.connectPartnerCenterSuccessful()
      : partnerCenterActions.connectPartnerCenterSuccessful({
          message: translate(loginWindowLang.connectSuccessMessage),
          title: translate(loginWindowLang.connectSuccessTitle)
        });

    dispatch(action);
    dispatch(customersActions.setTotalSyncCustomersNum(totalSyncCustomersNum));
    dispatch(customersActions.setSyncCustomersTimeoutId(syncCustomersTimeoutId));
  } else {
    dispatch(partnerCenterActions.handleConnectionFailed(messageJSON));
  }
};

/**
 * Individual customers Oauth flow update related message handler
 *
 * @param dispatch
 * @param getState
 * @param messageJSON
 */
export const handlerCustomersOauth = ({ dispatch, getState, messageJSON }) => {
  if (R.pathEq(['status'], 'error', messageJSON)) {
    dispatch(customersActions.handleConnectionFailed(messageJSON));
  }
};
