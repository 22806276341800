// According to IoT Protocol Limits, the default keep-alive interval is 1200 seconds,
// which is 30 mins. So, to make it more safer, it reconnects IoT protocal for every 29 mins
// https://docs.aws.amazon.com/general/latest/gr/aws_service_limits.html#iot-protocol-limits
export const IOT_RECONNECT_INTERVAL_MS = 1740000; // 29 * 60 * 1000

export const HANDLER_TYPES = {
  CONSOLE: 'CONSOLE',

  // Message contract - https://accordo.atlassian.net/wiki/spaces/PE/pages/768966722/Partner+Center+OAuth+Flow#PartnerCenterOAuthFlow-pc-client-iot
  PARTNER_CLIENT_ORG_INDEX_UPDATED: 'PARTNER_CLIENT_ORG_INDEX_UPDATED',

  // Message contract - https://accordo.atlassian.net/wiki/spaces/PE/pages/768966722/Partner+Center+OAuth+Flow#PartnerCenterOAuthFlow-pc-oauth-iot
  PC_OAUTH: 'PC_OAUTH',

  // Message contract - https://bitbucket.org/accordogroup/partner-center-service/src/master/docs/actions.md
  LOAD_ALL_CUSTOMERS: 'LOAD_ALL_CUSTOMERS',

  // No contract, refer to code in consumption-service
  GRAPH_OAUTH: 'GRAPH_OAUTH'
};

export const PARTNER_CLIENT_ORG_INDEX_UPDATED_INDEXES = {
  PARTNER_CLIENT_ORG: 'partner-client-organization'
};
