import React, { useMemo } from 'react';
import ReactECharts from 'echarts-for-react';
import * as R from 'ramda';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { constants } from '@accordo-feed/aco-styled-components';

import * as selectors from 'src/redux/selectors';
import { formatCurrencyHOC, widgetDataHOC } from 'src/hoc';

const { colors } = constants;

type Props = {
  theme?: 'light' | 'dark',
  data: Array<Object>,
  formatCurrency: Function
};

const M365Licences = ({ data = [], theme, formatCurrency }: Props) => {
  const options = useMemo(() => {
    return {
      tooltip: {
        trigger: 'item',
        textStyle: {
          fontSize: 14,
          width: 130,
          overflow: 'truncate'
        },
        formatter: params => {
          const { purchased, annualSpend, assigned, unassigned } = params.data;
          return `
            <div style="display: flex; align-items: center;  margin-bottom: 8px">
              <div style="background-color: ${
                params.color
              }; width: 15px; height: 15px; border-radius: 180px; display: inline-block; margin-right: 8px;"></div> 
              <span>${params.name}</span>
            </div>
            Total Licenses: <span>${purchased ?? 0}</span> <br/>
            Annual Spend: <span">${formatCurrency(annualSpend ?? 0)}</span> <br/>
            Assigned: <span>${assigned ?? 0}</span> <br/>
            Unassigned: <span>${unassigned}</span> <br/>
          `;
        }
      },

      legend: {
        orient: 'vertical',
        left: 'left',
        icon: 'circle',
        top: 15,
        itemGap: 20,
        itemWidth: 15,
        itemHeight: 15,
        padding: [5, 15],
        textStyle: {
          color: 'inherit',
          padding: [0, 5],
          fontStyle: 'normal',
          fontSize: 14,
          width: 180,
          overflow: 'truncate'
        }
      },

      series: [
        {
          name: 'License Chart',
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: false,
          label: { show: false, position: 'center' },
          center: ['100%', '45%'],
          width: '70%',
          emphasis: {
            label: {
              show: true,
              fontSize: '14',
              color: theme === 'dark' ? colors.white : colors.grey,
              width: 130,
              overflow: 'truncate'
            }
          },
          data
        }
      ]
    };
  }, [data, theme]);

  return <ReactECharts className="piechart" style={{ height: '100%' }} option={options} />;
};

/*************
 *   REDUX   *
 *************/

const mapStateToProps = R.applySpec({
  theme: selectors.appThemeSelector
});

export default compose(connect(mapStateToProps), widgetDataHOC(['data']), formatCurrencyHOC)(M365Licences);
