import { langHelper } from '@accordo-feed/micro-frontends-utils';

const genId = langHelper.genLangId('shell.aco.dashboard.widget.opportunitiesHighlights');
const genOpts = langHelper.genLangOptions(genId);

export default {
  licenseOverview: genOpts('overview', 'Overview'),
  overviewSubtitle: genOpts('overviewSubtitle', 'Customers & Licenses'),
  companiesTitle: genOpts('companies', 'Companies'),
  subscriptionsTitle: genOpts('subscriptions', 'Subscriptions'),
  subscriptionsSubTitle: genOpts('legacy', 'Legacy & New Commerce'),
  customers: genOpts('customers', 'Customers'),
  prospects: genOpts('prospects', 'Prospects'),
  connected: genOpts('connected', 'Connected'),
  failed: genOpts('failed', 'Failed'),
  legacy: genOpts('legacy', 'Legacy Subs'),
  newCommerce: genOpts('newCommerce', 'New Commerce'),
  totalCustomers: genOpts('totalCustomers', 'Total Customers'),
  totalActiveUsers: genOpts('totalActiveUsers', 'Total Active Users'),
  totalLicenses: genOpts('totalLicenses', 'Total Licenses'),
  totalUniquePlans: genOpts('totalUniquePlans', 'Total Unique Office365 Plans'),
  opportunities: genOpts('opportunities', 'Recommendations'),
  recommendationsSubtitle: genOpts('recommendationsSubtitle', 'Insights for Retention and Growth'),
  recommendationsOptimization: genOpts('recommendationsOptimization', 'Optimization'),
  viewAll: genOpts('viewAll', 'View All'),
  security: genOpts('security', 'Security'),
  engagement: genOpts('engagement', 'Engagement'),
  monthlyRevenue: genOpts('monthlyRevenue', 'Monthly Revenue'),
  consultingRevenue: genOpts('consultingRevenue', 'Consulting Revenue'),
  secureScore: genOpts('secureScore', 'Secure Score'),
  secureScoreTooltip: genOpts('secureScoreTooltip', 'Average Secure Score')
};
