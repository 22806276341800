import styled from 'styled-components';
import { mixins, constants } from '@accordo-feed/aco-styled-components';
import WarningSvg from 'src/images/bannerWarning.svg';
import { iconMixins } from 'src/components/appSider/menu/menu.styled';

import Banner from 'src/components/banner';
import { STORAGE_KEYS } from 'src/constants';
import { contentPadding } from 'src/layouts/coreLayout.styled';
import { bodyBackground, widgetHeaderTextColor } from 'src/App.styled';
const { colors, fontSize } = constants;

export const GlobalAlert = styled(Banner).attrs({
  localStorageName: STORAGE_KEYS.HIDE_GLOBAL_ALERT
})`
  border: none;
  background: ${widgetHeaderTextColor};
  margin: -${contentPadding} -${contentPadding} 3px;
  padding: 3px 37px 3px 70px;

  .ant-alert-message {
    span {
      font-size: ${fontSize.xSmall};
      color: ${bodyBackground};
      strong {
        font-weight: 500;
      }
    }
    a {
      color: ${bodyBackground};
      text-decoration: underline;
      font-weight: 500;
    }
  }

  .ant-alert-close-icon {
    top: 2px;
    right: 30px;
    i {
      color: ${colors.aquaBlue3};
    }
  }
`;

export const WarningIcon = styled(WarningSvg).attrs({
  className: 'anticon'
})`
  ${iconMixins};
  ${mixins.size('18.33px')};
  fill: none;
  overflow: inherit !important;
`;
