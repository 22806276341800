// @flow

import React from 'react';
import { ArrowForward } from '@material-ui/icons';
import { useTranslate } from '@accordo-feed/language.entry';

import * as Styled from './viewMore.styled';
import lang from './viewMore.lang';

/*************
 *   TYPES   *
 *************/

type ViewMoreProps = {
  title?: string,
  path: string,
  disabled: boolean,
  theme?: 'light' | 'dark'
};

/*****************
 *   COMPONENT   *
 *****************/

const ViewMore = ({ title = null, path, disabled = false, theme = 'light' }: ViewMoreProps) => {
  const translate = useTranslate();

  return (
    <Styled.ViewMoreContainer className="at_viewMoreContainer" dark={theme === 'dark'} disabled={disabled}>
      <Styled.ViewMoreLink disabled={disabled} className="at_viewMoreLink" to={path}>
        {title ? title : translate(lang.viewMore)}
        <Styled.ForwardIcon element={ArrowForward} />
      </Styled.ViewMoreLink>
    </Styled.ViewMoreContainer>
  );
};

export default ViewMore;
