// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as selectors from 'src/redux/selectors';
import { getAzureDashboardData } from 'src/pages/azureDashboard/azureDashboard.duck';
import AzureDashboardComponent from './AzureDashboard.component';

/*****************
 *   COMPONENT   *
 *****************/

const AzureDashboard = props => {
  useEffect(() => {
    props.actions.getAzureDashboardData();
  }, []);

  return <AzureDashboardComponent {...props} />;
};

/*************
 *   REDUX   *
 *************/

const mapStateToProps = state => ({
  theme: selectors.appThemeSelector(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ getAzureDashboardData }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AzureDashboard);
