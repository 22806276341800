// @flow

import React from 'react';
import { useTranslate } from '@accordo-feed/language.entry';

import * as Styled from './filterTags.styled';
import { hasFiltersApplied } from './filterTags.utils';
import lang from './filterTags.lang';

/*************
 *   TYPES   *
 *************/

type Props = {
  selectedFilters: Object,
  filterTags: string[],
  onRemoveFilterTag: Function,
  onClearAll: Function
};

/*****************
 *   COMPONENT   *
 *****************/

export default ({ selectedFilters, filterTags, onRemoveFilterTag, onClearAll }: Props) => {
  const translate = useTranslate();
  const hasFilters = hasFiltersApplied(selectedFilters);

  return (
    <Styled.FiltersSection>
      {hasFilters && <label>{translate(lang.filtersLabel)}</label>}
      {filterTags.map(filterTag => (
        <Styled.FilterTag key={filterTag} onClose={() => onRemoveFilterTag(filterTag)}>
          {filterTag}
        </Styled.FilterTag>
      ))}
      {hasFilters && <Styled.ClearAllButton onClick={onClearAll}>{translate(lang.clearAll)}</Styled.ClearAllButton>}
    </Styled.FiltersSection>
  );
};
