// @flow

import * as R from 'ramda';
import React from 'react';
import { Formik, Form } from 'formik';
import { Row, Col } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useTranslate } from '@accordo-feed/language.entry';

import UIButton from 'src/components/UI/button';
import {
  Title,
  Description,
  ToggleSwitchContainer,
  ToggleSwitch,
  CustomTagInput,
  ErrMsg,
  FieldLabel,
  FieldIntro
} from 'src/CustomComponent.styled';
import { psaBillingAlertSchema } from 'src/pages/profile/profile.config';
import lang from './psaBillingAlert.lang';

/*************
 *   TYPES   *
 *************/

export type Actions = {
  addPsaBillingAlertEmail: Function,
  removePsaBillingAlertEmail: Function,
  setPsaBillingAlertStatus: Function,
  setAlertUpdated: Function,
  updatePsaBillingAlert: Function
};

type Props = {
  actions: Actions,
  billingAlert: Object,
  isAlertUpdated: boolean,
  isAlertUpdating: boolean
};

/*****************
 *   COMPONENT   *
 *****************/

const ErrorMessage = ({ errors, touched }) => {
  const path = ['psaBillingAlert', 'emailList'];

  let msg = R.pathOr('', path)(errors);
  if (R.is(Array, msg)) {
    for (let i = 0, l = msg.length; i < l; i++) {
      if (msg[i]) {
        // $FlowIgnore
        msg = msg[i].name;
        break;
      }
    }
  }

  const isShown = msg && R.path(path, touched);

  return isShown ? <ErrMsg noLeftGap>{msg}</ErrMsg> : null;
};

export default ({ actions, billingAlert, isAlertUpdated, isAlertUpdating }: Props) => {
  const translate = useTranslate();

  const renderForm = ({ errors, handleSubmit, setFieldValue, touched, values: { psaBillingAlert } }) => {
    const handleValueChange = (key, callback: ?Function) => value => {
      actions.setAlertUpdated(false);
      setFieldValue(key, value);

      if (callback) {
        callback(value);
      }
    };

    return (
      <Form>
        <ToggleSwitchContainer>
          <ToggleSwitch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={psaBillingAlert.isAlertOn}
            onChange={handleValueChange('psaBillingAlert.isAlertOn', actions.setPsaBillingAlertStatus)}
          />
        </ToggleSwitchContainer>
        <FieldLabel>{translate(lang.emailTitle)}</FieldLabel>
        <FieldIntro>{translate(lang.emailIntro)}</FieldIntro>
        <Row gutter={[24, 24]}>
          <Col xs={{ span: 24 }} lg={{ span: 16 }}>
            <CustomTagInput
              tags={psaBillingAlert.emailList}
              onAdd={actions.addPsaBillingAlertEmail}
              onChange={handleValueChange('psaBillingAlert.emailList')}
              onRemove={actions.removePsaBillingAlertEmail}
              placeholder={translate(lang.placeholder)}
            />
            <ErrorMessage errors={errors} touched={touched} />
            <UIButton
              variant="primary"
              onClick={handleSubmit}
              disabled={isAlertUpdated}
              loading={isAlertUpdating}
              margin="20px 0 0 0"
            >
              {translate(lang.saveChangesButton)}
            </UIButton>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <Row gutter={[24, 16]}>
      <Col xs={{ span: 24 }} lg={{ span: 6 }}>
        <Title>{translate(lang.title)}</Title>
        <Description>{translate(lang.switch)}</Description>
      </Col>

      <Col xs={{ span: 24 }} lg={{ span: 18 }}>
        <Formik
          enableReinitialize={true}
          validationSchema={psaBillingAlertSchema(translate)}
          initialValues={billingAlert}
          onSubmit={actions.updatePsaBillingAlert}
          children={renderForm}
        />
      </Col>
    </Row>
  );
};
