import * as R from 'ramda';

export const calculateFilterData = (list, selectedFilters) => {
  let minActiveUsers = Number.MAX_VALUE;
  let minTotalSpend = Number.MAX_VALUE;
  let maxActiveUsers = 0;
  let maxTotalSpend = 0;

  list.forEach(({ activeUsers, totalMonthlySpend }) => {
    const totalSpend = totalMonthlySpend;

    if (activeUsers < minActiveUsers) {
      minActiveUsers = activeUsers;
    }

    if (activeUsers > maxActiveUsers) {
      maxActiveUsers = activeUsers;
    }

    if (totalSpend < minTotalSpend) {
      minTotalSpend = totalSpend;
    }

    if (totalSpend > maxTotalSpend) {
      maxTotalSpend = totalSpend;
    }
  });

  const shouldShowActiveUsersFilter = R.not(R.equals(minActiveUsers, maxActiveUsers));
  const shouldShowTotalSpendFilter = R.not(R.equals(minTotalSpend, maxTotalSpend));

  minTotalSpend = Math.floor(minTotalSpend);
  maxTotalSpend = Math.ceil(maxTotalSpend);

  return {
    shouldShowActiveUsersFilter,
    shouldShowTotalSpendFilter,
    minActiveUsers,
    maxActiveUsers,
    minTotalSpend,
    maxTotalSpend,
    selectedMinActiveUsers: selectedFilters.minActiveUsers || minActiveUsers,
    selectedMaxActiveUsers: selectedFilters.maxActiveUsers || maxActiveUsers,
    selectedMinTotalSpend: selectedFilters.minTotalSpend || minTotalSpend,
    selectedMaxTotalSpend: selectedFilters.maxTotalSpend || maxTotalSpend,
    ...R.pick(['recommendations'], selectedFilters)
  };
};
