// https://bitbucket.org/accordogroup/partner-center-service/src/af83e8242f8b4a1bd12ada5b8be385c8df8d8011/src/domains/partnerCenter/authStates.js?at=master#lines-1:6
export const PARTNER_CENTER_AUTH_STATES = {
  NEW: 'new',
  ACCESS_GRANTED: 'accessGranted',
  INVALID_TOKEN: 'invalidToken',
  DISCONNECTED: 'disconnected'
};

// https://bitbucket.org/accordogroup/partner-center-service/src/af83e8242f8b4a1bd12ada5b8be385c8df8d8011/src/domains/partnerCenter/PartnerEntity.js?at=master#lines-7:10
export const PARTNER_CENTER_CONNECT_TYPE = {
  PARTNER_AMDIN: 'partnerAdmin',
  APP_CONNECT: 'appConnect'
};
