// @flow

import React from 'react';
import { bindActionCreators } from 'redux';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import * as Styled from './ctaLayout.styled';
import * as selectors from 'src/redux/selectors';
import Search from 'src/components/search';
import Slider from 'src/components/slider';
import lang from './filters.lang';
import { formatCurrencyHOC } from 'src/hoc';
import { onSearchChange } from 'src/components/search/seach.util';
import { setSearchTerm } from 'src/redux/modules/searchTerm/searchTerm.duck';

/*************
 *   TYPES   *
 *************/

type Props = {
  actions: {
    setSearchTerm: Function
  },
  searchTerm: string,
  formatCurrency: Function,
  filterData: Object,
  originalDataSource: Array<Object>,
  activeUserSliderProps: Object,
  totalSpendSliderProps: Object
};

/*****************
 *   COMPONENT   *
 *****************/

const Filters = ({
  searchTerm,
  formatCurrency,
  filterData,
  originalDataSource,
  activeUserSliderProps,
  totalSpendSliderProps,
  actions
}: Props) => {
  const activeUserSilderComponentProps = {
    titleLang: lang.filterTitle1,
    isFilterShowed: filterData.shouldShowActiveUsersFilter,
    minLabel: filterData.minActiveUsers,
    maxLabel: filterData.maxActiveUsers,
    sliderProps: activeUserSliderProps
  };
  const totalSpendSliderComponentProps = {
    titleLang: lang.filterTitle2,
    tooltipLang: lang.tooltip1,
    isFilterShowed: filterData.shouldShowTotalSpendFilter,
    minLabel: formatCurrency(filterData.minTotalSpend),
    maxLabel: formatCurrency(filterData.maxTotalSpend),
    sliderProps: totalSpendSliderProps
  };

  return (
    <Styled.ActionPanel>
      {originalDataSource.length > 1 && (
        <Styled.FiltersContainer>
          <Slider {...activeUserSilderComponentProps} />
          <Slider {...totalSpendSliderComponentProps} />
        </Styled.FiltersContainer>
      )}

      <Search searchTerm={searchTerm} onChange={onSearchChange(actions.setSearchTerm)} />
    </Styled.ActionPanel>
  );
};

/*************
 *   REDUX   *
 *************/

const mapStateToProps = state => ({
  searchTerm: selectors.searchTermSelector(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ setSearchTerm }, dispatch)
});

export default compose(connect(mapStateToProps, mapDispatchToProps), formatCurrencyHOC)(Filters);
