import React, { useState } from 'react';
import { message } from 'antd';
import { useTranslate } from '@accordo-feed/language.entry';

import { Field, Input, ErrorInfo } from './paymentInfo.styled';
import { billingEmailSchema } from './billingEmail.config';
import { isStatus } from 'src/utils';
import { paymentInfo as paymentInfoLang } from './billing.lang';
import { useInput } from 'src/hooks';
import UIButton from 'src/components/UI/button';

/*****************
 *   COMPONENT   *
 *****************/

export const BillingEmail = ({ orgId, email, updateBillingEmail, updateBillingEmailLoadingStatus }) => {
  const translate = useTranslate();
  const [value, setValue] = useInput(email);
  const [error, setError] = useState(null);
  const isLoading = isStatus.loading(updateBillingEmailLoadingStatus);

  const onEmailChange = async e => {
    setValue(e);

    const isValid = await billingEmailSchema.isValid({
      email: e.currentTarget.value
    });

    setError(!isValid);
  };

  const onUpdateEmailButtonClick = () => {
    updateBillingEmail({
      orgId,
      email: value,
      whenSuccessful: () => {
        message.success(translate(paymentInfoLang.paymentInfo.updateBillingEmail.success));
      },
      whenFailed: () => {
        message.error(translate(paymentInfoLang.paymentInfo.updateBillingEmail.error));
      }
    });
  };

  return (
    <>
      <Field labelText={translate(paymentInfoLang.paymentInfo.emailLabel)}>
        <Input disabled={isLoading} value={value} onChange={onEmailChange} type="email" />
        <UIButton
          disabled={error}
          loading={isLoading}
          onClick={onUpdateEmailButtonClick}
          variant="outline"
          margin="0 0 0 15px"
        >
          {translate(paymentInfoLang.paymentInfo.saveButton)}
        </UIButton>
      </Field>
      {error && <ErrorInfo>{translate(paymentInfoLang.paymentInfo.invalidEmail)}</ErrorInfo>}
    </>
  );
};
