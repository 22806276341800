// @flow

import { compose, mapProps } from 'recompose';
import { router } from '@accordo-feed/micro-frontends';

import Drawer from '../drawer';

export default compose(
  router.withRouterHOC,

  mapProps(({ route }) => ({
    route,
    hasDrawer: false
  }))
)(Drawer);
