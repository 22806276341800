// @flow

import React, { useState } from 'react';
import { Row, message } from 'antd';
import { injectStripe } from 'react-stripe-elements';
import { useTranslate } from '@accordo-feed/language.entry';
import { ButtonElement } from '@accordo-feed/aco-styled-components';

import { PrivacyLink } from 'src/components/appFooter/AppFooter.component';
import { StripeCardFields, StripeProvider } from 'src/components/stripe';
import { STRIPE_PLANS } from 'src/constants/index';
import { useModalProps } from 'src/hooks';
import { paymentInfo as lang } from 'src/pages/billing/billing.lang';
import * as Styled from 'src/pages/paymentDetails/cardForm.styled';
import { Title } from 'src/pages/paymentDetails/paymentDetails.styled';
import CustomModal from 'src/components/modal';
import { cardForm as cardFormLang } from 'src/pages/paymentDetails/paymentDetails.lang';
import { paymentInfo as paymentInfoLang } from './billing.lang';
import { CardNumber } from './paymentInfo.styled';

/*************
 *   TYPES   *
 *************/

type Props = {
  orgId: string,
  updatePaymentMethod: Function,
  lastFourDigitsCc: string
};

/*****************
 *   COMPONENTS   *
 *****************/

const CardButtons = injectStripe(({ stripe, closeModal, orgId, updatePaymentMethod }) => {
  const translate = useTranslate();
  const [isLoading, setIsLoading] = useState(false);

  const onCardButtonClick = async () => {
    const { token, error } = await stripe.createToken();
    if (error) {
      message.error(translate(lang.updateCard.httpError.creditCardValidError));
      return;
    }

    setIsLoading(true);

    updatePaymentMethod({
      orgId,
      planIds: [STRIPE_PLANS.OFFICE365],
      cardToken: token.id,
      whenSuccessful: () => {
        setIsLoading(false);
        closeModal();
      },
      whenFailed: () => {
        setIsLoading(false);
        message.error(translate(lang.updateCard.httpError.updateCardError));
      }
    });
  };

  return (
    <Row type="flex" justify="end" align="middle">
      <ButtonElement variant="outline" onClick={closeModal}>
        {translate(paymentInfoLang.updateCard.cancelButton)}
      </ButtonElement>
      <ButtonElement variant="primary" margin="0 0 0 15px" onClick={onCardButtonClick} loading={isLoading}>
        {translate(paymentInfoLang.updateCard.saveButton)}
      </ButtonElement>
    </Row>
  );
});

export const CardInfo = ({ lastFourDigitsCc, updatePaymentMethod, orgId }: Props) => {
  const translate = useTranslate();
  const { isModalOpen, openModal, closeModal } = useModalProps();

  return (
    <Row type="flex" align="middle">
      <CardNumber>{`●●●●●●${lastFourDigitsCc || ''}`}</CardNumber>
      <ButtonElement variant="outline" margin="0 0 0 15px" onClick={openModal} secondary="true">
        {translate(paymentInfoLang.paymentInfo.updateButton)}
      </ButtonElement>

      <CustomModal
        title={translate(paymentInfoLang.updateCard.heading)}
        subTitle={
          <Row type="flex" align="middle" padding="0 0 5px">
            <Title size="s">{translate(cardFormLang.formSubHeader)}</Title>
            <Styled.LockIcon />
            <Styled.StripeLink href="https://stripe.com" target="_blank">
              {translate(cardFormLang.stripeLink)}
            </Styled.StripeLink>
          </Row>
        }
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        customButtons={true}
        centered
      >
        <StripeProvider>
          <>
            <StripeCardFields />
            <PrivacyLink margin="10px 0 32px 0" display="block" />
            <CardButtons closeModal={closeModal} orgId={orgId} updatePaymentMethod={updatePaymentMethod} />
          </>
        </StripeProvider>
      </CustomModal>
    </Row>
  );
};
