import React from 'react';
import { connect } from 'react-redux';
import * as selectors from 'src/redux/selectors';
import { setAppTheme } from 'src/redux/modules/appStatus/appStatus.duck';
import { ToggleLabel, ToggleSwitch, ToggleThemeWrapper } from './ToggleTheme.styled';
import { APP_THEME } from 'src/constants';

const ToggleTheme = ({ theme, setAppTheme }) => {
  const handleToggle = e => {
    setAppTheme(e ? APP_THEME.DARK : APP_THEME.LIGHT);
  };
  return (
    <ToggleThemeWrapper>
      <ToggleSwitch size="small" checked={theme === 'dark'} onChange={handleToggle} />
      <ToggleLabel>{`${theme} mode`}</ToggleLabel>
    </ToggleThemeWrapper>
  );
};

const mapStateToProps = state => ({
  theme: selectors.appThemeSelector(state)
});

const mapDispatchToProps = {
  setAppTheme
};

export default connect(mapStateToProps, mapDispatchToProps)(ToggleTheme);
