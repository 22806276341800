// @flow

import { takeLatest, put, all, fork, select } from 'redux-saga/effects';

import * as actions from './securityScorePage.duck';
import * as apiCalls from 'src/pages/dashboard/dashboard.api';
import { transform, genFilterResultQuery } from './securityScore.utils';
import { orgIdSelector } from 'src/redux/selectors';
import { selectedFiltersSelector } from './filterPanel/filterPanel.selector';

/*************
 *   SAGAS   *
 *************/

function* getSecurityPageDataSaga({ payload }): any {
  yield put(actions.setLoadingState(true));

  try {
    const data = yield apiCalls.getSecurityData(payload);
    yield put(actions.getSecurityPageSuccess(transform(data)));
  } catch (err) {
    yield put(actions.setLoadingState(false));
  }
}

function* getFilteredSecurityPageDataSaga(): any {
  yield put(actions.setLoadingState(true));
  const partnerId = yield select(orgIdSelector);
  const selectedFilters = yield select(selectedFiltersSelector);
  const query = genFilterResultQuery(partnerId, selectedFilters);

  try {
    const data = yield apiCalls.getSecurityData(query);
    yield put(actions.getFilteredSecurityPageDataSuccess(transform(data)));
  } catch (err) {
    yield put(actions.setLoadingState(false));
  }
}

export default function*(): any {
  yield all([
    fork(takeLatest, actions.getSecurityPageData, getSecurityPageDataSaga),
    fork(takeLatest, actions.getFilteredSecurityPageData, getFilteredSecurityPageDataSaga)
  ]);
}
