import * as yup from 'yup';

import lang from './step1.lang';
import { FORM_KEYS } from './step1.constants';

const errLang = lang.err;

const guidRe = /^(\w){8}-(\w){4}-(\w){4}-(\w){4}-(\w){12}$/;

export default translate => {
  const required = langKey => yup.string().required(translate(errLang[langKey]));

  return yup.object().shape({
    [FORM_KEYS.APP_ID]: required(`${FORM_KEYS.APP_ID}Required`).matches(guidRe, {
      message: translate(errLang[`${FORM_KEYS.APP_ID}Invalid`])
    }),
    [FORM_KEYS.APP_SECRET]: required(`${FORM_KEYS.APP_SECRET}Required`),
    [FORM_KEYS.TENANT_ID]: required(`${FORM_KEYS.TENANT_ID}Required`).matches(guidRe, {
      message: translate(errLang[`${FORM_KEYS.TENANT_ID}Invalid`])
    })
  });
};
