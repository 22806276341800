import React, { useEffect } from 'react';

import { CurrentSubscription } from './CurrentSubscription.component';
import { SubscriptionUsage } from './SubscriptionUsage.component';
import * as Styled from './subscriptions.styled';

/*****************
 *   COMPONENT   *
 *****************/

export default ({
  office365CancelTime,
  willOffice365BeCancelled,
  cancelSubscriptionLoadingStatus,
  subscriptions,
  loadingStatus,
  actions,
  orgId
}: Props) => {
  useEffect(() => {
    actions.getSubscriptionUsage({ orgId });
  }, []);

  return (
    <Styled.CurrentSubscriptionWrapper>
      {Object.values(subscriptions).map(({ usage, tiers }, index) => (
        <SubscriptionUsage key={index} totalUsage={usage} tiers={tiers} />
      ))}

      <CurrentSubscription
        orgId={orgId}
        office365CancelTime={office365CancelTime}
        willOffice365BeCancelled={willOffice365BeCancelled}
        cancelSubscription={actions.cancelSubscription}
        cancelSubscriptionLoadingStatus={cancelSubscriptionLoadingStatus}
        subscriptions={subscriptions}
      />
    </Styled.CurrentSubscriptionWrapper>
  );
};
