// @flow

import { connect } from 'react-redux';

import { updateBillingAddress, updateBillingAddressLoaded } from 'src/redux/modules/payment/payment.duck';
import * as selectors from 'src/redux/selectors';
import { BillingAddress } from './BillingAddress.component';
import type { LoadingStatusType } from 'src/constants';

/*************
 *   TYPES   *
 *************/

type Props = {
  orgId: string,
  billingAddress: string,
  updateBillingAddressLoadingStatus: LoadingStatusType
};

/*************
 *   REDUX   *
 *************/

const mapStateToProps = (state: Props) => ({
  orgId: selectors.orgIdSelector(state),
  billingAddress: selectors.organizationBillingAddressSelector(state),
  updateBillingAddressLoadingStatus: selectors.updateBillingAddressLoadingStatusSelector(state)
});

const mapDispatchToProps = {
  updateBillingAddress,
  updateBillingAddressLoaded
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingAddress);
