import * as React from 'react';
import { Spin } from 'antd';
import * as R from 'ramda';

import { LOADING_STATUS } from 'src/constants';

/***************
 *   HELPERS   *
 ***************/

const isLoading = R.ifElse(R.is(Boolean), R.identity, R.equals(LOADING_STATUS.LOADING));

/*****************
 *   COMPONENT   *
 *****************/

export const LoadingBlock = ({ loadingStatus, children }) => (
  <Spin size="large" spinning={isLoading(loadingStatus)} style={{ top: '20%' }}>
    {children}
  </Spin>
);
