// @flow

import * as R from 'ramda';
import { command } from '@accordo-feed/micro-frontends';
import { core } from '@accordo-feed/micro-frontends-utils';

const genNamespaces = (submodules: []): {} =>
  submodules.reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: `sub_${core.snakeToCamel(value)}`
    }),
    {}
  );

export const DEFAULT_LANGUAGE = 'en-us';
export const DEFAULT_COUNTRY_CODE = 'us';
export const SHELL_NAME = 'cloud-optimizer';

export const SUBMODULES = {
  AZURE_OPTIMIZER: 'azure-optimizer',
  OFFICE_365_OPTIMIZER: 'consumption',
  PSA: 'psa',
  TEAM_INVITES: 'team-invites'
};

export const REDUCER_NAMESPACES = {
  // $FlowIgnore
  ...genNamespaces(R.toPairs(SUBMODULES)),
  SHELL: 'shell'
};

export const PATHS = {
  HOME: '/',
  AUTH_ERROR: '/auth-error',
  INVITE_ERROR: '/invite-error',
  LOGIN: '/login',
  LOGOUT: '/logout',
  SIGNUP: '/signup',
  ADD_CONNECTION: '/add-connection',
  AZURE_OPTIMIZER: {
    ROOT: `/${SUBMODULES.AZURE_OPTIMIZER}/`
  },
  AZURE_DASHBOARD: {
    ROOT: `/azure-dashboard/`
  },
  OFFICE_365_OPTIMIZER: {
    ROOT: `/${SUBMODULES.OFFICE_365_OPTIMIZER}/:id`,
    DASHBOARD: `/${SUBMODULES.OFFICE_365_OPTIMIZER}/{{ orgId }}/`,
    RECOMMENDATIONS: `/${SUBMODULES.OFFICE_365_OPTIMIZER}/{{ orgId }}/recommendations`,
    USERS: `/${SUBMODULES.OFFICE_365_OPTIMIZER}/{{ orgId }}/users`,
    PLANS: `/${SUBMODULES.OFFICE_365_OPTIMIZER}/{{ orgId }}/plans`,
    DEPARTMENT_SPEND: `/${SUBMODULES.OFFICE_365_OPTIMIZER}/{{ orgId }}/users/department-spend`
  },
  PSA: {
    ROOT: `/${SUBMODULES.PSA}/`,
    PRODUCT_MAPPING: `/${SUBMODULES.PSA}/product-mapping`,
    CUSTOMER_MAPPING: `/${SUBMODULES.PSA}/customer-mapping`
  },
  TEAM_INVITES: {
    ROOT: '/team-management/'
  },
  ACCOUNTS: {
    ROOT: '/accounts',
    BILLING: '/accounts/billing',
    PROFILE: '/accounts/profile',
    NOTIFICATIONS_SETTINGS: '/accounts/notifications-settings'
  },
  AUTH_CALLBACK: '/callback',
  CUSTOMERS: {
    ROOT: '/companies',
    ERROR: '/companies/error/:errorCode',
    PROFILE: '/companies/:id',
    LICENSE_ASSIGNMENT: '/companies/:id/licenseAssignments',
    AZURE_RECOMMENDATIONS: '/companies/:id/azure-recommendations'
  },
  DASHBOARD: {
    ROOT: '/dashboard',
    UNASSIGNED_LICENSES: '/dashboard/unassigned-licenses',
    OPPORTUNITIES: '/dashboard/opportunities',
    GROWTH_TREND: '/dashboard/customer-growth'
  },
  LICENSE_PRICING: '/license-pricing',
  ALLOCATION_ADOPTION_DASHBOARD: '/allocation-adoption-dashboard',
  PARTNER_CENTER_WELCOME: '/partner-center-welcome',
  PAYMENT_DETAILS: '/payment-details',
  PLAN_OVERVIEW: '/plan-overview',
  PRODUCT_CATALOG: '/product-catalog',
  SECURITY_SCORE: {
    ROOT: '/security-score',
    CLIENT: '/security-score/:clientId',
    CLIENT_DETAILS: '/security-score/:clientId/details'
  },
  SECURE_APP: '/secure-app'
};

export const NON_AUTH_PATHS = [
  PATHS.HOME,
  PATHS.AUTH_ERROR,
  PATHS.INVITE_ERROR,
  PATHS.LOGIN,
  PATHS.LOGOUT,
  PATHS.SIGNUP
];

export const EXTERNAL_LINKS = {
  ONLINE_HELP_DOC_LINK: 'https://docs.entori.com/en/',
  PARTNER_CENTER_SUBSCRIPTIONS: 'https://partner.microsoft.com/commerce/customers/{{ tenantId }}/subscriptions'
};

export const STORAGE_KEYS = {
  LOCALE: 'locale',
  USER_TOKEN: 'userToken',
  USER_TOKEN_EXPIRES_AT: 'userTokenExpiresAt',
  LANGUAGE_CHOICE: 'language_choice',
  HIDE_COOKIE_BANNER: 'hideCookieBanner',
  HIDE_SECURITY_BANNER: 'hideSecurityBanner',
  HIDE_GLOBAL_ALERT: 'hideGlobalAlert',
  SKIP_PARTNER_CENTER: 'skipPartnerCenter',
  HAS_VIEWED_WELCOME_PAGE: 'hasViewedWelcomePage',
  HIDE_SECURITY_SCORE_RECOMMENDATION_TOOLTIP: 'hideSecurityScoreRecommendationTooltip'
};

export const AUTH_ACTIONS = {
  LOGIN: 'login',
  LOGOUT: 'logout',
  SIGNUP: 'signUp'
};

export const DEFAULT_CURRENCY_OPTIONS = {
  symbol: '$',
  decimal: '.',
  separator: ',',
  precision: 2
};

export const MF_COMMAND_EVENTS = {
  ...command.EVENTS
};

export const STRIPE_PLANS = {
  OFFICE365: 'office365Basic'
};

export const APP_VIEWS = {
  OFFICE_365_OPTIMIZER: 'OFFICE_365_OPTIMIZER',
  SHELL: 'SHELL'
};

export const APP_THEME = {
  LIGHT: 'light',
  DARK: 'dark'
};

export const API_ERROR_ACTION_TYPES = {
  SHOW_ERROR_MESSAGE: 'SHOW_ERROR_MESSAGE',
  SHOW_TOASTER: 'SHOW_TOASTER',
  LOG_OUT: 'LOG_OUT'
};

export const ERROR_CODES = {
  LINKING_PERMISSION_DENIED: 162003
};

export const LOADING_STATUS = {
  INITIAL: 'INITIAL',
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  FAILED: 'FAILED'
};

export type LoadingStatusType = $Keys<typeof LOADING_STATUS>;

export const EMPTY_DATE_PLACEHOLDER = '-';

export const TIME_FORMATS = {
  TILL_NOW: 'TILL_NOW',
  SHORT: 'DD MMM YYYY',
  ISO_DATE: 'YYYY-MM-DD',
  DAY_MONTH: 'DD/MMM',
  MONTH_DAY_YEAR: 'MM/DD/YY'
};

export const PROMOTION_CODE = 'OCTINCENTIVE';

export const PSA_CONNECTION_STATUS = {
  AVAILABLE: 'available',
  ACTIVE: 'active',
  ERROR: 'error',
  NOT_FOUND: 'notFound',
  UNAUTHORIZED: 'unauthorized',
  LOCKED_USER: 'lockedUser',
  MISSING_PERMISSINGS: 'missingPermissions'
};

export const DOWNLOAD_STATIC_REPORT_TYPE = {
  BILLING_RECONCILE: 'billingReconcile'
};

export const INVITE_ERRORS = {
  INVALID_INVITE: 101038,
  EXPIRED_INVITE: 101039
};

export const COMMAND_NAVIGATE_TYPE = {
  REPLACE: 'replace'
};

export const SEGMENT_EVENTS = {
  PRICING_PAGE_START_TRIAL: 'PricingPage_StartTrial',
  CC_PAGE_START_TRIAL: 'CreditCardPage_StartTrial',
  WELCOME_PAGE_MANUAL: 'WelcomePage_Manual',
  WELCOME_PAGE_SKIP: 'WelcomePage_Skip',
  WELCOME_PAGE_SETUP_SECURE_APP: 'WelcomePage_SetupSecureApp',
  WELCOME_PAGE_IMPORT_CLIENT_LIST: 'WelcomePage_ImportClientList',
  SETUP_PS_NEXT: 'SetupPowerShell_Next',
  CONNECT_PC_CONNECT: 'ConnectPartnerCenter_connect'
};

export const USER_ROLES = {
  PARTNER_ADMIN: 'partner_admin',
  PARTNER_MEMBER: 'partner_member'
};

export const STORAGE_UNITS = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
export const BYTE_CONVERT_RATE = {
  B: 1,
  KB: Math.pow(1024, 1),
  MB: Math.pow(1024, 2),
  GB: Math.pow(1024, 3),
  TB: Math.pow(1024, 4),
  PB: Math.pow(1024, 5)
};

export const CUSTOMER_TYPES = ['Customer', 'Prospect', 'MSP'];

export const CUSTOMER_CONNECTION_STATUS = ['Active', 'Inactive', 'Auth Error'];

export const AZURE_TRACK_EVENTS = {
  AZUREOPTIMIZER_RECOMMENDATIONS_TAB_ALL: 'azureoptimizer_recommendations_tab_all',
  AZUREOPTIMIZER_RECOMMENDATIONS_TAB_COST: 'azureoptimizer_recommendations_tab_cost',
  AZUREOPTIMIZER_RECOMMENDATIONS_TAB_SECURITY: 'azureoptimizer_recommendations_tab_security',
  AZUREOPTIMIZER_RECOMMENDATIONS_TAB_PERFORMANCE: 'azureoptimizer_recommendations_tab_performance',
  AZUREOPTIMIZER_RECOMMENDATIONS_TAB_AVAILABILITY: 'azureoptimizer_recommendations_tab_availability'
};

export const CLICK_TRACK_CLASS = 'track-click-event';

export const RECOM_SECTIONS = {
  ALL: 'all',
  COST: 'cost',
  SECURITY: 'security',
  PERFORMANCE: 'performance',
  AVAILABILITY: 'availability'
};

export const RECOMMENDATIONS_ACTION_TYPES = {
  SWITCH_PLAN: 'switch_plan',
  STOP_RENEWING: 'stop_renewing',
  REASSIGNABLE: 'reassignable',
  CONSULT_TEAMS: 'consult_teams',
  ONEDRIVE_DATA_FLUCTUATION: 'onedrive_data_fluctuation',
  ONEDRIVE_FILE_FLUCTUATION: 'onedrive_file_fluctuation',
  OPTIMIZE_TEAMS: 'optimize_teams',
  OPTIMIZE_CLOUD: 'optimize_cloud',
  MFA_REGISTRATION: 'mfa_registration',
  MFA_CAPABLE: 'mfa_capable',
  SSPR_ENABLED: 'sspr_enabled',
  SSPR_REGISTRATION: 'sspr_registration',
  MISASSIGNED_OFFBOARDED: 'misassigned_offboarded',
  STORAGE_ONEDRIVE_DATA_FLUCTUATION: 'storage_onedrive_data_fluctuation',
  STORAGE_ONEDRIVE_FILE_FLUCTUATION: 'storage_onedrive_file_fluctuation'
};

export const IMPACT_LEVELS = {
  HIGH: 'high',
  MEDIUM: 'medium',
  LOW: 'low'
};

export const M365LICENSES_OPTIONS = ['Total Licenses', 'Annual Spend', 'Assigned', 'Unassigned'];

export const M365LICENSES_OPTION_VALUE = {
  'Total Licenses': 'purchased',
  'Annual Spend': 'annualSpend',
  Assigned: 'assigned',
  Unassigned: 'unassigned'
};
