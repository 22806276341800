import React from 'react';

import * as Styled from './completeModal.styled';

export const CompleteModal = ({ ...props }) => (
  <Styled.CompleteModal footer={null} {...props}>
    <Styled.CircleCheckIcon />
    {props.children}
  </Styled.CompleteModal>
);
