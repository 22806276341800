import styled from 'styled-components';
import { constants } from '@accordo-feed/aco-styled-components';

const { colors } = constants;

export const Container = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;
  background-color: ${colors.blueHighlight};
`;
