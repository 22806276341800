import ReactECharts from 'echarts-for-react';
import styled from 'styled-components';
import { mixins, constants, Icon } from '@accordo-feed/aco-styled-components';

import { appTextColor, bodyBackground } from 'src/App.styled';

const { fontSize } = constants;

export const Chart = styled(ReactECharts).attrs({
  style: {
    height: 200,
    width: 270
  },
  theme: 'royal'
})``;

export const RootWrapper = styled.div`
  width: 100%;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 15px;
`;

export const SectionTitle = styled.span`
  font-weight: normal;
  font-size: 10px;
  line-height: 18px;
  text-align: right;
  color: ${appTextColor};
`;

export const SectionTitleWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-left: 50px;
`;

export const ScoreChartWrap = styled.div`
  width: 289px;
  margin: 0 auto;
  display: inline-block;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 7px;
  padding-left: 15px;
  line-height: 34px;
  color: ${appTextColor};
  font-size: ${fontSize.medium};

  svg {
    height: 24px;
    width: 22px;
  }

  span {
    margin-left: 7px;
  }
`;

export const DemoButton = styled.div`
  ${mixins.flexVerticalCenter};
  height: 27px;
  min-width: max-content;
  cursor: pointer;
  padding: 0 12px;
  margin-right: 10px;
  border: 1.5px solid ${appTextColor};
  box-sizing: border-box;
  border-radius: 21px;
  background: transparent;
`;

export const DemoButtonText = styled.span`
  font-size: 10px;
  line-height: 15px;
  color: ${appTextColor};
  margin-left: 4px;
`;

export const GraphIcon = styled(Icon).attrs({
  color: appTextColor,
  size: '16px'
})``;

export const InfoBoxWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

export const InfoBox = styled.div`
  background-color: ${bodyBackground};
  color: ${appTextColor};
  font-size: ${fontSize.xSmall};
  line-height: 22px;
  padding: 20px;
  border-radius: 5px;
`;

export const InfoText = styled.span`
  display: block;
`;

export const ButtonsContainer = styled.div`
  grid-column: 1 / span 2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: -15px;
`;
