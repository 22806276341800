import styled, { css } from 'styled-components';
import { Layout } from 'antd';
import { stripUnit } from 'polished';
import { constants } from '@accordo-feed/aco-styled-components';

import { bodyBackground, scrollbarBackground, scrollbarHandlerColor } from 'src/App.styled';
import { siderWidth } from 'src/components/appSider/appSider.styled';
import { width } from 'src/pages/customers/profile/customerprofile.styled';

const { zIndex } = constants;

export const contentPadding = '10px';

export const AppWrapper = styled(Layout)`
  min-height: 100vh;
`;

export const BodyWrapper = styled(Layout)`
  margin-top: 0;
`;

export const ContentWrapper = styled(Layout)`
  margin-left: ${siderWidth};
  transition: margin-left 0.2s ease;
  overflow-x: auto !important;
  min-width: ${60 + stripUnit(width)}px;
  background-color: ${bodyBackground};

  .aco-main-content{
    max-width: 2400px;
    min-width: 350px;
    width: 100%;
    margin: 0 auto;
    height: calc(100vh - 30px - 43px - 45px);
    overflow: auto;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      background-color: ${scrollbarBackground};
    }

    &::-webkit-scrollbar-track {
      background-color: ${scrollbarBackground};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${scrollbarHandlerColor};
    }

    &::-webkit-scrollbar-corner {
      background: ${scrollbarHandlerColor};
    }
  }

  &.expand {
    margin-left: 68px;
  }

  ${
    ''
    /*
      Know more reason of using lowercase letters, please refer to:
      https://bitbucket.org/accordogroup/cloud-optimizer-frontend/pull-requests/789/ait2-5819-fix-solve-consumption-crash-by/diff#comment-138284420
    */
  }
  ${({ nominwidth }) =>
    JSON.parse(nominwidth) &&
    css`
      min-width: 0;
    `}

  ${({ nosider }) =>
    JSON.parse(nosider) &&
    css`
      margin-left: 0 !important;
    `}
`;

export const GreyedOutMask = styled.div.attrs({
  className: 'ant-modal-mask'
})`
  z-index: ${zIndex + 3};
`;
