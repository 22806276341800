// @flow

import React from 'react';
import { Spin } from 'antd';
import { useTranslate } from '@accordo-feed/language.entry';
import * as Styled from './widgetWrapper.styled';
import lang from './widgetWrapper.lang';

/*****************
 *   COMPONENT   *
 *****************/

const LoadingData = () => {
  const translate = useTranslate();
  return (
    <Styled.SpinContainer>
      <Spin tip={translate(lang.loadingData)} />
    </Styled.SpinContainer>
  );
};

export default LoadingData;
