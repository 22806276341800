export const ADVANCED_FILTER_KEYS = {
  CURRENT_SCORE: 'currentScore',
  PERCENTAGE: 'percentage',
  IMPROVEMENT_ACTION: 'improvementAction',
  STATUS: 'status',
  CONTROL_CATEGORY: 'controlCategory',
  USER_IMPACT: 'userImpact'
};

export const ADVANCED_FILTER_TEXT_MAP = {
  [ADVANCED_FILTER_KEYS.CURRENT_SCORE]: 'Current Secure Score',
  [ADVANCED_FILTER_KEYS.PERCENTAGE]: 'Secure Score Index',
  [ADVANCED_FILTER_KEYS.IMPROVEMENT_ACTION]: 'Improvement Actions',
  [ADVANCED_FILTER_KEYS.STATUS]: 'Status',
  [ADVANCED_FILTER_KEYS.CONTROL_CATEGORY]: 'Category',
  [ADVANCED_FILTER_KEYS.USER_IMPACT]: 'User Impact'
};

export const ES_QUERY_CONFIG = {
  SIZE: 10000,
  FIELDS: ['id', 'clientName', 'currentScore', 'maxScore', 'percentage', 'improvementActionCount'],
  SORT: [{ percentage: 'asc' }, { 'clientName.keyword': 'asc' }]
};
