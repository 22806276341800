import { langHelper } from '@accordo-feed/micro-frontends-utils';

const genId = langHelper.genLangId('shell.aco.securityScore.page.filterPanel');
const genOpts = langHelper.genLangOptions(genId);

export default {
  panelTitle: genOpts('title.filter', 'Filter'),
  hideButton: genOpts('hideButton', 'Hide'),
  showButton: genOpts('showButton', 'Show Filter'),
  currentScore: genOpts('filter.title1', 'Fiter by: Microsoft Secure Score current'),
  scoreIndex: genOpts('filter.title2', 'Filter by: Secure Score Index'),
  scoreIndexTooltip: genOpts('filter.tooltip1', 'Filter by: Secure Score Index'),
  improvementActionTitle: genOpts('improvementActionTitle', 'Improvement actions'),
  improvementActionPlaceholder: genOpts('improvementActionPlaceholder', 'Select improvement action'),
  statusTitle: genOpts('statusTitle', 'Status'),
  statusOpt1: genOpts('statusOpt1', 'Not Completed'),
  statusOpt2: genOpts('statusOpt2', 'Completed'),
  statusOpt3: genOpts('statusOpt3', 'Resolved by the third party'),
  statusOpt4: genOpts('statusOpt4', 'Ignored'),
  categoryTitle: genOpts('categoryTitle', 'Category'),
  categoryOpt1: genOpts('categoryOpt1', 'Identity'),
  categoryOpt2: genOpts('categoryOpt2', 'Data'),
  categoryOpt3: genOpts('categoryOpt3', 'Device'),
  categoryOpt4: genOpts('categoryOpt4', 'App'),
  categoryOpt5: genOpts('categoryOpt5', 'Infrastructure'),
  userImpactTitle: genOpts('userImpactTitle', 'User Impact'),
  userImpactOpt1: genOpts('userImpactOpt1', 'High'),
  userImpactOpt2: genOpts('userImpactOpt2', 'Moderate'),
  userImpactOpt3: genOpts('userImpactOpt3', 'Low')
};
