// @flow

import React from 'react';
import { LeftOutlined } from '@ant-design/icons';
import { Steps } from 'antd';
import { useTranslate } from '@accordo-feed/language.entry';

import * as Styled from './leftSide.styled';
import LangWrapper from 'src/components/langWrapper';
import lang from './leftSide.lang';
import { COLLAPSE_PANEL_STAY_ACTIVE_KEY } from './leftSide.constants';
import { useWindowSize } from 'src/hooks';

const { Step } = Steps;

/*************
 *   TYPES   *
 *************/

type Props = {
  currentStep: number,
  goBack: Function
};

/*****************
 *   COMPONENT   *
 *****************/

export default ({ currentStep, goBack }: Props) => {
  const translate = useTranslate();
  const { height } = useWindowSize();

  const getCollapsePanels = panels => {
    const isCollapseExpandable = panels.length !== 1;
    return panels.map((panel, index) => (
      <Styled.CollapsePanel
        header={panel.title}
        // NOTE: style will be overwrite if collpase is not expandable and then the panel automatically expand in default
        key={isCollapseExpandable ? index : COLLAPSE_PANEL_STAY_ACTIVE_KEY}
        showArrow={isCollapseExpandable}
      >
        <LangWrapper>{translate(panel.firstDesc)}</LangWrapper>
        {panel.image && <Styled.PanelImage src={panel.image} />}
        {panel.secondDesc && <LangWrapper>{translate(panel.secondDesc)}</LangWrapper>}
      </Styled.CollapsePanel>
    ));
  };

  return (
    <Styled.Container>
      <Styled.Header>
        <Styled.Logo />
        {currentStep === 1 && (
          <Styled.BackLink onClick={goBack}>
            <LeftOutlined />
            {translate(lang.backLink)}
          </Styled.BackLink>
        )}
      </Styled.Header>

      <Styled.Body height={height}>
        <Steps direction="vertical" current={currentStep - 1}>
          {lang.steps.map((step, idx) => (
            <Step
              key={idx}
              title={translate(step.title)}
              description={
                <>
                  <LangWrapper>{translate(step.desc)}</LangWrapper>
                  <Styled.StepCollapse>{getCollapsePanels(step.panels)}</Styled.StepCollapse>
                </>
              }
            />
          ))}
        </Steps>
      </Styled.Body>
    </Styled.Container>
  );
};
