// @flow

import * as React from 'react';

import * as Styled from './page.styled';
import PageHeader from 'src/components/pageHeader';

/*************
 *   TYPES   *
 *************/

type PageTypes = {
  children: React.Node,
  title: string
};

/*****************
 *   COMPONENT   *
 *****************/

const Page = ({ children, title }: PageTypes) => (
  <Styled.Container>
    <PageHeader title={title} />
    {children}
  </Styled.Container>
);

export default Page;
