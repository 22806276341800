// @flow

import FileSaver from 'file-saver';
import { core } from '@accordo-feed/micro-frontends-utils';

import { API, requestHandler, createDateFileName, resolveApiEndpoint } from 'src/utils';

/*****************
 *   API CALLS   *
 *****************/

const downloadQueryReportApi = resolveApiEndpoint(API.DOWNLOAD_QUERY_REPORT);

export const downloadQueryReport = ({
  orgId,
  clientName,
  data
}: {
  orgId: string,
  clientName: string,
  data: Object
}): Promise<any> =>
  requestHandler({
    url: core.replaceAll(downloadQueryReportApi, { orgId }),
    data,
    method: 'POST',
    handleBody: ({ data: csvData }) =>
      FileSaver.saveAs(new Blob([csvData]), createDateFileName(`${clientName}-SecureScore`, 'csv')),
    responseType: 'blob'
  });
