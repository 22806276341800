// @flow

import React, { useState } from 'react';
import { message } from 'antd';
import { useTranslate } from '@accordo-feed/language.entry';
import { constants } from '@accordo-feed/aco-styled-components';

import * as Styled from './planDetail.styled';
import PromotionBanner from 'src/components/promotionBanner';
import {
  DEFAULT_RATE_USD,
  DEFAULT_TRIAL_DAYS,
  DEFAULT_TOTAL_TODAY_PRICE,
  DEFAULT_PLAN_PRICE
} from './paymentDetails.constants.js';
import { Title } from './paymentDetails.styled';
import { formatUsd, checkPromotionTime } from 'src/utils';
import { planDetail as lang } from './paymentDetails.lang';
import { useEffectAsync } from 'src/hooks';

const { colors } = constants;

/*************
 *   TYPES   *
 *************/

type Props = {
  existingPromoCode: string,
  orgId: string,
  trialDays: number,
  setTrialDays: Function,
  submitPromoCode: Function
};

/*****************
 *   COMPONENT   *
 *****************/

export const PlanDetail = ({ existingPromoCode, orgId, trialDays, setTrialDays, submitPromoCode }: Props) => {
  const translate = useTranslate();
  const [isExpanded, setIsExpanded] = useState(false);
  const [totalPrice, setTotalPrice] = useState(DEFAULT_RATE_USD);
  const [couponDescription, setCouponDescription] = useState('');
  const isInPromotion = checkPromotionTime();

  useEffectAsync(async () => {
    if (existingPromoCode) {
      // TODO: Change to GET request when available
      await submitPromoCode({
        orgId,
        promotionCode: existingPromoCode,
        whenFailed: () => message.error(translate(lang.invalidCode, { value: existingPromoCode })),
        whenSuccessful: updateCouponInfo
      });
    }
  }, []);

  const updateCouponInfo = result => {
    const { percentOff, durationInMonths, trialPeriodDays } = result;
    if (percentOff) {
      setTotalPrice(totalPrice - (percentOff / 100) * totalPrice);
      const duration = durationInMonths ? ` ${translate(lang.discountDuration, { durationInMonths })}` : '';
      setCouponDescription(`"${translate(lang.discountDesc, { percentOff })}${duration}"`);
    }
    if (trialPeriodDays) {
      setTrialDays(DEFAULT_TRIAL_DAYS + trialPeriodDays);
      setCouponDescription(`"${translate(lang.trialExtDesc, { trialPeriodDays })}"`);
    }
  };

  const onClickApplyCoupon = value => {
    submitPromoCode({
      orgId,
      promotionCode: value,
      whenFailed: () => {
        message.warning(translate(lang.invalidCode, { value }));
      },
      whenSuccessful: result => {
        message.success(translate(lang.couponApplied, { coupon: value }));
        setIsExpanded(false);
        updateCouponInfo(result);
      }
    });
  };

  return (
    <Styled.PlainDetailBox>
      <Title size="m" color={colors.darkIndigo}>
        {translate(lang.planHeader, { trialDays })}
      </Title>
      <Styled.Line />
      <Styled.PlanDesc
        title={translate(lang.planName)}
        price={translate(lang.planPrice, {
          amount: formatUsd(DEFAULT_PLAN_PRICE)
        })}
      />
      <Styled.Line margin="0 0 12px 0" />
      <Styled.PriceItem
        left={translate(lang.totalToday.label)}
        right={translate(lang.totalToday.price, {
          amount: formatUsd(DEFAULT_TOTAL_TODAY_PRICE)
        })}
      />
      <Styled.Line isDashed margin="12px 0" />
      <Styled.PriceItem
        left={translate(lang.totalPostTrial.label)}
        right={translate(lang.totalPostTrial.price, {
          amount: formatUsd(totalPrice)
        })}
      />
      {couponDescription.length ? (
        <Styled.CouponDetails text={translate(lang.couponDetails, { coupon: couponDescription })} />
      ) : isExpanded ? (
        <Styled.CouponInput
          text={translate(lang.applyButton)}
          onButtonClick={onClickApplyCoupon}
          isInPromotion={isInPromotion}
        />
      ) : (
        <Styled.ApplyCouponButton text={translate(lang.couponLink)} onClick={() => setIsExpanded(!isExpanded)} />
      )}
      <Styled.Line margin="24px 0 0 0" />
      <Styled.Quote
        body={translate(lang.quote.body)}
        name={translate(lang.quote.name)}
        company={translate(lang.quote.company)}
      />
      {isInPromotion && <PromotionBanner />}
    </Styled.PlainDetailBox>
  );
};
