// @flow

import { PATHS, SEGMENT_EVENTS } from 'src/constants';
import { compose, withProps, withHandlers } from 'recompose';
import { eventTracking, router } from '@accordo-feed/micro-frontends';
import { translateHOC } from '@accordo-feed/language.entry';

import RightSide from './RightSide.component';

export default compose(
  translateHOC,
  router.withRouterHOC,

  withProps(({ translate }) => {
    const discount = 50;

    return {
      discount,
      plans: [
        {
          tierNum: 1,
          price: 0.7,
          userRange: '1 - 2,500'
        },
        {
          tierNum: 2,
          price: 0.6,
          userRange: '2,501 - 10,000'
        },
        {
          tierNum: 3,
          price: 0.5,
          userRange: '10,001+'
        }
      ]
    };
  }),

  withHandlers({
    onTrailButtonClick: ({ router }) => e => {
      eventTracking.trackEvent({ trackName: SEGMENT_EVENTS.PRICING_PAGE_START_TRIAL });
      router.push(PATHS.PAYMENT_DETAILS);
    }
  })
)(RightSide);
