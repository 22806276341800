import styled, { css } from 'styled-components';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router';
import { mixins, constants } from '@accordo-feed/aco-styled-components';

import { PATHS } from 'src/constants';
import { menuBorderWidth } from 'src/components/appSider/menu/menu.styled';

const { Item: MenuItem } = Menu;
const { colors, fontSize, shellIdSelector, zIndex } = constants;

export const headerHeight = '73px';

export const HeaderElement = styled(Layout.Header)`
  ${mixins.size('100%', `${headerHeight}`)};
  display: flex;
  position: fixed;
  padding: 0 30px;
  z-index: ${zIndex + 2};
  background: ${colors.darkBlue};
`;

export const SectionLogo = styled.div`
  font-size: ${fontSize.large};
  display: flex;
  align-items: center;
`;

export const SectionMenu = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;

  > button {
    height: ${headerHeight}px;
    margin-left: 2px;
  }

  ${({ isBulkEditView }) =>
    isBulkEditView &&
    css`
      display: none;
    `}
`;

export const NavMenu = styled(Menu).attrs({
  mode: 'horizontal'
})`
  && {
    ${mixins.flexVerticalCenter()};
    height: ${headerHeight}px;
    background: none;
    border-bottom: 0;

    .ant-menu-submenu {
      border-bottom: 0;

      .ant-menu-submenu-title {
        padding: 0;
      }
    }
  }
`;

export const NavMenuItem = styled(MenuItem)`
  && {
    ${mixins.flexVerticalCenter()};
    margin: 0;
    border-top: 1px solid ${colors.lightTintGrey};
    border-left: ${menuBorderWidth} solid transparent;

    .anticon {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      margin: 0;
    }

    &:hover {
      color: inherit;
      border-top-color: transparent;
      border-left-color: ${colors.blue};
      background-color: ${colors.hoverGrey};
    }
  }
`;

export const NavMenuProfile = styled(MenuItem)`
  ${shellIdSelector} &&& {
    display: block;
    height: auto;
    line-height: 1.7;
    margin: 12px 20px 12px ${menuBorderWidth};
    background-color: ${colors.white};

    div {
      color: ${colors.black};
      &:last-child {
        font-size: ${fontSize.xSmall};
      }
    }

    &:hover {
      background: transparent;
    }

    &.ant-menu-item-disabled {
      cursor: default;
    }
  }
`;

const logoPositionMixin = css`
  position: relative;
  top: 11px;
`;

export const LogoWrapper = styled.span`
  ${logoPositionMixin}
`;

export const LogoLink = styled(Link).attrs({
  to: PATHS.DASHBOARD.ROOT
})`
  ${logoPositionMixin}
`;
