import React from 'react';
import { connect } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import * as selectors from 'src/redux/selectors';

const ThemeProviderWrapper = ({ theme, children }) => <ThemeProvider theme={{ theme }}>{children}</ThemeProvider>;

const mapStateToProps = state => ({
  theme: selectors.appThemeSelector(state)
});

export default connect(mapStateToProps, null)(ThemeProviderWrapper);
