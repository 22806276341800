// @flow

import React from 'react';

import * as Styled from 'src/pages/dashboard/securityScore/securityScore.styled';
import filterLang from './filter.lang';
import Slider from 'src/components/slider';

/*************
 *   TYPES   *
 *************/

type ActionType = {
  setSecureScoreCurrentScoreFilter: Function,
  setSecureScorePercentageFilter: Function,
  setSecureScoreBarClickedIndex: Function
};

type Props = {
  actions: ActionType,
  shouldShowCurrentScoreFilter: boolean,
  shouldShowScoreIndexFilter: boolean,
  minCurrentScore: number,
  maxCurrentScore: number,
  minScoreIndex: number,
  maxScoreIndex: number,
  selectedMinCurrentScore: number,
  selectedMaxCurrentScore: number,
  selectedMinScoreIndex: number,
  selectedMaxScoreIndex: number
};

/*****************
 *   COMPONENT   *
 *****************/

export default ({
  actions,
  shouldShowCurrentScoreFilter,
  shouldShowScoreIndexFilter,
  minCurrentScore,
  maxCurrentScore,
  minScoreIndex,
  maxScoreIndex,
  selectedMinCurrentScore,
  selectedMaxCurrentScore,
  selectedMinScoreIndex,
  selectedMaxScoreIndex
}: Props) => {
  const currentScoreSliderProps = {
    titleLang: filterLang.currentScore,
    isFilterShowed: shouldShowCurrentScoreFilter,
    minLabel: minCurrentScore,
    maxLabel: maxCurrentScore,
    sliderProps: {
      range: 'range',
      min: minCurrentScore,
      max: maxCurrentScore,
      value: [selectedMinCurrentScore, selectedMaxCurrentScore],
      onChange: actions.setSecureScoreCurrentScoreFilter,
      disabled: !shouldShowCurrentScoreFilter
    }
  };

  const scoreIndexSliderProps = {
    titleLang: filterLang.scoreIndex,
    tooltipLang: filterLang.scoreIndexTooltip,
    isFilterShowed: shouldShowScoreIndexFilter,
    minLabel: minScoreIndex,
    maxLabel: maxScoreIndex + '%',
    sliderProps: {
      range: 'range',
      min: minScoreIndex,
      max: maxScoreIndex,
      value: [selectedMinScoreIndex, selectedMaxScoreIndex],
      onChange: value => {
        actions.setSecureScoreBarClickedIndex(-1);
        actions.setSecureScorePercentageFilter(value);
      },
      disabled: !shouldShowCurrentScoreFilter
    }
  };

  return (
    <Styled.OldFiltersContainer>
      <Slider {...currentScoreSliderProps} />
      <Slider {...scoreIndexSliderProps} />
    </Styled.OldFiltersContainer>
  );
};
