import styled from 'styled-components';
import { constants } from '@accordo-feed/aco-styled-components';
import { Select } from 'antd';
import { hexToRgb } from 'src/utils';
import { appTextColor } from 'src/App.styled';

const { colors } = constants;

export const FlagIcon = styled.div`
  line-height: 14px;
  margin-right: 10px;
`;

export const StyledSelect = styled(Select)`
  &.at__phoneCodeDropdown {
    .ant-select-selector {
      color: ${appTextColor};
      border: 1px solid ${colors.darkGrey6};
      background-color: rgba(${hexToRgb(colors.lightGrey7)}, 0.24);
      &:hover {
        border-color: #40a9ff;
      }
    }
    .ant-select-arrow {
      svg {
        fill: ${colors.darkGrey6};
      }
    }
  }
`;
