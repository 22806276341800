import { Link } from 'react-router';
import styled from 'styled-components';
import { constants } from '@accordo-feed/aco-styled-components';

import { ForwardIcon as ForwardIconBase } from 'src/components/widget/widget.styled';

const { colors, fontSize } = constants;

export const ForwardIcon = styled(ForwardIconBase).attrs({
  color: colors.black,
  size: '16px'
})`
  border-radius: 50%;
`;

export const ViewMoreContainer = styled.div`
  display: flex;
  min-width: max-content;
  max-width: max-content;
  height: 27px;
  padding: 0 6px 0 22px;
  align-items: center;
  line-height: 27px;
  border: 1.5px solid ${props => (props.theme.theme === 'dark' || props.dark ? colors.aquaBlue2 : colors.darkIndigo)};
  box-sizing: border-box;
  border-radius: 21px;
  color: ${colors.darkIndigo} !important;
  cursor: pointer;
  ${props =>
    (props.theme.theme === 'dark' || props.dark) &&
    `
    .at_viewMoreLink {
      color: ${colors.white} !important;
      svg {
        fill: ${colors.darkIndigo} !important;
        background: ${colors.aquaBlue2};
      }
    }`}
  ${props =>
    props.disabled &&
    `
    cursor: not-allowed;
    border-color: ${colors.darkGrey};
  `}
`;

const CtaButtonStyles = `
  font-size: ${fontSize.xSmall};
  margin: 0 auto;
  color: ${colors.lightBlueColorOverride};
  position: relative;
  top: -5px;

  &,
  &:hover,
  &:visited {
    color: ${colors.lightBlueColorOverride};
  }

  svg {
    position: relative;
    top: 4px;
  }
`;

export const ViewMoreLink = styled(Link)`
  ${CtaButtonStyles}
  font-size: 10px;
  line-height: 27px;
  color: ${colors.darkIndigo} !important;
  top: 0;

  &[disabled] {
    opacity: 0.3;
    color: ${colors.blackColor};
    pointer-events: none;
  }

  svg {
    background: ${colors.darkIndigo};
    fill: ${colors.white} !important;
    margin-left: 16px !important;
    padding: 0;
  }
`;
