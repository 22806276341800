// @flow

import * as R from 'ramda';
import React, { useEffect } from 'react';
import { LanguageEntry, useTranslate } from '@accordo-feed/language.entry';
import { DownloadOutlined } from '@ant-design/icons';

import ViewMore from 'src/components/viewMore';
import Widget from 'src/components/widget';
import UIButton from 'src/components/UI/button';
import { formatAndShortenNumberWithSymbol } from 'src/utils';
import { Tooltip } from 'src/pages/page.styled';
import { PATHS, DOWNLOAD_STATIC_REPORT_TYPE } from 'src/constants';
import { NotConfigured } from './noConnection';
import * as Styled from './billingDiscrepancy.styled';
import lang from './billingDiscrepancy.lang';

/*****************
 *     TYPES     *
 *****************/

type Actions = {
  getBillingDiscrepancyWidgetData: Function,
  downloadBillingDiscrepancyReport: Function
};

type Props = {
  data: Object,
  actions: Actions,
  query: Object,
  isLoading: boolean,
  isConnected: boolean,
  router: Object
};

/*****************
 *   COMPONENT   *
 *****************/

const NoBillingDiscrepancies = () => (
  <>
    <Styled.CheckIcon />
    <Styled.InfoWrapper marginBottom>
      <LanguageEntry {...lang.noDiscrepancies} />
    </Styled.InfoWrapper>
  </>
);

const BillingDiscrepancies = ({ totalBilling, totalDeficit, totalSurplus }) => (
  <>
    <Styled.InfoWrapper small leftAlign marginBottom>
      <LanguageEntry {...lang.totalDiscrepancies} />
    </Styled.InfoWrapper>
    <Styled.FlexWrapper>
      <Styled.EmphasisedText>{formatAndShortenNumberWithSymbol(totalBilling)}</Styled.EmphasisedText>
      <Styled.FlexWrapper column startAlign>
        <Styled.TextRow>
          <Styled.Text width={55}>
            <LanguageEntry {...lang.deficit} />
          </Styled.Text>
          <Styled.Text>{formatAndShortenNumberWithSymbol(totalDeficit, 1)}</Styled.Text>
        </Styled.TextRow>
        <Styled.TextRow>
          <Styled.Text width={55}>
            <LanguageEntry {...lang.surplus} />
          </Styled.Text>
          <Styled.Text>{formatAndShortenNumberWithSymbol(totalSurplus, 1)}</Styled.Text>
        </Styled.TextRow>
      </Styled.FlexWrapper>
    </Styled.FlexWrapper>
  </>
);

export default ({ data, query, actions, isConnected, isLoading, router }: Props) => {
  const translate = useTranslate();

  useEffect(() => {
    actions.getBillingDiscrepancyWidgetData(query);
  }, []);

  const totalDeficit = R.propOr(0, 'totalDeficit', data);
  const totalSurplus = R.propOr(0, 'totalSurplus', data);
  const totalBilling = totalDeficit + totalSurplus;
  const noBillingDiscrepancies = totalBilling === 0;

  const onDownloadStaticReportButtonClick = () =>
    actions.downloadBillingDiscrepancyReport(DOWNLOAD_STATIC_REPORT_TYPE.BILLING_RECONCILE);

  const onPsaIntegrationClick = () => {
    router.push(PATHS.PSA.ROOT);
  };

  return (
    <Widget
      key="billingDiscrepancies"
      title={
        <>
          <LanguageEntry {...lang.widgetTitle} />
          <Tooltip title={<LanguageEntry {...lang.tooltipMessage} />} />
        </>
      }
      footerContent={<ViewMore path={PATHS.PSA.ROOT} title={translate(lang.psaIntegrations)} disabled={isConnected} />}
    >
      {isConnected ? (
        <Styled.ContentWrapper>
          <Styled.FlexWrapper column>
            {noBillingDiscrepancies ? (
              <NoBillingDiscrepancies />
            ) : (
              <BillingDiscrepancies
                totalBilling={totalBilling}
                totalDeficit={totalDeficit}
                totalSurplus={totalSurplus}
              />
            )}
          </Styled.FlexWrapper>
          <UIButton variant="outline" loading={isLoading} onClick={onDownloadStaticReportButtonClick}>
            <DownloadOutlined />
            <LanguageEntry {...lang.discrepanciesReport} />
          </UIButton>
        </Styled.ContentWrapper>
      ) : (
        <NotConfigured onButtonClick={onPsaIntegrationClick} />
      )}
    </Widget>
  );
};
