// @flow

import React, { useMemo, useState } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';

import Dropdown from 'src/components/dropdown';
import { M365LICENSES_OPTIONS, M365LICENSES_OPTION_VALUE } from 'src/constants';
import * as selectors from 'src/redux/selectors';
import M365licensesComp from './M365licenses.component';
import * as Styled from './m365licenses.styled';

const M365licenses = props => {
  const [selected, setSelected] = useState(M365LICENSES_OPTIONS[0]);

  const data = useMemo(() => {
    const source = (props.data ?? [])
      .map(e => ({ ...e, unassigned: (e.purchased ?? 0) - (e.assigned ?? 0) }))
      .map(e => ({ ...e, value: e[M365LICENSES_OPTION_VALUE[selected]] ?? 0 }));
    const byValue = R.descend(R.prop('value'));
    return R.slice(0, 9, R.sort(byValue, source));
  }, [props.data, selected]);

  return (
    <Styled.Wrapper>
      <Styled.DropdownWrapper>
        <Dropdown
          showSearch={false}
          className="m365licenses_dropdown"
          defaultValue={M365LICENSES_OPTIONS[0]}
          data={M365LICENSES_OPTIONS}
          onSelect={e => setSelected(e)}
        />
      </Styled.DropdownWrapper>
      <M365licensesComp data={data} />
    </Styled.Wrapper>
  );
};

/*************
 *   REDUX   *
 *************/

const mapStateToProps = R.applySpec({
  data: selectors.dashboardM365LicensesSelector
});

export default connect(mapStateToProps)(M365licenses);
