import * as R from 'ramda';
import { createSelector } from 'reselect';

import { getModuleSelector } from 'src/redux/utils';

const moduleSelector = getModuleSelector('menu');

export const subMenuOpenKeysSelector = createSelector(moduleSelector, R.prop('subMenuOpenKeys'));

export const menuCollapsedStateSelector = createSelector(moduleSelector, R.prop('collapsed'));
