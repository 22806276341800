import React from 'react';
import * as R from 'ramda';
import { Row, Col, Tooltip } from 'antd';
import { Progress } from '@accordo-feed/aco-styled-components';
import { useTranslate } from '@accordo-feed/language.entry';
import { widgetDataHOC } from 'src/hoc';
import { appTextColor, nestedWidgetBackground } from 'src/App.styled';
import * as Styled from './unassignedLicenses.styled';

/*************
 *   TYPES   *
 *************/

export interface Elipse {
  elipseLongName: boolean;
  elipseShortWidth: string;
  elipseLongWidth: string;
}

type UnassignedLicensesProps = {
  elipse: Elipse,
  topUnassigned: Array<{ name: string, total: number, value: number, percentage: number }>,
  theme?: 'light' | 'dark'
};
/***************
 *   HELPERS   *
 ***************/

const renderUnassignedLicenses = ({ topUnassigned, translate, elipse }: UnassignedLicensesProps) => {
  const length = topUnassigned.length;
  const unassignedLicensesCount = length < 4 ? length : 4;
  const topUnassignedLicenses = topUnassigned.slice(0, unassignedLicensesCount);
  const mapIndexed = R.addIndex(R.map);

  return mapIndexed(
    (unassignedLicense, index) => (
      <Styled.LicenseRow key={index}>
        <Tooltip title={`Unassigned Licenses: ${unassignedLicense.value}`}>
          <Styled.ProgressWrapper>
            <Progress
              percent={unassignedLicense.percentage}
              height="15px"
              width="100%"
              barColor="#DA5555"
              borderColor={appTextColor}
              backgroundColor={nestedWidgetBackground}
            />
          </Styled.ProgressWrapper>
        </Tooltip>
        <Styled.TitleWrapper>
          <Styled.CompanyName
            elipseMaxWidth={Boolean(index) ? elipse.elipseLongWidth : elipse.elipseShortWidth}
            elipseLongName={elipse.elipseLongName}
            title={unassignedLicense.name}
          >
            {unassignedLicense.name}
          </Styled.CompanyName>
          <span>{unassignedLicense.percentage}%</span>
        </Styled.TitleWrapper>
      </Styled.LicenseRow>
    ),
    topUnassignedLicenses
  );
};

/*****************
 *   COMPONENT   *
 *****************/

const UnassignedLicenses = ({
  unassignedLicenses,
  elipse,
  formatCurrencyWithoutUnit,
  theme
}: UnassignedLicensesProps) => {
  const translate = useTranslate();
  const { potentialSavings, topUnassigned, total } = unassignedLicenses;
  if (R.isEmpty(topUnassigned) || R.isNil(topUnassigned)) return null;
  return (
    <Styled.Wrapper>
      <Row type="flex">
        <Col xs={12} md={9}>
          <Styled.NumberBox>
            <Styled.NumberLabel theme={theme}>{total || 0}</Styled.NumberLabel>
            <Styled.Text>Total</Styled.Text>
          </Styled.NumberBox>
        </Col>
        <Col xs={12} md={15}>
          <Styled.NumberBox>
            <Styled.NumberLabel theme={theme} colorIdx="1">
              {formatCurrencyWithoutUnit(potentialSavings || 0)}
            </Styled.NumberLabel>
            <Styled.Text>Potential Savings</Styled.Text>
          </Styled.NumberBox>
        </Col>
      </Row>
      <Styled.UnassignedLicense>
        {renderUnassignedLicenses({ topUnassigned, translate, elipse })}
      </Styled.UnassignedLicense>
    </Styled.Wrapper>
  );
};

export default widgetDataHOC(['unassignedLicenses'])(UnassignedLicenses);
