// @flow

import * as R from 'ramda';
import { takeLatest, put, all, fork, select, call } from 'redux-saga/effects';

import * as actions from './dashboard.duck';
import * as apiCalls from './dashboard.api';
import * as selectors from 'src/redux/selectors';
import { transform } from './widgets/securityScores/securityScores.utils';
import { transform as getBillingDiscrepanciesTransform } from './widgets/billingDiscrepancy/billingDiscrepancy.utils';
import { PSA_CONNECTION_STATUS } from 'src/constants';
import { patchData } from 'src/demo/demo.utils';

/************
 *   SAGA   *
 ************/

function* getDashboardDataSaga(): any {
  yield put(actions.setLoadingState(true));
  const orgId = yield select(selectors.orgIdSelector);

  try {
    let data = yield apiCalls.getDashboardData(orgId);
    const isDemo = yield select(selectors.hasFeatureDemo);
    if (isDemo) {
      data = yield call(patchData, data);
    }
    const companyIds = data.masterList.map(e => e.id);
    const consumptionServicesRes = yield apiCalls.getConsumptionServices(companyIds);
    const consumptionServices = consumptionServicesRes.filter(e => e?.id);
    const consumptionAdoptionsRes = yield apiCalls.getConsumptionAdoptions(companyIds);
    const consumptionAdoptions = consumptionAdoptionsRes.filter(e => e?.id);
    const masterList = data.masterList.map(item => {
      const consumptionService = R.find(R.propEq('id', item.id), consumptionServices) || {};
      const consumptionAdoption = R.find(R.propEq('id', item.id), consumptionAdoptions) || {};
      const savings = consumptionService.savings || [];
      const plans = [];
      let misassigned = 0;
      let exchange = 0;
      let sharepoint = 0;
      let onedrive = 0;
      savings.forEach(e => {
        plans.push(e.resource);
        const quantity = R.path(['recommendedActions', 'variables', 'quantity'], e) ?? 0;
        if (R.path(['recommendedActions', 'type'], e) === 'misassigned_offboarded') {
          misassigned += quantity;
        }
      });
      (consumptionAdoption.details ?? []).forEach(e => {
        if (R.path(['application'], e) === 'Exchange') {
          exchange += e.storage;
        }
        if (R.path(['application'], e) === 'SharePoint') {
          sharepoint += e.storage;
        }
        if (R.path(['application'], e) === 'OneDrive') {
          onedrive += e.storage;
        }
      });
      return {
        ...item,
        planSKUs: R.uniq(plans),
        allocationPotentialSavings: item.unassignedLicenses ?? 0 + misassigned,
        adoptionPotentialSavings: consumptionAdoption.potentialSavings,
        storage: { exchange, sharepoint, onedrive },
        misassigned,
        ...consumptionAdoption
      };
    });
    yield put(actions.getDashboardDataSuccess({ ...data, masterList }));
  } catch (err) {
    yield put(actions.getDashboardDataFailed());
  }
}

function* getSecurityWidgetSaga({ payload }): any {
  yield put(actions.setSecurityWidgetLoadingState(true));

  try {
    const data = yield apiCalls.getSecurityData(payload);

    yield put(actions.getSecurityWidgetDataSuccess(transform(data)));
    yield put(actions.setSecurityWidgetLoadingState(false));
  } catch (err) {
    yield put(actions.setSecurityWidgetLoadingState(false));
  }
}

function* downloadBillingDiscrepancyReportSaga({ payload }): any {
  yield put(actions.setBillingDiscrepancyWidgetLoadingState(true));
  const orgId = yield select(selectors.orgIdSelector);

  try {
    yield apiCalls.downloadStaticReport({ orgId, type: payload });
    yield put(actions.setBillingDiscrepancyWidgetLoadingState(false));
  } catch (err) {
    yield put(actions.setBillingDiscrepancyWidgetLoadingState(false));
  }
}

function* getBillingDiscrepancyWidgetSaga({ payload }): any {
  yield put(actions.setBillingDiscrepancyWidgetLoadingState(true));
  const orgId = yield select(selectors.orgIdSelector);
  const billingDiscrepancy = {};

  try {
    const psaConnectionData = yield apiCalls.getPsaConnections(orgId);
    billingDiscrepancy.isConnected = R.equals(R.path([0, 'status'], psaConnectionData), PSA_CONNECTION_STATUS.ACTIVE);
    const billingDiscrepancyData = yield apiCalls.getBillingDiscrepancyData(payload);
    billingDiscrepancy.data = getBillingDiscrepanciesTransform(billingDiscrepancyData);

    yield put(actions.getBillingDiscrepancyWidgetDataSuccess(billingDiscrepancy));
  } catch (err) {
    yield put(actions.setBillingDiscrepancyWidgetLoadingState(false));
  }
}

export default function*(): any {
  yield all([
    fork(takeLatest, actions.getDashboardData, getDashboardDataSaga),
    fork(takeLatest, actions.getSecurityWidgetData, getSecurityWidgetSaga),
    fork(takeLatest, actions.downloadBillingDiscrepancyReport, downloadBillingDiscrepancyReportSaga),
    fork(takeLatest, actions.getBillingDiscrepancyWidgetData, getBillingDiscrepancyWidgetSaga)
  ]);
}
