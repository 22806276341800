import styled from 'styled-components';
import { constants, mixins, Icon } from '@accordo-feed/aco-styled-components';

import { hexToRgb } from 'src/utils';
import ShrinkSvg from 'src/images/shrink-icon.svg';
import ExpandSvg from 'src/images/expand-icon.svg';
import { subHeaderTextColor, widgetBackground, widgetBorderColor, widgetHeaderTextColor } from 'src/App.styled';

const { fontSize, colors } = constants;

export const ShrinkIcon = styled(ShrinkSvg)`
  ${mixins.size('14px')};
`;

export const ExpandIcon = styled(ExpandSvg)`
  ${mixins.size('14px')};
`;

export const WidgetCardWrapper = styled.div`
  border-radius: 15px;
  border: 1px solid ${widgetBorderColor};
  background: ${widgetBackground};
  box-shadow: 0 1px 3px rgba(${hexToRgb(colors.shadowColor)}, 0.1),
    0 1px 2px rgba(${hexToRgb(colors.shadowColor)}, 0.06);
  width: 100%;
`;

export const WidgetCardTitle = styled.div`
  color: ${widgetHeaderTextColor};
  font-size: ${props => (props.isSubWidget ? fontSize.small : fontSize.medium)};
  line-height: 1.5;
  display: flex;
  align-items: center;
`;

export const WidgetCardSubTitle = styled.div`
  color: ${props => (props.direction === 'vertical' ? subHeaderTextColor : colors.blue)};
  font-size: ${fontSize.xSmall};
  line-height: 1.5;
  display: flex;
  align-items: center;
  position: relative;
  margin-left: ${props => (props.direction === 'vertical' ? '0' : '10px')};

  &::before {
    content: '';
    position: absolute;
    width: 1px;
    top: 50%;
    height: 70%;
    transform: translateY(-50%);
    left: -8px;
    background-color: ${widgetHeaderTextColor};
    display: ${props => (props.direction === 'vertical' ? 'none' : 'block')};
  }
`;

export const WidgetContainer = styled(WidgetCardWrapper)`
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  color: ${widgetHeaderTextColor};
  border: 1px solid ${widgetBorderColor};
  background: ${props => (props.customBackground ? props.customBackground : widgetBackground)};
  overflow: hidden;
`;

const WidgetWidth = styled.div`
  width: 100%;
`;

export const WidgetHeaderWrapper = styled(WidgetWidth).attrs({
  className: 'at_widgetHeader'
})`
  font-size: ${fontSize.small};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 15px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: ${props => (props.direction === 'vertical' ? 'column' : 'row')};
  gap: ${props => (props.direction === 'vertical' ? '0' : '5px')};
`;

export const Status = styled.div`
  width: 10px;
  height: 10px;
  margin-left: 10px;
  background: #5cd470;
  border-radius: 10px;
`;

export const ExpandButton = styled.button`
  display: flex;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  opacity: 0.7;
  padding: 0;

  &:hover {
    opacity: 1;
  }
`;

export const WidgetBody = styled.div`
  height: calc(100% - ${props => (props.height ? props.height : '0px')});
  position: relative;
`;

export const WidgetBodyWrapper = styled(WidgetWidth)`
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
`;

export const WidgetActionWrapper = styled.div`
  height: 42px;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  border: 1.5px solid ${colors.aquaBlue2};
  box-sizing: border-box;
  border-radius: 21px;
`;

export const ForwardIcon = styled(Icon)`
  padding-top: 13px;
  padding-right: 5px;
`;

export const WidgetFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 15px;
  margin-right: 0;
`;
