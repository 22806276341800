import styled, { css } from 'styled-components';
import { mixins } from '@accordo-feed/aco-styled-components';

import DarkLogoSvg from 'src/images/darkLogo.svg';
import LightLogoSvg from 'src/images/lightLogo.svg';
import FullLogoSvg from 'src/images/full-logo.svg';
import EntoriLogoSvg from 'src/images/entoriLogo.svg';

const logoMixins = css`
  ${mixins.size('89px', 'auto')};
  fill: #27aae1;
  ${props =>
    props.size === 'large' &&
    css`
      width: 110px;
    `}
`;

export const DarkLogo = styled(DarkLogoSvg)`
  ${logoMixins};
`;

export const LightLogo = styled(LightLogoSvg)`
  ${logoMixins};
`;

export const FullLogo = styled(FullLogoSvg)`
  ${logoMixins};
`;

export const EntoriLogo = styled(EntoriLogoSvg)`
  ${mixins.size('auto')};
`;
