// @flow

import React from 'react';
import { useTranslate } from '@accordo-feed/language.entry';

import * as Styled from './priceBox.styled';
import lang from 'src/pages/planOverview/rightSide/rightSide.lang';

const cardLang = lang.card;

/*************
 *   TYPES   *
 *************/

type Props = {
  className: string,
  discount: number,
  price: number,
  tierNum: number,
  userRange: string
};

/*****************
 *   COMPONENT   *
 *****************/

export default ({ className, discount, price, tierNum, userRange }: Props) => {
  const translate = useTranslate();

  return (
    <Styled.Container className={className}>
      <Styled.Title>
        {translate(cardLang.title, {
          tierNum
        })}
      </Styled.Title>
      <Styled.Unit>US$</Styled.Unit>
      <Styled.Price value={price} discount={discount} />
      <Styled.Period>{translate(cardLang.period)}</Styled.Period>
      {translate(cardLang.userRange, { range: userRange })}
    </Styled.Container>
  );
};
