// @flow

import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { useTranslate } from '@accordo-feed/language.entry';

import ViewMore from 'src/components/viewMore';
import Widget from 'src/components/widget';
import { getSecurityWidgetData } from 'src/pages/dashboard/dashboard.duck';
import { setSelectedFilter } from 'src/pages/dashboard/securityScore/filterPanel/filterPanel.duck';
import { getFilteredSecurityPageData } from 'src/pages/dashboard/securityScore/securityScorePage.duck';
import { resetSearchTerm } from 'src/redux/modules/searchTerm/searchTerm.duck';
import * as selectors from 'src/redux/selectors';
import {
  resetFilter,
  setSecureScorePercentageFilter,
  setSecureScoreBarClickedIndex
} from 'src/redux/modules/filters/filters.duck';
import { isEmptyOrNil } from 'src/utils';
import { loaderInlineHOC } from 'src/hoc';
import { PATHS } from 'src/constants';
import * as Styled from './securityScores.styled';
import * as securitySelectors from './securityScores.selector';
import { securityChartIsLoadingSelector } from './securityScores.selector';
import SecurityScoresContent from './SecurityScores.component';
import DemoOverlay from './DemoOverlay';
import lang from './securityScores.lang';

/*************
 *   TYPES   *
 *************/

type PropTypes = {
  actions: Object,
  data: Object,
  query: Object,
  permissionCount: number,
  summary: Object,
  alertDisabled: boolean,
  demoDisabled: boolean,
  isLoading: boolean,
  spinner: Object,
  barClickedIndex: number,
  barClickable: boolean,
  hasFeatureSecurityFilterPanel: boolean,
  hasViewMoreAction: boolean
};

/**************
 *   HELPER   *
 **************/

const processChartData = (data, translate) => {
  if (isEmptyOrNil(data)) {
    return {};
  }

  const output = R.clone(data);
  output.xAxis.name = translate(lang.chartXAxis);
  output.yAxis.name = translate(lang.chartYAxis);
  return output;
};

/*****************
 *   COMPONENT   *
 *****************/

const SecurityScores = ({
  actions,
  data,
  query,
  summary: { totalCustomers },
  alertDisabled = false,
  demoDisabled = false,
  spinner,
  barClickedIndex,
  barClickable = false,
  hasFeatureSecurityFilterPanel,
  hasViewMoreAction = true
}: PropTypes) => {
  useEffect(() => {
    actions.getSecurityWidgetData(query);
  }, []);

  const [showOverlay, setShowOverlay] = useState(false);
  const translate = useTranslate();
  const chartData = processChartData(data, translate);

  // Details of params: https://www.echartsjs.com/en/tutorial.html#Events%20and%20Actions%20in%20ECharts
  const handleClick = params => {
    if (barClickable && params.componentType === 'series') {
      const scorePercentage = params.name.match(/\d+/g).map(item => parseInt(item, 10));

      if (hasFeatureSecurityFilterPanel) {
        actions.setSelectedFilter({ percentage: scorePercentage });
        actions.getFilteredSecurityPageData();
      } else {
        actions.resetSearchTerm();
        actions.resetFilter('secureScore');
        actions.setSecureScorePercentageFilter(scorePercentage);
      }

      actions.setSecureScoreBarClickedIndex(params.dataIndex);
    }
  };

  return (
    <Widget
      key="secureScore"
      widgetId="secureScore"
      title={!alertDisabled && translate(lang.widgetTitle)}
      subTitle={!alertDisabled && translate(lang.widgetSubTitle)}
      footerContent={hasViewMoreAction && <ViewMore path={PATHS.SECURITY_SCORE.ROOT} />}
    >
      <Styled.RootWrapper>
        <SecurityScoresContent
          data={chartData}
          isDemo={false}
          handleClick={handleClick}
          barClickedIndex={barClickedIndex}
          demoDisabled={demoDisabled}
          setShowOverlay={setShowOverlay}
        />
      </Styled.RootWrapper>
      {!demoDisabled && showOverlay && <DemoOverlay hide={() => setShowOverlay(false)} />}
      {/* SPINNER */}
      {spinner}
    </Widget>
  );
};

/*************
 *   REDUX   *
 *************/

const mapStateToProps = state => ({
  query: securitySelectors.securityChartQuerySelector(state),
  data: securitySelectors.securityChartDataSelector(state),
  summary: selectors.dashboardSummarySelector(state),
  isLoading: securityChartIsLoadingSelector(state),
  barClickedIndex: securitySelectors.barClickedIndexSelector(state),
  hasFeatureSecurityFilterPanel: selectors.hasFeatureSecurityFilterPanel(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getSecurityWidgetData,
      resetSearchTerm,
      resetFilter,
      setSecureScorePercentageFilter,
      setSecureScoreBarClickedIndex,
      setSelectedFilter,
      getFilteredSecurityPageData
    },
    dispatch
  )
});

export default compose(
  // $FlowIgnore
  connect(mapStateToProps, mapDispatchToProps),
  loaderInlineHOC()
)(SecurityScores);
