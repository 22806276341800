import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import * as R from 'ramda';
import * as Styled from './verticalMenu.styled';

const VerticalMenu = ({ options, onClick }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMenu = e => () => {
    onClick && onClick(e);
    handleClose();
  };

  const open = Boolean(anchorEl);

  if (R.isEmpty(options)) return null;

  return (
    <div className="vertical-menu">
      <Styled.VerticalIconWrapper onClick={handleClick}>
        <Styled.VerticalIcon />
      </Styled.VerticalIconWrapper>
      <Styled.StyledPopover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {options.map(option => (
          <MenuItem key={option} onClick={handleClickMenu(option)}>
            {option}
          </MenuItem>
        ))}
      </Styled.StyledPopover>
    </div>
  );
};

export default VerticalMenu;
