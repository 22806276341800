// @flow
import React from 'react';
import { DownOutlined } from '@ant-design/icons';

import * as Styled from './growthTrend.styled';

/*************
 *   TYPES   *
 *************/

type Props = {
  filterMenu: Object,
  selectedTitle: String
};

/*****************
 *   COMPONENT   *
 *****************/

export default ({ filterMenu, selectedTitle }: Props) => (
  <Styled.Dropdown overlay={filterMenu} trigger={['click']}>
    <Styled.DropWrapper className="ant-dropdown-link">
      {selectedTitle} <DownOutlined />
    </Styled.DropWrapper>
  </Styled.Dropdown>
);
