// @flow

import React from 'react';
import SimpleTable from 'src/components/simpleTable';
import { generateTableColumns } from './allocation.config';
import { widgetDataHOC } from 'src/hoc';
import { PATHS } from 'src/constants';

/*************
 *   TYPES   *
 *************/

type Props = {
  dataSource: Array<Object>
};

/*****************
 *   COMPONENT   *
 *****************/

const AllocationComponent = ({ dataSource }: Props) => {
  const tableOptions = {
    dataSource,
    pageSize: 17,
    columns: generateTableColumns({
      enableSort: true,
      prevPath: PATHS.DASHBOARD.ROOT
    }),
    x: 1200
  };

  return <SimpleTable {...tableOptions} />;
};

export default widgetDataHOC(['dataSource'])(AllocationComponent);
