import React from 'react';

import { FlagIcon, StyledSelect } from './countryPicker.styled';

const { Option } = StyledSelect;

export const byInputValue = inputValue => ({ name }) =>
  inputValue ? name.toLowerCase().startsWith(inputValue.toLowerCase()) : true;

export const generateOption = (map, type) => ({ code }) => {
  const lowercaseCode = code.toLowerCase();

  return (
    <Option value={lowercaseCode} key={lowercaseCode}>
      {type === 'country' && <FlagIcon className={`flag-icon flag-icon-${lowercaseCode}`} />}
      {map[lowercaseCode]}
    </Option>
  );
};
