// @flow

import * as R from 'ramda';

/*************
 *   TYPES   *
 *************/

type SubscriptionsType = {
  office365Basic: {
    id: ?string,
    lastPaymentFailed: boolean
  }
};

type PaymentType = ?{
  hasValidCard: boolean,
  subscriptions: SubscriptionsType
};

/*****************
 *   UTILITIES   *
 *****************/

/*
 * Sample structure of ReduxStore.shell.organization.data.payment
 *
    "payment": {
        "promotionCode": "LONGTERM_PARTNER",
        "subscribeDate": "2019-06-06T01:03:06.763Z",
        "lastSyncedDate": "2019-06-06T01:03:31.648Z",
        "customerId": "cus_FCZ9Y3UKMbdbDv",
        "hasValidCard": true,
        "subscriptions": {
            "office365Basic": {
                "id": "sub_FCZ94T3mFDr65P",
                "lastPaymentFailed": false,
                "cancelAtPeriodEnd": false
            }
        }
    }
*/

/*
 * Three cases needed to be covered:
 *
 * 1. `payment` field might be not existed since an user can only finish the first register step,
 *    but quiting at the following pages, like `/payment-details`
 * 2. credit card is not valid
 * 3. if the subscription has been cancelled and the billing cycle for that subscription is end
 * 4. all plans under payment.subscriptions must have plan.lastPaymentFailed === false
 */

export const isValidSubscription = (payment: PaymentType): boolean => {
  // case 1
  if (!payment) return false;

  const { hasValidCard, subscriptions } = payment;
  const plans = R.values(subscriptions);

  // case 2
  if (!hasValidCard) return false;

  // case 3
  // why we use this `id` as a flag is because we will remove this id
  // when we finalize this subscription at the end of the billing cycle.
  const isEverySubscriptionHasId = plans.every(plan => plan.hasOwnProperty('id') && plan.id !== '');
  if (!isEverySubscriptionHasId) return false;

  // case 4
  return plans.every(plan => !plan.lastPaymentFailed);
};
