import { createSelector } from 'reselect';
import * as R from 'ramda';

import { getModuleSelector } from 'src/redux/utils';
import { organizationPaymentSelector } from './organization';

const moduleSelector = getModuleSelector('payment');

export const office365PlanSelectedTierSelector = createSelector(
  moduleSelector,
  R.pathOr(null, ['planOverview', 'office365Basic', 'subscribeToTier'])
);

export const office365PlanSelectedIsAnnualSelector = createSelector(
  moduleSelector,
  R.pathOr(null, ['planOverview', 'office365Basic', 'isAnnual'])
);

export const subscriptionsLoadingStatusSelector = createSelector(
  moduleSelector,
  R.pathOr(null, ['subscriptions', 'loadingStatus'])
);

export const cancelSubscriptionLoadingStatusSelector = createSelector(
  moduleSelector,
  R.pathOr(null, ['subscriptions', 'cancelSubscriptionLoadingStatus'])
);

export const subscriptionsSelector = createSelector(moduleSelector, R.pathOr(null, ['subscriptions', 'data']));

export const office365SubscriptionSelector = createSelector(
  organizationPaymentSelector,
  R.pathOr(null, ['subscriptions', 'office365Basic'])
);

/*
 * The reason we need this property:
 *
 * After pressing the cancel subscription button in payment center,
 * The actual cancel will happen at the end of billing cycle.
 * But we need to disable the adding new client ability in the client side
 * even before the actual cancel happen.
 * And the payment center needs a flag to display the `Start subscribe` button
 *
 */
export const willOffice365BeCancelledSelector = createSelector(
  office365SubscriptionSelector,
  R.pathOr(null, ['cancelAtPeriodEnd'])
);

export const office365BeCancelTimeSelector = createSelector(
  office365SubscriptionSelector,
  R.pathOr(null, ['cancelAt'])
);

export const updateBillingEmailLoadingStatusSelector = createSelector(
  moduleSelector,
  R.pathOr(null, ['subscriptions', 'updateBillingEmailLoadingStatus'])
);

export const updateBillingAddressLoadingStatusSelector = createSelector(
  moduleSelector,
  R.pathOr(null, ['billingAddressStatus', 'loadingStatus'])
);

export const updateBillingAddressErrorsSelector = createSelector(
  moduleSelector,
  R.pathOr(null, ['billingAddressStatus', 'errors'])
);
