import * as R from 'ramda';

import { INVITE_ERRORS } from 'src/constants';

export const isInviteError = parsedHash => {
  const { error_description: errorDescription } = parsedHash;

  if (R.isNil(errorDescription)) {
    return false;
  }

  const { code: errorCode } = JSON.parse(errorDescription.toString());

  if ([INVITE_ERRORS.INVALID_INVITE, INVITE_ERRORS.EXPIRED_INVITE].includes(errorCode)) {
    return true;
  }

  return false;
};
