import { langHelper } from '@accordo-feed/micro-frontends-utils';
import { PROMOTION_CODE } from 'src/constants';

const genPromotionBannerId = langHelper.genLangId('shell.aco.promotionBanner');
const genPromotionBannerOpts = langHelper.genLangOptions(genPromotionBannerId);

export default {
  promotion1: genPromotionBannerOpts(
    'promotion1',
    `Use the promotion code ${PROMOTION_CODE} and receive 50% off your subscription for life!`
  ),
  promotion2: genPromotionBannerOpts(
    'promotion2',
    'Take advantage of this limited-time offer valid through October 2019'
  )
};
