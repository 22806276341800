// @flow

import React from 'react';
import { useTranslate } from '@accordo-feed/language.entry';

import { Tooltip } from 'src/pages/page.styled';
import * as Styled from './slider.styled';

/*************
 *   TYPES   *
 *************/

type SliderProps = {
  range: string,
  min: number,
  max: number,
  value: Array<number>,
  onChange: Function,
  width: string
};

type Props = {
  titleLang: Object,
  tooltipLang: Object,
  minLabel: string,
  maxLabel: string,
  isCurrency: boolean,
  sliderProps: SliderProps
};

/****************
 *   COMPONENT   *
 ****************/

export default ({ titleLang, tooltipLang, minLabel, maxLabel, sliderProps }: Props) => {
  const translate = useTranslate();
  return (
    <Styled.FilterWrapper>
      <Styled.Title>
        <div>
          {translate(titleLang)}
          {tooltipLang && <Tooltip title={translate(tooltipLang)} gapLeft="3px" />}
        </div>
      </Styled.Title>
      <Styled.SliderWrapper width={sliderProps.width}>
        <Styled.SliderLabel>{minLabel}</Styled.SliderLabel>
        <Styled.Slider {...sliderProps} />
        <Styled.SliderLabel>{maxLabel}</Styled.SliderLabel>
      </Styled.SliderWrapper>
    </Styled.FilterWrapper>
  );
};
