// @flow

import * as R from 'ramda';
import { createAction } from 'redux-actions';
import { redux } from '@accordo-feed/micro-frontends';

import type { Action } from 'src/redux/types';
import { wrapWithModule } from 'src/redux/utils';
import { STORAGE_KEYS } from 'src/constants';

const { setState, toggleState } = redux;

/*************
 *   TYPES   *
 *************/

type StateType = {
  data: {
    clientName: string,
    controlProfiles: Array<Object>,
    currentScore: number,
    maxScore: number,
    percentage: number
  },
  isCsvLoading: boolean,
  filters: {
    status: Array<string>,
    controlCategory: Array<string>,
    userImpact: Array<string>
  },
  refreshLocalDropdownState: boolean,
  clientId: string,
  isLoading: boolean,
  isOpenPanel: boolean,
  searchTerm: string,
  isTooltipdisabled: boolean
};

/*********************
 *   INITIAL STATE   *
 *********************/

export const filtersInit = {
  status: [],
  controlCategory: [],
  userImpact: []
};

const initialState: StateType = {
  data: {
    clientName: '',
    controlProfiles: [],
    currentScore: 0,
    maxScore: 0,
    percentage: 0
  },
  isCsvLoading: false,
  filters: filtersInit,
  refreshLocalDropdownState: false,
  clientId: '',
  isLoading: false,
  isOpenPanel: true,
  searchTerm: '',
  isTooltipdisabled: Boolean(localStorage.getItem(STORAGE_KEYS.HIDE_SECURITY_SCORE_RECOMMENDATION_TOOLTIP))
};

/***************
 *   ACTIONS   *
 ***************/

const wrapWithNamespace = wrapWithModule('pages/dashboard/securityScores/drawer');
const GET_SECURITY_CONTROL_PROFILE_DATA = wrapWithNamespace('GET_SECURITY_CONTROL_PROFILE_DATA');
const GET_SECURITY_CONTROL_PROFILE_DATA_SUCCESS = wrapWithNamespace('GET_SECURITY_CONTROL_PROFILE_DATA_SUCCESS');
const RESET = wrapWithNamespace('RESET');
const RESET_SECURITY_CONTROL_PROFILE_FILTERS = wrapWithNamespace('RESET_SECURITY_CONTROL_PROFILE_FILTERS');
const SET_SEARCH_TERM = wrapWithNamespace('SET_SEARCH_TERM');
const SET_SECURITY_CONTROL_PROFILE_CLIENT_ID = wrapWithNamespace('SET_SECURITY_CONTROL_PROFILE_CLIENT_ID');
const SET_SECURITY_CONTROL_PROFILE_FILTER = wrapWithNamespace('SET_SECURITY_CONTROL_PROFILE_FILTER');
const SET_SECURITY_CONTROL_PROFILE_LOADING_STATE = wrapWithNamespace('SET_SECURITY_CONTROL_PROFILE_LOADING_STATE');
const SET_DOWNLOAD_QUERY_REPORT = wrapWithNamespace('SET_DOWNLOAD_QUERY_REPORT');
const SET_DOWNLOAD_QUERY_REPORT_LOADING_STATE = wrapWithNamespace('SET_DOWNLOAD_QUERY_REPORT_LOADING_STATE');
const TOGGLE_REFRESH_LOCAL_DROPDOWN_STATE = wrapWithNamespace('TOGGLE_REFRESH_LOCAL_DROPDOWN_STATE');
const DISABLE_DRAWER_TOOLTIP = wrapWithNamespace('DISABLE_DRAWER_TOOLTIP');
const SET_OPEN_FILTER_PANEL = wrapWithNamespace('SET_OPEN_FILTER_PANEL');

export const getSecurityControlProfileData = createAction(GET_SECURITY_CONTROL_PROFILE_DATA);
export const getSecurityControlProfileDataSuccess = createAction(GET_SECURITY_CONTROL_PROFILE_DATA_SUCCESS);
export const reset = createAction(RESET);
export const resetSecurityControlProfileFilters = createAction(RESET_SECURITY_CONTROL_PROFILE_FILTERS);
export const setSearchTerm = createAction(SET_SEARCH_TERM);
export const setSecurityControlProfileClientId = createAction(SET_SECURITY_CONTROL_PROFILE_CLIENT_ID);
export const setSecurityControlProfileFilter = createAction(SET_SECURITY_CONTROL_PROFILE_FILTER);
export const setSecurityControlProfileLoadingState = createAction(SET_SECURITY_CONTROL_PROFILE_LOADING_STATE);
export const setDownloadQueryReport = createAction(SET_DOWNLOAD_QUERY_REPORT);
export const setDownloadQueryReportLoadingState = createAction(SET_DOWNLOAD_QUERY_REPORT_LOADING_STATE);
export const toggleRefreshLocalDropdownState = createAction(TOGGLE_REFRESH_LOCAL_DROPDOWN_STATE);
export const disableDrawerTooltip = createAction(DISABLE_DRAWER_TOOLTIP);
export const setOpenFilterPanel = createAction(SET_OPEN_FILTER_PANEL);

/***************
 *   REDUCER   *
 ***************/

export default (state: StateType = initialState, action: Action) => {
  const { type, payload } = action;

  const reducer = {
    [GET_SECURITY_CONTROL_PROFILE_DATA_SUCCESS]: (state, payload) => ({
      ...initialState,
      ...R.pick(['clientId', 'isTooltipdisabled'], state),
      data: payload,
      isLoading: false
    }),
    [RESET]: R.always({
      ...initialState,
      isTooltipdisabled: Boolean(localStorage.getItem(STORAGE_KEYS.HIDE_SECURITY_SCORE_RECOMMENDATION_TOOLTIP))
    }),
    [RESET_SECURITY_CONTROL_PROFILE_FILTERS]: R.assoc('filters', filtersInit),
    [SET_SEARCH_TERM]: setState('searchTerm'),
    [SET_SECURITY_CONTROL_PROFILE_CLIENT_ID]: setState('clientId'),
    [SET_SECURITY_CONTROL_PROFILE_FILTER]: (state, { path, value }) => R.assocPath(['filters', path], value, state),
    [SET_SECURITY_CONTROL_PROFILE_LOADING_STATE]: setState('isLoading'),
    [SET_DOWNLOAD_QUERY_REPORT_LOADING_STATE]: setState('isCsvLoading'),
    [TOGGLE_REFRESH_LOCAL_DROPDOWN_STATE]: toggleState('refreshLocalDropdownState'),
    [DISABLE_DRAWER_TOOLTIP]: state => ({
      ...state,
      isTooltipdisabled: true
    }),
    [SET_OPEN_FILTER_PANEL]: setState('isOpenPanel')
  }[type];

  return reducer ? reducer(state, payload) : state;
};
