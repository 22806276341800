// @flow

import React from 'react';

import Page from 'src/pages';

/*************
 *   TYPES   *
 *************/

type Props = {
  orgId: string,
  formatCurrency: Function,
  translate: Function
};

/*****************
 *   COMPONENT   *
 *****************/

export default ({ orgId, formatCurrency, translate }: Props) => <Page>O365 License pricing</Page>;
