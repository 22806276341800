// @flow

import React from 'react';
import { ErrorPage } from '@accordo-feed/aco-styled-components';
import { PATHS } from 'src/constants';
import { useTranslate } from '@accordo-feed/language.entry';

import * as Styled from './page.styled';
import lang from './page.lang';

/*************
 *   TYPES   *
 *************/

type Props = {
  router: Object
};

/*****************
 *   COMPONENT   *
 *****************/

export default ({ router }: Props) => {
  const translate = useTranslate();

  const props = {
    header: translate(lang.header),
    message: (
      <>
        <Styled.Paragraph>{translate(lang.paragraph1)}</Styled.Paragraph>
        <Styled.Paragraph>{translate(lang.paragraph2)}</Styled.Paragraph>
      </>
    ),
    buttonText: translate(lang.buttonText),

    onButtonClick: () => {
      router.replace(PATHS.PARTNER_CENTER_WELCOME);
    }
  };

  return <ErrorPage {...props} />;
};
