// @flow

import * as React from 'react';
import { useTranslate } from '@accordo-feed/language.entry';

import * as Styled from './ctaLayout.styled';
import lang from './statics.lang';

/*************
 *   TYPES   *
 *************/

type PropTypes = {
  active: number,
  total: number
};

/*****************
 *   COMPONENT   *
 *****************/

const Statics = ({ active, total }: PropTypes) => {
  const translate = useTranslate();

  return (
    <Styled.StaticsWrapper>
      <div>
        <Styled.FilterWording>
          {active} {translate(lang.filtered)} /{' '}
        </Styled.FilterWording>
        {translate(lang.total)} {total}
      </div>
    </Styled.StaticsWrapper>
  );
};

export default Statics;
