import 'antd/dist/antd.css';
import 'react-grid-layout/css/styles.css';

import '@babel/polyfill';
import 'custom-event-polyfill';
import React from 'react';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { TranslateProvider, renderWithLanguageEntry } from '@accordo-feed/language.entry';

import App from './App';
import { GlobalStyle } from './App.styled';
import ErrorModal from './components/errorModal';
import ThemeProviderWrapper from './components/wrapperComponents/ThemeProvider.wrapper';
import getStore from './redux';
import { DEFAULT_LANGUAGE, STORAGE_KEYS } from './constants';
import { StoreContext } from './hooks/useReduxStore';
import { initSharedDependencies, initCommandListeners } from './utils';

(async () => {
  // The share dependencies and segment listeners should be initialized as soon as possible
  initSharedDependencies();

  const store = await getStore();
  const persistor = persistStore(store);

  initCommandListeners(store);

  const reduxEnhancedApp = (
    <Provider store={store}>
      <StoreContext.Provider value={store}>
        <PersistGate loading={null} persistor={persistor}>
          <TranslateProvider>
            <ThemeProviderWrapper>
              <App />
              <ErrorModal />
              <GlobalStyle />
            </ThemeProviderWrapper>
          </TranslateProvider>
        </PersistGate>
      </StoreContext.Provider>
    </Provider>
  );

  renderWithLanguageEntry({
    container: document.getElementById('root'),
    locale: localStorage.getItem(STORAGE_KEYS.LOCALE) || DEFAULT_LANGUAGE
  })(reduxEnhancedApp);
})();
