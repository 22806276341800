// @flow

import React from 'react';
import { useTranslate } from '@accordo-feed/language.entry';

import CheckboxGroup from 'src/components/checkboxGroup';
import lang from 'src/pages/masterList/masterList.lang';
import { RECOMMENDATION_TYPE } from 'src/redux/modules/filters/filters.duck';
import * as Styled from './filter.styled';

const filterLang = lang.filter;

/*************
 *   TYPES   *
 *************/

type ActionType = {
  setDashboardRecommendationsFilter: Function
};

type Props = {
  actions: ActionType
};

/*****************
 *   COMPONENT   *
 *****************/

export default ({ actions }: Props) => {
  const translate = useTranslate();

  const dashboardRecommendationFilterOptions = [
    { label: translate(filterLang.hasEngagement), value: RECOMMENDATION_TYPE.ENGAGEMENT },
    { label: translate(filterLang.hasMonthlyRevenue), value: RECOMMENDATION_TYPE.MONTHLY_REVENUE },
    { label: translate(filterLang.hasConsultingRevenue), value: RECOMMENDATION_TYPE.CONSULTING_REVENUE }
  ];

  return (
    <>
      <Styled.Title>{translate(filterLang.recommendationsTitle)}</Styled.Title>
      <CheckboxGroup
        options={dashboardRecommendationFilterOptions}
        onChange={actions.setDashboardRecommendationsFilter}
      />
    </>
  );
};
