import { langHelper } from '@accordo-feed/micro-frontends-utils';

const genId = langHelper.genLangId('shell.aco.notificationsSettings');
const genOpts = langHelper.genLangOptions(genId);

export default {
  title: genOpts('title', 'Email Notification Settings'),
  desc: genOpts('desc', 'Email notifications will provide actionable insights direct to your inbox!'),
  content: {
    heading1: genOpts('content.heading1', 'Licence Management Insights'),
    desc1: genOpts(
      'content.desc1',
      'Learn your top 5 clients with the most unassigned licenses and recurring revenue at risk.'
    ),
    heading2: genOpts('content.heading2', 'Secure Score Insights'),
    desc2: genOpts(
      'content.desc2',
      'Learn who your top 5 clients are with the lowest MS Secure Score for Office 365 and how many improvement actions you can complete to increase their scores.'
    )
  }
};
