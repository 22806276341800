// @flow

import React from 'react';
import { ErrorPage } from '@accordo-feed/aco-styled-components/dist';
import { compose, withProps, withHandlers } from 'recompose';
import { router } from '@accordo-feed/micro-frontends';
import { translateHOC } from '@accordo-feed/language.entry';

import * as Styled from './inviteError.styled';
import lang from './inviteError.lang';
import { PATHS } from 'src/constants';

const InviteError = compose(
  translateHOC,
  router.withRouterHOC,

  withProps(({ translate }) => ({
    header: translate(lang.header),
    message: (
      <>
        <Styled.Message>{translate(lang.message1)}</Styled.Message>
        <Styled.Message>{translate(lang.message2)}</Styled.Message>
      </>
    ),
    buttonText: translate(lang.backToSignIn)
  })),

  withHandlers({
    onButtonClick: ({ router }) => () => router.push(PATHS.LOGIN)
  })
)(ErrorPage);

InviteError.noSider = true;

export default InviteError;
