// @flow

declare var featureRules: Object;

/*************
 *   TYPES   *
 *************/

type FeatureRulesType = {
  AggregateDashboard: Object
};

/*********************
 *   INITIAL STATE   *
 *********************/

const initialState: FeatureRulesType = featureRules || {};

/***************
 *   REDUCER   *
 ***************/

export default (state: FeatureRulesType = initialState) => {
  // Should have no action but refer `featureRules`
  return state;
};
