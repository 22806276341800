import React, { useState } from 'react';
import { getData as getCountryList, getCodeList } from 'country-list';

import { StyledSelect } from './countryPicker.styled';
import { byInputValue, generateOption } from './countryPicker.utils';
import enabledCountries from './enabledCountries';
import { DEFAULT_COUNTRY_CODE } from 'src/constants';

const fullCountryList = getCountryList();
const filteredCountryList = fullCountryList.filter(country => enabledCountries.includes(country.code));
const countryMap = getCodeList();

/*****************
 *   COMPONENT   *
 *****************/

const CountryPicker = ({ includeAllCountries = true, ...props }) => {
  const [inputValue, setInputValue] = useState();
  const countryList = includeAllCountries ? fullCountryList : filteredCountryList;

  return (
    <StyledSelect
      name="country"
      defaultValue={(props.registeredCountryCode || DEFAULT_COUNTRY_CODE).toLowerCase()}
      showSearch
      size="large"
      filterOption={false}
      onSearch={setInputValue}
      notFoundContent={null}
      {...props}
    >
      {countryList.filter(byInputValue(inputValue)).map(generateOption(countryMap, 'country'))}
    </StyledSelect>
  );
};

export default CountryPicker;
