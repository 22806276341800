// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { Layout } from 'antd';

import AppFooter from 'src/components/appFooter';
import AppSider from 'src/components/appSider';
import GlobalHeader from 'src/components/globalHeader';
import * as customersSelectors from 'src/pages/customers/customers.selector';
import * as selectors from 'src/redux/selectors';
import { APP_VIEWS, NON_AUTH_PATHS } from 'src/constants';
import { setMenuCollapsedState } from 'src/components/appSider/menu/menu.duck';
import GlobalAlert from './globalAlert';
import * as Styled from './coreLayout.styled';

const { Content } = Layout;

/*************
 *   TYPES   *
 *************/

type DefaultLayoutTypes = {
  children: React.Node,
  appView: typeof APP_VIEWS.SHELL | typeof APP_VIEWS.OFFICE_365_OPTIMIZER,
  collapsed: boolean,
  setMenuCollapsedState: Function,
  isBulkEditView: boolean
};

/*****************
 *   COMPONENT   *
 *****************/

const CoreLayout = ({ appView, children, collapsed, isBulkEditView, setMenuCollapsedState }: DefaultLayoutTypes) => {
  const noMinWidth = true;
  let noSider = false;

  React.Children.forEach(children, element => {
    if (!React.isValidElement(element)) return;

    const { props } = element;
    const getPropPath = prop => ['route', 'component', prop];

    noSider = R.pathOr(false, getPropPath('noSider'), props) || R.includes(props.location.pathname, NON_AUTH_PATHS);
  });

  const contentWrapperProps = {
    className: collapsed && 'expand',
    nominwidth: noMinWidth.toString(),
    nosider: noSider.toString()
  };

  return (
    <Styled.AppWrapper>
      {isBulkEditView && <Styled.GreyedOutMask />}
      <Styled.BodyWrapper>
        {!noSider && <AppSider collapsed={collapsed} setCollapsed={setMenuCollapsedState} />}
        <Styled.ContentWrapper {...contentWrapperProps}>
          <GlobalAlert className="at_globalAlert" />
          <GlobalHeader />
          <Content className="aco-main-content">{children}</Content>
          <AppFooter />
        </Styled.ContentWrapper>
      </Styled.BodyWrapper>
    </Styled.AppWrapper>
  );
};

/*************
 *   REDUX   *
 *************/

const mapStateToProps = R.applySpec({
  appView: selectors.appViewSelector,
  collapsed: selectors.menuCollapsedStateSelector,
  isBulkEditView: customersSelectors.bulkEditViewSelector
});

const mapDispatchToProps = {
  setMenuCollapsedState
};

export default connect(mapStateToProps, mapDispatchToProps)(CoreLayout);
