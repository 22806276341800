// @flow

import { router } from '@accordo-feed/micro-frontends';

import PageComponent from './Page.component';

const Page = router.withRouterHOC(PageComponent);

Page.noSider = true;

export default Page;
