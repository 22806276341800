import * as Yup from 'yup';

import { lang } from './addressForm.lang';

// line1,
// line2,
// city,
// region,
// country,
// postalCode

export const addressSchema = translate =>
  Yup.object().shape({
    line1: Yup.string().required(translate(lang.error, { fieldName: translate(lang.line1) })),
    line2: Yup.mixed().notRequired(),
    city: Yup.string().required(translate(lang.error, { fieldName: translate(lang.city) })),
    region: Yup.mixed().notRequired(),
    country: Yup.mixed().notRequired(),
    postalCode: Yup.string().required(translate(lang.error, { fieldName: translate(lang.postalCode) }))
  });
