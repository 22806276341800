// @flow

import * as R from 'ramda';
import { connect } from 'react-redux';

import * as selectors from 'src/redux/modules/distiBilling/distiBilling.selector';
import SubscriptionUsage from './SubscriptionUsage.component';

/*************
 *   REDUX   *
 *************/

const mapStateToProps = R.applySpec({
  data: selectors.dataSelector
});

export default connect(mapStateToProps)(SubscriptionUsage);
