import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { CheckCircle, Lock } from '@material-ui/icons';
import { mixins, constants } from '@accordo-feed/aco-styled-components';

import { useStripeField } from 'src/hooks/useStripe';
import { hexToRgb } from 'src/utils';
import { Row, Column } from './paymentDetails.styled';
import { appTextColor } from 'src/App.styled';

const { colors, fontSize } = constants;

export const CardFormBox = styled(Column)`
  width: 60%;
  min-width: 350px;
  max-width: 560px;
  margin-top: 24px;
  color: ${colors.white};
`;

const CardInputBox = styled.div`
  height: 40px;
  padding: 11px;
  font-size: ${fontSize.normal};
  border-radius: 7px;
  border: 1px solid ${colors.darkGrey6};
  background-color: rgba(${hexToRgb(colors.lightGrey7)}, 0.24);
  input {
    font-size: ${fontSize.normal} !important;
  }
`;

const CardFormFieldLabel = styled.label`
  color: ${appTextColor};
  font-weight: 300;
  font-size: ${fontSize.small};
  letter-spacing: 0.24px;
  margin-bottom: 5px;
`;

export const CardFormField = ({ id, text, children, width, notStripe = false }) => {
  const { props, errorMessage } = useStripeField(id);
  const { isFocus, isError, ...rest } = props;

  return (
    <Column width={width} margin="20px 0 0" className="inspectletIgnore">
      <CardFormFieldLabel htmlFor={id}>{text}</CardFormFieldLabel>
      {React.Children.map(children, child =>
        notStripe ? (
          child
        ) : (
          <>
            <CardInputBox focus={isFocus} error={isError}>
              {React.cloneElement(child, rest)}
            </CardInputBox>
            {errorMessage}
          </>
        )
      )}
    </Column>
  );
};

const GreenTick = styled(CheckCircle)`
  ${mixins.size('18px !important')};
  color: ${colors.shadeGreen};
  margin-right: 8px;
`;

export const CardFormHighlight = ({ text }) => (
  <Row margin="8px 0 0 0">
    <GreenTick />
    {text}
  </Row>
);

export const CardFormFooter = styled.p`
  font-size: ${fontSize.xSmall};
  color: ${colors.darkGrey};
  margin-top: 38px;
`;

export const StripeLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer'
})`
  font-size: ${fontSize.xSmall};
  color: ${colors.darkGrey};
`;

export const Link = styled(StripeLink)`
  text-decoration: underline;
`;

export const LockIcon = styled(Lock)`
  ${mixins.size('18px !important')};
  color: ${colors.darkGrey};
  float: left;
  margin: 0 5px 0 10px;
`;

export const StartButton = styled(Button).attrs({
  type: 'primary',
  shape: 'round'
})`
  margin-bottom: 20px;
  margin-top: 40px;
  width: 100%;
  text-align: center;
  border-radius: 30px !important;
  ${mixins.verticalLineMiddle('40px')};
  background: linear-gradient(180deg, ${colors.deepCyan} 1.56%, ${colors.deepCyan1} 100%);
`;
