// @flow

import React from 'react';
import { Formik } from 'formik';
import { LeftOutlined } from '@ant-design/icons';
import { useTranslate } from '@accordo-feed/language.entry';
import { eventTracking } from '@accordo-feed/micro-frontends';

import * as Styled from './step2.styled';
import lang from './step2.lang';
import { SEGMENT_EVENTS } from 'src/constants';

/*************
 *   TYPES   *
 *************/

type Actions = {
  setStep: Function,
  connectPartnerCenter: Function
};

type Props = {
  actions: Actions,
  isConnecting: Boolean
};

/*****************
 *   COMPONENT   *
 *****************/

export default ({ actions, isConnecting }: Props) => {
  const translate = useTranslate();

  const onBack = () => actions.setStep(1);

  const onConnect = () => {
    eventTracking.trackEvent({ trackName: SEGMENT_EVENTS.CONNECT_PC_CONNECT });
    actions.connectPartnerCenter({ translate });
  };

  return (
    <Styled.Container>
      <Formik onSubmit={() => {}}>
        {() => (
          <Styled.Body>
            <Styled.CircleCheckIcon type="check-circle"></Styled.CircleCheckIcon>
            <Styled.Title>{translate(lang.title)}</Styled.Title>
            <Styled.Desc>{translate(lang.desc)}</Styled.Desc>
            <Styled.ButtonsWrapper>
              <Styled.Back onClick={onBack} disabled={isConnecting}>
                <LeftOutlined />
                {translate(lang.back)}
              </Styled.Back>
              <Styled.Connect onClick={onConnect} disabled={isConnecting}>
                {translate(lang.connect)}
              </Styled.Connect>
            </Styled.ButtonsWrapper>
          </Styled.Body>
        )}
      </Formik>

      <Styled.Footer>{translate(lang.footer)}</Styled.Footer>
    </Styled.Container>
  );
};
