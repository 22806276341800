import { langHelper } from '@accordo-feed/micro-frontends-utils';

const genProfileId = langHelper.genLangId('shell.aco.profile');

const genProfileLang = langHelper.genLangOptions(genProfileId);

export default {
  heading: genProfileLang('heading', 'Account Settings'),
  personalDetailsHeading: genProfileLang('personalDetailsHeading', 'User Profile'),
  firstNameLabel: genProfileLang('firstNameLabel', 'First Name'),
  lastNameLabel: genProfileLang('lastNameLabel', 'Last Name'),
  emailLabel: genProfileLang('emailLabel', 'Email address'),
  countryLabel: genProfileLang('countryLabel', 'Country'),
  phoneNumberLabel: genProfileLang('phoneNumberLabel', 'Phone Number'),
  organizationDetailsHeading: genProfileLang('organizationDetailsHeading', 'Organization Profile'),
  organizationLabel: genProfileLang('organizationLabel', 'Name'),
  seatCountLabel: genProfileLang('seatCountLabel', 'Seat Count'),
  clientsLabel: genProfileLang('clientsLabel', 'Number of clients'),
  psaToolsLabel: genProfileLang('psaToolsLabel', 'PSA tools'),
  brandAccentColor: genProfileLang('brandAccentColor', 'Brand Accent Color (for PDF Report)'),
  brandLogo: genProfileLang('brandLogo', 'Logo'),
  brandLogoDescription: genProfileLang(
    'brandLogoDesc',
    'Only JPEG, PNG files are supported. Maximum allowed file size 5MB.'
  ),
  brandLogoButton: genProfileLang('brandLogoButton', 'Update Logo'),
  privacyAndCookiesLink: genProfileLang('privacyAndCookiesLink', 'Privacy & Cookies'),
  saveChangesButton: genProfileLang('saveChangesButton', 'Save Changes'),
  errors: {
    firstNameTooLong: genProfileLang('firstNameTooLong', 'First name is too long'),
    firstNameRequired: genProfileLang('firstNameRequired', 'Please enter the first name'),
    lastNameTooLong: genProfileLang('lastNameTooLong', 'Last name is too long'),
    lastNameRequired: genProfileLang('lastNameRequired', 'Please enter the last name'),
    phoneNumberInvalid: genProfileLang('phoneNumberInvalid', 'Please enter a valid phone number'),
    organizationNameTooLong: genProfileLang('organizationNameTooLong', 'Organisation name is too long'),
    organizationNameRequired: genProfileLang('organizationNameRequired', 'Please enter the organisation name'),
    seatCountInvalid: genProfileLang('seatCountInvalid', 'Please enter a valid organisation seat count'),
    clientsInvalid: genProfileLang('clientsInvalid', 'Please enter a valid clients number'),
    alertOnInvalid: genProfileLang('alertOnInvalid', 'Please enter a boolean value'),
    emailInvalid: genProfileLang('emailInvalid', 'Please enter valid email addresses'),
    emailRequired: genProfileLang(
      'emailRequired',
      'If alert is on, please enter at least one email for the subscription'
    )
  },
  changePasswordButton: genProfileLang('changePasswordButton', 'Change Password'),
  inviteTeamMemberButton: genProfileLang('inviteTeamMemberButton', 'Invite Team Member'),
  comingSoonSpan: genProfileLang('comingSoonSpan', 'Coming soon'),
  psaOptions: {
    connectwise: genProfileLang('psaOptions.connectwise', 'ConnectWise'),
    autotask: genProfileLang('psaOptions.autotask', 'AutoTask'),
    kaseya: genProfileLang('psaOptions.kaseya', 'Kaseya'),
    others: genProfileLang('psaOptions.others', 'Others')
  }
};
