// @flow

import React, { useEffect } from 'react';
import queryString from 'query-string';
import { LoadingView } from '@accordo-feed/aco-styled-components';
import { router } from '@accordo-feed/micro-frontends';
import { useTranslate } from '@accordo-feed/language.entry';

import authLang from 'src/components/auth/auth.lang';
import { PATHS } from 'src/constants';
import { REDIRECT_TO_PARAM } from 'src/utils/auth';

/*************
 *   TYPES   *
 *************/

type Props = {
  router: Object
};

/*****************
 *   COMPONENT   *
 *****************/

const Home = ({ router }: Props) => {
  const translate = useTranslate();

  useEffect(() => {
    const { pathname } = router.location;

    const stringified = queryString.stringify({
      [REDIRECT_TO_PARAM]: pathname === PATHS.HOME ? PATHS.PARTNER_CENTER_WELCOME : pathname
    });

    router.replace(`${PATHS.LOGIN}?${stringified}`);
  }, []);

  return <LoadingView loadingMsg={translate(authLang.loading)} />;
};

export default router.withRouterHOC(Home);
